import Fieldset from 'components/_UI/Fieldset';
import Text from 'components/_UI/Text';
import React, { useEffect, useState } from 'react';
import { Column } from 'styled/alignment/Column';
import { StyledForm } from 'styled/UI/StyledForm';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import { useForm } from 'react-hook-form';
import fieldValidations from 'helpers/fieldValidations';
import Button from 'components/_UI/Button';
import { HorizontalCenter } from 'styled/alignment/Center';
import { useStepRecoverAccount } from 'context/login/StepRecoverAccount';
import { useUser } from 'context/user/User';
import masks from 'helpers/masks';
import unformat from 'helpers/unformat';
import loginApi from 'api/requests/login';
import step from 'helpers/system/step';
import buttonText from 'texts/buttons/buttonText';
import Loader from 'components/_UI/Loader';
import paths from 'routes/paths';

function RecoverAccountStepOne() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const { stepRecoverAccount, setStepRecoverAccount } = useStepRecoverAccount();
    const { setUser } = useUser();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    watch(({email}) => {
        if(errorMessage) {
            setErrorMessage(false)
        }
    })

    const nextStep = ({ email }) => {
        const payload = {
            email,
            // document: unformat.getNumbers(cpf)
        }

        setIsLoading(true);
        forgotPassword(payload);
    };

    const forgotPassword = async (payload) => {
        try {
            const { data } = await loginApi.forgotPassword(payload);
            setUser({ ...payload, ...data });
            setStepRecoverAccount(step.next(stepRecoverAccount));
        } catch ({response}) {
            
            setErrorMessage(response?.data?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Column verticalCenter>
                <Text color={colors.neutral} type='bold' name='highlight'>Precisamos saber</Text>
                <Text color={colors.neutral} type='bold' name='highlight'>algumas informações básicas</Text>
            </Column>

            <StyledForm
                marginTop={sizes.normal}
                paddingRight='13%'
                paddingLeft='13%'
                onSubmit={handleSubmit(nextStep)}
            >
                <Fieldset
                    placeholder='E-mail *'
                    layout='straight-center'
                    marginTop={sizes.normal}
                    name="email"
                    register={register}
                    validations={fieldValidations.email()}
                />
                {
                    (errors.email?.message) && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.email?.message} </Text>
                        </HorizontalCenter>
                    )
                }
                {
                    errorMessage && (
                        <HorizontalCenter marginTop='10px'>
                            <Text color={colors.danger}> {errorMessage} </Text>
                        </HorizontalCenter>
                    )
                }

                {/* <Fieldset
                    placeholder='CPF *'
                    layout='straight-center'
                    marginTop={sizes.normal}
                    name="cpf"
                    mask={masks.cpf}
                    register={register}
                    validations={fieldValidations.cpf()}
                />
                {(errors.cpf?.message) && (
                    <HorizontalCenter>
                        <Text color={colors.danger}> {errors.cpf?.message} </Text>
                    </HorizontalCenter>
                )}

                {(errorMessage) && (
                    <HorizontalCenter>
                        <Text color={colors.danger}> {errorMessage} </Text>
                    </HorizontalCenter>
                )} */}

                {
                    !isLoading ? (
                        <Button
                            category='roundedBorded'
                            variant='secondary'
                            size='large'
                            marginTop={sizes.big}
                            type='submit'
                        >
                            {buttonText.next}
                        </Button>
                    ) : (<Loader />)
                }

                <Button
                    path={paths.login}
                    category='minimal'
                    variant='primary'
                    size='large'
                    marginTop={sizes.nano}
                >
                    {buttonText.back}
                </Button>
            </StyledForm>
        </>
    )
};

export default RecoverAccountStepOne;