import paths from './paths';
import UserProfile from 'screens/user/UserProfile';
import Wallet from 'screens/user/Wallet';


export default [
    {
        exact: true,
        path: paths.user.profile,
        element: <UserProfile />
    },
    {
        exact: true,
        path: paths.user.wallet,
        element: <Wallet />
    },
]