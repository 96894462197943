// /custom-fields/
import api from "api";

const customFields = {
    get: (slug) => {
        return api().get(`/custom-fields/${slug}`)
    },

    create: (payload) => {
        return api().post('/custom-fields', payload)
    },

    update: (payload) => {
        return api().put('/custom-fields', payload)
    },


}

export default customFields;