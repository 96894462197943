import Loader from 'components/_UI/Loader'
import Text from 'components/_UI/Text'
import React, { useEffect } from 'react'
import EventCard from '../card/EventCard'
import { useCollapse } from 'context/general/Collapse'
import EventDetail from '../detail/EventDetail'
import { Column } from 'styled/alignment/Column'
import styled from 'styled-components'
import { Row } from 'styled/alignment/Row'
import Icon from 'components/_UI/Icon'
import colors from 'theme/colors'
import { FullyCentered } from 'styled/alignment/Center'
import eventsApi from 'api/requests/events'
import { useSearchParams } from 'react-router-dom'
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse'
import breakpoints from 'theme/breakpoints'

const FiltersResult = React.forwardRef(({ data, error, routeParams }, ref) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const { collapse, setCollapse } = useCollapse();
    const { backButtonCollapse, setBackButtonCollapse } = useBackButtonCollapse();

    function renderResult() {
        if (data.length) {
            return (
                <Column fullyCentralized>
                    <Text name='highlight'>
                        {
                            data.length > 1
                                ? `Foram encontrados ${data.length} eventos na sua busca`
                                : `Foi encontrado ${data.length} evento na sua busca`
                        }
                    </Text>
                    <ResponseContent>
                        {
                            data.map(el => (
                                <EventCard
                                    data={el}
                                    key={el.id}
                                    whiteBackground
                                    onEventClick={(e) => renderEventDetail(e)}
                                    smallOnDesktop={true}
                                />
                            ))
                        }
                    </ResponseContent>
                </Column>
            )
        }

        if (error) {
            return (
                <ErrorContainer>
                    <Text
                        name='subtitle'
                        color={colors.night}
                    >
                        Ops... Ocorreu um erro inesperado, tente novamente.
                    </Text>
                </ErrorContainer>
            )
        }

        return (
            <ErrorContainer>
                <Column
                    width='70px'
                    height='70px'
                    marginBottom='24px'
                >
                    <Icon
                        color={colors.night}
                        name='marker-not-found'
                    />
                </Column>
                <Text
                    name='subtitle'
                    color={colors.night}
                >
                    Infelizmente não foram encontrados resultados para a sua busca.
                </Text>
            </ErrorContainer>
        )
    }

    async function renderEventDetail(event) {
        setBackButtonCollapse({
            ...backButtonCollapse,
            from: 'filter-result',
            data: routeParams
        })

        setSearchParams({ evento: event.slug })

        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => event && (
                <FullyCentered height='100%'>
                    <Loader />
                </FullyCentered>
            )
        })

        try {
            const { data } = await eventsApi.getBySlug(event.slug)
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => data && <EventDetail data={data.data} withBackButton />
            })
        } catch (error) {
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => event && (
                    <FullyCentered height='100%'>
                        <Text>Ocorreu um erro inesperado.</Text>
                    </FullyCentered>
                )
            })
        }
    }

    return (
        <ResultContainer ref={ref}>
            {renderResult()}
        </ResultContainer>

    )
})

export default FiltersResult;

const ResultContainer = styled(Column)`
    padding: 25px 0 50px 0;
`;


const ResponseContent = styled(Row)`
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }
`;

const ErrorContainer = styled(Column)`
    width: 100%;
    height: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`