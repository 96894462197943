import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import { useEstablishmentFilter } from 'context/establishments/EstablishmentFilter';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function EstablishmentFeaturesSelects({data}) {
    const { establishmentFilter, setEstablishmentFilter } = useEstablishmentFilter();

    const [selectedItems, setSelectedItems] = useState([]);

    const handleSelectItem = (id) => {
        setSelectedItems(prevSelectedItems => {
            let newSelectedItems;

            if (prevSelectedItems.includes(id)) {
                newSelectedItems = prevSelectedItems.filter(item => item !== id);
            } else {
                newSelectedItems = [...prevSelectedItems, id];
            }

            setEstablishmentFilter({
                ...establishmentFilter,
                features: newSelectedItems
            });

            return newSelectedItems;
        });
    };

    return (
        <Column fullyCentralized marginTop='25px'>
            <Text size='small' type='bold' paddingBottom={'5px'}>
                Características do local
            </Text>
            <Text size='small' color={colors.night} paddingBottom={'20px'}>
                Escolha as características que você busca no local.
            </Text>

            <StyledList>
                {
                    data.map(el => (
                        <SelectItem
                            key={el.id}
                            selected={selectedItems.includes(el.id)}
                            onClick={() => handleSelectItem(el.id)}
                        >
                            <Column width='22px' height='22px'>
                                <Icon
                                    name={el.icon}
                                    color={selectedItems.includes(el.id) ? colors.neutral : colors.night}
                                />
                            </Column>
                            <Text
                                name='mini'
                                marginTop='4px'
                                textAlign={'center'}
                                type='bold'
                                color={selectedItems.includes(el.id) ? colors.neutral : colors.night}
                            >
                                {el.name}
                            </Text>
                        </SelectItem>
                    ))
                }
            </StyledList>
        </Column>
    );
}

const StyledList = styled(Row)`
    flex-wrap: wrap;
    justify-content: space-around;
`;

const SelectItem = styled.div`
    width: 30%;
    margin-bottom: 3%;
    border: 1px solid ${colors.night};
    padding: 8px 5px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & > p {
        padding-bottom: 0;
    }

    @media screen and (min-width: ${breakpoints.lg}){
        width: 15%;
    }

    ${({ selected }) => selected && `
        background: #4617ad;
        border: 1px solid #4617ad;
    `};
`;
