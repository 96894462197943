import EstablishmentProfile from 'modules/dashboard/profile/EstablishmentProfile'
import React, { useState } from 'react'
import DashContainer from 'modules/dashboard/_common/DashContainer'
import RightSlotProfile from 'modules/dashboard/profile/right-slot/RightSlotProfile'
import EstablishmentProvider from 'context/establishments/Establishment'
import BackButtonCollapseDashProvider from 'context/dash/BackButtonCollapseDash'
import ShowAllEventsProvider from 'context/dash/ShowAllEvents'
import EstablishmentTagsProvider from 'context/establishments/EstablishmentTags'
import BackButtonCollapseProvider from 'context/general/BackButtonCollapse'
import DetailVisibleProvider from 'context/dash/DetailVisible'

export default function DashProfile() {

  const [editProfileClick, setCreateEventClick] = useState(0)

  function rightSlotContent() {
    return (
      <RightSlotProfile onEditProfile={() => setCreateEventClick(editProfileClick + 1)} />
    )
  }
  return (
    <BackButtonCollapseDashProvider>
      <DetailVisibleProvider>
        <EstablishmentTagsProvider>
          <ShowAllEventsProvider>
            <EstablishmentProvider>
              <BackButtonCollapseProvider>

                <DashContainer title={'Estabelecimento'} rightSlot={() => rightSlotContent()}>
                  <EstablishmentProfile editProfileClick={editProfileClick} />
                </DashContainer>

              </BackButtonCollapseProvider>
            </EstablishmentProvider>
          </ShowAllEventsProvider>
        </EstablishmentTagsProvider>
      </DetailVisibleProvider>
    </BackButtonCollapseDashProvider>
  )
}
