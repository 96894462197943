import React, { useState, useEffect } from 'react'
import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import { Column } from 'styled/alignment/Column';
import userApi from 'api/requests/user';
import Loader from 'components/_UI/Loader';
import { useUser } from 'context/user/User';
import paths from 'routes/paths';
import indexPriority from 'theme/z-index';
import useAuth from 'hooks/useAuth';
import Button from 'components/_UI/Button';
import { LogOut, User, Shield, UserCheck, Lock } from 'react-feather';
import { useOnSelectMenu } from 'context/general/OnSelectMenu';
import DynamicProfileImage from 'components/commons/DynamicProfileImage';

export default function DashHeader({
    menuVisible,
    visible,
    onToggleMenu,
    onSelectValidation = () => false
}) {
    const { user, setUser } = useUser();
    const { isAuth } = useAuth();

    const dropdownItems = [
        { name: 'profile', label: user?.role?.id == import.meta.env.VITE_PRODUCER_ROLE_ID ? 'Resumo' : 'Estabelecimento' && !visible ? 'Perfil' : 'Estabelecimento', route: paths.dashboard.profile, icon: <User size='16px' color={colors.primaryDashboard} /> },
        { name: 'wallet', label: 'Carteira', icon: <ContentIcon wallet><Icon name='wallet' color={colors.primaryDashboard} /></ContentIcon> },
        { name: 'change-password', label: 'Alterar senha', icon: <Lock size='16px' color={colors.primaryDashboard} /> },
        // { name: 'Desativar Conta' },
        // { name: 'delete-account', label: 'Excluir conta', icon: <Trash2 size='16px' color={colors.primaryDashboard} /> },
        // { name: 'checkin', label: 'Validação', icon: <ContentIcon wallet><Icon name='qrcode' color={colors.primaryDashboard} /></ContentIcon> }, ,
        { name: 'use-terms', label: 'Termos de uso', icon: <UserCheck size='16px' color={colors.primaryDashboard} /> },
        { name: 'privacy-policies', label: 'Política de privacidade', icon: <Shield size='16px' color={colors.primaryDashboard} /> },
        { name: 'logout', label: 'Sair', icon: <LogOut size='16px' color={colors.primaryDashboard} /> },
    ]

    const [firstLineToggle, setFirstLineToggle] = useState(false);
    const [secondLineToggle, setSecondLineToggle] = useState(false);
    const [visibleDropdown, setVisibleDropdown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { onSelectMenu, setOnSelectMenu } = useOnSelectMenu()
    const [imageProfile, setImageProfile] = useState('')
    const [nameProfile, setNameProfile] = useState('')

    const authorizedRoles = [
        +import.meta.env.VITE_PRODUCER_ROLE_ID,
        +import.meta.env.VITE_ESTABLISHMENT_ROLE_ID,
        +import.meta.env.VITE_ADMIN_ROLE_ID
    ];

    useEffect(async () => {
        let currentUser = user

        if (!currentUser && isAuth) {
            currentUser = await getUser()
        }

        handleProfileImage(currentUser)
    }, [])

    useEffect(async () => {
        handleProfileImage(user)
    }, [user])

    useEffect(() => {
        if (menuVisible) {
            setVisibleDropdown(false)
            setFirstLineToggle(false)
            setSecondLineToggle(false)
        }
    }, [menuVisible])

    async function handleProfileImage (updatedUser) {
        let image = updatedUser?.avatar
        let name = updatedUser?.first_name

        if (updatedUser?.role?.id == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID) {
            const currentEstablishment = updatedUser?.establishments[0]

            image = currentEstablishment?.profile_image_id?.thumb || image
            name = currentEstablishment?.name || name
        }

        setImageProfile(image)
        setNameProfile(name)
    }

    async function getUser() {
        if (user) {
            return;
        }

        setIsLoading(true);

        try {
            const { data } = await userApi.me();

            setUser(data);

            return data;
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    function toggleMenu() {
        setFirstLineToggle(!firstLineToggle)
        setSecondLineToggle(!secondLineToggle)
        onToggleMenu()
    }

    function toogleProfile() {
        setVisibleDropdown(!visibleDropdown)
    }

    function goHome() {
        window.location.href = paths.home
    }

    return (
        <StyledHeader onClick={() => setVisibleDropdown(false)}>
            <WhizrLogo
                onClick={() => goHome()}
            >
                <Icon name={'whizr-inline-logo-dark'} />
            </WhizrLogo>

            <RightNavContainer>
                {
                    !isAuth ? (
                        <Column width='220px'>
                            <Button
                                path={paths.login}
                                variant='featured'
                            >
                                <FullyCentered>
                                    <Column width='22px' height='22px'>
                                        <Icon name='login' color={colors.neutral} />
                                    </Column>
                                    <Text color={colors.neutral} type='bold' paddingBottom={0} marginLeft={'8px'}>
                                        Entrar ou criar conta
                                    </Text>
                                </FullyCentered>
                            </Button>
                        </Column>
                    ) : (
                        <>
                            <HamburguerMenu onClick={toggleMenu}>
                                <HamburguerMenuLine firstLineToggle={firstLineToggle} />
                                <HamburguerMenuLine secondLineToggle={secondLineToggle} />
                            </HamburguerMenu>

                            <ContainerUser>
                                {
                                    !user && isLoading ? <Loader /> : (
                                        <>
                                            {
                                                (authorizedRoles?.includes(user?.role?.id) && !visible) && (
                                                    <>
                                                        <ButtonProducerContainer>
                                                            <Button path={user?.role?.id == import.meta.env.VITE_PRODUCER_ROLE_ID ? paths.dashboard.resume : paths.dashboard.home} size='medium'>
                                                                <ContentIcon>
                                                                    <Icon name='pencil-square' color={colors.occasionalPurple} />
                                                                </ContentIcon>
                                                                Painel do produtor
                                                            </Button>
                                                        </ButtonProducerContainer>
                                                    </>
                                                )
                                            }

                                            <ContentProfile
                                                dropdown={visibleDropdown}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toogleProfile();
                                                }}
                                            >

                                                {
                                                    !isLoading && user ? (
                                                        <StylePreview desktop>
                                                            <ArrowDown arrow={visibleDropdown}
                                                            />
                                                            <ContentTextProfile>
                                                                <Text
                                                                    type='bold'
                                                                    paddingBottom={'0px'}
                                                                    ellipsisAt={nameProfile > 30 ? '95%' : '100%'}
                                                                    textAlign={'center'}
                                                                >
                                                                    {nameProfile}
                                                                </Text>
                                                            </ContentTextProfile>

                                                            <DynamicProfileImage
                                                                word={nameProfile}
                                                                img={imageProfile}
                                                                size={'40px'}
                                                                variant='squired'
                                                            />
                                                        </StylePreview>

                                                    ) : (
                                                        <Loader />
                                                    )
                                                }


                                            </ContentProfile>

                                            <StylePreview
                                                mobile
                                                dropdown={visibleDropdown}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toogleProfile();
                                                }}>
                                                <DynamicProfileImage
                                                    word={nameProfile}
                                                    img={imageProfile}
                                                    size={'40px'}
                                                    variant='rounded'
                                                />
                                            </StylePreview>

                                            <Dropdown dropdown={visibleDropdown}>
                                                {
                                                    !!(user?.have_permission_to_checkin && !visible) && (
                                                        <Items onClick={onSelectValidation}>
                                                            <ContainerItems>
                                                                <ContentIcon>
                                                                    <Icon name='qrcode' color={colors.primaryDashboard} />
                                                                </ContentIcon>
                                                                <Text paddingBottom={'0px'} ellipsisAt={'95%'} color={colors.primaryDashboard}>
                                                                    Validação
                                                                </Text>
                                                            </ContainerItems>
                                                        </Items>
                                                    )
                                                }

                                                {dropdownItems?.map((el, index) => {
                                                    if (!(el?.name === 'wallet' && (user?.role?.id == import.meta.env.VITE_PRODUCER_ROLE_ID || user?.role?.id == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID || visible)) && !(el?.name === 'profile' && ((user?.role?.id == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID || user?.role?.id == import.meta.env.VITE_PRODUCER_ROLE_ID) && !visible))) {
                                                        return (
                                                            <Items key={index} onClick={() => setOnSelectMenu({ tab: el?.name })} wallet={el?.name === 'wallet'}>
                                                                <ContainerItems wallet={el?.name === 'wallet'}>
                                                                    {el.icon}
                                                                    <Text paddingBottom={'0px'} ellipsisAt={'95%'} color={colors.primaryDashboard}>
                                                                        {el.label}
                                                                    </Text>
                                                                </ContainerItems>
                                                            </Items>
                                                        )
                                                    }
                                                })}


                                            </Dropdown>

                                        </>
                                    )
                                }
                            </ContainerUser>
                        </>
                    )
                }

            </RightNavContainer>
        </StyledHeader >
    )
}


const StyledHeader = styled.header`
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    background: ${colors.neutralBackground};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 15;
    box-shadow: 1px 1px 4px #ddd;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        padding: 20px 40px; 
        width: 100%;
        border: 1px solid ${colors.neutralDark};
        z-index: ${indexPriority.xHigh};
    }
`;

const WhizrLogo = styled.div`
    width: 100px;
    height: 30px;
    cursor: pointer;
    transition: all ease-in-out .25s;

    &:hover {
        transform: scale(1.03)
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        width: 120px;
        height: 40px;
    }
`;

const HamburguerMenu = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;

    @media screen and (min-width: ${breakpoints.lg}){
        display: none;
    }
`;

const HamburguerMenuLine = styled.div`
    display: block;
    width: 30px;
    background: ${colors.secondaryDark};
    height: 2px;
    margin: 5px 5px;
    transition: all 0.4s ease;
    z-index: 1;
    
    ${({ firstLineToggle }) => firstLineToggle && `
        transform: rotate(45deg) translate(8px, 2px);
    `}
    
    ${({ secondLineToggle }) => secondLineToggle && `
        transform: rotate(-45deg) translate(6px, 0px);
    `}

`;

const RightNavContainer = styled(Row)`
    width: auto;
    align-items: center;
    flex-direction: row-reverse;
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 230px;
    }
    @media screen and (min-width: ${breakpoints.lg}) {
        margin-right: 25px;
    }
`;

const ArrowDown = styled.div`
    width: ${sizes.tiny};
    height: ${sizes.tiny};
    background: '#ccc';
    border-right: 1px solid ${colors.night};
    border-bottom: 1px solid ${colors.night};
    transform: rotate(45deg);
    transition: all ease-in-out .25s;
    display: none;
    ${({ arrow }) => arrow && `
        transform: rotate(225deg);
        margin-top: ${sizes.tiny};
    `}
    @media screen and (min-width: ${breakpoints.md}) {
        display: flex;
    }
`;

const ContainerItems = styled(Row)`
    justify-content: flex-start;
    padding: 0 0 5px 10px;
    align-items: flex-end;
    & > p  {
        padding-bottom: 0;
        margin-left: 6px
    }


    ${({ wallet }) => wallet && `
        padding: 0 0 5px 7px;
        align-items: flex-end;
        justify-content: flex-start;

        & > p {
            margin-left: 4px;
        }
    `}
   
`;

const ContainerUser = styled.div`
    height: 55px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    @media screen and (min-width: ${breakpoints.md}) {
        margin-right: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

const ContentProfile = styled.div`
    display: none;
    background: transparent;
    justify-content: space-around;
    display: flex;
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
    position: relative;
    transition: all ease-in-out .25s;

    &:hover {
        transform: scale(1.02)
    }
    ${({ dropdown }) => dropdown === true && `
       background: red;
       visibility: visible;
       opacity: 1;
       display: flex;
       justify-content: center;
       padding-top: 0;
       overflow: hidden;
       transition: height .4s ease;
    `}
    & > p {
        padding-bottom: 0;
        display: none
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        background: #FFFDFD;
        width: 185px;
        display: flex;
        padding: 5px;
        justify-content: space-evenly;
        & > p {
            padding-bottom: 0;
            display: flex;
        }
    }
`;

const Dropdown = styled(Column)`
    display: none;
    ${({ dropdown }) => dropdown === true && `
        display: flex;
        overflow: auto;
        width: 185px;
        position: fixed;
        align-items: center;
        border-radius: 8px;
        background: #FFFDFD;
        top: 80px;
        max-height: 350px;
    
    `}
`;

const Items = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    height: auto;
    width: 100%;
    cursor: pointer;
    &:hover {
        background: ${colors.primaryLight};
        p {
            color: ${colors.neutral} !important;
        }
        & > div > svg {
            stroke: ${colors.neutral}
        }

        & > div > div > svg {
            fill: ${colors.neutral}
        }
    }

    ${({ wallet }) => wallet && `
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            & > div > div> svg {
                fill: 
                    ${colors.neutral};
            }
        }

        & > div {
            align-items: center;
        }
    `}
`;

const ButtonProducerContainer = styled(Column)`
    position: absolute;
    right: 270px;
    width: 200px;

    & > a > button {
        margin-bottom: 0px;
        background: transparent;
        border: 1px solid ${colors.occasionalPurple};
        color: ${colors.occasionalPurple};
        transition: ease-in-out .25s all;
        
        &:hover {
            transform: scale(1.02);
            border: 1px solid ${colors.occasionalPurple};
            color: ${colors.occasionalPurple};
            background: transparent;
        }

    }
`;

const StylePreview = styled(Row)`
    ${({ mobile }) => mobile && `
        justify-content: flex-end;
        @media screen and (min-width: ${breakpoints.lg}) {
            display: none;
        }
    `}
    ${({ desktop }) => desktop && `
         display: none;
        @media screen and (min-width: ${breakpoints.lg}) {
            display: flex;
            align-items: center;
            & > p {
                padding-bottom: 0px;
            }
            justify-content: space-around;
        }
    `}
`;


const ContentIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 6px;

    ${({ wallet }) => wallet && `
        width: 20px;
        height: 20px;
        margin-right: 0px;
    `}

    ${({qrcode}) => qrcode && `
        &:hover {
            & > svg {
                fill: ${colors.neutralLight}
            }
        }
    `}
`;


const ContentTextProfile = styled.div`
    max-width: 100px;
`;