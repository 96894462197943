import PresentationBackground from 'modules/onboarding/common/PresentationBackground';
import RecoverAccountSteps from 'modules/onboarding/recover-account/RecoverAccountSteps';
import StepRecoverAccountProvider from 'context/login/StepRecoverAccount';
import React from 'react'

function RecoverAccount() {
    return (
            <StepRecoverAccountProvider>
                <PresentationBackground
                    ComponentToRender={() => <RecoverAccountSteps />}
                    is='recoverAccount'
                />
            </StepRecoverAccountProvider>
    )
}

export default RecoverAccount;