import establishmentsApi from 'api/requests/establishments';
import ButtonSelect from 'components/_UI/ButtonSelect';
import Loader from 'components/_UI/Loader';
import { SelectContainer } from 'components/_UI/SelectsContent';
import Text from 'components/_UI/Text';
import { useEstablishmentFilter } from 'context/establishments/EstablishmentFilter';
import { useEstablishmentTags } from 'context/establishments/EstablishmentTags';
import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

let categories = [];

export default function EstablishmentCategoriesSelects({ refresh, withLabel = false, squired = false }) {
    const { establishmentTags, setEstablishmentTags } = useEstablishmentTags();
    const { establishmentFilter, setEstablishmentFilter } = useEstablishmentFilter();

    const [selectedItems, setSelectedItems] = useState([]);
    const [loadCategories, setLoadCategories] = useState(false);

    useEffect(() => {
        getCategories()

        if (refresh) {
            categories = []
        }

    }, [refresh])


    async function getCategories() {
        if (establishmentTags) {
            return
        }

        setLoadCategories(true)

        try {
            const { data } = await establishmentsApi.getTags(1)
            setEstablishmentTags(data?.data)
        } catch (error) {
            // console.log('error')
        } finally {
            setLoadCategories(false)
        }
    }

    const handleSelectItem = (id) => {
        setSelectedItems(prevSelectedItems => {
            let newSelectedItems;

            if (prevSelectedItems.includes(id)) {
                newSelectedItems = prevSelectedItems.filter(item => item !== id);
            } else {
                newSelectedItems = [...prevSelectedItems, id];
            }

            setEstablishmentFilter({
                ...establishmentFilter,
                categories: newSelectedItems
            });

            return newSelectedItems;
        });
    };
    
    return (
        <Column marginTop='30px' fullyCentralized>
            <Text size='small' type='bold' paddingBottom={'5px'}> Categorias</Text>
            <Text size='small' color={colors.night} paddingBottom={'15px'}>Escolha as categorias que deseja buscar.</Text>

            <StyledList>
                {
                    loadCategories ? (
                        <FullyCentered>
                            <Loader />
                        </FullyCentered>
                    ) : (
                        establishmentTags?.map(el => (
                            <SelectItem
                                key={el.id}
                                selected={selectedItems.includes(el.id)}
                                onClick={() => handleSelectItem(el.id)}
                            >
                                <Text
                                    name='mini'
                                    textAlign={'center'}
                                    paddingBottom={0}
                                    type='bold'
                                    ellipsisAt={'98%'}
                                    color={selectedItems.includes(el.id) ? colors.neutral : colors.night}
                                >
                                    {el.name}
                                </Text>
                            </SelectItem>
                        ))
                    )
                }
            </StyledList>
        </Column>
    );
}

const StyledList = styled(Row)`
    flex-wrap: wrap;
    justify-content: space-around;
`;

const SelectItem = styled.div`
    width: 30%;
    margin-bottom: 3%;
    border: 1px solid ${colors.night};
    padding: 10px 5px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;


    @media screen and (min-width: ${breakpoints.lg}){
        width: 15%;
    }

    ${({ selected }) => selected && `
        background: #4617ad;
        border: 1px solid #4617ad;
    `};
`;
