import './services/sentry';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import featuresApi from 'api/requests/features';


window.featuresFlagEnabled = import.meta.env.VITE_FEATURE_FLAG_ENABLED
window.features = []

const isProduction = window.location.origin === import.meta.env.VITE_ORIGIN

async function getFeaturesFlags() {
  try {
    const { data} = await featuresApi.flags()
    const enabledFeatures = data.data.filter(el => el.enabled)

    enabledFeatures.map(el => {
      window.features = [...window.features, el.name]
    })
  } catch (error) {
    window.features = []
  }
}

if (window.featuresFlagEnabled && isProduction) {
    getFeaturesFlags()
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
