import React, { useEffect, useState } from 'react'
import DashSlot from './DashSlot';
import ContainerClickProvider from 'context/dash/ContainerClick';
import styled from 'styled-components';
import breakpoints from 'theme/breakpoints';
import { Column } from 'styled/alignment/Column';
import Text from 'components/_UI/Text';
import OnSelectMenuProvider from 'context/general/OnSelectMenu';
import BankAccountWarning from './BankAccountWarning';
import { useUser } from 'context/user/User';
import userApi from 'api/requests/user';
import { Row } from 'styled/alignment/Row';

export default function DashContainer({ children, title, rightSlot = () => false }) {
    const { user, setUser } = useUser();
    const [loaded, setLoaded] = useState(false);

    useEffect(async () => {
        if (!user) {
            const data = await userApi.me();
            setUser(data?.data);
        }

        setLoaded(true);
    }, []);

    return (
        <OnSelectMenuProvider>
                <ContainerClickProvider>
                    <DashSlot>
                        <StyledContainer>

                        {
                            (loaded && !user?.has_bank_account && 
                                <BankAccountWarning />
                            )
                        }

                        <ContentHeader>
                            <ContentTitle>
                                <Text name='subtitle'>{title}</Text>
                            </ContentTitle>

                            {rightSlot()}
                        </ContentHeader>

                        <WrapperContent>
                            {children}
                        </WrapperContent>

                        </StyledContainer>
                    </DashSlot>
                </ContainerClickProvider>
        </OnSelectMenuProvider>
    )
}

const StyledContainer = styled(Column)`
    @media screen and (min-width: ${breakpoints.lg}){
        padding-left: 220px;
        padding-right: 50px;
        padding-bottom: 20px;
    }  
`;

const WrapperContent = styled.div`
    padding: 10px 0;
`;


const ContentHeader = styled(Column)`
    display: flex;
    width: 100%;
    /* padding: 15px; */
    align-items: center;
    text-align: center;
    justify-content: center;
    /* padding: 10px 12px; */
    
    @media screen and (min-width: ${breakpoints.md}) {
        /* padding: 0 25px; */
        display: flex;
        margin-top: 15px;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
    
        & > h2 {
            width: 100%;
            padding-bottom: 0;
        }
    }
            

    @media screen and (min-width: ${breakpoints.lg}) {
        /* padding: 0 25px; */
        display: flex;
        /* background: red; */
        margin-top: 25px;
        flex-direction: row;
        justify-content: space-between;
        & > h2 {
            width: 100%;
        }
    }
    
    @media screen and (min-width: ${breakpoints.xl}) {
        padding: unset;

        & > h2 {
            width: 100%;
        }
    }
`;

const ContentTitle = styled.div`
    width: 100%;
    margin-bottom: 20px;
 
    & > h2 {
        width: 100%;
        padding-bottom: 0;
    }
    
    @media screen and (min-width: ${breakpoints.sm}) {
        margin-bottom: 20px;
        /* margin-left: 40px; */
        & > h2 {
            width: 100%;
            padding-bottom: 0;
        }
    }
  
    @media screen and (min-width: ${breakpoints.md}) {
      margin-bottom: unset;
    }
    
    @media screen and (min-width: ${breakpoints.xl}) {
        margin-bottom: unset;
        margin-left: 0;
        
        & > h2 {
            width: 100%;
            padding-bottom: 0;
        }
    }
`;

const ContentBanner = styled(Row)`
    margin-bottom: 20px;
    & > div {
        flex-direction: column;
    }
    & > div > div {
        margin-bottom: 20px;
    }

    @media screen and (min-width: ${breakpoints.md}) {
        & > div {
            flex-direction: row;
        }
        & > div > div {
            margin-bottom: 0;
        }
    }
`