import React from 'react';
import PropTypes from 'prop-types';
import { StepsContainer, StepsDivider, Step } from 'styled/UI/StyledSteps';

function Steps({ data, active, onStepClick }) {
    const stepClick = (e) => onStepClick(e);

    return (
        <StepsContainer>
            {data.map((el, index) => (
                <React.Fragment key={index}>
                    <Step active={index + 1 === active} onClick={(e) => stepClick(e)} />
                    {index !== (data.length - 1) && <StepsDivider />}
                </React.Fragment>
            ))}
        </StepsContainer>
    )
};

Steps.propTypes = {
    data: PropTypes.array,
    active: PropTypes.number,
};

Steps.defaultProps = {
    data: [{item: ''}]
};

export default Steps;