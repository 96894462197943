const recurrences = {
    week: [
        {name: 'Até 1 vez na semana', value: '1 time a week'},
        {name: 'Até 3 vezes na semana', value: '3 times a week'},
        {name: 'Somente fim de semana', value: 'weekend only'},
    ],
    month: [
        {name: 'Até 3 vezes no mês', value: '3 times a month'},
        {name: 'Até 5 vezes no mês', value: '5 times a month'},
        {name: 'Até 7 vezes no mês', value: '7 times a month'},
        {name: 'Mais de 7 vezes no mês', value: '7 more times a month'},
    ]
}

export default recurrences;