import api from "api";

const user = {
    create: (payload) => {
        return api(false, true).post('/users', payload);
    },
    
    dataEnable: (payload) => {
        return api(false, true).post('/users/data-enable', payload);
    },

    me: () => {
        return api().get('/users/me')
    },
    
    update: (id, payload) => {
        return api().put(`/users/${id}`, payload);
    },

    changePassword: (currentPassword, newPassword) => {
        return api().put('/users/change-new-password', {
            "current_password": currentPassword, 
            "new_password": newPassword 
        })
    },
    
    delete: (id) => {
        return api().delete(`/users/${id}`);
    },
};
 
export default user;  