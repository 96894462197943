import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import date from 'helpers/date';
import types from 'helpers/system/types';
import React from 'react'
import { Calendar } from 'react-feather';
import styled, { css } from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import eventTypes from 'theme/eventTypes';
import fonts from 'theme/fonts';

export default function EventCard({ data, onClick = () => false }) {
    const formatDate = dateContent => date.format(dateContent)

    return (
        <ContainerCard onClick={onClick}>
            <ContentImage>
                {data?.images?.length ? (
                    <CardImage src={data?.images[0]} />
                ) : (
                    <CardTextWrapper category={data?.classification?.name}>
                        <Text
                            color={colors.neutralLight}
                            type='bold'
                            name='title'
                        >
                            {data?.name?.charAt(0).toUpperCase()}
                        </Text>
                    </CardTextWrapper>
                )
                }
            </ContentImage>

            <ContentData>
                <StyledRow>
                    <Text
                        name='small'
                        type='bold'
                        paddingBottom={'0'}
                        paddingTop={'5px'}
                        ellipsisAt={'95%'}
                        color={colors.secondaryPurple}
                    >
                        {data?.name}
                    </Text>
                </StyledRow>
                <StyledRow categories>

                    {
                        !!data?.dates?.length && (
                            <RowDates>
                                {/* <ContentIconContainer>
                            <Icon color={colors.night} name='calendar' />
                        </ContentIconContainer> */}
                                <Calendar color={colors.primaryDashboard} size={16} />
                                <Text
                                    name='small'
                                    color={colors.primaryDashboard}
                                    paddingBottom={'0'}
                                    marginLeft='4px'
                                >
                                    {formatDate(data?.dates[0]?.date)}
                                </Text>
                            </RowDates>

                        )
                    }
                    <ContentClassification>
                        <EventType type={data?.classification?.name}>
                            <Text name='mini' color={colors.neutral} paddingBottom={'0'}>

                                {types.getBySlug(data?.classification?.name)?.label}
                            </Text>
                        </EventType>
                    </ContentClassification>
                </StyledRow>
            </ContentData>
        </ContainerCard>
    )
}

const ContainerCard = styled(Column)`
    border: 1px solid ${colors.primaryDashboard};
    border-radius: 4px;
    margin-top: 5px;
    height: 110px;
    cursor: pointer;
    position: relative;
    @media screen and (min-width: 375px) {
        display: flex;
        flex-direction: row;
        height: 65px;
        position: relative;
    }
`
const ContentImage = styled(Column)`
    display: flex;
    align-items: center;
    width: 100%;
    height: 65px;
    justify-content: center;
    @media screen and (min-width: 375px) {
        width: 25%;
        height: unset;
    }
`;

const ContentData = styled(Column)`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 4px;
    @media screen and (min-width: 375px) {
        width: 75%;
    }
`;

const StyledRow = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 0 4px 5px;
    position: relative;
    ${({ categories }) => categories && `
        width: 100%;
        display: flex;
        padding-bottom: 5px;
        justify-content: space-between;
        align-items: center;
    `}
`;


const EventType = styled.div`
    width: 45px;
    display: flex;
    flex-direction: row;
    color: ${colors.neutralLight};
    border-radius: 30px;
    padding: 4px 10px;
    font-size: ${fonts.sizes.small};
    position: absolute;
    bottom: 3px;
    justify-content: center;
    ${({ type }) => type === eventTypes.free.slug && `
        background: ${eventTypes.free.color};
    `}

    ${({ type }) => type === eventTypes.paid.slug && `
        background: ${eventTypes.paid.color};
        width: 45px;
    `}
`;
const ContentClassification = styled(Column)`
    display: flex;
    align-items: center;
    width: auto;
    padding-right: 30px;

`;

const RowDates = styled(Row)`
    display: flex;
    align-items: center;
`;

const ContentIconContainer = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CardTextWrapper = styled(FullyCentered)`
    text-align: center;
    height: 100%;

    & > h1 {
        padding-bottom: 0;
    }

    ${({ category }) => category === 'free' && css`
        background: ${eventTypes.free.color};
    `};

    ${({ category }) => category === 'private' && css`
        background: ${eventTypes.paid.color};
    `};

`;

const CardImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;