import Text from 'components/_UI/Text';
import { useCheckout } from 'context/checkout/Checkout';
import currency from 'helpers/currency';
import React, { useState, useEffect } from 'react';
import { MinusCircle, PlusCircle } from 'react-feather';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function TicketItem({ data, selectedTickets, setSelectedTickets, couponTrigger, onChangeTotalValue }) {
    const [quantity, setQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const { checkout, setCheckout } = useCheckout();

    // Função para calcular a quantidade de itens selecionados por id
    const calculateQuantityFromSelectedTickets = (id) => {
        return selectedTickets.filter(ticket => ticket.id === id).length;
    };

    const incrementQuantity = () => {
        if (quantity < data.quantity && quantity < 10) {
            setQuantity(prev => prev + 1);

            // Adiciona uma nova instância do ticket completo ao selectedTickets
            setSelectedTickets(prevSelectedTickets => [
                ...prevSelectedTickets,
                data
            ]);
        }
    };

    const decrementQuantity = () => {
        if (quantity > 0) {
            setQuantity(prev => prev - 1);

            // Remove apenas uma instância do ticket correspondente
            setSelectedTickets(prevSelectedTickets => {
                const ticketIndex = prevSelectedTickets.findIndex(ticket => ticket.id === data.id);
                if (ticketIndex > -1) {
                    const updatedTickets = [...prevSelectedTickets];
                    updatedTickets.splice(ticketIndex, 1); // Remove uma instância do ingresso
                    return updatedTickets;
                }
                return prevSelectedTickets;
            });
        }
    };

    useEffect(() => {
        const updatedTotalPrice = data.price * quantity;
        const updatedTotalOriginalPrice = Number(data.original_price) * quantity;

        // Calcula a mudança no preço (pode ser positiva ou negativa)
        const priceChange = updatedTotalPrice - totalPrice;
        const originalPriceChange = updatedTotalOriginalPrice - totalPrice;

        setTotalPrice(updatedTotalPrice);

        onChangeTotalValue(priceChange, originalPriceChange);

        if (quantity === 0) {
            setCheckout(prevCheckout => ({
                ...prevCheckout,
                batches: prevCheckout?.batches?.filter(item => item.id !== data.id) || []
            }));
        } else {
            setCheckout(prevCheckout => {
                const existingBatch = prevCheckout?.batches?.find(item => item.id === data.id);
                if (existingBatch) {
                    // Atualiza a quantidade do item existente
                    return {
                        ...prevCheckout,
                        batches: prevCheckout.batches.map(item =>
                            item.id === data.id
                                ? { ...item, quantity: quantity }
                                : item
                        )
                    };
                } else {
                    return {
                        ...prevCheckout,
                        batches: [...prevCheckout.batches, { id: data.id, quantity: quantity }]
                    };
                }
            });
        }
    }, [quantity, data.original_price]);

    // useEffect para lidar com o couponTrigger
    useEffect(() => {
        if (couponTrigger) {
            // Atualiza a quantidade conforme o selectedTickets
            const newQuantity = calculateQuantityFromSelectedTickets(data.id);
            setQuantity(newQuantity);
        }
    }, [couponTrigger, selectedTickets, data.id]);

    return (
        !!data?.is_active && (
            <TicketItemContainer>
                <Column>
                    <Text type='bold' marginBottom={'5px'}>
                        {data.name}
                    </Text>

                    <Column width='80%'>
                        {
                            Number(data?.original_price) === Number(data?.price) &&
                            <Text color={colors.night} marginBottom={'5px'}>
                                {data?.original_price != 0 ? currency.format(data?.original_price) : 'Gratuito'}
                            </Text>
                        }
                        {
                            Number(data?.original_price) !== Number(data?.price) &&
                            <Row marginBottom={'5px'}>
                                <Text
                                    color={colors.nightLight}
                                    marginRight={'.5rem'}
                                    decoration={'line-through'}
                                >
                                    {currency.format(data?.original_price)}
                                </Text>
                                <Text color={colors.success}>
                                    {currency.format(data?.price)}
                                </Text>
                            </Row>
                        }
                        {
                            data?.description && (
                                <Text color={colors.primaryLight} type='bold' marginBottom={'5px'}>
                                    {data?.description}
                                </Text>
                            )
                        }
                        {
                            data.quantity <= 15 &&
                            <Text color={colors.occasionalOrange} name='small' paddingBottom={0}>
                                Restam poucos ingressos
                            </Text>
                        }
                    </Column>
                </Column>

                <QuantityContainer>
                    <ToggleValuesContainer>
                        <MinusCircle
                            style={{ cursor: 'pointer' }}
                            color={colors.night}
                            size={20}
                            onClick={decrementQuantity}
                        />

                        <Text marginLeft={'6px'} marginRight={'6px'} color={colors.night} paddingBottom={0}>
                            {quantity}
                        </Text>

                        <PlusCircle
                            style={{
                                pointerEvents: quantity === 10 ? 'none' : 'auto',
                                cursor: quantity === 10 ? 'not-allowed' : 'pointer'
                            }}
                            color={quantity === 10 ? colors.nightLight : colors.night}
                            size={20}
                            onClick={incrementQuantity}
                        />
                    </ToggleValuesContainer>
                    <Text type='bold' color={colors.night} paddingBottom={0}>
                        R$ {totalPrice.toFixed(2)}
                    </Text>
                </QuantityContainer>
            </TicketItemContainer>
        )
    );
}

// Componentes estilizados
const TicketItemContainer = styled(Row)`
    margin-top: 20px;
    padding: 14px 0;
    align-items: center;
    border-bottom: 1px solid ${colors.nightLight};

    ${({ stoped }) => stoped && `
        opacity: .5;
    `};

    @media screen and (min-width: ${breakpoints.lg}) {
        border-bottom: none;
        border-top: 1px solid ${colors.nightLight};
        margin-top: 0;
        
        &:first-child {
            border-top: none;
        }
    }
`;

const QuantityContainer = styled(Column)`
    flex-direction: column;
    width: auto;
    align-items: center;
    width: 100px;
`;

const ToggleValuesContainer = styled(Row)`
    margin-bottom: 10px;
    width: auto;
`;
