import React from 'react'
import colors from 'theme/colors';
import Text from './Text';
import styled from 'styled-components';

function LinkPage({
    children,
    to,
    target,
    color,
    size,
    isWrapper
}) {
    return (
        isWrapper ? (
            <LinkContainer href={to} target={target} isWrapper>{children}</LinkContainer>
        ) : (
            <LinkContainer href={to} target={target} >
                <Text color={color} name={size}>
                    {children}
                </Text>
            </LinkContainer>
        )
    )
};

LinkPage.defaultProps = {
    size: 'small',
    target: '_blank',
    color: colors.primaryLight
}

const LinkContainer = styled.a`
    cursor: pointer;

    ${({isWrapper}) =>  isWrapper && `
        width: 100%;
    `};
`;

export default LinkPage;