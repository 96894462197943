import React, { useEffect, useState } from 'react'
import EventItem from './EventItem'
import styled from 'styled-components'
import { Row } from 'styled/alignment/Row'
import breakpoints from 'theme/breakpoints'
import colors from 'theme/colors'
import Text from 'components/_UI/Text'
import { Column } from 'styled/alignment/Column'
import { useContainerClick } from 'context/dash/ContainerClick'
import fonts from 'theme/fonts'
import { FullyCentered } from 'styled/alignment/Center'
import Button from 'components/_UI/Button'
import { useShowAllEvents } from 'context/dash/ShowAllEvents'
// import DeleteEvent from './delete/DeleteEvent'

export default function ListEventItems({ title, data, type, expired, onEventClick, onDropdownAction, dropdownLoading }) {
    const [selected, setSelected] = useState(false)

    const { showAllEvents, setShowAllEvents } = useShowAllEvents()
    const { containerClick } = useContainerClick()

    useEffect(() => {
        if (containerClick) {
            setSelected(false)
        }
    }, [containerClick])

    return (
        <Container>
            <Content>
                <ContentMain>
                    <Text name='subtitle'>{title}</Text>
                </ContentMain>

                <ListContainer>
                    <ListContent>
                        <Column>
                            <Row>
                                {data && data?.items?.data?.map((el, index) => (
                                    <EventItem
                                        isLoading={dropdownLoading}
                                        expired={expired}
                                        key={index} data={el}
                                        dropDownVisible={index === selected}
                                        onEventClick={() => {
                                            onEventClick(el)
                                            setSelected(index)
                                        }}
                                        onMoreClick={() => setSelected(index)}
                                        onDropdownAction={e => { onDropdownAction({ action: e, ...el }) }}
                                    />
                                ))}

                                {
                                    data?.total > 10 && (
                                        <ShowMore
                                            width='240px'
                                            onClick={() => setShowAllEvents({
                                                type,
                                                title,
                                                visible: true,
                                                data: data?.items?.data,
                                                total: data?.total
                                            })}
                                        >
                                            <Text name='highlight' color={colors.primary}>Mostrar todos</Text>
                                        </ShowMore>
                                    )
                                }
                            </Row>
                        </Column>
                    </ListContent>
                </ListContainer>
            </Content>
        </Container>

    )
}
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: ${breakpoints.sm}) {
        width: 100%;
    }
`;

const Content = styled.div`
    background: ${colors.neutralLight};
    padding: 15px;
    border-radius: 8px;
    margin-top: 15px;
    width: 100%;
    @media screen and (min-width: ${breakpoints.sm}) {
        width: 100%;
        border-radius: 16px;
    }

    @media screen and (min-width: ${breakpoints.xl}) {
        width: 100%;
        border-radius: 16px;
    }

`;
const ListContainer = styled(Row)`
    overflow-y: auto;
    overflow-x: unset;
    @media screen and (min-width: ${breakpoints.lg}) {
        margin-top: 0;
    }
`;

const ListContent = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
`;

const ContentMain = styled(Row)`
    padding: 15px 15px 0 15px;
`;

const ShowMore = styled(FullyCentered)`
    border-radius: 12px;
    box-shadow: 0px 1px 4px ${colors.night};
    height: 220px;
    margin-top: 5px;
    margin-left: 5px;
    cursor: pointer;
    transition: all ease-in-out .25s;

    &:hover {
        box-shadow: 0 6px 12px ${colors.nightLight};
    }
`;



