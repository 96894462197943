import React, { useState } from 'react'
import Modal from "components/_UI/Modal";
import styled from "styled-components";
import colors from "theme/colors";
import { FullyCentered } from "styled/alignment/Center";
import masks from 'helpers/masks';
import Button from "components/_UI/Button";
import Text from 'components/_UI/Text';
import sizes from 'theme/sizes';
import Fieldset from 'components/_UI/Fieldset';

export function PersonalInfoDialog ({ open, setOpen }) {
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [birthday, setBirthday] = useState('')
    const [gender, setGender] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    return (
        <Container>
            <Modal isOpen={open} onClose={() => setOpen(false)}>
                <Text
                    name='title'
                    marginBottom={sizes.medium}
                >
                    Configurações
                </Text>

                <Text
                    type='bold'
                    textAlign='left'
                >
                    Informações pessoais
                </Text>

                <Fieldset
                    marginBottom={sizes.normal}
                    placeholder='Nome'
                    onChangeValue={(e) => setName(e)}
                ></Fieldset>
                <Fieldset
                    marginBottom={sizes.normal}
                    placeholder='Data de nascimento'
                    mask={masks.date}
                    onChangeValue={(e) => setBirthday(e)}
                ></Fieldset>
                <Fieldset
                    marginBottom={sizes.large}
                    placeholder='Gênero'
                    onChangeValue={(e) => setGender(e)}
                ></Fieldset>

                <Text
                    type='bold'
                    textAlign='left'
                >
                    Informações de contato
                </Text>

                <Fieldset
                    marginBottom={sizes.normal}
                    placeholder='Email'
                    onChangeValue={(e) => setEmail(e)}
                ></Fieldset>
                <Fieldset
                    marginBottom={sizes.normal}
                    placeholder='Telefone'
                    mask={masks.phone}
                    onChangeValue={(e) => setPhone(e)}
                ></Fieldset>

                <Button>Confirmar</Button>
                <Button
                    variant='secondary'
                    onClick={() => setOpen(false)}
                >
                    Cancelar
                </Button>
            </Modal>
        </Container>
    )
}

const Container = styled(FullyCentered)`
  width: 100%;
  height: 100vh;
  background: ${colors.neutralDark};
`
