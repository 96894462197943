import api from "api";


const tickets = {
    get: () => {
        return api().get('/users/wallet')
    },
    
    checkIn: (payload) => {
        return api().post('/checkin', payload)
    }, 
    
    create: (payload) => {
        return api().post('/products', payload)
    }, 

    createBulk: (payload) => {
        return api().post('/products/bulk', payload)
    }, 

    updateBulk: (payload) => {
        return api().put('/products/bulk', payload)
    }, 

    deleteBulk: (payload) => {
        return api().delete('/products/delete/bulk', payload)
    },

    getTicketForEvent: (slug) => {
        return api().get(`/events/detail/${slug}/tickets`)
    },
    
    removeDraft: (slug) => {
        return api().post(`/events/${slug}/finish`)
    }
}

export default tickets;