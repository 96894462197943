import Loader from 'components/_UI/Loader'
import DetailVisibleProvider from 'context/dash/DetailVisible'
import ListProductsProvider from 'context/products/ListProducts'
import DashContainer from 'modules/dashboard/_common/DashContainer'
import MainContent from 'modules/dashboard/resume/MainContent'
import React from 'react'

export default function DashResume() {
 
  return (
    <DetailVisibleProvider>
      <ListProductsProvider>
        <DashContainer title={`Bem vindo!`}>
          <MainContent />
        </DashContainer>
      </ListProductsProvider>
    </DetailVisibleProvider>
  )
}
