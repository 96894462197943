import Text from 'components/_UI/Text';
import NotResultDashBoard from 'components/commons/NotResultDashBoard';
import date from 'helpers/date';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';
import colors from 'theme/colors';

const ClicksStatistics = ({ clicks }) => {

    const formattedDates = clicks?.map(el => `${date.getMounthName(el.month.toString())} - ${el.year}`);
    const views = clicks?.map(el => el.views);
    
    const data = {
        labels: formattedDates,
        datasets: [
            {
                label: 'Visitas',
                data: views,
                backgroundColor: colors.occasionalPurple,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <Container>
            {clicks?.length ? (
                <>
                    <HighlightText>Visitas ao perfil</HighlightText>
                    <Bar data={data} options={options} />
                </>
            ) : (
                <NotResultDashBoard 
                ilustration={'views'}
                text={
                    <>
                        <Text name='highlight'>
                            Ainda não há visitas no perfil do seu estabelecimento.
                        </Text>
                        <Text textAlign={'center'} marginTop={'5px'}>Assim que você começar a receber visitas, o gráfico de visitas será exibido aqui, 
                            permitindo que você acompanhe o desempenho do seu perfil em tempo real.</Text>
                    </>
                }
            />
            )}
        </Container>
    );
};

const Container = styled.div`
    background: ${colors.neutralLight};
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 15px;
    margin-top: 20px;
`;

const HighlightText = styled.h2`
    color: ${colors.occasionalPurple};
    margin-bottom: 20px;
`;

export default ClicksStatistics;
