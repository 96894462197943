import styled from 'styled-components';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import marginProps from 'styled/shared-props/margin';
import colors from 'theme/colors';

export const Container = styled(Row)`  
  width: 100%;
  border-radius: 4px;
  /* padding: 30px; */
  background: ${colors.neutralLight};
  display: flex;
  align-items: center;
  justify-content: center;
  ${marginProps};
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
`;

export const IlustrationContainer = styled.picture`
  display: none;
  width: auto;
  height: 300px;
  margin-right: ${props => (props.reverse ? '0px' : '40px')};
  margin-left: ${props => (props.reverse ? '0px' : '40px')};
  @media screen and (min-width: ${breakpoints.sm}) {
      display: flex;
  }
`;

export const ContentNotResult = styled.div`
  width: 100%;
  min-height: 200px;
  max-height: auto;
  padding: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  ${({ minimal }) => minimal && `
    width: 80%;
  `};

  @media screen and (min-width: ${breakpoints.sm}) {
      width: 35%;

      ${({ minimal }) => minimal && `
        width: 60%;
        height: 100%;
      `};
  }
`;

export const ContentButton = styled.div`
    width: 100%;
    cursor: pointer;
    & > button {
        margin-bottom: 0;
    }

    @media screen and (min-width: ${breakpoints.md} ){
      width: 100%;
    }
`;
