import styled, { css } from 'styled-components';
import color from 'theme/colors';
import sizes from 'theme/sizes';
import fonts from 'theme/fonts';
import InputMask from "react-input-mask";
import marginProps from 'styled/shared-props/margin';
import paddingProps from 'styled/shared-props/padding';
import { FullyCentered } from 'styled/alignment/Center';
import indexPriority from 'theme/z-index';
import colors from 'theme/colors';

export const StyledForm = styled.form`
    width: 100%;
    ${marginProps};
    ${paddingProps};
`;

export const StyledFieldset = styled.div`
    width: 100%;
    position: relative;
    text-align: left;
    ${marginProps};
    ${paddingProps};
`;

export const StyledInput = styled(InputMask)`
    width: 100%;
    outline-color: transparent;
    transition: all ease .25s;
    background: transparent;
    outline: transparent;
    color: ${color.night};

    &::placeholder {
        color: ${color.nightLight};
    }

    ${({ icon }) => icon && css`
        padding-left: ${sizes.large} !important;
    `}

    ${({ textIndent }) => textIndent && css`
        text-indent: ${textIndent};
        
        &::placeholder {
            text-indent: ${textIndent};
        }
    `}

    /*=========================*/
    /*          Layout         */
    /*=========================*/
    ${({ layout }) => layout === 'straight' && css`
        border: none;
        border-bottom: ${sizes.quark} solid ${color.nightLight};
        padding: ${sizes.quark};
        font-weight: ${fonts.weight.bold};
        
        &::placeholder {
            font-weight: ${fonts.weight.regular};
        }

        &:focus {
            border-bottom: 2px solid ${color.night}
        }
    `}

    ${({ layout, disabled }) => layout === 'squired' && disabled && css`
        background: transparent;
        border: 1px solid ${colors.nightLight};
        padding: 10px;
        pointer-events: none;
        border-radius: 4px;
        margin-top: 5px;
        opacity: .8;
        pointer-events: none;
    `}
    
    ${({ layout }) => layout === 'squired' && css`
        background: transparent;
        border: 1px solid ${colors.nightLight};
        padding: 10px;
        border-radius: 4px;
        margin-top: 5px;

        &:focus {
            border: 1px solid ${color.night}
        }
    `}

    ${({ layout, disabled }) => layout === 'straight' && disabled && css`
        background: transparent;
        font-weight: ${fonts.weight.bold};
        pointer-events: none;
    `}

    ${({ layout }) => layout === 'straight-center' && css`
        border: none;
        border-bottom: ${sizes.quark} solid ${color.nightLight};
        padding: ${sizes.quark};
        font-weight: ${fonts.weight.bold};
        text-align: center;
        color: ${color.neutralDark};
        
        &::placeholder {
            font-weight: ${fonts.weight.regular};
            text-align: center;
            color: ${color.nightLight};
        }

        &:focus {
            border-bottom: 2px solid ${color.neutralDark}
        }
    `}

    ${({ layout }) => layout === 'straight-center-dark' && css`
        border: none;
        border-bottom: ${sizes.quark} solid ${color.nightLight};
        padding: ${sizes.quark};
        font-weight: ${fonts.weight.bold};
        text-align: center;
        color: ${color.night};
        
        &::placeholder {
            font-weight: ${fonts.weight.regular};
            text-align: center;
            color: ${color.night};
        }

        &:focus {
            border-bottom: 2px solid ${color.nightLight}
        }
    `}

    ${({ layout }) => layout === 'rounded' && css`
        border: 2px solid #ccc;
        border-radius: 30px;
        padding: ${sizes.mini};
        font-weight: ${fonts.weight.bold};
        background: transparent;
        color: ${color.nightDark};

        &:focus {
            border: ${sizes.quark} solid ${color.night};
        }
    `}

    ${({ layout }) => layout === 'roundedBorded' && css`
        border: 1px solid ${color.nightLight};
        border-radius: 30px;
        padding: ${sizes.mini};
        font-weight: ${fonts.weight.bold};
        background: ${color.neutralLight};
        color: ${color.nightDark};

        &:focus {
            border: ${sizes.quark} solid ${color.night};
        }
    `}

   
    
    ${({ layout }) => layout === 'roundedDark' && css`
        border: 2px solid ${color.occasionalGrey};
        border-radius: 30px;
        padding: ${sizes.mini};
        font-weight: ${fonts.weight.bold};
        background: ${color.occasionalGrey};
        color: ${color.night};
    `}

    ${({ layout, disabled }) => layout === 'rounded' && disabled && css`
        border: ${sizes.quark} solid ${color.nightLight};
        border-radius: 30px;
        padding: ${sizes.mini};
        font-weight: ${fonts.weight.bold};
        background: transparent;
        color: ${color.nightDark};
    `}
`;

export const StyledLabel = styled.label`
    color: ${color.nightDark};
    font-size: ${fonts.sizes.small};
    font-weight: ${fonts.weight.bold};
    `;

export const StyledBadgeText = styled.div`
    position: absolute;
    font-weight: ${fonts.weight.bold};
    font-size: ${fonts.sizes.small};
    color: ${color.nightLight};
    
    ${({ layout, position }) => (layout === 'rounded' || layout === 'roundedDark' || layout === 'roundedBorded') && position === 'right' && css`
        right: ${sizes.little};;
        bottom: ${sizes.intermediary};
    `}

    ${({ layout, position }) => layout === 'straight' && position === 'right' && css`
        right: ${sizes.little};
        bottom: ${sizes.small};
    `}

    ${({ layout, position }) => (layout === 'rounded' || layout === 'roundedDark' || layout === 'roundedBorded') && position === 'left' && css`
        left: ${sizes.quark};
        bottom: ${sizes.intermediary};
    `}

    ${({ layout, position }) => layout === 'straight' && position === 'left' && css`
      
    left: ${sizes.quark};
        bottom: ${sizes.small};
    `}
    
    ${({ clickable }) => clickable && css`
        cursor: pointer;
        color: ${color.occasionalPurple};
    `}
`

export const StyledBadgeIcon = styled(FullyCentered)`
    position: absolute;
    height: ${sizes.large};
    width: ${sizes.large};
    background: ${props => props.bgColor};
    border-radius: 30px;
    z-index: ${indexPriority.normal};

    ${({ clickable }) => clickable && css`
        cursor: pointer;
    `}

    ${({ layout, position }) => (layout === 'straight' || layout === 'straight-center-dark' || layout === 'straight-center') && position === 'left' && css`
        bottom: ${sizes.tiny};
        left: ${sizes.none};
        padding: ${sizes.micro} ${sizes.nano} ${sizes.nano} ${sizes.none};
    `}

    ${({ layout, position }) => (layout === 'rounded' || layout === 'roundedDark' || layout === 'roundedBorded') && position === 'left' && css`
        bottom: ${sizes.little};
        left: ${sizes.micro};
        padding: ${sizes.micro};
    `}

    ${({ layout, position }) => (layout === 'straight' || layout === 'straight-center-dark' || layout === 'straight-center') && position === 'right' && css`
        bottom: ${sizes.tiny};
        right: ${sizes.none};
        padding: ${sizes.micro} ${sizes.nano} ${sizes.nano} ${sizes.none};
    `}

    ${({ layout, position }) => (layout === 'rounded' || layout === 'roundedDark' || layout === 'roundedBorded') && position === 'right' && css`
        bottom: ${sizes.little};
        right: ${sizes.micro};
        padding: ${sizes.micro};
    `}
`

StyledFieldset.defaultProps = {
    paddingBottom: sizes.mini
}