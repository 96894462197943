import React, { createContext, useState, useContext } from 'react';

const BackButtonCollapseContext = createContext();

export default function BackButtonCollapseProvider({ children }) {
    const [backButtonCollapse, setBackButtonCollapse] = useState({visible: false});

    return (
        <BackButtonCollapseContext.Provider value={{ backButtonCollapse, setBackButtonCollapse }}>
            {children}
        </BackButtonCollapseContext.Provider>
    );
}

export function useBackButtonCollapse() {
    const context = useContext(BackButtonCollapseContext);

    if (!context) throw new Error('useBackButtonCollapse must be used within a BackButtonCollapseProvider');

    const { backButtonCollapse, setBackButtonCollapse } = context;
    return { backButtonCollapse, setBackButtonCollapse };
}