import React, { createContext, useState, useContext } from 'react';

const EstablishmentTagsContext = createContext();

export default function EstablishmentTagsProvider({ children }) {
    const [establishmentTags, setEstablishmentTags] = useState(null);

    return (
        <EstablishmentTagsContext.Provider value={{ establishmentTags, setEstablishmentTags }}>
            {children}
        </EstablishmentTagsContext.Provider>
    );
}

export function useEstablishmentTags() {
    const context = useContext(EstablishmentTagsContext);

    if (!context) throw new Error('useEstablishmentTags must be used within a EstablishmentTagsProvider');

    const { establishmentTags, setEstablishmentTags } = context;
    return { establishmentTags, setEstablishmentTags };
}