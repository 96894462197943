import paths from './paths';
import Login from 'screens/Login';
import RecoverAccount from "screens/RecoverAccount";
import Register from "screens/Register";
import UserPartner from 'screens/user/partner/UserPartner';


export default [
    {
        exact: true,
        path: paths.login,
        element: <Login />
    },
    {
        exact: true,
        path: paths.recoverAccount,
        element: <RecoverAccount />
    },
    {
        exact: true,
        path: paths.register,
        element: <Register />
    },
    {
        exact: true,
        path: paths.partner.createAccount,
        element: <UserPartner/>
    },
    {
        exact: true,
        path: paths.recoverAccount,
        element: <RecoverAccount />
    },
]