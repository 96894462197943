import Divider from 'components/_UI/Divider';
import Icon from 'components/_UI/Icon';
import LinkPage from 'components/_UI/LinkPage';
import Modal from 'components/_UI/Modal';
import Text from 'components/_UI/Text';
import DynamicProfileBackground from 'components/commons/DynamicProfileBackground';
import DynamicProfileImage from 'components/commons/DynamicProfileImage';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import eventTypes from 'theme/eventTypes';
import fonts from 'theme/fonts';
import HoursList from '../items/HoursList';
import Contact from '../items/Contact';
import Events from '../items/Events';
import EstablishmentServicesOptions from './EstablishmentServicesOptions';
import establishmentTypes from 'theme/establishmentTypes';
import socialMedia from 'theme/socialMedia';
import { Phone, Clock, Calendar, BookOpen, MapPin, } from 'react-feather';
import establishmentsApi from 'api/requests/establishments';
import InfoTooltip from 'components/_UI/InfoTooltip';
import { useInvisibleButton } from 'context/general/InvisibleButton';

export default function CardProfile({
  data,
  nextEvents,
  nowEvents,
  onEventClick,
  isLoading,
  withBackButton,
  fullWidth = false
}) {

  const [openModal, setOpenModal] = useState(false);
  const [selectedAction, setSelectAction] = useState(false);
  const [options, setOptions] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const { invisibleButton, setInvisibleButton } = useInvisibleButton()

  useEffect(() => {
    getOptions()
  }, []);

  function renderAction(action) {
    if (action == 'contact') {
      setOpenModal(true)
      setSelectAction('contact')
      setInvisibleButton(true)
    }
    if (action == 'hoursList') {
      setOpenModal(true)
      setSelectAction('hoursList')
      setInvisibleButton(true)
    }
    if (action == 'events') {
      setOpenModal(true)
      setSelectAction('events')
      setInvisibleButton(true)
    }
  }

  async function getOptions() {
    setLoadingOptions(true);
    try {
      const { data } = await establishmentsApi.getOptions();
      setOptions(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOptions(false);
    }
  }

  function onCloseModal() {
    setOpenModal(false)
    setInvisibleButton(false)
  }


  return (
    <Container fullWidth={fullWidth}>
      <ContentThumb>
        <DynamicProfileBackground
          img={data?.backgroundImage.path}
          size={'160px'}
        />
        <CardImage status={data?.open}>
          <StablishmentStatus status={data?.open}>{data?.open ? 'Aberto' : 'Fechado'}</StablishmentStatus>
          <DynamicProfileImage
            img={data?.profileImage?.path}
            size={'80px'}
            word={data?.name}
          />
        </CardImage>
      </ContentThumb>

      <BasicInfo>
        <Row fullyCentralized marginTop={'30px'} paddingBottom='12px'>
          <Text name='subtitle' paddingBottom={0}>
            {data?.name}
          </Text>
          {
            !!data?.verified && (
              <InfoTooltip
                type={'none'}
                text={'Estabelecimento verificado'}
                bgColor={colors.night}
              >
                <VerifieldIcon >
                  <Icon name='verifield' color={colors.primaryLight} />
                </VerifieldIcon>
              </InfoTooltip>
            )
          }
        </Row>
        <Text textAlign={'center'}>{data?.description}</Text>


        <ContentCategories>
          {
            <SpanCategories bgColor={establishmentTypes?.free?.id == data.classification.id ? establishmentTypes.free.color : establishmentTypes.paid.color}>
              {establishmentTypes.free.id == data?.classification?.id ? establishmentTypes.free.label : establishmentTypes.paid.label}
            </SpanCategories>
          }
          {
            data?.adulthood && <SpanCategories adulthood>+18</SpanCategories>
          }


          {data?.tagsEstablishments?.map(el => (
            <SpanCategories>
              {el?.name}
            </SpanCategories>
          ))}


        </ContentCategories>

        <ContainerAddress>
          <ContentIcon>
            <MapPin size={'18px'} color={colors.primaryDashboard} />
          </ContentIcon>

          <LinkPage
            to={data?.link_map}
          >
            <Text color={colors.primaryDark} decoration='underline' >
              {data?.address?.name && `${data?.address?.name},`} {data?.address?.district && `Bairro ${data?.address?.district}`}
              {data?.address?.number && `, nº ${data?.address?.number}`}
              {data?.address?.complement && ` - ${data?.address?.complement}`}
            </Text>
          </LinkPage>
        </ContainerAddress>
        <Divider opacity={0.3} />

        <ContentGroup items>
          {
            <Items
              rounded
              onClick={() => renderAction('hoursList')}
            >
              <ContentIcon rounded>
                <Clock size={'24px'} color={colors.neutralLight} />
              </ContentIcon>
              <Text name='small' ellipsisAt={'95%'} marginTop={'8px'} color={colors.night}>
                Horários
              </Text>
            </Items>
          }

          {
            data?.menuImage?.path && (
              <Items rounded to={data?.menuImage?.path} target='_blank'>
                <ContentIcon rounded>
                  <BookOpen size={'24px'} color={colors.neutralLight} />
                </ContentIcon>
                <Text name='small' ellipsisAt={'95%'} marginTop={'8px'} color={colors.night}>
                  Cardápio
                </Text>
              </Items>
            )
          }
          {
            data?.delivery_url && (
              <Items rounded to={data?.delivery_url} target='_blank'>
                <ContentIcon delivery>
                  <Icon color={colors.neutralLight} name='delivery' />
                </ContentIcon>
                <Text name='small' ellipsisAt={'95%'} marginTop={'8px'} color={colors.night}>
                  Delivery
                </Text>
              </Items>
            )
          }

          {
            (data?.events?.now?.length > 0 || data?.events.next?.length > 0) &&
            <Items
              rounded
              onClick={() => renderAction('events')}
            >
              <ContentIcon rounded>
                <Calendar size={'24px'} color={colors.neutralLight} />
              </ContentIcon>
              <Text name='small' ellipsisAt={'95%'} marginTop={'8px'} color={colors.night}>
                Eventos
              </Text>
            </Items>
          }

          {
            data?.phone && (
              <Items
                onClick={() => renderAction('contact')}
                rounded
              >
                <ContentIcon rounded>
                  <Phone color={colors.neutralLight} size={'24px'} />
                </ContentIcon>
                <Text name='small' ellipsisAt={'95%'} marginTop={'8px'} color={colors.night}>
                  Contato
                </Text>
              </Items>
            )
          }
        </ContentGroup>


        {
          !!data?.features?.length && (
            <Column>
              <Divider opacity={0.3} />
              <Text
                textAlign={'center'}
                type='bold'
                marginBottom={'5px'}
              >
                Características
              </Text>
              <ContentGroup features fullWidth={fullWidth} >
                {
                  data?.features.map((el) => (
                    <Items features key={el.id} fullWidth={fullWidth}>
                      <ContentIcon>
                        <Icon color={colors.night} name={el?.icon} />
                      </ContentIcon>
                      <Text ellipsisAt={'95%'} name='mini' marginTop={'8px'} color={colors.night}>{el?.name}</Text>
                    </Items>
                  ))

                }
              </ContentGroup>
            </Column>
          )
        }

        {

          !!data?.serviceOptions?.length && options?.serviceOptions?.length &&
          <>
            <Divider opacity={0.3} />

            <EstablishmentServicesOptions
              fullWidth={fullWidth}
              data={data?.serviceOptions}
              content={options?.serviceOptions}
              loading={loadingOptions}
            />
          </>
        }

        {
          !!data?.socialMedia?.length && (
            <>
              <Divider opacity={0.3} />
              <Text
                textAlign={'center'}
                type='bold'
                marginBottom={'5px'}
              >
                Redes socias
              </Text>
              <ContentGroup socialMedia fullWidth={fullWidth}>
                {data?.socialMedia.map((el) => {
                  const matchedSocialMedia = socialMedia.find(sm => sm.name === el?.name?.toLowerCase());
                  const bgColor = matchedSocialMedia ? matchedSocialMedia.color : undefined;
                  return (
                    <Items
                      to={el.url}
                      target="_blank"
                      key={el.id}
                      socialMedia
                      fullWidth={fullWidth}
                    >
                      <SocialIcon bgColor={bgColor}>
                        <Icon name={el?.name && matchedSocialMedia?.icon} color={bgColor} />
                      </SocialIcon>
                      <Text
                        ellipsisAt="95%"
                        name="mini"
                        type="bold"
                        marginTop="8px"
                        color={bgColor}
                      >
                        {el.name}
                      </Text>
                    </Items>
                  );
                })}
              </ContentGroup>
            </>
          )
        }
      </BasicInfo>

      <Modal isOpen={openModal} onClose={onCloseModal}>
        {
          selectedAction === 'contact' &&
          <Contact data={data?.phone} />
        }

        {
          selectedAction === 'hoursList' &&
          <HoursList data={data?.dates} />
        }
        {
          selectedAction === 'events' &&
          <Events nextEvents={nextEvents || data?.events?.next} nowEvents={nowEvents || data?.events?.now} onEventClick={onEventClick} isLoading={isLoading} />
        }
      </Modal>
    </Container>
  )
}


const Container = styled(Column)`
  background: ${colors.neutralLight};
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  @media screen and (min-width: ${breakpoints.lg}) {
    width: 32.5%;
  }

  ${({ fullWidth }) => fullWidth && `
    width: 100% !important;
    height: 100%;
  `};
`;


const ContentThumb = styled.div`  
  display:flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  `;


const CardImage = styled.div`
  width: 78px;
  height: 78px;
  position: absolute;
  bottom: -35px;
  border-radius: 50%;

  &:after{ 
    content: '';
    ${({ status }) => status === true && `
      border: 3px solid ${colors.success};
    `}
    ${({ status }) => status === false && `
      border: 3px solid ${colors.nightLight};
    `}
    position: absolute;
    bottom: -4px;
    left: -2px;
    width: 78px;
    height: 78px;
    border-radius: 50%;
  }

  & > picture > img {
    object-fit: cover;
  }
`;

const StablishmentStatus = styled.p`
  position: absolute;
  bottom: -20px;
  padding: 4px 10px;
  font-size: ${fonts.sizes.mini};
  color: ${colors.neutral};
  border-radius: 30px;
  ${({ status }) => status === false && `
      background: ${colors.nightLight};
      left: 10px;
      padding: 4px 10px;
  `}
  ${({ status }) => status === true && `
      background: ${colors.success};
      left: 14px;
      padding: 4px 10px;
  `}
`;

const BasicInfo = styled(Column)`
  
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 50px;
  @media screen and (min-width: ${breakpoints.lg}) {
    background: ${colors.neutralLight}
    
  }
`;

const VerifieldIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;

const SpanCategories = styled.span`
  background: ${colors.goldLight};
  margin: 20px 10px 0 0;
  border-radius: 4px;
  padding: 4px 6px;
  color: ${colors.neutralLight};
  text-transform: capitalize;
  font-size: ${fonts.sizes.small};

  ${({ adulthood }) => adulthood && `
    background: ${colors.nightDark};
  `}

  ${({ bgColor }) => bgColor && `
    background: ${bgColor};
  `}
`;

const ContentCategories = styled(Row)`
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const ContentGroup = styled(Row)`
  border-radius: 4px;
  min-height: 80px;
  max-height: auto;
  align-items: center;

  justify-content: space-around;

  ${({ socialMedia }) => socialMedia && `
    flex-wrap: wrap;
    
  `}
  ${({ features }) => features && `
    flex-wrap: wrap;
  `}


  
  // @media screen and (min-width: ${breakpoints.sm}){
  //   flex-wrap: wrap;
  // }
  
  ${({ items }) => items && `
    overflow-x: auto;
    justify-content: space-around;
    // @media screen and (min-width: ${breakpoints.sm}) {
    //   flex-wrap: unset;
    //   // justify-content: center;
    // }
    
  `}
  
  ${({ rounded }) => rounded && `
    justify-content: space-around;
    flex-wrap: nowrap !important;
    max-width: 100%;
    overflow-x: scroll !important;
    overflow-x: scroll !important;
  `};

    
`;

const ContentIcon = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    flex-direction: row;

  ${({ rounded }) => rounded && `
    width: 46px;
    height: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${colors.primaryLight};
    padding: 4px; 
    border-radius: 30px;
    justify-content: center;
  `};

  ${({ delivery }) => delivery && `
    width: 46px;
    height: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${colors.primaryLight};
    padding: 4px; 
    border-radius: 30px;
    justify-content: center;
    & > svg {
      width: 30px;
      height: 30px;
    }
  `}
`;

const SocialIcon = styled(FullyCentered)`
  width: 46px;
  height: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  

  ${({ bgColor }) => bgColor && `
      border: 1px solid ${bgColor};
  `};

  & > svg {
    padding: 10px;
  }
  
`;

const Items = styled(Link)`
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 375px) {
    display: flex;
    width: 33%;
  }

  ${({ rounded }) => rounded && `
    min-width: 90px !important;
    max-width: 200px;
  `};

  ${({ socialMedia }) => socialMedia && `
     @media screen and (max-width: 375px) {
      width: 100px;
     }
  `}

  ${({ features }) => features && `
    cursor: initial;
    
     @media screen and (max-width: 375px) {
      width: 100px;
     }
    & > p {
      text-transform: capitalize;
    }
  `}

  ${({ features, socialMedia, fullWidth }) => ((features && fullWidth) || (socialMedia && fullWidth)) && `
    @media screen and (min-width: ${breakpoints.md}) {
      width: 19%;
    }
  `};
  `;

const ContainerAddress = styled(Row)`
    align-items: center;
    justify-content: center;
    padding: 15px 20px 10px 10px;
    margin-top: 5px;

    & > a > p > p {
      padding-bottom: 0 !important;
      margin-left: 10px;
    }
    @media screen and (min-width: ${breakpoints.sm}) {
      & > a > p > p {
        padding-bottom: 0 !important;
        margin-left: 0px;
      }
    }
    & > a > p {
      padding-bottom: 0 !important;
    }

`;


// const Status = styled(Column)`
//    border: 2px solid green;
// `