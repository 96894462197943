import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import RegisterStepOne from './RegisterStepOne';
import RegisterStepTwo from './RegisterStepTwo';
import RegisterStepThree from './RegisterStepThree';
import { useStepRegister } from 'context/login/StepRegister';
import colors from 'theme/colors';
import Steps from 'components/_UI/Steps';
import sizes from 'theme/sizes';
import RegisterStepFor from './RegisterStepFour';
import InfoModalSteps from './InfoModalSteps';
import FastOnboarding from './FastOnboarding';
import OnboardingTags from './OnboardingTags';

const STEP_REGISTER_ONE = 1
const STEP_REGISTER_TWO = 2
// const STEP_REGISTER_THREE = 3
// const STEP_REGISTER_FOUR = 4

function SignUp() {
    const { stepRegister } = useStepRegister();

    const [modalOpen, setModalOpen] = useState(false);

    const stepData = [
        { item: STEP_REGISTER_ONE },
        { item: STEP_REGISTER_TWO },
        // { item: STEP_REGISTER_THREE }
    ];

    return (
        <SignUpContainer>
            {stepRegister === STEP_REGISTER_ONE && <FastOnboarding />}
            {stepRegister === STEP_REGISTER_TWO && <OnboardingTags />}

            {/* {stepRegister <= stepData.length && (
                <StepsPosition>
                    <Steps
                        data={stepData}
                        active={stepRegister}
                        onStepClick={() => setModalOpen(true)}
                    />
                </StepsPosition>
            )} */}
        </SignUpContainer>
    )
};

const SignUpContainer = styled(FullyCentered)`
    flex-direction: column;
    padding: ${sizes.medium} ${sizes.tiny};
    background: ${colors.neutralDark};
    max-width: 420px;
`;

export default SignUp;

