import Text from 'components/_UI/Text';
import DynamicProfileImage from 'components/commons/DynamicProfileImage';
import { useCheckout } from 'context/checkout/Checkout';
import currency from 'helpers/currency';
import React, { useState, useEffect } from 'react';
import { MinusCircle, PlusCircle } from 'react-feather';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';


//TO-DO usar batch do produto e nao id do mesmo

export default function ProductItem({ data, onChangeTotalValue }) {
    const [quantity, setQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [batch, setBatch] = useState(data.batches.find(batch => batch.is_active) || data.batches[0])

    const { checkout, setCheckout } = useCheckout();

    const incrementQuantity = () => {
        if (quantity < data.quantity && quantity < 15) {
            setQuantity(prev => prev + 1);
        }
    };

    const decrementQuantity = () => {
        if (quantity > 0) {
            setQuantity(prev => prev - 1);
        }
    };

    useEffect(() => {
        const updatedTotalPrice = batch.price * quantity;
        const updatedTotalOriginalPrice = Number(batch.original_price) * quantity;
        const priceChange = updatedTotalPrice - totalPrice;
        const totalPriceChange = updatedTotalOriginalPrice - totalPrice;

        setTotalPrice(updatedTotalPrice);
        onChangeTotalValue(priceChange, totalPriceChange);

        // Atualiza o checkout com base na quantidade selecionada e nos batches
        setCheckout(prevCheckout => {
            const existingBatches = prevCheckout.batches || [];
            
            // Remove os batches do produto atual do checkout
            const filteredBatches = existingBatches.filter(batch =>
                !data.batches.some(batchData => batchData.id === batch.id)
            );
            
            if (quantity === 0) {
                // Se a quantidade for 0, apenas retorne os batches filtrados
                return {
                    ...prevCheckout,
                    batches: filteredBatches
                };
            } else {
                // Adiciona ou atualiza os batches do produto atual
                const updatedBatches = data.batches.map(batchData => {
                    const existingBatch = filteredBatches.find(
                        batch => batch.id === batchData.id
                    );
                    
                    if (existingBatch) {
                        // Atualiza a quantidade do lote existente
                        return { ...existingBatch, quantity: quantity };
                    } else {
                        // Adiciona o novo lote
                        return { id: batchData.id, quantity: quantity };
                    }
                });

                return {
                    ...prevCheckout,
                    batches: [...filteredBatches, ...updatedBatches]
                };
            }
        });
    }, [quantity, data.price, data.batches]);


    return (
        <ProductItemContainer>
            <Row>
                {
                    data?.image ? (
                        <DynamicProfileImage
                            variant='squired'
                            size='60px'
                            img={data?.image}
                            word={data?.name}
                        />
                    ) : (
                        <img
                            src='/assets/images/placeholder-products.png'
                            style={{
                                width: 60,
                                height: 60,
                                borderRadius: 6
                            }}
                        />
                    )
                }

                <Column marginLeft='10px'>
                    <Text type='bold' paddingBottom={0}>
                        {data.name}
                    </Text>

                    {
                        data?.description && (
                            <Text marginTop={'8px'} name='small' paddingBottom={0}>
                                {data?.description}
                            </Text>
                        )
                    }

                    {
                        // não tem desconto
                        Number(batch?.original_price) === Number(batch?.price) &&
                        <Text color={colors.night} marginBottom={'5px'}>
                            {batch?.original_price != 0 ? currency.format(batch?.original_price) : 'Gratuito'}
                        </Text>
                    }
                    {
                        // tem desconto
                        Number(batch?.original_price) !== Number(batch?.price) &&
                            <Row marginBottom={'8px'}>
                                <Text
                                    color={colors.nightLight}
                                    marginRight={'.5rem'}
                                    decoration={'line-through'}
                                >
                                    {currency.format(batch?.original_price)}
                                </Text>
                                <Text color={colors.success}>
                                    {currency.format(batch?.price)}
                                </Text>
                            </Row>
                    }
                    {/* <Text color={colors.night} marginTop={'8px'} paddingBottom={0}>
                        {currency.format(data?.price)}
                    </Text> */}


                    {
                        data.quantity <= 15 &&
                        <Text
                            name='small'
                            paddingBottom={0}
                            marginTop={'8px'}
                            color={colors.occasionalOrange}
                        >
                            Restam poucas unidades
                        </Text>

                    }
                </Column>
            </Row>

            <QuantityContainer>
                <ToggleValuesContainer
                >
                    <MinusCircle
                        style={{ cursor: 'pointer' }}
                        color={colors.night}
                        size={20}
                        onClick={decrementQuantity}
                    />

                    <Text marginLeft={'6px'} marginRight={'6px'} color={colors.night} paddingBottom={0}>
                        {quantity}
                    </Text>

                    <PlusCircle
                        style={{ cursor: 'pointer', pointerEvents: quantity === 15 ? 'none' : 'visible' }}
                        color={quantity === 15 ? colors.nightLight :  colors.night}
                        size={20}
                        onClick={incrementQuantity}
                    />
                </ToggleValuesContainer>

                <Text type='bold' color={colors.night} paddingBottom={0}>
                    R$ {totalPrice.toFixed(2)}
                </Text>
            </QuantityContainer>
        </ProductItemContainer>
    )
}

const ProductItemContainer = styled(Row)`
    margin-top: 20px;
    padding: 14px 0;
    align-items: center;
    border-bottom: 1px solid ${colors.nightLight};

    ${({ stoped }) => stoped && `
        opacity: .5;
    `};

    @media screen and (min-width: ${breakpoints.lg}) {
        border-bottom: none;
        border-top: 1px solid ${colors.nightLight};
        margin-top: 0;
        
        &:first-child {
            border-top: none;
        }
    }
`;

const QuantityContainer = styled(Column)`
    flex-direction: column;
    width: auto;
    align-items: center;
    width: 100px;
`

const ToggleValuesContainer = styled(Row)`
    margin-bottom: 10px;
    width: auto;
`;
