import Icon from 'components/_UI/Icon';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import DynamicProfileBackground from 'components/commons/DynamicProfileBackground';
import DynamicProfileImage from 'components/commons/DynamicProfileImage';
import date from 'helpers/date';
import types from 'helpers/system/types';
import React, { useEffect, useState } from 'react'
import { Calendar, MapPin } from 'react-feather';
import styled, { css } from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import eventTypes from 'theme/eventTypes';


export default function EventItem({
    data,
    expired,
    isLoading,
    onDropdownAction,
    dropDownVisible,
    onEventClick,
    onMoreClick,

}) {

    const [toogleDropdown, setToogleDropdown] = useState(null);

    function renderActions() {
        setToogleDropdown(!toogleDropdown)
        onMoreClick()
    }


    return (
        <CardContainer onClick={(e) => onEventClick(e)}>
            <ContainerHeader>
                <DynamicProfileBackground
                    img={data?.images}
                    word={data?.name}
                    size={'130px'}
                    event
                />
            </ContainerHeader>


            <ContainerBody>
                <ContentBody>
                    <Calendar size={18} color={colors.primaryDashboard} />

                    <Text
                        color={colors.primaryDashboard}
                        marginLeft={'4px'}
                        paddingBottom={0}
                    >
                        {`
                            ${date.format(data?.dates[0]?.date)} - 
                            ${data?.dates[0]?.start.split(':')[0]}:${data?.dates[0]?.start.split(':')[1]}
                        `}
                    </Text>
                </ContentBody>
                <ContentBody>
                    <Text
                        color={colors.secondaryPurple}
                        type='bold'
                        ellipsisAt='95%'
                    >
                        {data?.name}
                    </Text>
                </ContentBody>
                <ContentBody>
                    <StyledIconPin>
                        <MapPin size={'18px'} color={colors.primaryDashboard} />
                    </StyledIconPin>
                    <Text
                        color={colors.primaryDashboard}
                        ellipsisAt='95%'
                        marginLeft={'4px'}
                    >
                        {data?.address?.street?.name && `${data?.address?.street?.name},`} {data?.address?.street?.district && `Bairro ${data?.address?.street?.district}`}
                        {data?.address?.number && `, nº ${data?.address?.number}`}
                        {data?.address?.complement && ` - ${data?.address?.complement}`}
                    </Text>
                </ContentBody>
            </ContainerBody>

            <StyledClassification type={data?.classification?.name} colorType={!data.images?.length}>
                {types.getBySlug(data?.classification?.name).label}
            </StyledClassification>

            <ContentMore onClick={(e) => e.stopPropagation(e)}>
                <StyledIconMore onClick={() => renderActions()}>
                    <MoreCircle />
                    <MoreCircle />
                    <MoreCircle />
                </StyledIconMore>
            </ContentMore>

            {(toogleDropdown && dropDownVisible) && (
                <DropDownActions onClick={(e) => e.stopPropagation(e)}>
                    {
                        isLoading ? (
                            <FullyCentered style={{padding: '20px 0'}}>
                                <Loader />
                            </FullyCentered>
                        ) : (
                            <>
                                {!expired && (
                                    <StyledActions onClick={() => onDropdownAction('edit')}>
                                        {/* // <StyledActions onClick={() => alert('Disponivel em breve')}> */}
                                        <StyledIconAction edit>
                                            <Icon color={colors.night} name='edit' />
                                        </StyledIconAction>
                                        <Text name='small'>Editar</Text>
                                    </StyledActions>
                                )}

                                <StyledActions onClick={() => onDropdownAction('delete')}>
                                    <StyledIconAction>
                                        <Icon color={colors.night} name='delete' />
                                    </StyledIconAction>
                                    <Text name='small'>Excluir</Text>
                                </StyledActions>
                            </>
                        )
                    }

                </DropDownActions>
            )}
        </CardContainer>
    )
}

const CardContainer = styled(Column)`
    width: 230px;
    height: 220px;
    border-radius: 12px;
    box-shadow: 0px 1px 4px ${colors.night};
    margin-top: 25px;
    background: ${colors.neutralLight};
    transition: all ease-in-out .25s;
    margin: 5px 5px 5px 5px;
    position: relative;
    display: flex;
    cursor: pointer;

    &:hover {
        box-shadow: 0 6px 12px ${colors.nightLight};
    }

    @media screen and (min-width: ${breakpoints.md}) {
        cursor: pointer;
        width: 250px;
        margin: 5px 10px;
    }
`;

const ContainerHeader = styled(Row)`
    width: 100%;
    justify-content: flex-end;
    position: relative;
    height: 121px;
`;

const CardImage = styled.img`
    width: 100%;
    object-fit: cover;
    height: 121px;
    border-radius: 8px 8px 0 0;
`;
const ContainerBody = styled(Column)`
    width: 100%;
    display: flex;
    padding: 10px 10px;
`;

const ContentBody = styled(Row)`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;

    & > p {
        padding-bottom: 0;
        font-size: 14px;
    }
`;

const StyledClassification = styled.div`
    position: absolute;
    margin: 10px 0 0 10px;
    width: 65px;
    height: 20px;
    border-radius: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 2px;
    color: ${colors.neutralLight};
    font-weight: bold;
    font-size: 12px;
    ${({ type }) => type === eventTypes.free.slug && `
        background: ${eventTypes.free.color};
        height: 20px;

    `}

     ${({ type }) => type === eventTypes.paid.slug && `
        background: ${eventTypes.paid.color};   
        height: 20px;

    `}

    ${({ colorType, type }) => colorType && type === eventTypes.paid.slug && `
        background: ${colors.neutralLight};
        color: ${eventTypes.paid.color};
        height: 20px;
    `}

    ${({ colorType, type }) => colorType && type === eventTypes.free.slug && `
        background: ${colors.neutralLight};
        color: ${eventTypes.free.color};
        height: 20px;
    `}

    & > p {
        padding-bottom: 0;
    }
`;

const StyledIconPin = styled.div`
    /* min-width: 18px;
    width: 24px;
    height: 24px; */
`;

const CardTextWrapper = styled(FullyCentered)`
    text-align: center;
    height: 100%;
    border-radius: 8px 8px 0 0;
    & > h1 {
        padding-bottom: 0;
    }

    ${({ category }) => category === 'free' && css`
        background: ${eventTypes.free.color};
    `};

    ${({ category }) => category === 'private' && css`
        background: ${eventTypes.paid.color};
    `};

`;

const StyledIconMore = styled(Row)`
    position: absolute;
    height: 40px;
    width: 30px;
    right: 10px;
    top: 0;
    margin-top: 0;
    align-items: center;
    justify-content: space-between;
`;

const MoreCircle = styled.div`
    width: 8px;
    height: 8px;
    background: ${colors.neutralLight};
    border: 1px solid ${colors.nightDark};
    border-radius: 30px;
`;


const DropDownActions = styled(Column)`
    display: flex;
    width: 100px;
    overflow: auto;
    position: absolute;
    align-items: center;
    border-radius: 8px;
    background: #FFFDFD;;
    top: 14%;
    right: 10px;
    min-height: auto;
    padding: 8px;
    max-height: 200px;
    border: 1px solid ${colors.nightLight};
`;

const StyledActions = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 4px;
    margin-top: 5px;
    text-align: left;

    &:hover {
        background: ${colors.primaryLight};

        & > div > svg {
            fill: ${colors.neutral} !important;
        }

        & > p {
            color: ${colors.neutral} !important;
        }
    }
    & > p {
        font-weight: bold;
        color: ${colors.primaryDashboard};
        padding-bottom: 0;
    }

`;

const StyledIconAction = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 4px;
    ${({ edit }) => edit && `
        margin-right: 4px;
        width: 20px;
        height: 15px;
    `}
`

const ContentMore = styled.div`
`