import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import colors from 'theme/colors'

export default function Progress({ value = 0}) {
    const [progressValue, setProgressValue] = useState(0);

    useEffect(() => {
        setProgressValue(value);
    }, [value]);

    return (
        <StyledProgressContainer>
            <StyledProgressContent progress={progressValue} />
        </StyledProgressContainer>
    )
}

const StyledProgressContainer = styled.div`
    background: transparent;
    width: 100%;
    height: 15px;
    border-radius: 30px;
    border: 1px solid ${colors.neutralDark};
    padding: 1px;
    box-shadow: 0px 0px 6px ${colors.occasionalPurple};
`;

const StyledProgressContent = styled.div`
    background: ${colors.occasionalPurple};
    height: 100%;
    border-radius: 30px;
    width: ${({ progress }) => progress}%; 
    transition: width .7s ease-in-out;
`;
