import React from 'react';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import sizes from 'theme/sizes';
import colors from 'theme/colors';
import Steps from 'components/_UI/Steps';
import { useStepRecoverAccount } from 'context/login/StepRecoverAccount';
import RecoverAccountStepOne from './RecoverAccountStepOne';
import RecoverAccountStepTwo from './RecoverAccountStepTwo';
import RecoverAccountStepThree from './RecoverAccountStepThree';

const STEP_REGISTER_ONE = 1
const STEP_REGISTER_TWO = 2
const STEP_REGISTER_THREE = 3

function RecoverAccountSteps() {
    const { stepRecoverAccount} = useStepRecoverAccount();

    const stepData = [
        { item: STEP_REGISTER_ONE },
        { item: STEP_REGISTER_TWO },
        { item: STEP_REGISTER_THREE }
    ];

    return (
        <RecoverAccountContainer>

            <StepsPosition>
                <Steps data={stepData} active={stepRecoverAccount} />
            </StepsPosition>

            {stepRecoverAccount === STEP_REGISTER_ONE && (<RecoverAccountStepOne />)}
            {stepRecoverAccount === STEP_REGISTER_TWO && (<RecoverAccountStepTwo />)}
            {stepRecoverAccount === STEP_REGISTER_THREE && (<RecoverAccountStepThree />)}

        </RecoverAccountContainer>
    )
};

export default RecoverAccountSteps;

const RecoverAccountContainer = styled(FullyCentered)`
    flex-direction: column;
    padding: ${sizes.medium} ${sizes.tiny};
    background-image: ${colors.blueVerticalGradient};
    max-width: 420px;
`;

const StepsPosition = styled.div`
    padding-bottom: 50px;
`;
