const unformat = {
    getNumbers: (data) => {
        if (typeof data !== 'string') {
            return ''; // or handle the error as appropriate for your use case
        }
        return data.replace(/\D/g, '');
    }
};

export default unformat;
