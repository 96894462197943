import collaboratorsApi from 'api/requests/collaborators'
import Button from 'components/_UI/Button'
import Loader from 'components/_UI/Loader'
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import buttonText from 'texts/buttons/buttonText'
import breakpoints from 'theme/breakpoints'


export default function DeletePermissionModal({ visible, data, edit, onClose, onSuccess }) {
    const [loading, setLoading] = useState();

    async function deletePermission() {
        setLoading(true);

        try {
            await collaboratorsApi.deletePermission(data?.id);
            onSuccess();
        } catch (error) {
            console.log('error')
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal isOpen={visible} closeButton={true} onClose={onClose}>
            <Container>
                <Text textAlign={'center'}>
                    Tem certeza que deseja excluir as permissões referentes ao usuário "<b>{data?.email}</b>"?
                </Text>

                {
                    loading ? (
                        <FullyCentered height='60px'>
                            <Loader />
                        </FullyCentered>
                    ) : (
                        <ButtonsContainer>
                            <Button
                                variant={'light'}
                                category='roundedBorded'
                                onClick={onClose}
                            >
                                {buttonText.cancel}
                            </Button>

                            <Button
                                variant={'lightBlue'}
                                category='roundedBorded'
                                onClick={deletePermission}
                            >
                                Excluir
                            </Button>
                        </ButtonsContainer>
                    )
                }
            </Container>
        </Modal >
    )
}


const Container = styled(Column)`
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
`;

const ButtonsContainer = styled(Column)`
    margin-top: 20px;

    @media screen and (min-width: ${breakpoints.md}) {
        flex-direction: row;
        
        button:first-child {
            margin-right: 16px;
        }
    }
`;