import React from 'react';
import CardSlot from '../parts/CardSlot';
import colors from 'theme/colors';
import Text from 'components/_UI/Text';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';

export default function ProfileSchedule({ data, events }) {
    const hasEvent = (date) => {
      const dateString = date.toISOString().split('T')[0];
      const checkEvents = (eventList) => eventList?.some(event => 
        event?.dates?.some(eventDate => eventDate.date === dateString)
      );
      
      return checkEvents(events?.next) || checkEvents(events?.now);
    };

  const disableTiles = ({ date, view }) => {
    if (view === 'month') {
      const dayOfWeek = date.getUTCDay();
      const dayMap = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      return data[dayMap[dayOfWeek]] === null || data[dayMap[dayOfWeek]].length === 0;
    }
    return false;
  };

  const tileClassName = ({ date, view }) => {
    if (view === 'month' && hasEvent(date)) {
      return 'react-calendar__tile--hasEvent';
    }
    return null;
  };

  return (
    <CardSlot marginTop={'0'}>
       <Text name='highlight' color={colors.occasionalPurple}>Agenda</Text>
      <CalendarContainer>
        <Calendar
          tileDisabled={disableTiles}
          tileClassName={tileClassName}
        />
      </CalendarContainer>
    </CardSlot>
  );
}

const CalendarContainer = styled.div`
  .react-calendar {
    border: none !important;
    width: 100%;
  }

  .react-calendar__tile--now {
    background: ${colors.secondaryPurple} !important;
    color: ${colors.neutralLight} !important;
    border-radius: 6px;
    opacity: .7;
  }

  .react-calendar__navigation button:hover {
    background: ${colors.secondaryPurple} !important;
    color: ${colors.neutralLight} !important;
    border-radius: 6px;
  }

  .react-calendar button:hover {
    background: ${colors.secondaryPurple} !important;
    color: ${colors.neutralLight} !important;
    border-radius: 6px;
  }

  .react-calendar__tile--hasEvent {
    background: transparent !important;
    color: ${colors.secondaryPurple} !important;
    border-radius: 6px;
    opacity: .85;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 46%;
      bottom: 5px;
      background: ${colors.secondaryPurple};
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
  }

  .react-calendar__tile--unavailable {
    background: ${colors.red} !important;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: ${colors.secondaryPurple};
  }

  .react-calendar__month-view__days__day--weekend {
    font-weight: bold;
    color: black;
  }

  .react-calendar__tile:disabled {
    background: transparent;
    opacity: 0.5;
  }
`;
