import React, { createContext, useState, useContext } from 'react';

const InvisibleButtonContext = createContext();

export default function InvisibleButtonProvider({ children }) {
    const [invisibleButton, setInvisibleButton] = useState(false);

    return (
        <InvisibleButtonContext.Provider value={{ invisibleButton, setInvisibleButton }}>
            {children}
        </InvisibleButtonContext.Provider>
    );
}

export function useInvisibleButton() {
    const context = useContext(InvisibleButtonContext);

    if (!context) throw new Error('useInvisibleButton must be used within a InvisibleButtonProvider');

    const { invisibleButton, setInvisibleButton } = context;
    return { invisibleButton, setInvisibleButton };
}