import Banner from 'components/_UI/Banner'
import Button from 'components/_UI/Button'
import React from 'react'
import { FullyCentered } from 'styled/alignment/Center'
import paths from 'routes/paths'
import colors from 'theme/colors'
import styled from 'styled-components'
import breakpoints from 'theme/breakpoints'

export default function () {
    return (
        <Banner
            type='warning'
            textColor={colors.night}
            iconColor={colors.night}
            title='Percebemos que você ainda não cadastrou seus dados bancários.'
            text='Para ter acesso aos seus recebíveis, preencha as informações faltantes em relação à sua conta de preferência.'
            rightSlot={() => (
                <ButtonContainer>
                    <Button
                        marginBottom={0}
                        variant='orange'
                        path={`${paths.dashboard.financial}?dados-bancarios=1`}
                    >
                        Completar cadastro
                    </Button>
                </ButtonContainer>
            )}
        />
    )
}

const ButtonContainer = styled(FullyCentered)`
    width: 100%;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 200px;
    }
`;