import React, { createContext, useState, useContext } from 'react';

const EstablishmentContext = createContext();

export default function EstablishmentProvider({ children }) {
    const [establishment, setEstablishment] = useState(null);

    return (
        <EstablishmentContext.Provider value={{ establishment, setEstablishment }}>
            {children}
        </EstablishmentContext.Provider>
    );
}

export function useEstablishment() {
    const context = useContext(EstablishmentContext);

    if (!context) throw new Error('useEstablishment must be used within a EstablishmentProvider');

    const { establishment, setEstablishment } = context;
    return { establishment, setEstablishment };
}