import eventsApi from 'api/requests/events'
import Text from 'components/_UI/Text'
import React, { useEffect, useState} from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import EventsGroupList from './EventsGroupList'
import Loader from 'components/_UI/Loader'

export default function Events({nextEvents, nowEvents, onEventClick, isLoading}) {
  

  return (
    <ContainerEvent>
      {
       !isLoading ? (
         <EventsGroupList nextEvents={nextEvents} nowEvents={nowEvents} onEventClick={onEventClick}/>
        ) : (
          <Loader />
        )
      }
    </ContainerEvent>
  )
}

const ContainerEvent = styled(Column)`
  display: flex;
  width: 100%;
`;

