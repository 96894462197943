import React, { createContext, useState, useContext } from 'react';

const BackButtonCollapseDashContext = createContext();

export default function BackButtonCollapseDashProvider({ children }) {
    const [backButtonCollapseDash, setBackButtonCollapseDash] = useState({
        visible: false
    });

    return (
        <BackButtonCollapseDashContext.Provider value={{ backButtonCollapseDash, setBackButtonCollapseDash }}>
            {children}
        </BackButtonCollapseDashContext.Provider>
    );
}

export function useBackButtonCollapseDash() {
    const context = useContext(BackButtonCollapseDashContext);

    if (!context) throw new Error('useBackButtonCollapseDash must be used within a useBackButtonCollapseDashProvider');

    const { backButtonCollapseDash, setBackButtonCollapseDash} = context;
    return { backButtonCollapseDash, setBackButtonCollapseDash };
}