import React, { createContext, useState, useContext } from 'react';

const ProductsContext = createContext();

export default function ProductsProvider({ children }) {
    const [products, setProducts] = useState(null);

    return (
        <ProductsContext.Provider value={{ products, setProducts }}>
            {children}
        </ProductsContext.Provider>
    );
}

export function useProducts() {
    const context = useContext(ProductsContext);

    if (!context) throw new Error('useProducts must be used within a ProductsProvider');

    const { products, setProducts } = context;
    return { products, setProducts };
}