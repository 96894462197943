import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import CategoriesSelects from './CategoriesSelects';
import TypesSelects from './TypesSelects';
import DateSelects from './DateSelects';
import Button from 'components/_UI/Button';
import establishmentsApi from 'api/requests/establishments';
import FiltersResult from './FiltersResult';
import Loader from 'components/_UI/Loader';
import { Column } from 'styled/alignment/Column';
import { useSearchParams } from 'react-router-dom';
import RangeSelect from './RangeSelect';
import date from 'helpers/date';
import { useEstablishmentFilter } from 'context/establishments/EstablishmentFilter';
import EstablishmentHoursSelects from 'modules/events/filters/EstablishmentsHoursSelects';
import ServiceOptionsFilter from './ServiceOptionsFilter';
import EstablishmentFeaturesSelects from './EstablishmentFeaturesSelects';
import EstablishmentCategoriesSelects from './EstablishmentCategoriesSelects';
import Fieldset from 'components/_UI/Fieldset';
import colors from 'theme/colors';
import breakpoints from 'theme/breakpoints';
import { useCurrentLocation } from 'context/user/CurrentLocation';
import { useUserLocation } from 'context/user/UserLocation';
import InputWithAutocomplete from 'components/_UI/InputWithAutocomplete';

let count = 0;

export default function Selects({
    searchTrigger,
    isRouteParam,
    backData,
    onEventClick,
    setSearchInputValue,
}) {

    const { establishmentFilter, setEstablishmentFilter } = useEstablishmentFilter();
    const { userLocation } = useUserLocation()

    const [searchParams, setSearchParams] = useSearchParams();

    const [result, setResult] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [loading, setLoading] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [error, setError] = useState(false);
    const [searchResultParams, setSearchResultParams] = useState(false);
    const [serviceOptions, setServiceOptions] = useState([])
    const [categories, setCategories] = useState([])
    const [features, setFeatures] = useState([])
    const [loaddingOptions, setLoadingOptions] = useState(false)

    const { currentLocation } = useCurrentLocation()

    const resultRef = useRef(null);

    useEffect(() => {
        getOptions();

        if (searchTrigger && establishmentFilter) {
            filterEstablishments();
        }

        if (isRouteParam && count === 0) {
            count++
            filterEstablishments(isRouteParam)
        }

        if (searchTrigger && backData) {
            filterEstablishments(backData)
        }

        if (backData) {
            filterEstablishments(backData)
        }

    }, [searchTrigger, backData]);

    async function getOptions() {
        setLoadingOptions(true);

        try {
            const { data } = await establishmentsApi.getOptions();
            setCategories(data?.tags);
            setServiceOptions(data?.serviceOptions);
            setFeatures(data?.features);
        } catch (error) {
            // console.log(error)
        } finally {
            setLoadingOptions(false);
        }
    }

    function createTagsData() {
        let formatedTags = ''

        establishmentFilter?.categories.map((el, index) => {
            if (index === 0) {
                formatedTags = el.name
            }

            if (index > 0) {
                formatedTags = `${formatedTags},${el.name}`
            }
        })

        return formatedTags
    }

    function validatedPayload(data) {
        let payload = data;

        if (!establishmentFilter?.name) {
            delete payload.name
        }

        if (!establishmentFilter?.dates) {
            delete payload.filter_date
        }

        if (establishmentFilter?.dates === 'custom') {
            delete payload.filter_date
            payload = {
                ...payload,
                min_date: date.unformat(establishmentFilter?.customFields?.from),
                max_date: date.unformat(establishmentFilter?.customFields?.to),
            }
        } else {
            delete payload.min_date
            delete payload.max_date
        }

        if (!establishmentFilter?.open) {
            delete payload.open;
        } else {
            delete payload.operating_days;
        }

        if (!payload.operating_days) {
            delete payload.operating_days;
        }

        if (!establishmentFilter?.types?.length || establishmentFilter?.types?.length > 1) {
            delete payload.classification_id
        }

        if (!establishmentFilter?.features?.length) {
            delete payload.features;
        }

        if (!establishmentFilter?.services) {
            delete payload.services;
        }

        if (!establishmentFilter?.categories?.length) {
            delete payload.tags;
        }

        if (!establishmentFilter?.distance_range) {
            delete payload.distance_range
        }

        if (establishmentFilter?.distance_range && (userLocation.lat && userLocation.long)) {
            payload = {
                ...payload,
                lat: userLocation.lat,
                long: userLocation.long
            }
        }

        return payload
    }

    function createPayload(routeParam) {
        if (routeParam) {
            let payload = routeParam

            if (!payload.name) {
                delete payload.name
            }

            if (!payload.filter_date) {
                delete payload.filter_date
            }

            if (!payload.classification_id) {
                delete payload.classification_id
            }

            if (!payload.tags) {
                delete payload.tags
            }

            if (!payload.distance_range) {
                delete payload.distance_range
            } else {
                payload = {
                    ...payload,
                    lat: userLocation.lat,
                    long: userLocation.long
                }
            }

            if (!payload.min_date) {
                delete payload.min_date
            }

            if (!payload.max_date) {
                delete payload.max_date
            }

            if (!payload.features) {
                delete payload.features
            }

            if (!payload.services) {
                delete payload.services
            }

            if (!payload.open) {
                delete payload.open
            }

            if (!payload.operating_days) {
                delete payload.operating_days
            }

            return payload
        }

        const payload = {
            city: currentLocation?.city,
            name: establishmentFilter?.name,
            filter_date: establishmentFilter?.dates,
            classification_id: establishmentFilter?.types?.length === 1 ? establishmentFilter?.types[0].id : establishmentFilter?.types,
            distance_range: establishmentFilter?.distance_range,
            open: establishmentFilter?.open,
            operating_days: establishmentFilter?.operating_days,
            services: establishmentFilter?.services,
            features: establishmentFilter?.features?.map(el => el).join(', '),
            tags: establishmentFilter?.categories?.map(el => el).join(', '),
        }

        return validatedPayload(payload)
    }

    async function filterEstablishments(routeParam) {
        setLoading(true);

        try {
            const { data } = await establishmentsApi.filter(routeParam ? createPayload(routeParam) : createPayload());
            if (createPayload() && !routeParam) {
                setSearchParams({ ...createPayload() })
                setSearchResultParams(createPayload())
            } else {
                setSearchParams({ ...createPayload(routeParam) })
                setSearchResultParams(createPayload(routeParam))
            }

            data?.data && setResult(data.data)
            setShowResult(true)
            setRefreshData(true)
            setError(false)
            setEstablishmentFilter(false)
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false);
            resultRef.current.scrollIntoView({ behavior: 'smooth' });
            routeParam = false
        }
    }

    return (
        <>
            {
                loading
                    ? (
                        <Column height='100%' fullyCentralized>
                            <Loader />
                        </Column >
                    )
                    : (
                        <SelectsContainer>
                            <InputWithAutocomplete
                                placeholder='Pesquisar'
                                id='filter_search_input'
                                layout='roundedDark'
                                inputValue={establishmentFilter?.name || ''}
                                badgeIcon='search'
                                badgeIconColor={colors.neutral}
                                isBadgeClickable={true}
                                badgeIconBackground='#4F86F7'
                                // trigger={searchTrigger}
                                onBadgeClick={filterEstablishments}
                                onChange={(e) => {
                                    setEstablishmentFilter({ ...establishmentFilter, name: e })
                                }}
                            />

                            {(userLocation?.lat && userLocation?.long) && <RangeSelect />}
                            {
                                loaddingOptions ? (
                                    <FullyCentered height='200px'>
                                        <Loader />
                                    </FullyCentered>
                                ) : (
                                    <>
                                        <EstablishmentHoursSelects />
                                        <ServiceOptionsFilter data={serviceOptions} />
                                        <EstablishmentFeaturesSelects data={features} />
                                        <EstablishmentCategoriesSelects data={categories} squired='true' />

                                        <Button
                                            id='filter_button'
                                            variant={!establishmentFilter ? 'disabled' : 'primary'}
                                            onClick={() => filterEstablishments()}
                                        >
                                            Buscar
                                        </Button>
                                    </>
                                )
                            }
                        </SelectsContainer >
                    )
            }

            {
                showResult &&
                <FiltersResult
                    routeParams={searchResultParams}
                    ref={resultRef}
                    data={result}
                    error={error}
                    onEventClick={onEventClick}
                />
            }
        </>
    )
}

const SelectsContainer = styled(FullyCentered)`
    flex-direction: column;
    text-align: center;

    @media screen and (min-width: ${breakpoints.lg}) {
        & > div:nth-child(1) {
            display: none;
        }
    }
`;