import Text from 'components/_UI/Text';
import React from 'react'
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center';
import colors from 'theme/colors';

export default function DynamicProfileImage({
    img,
    size,
    word,
    textSize = 'title',
    variant = 'rounded',
    bgColor = colors.secondary,
}) {
    return (
        img
            ? (
                <Picture size={size}>
                    <ImageBackground
                        src={img}
                        alt='imagem de perfil'
                        size={size}
                        variant={variant}
                    />
                </Picture>
            )
            : (
                <TextContent
                    bgColor={bgColor}
                    variant={variant}
                    size={size}
                >
                    <Text
                        color={colors.neutral}
                        type='bold'
                        name={textSize}
                    >
                        {word?.charAt(0).toUpperCase()}
                    </Text>
                </TextContent>
            )
    )
}

const Picture = styled.picture`
    ${({ size }) => size && `
        width: ${size};
        height: ${size};
    `}
`;

const ImageBackground = styled.img`
    ${({ size }) => size && `
        width: ${size};
        height: ${size};
        object-fit: cover;
    `}

    ${({ variant }) => variant === 'rounded' && `
        border-radius: 60px;
    `}

    ${({ variant }) => variant === 'squired' && `
        border-radius: 6px;
    `}
`;

const TextContent = styled(FullyCentered)`
    ${({ bgColor }) => bgColor && `
        background: ${bgColor};
    `}

    ${({ size }) => size && `
        width: ${size};
        height: ${size};
    `}

    ${({ variant }) => variant === 'rounded' && `
        border-radius: 60px;
    `}

    ${({ variant }) => variant === 'squired' && `
        border-radius: 6px;
    `}

    p, h1, h2, h3, h4 {
        padding-bottom: 0 !important;
    }
`;
