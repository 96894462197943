export default function downloadBlob(data, fileName, type = 'application/vnd.ms-excel') {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);

    link.click();
    link.remove();
}