import TagsProvider from 'context/events/Tags'
import CollapseProvider from 'context/general/Collapse'
import OnSelectMenuProvider from 'context/general/OnSelectMenu'
import PreferencesProvider from 'context/user/Preferences'
import Users from 'modules/users/Users'
import EditProfile from 'modules/users/form/EditProfile'
import React, {useState } from 'react'

export default function UserProfile() {
  const [edit, setEdit] = useState(false)
  
  function handleEditUser(item) {
    setEdit(item)   
  }


  
  return (
    <OnSelectMenuProvider>
      <CollapseProvider>
        <TagsProvider>
          <PreferencesProvider>
            {
              edit ? (
                <EditProfile data={edit} close={() => setEdit(false)}  mobile />
              ) : (
                <Users handleEditUser={handleEditUser}  />
              )
            }
          </PreferencesProvider>
        </TagsProvider>
      </CollapseProvider>
    </OnSelectMenuProvider>
  )
}
