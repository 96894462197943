const fonts = {
    familly: {},
    
    sizes: {
        hero: '40px',
        title: '24px',
        subtitle: '20px',
        highlight: '16px',
        default: '14px',
        small: '12px',
        mini: '10px'
    },

    weight: {
        regular: 400,
        bold: 700
    },

    style: {
        italic: 'italic'
    }

};

export default fonts;