import establishmentsApi from 'api/requests/establishments';
import BoxCollapse from 'components/_UI/BoxCollapse';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import { useCollapse } from 'context/general/Collapse';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import tabs from 'theme/tabs';
import { useEstablishmentsList } from 'context/establishments/EstablishmentsList';
import CardProfile from 'modules/dashboard/profile/parts/CardProfile';
import EstablishmentsBoxFilters from './filters/BoxFilters';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';
import ResumeCard from './card/CardResume';
import { Column } from 'styled/alignment/Column';
import { useSearchParams } from 'react-router-dom';
import MultipleEstablishmentsList from 'modules/events/map/MultipleEstablishmentsList';
import { useCurrentLocation } from 'context/user/CurrentLocation';
import routeParams from 'theme/routeParams';
import login from 'api/requests/login';
import eventsApi from 'api/requests/events';
import EventDetail from 'modules/events/detail/EventDetail';
import { useInvisibleButton } from 'context/general/InvisibleButton';

let nowEvents = null
let nextEvents = null

export default function Establishments({
  establishmentClick,
  filterClick,
  onLoadData,
  onLoading,
  setEstablishmentClick
}) {
  const { collapse, setCollapse } = useCollapse();
  const { establishmentsList, setEstablishmentsList } = useEstablishmentsList();
  const { backButtonCollapse, setBackButtonCollapse } = useBackButtonCollapse();
  const [searchParams, setSearchParams] = useSearchParams();
  const {invisibleButton, setInvisibleButton} = useInvisibleButton()

  const [filterData, setFilterData] = useState(null)
  const [slug, setSlug] = useState(null);
  const [establishmentData, setEstablishmentData] = useState();
  const [nextEvents, setNextEvents] = useState(null)
  const [nowEvents, setNowEvents] = useState(null)

  const { currentLocation } = useCurrentLocation()

  useEffect(async () => {
    await getEstablishments();
    await getEstablishmentsByRouteParam();
  }, [currentLocation?.city])

  useEffect(async () => {
    if (establishmentClick) {
      onEstablishmentClick(establishmentClick)
      return
    }
  }, [establishmentClick]);

  async function getEstablishments() {
    onLoading(true)
    try {
      const { data } = await establishmentsApi.getForMap(currentLocation.city);
      const arraysWithMultiple = data?.data?.filter(obj => obj.hasOwnProperty('multiples'));
      const arraysWithoutMultiple = data?.data?.filter(obj => {
        return !obj.hasOwnProperty('multiples') && !obj.hasOwnProperty('noAddress')
      });

      const formatedObject = {
        data: arraysWithoutMultiple,
        withMultiples: arraysWithMultiple
      };

      onLoading(false)
      onLoadData(formatedObject);
      setEstablishmentData(formatedObject);
      setEstablishmentsList(formatedObject);

    } catch (error) {
      onLoading(false)
    }
  }

  async function getEstablishmentsByRouteParam() {
    const establishmentSlug = searchParams.get('estabelecimento');

    const latParam = searchParams.get('lat')
    const longParam = searchParams.get('long')

    const name = searchParams.get('name')
    const filter_date = searchParams.get('filter_date')
    const classification_id = searchParams.get('classification_id')
    const tags = searchParams.get('tags')
    const services = searchParams.get('services')
    const features = searchParams.get('features')
    const open = searchParams.get('open')
    const operating_days = searchParams.get('operating_days')
    const distance_range = searchParams.get('distance_range')
    const city = searchParams.get('city')

    if (name || filter_date || classification_id || tags || distance_range || features || services || open || operating_days || city) {
      setFilterData({
        name,
        filter_date,
        classification_id,
        tags,
        distance_range,
        services,
        features,
        operating_days,
        open,
        city
      })
      return
    }

    if (latParam && longParam) {
      renderMultipleEstablishments(latParam, longParam)
      return
    }

    if (!establishmentSlug) {
      return
    }

    setCollapse({
      ...collapse,
      withContent: true,
      height: 'max',
      arrowPosition: false,
      componentToRender: () => (
        <FullyCentered height='100%'>
          <Loader />
        </FullyCentered>
      )
    })

    try {
      const { data } = await establishmentsApi.getBySlug(establishmentSlug);
      
      setNextEvents(data?.data?.events?.next)
      setNowEvents(data?.data?.events?.now)
      
      setSlug(establishmentSlug);
      

      setCollapse({
        ...collapse,
        withContent: true,
        height: 'max',
        arrowPosition: false,
        componentToRender: () => data && 
        <CardProfile onEventClick={onEventClick} nowEvents={nowEvents}  nextEvents={nextEvents} data={data?.data} fullWidth={true} />
      })
    } catch (error) {
      setCollapse({
        ...collapse,
        withContent: true,
        height: 'max',
        arrowPosition: false,
        componentToRender: () => (
          <FullyCentered height='100%'>
            <Text>Ocorreu um erro, tente novamente mais tarde.</Text>
          </FullyCentered>
        )
      })
    }
  }

  const onEventClick = async(slug) => {
    setInvisibleButton(false)
    setCollapse({
      ...collapse,
      withContent: true,
      height: 'max',
      arrowPosition: false,
      componentToRender: () => (
        <FullyCentered height='100%'>
            <Loader />
        </FullyCentered>
      )
    })
    try {
      const {data} = await eventsApi.getBySlug(slug)
      setCollapse({
        ...collapse,
        withContent: true,
        height: 'max',
        arrowPosition: false,
        componentToRender: () => data && <EventDetail data={data.data} />
       })
    } catch (error) {
      setCollapse({
        ...collapse,
        withContent: true,
        height: 'max',
        arrowPosition: false,
        componentToRender: () => (
          <Column>
            <Text>Ocorreu um erro, tente novamente mais tarde.</Text>
          </Column>
        )
    })
    }
  }

  async function renderMultipleEstablishments(lat, long) {
    
    setCollapse({
      ...collapse,
      withContent: true,
      height: 'max',
      arrowPosition: false,
      componentToRender: () => (
        <FullyCentered height='100%'>
          <Loader />
        </FullyCentered>
      )
    })

    try {
      const { data: res } = await establishmentsApi.findMultiples({ lat, long })
      setCollapse({
        ...collapse,
        withContent: true,
        height: 'max',
        arrowPosition: false,
        componentToRender: () => res?.data && <MultipleEstablishmentsList onEventClick={onEventClick} data={res.data} />
      })
    } catch (error) {
      <FullyCentered height='100%'>
        <Text name='highlight'> Ocorreu um erro inesperado. </Text>
      </FullyCentered>
    }
  }

  async function onEstablishmentClick(item) {
    let response = false

    setCollapse({
      ...collapse,
      withContent: true,
      height: 'max',
      arrowPosition: false,
      from: tabs.establishment.slug,
      componentToRender: () => (
        <FullyCentered height='100%'>
          <Loader />
        </FullyCentered>
      )
    })

    try {
      const { data } = await establishmentsApi.getBySlug(item?.slug)
      response = data.data
    } catch (error) {
      response = 'error'
    } finally {
      setSearchParams({ estabelecimento: item.slug })
      setCollapse({
        ...collapse,
        withContent: true,
        height: 'max',
        arrowPosition: false,
        from: tabs.establishment.slug,
        componentToRender: () => item && renderDetail(response)
      })
    }
  };

  function renderDetail(response) {
    if (!response) {
      return
    }

    if (response === 'error') {
      return (
        <FullyCentered height='100%'>
          <Text name='highlight'>Ocorreu um erro inesperado.</Text>
        </FullyCentered>
      )
    }

    return setCollapse({
      ...collapse,
      withContent: true,
      height: 'max',
      arrowPosition: false,
      from: tabs.establishment.slug,
      componentToRender: () => (
        <CardProfile onEventClick={onEventClick} nowEvents={nowEvents} nextEvents={nextEvents} data={response} fullWidth={true} />

      )
    })
  }

  function onCloseCollapse() {
    setEstablishmentClick(null)
    setCollapse({
      ...collapse,
      withContent: false,
      height: 'min',
      from: tabs.establishment.slug,
      // arrowPosition: true,
      componentToRender: false
    });

    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);

      Object.values(routeParams.establishments).map(el => {
        newSearchParams.delete(el)
      })

      return newSearchParams;
    });
    setCollapse({
      ...collapse,
      withContent: false,
      height: 'min',
      from: tabs.establishment.slug,
      arrowPosition: true,
      componentToRender: false
    });

    setBackButtonCollapse({ ...backButtonCollapse, visible: false });
  }

  return (
    <EstablishmentsBoxFilters
      onClose={() => onCloseCollapse()}
      onNearToYouClick={(establishment) => onEstablishmentClick(establishment)}
      isRouteParam={filterData}
      filterClick={filterClick}
      onEventClick={onEventClick}

    />
  )
}

// const CollapseContainer = styled(Column)`
//     padding: ${sizes.none} ${sizes.medium};
//     overflow-x: unset;
// `;
