import Text from 'components/_UI/Text';
import { useEstablishmentFilter } from 'context/establishments/EstablishmentFilter';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import { transformedServicesOptions } from 'theme/establishmentsServicesOptions';

export default function ServiceOptionsFilter({ data }) {
    const { establishmentFilter, setEstablishmentFilter } = useEstablishmentFilter();

    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (selected !== null) {
            const selectedOption = transformedServicesOptions(data).find(option => option.value == selected);
            if (selectedOption) {
                setEstablishmentFilter({
                    ...establishmentFilter,
                    services: selectedOption.value
                });
            }
        }
    }, [selected]);

    const handleServiceSelect = (item) => {
        setSelected(item.value);
    };



    return (
        <Container>
            <Text size='small' type='bold' paddingBottom={'5px'}>
                Opções de serviço
            </Text>
            <Text size='small' color={colors.night} paddingBottom={'20px'}>
                Escolha a opção de serviço que se encaixe na sua busca.
            </Text>

            <Column>
                <Select
                    placeholder='Selecione uma opção'
                    options={transformedServicesOptions(data)}
                    selected={selected}
                    onChange={handleServiceSelect}
                />
            </Column>
        </Container>
    );
}


const Container = styled(FullyCentered)`
    flex-direction: column;
    margin-top: 25px;

    @media screen and (min-width: ${breakpoints.lg}) {
        width: 80%;
    }
`;