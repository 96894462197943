import Text from 'components/_UI/Text'
import { useEventFilter } from 'context/events/EventFilter'
import React, { useEffect, useState } from 'react'
import { Column } from 'styled/alignment/Column'
import breakpoints from 'theme/breakpoints'
import colors from 'theme/colors'
import Select from 'react-select';
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center'
import { transformedEventTypes } from 'theme/eventTypes'

export default function TypesSelects() {
    const { eventFilter, setEventFilter } = useEventFilter();

    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (selected !== null) {
            const selectedOption = transformedEventTypes.find(option => option.value == selected);
            if (selectedOption) {

                setEventFilter({
                    ...eventFilter,
                    classification_id: selectedOption.value
                });
            }
        }
    }, [selected]);

    const handleSelect = (item) => {
        setSelected(item.value);
    };


    return (
        <Container marginTop='18px'>
            <Text size='small' type='bold' marginLeft='6px'> Tipo de evento</Text>
            <Text size='small' color={colors.night}>Escolha os tipos de evento que deseja buscar.</Text>

            <Column>
                <Select
                    placeholder='Selecione uma opção'
                    options={transformedEventTypes}
                    selected={selected}
                    onChange={handleSelect}
                />
            </Column>
        </Container>
    )
}


const Container = styled(FullyCentered)`
    flex-direction: column;
    margin-top: 25px;

    @media screen and (min-width: ${breakpoints.lg}) {
        width: 80%;
    }
`;