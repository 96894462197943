import React, { useState } from 'react';
import Button from 'components/_UI/Button';
import Fieldset from 'components/_UI/Fieldset';
import Text from 'components/_UI/Text';
import { useUser } from 'context/user/User';
import { Column } from 'styled/alignment/Column';
import { StyledForm } from 'styled/UI/StyledForm';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import { useStepRecoverAccount } from 'context/login/StepRecoverAccount';
import buttonText from 'texts/buttons/buttonText';
import { useForm } from 'react-hook-form';
import fieldValidations from 'helpers/fieldValidations';
import { FullyCentered } from 'styled/alignment/Center';
import loginApi from 'api/requests/login';
import fieldMessages from 'texts/messages/field';
import Loader from 'components/_UI/Loader';
import Modal from 'components/_UI/Modal';
import paths from 'routes/paths';


function RecoverAccountStepThree() {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const { stepRecoverAccount, setStepRecoverAccount } = useStepRecoverAccount();
    const { user } = useUser();

    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const changePassword = async ({ password, confirmPassword }) => {
        if (password !== confirmPassword) {
            setErrorMessage(fieldMessages.passwordMustBeEqual);
            return;
        }

        const { code, email } = user;
        const payload = { code, email, password }

        setIsLoading(true);

        try {
            await loginApi.changePassword(payload);
            setModalOpen(true);
        } catch ({ response }) {
            setErrorMessage(fieldMessages.generic);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Column verticalCenter>
                <Text color={colors.neutral} type='bold' name='highlight'>Para finalizar,</Text>
                <Text color={colors.neutral} type='bold' name='highlight'>confirme sua nova senha</Text>
            </Column>

            <StyledForm
                marginTop={sizes.normal}
                paddingRight='13%'
                paddingLeft='13%'
                onSubmit={handleSubmit(changePassword)}
            >

                <Fieldset
                    placeholder='Senha'
                    layout='straight-center'
                    marginTop={sizes.normal}
                    inputType='password'
                    badgeIconPassword
                    badgeIconColorPassword={colors.neutral}
                    badgeIconPositionPassword={'right'}
                    name='password'
                    register={register}
                    validations={fieldValidations.password()}
                />
                {
                    errors.password?.message && (
                        <FullyCentered textAlign='center'>
                            <Text color={colors.danger}> {errors.password?.message} </Text>
                        </FullyCentered>
                    )
                }

                <Fieldset
                    placeholder='Confirme sua senha'
                    layout='straight-center'
                    marginTop={sizes.normal}
                    badgeIconPassword
                    badgeIconColorPassword={colors.neutral}
                    badgeIconPositionPassword={'right'}
                    inputType='password'
                    name='confirmPassword'
                    register={register}
                    validations={fieldValidations.password()}
                />
                {
                    errors.confirmPassword?.message && (
                        <FullyCentered textAlign='center'>
                            <Text color={colors.danger}> {errors.confirmPassword?.message} </Text>
                        </FullyCentered>
                    )
                }

                <FullyCentered marginTop={sizes.micro}>
                    <Text
                        color={colors.danger}
                        type='bold'
                    >
                        {errorMessage}
                    </Text>
                </FullyCentered>

                {
                    !isLoading ? (
                        <Button
                            category='roundedBorded'
                            variant='secondary'
                            size='large'
                            marginTop={sizes.normal}
                            type='submit'
                        >
                            {buttonText.confirm}
                        </Button>
                    ) : (<Loader />)
                }

                <Button
                    category='minimal'
                    variant='primary'
                    size='large'
                    marginTop={sizes.nano}
                    onClick={() => setStepRecoverAccount(stepRecoverAccount - 1)}
                >
                    {buttonText.back}
                </Button>
            </StyledForm>

            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <Text name='highlight' marginTop={sizes.small}>Parabéns!</Text>
               
                <Text name='default' type='bold'>Sua senha foi alterada com sucesso.</Text>
               
                <Column paddingRight='15%' paddingLeft='15%' marginTop={sizes.normal}> 
                    <Button path={paths.login} onClick={() => setModalOpen(false)}>
                        {buttonText.understood} 
                    </Button>
                </Column>
            </Modal>
        </>
    )
};

export default RecoverAccountStepThree;