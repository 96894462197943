import React, { createContext, useState, useContext } from 'react';

const ContainerClickVisible = createContext();

export default function ContainerClickProvider({ children }) {
    const [containerClick, setContainerClick] = useState(0);

    return (
        <ContainerClickVisible.Provider value={{ containerClick, setContainerClick }}>
            {children}
        </ContainerClickVisible.Provider>
    );
}

export function useContainerClick() {
    const context = useContext(ContainerClickVisible);

    if (!context) throw new Error('useContainerClick must be used within a ContainerClickProvider');

    const { containerClick, setContainerClick} = context;
    return { containerClick, setContainerClick };
}