import ButtonSelect from 'components/_UI/ButtonSelect'
import Loader from 'components/_UI/Loader'
import { ButtonSelectItem, SelectContainer, SelectWrapper } from 'components/_UI/SelectsContent'
import Text from 'components/_UI/Text'
import { useEvent } from 'context/events/Event'
import React, { useState, useEffect } from 'react'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'
import tagsApi from 'api/requests/tags'
import { useTags } from 'context/events/Tags'
import { HorizontalCenter } from 'styled/alignment/Center'
import styled from 'styled-components'
import breakpoints from 'theme/breakpoints'
import Modal from 'components/_UI/Modal'

export default function CategoriesInfo({ tagsLoading, error, edit, setSelecteds }) {
    const { tags } = useTags();
    // const { event, setEvent } = useEvent();

    const [selectedItems, setSelectedItems] = useState(edit?.map(item => item.id) || []);
    const [showErrorModal, setShowErrorModal] = useState(false);

    useEffect(() => {
        if (edit) {
            const items = edit?.map(item => item?.id)
            setSelectedItems(items);
            setSelecteds(items);
            // setEvent({ ...event, tags: edit?.map(item => item?.id) })
        }
    }, [error, tagsLoading,])


    const handleSelectItem = (id) => {
        setSelectedItems(prevSelectedItems => {
            let newSelectedItems;

            if (prevSelectedItems.includes(id)) {
                newSelectedItems = prevSelectedItems.filter(item => item !== id);
            } else {
                if (prevSelectedItems.length >= 3) {
                    setShowErrorModal(true)
                    return prevSelectedItems
                }
                newSelectedItems = [...prevSelectedItems, id];
            }

            setSelecteds(newSelectedItems);
            // setEvent({
            //     ...event,
            //     tags: newSelectedItems
            // });

            return newSelectedItems;
        });
    };


    return (
        <Column marginTop='22px'>
            <Text size='small' type='bold' color={colors.nightDark}>
                Categorias
            </Text>
            <Text size='small' color={colors.night}>
                Selecione até 3 categorias que seu evento se encaixe.
            </Text>

            {
                tagsLoading ? <Loader /> : (
                    <SelectContainer>
                        {
                            tags?.map(el => (
                                <SelectItem
                                    key={el.id}
                                    selected={selectedItems.includes(el.id)}
                                    onClick={() => handleSelectItem(el.id)}
                                >
                                    <Text
                                        name='mini'
                                        textAlign={'center'}
                                        paddingBottom={0}
                                        type='bold'
                                        ellipsisAt={'98%'}
                                        color={selectedItems.includes(el.name) ? colors.neutral : colors.night}
                                    >
                                        {el.name}
                                    </Text>
                                </SelectItem>
                            ))

                        }
                    </SelectContainer>
                )
            }

            {showErrorModal && (
                <Modal isOpen={true} onClose={() => setShowErrorModal(false)}>
                    <Text
                        type='bold'
                        name='normal'
                        paddingRight={'50px'}
                        paddingLeft={'50px'}
                        paddingTop={'20px'}
                        paddingBottom={'20px'}
                    >
                        Você pode selecionar no máximo 3 categorias.
                    </Text>
                </Modal>
            )}

            {error && (
                <HorizontalCenter>
                    {/* {error === 'max' && (
                        <Modal isOpen={true}>
                            <Text>Você pode selecionar no máximo 5 categorias.</Text>
                        </Modal>
                    )} */}

                    {error === 'min' && (
                        <Text color={colors.danger}> Você deve selecionar pelo menos uma categoria </Text>
                    )}
                </HorizontalCenter>
            )}
        </Column>
    )
}



const SelectItem = styled.div`
    width: 30%;
    margin-bottom: 3%;
    border: 1px solid ${colors.night};
    padding: 10px 5px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;


    @media screen and (min-width: ${breakpoints.lg}){
        width: 13%;
    }

    ${({ selected }) => selected && `
        background: #4617ad;
        border: 1px solid #4617ad;
        & > p {
            color: ${colors.neutral} !important;
        }
    `};
`;

