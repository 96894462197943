import feedbackApi from 'api/requests/feeback';
import Button from 'components/_UI/Button';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import TextArea from 'components/_UI/TextArea'
import fieldValidations from 'helpers/fieldValidations';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import buttonText from 'texts/buttons/buttonText';
import fieldMessages from 'texts/messages/field';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import sizes from 'theme/sizes';



export default function FeedbackForm({openModalSucess, onClose}) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);



    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    
    async function createFeedback(content) {
       
        setIsLoading(true)
        try {
            await feedbackApi.create(content)
            openModalSucess()
            onClose()
        } catch (error) {
            if(error) {
                setErrorMessage(fieldMessages.generic);    
                return    
            }
        } finally {
            setIsLoading(false)
        }
    }

  return (
        <FormContainer onSubmit={handleSubmit(createFeedback)}>
            <Column horizontalCenter>
                <Text name='subtitle'>Sugestões</Text>  
          
                <TextArea 
                    register={register}
                    name='content'
                    placeholder='Digite sugestões para melhoria da plataforma'
                    validations={fieldValidations.generic('sugestões')}
                />
                {
                    errors.content?.message && (
                        <FullyCentered textAlign='center'>
                            <Text color={colors.danger}> {errors.content?.message} </Text>
                        </FullyCentered>
                    )
                }
                <FullyCentered marginTop={sizes.micro}>
                    <Text
                        color={colors.danger}
                        type='bold'
                    >
                        {errorMessage}
                    </Text>
                </FullyCentered>

                {
                    !isLoading ? (
                        <FullyCentered>
                            <ContentButton>
                                <Button
                                    marginTop={sizes.tiny}
                                    type='submit'
                                    size='medium'
                                >
                                    {buttonText.confirm}
                                </Button>
                            </ContentButton>
                        </FullyCentered>
                    ) : ( <Loader />)
                }
            </Column>
        </FormContainer>
  )
}
const FormContainer = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: ${breakpoints.sm}) {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`;


const ContentButton = styled(Column)`
    width: 100%;
 
    @media screen and (min-width: ${breakpoints.sm}) {
        width: 95%;
    }
  
`;