import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';

export default function TermPartner() {
    const navigate = useNavigate();

    function goBack() {
        navigate(-1)
    }
  return (
    <Column
        paddingLeft='30px'
        paddingRight='30px'
        paddingTop='20px'
    >
        <StyledRow >
            <ContenButtonBack
                onClick={goBack}
            >
                <StyledButton>
                    <Icon name='back' />
                </StyledButton>
                <Text>Voltar</Text>
            </ContenButtonBack>
        </StyledRow>
        
        <Text name='subtitle' marginTop={'40px'} >Termos e Condições de Uso e Comercialização</Text>

        <Text>O Whizr Plataforma de Conteúdo LTDA - sob CNPJ 54.729.749/0001-01, reserva o direito de fazer atualizações nos seus Termos e Condições de Uso e Comercialização a qualquer momento. A versão atualizada estará disponível para consulta com a data de alteração indicada no documento, sendo que, a partir do momento em as alterações entrarem em vigor, e ficarem disponíveis na plataforma, é de responsabilidade do contratante revisar tais alterações. Para os casos em que o contratante continue a utilizar os serviços propostos pelo Whizr sem contestação, será entendido que as alterações propostas nos Termos e Condições de Uso foram aceitas.</Text>

        <Text>É de extrema importância que, para operar na plataforma, todas as pessoas e entidades usuárias aceitem os Termos e Condições de Uso e a Declaração de Privacidade.</Text>

        <Text name='highlight' marginTop={'20px'}>Introdução</Text>

        <Text>Olá, esse é o seu primeiro contato com o Whizr, seja muito bem-vindo!</Text>

        <Text>Os termos a seguir são constituídos a partir dos princípios que norteiam a plataforma, do relacionamento com as diferentes frentes de usuários e do bom relacionamento e respeito às normas previstas em lei. Portanto, para continuar com a sua candidatura, leia com atenção e, antes de realizar a aceitação dos termos propostos, esteja completamente ciente e de acordo com as regras que regem o documento.</Text>

        <Text>É importante ressaltarmos aqui que não é permitido acessar ou usar o Whizr para qualquer finalidade diferente daquela para a qual disponibilizamos a plataforma e os nossos serviços. </Text>


        

        <Text name='highlight' marginTop={'20px'}>Definições importantes</Text>

        <Text>Whizr: serviço disponível em plataforma web ou aplicativo, permitindo uso e cadastro de pessoas e negócios, de forma a intermediar informações, vendas ou compras entre os mesmos.</Text>

        <Text>
            Plataforma: refere-se ao acesso ao ambiente do Whizr em modo de navegação web.
        </Text>

        <Text>Aplicativo: refere-se ao acesso ao aplicativo Whizr para android.</Text>

        <Text>Serviços: refere-se aos serviços propostos pelo Whizr, sejam eles direcionados a empresas ou pessoas físicas.</Text>

        <Text>Contratante: refere-se a quem usa os serviços do Whizr para a realização de exposição de marca, criação de eventos, venda de ingressos e produtos, etc.</Text>

        <Text>Contratada: refere-se ao Whizr como plataforma intermediária entre negócios e clientes. </Text>

        <Text>Usuários: pessoas que usam os serviços disponíveis na plataforma do Whizr, sejam os mesmos usuários pessoa física ou pessoa jurídica.</Text>

        <Text>Taxa de operação: taxa que o Whizr cobra para operacionalizar os serviços.</Text>


        <Text name='highlight' marginTop={'20px'}> O Whizr</Text>

    

        <Text>O Whizr foi criado com o intuito de tornar a experiência de encontrar eventos e atividades de lazer uma busca rápida e prática. Para isso, são consolidados eventos de múltiplas plataformas no mapa e buscas personalizadas podem ser realizadas através de filtros por usuários interessados. Porém, para eventos criados por usuários parceiros ou contratantes, os usuários finais também podem realizar a aquisição de ingressos e produtos vinculados aos eventos dispostos na plataforma pelos criadores/contratantes.</Text>
        
        <Text>É importante salientar que, nesse sentido, quem disponibiliza e gerencia eventos, ingressos e produtos através de parceria direta com o Whizr, são os próprios parceiros organizadores (contratantes), ficando o Whizr responsável apenas pela intermediação do processo de oferta, venda e disponibilização dos ingressos e/ou produtos adquiridos por usuários compradores. Assim como, pela intermediação entre gateway de pagamento e contratante, permitindo o repasse do valor adquirido, já com o valor de taxa de operação descontado, ao parceiro (contratante). Esse processo leva em consideração as informações de conta passadas pelo contratante, após ser realizada a checagem de acontecimento do evento em conformidade com as regras da plataforma.</Text>

        <Text>Para a utilização do Whizr, os usuários contratantes se comprometem a fazer uso da plataforma de forma ética e adequada aos propósitos estabelecidos nestes Termos, atendendo às leis brasileiras e comprometendo-se a se abster de qualquer atividade que constitua violação das referidas normas.</Text>

        <Text name='highlight' marginTop={'20px'}>Direitos do Whizr</Text>

        <Text>O Whizr reserva o direito de realizar alterações ou modificações na plataforma, possibilitando a correção de quaisquer imprecisões, erros ou omissões e alterar ou atualizar informações a qualquer momento sem aviso prévio. Dessa forma, a plataforma não será responsável por quaisquer impactos relacionados em perda de receita, perda de contratos, lucros cessantes, perda de dados ou por qualquer perda ou dano indireto ou consequente decorrentes ou causados por ato ilícito (incluindo negligência), quebra de contrato ou como resultado de quaisquer alterações que possam ser feitas como parte do gerenciamento de nossos negócios e plataforma e de acordo com nossa Política de Privacidade.        </Text>


        <Text name='highlight' marginTop={'20px'}>Responsabilidades e atribuições</Text>

        <Text>O Whizr não produz eventos ou vende ingressos e produtos, ficando então responsável apenas pela intermediação do processo de venda e aquisição, para o caso do que é cadastrado na plataforma por parceiros. Nesse sentido, os eventos publicados na plataforma são de responsabilidade do usuário contratante, e devem ter as informações cadastradas e atualizadas de forma legítima, em conformidade com a lei e Termos da plataforma. O mesmo se aplica à criação da página de estabelecimento. Lembrando que isso se aplica apenas ao que é criado através de parceria com o Whizr, ficando os eventos de outras plataformas isentos dessas responsabilidades, pois apenas são mapeados e não criados e gerenciados dentro do Whizr.</Text>
        
        <Text>O usuário contratante será o responsável por definir quantidade de ingressos, lotes, valores, produtos, regra de acesso ao evento, regra de acesso para retirada de produtos, checagem de ingresso por modalidade (ex: política de meia entrada), políticas de reembolso, ou quaisquer outros pontos correlacionados. O Whizr não é responsável por essas definições, 
            porém poderá monitorar os eventos e estabelecimentos criados, a fim de que estes cumpram suas políticas legais.
        </Text>

        <Text>O contratante isenta o Whizr de qualquer responsabilidade civil, de natureza moral ou material, inclusive lucros cessantes e danos emergentes, advinda dos atos referentes à produção e realização de eventos. Portanto, havendo condenação judicial contra a plataforma, o Whizr poderá efetuar a cobrança do valor que for obrigada a pagar, sem a necessidade de ajuizamento de ação de regresso.</Text>

        <Text name='highlight' marginTop={'20px'}>Cancelamentos</Text>

        <Text>Em casos de cancelamentos de vendas, o usuário contratante poderá recorrer à solicitação do cancelamento e reembolso do valor da venda, desde que o processo seja realizado em até 2 dias úteis após a finalização e confirmação de evento realizado em conformidade com os Termos da plataforma. É de responsabilidade do contratante informar aos seus clientes qual a política própria de cancelamento e reembolso vigente para a marca, sem exceder os períodos máximos estipulados pelo Whizr como critérios de aceitação.</Text>
 
        <Text>Para casos em que o consumidor deseje exercer seu direito de arrependimento, o contratante deverá entrar em contato com o Whizr para solicitar o cancelamento da venda, assim como, deverá ser realizado o reembolso do valor de compra ao consumidor em um prazo máximo de sete dias pelo contratante. No caso do prazo exceder os repasses pós evento, o contratante deverá realizar o reembolso diretamente.</Text>

        <Text name='highlight' marginTop={'20px'}>
            Repasses
        </Text>

        <Text>
        O contratante deve informar dados válidos de conta bancária, a partir do cadastro das informações em seu painel de gerenciamento. Dessa forma, confirmado o acontecimento do evento em conformidade com os Termos da plataforma, o valor destinado ao contratante (já com a taxa de operação do Whizr extraída) será repassado para a conta cadastrada em até 5 dias úteis em caso de pagamentos no pix e 31 dias em caso de pagamentos no cartão de crédito estipulado pelo gateway de pagamento, contando a partir da data de finalização do evento. A taxa incidente será de 10% do valor total, exceto em casos de ingressos ou produtos com valor inferior ou igual a R$25,00, cuja taxa fixa aplicada será de R$2,50.
        </Text>

        <Text>
        Em caso de reembolsos pendentes, reclamações, cancelamento, mudanças, ou qualquer impacto substancial que ocorra, o Whizr terá direito a retenção dos valores, pelo tempo que for necessário, considerando medidas cabíveis a esse tipo de situação. Caso o contratante não tome as medidas necessárias para reembolso, o Whizr poderá fazê-lo sem necessidade de autorização ou comunicação prévia ao contratante.
        </Text>

        <Text name='highlight' marginTop={'20px'}>Funcionamento da plataforma</Text>

        <Text>
            O Whizr preza pela qualidade dos serviços oferecidos, e, portanto, se compromete a sempre entregar a melhor experiência aos usuários da plataforma. A partir disso, os recursos serão atualizados e aprimorados frequentemente.
        </Text>

        <Text>
        A consistência na disponibilidade do serviço também é um ponto de extrema importância para o Whizr. Porém, eventualmente, poderão existir casos de manutenção ou de indisponibilidade por motivos de causa maior, ficando o Whizr então a partir dos Termos presentes, isento de culpa por tais motivações. Em caso de indisponibilidade, o serviço será restabelecido com a maior agilidade possível, e, se programado, haverá comunicação sinalizando isso com antecedência aos usuários.
        </Text>

        <Text
            name='highlight' marginTop={'20px'}
        >
            Cadastro no Whizr
        </Text>

        <Text>
            O cadastro na plataforma é gratuito e poderá ser iniciado a partir do formulário disponível para parcerias. O preenchimento do formulário não garante que o usuário tenha sua conta criada na plataforma. Todos os formulários passarão por uma análise de viabilidade e veracidade de informações disponibilizadas e, caso sejam aprovados, receberão através do e-mail cadastrado os próximos passos para o prosseguimento da criação da conta.
        </Text>

        <Text>
        IMPORTANTE: em um primeiro momento, o Whizr está trabalhando com um número de parceiros limitado, a fim de manter a qualidade de operação até que seja viável expandir sem prejuízos à mesma. Isso pode ocasionar demora na resposta de aprovação ou reprovação para prosseguimento do processo de cadastro. Porém, a fila de pretensão de uso será mantida em ordem de solicitação, obedecendo os critérios de localização atendida.
        </Text>

        <Text>
        O Whizr não se responsabiliza pelas informações concedidas pelos usuários, sendo de responsabilidade deles responderem de forma verdadeira e completa, assim como as atualizarem em caso de mudanças. Além disso, é de responsabilidade do usuário manter suas credenciais de acesso em segurança, não repassando para terceiros. O não preenchimento ou descumprimento dos Termos pode acarretar em uma não aprovação ou exclusão da conta posteriormente, se identificada alguma irregularidade. Caso o Whizr identifique atividade suspeita ou algo que indique risco de fraude, poderão ser solicitados documentos pessoais ou outras formas de comprovação, com a finalidade de confirmar a identidade e os dados pessoais do usuário. É de responsabilidade do usuário responder ao processo de solicitação em tempo hábil e de forma clara.
        </Text>

        <Text>
        Ao preencher o formulário, o usuário atesta que irá utilizar o Whizr de forma ética e condizente com os presentes Termos, atendendo ao disposto nas leis brasileiras, em especial a Lei nº 9.613 de 1998, comprometendo-se a se abster de qualquer atividade que constitua violação das normas especificadas.
        </Text>

        <Text
            name='highlight' marginTop={'20px'}
        >
            Criação de eventos e vendas
        </Text>

        <Text>
        Ao criar um evento na plataforma, o usuário se compromete a prover informações verdadeiras e exatas para todos os campos de cadastro. Além disso, as informações devem se manter atualizadas, de forma a abranger todos os pontos que sejam relevantes para os consumidores. O mesmo se aplica à página do estabelecimento e aos produtos a serem vendidos. Ao publicar algo, o usuário atesta que possui todos os direitos legais sobre o evento ou produto, assim como sobre o conteúdo vinculado, também confirma que todas as informações já estão validadas para que o evento ocorra conforme anunciado, sob pena de publicidade enganosa caso algo difira. Em critério de confirmação, o contratante permite o contato do Whizr com quaisquer partes envolvidas.
        </Text>

        <Text
            name='highlight' marginTop={'20px'}
        >
            Cancelamentos e alterações
        </Text>

        <Text>
            É obrigação do contratante comunicar qualquer espécie de cancelamento e/ou mudanças importantes imediatamente ao Whizr, que avaliará o caso. Em casos sem justificativas plausíveis, poderá ser cobrada multa de 20% sobre o valor total do evento. Além disso, o contratante deverá arcar com o reembolso total dos consumidores caso o evento não ocorra ou não cumpra com os pontos anunciados. É assegurado ao Whizr o direito de regresso contra o contratante por quaisquer valores que seja obrigado a pagar aos consumidores em virtude de ação, reclamação ou processo administrativo.
        </Text>

        <Text>
            Em caso de eventos não realizados ou entregues de forma diferente do anunciado, o Whizr terá o direito de reembolsar as compras ou ainda, suspender repasses até a resolução do problema. Além disso, valores poderão ser retidos pelo Whizr também sobre outros eventos no caso de dívida.
        </Text>

        <Text
            name='highlight' marginTop={'20px'}
        >
            Propriedade intelectual do Whizr
        </Text>

        <Text>
        O uso comercial da palavra “Whizr” como marca, nome empresarial ou nome de domínio, assim como os conteúdos das telas relativas aos serviços oferecidos, são de titularidade apenas do Whizr. O uso indevido de algum conteúdo é proibido, exceto que haja autorização prévia da marca.
        </Text>

        <Text
            name='highlight' marginTop={'20px'}
        >
            Proteção de registros no Whizr
        </Text>

        <Text>O Whizr será obrigado a disponibilizar registros de acesso ou informações pessoais armazenadas na conta caso haja uma ordem judicial ou requisição de autoridade policial ou administrativa competente.</Text>

        <Text
            name='highlight' marginTop={'20px'}
        >
            Limitação de responsabilidade do Whizr
        </Text>

        <Text>
            Fica estabelecido que o Whizr não tem a responsabilidade pelas ações tomadas pelos usuários durante o uso do serviço e, portanto, não poderá ser penalizada por tais atos desses usuários. Isso se aplica inclusive em casos de atos ilícitos ou antiéticos, os quais devem responder à justiça em caráter pessoal.
        </Text>

        <Text type='bold' marginBottom={'40px'} marginTop={'20px'}>Ficou com alguma dúvida? Entre em contato com a gente através do e-mail contato@whizr.app.</Text>
       

    </Column>
  
  )
}


const StyledRow = styled(Row)`
    width: 100%;
    justify-content: center;
`;

const ContenButtonBack = styled.div`
    display: flex;
    flex-direction: row;
    width: 80px;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in-out .25s;
    & > p {
        padding-bottom: 0px;
        font-size: 16px;
        margin-left: 5px;
        color: ${colors.primaryDashboard}
    }

    &:hover {
        transform: scale(1.02)
    }

`;

const StyledButton = styled(Row)`
    width: 26px;
    padding: 5px;
    height: 26px;
    border-radius: 50%;
`;