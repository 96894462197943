import Button from 'components/_UI/Button'
import Fieldset from 'components/_UI/Fieldset'
import Text from 'components/_UI/Text'
import fieldValidations from 'helpers/fieldValidations'
import masks from 'helpers/masks'
import unformat from 'helpers/unformat'
import React from 'react'
import { useForm } from 'react-hook-form'
import { FullyCentered } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'

export default function PixForm({ onTrigger = () => false }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    function handlePayment({document}) {
        window.trigger = Math.random(Infinity);
        onTrigger({ document: unformat.getNumbers(document) });
    }
    return (
        <Column>
            <Column fullyCentralized marginTop='25px'>
                <Text type='bold'>
                    Pagamento com PIX
                </Text>

                <Text color={colors.night} name='small' textAlign={'center'}>
                    Ao finalizar a compra, será gerado um código de pagamento. Use o aplicativo do seu banco ou carteira digital para realizar o pagamento.
                </Text>
            </Column>


            <Fieldset
                marginTop='20px'
                label='CPF *'
                layout='squired'
                name="document"
                register={register}
                mask={masks.cpf}
                validations={fieldValidations.cpf('CPF')}
            />

            {errors.holder?.message && (
                <Column>
                    <Text color={colors.danger} name='small'> {errors.holder?.message} </Text>
                </Column>
            )}

            <Button
                marginTop={'10px'}
                marginBottom={'30px'}
                variant='featured'
                onClick={handleSubmit(handlePayment)}
            >
                Pagar agora
            </Button>
        </Column>
    )
}
