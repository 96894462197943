import React from 'react';
import PresentationBackground from 'modules/onboarding/common/PresentationBackground';
import StepRegisterProvider from 'context/login/StepRegister';
import SignUp from 'modules/onboarding/sign-up/SignUp';
import TagsProvider from 'context/events/Tags';

function Register() {
    return (
            <TagsProvider>
                <StepRegisterProvider>
                    
                    <PresentationBackground
                        ComponentToRender={() => <SignUp />}
                        is='signUp'
                    />
                </StepRegisterProvider>
            </TagsProvider>

    )
};

export default Register;

