import api from "api";

const events = {
    get: (grouped, city) => {
        return api().get('/events', {
            params: {
                city: city,
                grouped,
                limit: 500
            }
        })
    },

    filter: (payload) => {
        return api().get('/events', {
            params: { ...payload }
        })
    },

    near: ({ lat, long }) => {
        return api().get(`/events/near/${Number(lat)}/${Number(long)}/5`)
    },
    allNears: ({ lat, long }) => {
        return api().get(`/events/near/${Number(lat)}/${Number(long)}`)
    },

    findMultiples: ({ lat, long }) => {
        return api().get(`/events`, {
            params: { lat, long }
        })
    },

    getBySlug: (payload) => {
        return api().get(`/events/detail/${payload}`)
    },

    autocomplete: (payload) => {
        return api(false, true).get(`/search/${payload}`);
    },

    create: (payload) => {
        return api().post('/events', payload);
    },
    
    update: (slug, payload) => {
        return api().put(`/events/${slug}`, payload);
    },

    delete: (payload) => {
        return api().delete(`/events/${payload}`);
    },

    myEvents: (params) => {
        return api().get('/events/my-events', {
            params: {...params}
        });
    },

    searchEvents: (payload) => {
        return api().get(`/events/my-events?name=${payload}`);
    },

    deleteMyEvents: (payload) => {
        return api().delete(`/events/${payload}`);
    },


};

export default events;  