import establishmentsApi from 'api/requests/establishments';
import ButtonSelectDash from 'components/_UI/ButtonSelectDash';
import Icon from 'components/_UI/Icon';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import { useEstablishment } from 'context/establishments/Establishment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';


export default function FeaturesSelects({ data, isEdit, loading, setFeatures = () => false }) {
    const [selectedItems, setSelectedItems] = useState(isEdit?.map(item => item.id) || [])
    const { establishment, setEstablishment } = useEstablishment()


    useEffect(() => {
        if (isEdit) {
            const items = isEdit.map(item => item.id)
            setSelectedItems(items)
            // items.length && setEstablishment({ ...establishment, features: items })
            setFeatures(items);
        }
    }, [isEdit, loading, data])

    const handleSelectItem = (id) => {
        setSelectedItems(prevSelectedItems => {
            let newSelectedItems

            if (prevSelectedItems.includes(id)) {
                newSelectedItems = prevSelectedItems.filter(item => item !== id)
            } else {
                newSelectedItems = [...prevSelectedItems, id]
            }

            // setEstablishment({
            //     ...establishment,
            //     features: newSelectedItems
            // })
            setFeatures(newSelectedItems);

            return newSelectedItems
        })
    }


    return (
        <Column fullyCentralized marginTop='25px'>
            <Text size='small' type='bold' color={colors.occasionalPurple}>Características do local</Text>
            <Text size='small' paddingBottom={'20px'} textAlign={'center'} color={colors.night}>
                Escolha as características que você busca no local.
            </Text>
            <StyledList>
                {
                    loading ? (
                        <FullyCentered>
                            <Loader />
                        </FullyCentered>
                    ) : (
                        data?.map(el => {
                            return (
                                <SelectItem
                                    key={el.id}
                                    selected={selectedItems.includes(el.id)}
                                    onClick={() => handleSelectItem(el.id)}
                                >
                                    <ContentIcon>
                                        <Icon
                                            name={el.icon}
                                            color={selectedItems.includes(el.id) ? colors.neutral : colors.night}
                                        />
                                    </ContentIcon>
                                    <Text
                                        name='mini'
                                        textAlign={'center'}
                                        paddingBottom={0}
                                        type='bold'
                                        ellipsisAt={'98%'}
                                        color={selectedItems.includes(el.id) ? colors.neutral : colors.night}
                                    >
                                        {el.name}
                                    </Text>
                                </SelectItem>
                            )
                        })
                    )
                }
            </StyledList>
        </Column>
    )
}

const StyledList = styled(Row)`
    flex-wrap: wrap;
    justify-content: space-around;
    & > div {
        width: 120px;
        height: 57px;
    }
    @media screen and (min-width: ${breakpoints.sm}) {
        & > div {
            width: 130px;
            height: 60px;
        }
    }
`;

const ContentIcon = styled.div`
    width: 22px;
    height: 22px;
    margin-bottom: 4px;
`;

const SelectItem = styled.div`
            width: 30%;
            margin-bottom: 3%;
            border: 1px solid ${colors.night};
            padding: 10px 5px;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            @media screen and (min-width: ${breakpoints.lg}){
                width: 15%;
    }

            ${({ selected }) => selected && `
        background: #4617ad;
        border: 1px solid #4617ad;
    `};
`
