import React from 'react';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import CardFilter from '../card/CardFilter';
import colors from 'theme/colors';
import { Row } from 'styled/alignment/Row';
import Text from 'components/_UI/Text';
import { useDetailVisible } from 'context/dash/DetailVisible';

export default function FilterGroupItem({ data, title }) {
  const { setDetailVisible } = useDetailVisible()

  return (
    <>
      <StyledRow>
        <StyledText>
          <Text name='mini'>{title}</Text>
        </StyledText>
      </StyledRow>
      <StyledBody>
        {data?.items?.data?.map((el) => (
          <EventContent key={el?.id} onClick={() => setDetailVisible(el)}>
            <CardFilter data={el} />
          </EventContent>
        ))}
      </StyledBody>
    </>
  );
}

const StyledBody = styled(Column)`
  width: 100%;
`;

const EventContent = styled(Column)`
  cursor: pointer;
  &:last-child {
    padding-bottom: 10px;
  }
  &:hover {
    transform: scale(1.01);
  }
`;

const StyledRow = styled(Row)`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    background: ${colors.nightLight};
    height: 1px;
    position: absolute;
    top: 6px;
    right: 2px;
  }
`;

const StyledText = styled.div`
  background: ${colors.neutralLight};
  position: sticky;
  padding: 0 6px 0 6px;
  & > p {
    color: ${colors.primaryDashboard};
  }
`