import React, { createContext, useState, useContext } from 'react';

const CurrentLocationContext = createContext();

export default function CurrentLocationProvider({ children }) {
    const [currentLocation, setCurrentLocation] = useState(null);

    return (
        <CurrentLocationContext.Provider value={{ currentLocation, setCurrentLocation }}>
            {children}
        </CurrentLocationContext.Provider>
    );
}

export function useCurrentLocation() {
    const context = useContext(CurrentLocationContext);

    if (!context) throw new Error('useCurrentLocation must be used within a CurrentLocationProvider');

    const { currentLocation, setCurrentLocation } = context;
    return { currentLocation, setCurrentLocation };
}