import Button from 'components/_UI/Button'
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import buttonText from 'texts/buttons/buttonText';
import { useNavigate } from 'react-router-dom'
import Fieldset from 'components/_UI/Fieldset';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { StyledForm } from 'styled/UI/StyledForm';
import { useForm } from 'react-hook-form';
import fieldValidations from 'helpers/fieldValidations';
import { HorizontalCenter } from 'styled/alignment/Center';
import colors from 'theme/colors';
import { useUser } from 'context/user/User';
import loginApi from 'api/requests/login';
import Loader from 'components/_UI/Loader';
import paths from 'routes/paths';
import Countdown from 'components/commons/Countdown';

export default function ConfirmEmailModal({
    fromSignIn = false,
    setShowConfirmModal,
    role = false,
    onLogged = () => false
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { user, setUser } = useUser()
    const [error, setError] = useState('')
    const [resendError, setResendError] = useState('')
    const [resendCode, setResendCode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [resendLoading, setResendLoading] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => { }, [user])
    const navigate = useNavigate();

    async function confirmCode({ code }) {
        setError(false);
        setIsLoading(true);
        try {
            await loginApi.confirmEmail(user?.email, code)
            const payload = {
                email: user?.email,
                password: user?.password
            }

            if (fromSignIn) {
                onLogged(payload);
                onClose();
                return
            }

            login(payload)

        } catch (error) {
            setIsLoading(false)
            
            if (error?.response?.status == 500) {
                setError(error?.response?.data?.message);
                return
            }
            setError('Ocorreu um erro.')
        }
    }

    const login = async ({ email, password }) => {
        const payload = { email, password };
        try {
            const { data } = await loginApi.signIn(payload);

            setUser(data);

            localStorage.setItem("token", data.accessToken);
            redirectUser()
            window.location.reload();
            // getMe()
        } catch (error) {
            if (error?.response?.status === 403) {
                setError('Ocorreu um erro.')
            }
        } finally {
            setIsLoading(false);
        }
    };

    function redirectUser(){
        if (!role) {
            navigate(paths.home);
            return
        }

        if (role == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID) {
            navigate(paths.dashboard.profile);
            return
        }
        
        if (role == import.meta.env.VITE_PRODUCER_ROLE_ID) {
            navigate(paths.dashboard.resume);
        }
    }

    function onClose() {
        setSearchParams((prevSearchParams) => {
            const newSearchParams = new URLSearchParams(prevSearchParams);

            newSearchParams.delete('confirmEmail');

            return newSearchParams;
        });
        if (fromSignIn) {
            setShowConfirmModal(false);
        }
        
        navigate(paths.login);
    }

    async function resend() {      
        setResendLoading(true)
        try {
            await loginApi.resendConfirmCode(user)
            setResendCode(false)
        } catch (error) {
            if (error?.response?.status == 500) {
                setResendError('Ocorreu um erro ao reenviar o código')
            }
        } finally {
            setResendLoading(false)
        }
    }

    return (
        <Modal isOpen={true} onClose={onClose}>
            <StyledForm onSubmit={handleSubmit(confirmCode)}>
                <Text name='highlight'> Confirme seu e-mail </Text>
                {!resendCode ? (
                    <ModalWrapper>
                    <Text marginTop='5px'>Foi enviado um código de confirmação para <b>{user?.email}</b>. Por favor, verifique sua caixa de entrada.</Text>
                    <Fieldset
                        layout='straight-center-dark'
                        placeholder={'Código'}
                        marginTop='15px'
                        register={register}
                        name='code'
                        validations={fieldValidations.code('código')}

                    />
                    <Column fullyCentralized marginTop="5px">
                        <Text color={colors.night} name='small'>Seu código expira em:</Text>
                        <Countdown time={20} onFinishTime={() => setResendCode(true)}/>
                    </Column>
                    {errors && (
                        <HorizontalCenter marginTop="10px">
                            <Text color={colors.danger}> {errors.code?.message} </Text>
                        </HorizontalCenter>
                    )}
                    {error && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {error} </Text>
                        </HorizontalCenter>
                    )}
                    {
                        !isLoading ? (
                            <Button marginTop='10px' type={'submit'} > {buttonText.confirm} </Button>
                        ) : (
                            <Loader />
                        )
                    }
                </ModalWrapper>
                ) : (
                    <>
                        <Column fullyCentralized marginTop="5px">
                            <Text color={colors.danger} name='subtitle'>Seu código expirou</Text>
                            <Text> Para enviar novamente, clique no botão abaixo</Text>
                            {
                                resendLoading ? (
                                    <Loader />
                                ) : (
                                    <Button onClick={resend}>{buttonText.resend} E-mail</Button>
                                )
                            }
                        </Column>
                        
                        {resendError && (
                            <Text color={colors.danger}>{resend}</Text>
                        )}
                    </>
                )
                
                }
            </StyledForm>
        </Modal>
    )
}

const ModalWrapper = styled(Column)`
    margin-top: 10px;
    width: 100%;
    /* background: red; */
`
