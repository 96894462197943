import Text from 'components/_UI/Text'
import { useEventFilter } from 'context/events/EventFilter'
import React, { useEffect, useState } from 'react'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'

const minRange = 1
const maxRange = 50

export default function RangeSelect() {
    const [rangeValue, setRangeValue] = useState(25)
    
    const { eventFilter, setEventFilter } = useEventFilter();

    useEffect(() => {
        setEventFilter({...eventFilter, distance_range: rangeValue})
    }, [])

    function onChangeRange(range) {
        setRangeValue(range)
        setEventFilter({...eventFilter, distance_range: range})
    }
    
    return (
        <Column 
            marginTop='18px' 
            paddingLeft='25px'
            paddingRight='25px'
        >
            <Text size='small' type='bold' marginLeft='6px'> Distância </Text>
            <Text size='small' color={colors.night}>Escolha o alcance da sua busca.</Text>
            
            <Text marginTop='8px'> 
                {rangeValue} km 
            </Text>

            <input
                id='filter_range'
                type='range'
                value={rangeValue}
                min={minRange}
                max={maxRange}
                onChange={(e) => onChangeRange(e.target.value)}
            />

        </Column>
    )
}
