import styled, { css } from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import colors from 'theme/colors';
import sizes from 'theme/sizes';

export const StepsContainer = styled(FullyCentered)`
    flex-direction: row;
    height: auto;
`;

export const StepsDivider = styled.div`
    width: ${sizes.massive};
    height: ${sizes.quark};
    margin: ${sizes.tiny};
    background: ${colors.neutralDark};
`;

export const Step = styled.div`
    background: transparent;
    border-radius: 50%;
    border: 2px solid ${colors.neutralDark};
    width: ${sizes.tiny};
    height: ${sizes.tiny};
    cursor: pointer;
    padding: ${sizes.nano};
    transition: all ease .35s;

    ${({ active }) => active && css`
        border: ${sizes.quark} solid ${colors.neutralLight};
        background: ${colors.neutralLight};
        cursor: default;
    `}
`;