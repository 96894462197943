import React, { useState } from 'react'
import styled from 'styled-components'
import breakpoints from 'theme/breakpoints'
import support from 'theme/support'

import { StyledTitle, StyledParagraph } from 'styled/UI/StyledText'
import CardCollapse from 'components/_UI/CardCollapse'
import { FullyCentered } from 'styled/alignment/Center'
import colors from 'theme/colors'
import Icon from 'components/_UI/Icon'
import Text from 'components/_UI/Text'
import { Row } from 'styled/alignment/Row'
import Button from 'components/_UI/Button'
import { StyledRouteLink } from 'styled/UI/StyledButton'
import FeedbackForm from 'modules/users/form/FeedbackForm'
import Modal from 'components/_UI/Modal'
import { Column } from 'styled/alignment/Column'
import buttonText from 'texts/buttons/buttonText'
import sizes from 'theme/sizes'
import useToast from 'hooks/useToast'
import ToastContainer from 'components/_UI/ToastsContainer'

export default function SupportContent() {
    const [openCards, setOpenCards] = useState(Array(support.faq.length).fill(false));
    const [openFeedbackForm, setOpenFeedbackForm] = useState(false)
    const [modalSucess, setModalSucess] = useState(false);

    const { toasts, toast } = useToast();

    function toggleCard(index) {
        const newOpenCards = [...openCards];
        newOpenCards[index] = !newOpenCards[index];
        setOpenCards(newOpenCards);
    };

    async function copyToClipboard(value) {
        if (navigator?.clipboard?.writeText) {
            try {
                await navigator.clipboard.writeText(value)
                toast.success('Copiado com sucesso!', { position: 'top-center' })
            } catch (error) {
                copyToClipboardFallback(value)
            }
        }
    }


    function copyToClipboardFallback(value) {
        const input = document.createElement('input');
        input.value = value;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        toast.success('Copiado com sucesso!', { position: 'top-center' })
    }

    return (
        <DashSupportContainer>
            <ToastContainer toasts={toasts} />
            <DashSupportCards>
                <DashSupportCard>
                    <ContentHeader>
                        <StyledIcon>
                            <Icon name='mail' color={colors.night} />
                        </StyledIcon>
                        <Text name='subtitle' color={colors.night}>E-mail</Text>
                    </ContentHeader>
                    <ContentText>
                        <Text color={colors.night}>Entre em contato através do E-mail: <b>{support.contact.email}</b></Text>
                    </ContentText>

                    <StyledButton>
                        <Button category='squiredBorded' variant='featured' onClick={() => copyToClipboard(support.contact.email)}>
                            Copiar E-mail
                        </Button>
                    </StyledButton>

                </DashSupportCard>
                <DashSupportCard>
                    <ContentHeader>
                        <StyledIcon>
                            <Icon color={colors.night} name='whatsapp' />
                        </StyledIcon>
                        <Text color={colors.night} name='subtitle'>Whatsapp</Text>
                    </ContentHeader>

                    <ContentText>
                        <Text color={colors.night}> Entre em contato diretamente pelo nosso <b>Whatsapp</b></Text>
                    </ContentText>

                    <StyledButton>
                        <LinkWhatsapp to='https://wa.me/539991367182' target='_blank'>
                            Entrar em contato
                        </LinkWhatsapp>
                    </StyledButton>

                </DashSupportCard>
                <DashSupportCard>
                    <ContentHeader>
                        <StyledIcon>
                            <Icon color={colors.night} name='suggestions' />
                        </StyledIcon>
                        <Text name='subtitle' color={colors.night}>Sugestões</Text>
                    </ContentHeader>

                    <ContentText>
                        <Text color={colors.night}> Envie <b>Sugestões</b> e <b>FeedBacks</b></Text>
                    </ContentText>

                    <StyledButton>
                        <Button category='squiredBorded' variant='featured' onClick={() => setOpenFeedbackForm(true)}>
                            Enviar sugestões
                        </Button>
                    </StyledButton>
                </DashSupportCard>
            </DashSupportCards>

            <DashSupportCollapsesWrapper>
                <StyledTitle name="title" color={colors.night}>Perguntas Frequentes</StyledTitle>

                <StyledParagraph marginBottom={'20px'} color={colors.night}>
                    Aqui você encontra respostas para as dúvidas mais comuns sobre como utilizar nossa plataforma. Desde a criação de eventos até a gestão de inscrições e pagamentos, organizamos as informações para que você tenha uma experiência simples e eficiente. Se não encontrar o que procura, enre em contato com a nossa equipe de suporte, que está sempre pronta para ajudar!
                </StyledParagraph>

                {
                    support.faq.map((el, key) => (
                        <CardCollapse
                            key={key}
                            title={el.question}
                            content={el.response}
                            isOpen={openCards[key]}
                            onClick={() => toggleCard(key)}
                        />
                    ))
                }
            </DashSupportCollapsesWrapper>

            <Modal isOpen={openFeedbackForm} onClose={() => setOpenFeedbackForm(false)}>
                <FeedbackForm onClose={() => setOpenFeedbackForm(false)} setModalSucess={setModalSucess} />
            </Modal>


            <Modal isOpen={modalSucess} onClose={() => setModalSucess(false)}>

                <Text name='default' type='bold'>Sugestões salvas com sucesso!</Text>

                <Column paddingRight='15%' paddingLeft='15%' marginTop={sizes.normal}>
                    <Button onClick={() => setModalSucess(false)}>
                        {buttonText.understood}
                    </Button>
                </Column>
            </Modal>

        </DashSupportContainer>
    )
}

const DashSupportContainer = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const DashSupportCards = styled.div`
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    background: ${colors.neutralLight};
    padding: 10px;
    border-radius: 4px;
    
    @media screen and (min-width: ${breakpoints.sm}) {
        padding: 30px;
    }
    @media screen and (min-width: ${breakpoints.xl}){
        gap: .5rem 1.5rem;
        justify-content: space-between;
    }
`;

const DashSupportCard = styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0 3px 5px ${colors.nightLight};
    border-radius: .25rem;
    padding: 24px;
    background: ${colors.neutralLight};

    @media screen and (min-width: ${breakpoints.md}){
        width: 30%;
    }

`;

const DashSupportCollapsesWrapper = styled.section`
    width: 100%;
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: ${colors.neutralLight};
    padding: 10px;
    border-radius: 4px;
    @media screen and (min-width: ${breakpoints.sm}) {
        padding: 30px;
    }
`;

const ContentHeader = styled(Row)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > h2 {
        padding-bottom: unset;
        color: ${colors.primaryDashboard};
    }
`;

const StyledIcon = styled.div`
    margin-right: 5px;
    width: 35px;
    height: 30px;
`;

const LinkWhatsapp = styled(StyledRouteLink)`
    cursor: pointer;
    transition: all ease .3s;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    background: ${colors.secondaryPurple};
    border: 1px solid #3F6BC6;
    color: #F7F7F7;
    border-radius: 6px;
    font-weight: 700;
    box-shadow: none;
    padding: 10px 28px;
    font-size: 14px;

`;

const ContentText = styled.div`
    display: flex;
    text-align: center;
    max-width: 100%;
    justify-content: center;
    & > p {
        color: ${colors.night};
    }
`;

const StyledButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    // background: green;
    & > * {
        max-width: 400px;
    }
`;