import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors';
import Text from './Text';
import sizes from 'theme/sizes';

export default function CardCollapse({ title, content, isOpen, onClick }) {
    return (
        <CollapseContainer isOpen={isOpen}>
            <CardTitle onClick={onClick}>  
                <Text color={colors.night} name='highlight'>{title}</Text>
                <Arrow isOpen={isOpen}/>
            </CardTitle>
            <CardContent isOpen={isOpen}>{content}</CardContent>
        </CollapseContainer>
    )
}

const CollapseContainer = styled(Column)`
    height: auto;
    box-shadow: 1px 0 5px #ccc;
    margin-bottom: 14px;
    border-radius: 4px;
    background: ${colors.neutralLight};

`;
const CardTitle = styled.div`
    width: 100%;
    padding: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    & > h3 {
        padding-bottom: 0 !important;
    }
`;

const CardContent = styled.div`
    width: 100%;
    transition: all ease-in-out .2s;
    height: 0;
    opacity: 0;

    ${({isOpen}) => isOpen && `
        height: auto;
        opacity: 1;
        padding: 4px 30px 30px 30px;
    `}
`;

const Arrow = styled.div`
    width: ${sizes.small};
    height: ${sizes.small};
    background: ${colors.white};
    border-right: 1px solid ${colors.primaryDashboard};
    border-bottom: 1px solid ${colors.primaryDashboard};
    transform: rotate(45deg);
    margin-right: ${sizes.intermediary};
    transition: all ease-in-out .25s;
    ${({ isOpen }) => isOpen && `
        transform: rotate(225deg);
        margin-top: ${sizes.tiny};
    `}
    
`;