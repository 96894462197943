import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import CategoriesSelects from './CategoriesSelects';
import TypesSelects from './TypesSelects';
import DateSelects from './DateSelects';
import { useEventFilter } from 'context/events/EventFilter';
import Button from 'components/_UI/Button';
import eventsApi from 'api/requests/events';
import FiltersResult from './FiltersResult';
import Loader from 'components/_UI/Loader';
import { Column } from 'styled/alignment/Column';
import { useSearchParams } from 'react-router-dom';
import RangeSelect from './RangeSelect';
import date from 'helpers/date';
import Fieldset from 'components/_UI/Fieldset';
import colors from 'theme/colors';
import breakpoints from 'theme/breakpoints';
import { useCurrentLocation } from 'context/user/CurrentLocation';
import { useUserLocation } from 'context/user/UserLocation';
import InputWithAutocomplete from 'components/_UI/InputWithAutocomplete';

let count = 0;

export default function Selects({
    searchTrigger,
    isRouteParam,
    backData,
    setSearchInputValue, ß
}) {

    const { eventFilter, setEventFilter } = useEventFilter();

    const [searchParams, setSearchParams] = useSearchParams();

    const [result, setResult] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [loading, setLoading] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [error, setError] = useState(false);
    const [searchResultParams, setSearchResultParams] = useState(false);
    const { currentLocation } = useCurrentLocation();
    const { userLocation } = useUserLocation();

    const resultRef = useRef(null);

    useEffect(() => {
        if (searchTrigger && eventFilter) {
            filterEvents();
        }

        if (isRouteParam && count === 0) {
            count++
            filterEvents(isRouteParam)
        }

        if (searchTrigger && backData) {
            filterEvents(backData)
        }

        if (backData) {
            filterEvents(backData)
        }

    }, [searchTrigger, backData])

    function createTagsData() {
        let formatedTags = ''

        eventFilter?.categories?.map((el, index) => {
            if (index === 0) {
                formatedTags = el
            }

            if (index > 0) {
                formatedTags = `${formatedTags},${el}`
            }
        })
        return formatedTags
    }

    function validatedPayload(data) {
        let payload = data;

        if (!eventFilter?.term) {
            delete payload.term
        }

        if (!eventFilter?.dates) {
            delete payload.filter_date
        }

        if (eventFilter?.dates === 'custom') {
            delete payload.filter_date
            payload = {
                ...payload,
                min_date: date.unformat(eventFilter?.customFields?.from),
                max_date: date.unformat(eventFilter?.customFields?.to),
            }
        } else {
            delete payload.min_date
            delete payload.max_date
        }


        if (!eventFilter?.classification_id) {
            delete payload.classification_id
        }

        if (!eventFilter?.categories?.length) {
            delete payload.tags
        }

        if (!eventFilter?.distance_range) {
            delete payload.distance_range
        }

        if (eventFilter?.distance_range && (userLocation.lat && userLocation.long)) {
            payload = {
                ...payload,
                lat: userLocation.lat,
                long: userLocation.long
            }
        }

        return payload
    }

    function createPayload(routeParam) {
        if (routeParam) {
            let payload = routeParam

            if (!payload.term) {
                delete payload.term
            }

            if (!payload.filter_date) {
                delete payload.filter_date
            }

            if (!payload.classification_id) {
                delete payload.classification_id
            }

            if (!payload.tags) {
                delete payload.tags
            }

            if (!payload.distance_range) {
                delete payload.distance_range
            } else {
                payload = {
                    ...payload,
                    lat: userLocation.lat,
                    long: userLocation.long
                }
            }

            if (!payload.min_date) {
                delete payload.min_date
            }

            if (!payload.max_date) {
                delete payload.max_date
            }

            return payload
        }

        const payload = {
            city: currentLocation?.city,
            term: eventFilter?.term,
            filter_date: eventFilter?.dates,
            classification_id: eventFilter?.classification_id,
            tags: eventFilter?.categories?.length && createTagsData(),
            distance_range: eventFilter?.distance_range,
        }

        return validatedPayload(payload)
    }

    async function filterEvents(routeParam) {
        setLoading(true);

        try {
            const { data } = await eventsApi.filter(routeParam ? createPayload(routeParam) : createPayload());
            if (createPayload() && !routeParam) {
                setSearchParams({ ...createPayload() })
                setSearchResultParams(createPayload())
            } else {
                setSearchParams({ ...createPayload(routeParam) })
                setSearchResultParams(createPayload(routeParam))
            }

            data?.data && setResult(data.data)
            setShowResult(true)
            setRefreshData(true)
            setError(false)
            setEventFilter(false)
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false);
            resultRef.current.scrollIntoView({ behavior: 'smooth' });
            routeParam = false
        }
    }

    return (
        <>
            {
                loading
                    ? (
                        <Column height='100%' fullyCentralized>
                            <Loader />
                        </Column >
                    )
                    : (
                        <SelectsContainer>
                             <InputWithAutocomplete
                                placeholder='Pesquisar'
                                id='filter_search_input'
                                layout='roundedDark'
                                inputValue={eventFilter?.term || ''}
                                badgeIcon='search'
                                badgeIconColor={colors.neutral}
                                isBadgeClickable={true}
                                badgeIconBackground='#4F86F7'
                                // trigger={searchTrigger}
                                onBadgeClick={filterEvents}
                                onChange={(e) => {
                                    setEventFilter({ ...eventFilter, term: e })
                                }}
                            />

                            {(userLocation?.lat && userLocation?.long) && <RangeSelect />}

                            <DateSelects />
                            <TypesSelects refresh={refreshData} />
                            <CategoriesSelects refresh={refreshData} />

                            <Button
                                id='filter_button'
                                variant={!eventFilter ? 'disabled' : 'primary'}
                                onClick={() => filterEvents()}
                            >
                                Buscar
                            </Button>
                        </SelectsContainer >
                    )
            }

            {
                showResult &&
                <FiltersResult
                    routeParams={searchResultParams}
                    ref={resultRef}
                    data={result}
                    error={error}
                />
            }
        </>
    )
}

const SelectsContainer = styled(FullyCentered)`
    flex-direction: column;
    text-align: center;

    @media screen and (min-width: ${breakpoints.lg}) {
        & > div:nth-child(1) {
            display: none;
        }
    }
`;

