import PresentationBackground from 'modules/onboarding/common/PresentationBackground'
import React from 'react'
import PartnerForm from './form/PartnerForm'

export default function RegisterPartner() {
  return (
    <>
      <PresentationBackground
        ComponentToRender={() => <PartnerForm />}
        is='createPartner'
      />
    </>
  )
}
