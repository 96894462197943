// ToastContainer.js
import React from 'react';
import styled from 'styled-components';
import Toast from './ToastWrapper';

const ToastContainer = ({ toasts }) => {
  return (
    <Container>
      {toasts.map((toast) => (
        <Toast key={toast.id} message={toast.message} type={toast.type} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
`;


export default ToastContainer;
