import Loader from 'components/_UI/Loader'
import Text from 'components/_UI/Text'
import React, { useState, useEffect } from 'react'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'
import { FullyCentered, HorizontalCenter } from 'styled/alignment/Center'
import establishmentsApi from 'api/requests/establishments'
import { useEstablishmentTags } from 'context/establishments/EstablishmentTags'
import { useEstablishment } from 'context/establishments/Establishment'
import ButtonSelectDash from 'components/_UI/ButtonSelectDash'
import styled from 'styled-components'
import { Row } from 'styled/alignment/Row'
import breakpoints from 'theme/breakpoints'
import Modal from 'components/_UI/Modal'

export default function CategoriesInfo({ data, error, loading, isEdit, setCategories = () => false }) {
    const { establishment, setEstablishment } = useEstablishment()
    const { establishmentTags } = useEstablishmentTags()

    const [selectedItems, setSelectedItems] = useState(isEdit?.map(item => item.id) || [])
    const [showErrorModal, setShowErrorModal] = useState(false)

    useEffect(() => {
        if (isEdit) {
            const items = isEdit.map(item => item.id)
            setSelectedItems(items)
            // items.length && setEstablishment({...establishment, tags: items })
            setCategories(items);
        }
    }, [isEdit, data, loading, error])



    const handleSelectItem = (id) => {
        setSelectedItems(prevSelectedItems => {
            let newSelectedItems

            if (prevSelectedItems.includes(id)) {
                newSelectedItems = prevSelectedItems.filter(item => item !== id)
            } else {
                if (prevSelectedItems.length >= 5) {
                    setShowErrorModal(true)
                    return prevSelectedItems // Retorna sem modificar a seleção
                }
                newSelectedItems = [...prevSelectedItems, id]
            }

            // setEstablishment({
            //     ...establishment,
            //     tags: newSelectedItems
            // })

            setCategories(newSelectedItems);
            return newSelectedItems
        })
    }

    return (
        <Column marginTop='40px' verticalCenter>
            <Text size='small' type='bold' color={colors.occasionalPurple}> Categorias</Text>
            <Text size='small' color={colors.night} textAlign={'center'}>Selecione até 5 categorias que seu estabelecimento se encaixe.</Text>

            <StyledList>
                {
                    loading ? (
                        <FullyCentered>
                            <Loader />
                        </FullyCentered>
                    ) : (
                        data?.map(el => (
                            <SelectItem
                                key={el.id}
                                selected={selectedItems.includes(el.id)}
                                onClick={() => handleSelectItem(el.id)}
                            >
                                <Text
                                    name='mini'
                                    textAlign={'center'}
                                    paddingBottom={0}
                                    type='bold'
                                    ellipsisAt={'98%'}
                                    color={selectedItems.includes(el.id) ? colors.neutral : colors.night}
                                >
                                    {el.name}
                                </Text>
                            </SelectItem>
                        ))
                    )
                }
            </StyledList>

            {showErrorModal && (
                <Modal isOpen={true} onClose={() => setShowErrorModal(false)}>
                    <Text
                        type='bold'
                        name='normal'
                        paddingRight={'50px'}
                        paddingLeft={'50px'}
                        paddingTop={'20px'}
                        paddingBottom={'20px'}
                    >
                        Você pode selecionar no máximo 5 categorias.
                    </Text>
                </Modal>
            )}

            {error && (
                <HorizontalCenter>
                    {/* {error === 'max' && (
                        <Modal isOpen={true}>
                            <Text>Você pode selecionar no máximo 5 categorias.</Text>
                        </Modal>
                    )} */}

                    {error === 'min' && (
                        <Text color={colors.danger}> Você deve selecionar pelo menos uma categoria </Text>
                    )}
                </HorizontalCenter>
            )}
        </Column>
    )
}

const ContainerSelect = styled(Row)`
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 5px;
   
    & > div > p {
        display: flex;
        align-items: center;
        padding: 8px;
        text-transform: capitalize;
    }
    & > div {
        width: 120px;
        height: 30px;
    }
    @media screen and (min-width: ${breakpoints.sm}) {
        & > div {
            width: 130px;
            height: 40px;
        }
    }
`

const StyledList = styled(Row)`
    flex-wrap: wrap;
    justify-content: space-around;
`

const SelectItem = styled.div`
    width: 30%;
    margin-bottom: 3%;
    border: 1px solid ${colors.night};
    padding: 10px 5px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media screen and (min-width: ${breakpoints.lg}){
        width: 15%;
    }

    ${({ selected }) => selected && `
        background: #4617ad;
        border: 1px solid #4617ad;
    `};
`
