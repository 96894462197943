import React, { useState, useEffect} from 'react'
import sizes from 'theme/sizes';
import Button from './Button'

export default function ButtonSelect({
    children,
    isActive,
    defaultActive = false,
    isVariant = false,
    id,
    onToggle
}) {
    const states = {
        active: 'ACTIVE',
        inative: 'INATIVE',
    }
    const [currentState, setCurrentState] = useState(isActive);
    const [stateButton, setStateButton] = useState('light');
    useEffect(() => {
        
        if(isVariant && !isActive) {
            setStateButton(isVariant)
        }
        
        if(!isVariant && isActive) {
            setStateButton(isVariant)
        }
        if(isVariant && isActive) {
            setStateButton(isVariant)
        }

        if (defaultActive) {
            if (currentState === states.inative || !currentState) {
                setStateButton('dark')
                setCurrentState(states.active);
                return;
            }
            if (currentState === states.active) {
                setStateButton('light')
                setCurrentState(states.inative);
                return;
            }
            
        }
        
    }, [isActive, isVariant, defaultActive])
        
    const toggleState = () => {
        if (currentState === states.inative || !currentState) {
            setStateButton('dark')
            setCurrentState(states.active);
            onToggle(states.active)
            return;
        }
        if (currentState === states.active) {
            setStateButton('light')
            setCurrentState(states.inative);
            onToggle(states.inative)
            return;
        }
        
        setCurrentState(states.active);
        onToggle(states.active)
        
    };

    return (
        <Button
            variant={
               stateButton === isVariant ? isVariant : false
                || stateButton === 'light' ? 'light' : false
                || stateButton === 'dark' ? 'dark' : 'light'
            }
            size={'default'}
            id={id}
            onClick={(e) => toggleState(e)}
            marginRight={sizes.medium}
            marginBottom={sizes.medium}
            textTransform='capitalize'
        >
            {children}
        </Button>
    )
};

