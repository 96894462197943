import Text from 'components/_UI/Text';
import { useEstablishmentFilter } from 'context/establishments/EstablishmentFilter';
import React, { useState } from 'react';
import { Column } from 'styled/alignment/Column';
import Select from 'react-select';
import colors from 'theme/colors';
import Radio from 'components/_UI/Radio';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import breakpoints from 'theme/breakpoints';

export default function EstablishmentHoursSelects() {
    const [radioSelected, setRadioSelected] = useState(0);
    const [selectedDay, setSelectedDay] = useState(null);

    const { establishmentFilter, setEstablishmentFilter } = useEstablishmentFilter();

    function handleRadioChange() {
        const newRadioValue = radioSelected === 0 ? 1 : 0;
        setRadioSelected(newRadioValue);

        if (newRadioValue === 1) {
            setEstablishmentFilter({
                ...establishmentFilter,
                open: newRadioValue,
                operating_days: null 
            });
        } else {
            setEstablishmentFilter({
                ...establishmentFilter,
                open: newRadioValue
            });
        }
    };

    // Função para lidar com a seleção do dia
    function handleDaySelect(item) {
        setRadioSelected(0);
        const shortDay = item.label.slice(0, 3).toLowerCase();
        setSelectedDay(shortDay);

        setEstablishmentFilter({
            ...establishmentFilter,
            operating_days: shortDay,
            open: 0 
        });
    }

    return (
        <Container >
            <Text size='small' type='bold' paddingBottom={'5px'}>
                Dias de funcionamento
            </Text>
            <Text size='small' color={colors.night} paddingBottom={'20px'}>
                Escolha a opção de dia que se encaixe na sua busca.
            </Text>

            <Column>
                <Select
                    placeholder='Selecione uma opção'
                    options={[
                        { label: 'Domingo', value: '1' },
                        { label: 'Segunda-feira', value: '2' },
                        { label: 'Terça-feira', value: '3' },
                        { label: 'Quarta-feira', value: '4' },
                        { label: 'Quinta-feira', value: '5' },
                        { label: 'Sexta-feira', value: '6' },
                        { label: 'Sábado', value: '7' },
                    ]}
                    onChange={handleDaySelect}
                    selected={selectedDay} 
                    search={false}
                    boxStyles={{ width: '100%', paddingBottom: 8, paddingTop: 12, borderRadius: 6 }}
                />

                <Radio
                    label='Aberto agora'
                    selected={radioSelected === 1}
                    onSelect={handleRadioChange}
                />
            </Column>
        </Container>
    );
}

const Container = styled(FullyCentered)`
    flex-direction: column;
    margin-top: 25px;

    @media screen and (min-width: ${breakpoints.lg}) {
        width: 80%;
    }
`;