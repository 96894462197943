const dayTranslations = {
    sunday: 'Domingo',
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sábado'
};


function translateDay(day) {
    return dayTranslations[day.toLowerCase()] || day;
}

export { translateDay, dayTranslations };