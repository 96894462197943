import React, { useEffect, useState } from 'react';
import { Column } from 'styled/alignment/Column';
import CardProfile from './parts/CardProfile';
import ProfileHours from './content/ProfileHours';
import styled from 'styled-components';
import breakpoints from 'theme/breakpoints';
import ProfileAddress from './content/ProfileAddress';
import ProfileEvents from './content/ProfileEvents';
import ProfileSchedule from './content/ProfileSchedule';
import establishmentsApi from 'api/requests/establishments';
import Loader from 'components/_UI/Loader';
import ClicksStatistics from './content/ClicksStatistics';
import NotResultDashBoard from 'components/commons/NotResultDashBoard';
import DashCollapse from 'components/_UI/DashCollapse';
import ProfileForm from './create/ProfileForm';
import { useMyEstablishment } from 'context/establishments/MyEstablishment';
import eventsApi from 'api/requests/events';
import paths from 'routes/paths';
import routeParams from 'theme/routeParams';
import { useSearchParams } from 'react-router-dom';
import useToast from 'hooks/useToast';
import ToastContainer from 'components/_UI/ToastsContainer';
import Text from 'components/_UI/Text';

export default function EstablishmentProfile({ editProfileClick }) {
  const { myEstablishment, setMyEstablishment } = useMyEstablishment();
  const [loader, setLoader] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [nextEvents, setNextEvents] = useState(null);
  const [nowEvents, setNowEvents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const showCreate = searchParams.get(routeParams.profile.create);
  const [slug, setSlug] = useState(null);
  const [clicksData, setClicksData] = useState(null);
  const [establishmentData, setEstablishmentData] = useState(false)
  const { toast, toasts } = useToast()

  useEffect(() => {
    if (showCreate) {
      setIsCreate(true);
    }

    if (!editProfileClick) {
      getCompanyData();
    }

    if (editProfileClick) {
      setIsEdit(myEstablishment[0]);
    }

  }, [editProfileClick, showCreate]);

  useEffect(() => {
    if (slug) {
      getClicksStatistics(slug);
    }
  }, [slug]);

  const onEventClick = (slug) => {
    window.location.href = `${paths.dashboard.events}?detail=${slug}`;
  };

  async function getCompanyData() {
    setLoader(true);
    try {
      const { data } = await establishmentsApi.my();
      setMyEstablishment(data?.data);
      setSlug(data?.data[0]?.slug);
      setEstablishmentData(data?.data)
      setNowEvents(data?.data[0]?.events?.now);
      setNextEvents(data?.data[0]?.events?.next);
    } catch (error) {
      // console.log(error);
      setMyEstablishment(null);
    } finally {
      setLoader(false);
    }
  }

  async function getClicksStatistics(slug) {
    try {
      const { data } = await establishmentsApi.getClicksStatistics(slug);
      setClicksData(data?.establishmentView);
    } catch (error) {
      // console.log(error);
    }
  }

  function onCloseCollapse() {
    setIsCreate(false);
    setIsEdit(false);
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      Object.values(routeParams.profile).forEach(el => {
        newSearchParams.delete(el);
      });
      return newSearchParams;
    });
  }

  function onSuccess(data, text) {
    toast.success(`Estabelecimento ${text} com sucesso!`)
    onCloseCollapse();
    getCompanyData();
    getClicksStatistics();
  }

  return (
    <>
      <ToastContainer toasts={toasts} />
      {loader || !myEstablishment ? (
        <Loader />
      ) : (
        <>
          {establishmentData && establishmentData.length ? (
            <ProfileContainer>
              <CardProfile
                data={establishmentData[0]}
                nextEvents={nextEvents}
                nowEvents={nowEvents}
                onEventClick={onEventClick}
                isLoading={isLoading}
              />
              <ProfileCards>
                <ProfileEvents data={nextEvents} />
                <ProfileSchedule data={establishmentData[0].dates} events={establishmentData[0]?.events} />
                <ProfileAddress data={establishmentData[0]?.address} />
                <ProfileHours data={establishmentData[0]} />
              </ProfileCards>
              <ClicksStatistics clicks={clicksData} />
            </ProfileContainer>
          ) : (
            <NotResultDashBoard
              ilustration={'adding-pin-in-map'}
              text={
                <>
                  <Text name='highlight'>Que tal ficar visível no mapa?</Text>
                  <Text>Saiba que estamos aqui para ajudar em todo o processo. Para começar, que tal cadastrar seu estabelecimento no mapa e ficar visível para todos os usuários?</Text>
                </>
              }
              textButton={'Cadastrar estabelecimento'}
              onClick={() => setIsCreate(true)}
            />
          )}
        </>
      )}
      <DashCollapse visible={isCreate || isEdit} onClose={onCloseCollapse}>
        <ProfileForm
          onClose={onCloseCollapse}
          isEdit={isEdit}
          // onSuccess={() => window.location.reload()}
          onSuccess={onSuccess}
        />
      </DashCollapse>
    </>
  );
}

const ProfileContainer = styled(Column)`
  width: 100%;
  flex-wrap: wrap;
  @media screen and (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;

const ProfileCards = styled(Column)`
  width: 100%;
  @media screen and (min-width: ${breakpoints.lg}) {
    width: 66.5%;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
  }
`;
