import api from "api";

const login = {
    signIn: (payload) => {
        return api(false, true).post('/users/login', payload);
    },
    
    forgotPassword: (paylaod) => {
        return api(false, true).post(`/users/forgotten-password`, paylaod);
    },

    verifyCode: (paylaod) => {
        return api(false, true).post(`/users/verified-code`, paylaod);
    },

    resendConfirmCode: (paylaod) => {
        return api(false, true).post(`/users/resend-confirm-code`, paylaod);
    },

    changePassword: (paylaod) => {
        return api(false, true).post('/users/change-password', paylaod);
    },

    withGoogle: (payload) => {
        return api(false, true).post('/users/google/login', payload);
    },

    confirmEmail: (email, code) => {
        return api(false, true).get(`/users/confirm/${email}/${code}`);
    }
};
 
export default login;