import Fieldset from 'components/_UI/Fieldset'
import Switch from 'components/_UI/Switch'
import Text from 'components/_UI/Text'
import { useEvent } from 'context/events/Event'
import fieldValidations from 'helpers/fieldValidations'
import React, { useEffect, useState } from 'react'
import { HorizontalCenter } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import eventTypes from 'theme/eventTypes'

export default function ExtraInfo({ register, errors, edit = false }) {
    const { event, setEvent } = useEvent();

    const [isPaid, setIsPaid] = useState(false)
    const [isAdulthood, setIsAdulthood] = useState(false)
    const [withTickets, setWithTickets] = useState(false)

    useEffect(() => {
        if (edit) {
            setEvent({
                ...event,
                has_tickets: edit?.has_tickets,
                adulthood: edit?.adulthood,
                classification_id: edit?.classification?.id
            })
            setIsPaid(edit?.classification?.id == eventTypes.paid.id)
            setIsAdulthood(edit?.adulthood)
            setWithTickets(edit?.has_tickets)
            return
        }

        setEvent({
            ...event,
            // link_ticket: event?.link_ticket,
            adulthood: false,
            classification_id: eventTypes.free.id
        })

        setIsPaid(false)
        setIsAdulthood(false)
    }, [])

    return (
        <Column marginTop='18px'>
            <Text size='small' type='bold' color={colors.nightDark}> Informações extras </Text>
            {/* <Fieldset
                placeholder='Link do ingresso'
                layout='rounded'
                name='link_ticket'
                register={register}
                validations={fieldValidations.link('link do ingresso')}
            /> */}

            {errors && (
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors.link?.message} </Text>
                </HorizontalCenter>
            )}

            <Row marginLeft='4px'>
                <Column width='auto'>
                    <Switch
                        checked={isPaid}
                        onChange={(e) => {
                            setEvent({
                                ...event,
                                classification_id: !e ? eventTypes.free.id : eventTypes.paid.id
                            })
                            setIsPaid(!e)
                        }}

                    />
                </Column>
                <Text
                    size='small'
                    color={colors.night}
                    paddingLeft={'8px'}
                >
                    O evento é pago
                </Text>
            </Row>

            <Row marginLeft='4px' marginTop='6px'>
                <Column width='auto'>
                    <Switch
                        checked={isAdulthood}
                        onChange={(e) => {
                            setEvent({
                                ...event,
                                adulthood: !e ? 0 : 1
                            })
                            setIsAdulthood(e)
                        }}
                    />
                </Column>
                <Text
                    size='small'
                    color={colors.night}
                    paddingLeft={'8px'}
                >
                    O evento é para maiores de 18 anos
                </Text>
            </Row>

            {/* {
                !edit && ( */}
                    <Row marginLeft='4px' marginTop='6px'>
                        <Column width='auto'>
                            <Switch
                                checked={withTickets}
                                onChange={(e) => {
                                    setWithTickets(e)
                                    setEvent({ ...event, has_tickets: e })
                                }}

                            />
                        </Column>
                        <Text
                            size='small'
                            color={colors.night}
                            paddingLeft={'8px'}
                        >
                            Quero criar ingressos para o evento
                        </Text>
                    </Row>

                {/* )
            } */}
        </Column>
    )
}