import Text from 'components/_UI/Text';
import React, { useEffect, useState } from 'react'
import { Clock } from 'react-feather';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';

export default function Countdown({
    time,
    color = colors.night,
    size = 'subtitle',
    iconSize = 24,
    onFinishTime
}) {

    const [timeLeft, setTimeLeft] = useState((time * 60));

    useEffect(() => {
        const endTime = Date.now() + timeLeft * 1000;

        const interval = setInterval(() => {
            const remaining = Math.floor((endTime - Date.now()) / 1000);
            if (remaining > 0) {
                setTimeLeft(remaining);
            } else {
                clearInterval(interval);
                setTimeLeft(0);
                onFinishTime()
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <Row width="auto">
            <Clock size={iconSize} color={color} />

            <Text
                name={size}
                marginLeft={'10px'}
                marginRight={'10px'}
                color={color}
                paddingBottom={0}
                type='bold'
            >
                {formatTime(timeLeft)}
            </Text>
        </Row>
    )
}
