import React, { useState } from 'react'
import Modal from "components/_UI/Modal";
import Fieldset from "components/_UI/Fieldset";
import styled from "styled-components";
import colors from "theme/colors";
import { FullyCentered } from "styled/alignment/Center";
import Button from "components/_UI/Button";
import { Column } from "styled/alignment/Column";
import Icon from "components/_UI/Icon";
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';

export default function EnviromentForm({ onAuthorize }) {
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    function onConfirm() {
        setError(false)
        setLoading(true)

        if (username === import.meta.env.VITE_DEV_USER && password === import.meta.env.VITE_DEV_PASS) {
            onAuthorize()
            return
        }

        setError(true)
        setLoading(false)

    }

    return (
        <Container>
            <Modal isOpen={true} closeButton={false}>

                <Column fullyCentralized marginBottom='20px'>
                    <Column width='100px' height='100px'>
                        <Icon name="whizr-logo" color={colors.primaryDark} />
                    </Column>
                </Column>

                <Fieldset
                    placeholder='Usuário'
                    layout='rounded'
                    onChangeValue={e => setUsername(e)}
                />
                <Fieldset
                    placeholder='Senha'
                    layout='rounded'
                    inputType='password'
                    onChangeValue={e => setPassword(e)}
                />

                {
                    loading ? <Loader /> : <Button onClick={() => onConfirm()}>Confirmar</Button>
                }

                {
                    error && <Text color={colors.danger}> Ta errado meu chanceler. </Text>
                }
            </Modal>
        </Container>
    )
}

const Container = styled(FullyCentered)`
  width: 100%;
  height: 100vh;
  background: ${colors.neutralDark};
`
