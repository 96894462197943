import RadioSelectButton from 'components/_UI/RadioSelectButton'
import { SelectContainer } from 'components/_UI/SelectsContent'
import Text from 'components/_UI/Text'
import { usePreferences } from 'context/user/Preferences'
import React from 'react'
import styled from 'styled-components'
import { HorizontalCenter } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'
import eventTypes from 'theme/eventTypesPreferences'
import sizes from 'theme/sizes'
const EventTypes = React.forwardRef(({error}, ref) => {
    const {preferences, setPreferences} = usePreferences()

       
  return (
    <Container ref={ref}>
        <SelectContainer>
            <Text type='bold' marginBottom={sizes.normal}>Tipo de evento preferido:</Text>
            <RadioSelectButton 
                data={eventTypes}
                onChangeActive={(item) => setPreferences({...preferences, eventTypes: item.value})}
            />
        </SelectContainer>
        
        {error && (
            <HorizontalCenter>
                {error === 'min' && (
                    <Text color={colors.danger}> Você deve selecionar um tipo de evento preferido</Text>
                )}
            </HorizontalCenter>
        )}
    </Container>
  )
})

export default EventTypes;

const Container = styled(Column)`
    margin-top: ${sizes.normal}
`;
