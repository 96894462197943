import React, { createContext, useState, useContext } from 'react';

const StepRecoverAccountContext = createContext();

export default function StepRecoverAccountProvider({ children }) {
    const [stepRecoverAccount, setStepRecoverAccount] = useState(1);

    return (
        <StepRecoverAccountContext.Provider value={{stepRecoverAccount, setStepRecoverAccount}}>
            {children}
        </StepRecoverAccountContext.Provider>
    );
}

export function useStepRecoverAccount() {
    const context = useContext(StepRecoverAccountContext);
    
    if (!context) throw new Error('useStepRecoverAccount must be used within a StepRecoverAccount');
    
    const { stepRecoverAccount, setStepRecoverAccount } = context;
    return { stepRecoverAccount, setStepRecoverAccount };
}