import ButtonSelect from 'components/_UI/ButtonSelect'
import { SelectContainer } from 'components/_UI/SelectsContent'
import Text from 'components/_UI/Text'
import { usePreferences } from 'context/user/Preferences'
import React from 'react'
import styled from 'styled-components'
import { HorizontalCenter } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'
import sizes from 'theme/sizes'
import time from 'theme/times'

let times = []

const TimePreferences = React.forwardRef(({error}, ref) => {
    const {preferences, setPreferences} = usePreferences()
   
    function removeObjectWithId(arr, id) {
        const objWithIndex = arr.findIndex(obj => obj.id === id)

        if (objWithIndex > -1) {
            arr.splice(objWithIndex, 1)
        }

        return arr
    }

    function timeFilter(active, item) {
        const hoursObject = {
            active: active === 'ACTIVE' || false,
            name: item.name,
            id: item.id
        }

        if (active === 'INATIVE') {
            const newArray = removeObjectWithId(times, hoursObject.id)
            setPreferences({ ...preferences, times: newArray })
            return;
        }

        times.push(hoursObject)
        setPreferences({ ...preferences, times: times })
    }

  return (
    <Container ref={ref}>
        <Text type='bold' marginBottom={sizes.normal}>Horário que você prefere sair:</Text>
        <SelectContainer>
                {time?.periodsDay.map((el) => (
                    <ContentTimes>
                        <ButtonSelect
                            marginRight={'0px'}
                            onToggle={(state) => timeFilter(state, el)}
                        >
                            {el.label}
                        </ButtonSelect>
                    </ContentTimes>
                ))}
                {error && (
                    <HorizontalCenter>
                        {error === 'min' && (
                            <Text color={colors.danger}> Você deve selecionar pelo menos um horário</Text>
                        )}
                    </HorizontalCenter>
                )}
        </SelectContainer>
    </Container>
  )
})

export default TimePreferences;

const Container = styled(Column)`
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
`;

const ContentTimes = styled.div`
    display: flex;
    height: auto;
    flex-wrap: wrap;
    & > button {
        margin-right: 0px
    }
`;
