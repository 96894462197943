const support = {
    contact: {
        phone: '(53) 999999-999',
        email: 'contato@whizr.app',
        instagram: 'https://instagram.com/whizr',
        facebook: 'https://www.facebook.com/profile.php?id=100068044070988',
        linkedin: 'https://br.linkedin.com/company/whizr'
    },

    faq: [
        {
            question: 'Quanto tempo eu preciso esperar para receber o dinheiro das vendas?',
            response: 'O repasse do dinheiro das vendas é feito 5 dias após o término do evento. No entanto, você só poderá sacar o valor que já estiver disponível para saque. Compras feitas via Pix ficam disponíveis em 1 dia útil, enquanto compras com cartão de crédito demoram 31 dias para serem liberadas. Por exemplo, se o evento ocorrer no dia 01/02 e o ingresso ou produto for comprado com cartão de crédito um dia antes, o valor só estará disponível para saque 30 dias após o evento. No caso de compras via Pix, o valor estará disponível para saque 5 dias após o evento.'
        },
        {
            question: 'Quais métodos de pagamento são aceitos?',
            response: 'Aceitamos cartões de crédito e PIX'
        },
        {
            question: 'Posso cancelar ou editar meus ingressos?',
            response: 'Sim, você pode editar ou cancelar seus ingressos. No entanto, qualquer ingresso que já tenha sido vendido não será alterado com as novas informações ou modificações realizadas.'
        },
        {
            question: 'Como saber quem comprou meus ingressos e/ou produtos?',
            response: 'Você pode ter acesso a essas informações acessando a página de eventos, clicando no evento desejado e selecionando a opção "Participantes".'
        },
        {
            question: 'É possivel vincular um produto ao meu evento posterior a criação do evento?',
            response: 'Sim! Pra que isso seja feito, você precisa teu seu modelo de produto criado na página de Produtos, após ter o produto criado, basta você clicar em editar o evento e avançar até o passo de vinculação de produtos, depois disso, é só selecionar o produto que quer vincular ao evento e clicar em "Avançar".'
        },
        {
            question: 'Qual a diferença entre "Total transacionado", "Total a receber" e "Disponivel para saque"?',
            response: 'Total transacionado equivale a todo valor que já foi transacionado por você, levando em consideração todos seus eventos. Total a receber equivale ao valor total que você ainda tem para receber da plataforma, e disponivel para saque é o valor atual que já está disponivel para você sacar.'
        },
    ]

}

export default support;