import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import indexPriority from 'theme/z-index';
import Fieldset from './Fieldset';

export default function InputWithAutocomplete({
    data,
    id,
    inputValue,
    layout,
    placeholder,
    badgeIcon,
    badgeIconColor,
    badgeIconBackground,
    syncProp,
    debounce,
    onChange = () => false,
    onFocus = () => false,
    onSelectItem,
    trigger,
    onBadgeClick,
    isBadgeClickable
}) {

    useEffect(() => { }, [trigger])

    const renderItem = () => {
        return data.map((item, index) => {
            const firstValue = Object.values(item)[1][0];
            const secondValue = Object.values(item)[1][1];
            const lastValue = Object.values(item)[1][2];

            if (lastValue) {
                return (
                    <AutocompleteItem key={index} onClick={() => onSelectItem(item.value)}>
                        {firstValue.normal}
                        <span>{secondValue.highlight}</span>
                        {lastValue.normal}
                    </AutocompleteItem>
                )
            }

            if (firstValue.highlight) {
                if (!secondValue) {
                    return (
                        <AutocompleteItem key={index} onClick={() => onSelectItem(item.value)}>
                            <span>{firstValue.highlight}</span>
                        </AutocompleteItem>
                    )
                }
                return (
                    <AutocompleteItem key={index} onClick={() => onSelectItem(item.value)}>
                        <span>{firstValue.highlight}</span>
                        {secondValue.normal}
                    </AutocompleteItem>
                )
            }

            if (secondValue.highlight) {
                if (lastValue) {
                    return (
                        <AutocompleteItem key={index} onClick={() => onSelectItem(item.value)}>
                            {firstValue.normal}
                            <span>{secondValue.highlight}</span>
                            {lastValue.normal}
                        </AutocompleteItem>
                    )
                }
                return (
                    <AutocompleteItem key={index} onClick={() => onSelectItem(item.value)}>
                        {firstValue.normal}
                        <span>{secondValue.highlight}</span>
                    </AutocompleteItem>
                )
            }
        });
    };

    return (
        <FieldContainer>
            <Fieldset
                id={id}
                placeholder={placeholder}
                layout={layout}
                badgeIcon={badgeIcon}
                badgeIconColor={badgeIconColor}
                badgeIconBackground={badgeIconBackground}
                value={inputValue}
                // syncProp={true}
                withDebounce={debounce}
                isBadgeClickable={isBadgeClickable}
                refresh={trigger}
                onBadgeClick={() => onBadgeClick()}
                onChangeValue={(e) => onChange(e)}
                onChangeFocus={(e) => onFocus(e)}
            />
            {/* 
            {data?.length > 0 && (
                <AutocompleteContainer>
                    {renderItem()}
                </AutocompleteContainer>
            )} */}

        </FieldContainer>
    )
};

const FieldContainer = styled(Column)`
    position: relative;
`;

const AutocompleteContainer = styled(Column)`
    border: 1px solid ${colors.nightLight};
    padding: 14px 22px;
    border-radius: ${sizes.micro};
    box-shadow: 1px 2px 3px ${colors.neutralDark};
    min-height: 150px;
    max-height: 170px;
    overflow-y: auto;
    width: 100%;
    background: white;
    position: absolute;
    top: ${sizes.gigantic};
`;

const AutocompleteItem = styled.div`
    padding: 6px;
    color: ${colors.night};
    cursor: pointer;

    &:hover {
        background: ${colors.occasionalGrey};
    }

    & > span {
        background: #ccc;
    }
`; 