import Text from 'components/_UI/Text';
import DynamicProfileBackground from 'components/commons/DynamicProfileBackground';
import DynamicProfileImage from 'components/commons/DynamicProfileImage';
import { useEstablishment } from 'context/establishments/Establishment';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';
import React, { useEffect } from 'react'
import { MapPin } from 'react-feather';
import styled, { css } from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import establishmentTypes from 'theme/establishmentTypes';
import fonts from 'theme/fonts';


export default function ResumeCard({
    data,
    fullWidth,
    withBackButton,
    onCardClick = () => false
}) {
    const { setEstablishment } = useEstablishment()
    const { backButtonCollapse, setBackButtonCollapse } = useBackButtonCollapse();

    useEffect(() => {
        setEstablishment(data)

        if (withBackButton) {
            setBackButtonCollapse({
                ...backButtonCollapse,
                visible: true
            })
        }
    }, [])

    return (
        <Column>
            <CardContainer
                onClick={() => onCardClick(data)}
                fullWidth={fullWidth}
            >
                <ImageContainer fullWidth={fullWidth}>
                    {data?.backgroundImage?.path ? (
                        <CardImage
                            fullWidth={fullWidth}
                            src={data?.backgroundImage?.path}
                        />
                    ) : (
                        <DynamicProfileBackground
                            img={data?.backgroundImage?.path}
                            bgColor={colors.nightLight}
                            size={fullWidth ? '80px' : '60px'}
                        />
                    )}
                </ImageContainer>

                <ProfileImage fullWidth={fullWidth}>
                    <ImageBorder fullWidth={fullWidth}>
                        <DynamicProfileImage
                            img={data?.profileImage?.path}
                            word={data?.name}
                            size={fullWidth ? '60px' : '50px'}
                            textSize='highlight'
                        />
                    </ImageBorder>
                </ProfileImage>

                <Status opened={data?.dates?.open || data?.open} fullWidth={fullWidth}>
                    <Text name={fullWidth ? 'medium' : 'mini'} color={colors.neutralLight} type='bold' paddingBottom={0}>
                        {(data?.dates?.open || data?.open) == 1 ? 'Aberto' : 'Fechado'}
                    </Text>
                </Status>

                <ContentContainer>
                    <Text
                        name={fullWidth ? 'default' : 'small'}
                        type='bold'
                        textAlign={fullWidth ? 'center' : 'left'}
                        marginTop={fullWidth ? '15px' : '6px'}
                        ellipsisAt='95%'
                        paddingBottom={'2px'}
                    >
                        {data?.name}
                    </Text>

                    <Row marginTop={fullWidth ? '10px' : '0px'}>
                        {fullWidth && <MapPin color={colors.night} size={15} />}
                        <Text
                            name='small'
                            color={colors.nightLight}
                            ellipsisAt='95%'
                            paddingBottom={'4px'}
                            paddingLeft={fullWidth ? '5px' : 0}
                        >
                            {data?.address?.name 
                                ? `${data?.address?.name}, ` 
                                : data?.address?.street 
                                ? `${data?.address?.street}, ` 
                                : ''
                            }
                            {data?.address?.district && `Bairro ${data?.address.district}`}
                            {data?.address?.district && data?.address?.number && ','}
                            {data?.address?.number && ` nº ${data?.address?.number}`}
                            {data?.address?.complement && ` - ${data?.address?.complement}`}
                        </Text>
                    </Row>

                    <TagsContainer>
                        <CategoryCircle
                            category={data?.classification?.name}
                        />
                        <Text name='mini' color={colors.nightLight} ellipsisAt='95%' paddingBottom={0}>
                            {data?.tagsEstablishments?.map((item, index) => (
                                <React.Fragment key={item.id}>
                                    {index === 0 ? item.name : ` - ${item.name}`}
                                </React.Fragment>
                            ))}
                        </Text>
                    </TagsContainer>
                </ContentContainer>
            </CardContainer>
        </Column>
    )
};

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 155px;
  margin: 12px 10px 0 2px;
  background-color: ${colors.neutralLight};
  position: relative;
  cursor: pointer;
  transition: ease-in-out .25s;

    ${({ fullWidth }) => fullWidth && `
        width: 100% !important;
        margin: 8px 0;
        border-radius: 10px;
        box-shadow: 2px 8px 10px #ccc;        
        padding: 6px;
    `}    
    
    @media screen and (min-width: ${breakpoints.lg}) {
        ${({ smallOnDesktop }) => smallOnDesktop && `
            width: 30% !important; 
        `}    
    }

    ${({ whiteBackground }) => whiteBackground && `
        background: ${colors.neutralLight}; 
    `}    
    
    
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 99%;
        margin: 8px 0;
        border-radius: 10px;
        box-shadow: 2px 2px 4px #ccc;        
        padding: 6px;
        height: auto;

    }

    &:hover {
        transform: scale(1.02);
    }
`;

const CardTextWrapper = styled(FullyCentered)`
  width: 100%;
  text-align: center;
  padding: 20px;
  border-radius: 4px;

  ${({ category }) => category === 'free' && css`
    background: ${establishmentTypes.free.color};
  `};

  ${({ category }) => category === 'private' && css`
    background: ${establishmentTypes.paid.color};
  `};
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;

  ${({ fullWidth }) => fullWidth && `
    height: 80px;
  `}  

    @media screen and (min-width: ${breakpoints.lg}) {
        height: 90px;
        & > div > img {
            height: 90px;
        }
        
        & > img {
            height: 90px;
        }
    }
`;

const CardImage = styled.img`
  width: 100%;
  height: 60px; 
  object-fit: cover;
  border-radius: 4px;
  background: ${colors.neutralDark};

  ${({ fullWidth }) => fullWidth && `
    height: 80px;
  `}
`;

const TagsContainer = styled(Row)`
  align-items: center;
`;

const CategoryCircle = styled.div`
  min-width: 10px;
  max-width: 10px;
  height: 10px;
  border-radius: 30px;
  margin: 6px 8px 6px 2.5px;

  ${({ category }) => category === 'free' && css`
    background: ${establishmentTypes.free.color};
  `};

  ${({ category }) => category === 'private' && css`
    background: ${establishmentTypes.paid.color};
  `};
`;

const ProfileImage = styled(FullyCentered)`
  position: absolute;
  bottom: 60px;
  width: 100%;
  
  ${({ fullWidth }) => fullWidth && `
    bottom: 90px;
  `}
  
  @media screen and (min-width: ${breakpoints.lg}) {
    bottom: 82px;
    left: 0;

    & > div {
        width: 70px;
        height: 70px;

        & > div {
            width: 64px;
            height: 64px;
        }
    }

  }
`;

const ContentContainer = styled.div`
    width: 100%;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        padding-top: 20px;

        & > p {
            margin-bottom: 4px;
        }
    }
`;


const ImageBorder = styled(FullyCentered)`
  width: 54px;
  height: 54px;
  border: 4px solid ${colors.neutral};
  border-radius: 60px;
  /* position: absolute; */

  ${({ fullWidth }) => fullWidth && `
    width: 65px;
    height: 65px;
  `}  

    @media screen and (min-width: ${breakpoints.lg}) {
        & > picture {
            width: 62px;
            height: 62px;

            & > img {
                width: 62px;
                height: 62px;
            }
        }
    }
`;

const Status = styled(FullyCentered)`
  position: absolute;
  top: -8px;
  right: 8px;
  border-radius: 30px;
  width: auto;
  padding: 4px 10px;
  background: ${colors.night};
  
  ${({ fullWidth }) => fullWidth && `
    top: -4px;
    right: 4px;
    padding: 4px 10px; 

    & > p {
        font-size: ${fonts.sizes.small};
    }
  `}  

  ${({ opened }) => opened && `
    background: ${colors.successDark}
  `};
`;
