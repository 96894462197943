import React, { useEffect, useState } from 'react';
import EventProvider from 'context/events/Event';
import TagsProvider from 'context/events/Tags';
import EventFilters from 'modules/dashboard/events/EventFilters';
import MainContent from 'modules/dashboard/events/MainContent';
import RightSlotEvent from 'modules/dashboard/events/RightSlotEvent';
import DetailVisibleProvider from 'context/dash/DetailVisible';
import DashContainer from 'modules/dashboard/_common/DashContainer';
import ShowAllEventsProvider from 'context/dash/ShowAllEvents';
import EventDetailTabProvider from 'context/dash/EventDetailTab';
import BackButtonCollapseDashProvider from 'context/dash/BackButtonCollapseDash';
import  { useUser } from 'context/user/User';
import BatchesProvider from 'context/checkout/Batch';

export default function DashEvents() {
    const [createEventClick, setCreateEventClick] = useState(0);
    const [hasEvents, setHasEvents] = useState(false)

    return (
        // <UserProvider>
            <BatchesProvider>
                <DetailVisibleProvider>
                    <EventProvider>
                        <BackButtonCollapseDashProvider>
                            <EventDetailTabProvider>
                                <TagsProvider>
                                    <ShowAllEventsProvider>

                                        <DashContainer title={'Eventos'} rightSlot={RightSlotContent}>
                                            <MainContent createEventClick={createEventClick} isEvents={() => setHasEvents(true)}/>
                                        </DashContainer>

                                    </ShowAllEventsProvider>
                                </TagsProvider>
                            </EventDetailTabProvider>
                        </BackButtonCollapseDashProvider>
                    </EventProvider>
                </DetailVisibleProvider>
            </BatchesProvider>
        // </UserProvider>
    );

    function RightSlotContent() {
        let createEventVisible = false;
        const { user } = useUser();

        const isProducer = user?.role?.id == import.meta.env.VITE_PRODUCER_ROLE_ID;
        const isEstablishment = user?.role?.id == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID;
        const isAdmin = user?.role?.id == import.meta.env.VITE_ADMIN_ROLE_ID;

        if (isEstablishment && user?.establishments?.length) {
            createEventVisible = true;
        }
        
        if (isProducer || isAdmin) {
            createEventVisible = true;
        }
        

        return (
            createEventVisible && (
                <>
                    <EventFilters hasEvents={createEventVisible} />
                    <RightSlotEvent onCreateEventClick={() => setCreateEventClick(createEventClick + 1)} hasEvents={createEventVisible} />
                </>
            )
        );
    }
}
