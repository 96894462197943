import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Row } from 'styled/alignment/Row';
import { Column } from 'styled/alignment/Column';
import Button from 'components/_UI/Button';
import Text from 'components/_UI/Text';
import colors from 'theme/colors';
import { useEvent } from 'context/events/Event';
import ticketsApi from 'api/requests/tickets';
import Loader from 'components/_UI/Loader';
import eventTypes from 'theme/eventTypes';
import DefaultForm from './DefaultForm';
import breakpoints from 'theme/breakpoints';
import { v4 as uuidv4 } from 'uuid';

export default function TicketForm({
    edit,
    availableProducts,
    onSuccess = () => false
}) {
    const { event, setEvent } = useEvent();

    const [loading, setLoading] = useState(false);

    const [isFree, setIsFree] = useState(event?.classification.id === Number(eventTypes.free.id));
    const [ticketActive, setTicketActive] = useState(0);
    const [createdTickets, setCreatedTickets] = useState([]);
    const [deletedTickets, setDeletedTickets] = useState([]);
    const [deletedBatches, setDeletedBatches] = useState([]);
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        setHasErrors(!createdTickets.every(ticket => {
            const isTicketValid = validateTicket(ticket)

            if (!isTicketValid) {
                return false
            }

            // validate batches
            return ticket.batches.every(batch => {
                return validateBatch(batch)
            }) 
        }))
    }, [createdTickets])

    useEffect(() => {
        if (edit) {
            if (!event.products || !event.products.length) {
                setCreatedTickets([
                    {
                        internal_id: uuidv4(),
                        name: '',
                        quantity: '',
                        description: '',
                        price: '',
                        floatPrice: 0,
                        is_active: true,
                        batches: []
                    }
                ])

                return
            }

            const ticketsToEdit = event.products
                .filter(el => el?.type === 'ticket')
                .map(ticket => {
                    if (!ticket.batches || !ticket.batches.length) {
                        return ticket
                    }

                    return {
                        ...ticket,
                        floatPrice: Number.parseFloat(ticket.price),
                        is_active: Boolean(ticket.batches[0].is_active),
                        batch_id: ticket.batches[0].id,
                        batches: ticket.batches.slice(1).map(batch => {
                            return {
                                ...batch,
                                floatPrice: Number.parseFloat(batch.price),
                                internal_id: uuidv4()
                            }
                        })
                    }
                });

            setCreatedTickets(ticketsToEdit);
        } else {
            setCreatedTickets([
                {
                    internal_id: uuidv4(),
                    name: '',
                    quantity: '',
                    description: '',
                    price: '',
                    floatPrice: 0,
                    is_active: true,
                    batches: []
                }
            ])
        }
    }, [event]);

    function validateTicket(ticket) {
        const isNameValid = (ticket.name && ticket.name.length <= 30)
        const isDescriptionValid = (ticket.description ? ticket.description.length <= 80 : true);
        const isQuantityValid = (ticket.quantity && Number(ticket.quantity) >= 0);
        let isPriceValid = (ticket.floatPrice !== undefined && ticket.floatPrice !== null);

        if (!isFree) {
            isPriceValid = isPriceValid && ticket.floatPrice >= 5
        }

        return (
            isNameValid &&
            isDescriptionValid &&
            isPriceValid &&
            isQuantityValid
        )
    }

    function validateBatch(batch) {
        let isPriceValid = batch.floatPrice !== undefined && batch.floatPrice !== null
        const isQuantityValid = batch.quantity && Number(batch.quantity) >= 0

        if (!isFree) {
            isPriceValid = isPriceValid && batch.floatPrice >= 5
        }

        return (
            isPriceValid &&
            isQuantityValid
        )
    }

    function addNewForm() {
        setCreatedTickets([...createdTickets, {
            internal_id: uuidv4(),
            name: '',
            quantity: '',
            description: '',
            price: '',
            floatPrice: 0,
            is_active: true,
            batches: []
        }]);
        setTicketActive(createdTickets.length)
    };

    function onSubmitForm(from) {
        if (from === 'createNewForm') {
            addNewForm()
        }

        if (from === 'submitForm' && edit) {
            editTicket()
        }

        if (from === 'submitForm' && !edit) {
            createTicket()
        }
    }

    async function createTicket() {
        try {
            setLoading(true);

            const payload = {
                type: 'tickets',
                event_id: event?.id,
                products: createdTickets.map(ticket => {
                    const firstBatch = {
                        price: ticket.floatPrice,
                        name: ticket.name,
                        quantity: ticket.quantity,
                        is_active: ticket.is_active,
                    }
    
                    const ticketPayload = {
                        event_id: event?.id,
                        is_active: ticket.is_active,
                        type: 'ticket',
                        name: ticket.name,
                        price: ticket.floatPrice,
                        quantity: Number(ticket.quantity),
                        batches: [firstBatch, ...ticket.batches.map(batch => {
                            return {
                                price: batch.floatPrice,
                                name: batch.name,
                                quantity: batch.quantity,
                                is_active: batch.is_active,
                            }
                        })],
                    }

                    if (ticket.description) {
                        firstBatch.description = ticket.description
                        ticketPayload.description = ticket.description
                    }

                    // is event free?
                    if (event.classification.id === Number(eventTypes.free.id)) {
                        ticketPayload.is_free = true
                        ticketPayload.price = 0
                    }

                    return ticketPayload
                })
            }

            // validate payload

            const { data } = await ticketsApi.createBulk(payload);
            onSuccess({
                ...data,
                products: availableProducts
            })

            if (!availableProducts.length) {
                removeDraft();
            }
        } catch (error) {
            // console.log(error)
        } finally {
            setLoading(false);
        }
    }

    async function editTicket() {
        try {
            setLoading(true);
            const payload = {
                type: 'tickets',
                event_id: event?.id,
                products: createdTickets.map(ticket => {
                    const firstBatch = {
                        id: ticket.batch_id,
                        price: ticket.floatPrice,
                        name: ticket.name,
                        quantity: ticket.quantity,
                        is_active: ticket.is_active,
                    }
    
                    const ticketPayload = {
                        event_id: event?.id,
                        id: ticket.id,
                        is_active: ticket.is_active,
                        type: 'ticket',
                        name: ticket.name,
                        price: ticket.floatPrice,
                        quantity: Number(ticket.quantity),
                        batches: [firstBatch, ...ticket.batches.map(batch => {
                            return {
                                id: batch.id,
                                price: batch.floatPrice,
                                name: batch.name,
                                quantity: batch.quantity,
                                is_active: batch.is_active,
                            }
                        })],
                    }

                    if (ticket.description) {
                        firstBatch.description = ticket.description
                        ticketPayload.description = ticket.description
                    }
    
                    // is event free?
                    if (event.classification.id === Number(eventTypes.free.id)) {
                        ticketPayload.is_free = true
                        ticketPayload.price = 0
                    }
    
                    return ticketPayload
                })
            }

            if (deletedTickets.length || deletedBatches.length) {
                const deletePayload = {}

                if (deletedTickets.length) {
                    deletePayload.productsToDelete = deletedTickets
                }

                if (deletedBatches.length) {
                    deletePayload.batchesToDelete = deletedBatches
                }

                await ticketsApi.deleteBulk({
                    data: deletePayload
                })
            }

            await ticketsApi.updateBulk(payload)

            onSuccess()

            if (!availableProducts.length) {
                removeDraft();
            }
        } catch (error) {
            // log error
        } finally {
            setLoading(false)
        }
    }

    async function removeDraft() {
        try {
            await ticketsApi.removeDraft(event?.slug);
        } catch (error) {
            // console.log('error')
        } finally {
            setLoading(false);
        }
    }

    function deleteTicket(index) {
        const newTickets = [...createdTickets]
        const removed = newTickets.splice(index, 1)

        if (removed[0]?.id) {
            setDeletedTickets([
                ...deletedTickets,
                removed[0].id
            ])
        }

        setCreatedTickets(newTickets)

        if (ticketActive === index) {
            setTicketActive(false);
        }

        if (ticketActive > index) {
            setTicketActive(ticketActive - 1);
        }
    }


    return (
        <FormContainer>
            <FullyCentered>
                <Text name='title' color={colors.occasionalPurple}>
                    {edit ? 'Atualizar' : 'Criar'} ingresso
                </Text>
            </FullyCentered>

            {createdTickets?.map((ticket, index) => {
                return (
                    <DefaultForm
                        key={ticket.internal_id}
                        ticketIndex={index}
                        onDeleteTicket={() => deleteTicket(index)}
                        ticket={ticket}
                        setTicket={setCreatedTickets}
                        isUnique={createdTickets.length === 0}
                        selected={ticketActive == (index)}
                        onTicketClick={() => setTicketActive(index)}
                        setDeletedBatches={setDeletedBatches}
                    />
                )
            })}


            <Column fullyCentralized marginTop='30px' width='100%' paddingBottom='300px'>
                <Text marginBottom={'10px'} marginTop='10px' type='bold'>
                    Quer criar outro ingresso? Clique no botão abaixo.
                </Text>

                <Column width={'230px'}>
                    <Button
                        category='squiredBorded'
                        variant={hasErrors ? 'disabled' : 'featured'}
                        onClick={() => onSubmitForm('createNewForm')}
                    >
                        <ButtonContent buttonAdd>
                            <ButtonAdd />
                        </ButtonContent>
                        Novo Ingresso
                    </Button>
                </Column>
            </Column>

            <ButtonsContainer>

                {
                    loading ? (
                        <FullyCentered>
                            <Loader />
                        </FullyCentered>
                    ) : (
                        <FullyCentered marginTop='40px'>
                            <Column width='280px'>
                                <Button variant='secondary'>
                                    Voltar
                                </Button>
                            </Column>
                            <Column width='280px' marginLeft='20px'>
                                <Button
                                    variant={hasErrors ? 'disabled' : 'primary'}
                                    onClick={() => onSubmitForm('submitForm')}
                                >
                                    Avançar
                                </Button>
                            </Column>
                        </FullyCentered>
                    )
                }
            </ButtonsContainer>
        </FormContainer >
    );
}


const FormContainer = styled.div`
    overflow-x: auto;
`;

const ButtonsContainer = styled(FullyCentered)`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 14px;
    background: ${colors.neutralLight};
    border-top: 1px solid ${colors.neutralDark};
    border-radius: 12px;
    box-shadow: 0 -2px 8px #ddd;
    
    @media screen and (min-width: ${breakpoints.md}) {
        width: 75%;
    }
`;

const ButtonContent = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    border-radius: 4px;
    border: 2px solid ${colors.neutral};
    padding: 2px;
    height: 20px;  
    cursor: pointer;
    transition: all ease .3s;

    ${({ dark }) => dark && `
        border: 1px solid ${colors.night};
    `}
    
    &:hover {
        transform: scale(1.1);
    }

    ${({ buttonAdd }) => buttonAdd && `
        margin-right: 10px;
    `}
`;

const ButtonAdd = styled.button`
    position: relative;
    width: 20px;
    height: 20px;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 2px;
        border-radius: 4px;
        background-color: ${colors.neutral};
        transform: translate(-50%, -50%);

        ${({ dark }) => dark && `
            background-color: ${colors.night};
        `}
    }

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 10px;
        border-radius: 4px;
        background-color: ${colors.neutral};
        transform: translate(-50%, -50%);
        ${({ dark }) => dark && `
            background-color: ${colors.night};
        `}
    }
`;