import axios from "axios";
import auth from "middlewares/auth";

const headers = {
    Authorization: `Bearer ${auth.getToken()}`,
    'Content-Type': 'multipart/form-data'
}

const imageUpload = {
    uploadWithFolder: (payload) => {
        return axios.post(`${import.meta.env.VITE_DEFAULT_URL}images`, payload, {headers})
    },
    delete: (id) => {
        return axios.delete(`${import.meta.env.VITE_DEFAULT_URL}images/${id}`, {headers})
    },
    
};
 
export default imageUpload;  