import addressApi from 'api/requests/address'
import Fieldset from 'components/_UI/Fieldset'
import { StyledFieldset } from 'styled/UI/StyledForm'
import Loader from 'components/_UI/Loader'
import Text from 'components/_UI/Text'
import fieldValidations from 'helpers/fieldValidations'
import masks from 'helpers/masks'
import regex from 'helpers/regex'
import unformat from 'helpers/unformat';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import breakpoints from 'theme/breakpoints'
import colors from 'theme/colors'

export default function BankAddressInfo({
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    errors,
    isEditing,
    setIsEditing,
    watchCep,
}) {
    const [showFields, setShowFields] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (isEditing) {
            setIsEditing(false)
            setShowFields(true)
            return
        }

        const cep = getValues('cep')
        const unformatCep = unformat.getNumbers(cep)

        if (unformatCep?.length == 8) {
            showAddressFields(cep)
        }
    }, [watchCep])

    function showAddressFields(cep) {
        if (cep) {
            if (!validateCEP(cep)) {
                setShowFields(false)

                return
            }
        }
        getAddress(cep)
        setShowFields(true)
    }

    function validateCEP(cep) {
        return regex.cep.test(cep);
    }

    async function getAddress(cep) {
        setLoading(true)
        try {
            const { data } = await addressApi.getByPostalCode(cep)

            setValue('addressDistrict', data.street.district)
            setValue('addressStreet', data.street.name)
            setValue('addressNumber', '')
            clearErrors('addressDistrict')
            clearErrors('addressStreet')
            setValue('addressNumber')
            setShowFields(true)
        } catch (error) {
            if (error) {
                setShowFields(false)
                setError('cep', { type: 'custom', message: 'CEP inválido!' })
                return
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <ContainerAddress>
            <Text type='bold'>Endereço</Text>
            <Fieldset
                marginTop='6px'
                placeholder='CEP *'
                label='CEP'
                name="cep"
                register={register}
                mask={masks.cep}
                validations={fieldValidations.cepPatterns()}
                layout='squired'
            />


            {errors?.cep?.message && (
                <Column marginLeft='10px'>
                    <Text color={colors.danger} name='small'> {errors.cep?.message} </Text>
                </Column>
            )}

            {
                loading ? (
                    <Loader />
                ) : (
                    <AddressFields visible={showFields}>
                        <StyledRow>
                            <ContentFields>
                                <Fieldset
                                    name='addressDistrict'
                                    layout='squired'
                                    label='Bairro'
                                    marginTop='10px'
                                    register={register}
                                    // badgeText='Bairro'
                                    validations={fieldValidations.generic('Bairro')}
                                />
                                {errors?.addressDistrict && (
                                    <Text marginLeft='10px' name='small' color={colors.danger}>{errors.addressDistrict.message}</Text>
                                )}
                            </ContentFields>

                            <ContentFields>
                                <Fieldset
                                    name='addressStreet'
                                    layout='squired'
                                    label='Rua'
                                    marginTop='10px'
                                    register={register}
                                    // badgeText='Rua'
                                    validations={fieldValidations.generic('Rua')}
                                />
                                {errors?.addressStreet && (
                                    <Text marginLeft='10px' name='small' color={colors.danger}>{errors.addressStreet.message}</Text>
                                )}
                            </ContentFields>
                            <ContentFields>
                                <Fieldset
                                    name='addressNumber'
                                    placeholder='Número'
                                    label='Número'
                                    layout='squired'
                                    marginTop='10px'
                                    register={register}
                                    validations={fieldValidations.addressNumber('Número')}
                                />
                                {errors?.addressNumber && (
                                    <Text marginLeft='10px' name='small' color={colors.danger}>{errors.addressNumber.message}</Text>
                                )}
                            </ContentFields>
                        </StyledRow>
                    </AddressFields>
                )
            }
        </ContainerAddress>
    )
}

const ContainerAddress = styled(Column)`
    width: 100%;
    align-items: center;
    margin-top: 20px;
    position: relative;
`;

const AddressFields = styled.div`
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-in;

    ${({ visible }) => visible && `
        margin-bottom: 238px;
        visibility: visible;
        opacity: 1;
        @media screen and (min-width: ${breakpoints.sm}) {
             margin-bottom: 90px;
        }
    `};

    ${StyledFieldset} {
        height: fit-content;
    }
`;


const ContentFields = styled(Column)`
    width: 100%;

    @media screen and (min-width: ${breakpoints.sm}) {
        width: 48%;
    }
`;
const StyledRow = styled(Column)`
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    @media screen and (min-width: ${breakpoints.sm}) {
        flex-direction: row;
        width: 100%;
        margin-bottom: 30px;

        & > div{
            margin-left: 10px;
        }

        & > div:first-child {
            margin-left: 0;
        }
    }
`;