import Icon from 'components/_UI/Icon';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import fonts from 'theme/fonts';
// import servicesOptions from 'theme/servicesOptions';

export default function EstablishmentServicesOptions({ data, content, fullWidth, loading }) {
    const isServiceSelected = (service) => {
        return data.some(el => el.id === service.id);
    };

    return (
        <Column>
            <Text
                textAlign={'center'}
                type='bold'
                marginBottom={'5px'}
            >
                Opções de serviços
            </Text>

            {
                loading ?
                    <FullyCentered>
                        <Loader />
                    </FullyCentered>
                    : (
                        <ServicesContainer>
                            {
                                content.map((service) => (
                                    <ContainerCentralized fullWidth={fullWidth}>
                                        <ServiceItem key={service.id} fullWidth={fullWidth}>
                                            <StyledRow fullWidth={fullWidth}>
                                                <ContentIcon close={isServiceSelected(service) && 'close'}>
                                                    <Icon
                                                        name={isServiceSelected(service) ? 'check' : 'close'}
                                                        color={isServiceSelected(service) ? colors.successLight : colors.danger}
                                                    />
                                                </ContentIcon>

                                                <ServiceText
                                                    selected={isServiceSelected(service)}
                                                >
                                                    <Text paddingBottom={'0px'}>
                                                        {service.name}
                                                    </Text>
                                                </ServiceText>
                                            </StyledRow>
                                        </ServiceItem>
                                    </ContainerCentralized>
                                ))
                            }
                        </ServicesContainer>
                    )
            }

        </Column>
    )
}

const ServicesContainer = styled(Row)`
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 20px;

    @media screen and (min-width: ${breakpoints.sm}) {
        padding: 0 30px;
    }
    @media screen and (min-width: ${breakpoints.lg}) {
        padding: 0;
    }
`;

const ServiceItem = styled(Row)`
    width: 100%; 
    margin: 12px 2.5%; 
    align-items: center;
    justify-content: center; 

    ${({fullWidth}) => fullWidth && `
        @media screen and (min-width: ${breakpoints.md}) {
            margin: 12px 0 !important;

            & > div > div > p {
                width: 200px;
                fonts-size: ${fonts.sizes.small} !important;
                padding-bottom: 0;
                margin-left: 5px;
            }
        }
    `};
`;

const ContentIcon = styled.div`
    width: 22px;
    height: 22px;

    ${({ close }) => !close && `
        width: 16px;
        height: 16px;
        margin-left: 3px;
    `}
`;

const ServiceText = styled.div`
    ${({ selected }) => !selected && `
        & > p {
            text-decoration: line-through;
            color: ${colors.gray};
            margin-left: 3px;
        }
    `}
`;

const ContainerCentralized = styled.div`
    display: flex;
    flex-direction: row;
    width: 50%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    ${({fullWidth}) => fullWidth && `
        @media screen and (min-width: ${breakpoints.md}) {
            width: 33%;
        }
    `};
`;

const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 130px;
    
    ${({fullWidth}) => fullWidth && `
        @media screen and (min-width: ${breakpoints.md}) {
            width: 100%;
            justify-content: center;
        }
    `};
`