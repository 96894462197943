import React, { createContext, useState, useContext } from 'react';
import tabs from 'theme/tabs';

const CollapseContext = createContext();

export default function CollapseProvider({ children }) {
    const [collapse, setCollapse] = useState({
        height: 'min',
        withContent: false,
        componentToRender: false,
        arrowPosition: true,
        from: tabs.events.slug,
        withHideCity: false
    });

    return (
        <CollapseContext.Provider value={{ collapse, setCollapse }}>
            {children}
        </CollapseContext.Provider>
    );
}

export function useCollapse() {
    const context = useContext(CollapseContext);

    if (!context) throw new Error('useCollapse must be used within a CollapseProvider');

    const { collapse, setCollapse } = context;
    return { collapse, setCollapse };
}