import ButtonSelect from 'components/_UI/ButtonSelect'
import Loader from 'components/_UI/Loader'
import { ButtonSelectItem, SelectContainer, SelectWrapper } from 'components/_UI/SelectsContent'
import Text from 'components/_UI/Text'
import React, { useState, useEffect } from 'react'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'
import tagsApi from 'api/requests/tags'
import { HorizontalCenter } from 'styled/alignment/Center'
import { usePreferences } from 'context/user/Preferences'
import { useTags } from 'context/events/Tags'

const categories = [];

const CategoriesInfo = React.forwardRef(({ error }, ref) => {
    const { preferences, setPreferences} = usePreferences()
    const [loadCategories, setLoadCategories] = useState(false)
    const {tags, setTags} = useTags()
    
    useEffect(() => {
        getCategories()
    }, [])


    function removeObjectWithId(arr, id) {
        const objWithIndex = arr.findIndex(obj => obj.id === id)

        if (objWithIndex > -1) {
            arr.splice(objWithIndex, 1)
        }

        return arr
    }

    function categoriesFilter(active, item) {
        const categoriesObject = {
            active: active === 'ACTIVE' || false,
            name: item.name,
            id: item.id
        }

        if (active === 'INATIVE') {
            const newArray = removeObjectWithId(categories, categoriesObject.id)

            setPreferences({ ...preferences, tags: newArray })

            return;
        }

        categories.push(categoriesObject)
        setPreferences({ ...preferences, tags: categories })
    }

    async function getCategories() {
        if (tags) {
            return
        }

        setLoadCategories(true)
        try {
            const { data } = await tagsApi.get()
            setTags(data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoadCategories(false)
        }
    }


    return (
        <Column marginTop='22px'>
            <Text size='small' type='bold'> Categorias que você gosta </Text>
            <Text size='small' color={colors.night}>Selecione até 3 categorias que você goste</Text>

            {
                loadCategories ? <Loader /> : (
                    <SelectContainer>
                        {
                            tags?.filter(el => el.is_active === 1).map((el) => (
                                <SelectWrapper key={el.id}>
                                    <ButtonSelectItem>
                                        <ButtonSelect
                                            size={'small'}
                                            onToggle={(state) => categoriesFilter(state, el)}
                                        >
                                            {el.name}
                                        </ButtonSelect>
                                    </ButtonSelectItem>
                                </SelectWrapper>
                            ))
                        }
                    </SelectContainer>
                )
            }

            {error && (
                <HorizontalCenter ref={ref}>
                    {error === 'max' && (
                        <Text color={colors.danger}> Você deve selecionar no máximo 3 categorias </Text>
                    )}
                    
                    {error === 'min' && (
                        <Text color={colors.danger}> Você deve selecionar pelo menos uma categoria </Text>
                    )}
                </HorizontalCenter>
            )}
        </Column>
    )
})

export default CategoriesInfo;


