import Loader from 'components/_UI/Loader'
import Text from 'components/_UI/Text';
import { useCollapse } from 'context/general/Collapse';
import React, { useEffect } from 'react'
import { FullyCentered } from 'styled/alignment/Center'
import EventDetail from '../detail/EventDetail';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import eventsApi from 'api/requests/events';
import EventCard from '../card/EventCard';
import sizes from 'theme/sizes';
import { useSearchParams } from 'react-router-dom';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function MultipleEventsList({ data }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const { collapse, setCollapse } = useCollapse();
    const { backButtonCollapse, setBackButtonCollapse } = useBackButtonCollapse();

    useEffect(() => {
        setBackButtonCollapse({
            ...backButtonCollapse,
            data: data,
            from: 'multiple-list'
        })
    }, [])

    async function renderEventDetail(event) {
        setSearchParams({ evento: event.slug })
        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => (
                <FullyCentered height='100%'>
                    <Loader />
                </FullyCentered>
            )
        })

        try {
            const { data: res } = await eventsApi.getBySlug(event.slug);
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => event && <EventDetail withBackButton data={res.data} />
            })
        } catch (error) {
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => (
                    <FullyCentered height='100%'>
                        <Text name='highlight'> Ocorreu um erro inesperado. </Text>
                    </FullyCentered>
                )
            })
        }
    }

    return (
        <CardsContainer wrap={data.length > 4}>
            <Column
                fullyCentralized
                marginTop='20px'
                marginBottom='20px'
            >
                <Text type='bold'>
                    {data[0]?.address?.street?.name && `Rua ${data[0]?.address?.street?.name},`} {data[0]?.address?.street?.district && `bairro ${data[0]?.address?.street?.district}`}
                </Text>
            </Column>

            <Content>
                {
                    data.map(el => (
                        <EventCard
                            fullWidth={true}
                            key={el.id}
                            data={el}
                            onEventClick={(e) => renderEventDetail(e)}
                        />
                    ))
                }
            </Content>
        </CardsContainer>
    )
}


const CardsContainer = styled(Column)`
        width: 100%;
        height: auto;
        padding: ${sizes.medium};

        ${({ wrap }) => wrap && `
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
        `}
    `;

const Content = styled(Column)`
    @media screen and (min-width: ${breakpoints.lg}){
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        padding: ${sizes.medium} 0;

        & > div {
            margin-left: 1%;
            margin-right: 1%;
            width: 31% !important;   
            background: ${colors.neutralLight};
        }
    }
`;
