import DashContainer from 'modules/dashboard/_common/DashContainer'
import SupportContent from 'modules/dashboard/support/SupportContent'
import React from 'react'

export default function DashSuporte() {
  return (
    <DashContainer title={'Como podemos ajudar?'}>
        <SupportContent />
    </DashContainer>
  )
}
