import { useEvent } from 'context/events/Event';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

const items = {
    detail: { name: 'detail', label: 'Visão Geral' },
    tickets: { name: 'tickets', label: 'Ingressos' },
    products: { name: 'products', label: 'Produtos' },
    participant: { name: 'participant', label: 'Participantes' },
    permissions: { name: 'permissions', label: 'Permissões' },
    promotionalCode: { name: 'promotionalCode', label: 'Códigos promocionais' },
    additional: { name: 'additional', label: 'Adicionais' },
};

export default function Tab({ toogleTab, selectedTab, hasTickets, hasProducts }) {
    const {event} = useEvent();

    const [isTicketsLoaded, setIsTicketsLoaded] = useState(false);
    const [isProductsLoaded, setIsProductsLoaded] = useState(false);

    useEffect(() => {
        const checkTickets = async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setIsTicketsLoaded(true);
        };

        checkTickets();
    }, [hasTickets]);

    useEffect(() => {
        const checkProducts = async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setIsProductsLoaded(true);
        };

        checkProducts();
    }, [hasProducts]);


    return (
        <ContainerTab>
            <StyledTab>
                {Object.values(items).map((item) => (
                    <TabItem
                        key={item.name}
                        onClick={() =>
                            (item.name !== 'tickets' || (item.name === 'tickets' && isTicketsLoaded && hasTickets)) &&
                            (item.name !== 'products' || (item.name === 'products' && isProductsLoaded && hasProducts))
                                ? toogleTab(item.name)
                                : null
                        }
                        active={selectedTab === item.name}
                        disabled={
                            (item.name === 'tickets' && !hasTickets) || 
                            (item.name === 'products' && !hasProducts) || 
                            (item.name === items.participant.name && !event) ||
                            (item.name === items.permissions.name && !event) || 
                            (item.name === items.additional.name && !event) || 
                            (item.name === items.promotionalCode.name && !event) 
                        }
                    >
                        {item.label}
                    </TabItem>
                ))}
            </StyledTab>
        </ContainerTab>
    );
}

const ContainerTab = styled.div`
    display: flex;
    justify-content: flex-start;
`

const StyledTab = styled(Row)`
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    overflow-x: auto;

    border-bottom: 1px solid ${colors.primaryDashboard};
`;

const TabItem = styled.div`
    cursor: pointer;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* max-width: 125px; */
    /* min-width: 130px; */
    /* max-width: auto; */
    width: auto;
    padding: 0 14px;
    text-align: center;
    color: ${colors.nightDark};
    font-weight: bold;
    transition: all ease-in-out 0.25s;


    @media screen and (min-width: ${breakpoints.lg}) {
        ${({ disabled }) => !disabled && `
            &:hover {
                color: ${colors.secondaryPurple};
            }
        `}
    }
   
    ${({ disabled }) => disabled && `
        cursor: not-allowed;
        opacity: 0.5;
    `}

    ${({ active }) => active && `
        color: ${colors.secondaryPurple};
        border-bottom: 2px solid ${colors.secondaryPurple};
    `}
    
`;