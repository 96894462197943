import ButtonSelect from 'components/_UI/ButtonSelect'
import { SelectContainer } from 'components/_UI/SelectsContent'
import Text from 'components/_UI/Text'
import { usePreferences } from 'context/user/Preferences'
import React from 'react'
import styled from 'styled-components'
import { HorizontalCenter } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'
import sizes from 'theme/sizes'
import time from 'theme/times'


let days = []

const WeekDaysPreferences = React.forwardRef(({error}, ref) => {
    const {preferences, setPreferences} = usePreferences()
    
    function removeObjectWithId(arr, id) {
        const objWithIndex = arr.findIndex(obj => obj.id === id)

        if (objWithIndex > -1) {
            arr.splice(objWithIndex, 1)
        }

        return arr
    }

    function daysFilter(active, item) {
        const daysObject = {
            active: active === 'ACTIVE' || false,
            name: item.name,
            id: item.id
        }

        if (active === 'INATIVE') {
            const newArray = removeObjectWithId(days, daysObject.id)
            setPreferences({ ...preferences, weekDays: newArray })
            return;
        }

        days.push(daysObject)
        setPreferences({ ...preferences, weekDays: days })
    }

  return (
    <Container ref={ref}>
        <Text 
            type='bold' 
            marginBottom={sizes.normal}
        >
            Dias da semana que você prefere sair:
        </Text>
        <SelectContainer>
            {time?.weekdays.map((el) => (
                <ContentSelect>
                    <ButtonSelect
                        marginRight={'0px'}
                        onToggle={(state) => daysFilter(state, el)}
                    >
                        {el.label}
                    </ButtonSelect>
                </ContentSelect>
            ))}
        </SelectContainer>
        {error && (
            <HorizontalCenter>
                {error === 'min' && (
                    <Text color={colors.danger}> Você deve selecionar pelo menos um dia da semana </Text>
                )}
            </HorizontalCenter>
        )}
    </Container>
  )
})

export default WeekDaysPreferences;

const Container = styled(Column)`
    margin-top: ${sizes.normal};
    & > se {
        margin-right: 0px
    }
`;

const ContentSelect = styled.div`
    display: flex;
    height: auto;
    flex-wrap: wrap;
    margin-left: ${sizes.micro};
    & > button {
        margin-right: 0px
    }
`;


