import Loader from 'components/_UI/Loader';
import Modal from 'components/_UI/Modal'
import Radio from 'components/_UI/Radio';
import Text from 'components/_UI/Text'
import { useShowLocationModal } from 'context/general/ShowLocationModal';
import { useCurrentLocation } from 'context/user/CurrentLocation';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { useSearchParams } from 'react-router-dom';


export default function ModalLocation({ data, onChangeModalLocation = () => false }) {
    const { currentLocation, setCurrentLocation } = useCurrentLocation();
    const { showLocationModal, setShowLocationModal } = useShowLocationModal();
    const [searchParams, setSearchParams] = useSearchParams();

    // const [loading, setLoading] = useState(false)

    function onChangeSelect(city) {
        // setSelected(city)
        setCurrentLocation({ city: city })
        setShowLocationModal(false)
        onChangeModalLocation({ city });
        setSearchParams(new URLSearchParams());
    }

    return (
        <Modal isOpen={showLocationModal} onClose={() => setShowLocationModal(false)}>
            <ModalWrapper>
                <Text name='subtitle' type='bold'>
                    Escolha uma região
                </Text>
                <ContentLocation>

                    <Text marginTop={'30px'} type='bold'>Rio Grande do Sul</Text>

                    {
                        data?.map((el) => (
                            <Radio
                                key={el}
                                label={el}
                                selected={currentLocation?.city == el}
                                onSelect={() => onChangeSelect(el)}
                            />
                        ))
                    }
                </ContentLocation>
            </ModalWrapper>
        </Modal>
    )
}


const ModalWrapper = styled(Column)`
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const ContentLocation = styled(Column)`
    width: 50%;
    align-items: flex-start;
`