import React, { createContext, useState, useContext } from 'react';

const EventContext = createContext();

export default function EventProvider({ children }) {
    const [event, setEvent] = useState(null);

    return (
        <EventContext.Provider value={{ event, setEvent }}>
            {children}
        </EventContext.Provider>
    );
}

export function useEvent() {
    const context = useContext(EventContext);

    if (!context) throw new Error('useEvent must be used within a EventProvider');

    const { event, setEvent } = context;
    return { event, setEvent };
}