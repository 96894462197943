import React, { useEffect, useRef } from 'react';

const DescriptionWithHTML = ({ data }) => {
    const descriptionRef = useRef(null);

    const setImageStyles = () => {
        if (descriptionRef.current) {
            const images = descriptionRef.current.querySelectorAll('img');

            images.forEach(img => {
                if (window.innerWidth >= 1200) {
                    // Aplica o estilo original (se houver)
                    const style = img.getAttribute('style');
                    if (style) {
                        const match = style.match(/width:\s*([\d]+px);?\s*height:\s*([\d]+px);?/i);
                        if (match) {
                            img.style.width = match[1]; // width original
                            img.style.height = match[2]; // height original
                        }
                    } else {
                        // Se não houver style retornado do back, aplica width 100% e height auto
                        img.style.width = '100%';
                        img.style.height = 'auto';
                    }
                } else {
                    // Para telas menores que 1200px
                    img.style.width = '100%';
                    img.style.height = 'auto';
                }
            });
        }
    };

    useEffect(() => {
        setImageStyles();

        window.addEventListener('resize', setImageStyles);

        return () => {
            window.removeEventListener('resize', setImageStyles);
        };
    }, [data]);

    return (
        <div
            ref={descriptionRef}
            dangerouslySetInnerHTML={{ __html: data }}
        />
    );
};

export default DescriptionWithHTML;
