import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledSwitch, ContentSwitch, Slider, SwitchInput, TextLabel } from 'styled/UI/StyledSwitch';
import colors from 'theme/colors';
import fonts from 'theme/fonts';

function Switch({
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  checked,
  size = '30px',
  label = '',
  labelSize = fonts.sizes.default,
  labelColor,
  id,
  onChange = () => false,
}) {
  const [check, setCheck] = useState(checked);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  const handleChange = () => {
    setCheck(!check);
    onChange(!check);
  };

  return (
    <StyledSwitch
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
    >
      <ContentSwitch>
        <SwitchInput id={id} type="checkbox" checked={check} onChange={handleChange} />
        <Slider
          size={size}
        />
      </ContentSwitch>

      {
        label && <TextLabel for={id} labelColor={labelColor} labelSize={labelSize}>
          {label}
        </TextLabel>
      }
    </StyledSwitch>
  );
}

Switch.propTypes = {
  marginTop: PropTypes.string,
  marginRight: PropTypes.string,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Switch.defaultProps = {
  labelColor: '#707070'
};

export default Switch;
