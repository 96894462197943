import React, { createContext, useState, useContext } from 'react';

const StepRegisterContext = createContext();

export default function StepRegisterProvider({ children }) {
    const [stepRegister, setStepRegister] = useState(1);

    return (
        <StepRegisterContext.Provider value={{stepRegister, setStepRegister}}>
            {children}
        </StepRegisterContext.Provider>
    );
}

export function useStepRegister() {
    const context = useContext(StepRegisterContext);
    
    if (!context) throw new Error('useStepRegister must be used within a StepRegisterProvider');
    
    const { stepRegister, setStepRegister } = context;
    return { stepRegister, setStepRegister };
}