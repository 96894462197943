import React from 'react';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import colors from 'theme/colors';
import fonts from 'theme/fonts';
import Text from './Text';

const InfoTooltip = ({ children, text, bgColor = colors.occasionalPurple, type, textColor = colors.neutralLight }) => (
  <TooltipContainer>
    {type !== 'none' ? (
      <CircularTooltipTrigger bgColor={bgColor}>
        {type === 'info' ? 'i' : '?'}
      </CircularTooltipTrigger>
    ) : (
      <InvisibleTooltipTrigger />
    )}
    <Tooltip>
      <Text color={textColor} paddingBottom={0}>
        {text}
      </Text>
    </Tooltip>
    {children}
  </TooltipContainer>
);


const Tooltip = styled.div`
  visibility: hidden;
  background-color: rgba(0,0,0, .85);
  color: ${colors.neutral};
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%; 
  left: 50%;
  transform: translateX(-49%); 
  opacity: 0;
  transition: opacity 0.3s;
  font-size: ${fonts.sizes.small};
  width: max-content; 
  max-width: 300px; 

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-49%); 
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0,0,0, .85) transparent transparent transparent;
  }
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;

const InvisibleTooltipTrigger = styled.div`
  visibility: hidden;
  position: absolute;
  width: 15px;
  height: 15px;
`;


const CircularTooltipTrigger = styled(FullyCentered)`
    margin-left: 5px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    color: ${colors.neutral};
    font-weight: bold;
    font-size: 11px;
    opacity: .8;
    cursor: pointer;

    ${({ bgColor }) => bgColor && `
      background: ${bgColor};
    `};
`;

export default InfoTooltip;