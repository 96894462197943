// ProductForm.js
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import Button from 'components/_UI/Button';
import Fieldset from 'components/_UI/Fieldset';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import CurrencyInputField from 'components/_UI/CurrencyInputField';
import ImageProduct from './parts/ImageProduct';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import fieldValidations from 'helpers/fieldValidations';
import { useListProducts } from 'context/products/ListProducts';
import imageUploadApi from 'api/requests/imageUpload';
import { useUser } from 'context/user/User';
import userApi from 'api/requests/user';
import productsApi from 'api/requests/products';
import ToastContainer from 'components/_UI/ToastsContainer';
import useToast from 'hooks/useToast';
import { HorizontalCenter } from 'styled/alignment/Center';
import validateInputFile  from 'helpers/validate-input-file';
import allowedFilesTypes from 'texts/messages/allowedFilesTypes';

export default function ProductForm({
  onClose,
  isEdit,
  onSucess,
  onProductClick,
  onError
}) {
  const { listProducts, setListProducts } = useListProducts();
  const [isLoading, setIsLoading] = useState(false);
  const [errorImage, setErrorImage] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const { user, setUser } = useUser();
  const [image, setImage] = useState(null);
  const [establishmentId, setEstablishmentId] = useState(null);
  const [resetState, setResetState] = useState(false); 
  const [errorTypeImage, setErrorTypeImage] = useState('')
  const { toast, toasts } = useToast();


  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productName: '',
      description: '',
      quantity: '',
      price: ''
    }
  });

  useEffect(() => {
    if (!user) {
      getUser();
    }
    if (isEdit) {
      reset({
        productName: isEdit.name,
        description: isEdit.description || '',
        quantity: isEdit.quantity,
        price: isEdit.price
      });
      setImage({ image_id: isEdit?.image_id, path: isEdit?.image || null });
    } 
    if (!isEdit) {
      onResetForm();
    }
  }, [user, isEdit, reset]);

  const getUser = async () => {
    try {
      const { data } = await userApi.me();
      setUser(data);
      setEstablishmentId(data?.establishments[0]?.id);
    } catch (error) {
      console.error(error);
    }
  };


  async function uploadImage(file) {
    setIsImageUploading(true);

    const validationFile = validateInputFile(file);

    if (!validationFile.isValid) {
      setErrorTypeImage(validationFile.message);
      setIsImageUploading(false);
      return
    }
  
    const formData = new FormData();
    formData.append('image', file);
    formData.append('folder', 'products');
  
    try {
      const { data } = await imageUploadApi.uploadWithFolder(formData);
      setImage({ image_id: data?.id, path: data?.path });
    } catch (error) {
      setErrorImage(true);
    } finally {
      setIsImageUploading(false);
    }
  }

  async function onToggleDelete() {
    setErrorImage(false)
    setErrorTypeImage(false)
    if (!image) return;

    try {
      await imageUploadApi.delete(image.image_id);
      setImage(null);
    } catch (error) {
      console.error(error);
    }
  }

  const onResetForm = () => {
    setImage(null);
    reset({
      productName: '',
      description: '',
      quantity: '',
      price: ''
    });
    setResetState(prev => !prev); 
  };

  const createPayload = (content) => {
    const payload = {
      name: content.productName,
      price: parseFloat(content.price.replace(',', '.')).toFixed(2),
      type: 'product',
      establishment_id: establishmentId,
      quantity: parseInt(content.quantity),
      description: content.description,
      image_id: image?.image_id,
      image: image?.path
    };

    if (!payload.description) {
      delete payload.description;
    }

    if (!payload.image_id) {
      delete payload.image_id;
    }
    return payload;
  };

  const editProduct = async (content) => {
    setIsLoading(true);
    const payload = createPayload(content);

    try {
      const { data } = await productsApi.update(isEdit?.id, payload);
      setListProducts((prevList) =>
        prevList.map((product) => (product.id === isEdit.id ? { ...product, ...payload } : product))
      );

      onResetForm();
      onSucess('editado');
      onClose();
    } catch (error) {
      onError();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCurrencyChange = (value) => {
    if (value) {
        const formattedValue = value.replace('.', ''); 
        return formattedValue;
    }
    return '';
  };

  const createProduct = async (content) => {
    setIsLoading(true);
    const payload = createPayload(content);
    
    try {
      const { data } = await productsApi.create(payload);
      setListProducts([...listProducts, data]);

      onResetForm();
      onSucess('criado');
      onClose()
    } catch (error) {
      onError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(isEdit ? editProduct : createProduct)}>
      <ToastContainer toasts={toasts} />
      <Text name='title' marginBottom={'20px'} color={colors.occasionalPurple}>
        {`${isEdit ? 'Editar' : 'Criar'} produto`}
      </Text>

      <ContentInputs>
        <Column verticalCenter>
          <Fieldset
            layout='rounded'
            placeholder='Nome do produto'
            name="productName"
            validations={fieldValidations.generic('nome', 2, 100)}
            register={register}
          />

          {errors.productName && (
            <HorizontalCenter marginTop={'10px'}>
              <Text color={colors.danger}>{errors.productName.message}</Text>
            </HorizontalCenter>
          )}

          <Fieldset
            layout='rounded'
            placeholder='Descrição do produto'
            name='description'
            validations={fieldValidations.description('descrição', 100)}
            register={register}
            marginBottom='15px'
          />
          {errors.description && (
            <HorizontalCenter marginTop={'10px'}>
              <Text color={colors.danger}>{errors.description.message}</Text>
            </HorizontalCenter>
          )}

          <Fieldset
            placeholder='Quantidade'
            layout='rounded'
            name='quantity'
            validations={fieldValidations.number('quantidade')}
            register={register}
          />

          {errors.quantity && (
            <HorizontalCenter marginTop={'10px'}>
              <Text color={colors.danger}>{errors.quantity.message}</Text>
            </HorizontalCenter>
          )}

          <Controller
            name='price'
            control={control}
            rules={{ required: 'O preço é obrigatório' }}
            render={({ field: { onChange, value, ref } }) => (
                <CurrencyInputField
                    placeholder='Preço sugerido'
                    value={value}
                    onChange={(value) => {
                        const formattedValue = handleCurrencyChange(value);
                        onChange(formattedValue); // Passa o valor formatado para onChange
                    }}
                    ref={ref}
                    layout='rounded'
                />
            )}
          />

          {errors.price && (
            <HorizontalCenter marginTop={'10px'}>
              <Text color={colors.danger}>{errors.price.message}</Text>
            </HorizontalCenter>
          )}
        </Column>

        <Column verticalCenter>
          <ImageProduct
            product
            isEdit={isEdit}
            onToggleImage={(e) => uploadImage(e)}
            onDeleteFile={() => onToggleDelete()}
            resetState={resetState}
            errorImage={errorImage}
            errorTypeImage={errorTypeImage}
            setErrorTypeImage={setErrorTypeImage}
            setErrorImage={setErrorImage}
            allowedFiles={allowedFilesTypes?.images}
          />
        </Column>
      </ContentInputs>

      {!isLoading ? (
        <ContentButton>
          <Button marginTop={'20px'} type="submit" variant={isImageUploading ? 'disabled' : 'featured'}>
            {`${isEdit ? 'Editar' : 'Criar'} produto`}
          </Button>
        </ContentButton>
      ) : (
        <Loader />
      )}
    </StyledForm>
  );
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentInputs = styled(Column)`
  align-items: center;
  @media screen and (min-width: ${breakpoints.sm}) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const ContentButton = styled.div`
  width: 100%;
`;
