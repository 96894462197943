import Divider from 'components/_UI/Divider';
import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import currency from 'helpers/currency';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import { useNavigate } from 'react-router-dom';
import breakpoints from 'theme/breakpoints';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';
import QRCode from 'qrcode.react';
import DynamicProfileBackground from 'components/commons/DynamicProfileBackground';
import { Calendar, MapPin } from 'react-feather';
import date from 'helpers/date';
import sizes from 'theme/sizes';
import indexPriority from 'theme/z-index';
import Button from 'components/_UI/Button';
import Modal from 'components/_UI/Modal';
import { useInvisibleButton } from 'context/general/InvisibleButton';
import { useCollapse } from 'context/general/Collapse';


export default function WalletDetail({data, onBack }) {

    const tickets = data?.tickets?.filter(ticket => ticket.type === 'ticket') || [];
    const products = data?.tickets?.filter(ticket => ticket.type === 'product') || [];

    const totalPrice = tickets.concat(products).reduce((sum, ticket) => sum + ticket.price, 0);
    const {backButtonCollapse, setBackButtonCollapse} = useBackButtonCollapse()
    const { setInvisibleButton } = useInvisibleButton()

    const [ticketStatus, setTicketStatus] = useState([]);
    const [productStatus, setProductStatus] = useState([])
    const [visibleQrCode, setVisibleQrCode] = useState(false)
    const {collapse} = useCollapse()

    useEffect(() => {
       
        
        setBackButtonCollapse({
            ...backButtonCollapse,
            withHideCity: false,
            visible: true
        })
        
        
        const currentDate = new Date();
        const statusTickets = tickets.map(ticket => {
            const eventDate = new Date(data?.dates[0]?.date);
            if (eventDate < currentDate && !ticket?.used_at) {
                return 'expired';
            }
            if (ticket.used_at) {
                return 'used';
            }
        });
        setTicketStatus(statusTickets);
        const statusProducts = products?.map(ticket => {
            const eventDate = new Date(data?.dates[0]?.date);
            if (eventDate < currentDate && !ticket?.used_at) {
                return 'expired';
            }
            if (ticket.used_at) {
                return 'used';
            }
        });
        setProductStatus(statusProducts);
    }, []);
    
    function openModalQRCode(id) {
        setVisibleQrCode((prev) => ({ ...prev, [id]: true }));
        setInvisibleButton(true);
    }
    
    function closeModalQRCode(id) {
        setVisibleQrCode((prev) => ({ ...prev, [id]: false }));
        setInvisibleButton(false);
    }

    return (
        <ContainerDetail>
            {
                collapse?.height != 'max' && (
                    <StyledRow >
                        <ContenButtonBack
                            onClick={() => onBack()}
                        >
                            <StyledButton>
                                <Icon name='back' color={colors.night} />
                            </StyledButton>
                        </ContenButtonBack>
                    </StyledRow>
                )
            }

            <Text marginTop={'20px'} name='subtitle'>Detalhes da compra</Text>

            <ContainerInfos>
                <ContentEvent>
                    <DynamicProfileBackground
                        img={data?.images}
                        word={data?.name}
                        size={'200px'}
                        event
                    />
                    <Row horizontalCenter marginTop='20px'>
                        <Text color={colors.occasionalPurple} name='highlight' marginLeft={'5px'}>{data?.name}</Text>
                    </Row>
                    <Row verticalCenter>
                        <StyledIcon>
                            <Icon name='paid' color={colors.night}/>
                        </StyledIcon>
                        <Text  marginLeft={'5px'} paddingBottom={'0px'} >{currency.format(totalPrice)}</Text>
                    </Row>
                   
                    <Row verticalCenter marginTop='15px'>
                        <MapPin size={'25px'} color={colors.night} />
                        <Text marginLeft={'5px'} paddingBottom={'0px'}>
                            {data?.address?.street?.name && `${data?.address?.street?.name},`} {data?.address?.street?.district && `Bairro ${data?.address?.street?.district}`}
                            {data?.address?.number && `, nº ${data?.address?.number}`}
                            {data?.address?.complement && ` - ${data?.address?.complement}`}
                        </Text>

                    </Row>
                    <Row verticalCenter marginTop='15px'>
                        <Calendar color={colors.night} size={25} />
                        <Text  marginLeft={'5px'} paddingBottom={'0px'} >{date.format(data.dates[0].date)} &nbsp;- {date.getTimeWithoutSeconds(data?.dates[0].start)}
                        {data?.dates[0].end ? ` até ${date.getTimeWithoutSeconds(data?.dates[0].end)}` : ''}</Text>
                    </Row>
                </ContentEvent>


                {tickets?.length > 0 &&  (
                    <Column verticalCenter marginTop='40px'>
                        <Text name='highlight' marginBottom={'10px'}>Ingresso(s)</Text>

                        <Text type='bold' paddingBottom={'15px'} textAlign={'center'}>Apresente o código ou QRCode na hora do seu check-in</Text>

                        <ContainerTicket>
                            
                            {tickets.map((el, index) => (
                                <Slot key={el.id} status={ticketStatus[index]}>
                                    <ContentSlot status={ticketStatus[index]}>
                                        <Text name='highlight' color={colors.occasionalPurple}>{el.name}</Text>
                                        <Row horizontalCenter>
                                            <Text type='bold'>Valor:</Text>
                                            <Text marginLeft={'5px'}>{currency.format(el.price)}</Text>
                                        </Row>
                                        <Row horizontalCenter>
                                            <Text type='bold'>Participante:</Text>
                                            <Text marginLeft={'5px'}>{el?.participant}</Text>
                                        </Row>

                                        <ContentCodes>
                                        {
                                            (ticketStatus[index] == 'expired' || ticketStatus[index] == 'used' ) &&
                                                <Column verticalCenter marginTop='20px'>
                                                    <StyledQRCode>
                                                        <QRCode
                                                            value={el.code}
                                                            size={150}                                     
                                                            bgColor={"#ffffff"} 
                                                            fgColor={"#000000"} 
                                                            level={"Q"} 
                                                            renderAs={'svg'}
                                                            includeMargin={false}
                                                        />
                                                    </StyledQRCode>

                                                </Column>
                                        }

                                        </ContentCodes>
                                    
                                        {visibleQrCode[el.id] ? (
                                            <Modal isOpen={visibleQrCode[el.id]} onClose={() => closeModalQRCode(el.id)}>
                                                <Text type='bold' paddingBottom={'15px'} textAlign={'center'}>
                                                    Apresente o código ou QRCode na hora do seu check-in
                                                </Text>
                                                <Column verticalCenter marginTop='20px'>
                                                    <StyledQRCode>
                                                        <QRCode
                                                            value={el.code}
                                                            size={150}                                     
                                                            bgColor={"#ffffff"} 
                                                            fgColor={"#000000"} 
                                                            level={"Q"} 
                                                            renderAs={'svg'}
                                                            includeMargin={false}
                                                        />
                                                    </StyledQRCode>

                                                    <Column marginTop='10px' verticalCenter>
                                                        <Text type='bold'>Código</Text>
                                                        <Text marginLeft={'5px'} paddingBottom={'0px'} type='bold'>{el?.code}</Text>
                                                    </Column>

                                                    {
                                                        (ticketStatus[index] !== 'used' && ticketStatus[index] !== 'expired') && (
                                                            <ContentButtonVisibleQrCode close>
                                                                <Button variant='featured' onClick={() => closeModalQRCode(el.id)}>Fechar</Button>
                                                            </ContentButtonVisibleQrCode>
                                                        )
                                                    }
                                                </Column>
                                            </Modal>
                                        ) : (
                                            (ticketStatus[index] !== 'used' && ticketStatus[index] !== 'expired') &&
                                                <ContentButtonVisibleQrCode>
                                                    <Button marginTop={'20px'} variant='featured' onClick={() => openModalQRCode(el.id)}>VER QRCODE</Button>
                                                </ContentButtonVisibleQrCode> 
                                        )

                                        }

                                        <Column marginTop='10px' verticalCenter>
                                            <Text type='bold'>Código</Text>
                                            <Text marginLeft={'5px'} paddingBottom={'0px'} type='bold'>{el?.code}</Text>
                                        </Column>
                                    
                                    </ContentSlot>
                                </Slot>
                            
                            ))}
                        </ContainerTicket>
                    </Column>
                )}
                

                    {products?.length > 0 && (
                        <Column verticalCenter marginTop={tickets.length > 0 ? '40px' : '40px'}>
                        
                            <Text name='highlight' marginBottom={'10px'}>Produto(s)</Text>
                            <Text type='bold' paddingBottom={'15px'} textAlign={'center'}>
                                Apresente o código ou QRCode na hora de retirar seu produto
                            </Text>
                            <ContainerTicket>          
                                {products.map((el, index) => (
                                    <Slot key={el.id} status={productStatus[index]}>
                                        <ContentSlot status={productStatus[index]}>
                                            <Row>
                                                <Picture>
                                                    {
                                                        el?.image ? (
                                                            <StyledImage src={el?.image} />
                                                        ) : (
                                                            <StyledImage src={'../../assets/images/placeholder-products.png'} />
                                                        )
                                                    }
                                                </Picture>
                                                <Column marginLeft='15px'>
                                                    <Text name='highlight' color={colors.occasionalPurple}>
                                                        {el.name}
                                                    </Text>
                                                    <Row>
                                                        <Text type='bold'>Valor:</Text>
                                                        <Text marginLeft={'5px'}>{currency.format(el.price)}</Text>
                                                    </Row>
                                                </Column>
                                            </Row>                      
                                            
                                            <ContentCodes>


                                                {
                                                    (productStatus[index] == 'expired' || productStatus[index] == 'used' ) &&
                                                    <Column verticalCenter marginTop='20px'>
                                                        <StyledQRCode>
                                                            <QRCode
                                                                value={el.code}
                                                                size={150}                                     
                                                                bgColor={"#ffffff"} 
                                                                fgColor={"#000000"} 
                                                                level={"Q"} 
                                                                renderAs={'svg'}
                                                                includeMargin={false}
                                                            />
                                                        </StyledQRCode>

                                                    </Column>
                                                }


                                                {visibleQrCode[el.id] ? (
                                                    <Modal isOpen={visibleQrCode[el.id]} onClose={() => closeModalQRCode(el.id)}>

                                                        <Column verticalCenter marginTop='20px'>
                                                        <Text type='bold' paddingBottom={'15px'} textAlign={'center'}>
                                                            Apresente o código ou QRCode na hora de retirar seu produto
                                                        </Text>
                                                            <StyledQRCode>
                                                                <QRCode
                                                                    value={el.code}
                                                                    size={150}                                     
                                                                    bgColor={"#ffffff"} 
                                                                    fgColor={"#000000"} 
                                                                    level={"Q"} 
                                                                    renderAs={'svg'}
                                                                    includeMargin={false}
                                                                />
                                                            </StyledQRCode>
                                                            <Column marginTop='10px' verticalCenter>
                                                                <Text type='bold'>Código</Text>
                                                                <Text marginLeft={'5px'} paddingBottom={'0px'} type='bold'>{el?.code}</Text>
                                                            </Column>
                                                            {(productStatus[index] !== 'used' && productStatus[index] !== 'expired') &&
                                                                <ContentButtonVisibleQrCode close>
                                                                    <Button variant='featured' onClick={() => closeModalQRCode(el.id)}>Fechar</Button>
                                                                </ContentButtonVisibleQrCode>
                                                            }
                                                        </Column>
                                                    </Modal>
                                                ) : (
                                                    (productStatus[index] !== 'used' && productStatus[index] !== 'expired') &&
                                                        <ContentButtonVisibleQrCode>
                                                            <Button marginTop={'20px'} variant='featured' onClick={() => openModalQRCode(el.id)}>VER QRCODE</Button>
                                                        </ContentButtonVisibleQrCode> 
                                                        
                                                )

                                                }

                                                <Column marginTop='10px' verticalCenter>
                                                    <Text type='bold'>Código</Text>
                                                    <Text marginLeft={'5px'} paddingBottom={'0px'} type='bold'>{el?.code}</Text>
                                                </Column>
                                            </ContentCodes>

                                        </ContentSlot>
                                    </Slot>
                                ))}
                            </ContainerTicket>
                        </Column>
                    )}
            </ContainerInfos>
        </ContainerDetail>
    );
}

const ContainerDetail = styled(Column)`
    align-items: center;
    height: 100%;
    padding-bottom: 50px;
`;

const ContainerInfos = styled(Column)`
    padding: 10px 10px 0 10px;
    align-items: center;
    justify-content: center;
`;



const ContentEvent = styled(Column)`
    background: ${colors.neutralLight};
    border-radius: 8px;
    padding: 10px 10px 20px 10px;
    border-bottom: 1px solid ${colors.primaryDashboard};
`;


const ContainerTicket = styled(Column)`
    position: relative;
    align-items: center;
    background: ${colors.neutralLight};
    border: 1px solid ${colors.primaryDashboard};
    border-radius: 8px;
    margin-bottom: 20px;
    &:first-child {
        background: red;
    }
    
    &:after {
        content: '';
        background: ${colors.neutralBackground};
        position: absolute;
        z-index: 12;
        left: -17px;
        top: 50%;
        transform: translateY(-50%); 
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border-right: 1px solid ${colors.primaryDashboard};
    }

    &:before {
        content: '';
        background: ${colors.neutralBackground};
        position: absolute;
        right: -17px;
        top: 50%;
        z-index: 12;
        transform: translateY(-50%);
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border-left: 1px solid ${colors.primaryDashboard};
    }

   @media screen and (min-width: ${breakpoints.sm}) {
        width: 400px;
   }
`;


const Slot = styled(Column)`
    align-items: center;
    border-top: 2px dashed ${colors.primaryDashboard};
    position: relative;
    border-radius: 8px;
    height: 380px;
    &:nth-child(1) {
        border-radius: 8px;
        border-top: unset;
    }

    ${({status}) => status === 'expired' || status === 'used' ? `
        &::before {
            z-index: ${indexPriority.medium};
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 8px;
            left: ${sizes.none};
            color: yellow;
            background: rgba(26, 26, 26, 0.6);
        }
        &::after {
            z-index: ${indexPriority.medium};
            content: '${status === 'expired' ? 'Expirado' : 'Utilizado'}';
            width: 100%;
            height: 45px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: white;
            display: flex;
            font-size: 16px;
            text-transform: uppercase;
            justify-content: center;
            align-items: center;
            background: ${status === 'expired' ? colors.dangerLight : colors.occasionalOrange};
        }
    ` : ''}
    
    @media screen and (min-width: ${breakpoints.sm}) {
        width: 400px;
    }
   
`;

const ContentSlot = styled(Column)`
    padding: 15px;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    ${({status}) => status === 'expired' && `
        justify-content: center;

       
        & * p, h3 {
            opacity: 0.4 !important; 
        }
        & * img, svg {
            opacity: 0.4 !important; 
        }
       
    `}
    ${({status}) => status === 'used' && `
        justify-content: center;
        & * p, h3 {
            opacity: 0.4 !important; 
        }
        & * img, svg {
            opacity: 0.4 !important; 
        }
    `}
`;

const StyledRow = styled(Row)`
    width: 100%;
    justify-content: center;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        display: none;
    }

`;

const ContenButtonBack = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    margin-left: 5px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: all ease-in-out .25s;
    & > p {
        padding-bottom: 0px;
        font-size: 16px;
        margin-left: 5px;
        color: ${colors.primaryDashboard}
    }

    
    @media screen and (min-width: ${breakpoints.lg}){
        position: absolute;
        right: 60px;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background: #FFFFFF;
        border: 1px solid #ACACAC;
        top: 10px;
        &:hover {
            transform: scale(1.02)
        }
    }

`;

const StyledButton = styled(Row)`
    width: 26px;
    padding: 5px;
    height: 26px;
    border-radius: 50%;
`;

const StyledIcon= styled.div`
    width: 25px;
    height: 25px;
`


const Picture = styled.picture`
    min-width: 90px;
    max-width: 90px;
    height: 90px;
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
`;


const StyledQRCode = styled.div`
   /* margin-bottom: 10px; */
`

const ContentButtonVisibleQrCode = styled(Column)`
    align-items: center;
    width: 250px;
    ${({close}) => close && `
        margin-top: 15px;
        @media screen and (max-width: 375px) {
            width: 100%;
        }
        width: 250px;
    `}
`

const ContentCodes = styled(Column)`
    align-items: center;
    justify-content: center;

`;