import Text from 'components/_UI/Text'
import { useEstablishmentFilter } from 'context/establishments/EstablishmentFilter'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import breakpoints from 'theme/breakpoints'
import colors from 'theme/colors'

const minRange = 1
const maxRange = 50

export default function RangeSelect() {
    const [rangeValue, setRangeValue] = useState(25)

    const { establishmentFilter, setEstablishmentFilter } = useEstablishmentFilter();

    useEffect(() => {
        setEstablishmentFilter({ ...establishmentFilter, distance_range: rangeValue })
    }, [])

    function onChangeRange(range) {
        setRangeValue(range)
        setEstablishmentFilter({ ...establishmentFilter, distance_range: range })
    }

    return (
        <Container >
            <Text size='small' type='bold' marginLeft='6px'> Distância </Text>
            <Text size='small' color={colors.night}>Escolha o alcance da sua busca.</Text>

            <Text marginTop='8px'>
                {rangeValue} km
            </Text>

            <input
                id='filter_range'
                type='range'
                value={rangeValue}
                min={minRange}
                max={maxRange}
                onChange={(e) => onChangeRange(e.target.value)}
            />

        </Container>
    )
}

const Container = styled(FullyCentered)`
    flex-direction: column;
    margin-top: 25px;
    padding: 0 25px;

    & > input {
        width: 100%;
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        width: 80%;
    }
`;