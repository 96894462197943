import React from 'react'
import CardSlot from '../parts/CardSlot'
import Text from 'components/_UI/Text';
import styled from 'styled-components';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import { translateDay } from 'helpers/translateDays';
import { Column } from 'styled/alignment/Column';

export default function ProfileHours({data}) {
  
  return (
    <CardSlot marginTop={'10px'}>
      <Text name='highlight' color={colors.occasionalPurple}>Horários de funcionamento</Text>
      {
        data?.dates &&
        Object.entries(data?.dates).map(([day, hours]) => {
          if(day === 'open') return null;
          return (
            <ContentTimes key={day}>
              <Text type='bold'>{translateDay(day)}</Text>
              <ContentHours>
                {(!hours || hours.length === 0) ? (
                  <Text color={colors.danger}>
                    Fechado
                  </Text>
                ) : (
                  <>
                    {!hours.some(hour => hour['24h']) && hours.map((hour, idx) => (
                      <StyledHours key={idx}>
                        <Text color={colors.night} marginLeft={'3px'} paddingBottom={'0'}>
                          {`${hour.start} ${hour.end && `- ${hour.end}`}`}
                        </Text>
                      </StyledHours>
                    ))}
                    {hours.some(hour => hour['24h']) && (
                      <Text color={colors.night} marginTop={'10px'}>
                        24 horas
                      </Text>
                    )}
                  </>
                )}
              </ContentHours>
            </ContentTimes>
          )
        })
      }
    </CardSlot>
  )
}

const ContentTimes = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  height: auto;
`;

const ContentHours = styled(Column)`
  width: 30%;
  align-items: flex-end;
`;

const StyledHours = styled(Row)`
  justify-content: flex-end;
`;
