import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import phoneFormatter from 'helpers/phone';


export default function Contact({data}) {
  return (
   <ContainerContact>
        <Text  name='highlight' marginBottom={'20px'}>
            Telefone de contato
        </Text>
        <Row fullyCentralized>
            <StyledIcon>
                <Icon name='phone' color={colors.primaryDashboard}/>
            </StyledIcon>
            <Text marginLeft={'10px'} paddingBottom={'0px'}>{phoneFormatter(data)}</Text>
        </Row>
   </ContainerContact>
  )
}

const ContainerContact = styled(Column)`
    width: 100%;
    height: 80px;
`;

const StyledIcon = styled.div`
    width: 15px;
    height: 15px;
`;