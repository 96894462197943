import styled from "styled-components";
import { FullyCentered } from "styled/alignment/Center";
import colors from "theme/colors";
import sizes from "theme/sizes";
import indexPriority from "theme/z-index";
import breakpoints from 'theme/breakpoints';

export const StyledModalOverlay = styled(FullyCentered)`
    z-index: ${indexPriority.enormous};
    position: fixed;
    left: ${sizes.none};
    background: rgba(26, 26, 26, .9);
    width: 100%;
    height: 100vh;
    top: ${sizes.none};        
    padding: ${sizes.gigantic};
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: ${indexPriority.titanic};
        background: rgba(0, 0, 0, .1);

    }
    
    ${({ size }) => size === 'large' && `
        padding: 0 !important;
    `}
    

    ${({ size }) => size === 'xLarge' && `
        padding: 0 !important;
    `}
`;

export const StyledModal = styled(FullyCentered)`
    position: relative;
    width: 400px;
    max-height: 500px;
    flex-direction: column;
    background: ${colors.neutralLight};
    padding: ${sizes.gigantic} ${sizes.large} ${sizes.big} ${sizes.large};
    border-radius: 12px;
    z-index: ${indexPriority.max};
    overflow-x: hidden;

    ${({ size }) => size === 'large' && `
        width: 100%;
        min-height: 100%;
        max-height: 100%;
        border-radius: 0;
        padding-top: 70px;

        @media screen and (min-width: ${breakpoints.lg}) {
            width: 55%;
            min-height: 400px;
            max-height: 85vh;
            border-radius: 8px;
        }
    `}


${({ size }) => size === 'xLarge' && `
        width: 100%;
        min-height: 100%;
        max-height: 100%;
        border-radius: 0;
        padding-top: 70px;

        @media screen and (min-width: ${breakpoints.lg}) {
            width: 85%;
            min-height: 400px;
            max-height: 70%;
            border-radius: 8px;
        }
    `}

    ${({ size }) => size === 'medium' && `
        width: 100%;
        min-height: 100%;
        max-height: 100%;
        border-radius: 0;
        padding-top: 70px;
        
        @media screen and (min-width: ${breakpoints.lg}) {
            width: 45%;
            min-height: 400px;
            max-height: 85vh;
        }
    `}
`;

export const StyledModalContent = styled.div`
    text-align: ${props => props.textAlign};
    width: 100%;
    height: auto;
    overflow: auto;
`;

export const StyledCloseModal = styled.div`
    position: absolute;
    top: ${sizes.little};
    right: ${sizes.little};
    width: ${sizes.intermediary};
    height: ${sizes.intermediary};
    cursor: pointer;

    ${({ circular }) => circular && `
        background: ${colors.neutralLight};
        opacity: 80%;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        padding: 8px;
        z-index: ${indexPriority.max};

        & > svg {
            fill: ${colors.nightDark};
        }
    `}

    & > svg {
        transition: all ease-in-out .3s;
    }
    
    &:hover {
        & > svg {
            fill: ${colors.nightDark};
        }
    }
`;