import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Text from 'components/_UI/Text';
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import { FullyCentered } from 'styled/alignment/Center';
import eventTypes from 'theme/eventTypes';
import { Row } from 'styled/alignment/Row';
import indexPriority from 'theme/z-index';

function EventMarker({ image, type, name, onMarkerClick }) {
  return (
    <Marker id={`map_marker_${type}`} type={type} onClick={() => onMarkerClick()}>
      <MarkerBackground>
        {
          image ? <MarkerImage src={image} /> : <MarkerWithoutImage> {name.charAt(0).toUpperCase()} </MarkerWithoutImage>
        }
      </MarkerBackground>
    </Marker>
  )
};

const Marker = styled.div`
  width: 38px;
  height: 38px;
  background-color: purple;
  position: absolute;
  border-radius: 50% 50% 50% 0;
  transform: rotate(-45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${indexPriority.normal};
  border: 1px solid ${colors.neutral};

  ${({ type }) => type === eventTypes.free.slug && `
        background-color: ${eventTypes.free.color};
  `};
    
  ${({ type }) => type === eventTypes.paid.slug && `
        background-color: ${eventTypes.paid.color};
  `};
`;

const MarkerBackground = styled.div`
  position: absolute;
  top: 8%;
  left: 4%;
  width: 32px;
  height: 32px;
  border-radius: 50%;
`
const MarkerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 32px;
  transform: rotate(45deg);
`;

const MarkerWithoutImage = styled(FullyCentered)`
  width: 100%;
  height: 100%;
  font-size: 2em;
  color: ${colors.neutral};
  transform: rotate(45deg);
`;


export default EventMarker;