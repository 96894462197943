import React from 'react'
import styled from 'styled-components';
import {StyledPaginate, StyledContentPaginate, StyledContentButton, StyledButtonAction, StyledButtonPage, StyledArrow} from 'styled/UI/StyledPaginate';


export default function Paginate({
    // layout props
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    //prop
    currentPage, 
    totalItems, 
    lastPage, 
    perPage,
    firstPage = 1,
    //action
    onPaginate = () => false, 
    onNextClick = () => false,
    onPreviusClick = () => false
}) {

    const generatePageButtons = () => {
        const pages = [];
        const numButtons = 5; // Apenas 4 botões de página serão mostrados
        const middlePage = Math.ceil(numButtons / 2);
        let start = Math.max(currentPage - middlePage, firstPage);
        let end = Math.min(start + numButtons - 1, lastPage);

        if (end - start < numButtons - 1) {
            start = Math.max(end - numButtons + 1, firstPage);
        }

        for (let i = start; i <= end; i++) {
            pages.push(
                <StyledContentButton key={i}>
                    <StyledButtonPage
                        value={i}
                        onClick={() => handlePages(i)}
                        active={i === currentPage}
                    >
                        {i}
                    </StyledButtonPage>
                </StyledContentButton>
            );
        }

        return pages;
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            const numberPage = currentPage - 1
            onPaginate(numberPage)
            onPreviusClick()
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(totalItems / perPage)) {
            const number = currentPage + 1
            onPaginate(number);
            onNextClick()
        }
    }

    const handlePages = (page) => {
        onPaginate(page);
    };


    
  return (
    <StyledPaginate
        marginTop={marginTop} 
        marginRight={marginRight}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
    >
        <StyledContentPaginate>
            {lastPage > 1 && (
                <StyledContentButton>
                    <StyledButtonAction 
                        previous
                        disabled={currentPage !== 1 ? false : true} onClick={() => previousPage()}
                    >
                        <StyledArrow  previous/>
                    </StyledButtonAction>
                </StyledContentButton>
            )}
                {generatePageButtons()}
            {lastPage > 1 && (
                <StyledContentButton>
                    <StyledButtonAction next disabled={currentPage !== lastPage ? false : true} onClick={() => nextPage()}>
                         <StyledArrow next />
                    </StyledButtonAction>
                </StyledContentButton>
            )}
        </StyledContentPaginate> 
    </StyledPaginate>
  )
}

