import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    StyledFieldset,
    StyledLabel,
    StyledInput,
    StyledBadgeText,
    StyledBadgeIcon
} from 'styled/UI/StyledForm';
import Icon from './Icon';

const Fieldset = React.forwardRef(({
    // layout props
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    layout,
    label,
    value,
    placeholder,
    disabled,
    inputType,
    badgeText,
    badgeIcon,
    badgeIconPassword,
    badgeIconColor,
    badgeIconColorPassword,
    badgeIconPositionPassword,
    badgePosition,
    badgeIconBackground,
    isBadgeClickable,
    mask,
    maskChar,
    maskPlaceholder,
    name,
    register,
    validations,
    onChangeValue,
    onBadgeClick,
    onChangeFocus,
    refresh,
    syncProp,
    withDebounce,
    readOnly,
    textIndent,
    id,
    style,
    onKeyPress
}, ref
) => {

    const [newValue, setNewValue] = useState(value);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (refresh) onRefreshInput();
    }, [refresh]);

    const toggleValue = (e) => {
        setNewValue(e);
        onChangeValue(e || false);
    };

    const onRefreshInput = () => {
        setNewValue('');
        onChangeValue('');
    };

    const toggleShowPassword = () => {
        setShowPassword(prev => !prev);
    };

    return (
        <StyledFieldset
            marginTop={marginTop} 
            marginRight={marginRight}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
        >
            {label && (
                <StyledLabel layout={layout}>
                    {label}
                </StyledLabel>
            )}
            {!register ? (
                <StyledInput
                    style={style}
                    id={id}
                    textIndent={textIndent}
                    value={syncProp ? value : newValue}
                    placeholder={placeholder}
                    layout={layout}
                    type={showPassword ? 'text' : inputType}
                    disabled={disabled}
                    icon={badgeIcon}
                    mask={mask}
                    maskChar={maskChar}
                    maskPlaceholder={maskPlaceholder}
                    readOnly={readOnly}
                    onKeyPress={onKeyPress}
                    onChange={(e) => toggleValue(e.target.value)}
                    onFocus={(e) => onChangeFocus(e)}
                />
            ) : (
                <StyledInput
                    id={id}
                    ref={ref}
                    textIndent={textIndent}
                    placeholder={placeholder}
                    layout={layout}
                    type={showPassword ? 'text' : inputType}
                    disabled={disabled}
                    icon={badgeIcon}
                    mask={mask}
                    maskChar={maskChar}
                    maskPlaceholder={maskPlaceholder}
                    onKeyPress={onKeyPress}
                    onChange={(e) => toggleValue(e.target.value)}
                    onFocus={(e) => onChangeFocus(e)}
                    {...register(name, validations)}
                    readOnly={readOnly}
                />
            )}
            {badgeText && (
                <StyledBadgeText
                    position={badgePosition}
                    clickable={isBadgeClickable && !disabled}
                    layout={layout}
                    onClick={() => onBadgeClick()}
                >
                    {badgeText}
                </StyledBadgeText>
            )}

            {badgeIcon && (
                <StyledBadgeIcon
                    id='badge_icon'
                    layout={layout}
                    bgColor={badgeIconBackground}
                    position={badgePosition}
                    clickable={isBadgeClickable && !disabled}
                    onClick={() => onBadgeClick()}
                >
                    <Icon name={badgeIcon} color={badgeIconColor} />
                </StyledBadgeIcon>
            )}

            {badgeIconPassword  && (
                <StyledBadgeIcon
                    id='visibility_icon'
                    layout={layout}
                    bgColor={badgeIconBackground}
                    position={badgeIconPositionPassword}
                    clickable={!disabled}
                    onClick={toggleShowPassword}
                >
                    <Icon name={showPassword ? 'visibilityOff' : 'visibility'} color={badgeIconColorPassword} />
                </StyledBadgeIcon>
            )}
        </StyledFieldset>
    )
})

Fieldset.propTypes = {
    layout: PropTypes.oneOf(['straight', 'straight-center', 'straight-center-dark', 'rounded', 'roundedBorded','roundedDark', 'squired']),
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.string || PropTypes.bool,
    inputType: PropTypes.string,
    badgeText: PropTypes.elementType || PropTypes.string,
    badgeIcon: PropTypes.elementType || PropTypes.string,
    badgeIconColor: PropTypes.string,
    badgeIconBackground: PropTypes.string,
    badgePosition: PropTypes.oneOf(['left', 'right']),
    isBadgeClickable: PropTypes.bool,
    syncProp: PropTypes.bool,
    onChangeValue: PropTypes.func,
    onBadgeClick: PropTypes.func,
    debounce: PropTypes.number || PropTypes.bool,
    readOnly: PropTypes.bool
};

Fieldset.defaultProps = {
    layout: 'straight',
    label: '',
    value: '',
    placeholder: '',
    disabled: '',
    inputType: 'text',
    badgeText: '',
    badgeIcon: '',
    // badgeIconColor: '',
    // badgeIconBackground: 'transparent',
    badgePosition: 'right',
    isBadgeClickable: false,
    mask: '',
    refresh: false,
    readOnly: false,
    syncProp: false,
    onChangeValue: () => '',
    onBadgeClick: () => '',
    onChangeFocus: () => '',
};

export default Fieldset;