import { useState } from "react"
import Modal from "components/_UI/Modal"
import Text from "components/_UI/Text"
import { Column } from "styled/alignment/Column"
import Fieldset from "components/_UI/Fieldset"
import Button from "components/_UI/Button"
import Loader from "components/_UI/Loader"
import buttonText from "texts/buttons/buttonText"
import colors from "theme/colors"

export default function CouponModal({
    code,
    isOpen,
    error,
    loading,
    onClose = () => {},
    onSubmit = () => {}
}) {
    const [promotionalCode, setPromotionalCode] = useState(code)

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <Column>
                <Text
                    name="subtitle"
                    marginBottom={'1rem'}
                >
                    Insira o código promocional
                </Text>
                <Column marginBottom={'1rem'}>
                    <Fieldset
                        placeholder='Código'
                        layout='straight'
                        onChangeValue={e => setPromotionalCode(e)}
                    />
                    {
                        Boolean(error) && <Text color={colors.danger}>{error}</Text>
                    }
                </Column>
                {
                    loading ? 
                        <Loader /> :
                        <Button onClick={() => onSubmit(promotionalCode)}>{buttonText.apply}</Button>
                }
            </Column>
        </Modal>
    )
}