import Fieldset from 'components/_UI/Fieldset';
import Text from 'components/_UI/Text';
import React, { useState, useEffect } from 'react';
import { Column } from 'styled/alignment/Column';
import { StyledForm } from 'styled/UI/StyledForm';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import { useForm } from 'react-hook-form';
import Button from 'components/_UI/Button';
import { HorizontalCenter } from 'styled/alignment/Center';
import { useStepRecoverAccount } from 'context/login/StepRecoverAccount';
import { useUser } from 'context/user/User';
import { useNavigate } from 'react-router-dom';
import loginApi from 'api/requests/login';
import step from 'helpers/system/step';
import buttonText from 'texts/buttons/buttonText';
import Loader from 'components/_UI/Loader';
import fieldMessages from 'texts/messages/field';
import fieldValidations from 'helpers/fieldValidations';
import paths from 'routes/paths';
import styled from 'styled-components';

function RecoverAccountStepTwo() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [remainingTime, setRemainingTime] = useState(600);

    const { stepRecoverAccount, setStepRecoverAccount } = useStepRecoverAccount();
    const { user, setUser } = useUser();

    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setRemainingTime((prevTime) => prevTime - 1);

            if (remainingTime <= 0) {
                clearInterval(countdownInterval);
                navigate(paths.login);
            }
        }, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
    }, [remainingTime]);

    const nextStep = async ({ code }) => {
        setIsLoading(true);

        try {
            const { data } = await loginApi.verifyCode({ code });
            setUser({ ...user, ...data, code });
            setStepRecoverAccount(step.next(stepRecoverAccount));
        } catch ({ response }) {
            if (response.status === 422) {
                setErrorMessage(fieldMessages.invalidMaleField('código'));
                return;
            }

            setErrorMessage(fieldMessages.generic);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Column verticalCenter>
                {/* <Text color={colors.neutral} type='bold' name='highlight'></Text> */}
                <ContentTitle>
                    <Text color={colors.neutral} textAlign='center'>Enviamos um código para o e-mail: <b>{user?.email}</b></Text>
                </ContentTitle>
            </Column>


            <StyledForm
                marginTop={sizes.normal}
                paddingRight='13%'
                paddingLeft='13%'
                onSubmit={handleSubmit(nextStep)}
            >
                <Fieldset
                    placeholder='Código'
                    layout='straight-center'
                    marginTop={sizes.normal}
                    name="code"
                    register={register}
                    validations={fieldValidations.code()}
                />

                {(errors.code?.message || errorMessage) && (
                    <HorizontalCenter>
                        <Text color={colors.danger}> {errors.code?.message || errorMessage} </Text>
                    </HorizontalCenter>
                )}

                <Column verticalCenter marginTop={sizes.normal}>
                    <Text color={colors.neutral} type='bold' name='default'>Seu código expira em:</Text>
                    <Text color={colors.neutral} type='bold' name='default'>
                        {Math.floor(remainingTime / 60)} min {remainingTime % 60} seg
                    </Text>
                </Column>

                {
                    !isLoading ? (
                        <Button
                            category='roundedBorded'
                            variant='secondary'
                            size='large'
                            marginTop={sizes.big}
                            type='submit'
                        >
                            {buttonText.confirm}
                        </Button>
                    ) : (<Loader />)
                }

                <Button
                    onClick={() => setStepRecoverAccount(step.back(stepRecoverAccount))}
                    category='minimal'
                    variant='primary'
                    size='large'
                    marginTop={sizes.nano}
                >
                    {buttonText.back}
                </Button>
            </StyledForm>
        </>
    )
};

export default RecoverAccountStepTwo;

const ContentTitle = styled.div`
 & > p {
    font-size: 16px;
 }
`