
const socialMedia = [
    {
        id: 1,
        name: 'facebook',
        icon: 'facebook',
        color: '#3b5998'
    },
    {
        id: 3,
        name: 'instagram',
        icon: 'instagram',
        color: '#C13584'
    },
    {
        id: 5,
        name: 'whatsapp',
        icon: 'whatsapp',
        color: '#25D366'
    },
    {
        id: 2,
        name: 'youtube',
        icon: 'youtube',
        color: '#FF0000'
    },
    {
        id: 4,
        name: 'site',
        icon: 'link',
        color: '#151136',
    },
    {
        id: 6,
        name: 'tiktok',
        icon: 'tiktok',
        color: '#1A1A1A'
    },
]

export default socialMedia;