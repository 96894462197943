import { useState, useEffect, useCallback } from 'react';

let toastId = 0;

const useToast = () => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback((message, type, position) => {
    toastId += 1;
    const id = toastId;
    setToasts((prevToasts) => [...prevToasts, { id, message, type, position }]);
    setTimeout(() => {
      removeToast(id);
    }, 3000);
  }, []);

  const removeToast = useCallback((id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  const toast = {
    success: (message, options) => addToast(message, 'success', options?.position),
    error: (message, options) => addToast(message, 'error', options?.position),
    warning: (message, options) => addToast(message, 'warning', options?.position),
  };

  return { toasts, toast };
};

export default useToast;
