import React, { createContext, useState, useContext } from 'react';

const NearListAllContext = createContext();

export default function NearListAll({ children }) {
    const [nearListAll, setNearListAll] = useState(null);

    return (
        <NearListAllContext.Provider value={{ nearListAll, setNearListAll }}>
            {children}
        </NearListAllContext.Provider>
    );
}

export function useNearListAll() {
    const context = useContext(NearListAllContext);

    if (!context) throw new Error('useNearListAll must be used within a NearListAllContextProvider');

    const { nearListAll, setNearListAll } = context;
    return { nearListAll, setNearListAll };
}