import React from 'react';
import PropTypes from 'prop-types';
import buttons from 'theme/buttons';
import { StyledButton, StyledRouteLink } from 'styled/UI/StyledButton';

function Button({
    // layout props
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    // action props
    variant,
    category,
    size,
    path,
    children,
    type,
    id,
    style,
    onClick
}) {

    const renderButton = () => (
        <StyledButton
            style={style}
            id={id}
            variant={variant}
            category={category}
            size={size}
            disabled={variant === 'disabled'}
            type={type}
            marginTop={marginTop}
            marginRight={marginRight}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            onClick={(e) => onClick && onClick(e)}
        >
            {children}
        </StyledButton>
    );

    return (
        path ? (
            <StyledRouteLink to={path}> {renderButton()} </StyledRouteLink>
        ) : renderButton()
    )
};

Button.propTypes = {
    variant: PropTypes.oneOf([
        ...buttons.variant, 'danger', 'yellow-borded', 'pink-borded'
    ]),

    category: PropTypes.oneOf([
        ...buttons.category
    ]),

    size: PropTypes.oneOf([
        ...buttons.size
    ])
};

Button.defaultProps = {
    variant: 'primary',
    category: 'rounded',
    size: 'default',
    type: 'button',
    path: '',
};

export default Button;