import Icon from 'components/_UI/Icon';
import React from 'react'
import paths from 'routes/paths';
import styled from 'styled-components';
import colors from 'theme/colors';

export default function Topbar(props) {
    function goHome() {
        window.location.href = paths.home
    }
  return (
    <StyledHeader>
        <WhizrLogo
            onClick={() => goHome()}
        >
            <Icon name={'whizr-inline-logo-dark'} color={''} />
        </WhizrLogo>

        <IconContainer
            onClick={props.openMenu}
        >
            <Icon name={'menu'} color={colors.night} />
        </IconContainer>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    background: ${colors.neutralLight};
`;

const IconContainer = styled.button`
    width: 30px;
    height: 30px;
    background: none;
`;

const WhizrLogo = styled.div`
    width: 100px;
    height: 30px;
`;
