import React, { createContext, useState, useContext } from 'react';

const EventDetailTabContext = createContext();

export default function EventDetailTabProvider({ children }) {
    const [eventDetailTab, setEventDetailTab] = useState('detail');

    return (
        <EventDetailTabContext.Provider value={{ eventDetailTab, setEventDetailTab }}>
            {children}
        </EventDetailTabContext.Provider>
    );
}

export function useEventDetailTab() {
    const context = useContext(EventDetailTabContext);

    if (!context) throw new Error('useEventDetailTab must be used within a EventDetailTabProvider');

    const { eventDetailTab, setEventDetailTab} = context;
    return { eventDetailTab, setEventDetailTab };
}