import customFieldsApi from 'api/requests/customFields';
import Modal from 'components/_UI/Modal'
import { useEvent } from 'context/events/Event';
import React, { useState } from 'react'
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import buttonText from 'texts/buttons/buttonText';
import breakpoints from 'theme/breakpoints';
import styled from 'styled-components';
import Button from 'components/_UI/Button';
import Text from 'components/_UI/Text';
import Loader from 'components/_UI/Loader';

export default function CustomFieldsDeleteModal({ visible, toast, onClose, onSuccess }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const { event } = useEvent();

    async function deleteCustomFields() {
        setLoading(true);
        setError(false);

        const payload = {
            event_id: event?.id,
            custom_fields: []
        }

        try {
            await customFieldsApi.update(payload);
            onSuccess();
            toast.success('Campos excluidos com sucesso!', {
                position: 'top-center'
            })
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal isOpen={visible} onClose={onClose}>

            <Text name='highlight'>
                Excluir campos personalizados
            </Text>

            <Text>Você tem certeza que deseja excluir todos seus campos personalizados?</Text>

            {
                loading ? (
                    <FullyCentered height='40px'>
                        <Loader />
                    </FullyCentered>
                ) : (
                    <ButtonsContainer>
                        <Button
                            variant={'light'}
                            category={'roundedBorded'}
                            onClick={onClose}
                        >
                            {buttonText.cancel}
                        </Button>

                        <Button
                            category={'roundedBorded'}
                            variant='lightBlue'
                            onClick={deleteCustomFields}
                        >
                            {buttonText.delete}
                        </Button>
                    </ButtonsContainer>
                )
            }

            {error && (
                <Text color={colors.danger} name='small'>
                    Ocorreu um erro inesperado, tente novamente!
                </Text>
            )}
        </Modal>
    )
}

const ButtonsContainer = styled(Column)`
    margin-top: 20px;

    @media screen and (min-width: ${breakpoints.md}) {
    flex-direction: row;
    
    button:nth-child(2) {
        margin-left: 10px;
    }
}
`;
