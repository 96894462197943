import React, { useState } from 'react'
import { ButtonSelectItem, SelectContainer, SelectWrapper } from './SelectsContent'
import Button from './Button'
import sizes from 'theme/sizes'

let itemsContainer = [];

export default function RadioSelectButton({ data, onChangeActive }) {
    const [selected, setSelected] = useState(null)

    function toggleState(item, index) {
        if (itemsContainer[itemsContainer.length - 1] === item.name) {
            itemsContainer = []
            setSelected(null)
            onChangeActive(false)
            return
        }
        
        itemsContainer.push(item.name)        
        
        setSelected(index)
        onChangeActive(item)
    }

    return (
        <SelectContainer>
            {
                data && data.map((el, index) => (
                    <SelectWrapper key={el.name}>
                        <ButtonSelectItem>
                            <Button
                                id={`select_${el.value}`}
                                variant={selected === index ? 'dark' : 'light'}
                                size={'default'}
                                marginRight={sizes.medium}
                                marginBottom={sizes.medium}
                                textTransform='capitalize'
                                onClick={() => toggleState(el, index)}
                            >
                                {el.name}
                            </Button>
                        </ButtonSelectItem>
                    </SelectWrapper>
                ))
            }
        </SelectContainer>
    )
}
