import Text from 'components/_UI/Text';
import React from 'react'
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center';
import colors from 'theme/colors';

export default function DynamicProfileBackground({
    img,
    size,
    variant = 'rounded',
    bgColor = colors.neutralDark,
    event = false
}) {
    return (
        
        <Picture size={size} bgColor={bgColor} variant={variant}>
            {
                img ? (
                    <ImageBackground
                        src={img}
                        alt='imagem de perfil'
                        size={size}
                        variant={variant}
                    />
                ) : (
                    <ImageBackground
                        src={event ? '/assets/images/placeholder-events.png' : '/assets/images/placeholder-background.png'}
                        alt='imagem de perfil'
                        size={size}
                        variant={variant}
                    />
                )
            }
        </Picture>
    )
}

const Picture = styled.div`
    width: 100%;
    
    ${({ bgColor }) => bgColor && `
        background: ${bgColor};
    `}

    ${({ size }) => size && `
        height: ${size};
    `}

    ${({ variant }) => variant === 'rounded' && `
        border-radius: 4px 4px 0 0;
    `}

    ${({ variant }) => variant === 'squired' && `
        border-radius: 0;
    `}
    
`;

const ImageBackground = styled.img`
    width: 100%;
    object-fit: cover;
    ${({ size }) => size && `
        height: ${size};
    `}

    ${({ variant }) => variant === 'rounded' && `
        border-radius: 4px 4px 0 0;
    `}

    ${({ variant }) => variant === 'squired' && `
        border-radius: 0;
    `}
`;

