import React from 'react';
import styled from 'styled-components';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import indexPriority from 'theme/z-index';
import Icon from './Icon';
import { Row } from 'styled/alignment/Row';
import Progress from './Progress';
import { useBackButtonCollapseDash } from 'context/dash/BackButtonCollapseDash';
import { useShowAllEvents } from 'context/dash/ShowAllEvents';
import { Edit, Trash2, X } from 'react-feather';
import { useDetailVisible } from 'context/dash/DetailVisible';

export default function DashCollapse({
    children,
    onClose,
    visible = false,
    widthOnDesktop = false,
    deleted = false,
    edit = false,
    clickedAction = () => false
}) {


    const { backButtonCollapseDash, setBackButtonCollapseDash } = useBackButtonCollapseDash()
    const { showAllEvents, setShowAllEvents } = useShowAllEvents()
    const { detailVisible } = useDetailVisible()
    return (
        <Container visible={visible} onClick={() => onClose()}>
            <CollapseContainer
                visible={visible}
                widthOnDesktop={widthOnDesktop}
                onClick={(e) => e.stopPropagation()}
            >
                <ContentButtons>

                    {backButtonCollapseDash?.visible &&
                        <BackButtonContainer>
                            <BackButton
                                id='collapse_back'
                                onClick={() => setShowAllEvents({ ...showAllEvents, visible: true })}
                            >
                                <Icon name='back' color={colors.night} />
                            </BackButton>
                        </BackButtonContainer>
                    }
                    {
                        deleted &&
                        <ContainerAction>
                            <ContentDeleteEvent onClick={() => clickedAction({ action: 'delete', ...detailVisible })}>
                                <Trash2 size={'21px'} color={colors.danger} />
                            </ContentDeleteEvent>
                        </ContainerAction>
                    }
                    {
                        edit &&
                        <ContainerAction>
                            <ContentDeleteEvent edit onClick={() => clickedAction({ action: 'edit', ...detailVisible, from: 'collapse' })}>
                                <Edit size='21px' color={colors.primaryLight} />
                            </ContentDeleteEvent>
                        </ContainerAction>
                    }
                    {
                        visible && (
                            <CloseButtonContainer>
                                <X size='25px' color={colors.night} onClick={() => onClose()} />
                            </CloseButtonContainer>
                        )
                    }
                </ContentButtons>
                {children}
            </CollapseContainer>
        </Container>
    )
};


const Container = styled.div`
    height: 100vh;
    width: 0;
    display: flex;
    align-items: flex-end;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1000;
    transition: all .35s ease-in-out;
    

    ${({ visible }) => visible && `
        width: 100%;
        z-index: 99999999999999999999999;

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            background: ${colors.nightDark};
            opacity: .85;
            z-index: -200;
        }
    `}
`;

const CollapseContainer = styled.div`
    position: relative;
    background: ${colors.neutralLight};
    border-radius:  ${sizes.none} ${sizes.small} ${sizes.small} ${sizes.none} ;
    box-shadow: ${sizes.none} ${sizes.none} ${sizes.tiny} ${colors.nightLight};
    z-index: ${indexPriority.high};
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 10px 20px 10px;
    height: 100%;
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .35s ease-in-out;
    
    ${({ visible }) => visible && `
        width: 100%;
        opacity: 1;
        visibility: visible;
        
        @media screen and (min-width: ${breakpoints.md}) {
            padding: 0 20px 50px 20px;
            width: 75%;
        }
    
        // @media screen and (min-width: ${breakpoints.lg}) {
        //     // width: 55%;
        // }
    `};

    ${({ widthOnDesktop }) => widthOnDesktop && `
        @media screen and (min-width: ${breakpoints.lg}) {
            width: ${widthOnDesktop} !important;
        }
    `};
`

const CloseButtonContainer = styled.div`
    width: 34px;
    position: sticky;
    top: 0;
    left: 0;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 0;
    background: ${colors.neutralLight};
    z-index: ${indexPriority.high};
    cursor: pointer;
`;

const CloseButton = styled.div`
    display: flex;
    /* position: absolute;
    top: 15px;
    right: calc(0.5% + 20px); */
    width: 34px;
    height: 34px;   

    padding: 6px;
    // background: red;
    transition: all ease .3s;
    z-index: ${indexPriority.xHigh};
    cursor: pointer;

    @media screen and (min-width: ${breakpoints.md}) {
        /* right: calc(25% + 20px); */
        width: 35px;
        height: 35px;   
    }
/*     
    @media screen and (min-width: ${breakpoints.lg}) {
        right: calc(50% + 20px);
    } */
`;

const ContentButtons = styled.div`
    padding-top: 10px;
    width: 100%;
    height: 40px;
    // background: green;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const BackButtonContainer = styled.div`
    margin-right: 15px;
    width: 30px;
    position: sticky;
    background: blue;
    top: 0;
    left: 0;
    justify-content: flex-end;
    padding: 10px 0;
    background: ${colors.neutralLight};
    z-index: ${indexPriority.high};
`;


const BackButton = styled.div`
    // position: absolute;
    right: 60px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: ${colors.neutralLight};
    border: 1px solid ${colors.nightLight};
    box-shadow: 1px 4x 4px ${colors.night};
    transition: all ease .3s;
    padding: 10px;
    z-index: ${indexPriority.xHigh};
    cursor: pointer;

    @media screen and (min-width: ${breakpoints.lg}) {
        top: 1% !important;
        left: 23% !important;;
    }

    @media screen and (min-width: 1400px) {
        // position: absolute;
        top: 1% !important;
        left: 23.5% !important;;
    }

    @media screen and (min-width: ${breakpoints.xl}) {
        // position: absolute;
        top: 1% !important;
        left: 23.8% !important;;
    }

    @media screen and (min-width: 1920px) {
        // position: absolute;
        top: 1% !important;
        left: 24.3% !important;;
    }
`;

const ContainerAction = styled.div`
    padding: 6px;
`

const ContentDeleteEvent = styled(Row)`
    width: 21px;
    align-items: center;
    cursor: pointer;
`

