import React, { useEffect, useState } from 'react';
import ProductsList from './list/ProductsList';
import NotResultDashBoard from 'components/commons/NotResultDashBoard';
import DashCollapse from 'components/_UI/DashCollapse';
import ProductForm from './create/ProductForm';
import { useProducts } from 'context/products/Products';
import productsApi from 'api/requests/products';
import Loader from 'components/_UI/Loader';
import routeParams from 'theme/routeParams';
import { useListProducts } from 'context/products/ListProducts';
import { useSearchParams } from 'react-router-dom';
import Modal from 'components/_UI/Modal';
import Button from 'components/_UI/Button';
import { Row } from 'styled/alignment/Row';
import Text from 'components/_UI/Text';
import styled from 'styled-components';
import { useContainerClick } from 'context/dash/ContainerClick';
import breakpoints from 'theme/breakpoints';
import { useUser } from 'context/user/User';
import paths from 'routes/paths';
import ToastContainer from 'components/_UI/ToastsContainer';
import useToast from 'hooks/useToast';

export default function Products({ setIsCreate, isCreate }) {
    const { products, setProducts } = useProducts();
    const {toast, toasts} = useToast()
    const { listProducts, setListProducts } = useListProducts()
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const showCreate = searchParams.get(routeParams.products.create);
    const { user } = useUser()

    const [lastPage, setLastPage] = useState(null)
    const [perPage, setPerpage] = useState(null)
    const [currentPage, setCurrentPage] = useState(null)
    const [totalItems, setTotalItems] = useState(null)
    const [firstPage, setFirstPage] = useState(null)
    const [imageReset, setImageReset] = useState(null)

    const [isDelete, setIsDelete] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    const [visibleDetail, setVisibleDetail] = useState(null)
    const [noEstablishmentModal, setNoEstablishmentModal] = useState(null)

    const [loadingButton, setLoadingButton] = useState(false)




    useEffect(() => {
        getProducts();

        if (showCreate) {
            setIsCreate(true)
        }

    }, []);

    const getProducts = async (number = 1) => {
        setLoading(true);
        try {
            const { data } = await productsApi.get({ page: number });
            setListProducts(data?.data)
            setLastPage(data?.meta?.last_page)
            setPerpage(data?.meta?.per_page)
            setCurrentPage(data?.meta?.current_page)
            setTotalItems(data?.meta?.total)
            setFirstPage(data?.meta?.first_page)

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const closeCollapse = () => {
        setIsCreate(false)
        setIsEdit(false)
        setImageReset(true)
        setSearchParams((prevSearchParams) => {
            const newSearchParams = new URLSearchParams(prevSearchParams);

            Object.values(routeParams.products).map(el => {
                newSearchParams.delete(el)
            })

            return newSearchParams;
        });

    }

    const activeActionClicked = (item) => {
        setIsCreate(false)

        if (item.action === 'edit') {
            setIsEdit(item)
            return
        }

        if (item.action === 'delete') {
            setIsDelete(item)
        }
    }

    const closeModal = () => {
        setIsDelete(false);
    };

    const deleteProduct = async (item) => {
        setLoadingButton(true)
        const id = item?.id
        try {
            const { data } = await productsApi.delete(id)
            setListProducts(listProducts?.filter((el) => el.id !== item?.id))
            toast.success('Produto excluído com sucesso!', {
                position: 'top-center'
            })
        } catch (error) {
            toast.error('Ocorreu um erro inesperado', {
                position: 'top-center'
            })
        } finally {
            setLoadingButton(false)
            closeModal()
        }
    }

    const createProduct = () => {
        const isEstablishment = user?.role?.id == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID
        if (isEstablishment && !user?.establishments.length) {
            setNoEstablishmentModal(true)
            return
        }
        setIsCreate(true)
    }

    function handleSucess(text) {
     toast.success(`Produto ${text} com sucesso!`,
        { position: 'top-center'})
    }


    return (
        <>
            <ToastContainer toasts={toasts} />
            {!loading ? (
                listProducts && listProducts?.length > 0 ? (
                    <ProductsList
                        data={listProducts}
                        lastPage={lastPage}
                        perPage={perPage}
                        currentPage={currentPage}
                        totalItems={totalItems}
                        onPaginate={getProducts}
                        onProductClick={e => setVisibleDetail(e)}
                        visibleDetail={visibleDetail}
                        setVisibleDetail={setVisibleDetail}
                        onDropdownAction={(e) => activeActionClicked(e)}

                    />
                ) : (
                    <NotResultDashBoard
                        text={
                            'Sem produtos cadastrados? Cadastre seu primeiro produto e maximize suas vendas durante a compra do ingresso.'
                        }
                        textButton={'Adicionar produto'}
                        onClick={() => createProduct()}
                        ilustration={'stock'}
                    />
                )
            ) : (
                <Loader />
            )}
            <DashCollapse visible={isCreate || isEdit} onClose={() => closeCollapse()}>
                <ProductForm 
                    onClose={() => closeCollapse()} 
                    imageReset={imageReset} 
                    isEdit={isEdit} 
                    visibleDetail={visibleDetail} 
                    onProductClick={visibleDetail} 
                    onSucess={handleSucess}  
                    onError={() => toast.error('Ocorreu um erro inesperado', {position: 'top-center'})}
                />
            </DashCollapse>


            {
                noEstablishmentModal &&
                <Modal isOpen={!!noEstablishmentModal} onClose={() => setNoEstablishmentModal(false)}>
                    <Text name='highlight'>
                        Você precisa ter um estabelecimento criado para cadastrar produto.
                    </Text>
                    <Button path={paths.dashboard.home}>
                        Criar estabelecimento
                    </Button>
                </Modal>
            }

            <Modal isOpen={isDelete} onClose={() => closeModal()}>
                <Text>Tem certeza que deseja excluir esse produto?</Text>

                {
                    !loadingButton ? (
                        <ContentConfirm>
                            <ContentButton>
                                <Button
                                    onClick={() => deleteProduct(isDelete)}
                                >
                                    Sim
                                </Button>
                            </ContentButton>

                            <ContentButton>
                                <Button
                                    variant='secondary'
                                    onClick={() => setIsDelete(false)}
                                >
                                    Cancelar
                                </Button>
                            </ContentButton>
                        </ContentConfirm>
                    ) : (
                        <Loader />
                    )
                }
            </Modal>
        </>
    );
}

const ContentConfirm = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: center;
    @media screen and (max-width: ${breakpoints.xm}) {
        margin-top: 10px;
        justify-content: space-around;
    }
    @media screen and (min-width: ${breakpoints.sm}) {
        margin-top: 0px;
        justify-content: center;
    }
`;

const ContentButton = styled(Row)`
    @media screen and (max-width: ${breakpoints.xm}) {
        width: 85px;
        padding: 0;
    }
    width: 120px;
    padding: 10px 10px 0 10px;
`