import Navbar from 'components/commons/Navbar'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import Profile from './profile/Profile'
import Text from 'components/_UI/Text'
import colors from 'theme/colors'
import Button from 'components/_UI/Button'
import Topbar from 'components/commons/Topbar'
import Modal from 'components/_UI/Modal'
import sizes from 'theme/sizes'
import PreferencesForm from './form/PreferencesForm '
import buttonText from 'texts/buttons/buttonText'
import FeedbackForm from './form/FeedbackForm'
import { Menu } from './profile/Menu'
import { DeleteAccountDialog } from './profile/DeleteAccountDialog'
import { UpdatePasswordDialog } from './profile/UpdatePasswordDialog'
import { PersonalInfoDialog } from './profile/PersonalInfoDialog'
import { Row } from 'styled/alignment/Row'
import { useCollapse } from 'context/general/Collapse'
import { CheckCircle } from 'react-feather'
import { useInvisibleButton } from 'context/general/InvisibleButton'

export default function Users({home, handleEditUser}) {
  const [openPreferencesForm, setOpenPreferencesForm] = useState(false)
  const [openFeedback, setOpenFeedbackForm] = useState(false)
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false)
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [openPersonalInfo, setOpenPersonalInfo] = useState(false)
  const [modalSucess, setModalSucess] = useState(false);
  const [modalSucessPassword, setModalSucessPassword] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {collapse, setCollapse} = useCollapse()
  const {setInvisibleButton} = useInvisibleButton()


  useEffect(() => {
    if(home) {
     const currentUrl = new URL(window.location.href);

      currentUrl.search = ''
      window.history.replaceState({}, '', currentUrl)

      setCollapse({
        ...collapse,
        arrowPosition: false
      })
    }
  }, [])
  
  function openModalFeedBack() {
    setOpenFeedbackForm(true)
    setInvisibleButton(true)
  } 

  function closeModal() {
    setInvisibleButton(false)
    setOpenFeedbackForm(false)
  }

  function openModalSucess() {
    setTimeout(() => {
      setInvisibleButton(true);
    }, 100);
    setModalSucess(true);
  }
  function closeModalSucess() {
    setModalSucess(false)
    setInvisibleButton(false)
  }
  
  return (
    <UsersContainer>

        {
          !home &&
          <Topbar
            openMenu={() => setIsMenuOpen(true)}
          />
        }
      
          <Menu
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            setOpenDeleteAccount={setOpenDeleteAccount}
            setOpenChangePassword={setOpenChangePassword}
            setOpenPersonalInfo={setOpenPersonalInfo}
          />

        <Profile handleEditUser={handleEditUser}/>
        
        <Column 
          fullyCentralized 
          marginTop={'24px'} 
          paddingLeft={'30px'}
          paddingRight={'30px'}
          >
          {/* <Button
            onClick={() => setOpenPreferencesForm(true)}
          > 
            Defina suas preferências
          </Button> */}
          <ContentButtonFeedback>
            <Button onClick={openModalFeedBack} variant={'secondary'}> O que você quer ver no Whizr? </Button>
          </ContentButtonFeedback>
        </Column>
        
        {
          !home &&
          <Navbar />
        }
        {/* {
          <Modal size='large' isOpen={openPreferencesForm} onClose={() => setOpenPreferencesForm(false)}>
            <PreferencesForm  
              setModalSucess={setModalSucess}
              closeForm={() => setOpenPreferencesForm(false)}
            />
          </Modal>
        } */}

        <DeleteAccountDialog
          handleEditUser={handleEditUser}
          open={openDeleteAccount}
          setOpen={setOpenDeleteAccount}
        />

        <UpdatePasswordDialog
          setModalSucessPassword={setModalSucessPassword}
          open={openChangePassword}
          setOpen={setOpenChangePassword}
        ></UpdatePasswordDialog>

        <PersonalInfoDialog 
          open={openPersonalInfo}
          setOpen={setOpenPersonalInfo}
        ></PersonalInfoDialog>

        <Modal size='small' isOpen={openFeedback} onClose={closeModal}>
          <FeedbackForm onClose={closeModal} openModalSucess={openModalSucess} />
        </Modal>

        <Modal isOpen={modalSucess} onClose={() => closeModalSucess()}>
          
          <Text name='default' type='bold'>{'Sugestões'} salvas com sucesso!</Text>
        
          <Column paddingRight='15%' paddingLeft='15%' marginTop={sizes.normal}>
            <Button onClick={() => closeModalSucess()}>
              {buttonText.understood} 
            </Button>
          </Column>
        </Modal>
     
      
        <Modal isOpen={modalSucessPassword} onClose={() => setModalSucessPassword(false)}>
          <ContentModalSucessPassword>
            <CheckCircle color={colors.successLight}  size={'40px'}/>
            <Text  color={colors.primaryDashboard} marginTop={'20px'}>
                Sua senha foi alterada com sucesso!
            </Text>
          </ContentModalSucessPassword>
        </Modal>

    </UsersContainer >
  )
}

const UsersContainer = styled(Column)`
    height: 100vh;
    background: ${colors.neutralDark};
    width: 100%;
`;


const ContentButtonFeedback = styled(Row)`
  width: 300px;
`;


const ContentModalSucessPassword = styled(Column)`
  align-items: center;
`;

