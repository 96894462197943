const time = {
    weekdays: [
        {id: 1, label: 'Domingo', name: 'Sunday'},
        {id: 2, label: 'Segunda', name: 'Monday'},
        {id: 3, label: 'Terça', name: 'Tuesday'},
        {id: 4, label: 'Quarta', name: 'Wednesday'},
        {id: 5, label: 'Quinta', name: 'Thursday'},
        {id: 6, label: 'Sexta', name: 'Friday'},
        {id: 7, label: 'Sábado', name: 'Saturday'},
    ],
    periodsDay: [
        {id: 1, label: 'Manhã', name: 'Morning'},
        {id: 2, label: 'Tarde', name: 'Afternoon'},
        {id: 3, label: 'Noite', name: 'Night', },
        {id: 4, label: 'Madrugada' , name: 'Dawn'},
    ]
}

export default time;