import Text from 'components/_UI/Text';
import currency from 'helpers/currency';
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function ProductDetail(data) {


    return (
        <StyledDetail>
            <Text type='bold' name='subtitle'>Detalhes do produto</Text>
            <Content>
                <ContentColumn image>
                    {
                        data?.data?.image ? (

                            <StyledBackground src={data?.data?.image} />
                        ) : (
                            <StyledBackground src={'../../assets/images/placeholder-products.png'} />
                        )
                    }

                </ContentColumn>

                <ContentColumn infos>
                    <Text color={colors.occasionalPurple} type='bold' name='higlight' ellipsisAt={'98%'}>
                        {data?.data.name}
                    </Text>
                    <Text marginTop={'4px'} >Quantidade sugerida: <b>{data?.data?.quantity}</b></Text>
                    <Text >Preço sugerido: <b>{currency.format(data?.data?.price)}</b></Text>
                </ContentColumn>
            </Content>

            {
                data?.data?.description &&
                <StyledDescription>
                    <Text type='bold' paddingBottom={'0px'} textAlign={'center'}>Descrição:</Text>
                    <Text marginTop={'8px'} paddingBottom={'0px'}>{data?.data?.description}</Text>
                </StyledDescription>
            }
        </StyledDetail>
    )
}

const StyledDetail = styled(Column)`
    width: 100%;
`;

const Content = styled(Column)`
    padding: 10px;
    margin-top: 30px;
    @media screen and (min-width: ${breakpoints.sm}) {
        flex-direction: row;
        margin-top: 0px;
    }
`;

const StyledBackground = styled.img`
    width: 130px;
    height: 130px;
    border-radius: 6px;
    object-fit: cover;
    
    @media screen and (min-width: ${breakpoints.md}) {
        width: 110px;
        height: 110px;
    }
`;

const ContentColumn = styled(Column)`
    align-items: flex-start;

    ${({ infos }) => infos && `
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        
        @media screen and (min-width: ${breakpoints.md}) {
            margin-top: 0;
            margin-left: 8px;
            align-items: flex-start;
            justify-content: flex-start;
       }
    `}
    ${({ image }) => image && `
        width: 100%;
        align-items: center;
        justify-content: center;
        
        @media screen and (min-width: ${breakpoints.md}) {
            width: auto;
            margin-right: 5px;
            align-items: flex-start;
            justify-content: flex-start;
       }
    `}
`

const StyledDescription = styled(Column)`
    width: 100%;
    display: flex;
    padding: 10px 10px 10px 10px;
    border-top: 1px solid #ddd;
    margin-top: 10px;

`;