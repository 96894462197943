import api from "api";

const address = {
    getByPostalCode: (payload) => {
        return api(false, true).get(`/addresses/${payload}`);
    },
    submitUserAddress: ({postalcode, number}) => {
        const path = number 
            ? `${postalcode}/${number}` 
            : `${postalcode}`  
    
        return api(false, true).get(`/addresses/${path}`);
    },
};

export default address;  
