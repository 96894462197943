import api from "api";

const participants = {
    list: (slug, page) => {
        return api().get(`/events/detail/${slug}/participants?page=${page}`)
    },
    
    download: (payload) => {
        return api().get(`/exports/participants?event_id=${payload}`, 
        {
            headers: {
                'Accept': 'text/csv',
                'Content-Type': 'text/csv'
            },
            responseType: 'blob'
        }
        )
    }
}

export default participants;