import EventCard from 'modules/events/card/EventCard';
import React from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import sizes from 'theme/sizes';
import { useCollapse } from 'context/general/Collapse';
import eventsApi from 'api/requests/events';
import EventDetail from 'modules/events/detail/EventDetail';
import { FullyCentered } from 'styled/alignment/Center';
import Text from 'components/_UI/Text';
import Loader from 'components/_UI/Loader';
import breakpoints from 'theme/breakpoints';
import { Row } from 'styled/alignment/Row';

export default function NearListAll({ data, total }) {
    const { collapse, setCollapse } = useCollapse();

    async function renderEventDetail(event) {
        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => (
                <FullyCentered height='100%'>
                    <Loader />
                </FullyCentered>
            )
        })
        try {
            const { data: res } = await eventsApi.getBySlug(event.slug);
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => event && <EventDetail withBackButton data={res.data} />
            })
        } catch (error) {
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => (
                    <FullyCentered height='100%'>
                        <Text name='highlight'> Ocorreu um erro inesperado. </Text>
                    </FullyCentered>
                )
            })
        }

    }

    return (
        <Container >
            <FullyCentered>
                <Text name='default' type='bold'>
                    {total} eventos perto de você
                </Text>
            </FullyCentered>

            <RowItem>
                {data.map((event) => (
                    <EventCard
                        key={event.slug}
                        data={event}
                        whiteBackground={true}
                        onEventClick={() => renderEventDetail(event)}
                        fullWidth
                        smallOnDesktop={true}
                    />
                ))}
            </RowItem>
        </Container>
    )
}

const Container = styled(Column)`
    &:nth-child(1) {
        padding: ${sizes.medium} ${sizes.tiny} ${sizes.tiny} ${sizes.tiny};
    }
    width: 100%;
    height: auto;
    padding: ${sizes.medium};

    ${({ large }) => large && `
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    `}
`;

const RowItem = styled(Column)`
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        padding: ${sizes.medium} 0;

        & > div {
            margin-left: 1%;
            margin-right: 1%;
            width: 31% !important;   
        }
    }
`;