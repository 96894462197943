import Text from 'components/_UI/Text';
import { translateDay } from 'helpers/translateDays';
import React from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';




export default function HoursList({data}) {
 
  return (
    <Column>
      <Text name='highlight' marginBottom={'20px'}>
        Horários de funcionamento
      </Text>

      <HoursContainer>
        {
          data &&
          Object.entries(data).map(([day, hours]) => {
            if (day === 'open') return null;
            
            return (
              <Item key={day}>
                <Text type='bold'>{translateDay(day)}</Text>

                {(!hours || hours.length === 0) && (
                  <Text color={colors.danger}>
                    Fechado
                  </Text>
                )}

                {hours && !hours.some(hour => hour['24h']) && hours.map((hour, idx) => (
                  <Text key={idx} color={colors.night}>
                    {`${hour.start} ${hour.end && `- ${hour.end}`}`}
                  </Text>
                ))}

                {hours && hours.some(hour => hour['24h']) && (
                  <Text color={colors.night} marginTop={'10px'}>
                    24 horas
                  </Text>
                )}
              </Item>
            );
          })
        }
      </HoursContainer>

    </Column>
  )
}

const HoursContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Item = styled(Column)`
  /* border-bottom-width: 1px;
  border-bottom-color: ${colors.nightLight}; */
  border: 1px solid ${colors.night};
  border-radius: 6px;
  width: 100%;
  padding: 6px;
  margin-top: 10px;
`;