import React, { createContext, useState, useContext } from 'react';

const MyEstablishmentContext = createContext();

export default function MyEstablishmentProvider({ children }) {
    const [myEstablishment, setMyEstablishment] = useState(null);

    return (
        <MyEstablishmentContext.Provider value={{ myEstablishment, setMyEstablishment }}>
            {children}
        </MyEstablishmentContext.Provider>
    );
}

export function useMyEstablishment() {
    const context = useContext(MyEstablishmentContext);

    if (!context) throw new Error('useMyEstablishment must be used within a MyEstablishmentProvider');

    const { myEstablishment, setMyEstablishment } = context;
    return { myEstablishment, setMyEstablishment };
}