import RegisterPartner from 'modules/onboarding/sign-up/partner/RegisterPartner'
import React from 'react'

function UserPartner() {
  return (
    <>
        <RegisterPartner />
    </>
  )
}

export default UserPartner