import React from 'react'
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import colors from 'theme/colors';
import Text from './Text';

export default function Radio({
    selected = true,
    label,
    textSize,
    style,
    marginTop,
    onSelect = () => false
}) {
    return (
        <Container 
            paddingBottom='10px' 
            marginTop={marginTop}
            onClick={() => onSelect()}
        >
            <StyledRadio selected={selected}>
                <StyledRadioSelect selected={selected} />
            </StyledRadio>

            <Text color={selected ? colors.primary : colors.night} >
                {label}
            </Text>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    cursor: pointer;

    ${({ marginTop }) => marginTop && `
        margin-top: ${marginTop} !important;
    `};
`;

const StyledRadio = styled(FullyCentered)`
    border: 1px solid ${colors.night};
    padding: 2px;
    border-radius: 30px;
    width: 16px;
    height: 16px;
    margin-right: 10px;

    ${({ selected }) => selected && `
        border: 1px solid ${colors.primary};
    `};
`;

const StyledRadioSelect = styled.div`
    display: none;
    background: ${colors.primary};
    width: 10px;
    height: 10px;
    border-radius: 30px;

    ${({ selected }) => selected && `
        display: flex;
    `};
`;
