import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import sizes from 'theme/sizes';

export default function Table({
    columns,
    rows,
}) {
    useEffect(() => {
    }, [rows, columns])

    return (
        <StyledTable>
            <TableHeader>
                {
                    columns?.map((item, index) => (
                        <TableHeaderItem key={index}> {item} </TableHeaderItem>
                    ))
                }
            </TableHeader>
            <TableBody>
                {
                    rows && rows?.map((el, index) => (
                        <TableBodyRow key={el.id}>
                            {
                                Object.values(el)
                                    .map((item, idx) => (
                                        <TableBodyItem key={idx}>
                                            <ContainerText color={typeof item === 'string' && (item === 'Ativa' || item === 'Desativada') ? item : null}>
                                                {typeof item === 'object' ? item : item}
                                            </ContainerText>
                                        </TableBodyItem>
                                    ))
                            }
                        </TableBodyRow>
                    ))
                }
            </TableBody>
        </StyledTable>
    )
}

const StyledTable = styled.div`
    border: 1px;
    border: 1px 0 0 1px solid ${colors.neutralDark};
    border-radius: 6px;
    overflow-y: auto;

    @media screen and (min-width: ${breakpoints.lg}){
        overflow-y: unset;
    }
`;

const TableHeader = styled(Row)`
    justify-content: space-around;
    padding: 16px 6px;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    text-transform: capitalize;
`;

const TableBody = styled(Column)``;

const TableHeaderItem = styled.div`
    text-align: left;
    width: 100%;
    padding: 0 6px ;
    min-width: 140px;
    max-height: auto;
    color: ${colors.night};
`;

const TableBodyRow = styled(Row)`
    align-items: center;
    width: 100%;
    justify-content: space-around;
    border-bottom: 1px solid #ccc;

    &:nth-child(odd) {
        background: #fcfcfc;
    }
`;

const TableBodyItem = styled(Row)`
    width: 100%;
    justify-content: space-between;
    padding: 12px;
    min-width: 140px;
    max-height: auto;
    color: ${colors.night};

    &:last-child{
        justify-content: flex-start;
    }
`;

const ContainerText = styled(Row)`
    @media screen and (max-width: 820px) {
        &:nth-child(1) {
            width: 80px;
            max-width: 120px;
        }
    }
    ${({ color }) => color === 'Ativa' && css`
        width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: ${colors.success};
    `};
    ${({ color }) => color === 'Desativada' && css`
        justify-content: center;
        width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        background: ${colors.danger};
    `};
`;
