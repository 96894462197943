import React from 'react';
import styled from 'styled-components';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

function Background() {
    return (
        <BackgroundContainer>
            <BackgroundImage src={'/assets/images/background.jpg'} />
        </BackgroundContainer>
    )
};

const BackgroundContainer = styled.div`
    display: none;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        display: flex;
        height: 100%;
        width: 100%;
        background: ${colors.occasionalBlue};
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }
`;

const BackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    opacity: .3;
    object-fit: cover;
    filter: blur(5px);
`;

export default Background;