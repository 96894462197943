import React, { useEffect, useState } from 'react';
import Main from 'modules/home/Main';
import DashHeader from 'modules/dashboard/_common/DashHeader';
import styled from 'styled-components';
import breakpoints from 'theme/breakpoints';
import { Row } from 'styled/alignment/Row';
import { useUser } from 'context/user/User';
import useAuth from 'hooks/useAuth';
import { FullyCentered } from 'styled/alignment/Center';
import indexPriority from 'theme/z-index';
import sizes from 'theme/sizes';
import UserLocaleProvider from 'context/user/UserLocale';
import HomeWrapper from './HomeWrapper';

export default function Home() {
    // const { user } = useUser();
    // const { isAuth } = useAuth();

    const [selectValidation, setSelectValidation] = useState(0);

    // useEffect(() => {
    // }, [user, isAuth]);
    
    function onSelectValidation() {        
        setSelectValidation(selectValidation + 1);
    }

    return (
        <HomeWrapper>
            <ContentHeader>
                <DashHeader onSelectValidation={onSelectValidation} />
            </ContentHeader>
            <Main selectValidation={selectValidation} setSelectValidation={setSelectValidation} />
        </HomeWrapper>
    );
}

const ContentHeader = styled(Row)`
    display: none;
    @media screen and (min-width: ${breakpoints.lg}) {
        display: flex;
    }
`;

const FullScreenLoading = styled(FullyCentered)`
    z-index: ${indexPriority.enormous};
    position: fixed;
    left: ${sizes.none};
    background: rgba(26, 26, 26, .9);
    width: 100%;
    height: 100vh;
    top: ${sizes.none};        
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: ${indexPriority.tremendous};
        background: rgba(0, 0, 0, .65);
    }
`;
