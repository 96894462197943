import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/_UI/Icon';


export default function PrivacityTerm() {
    const navigate = useNavigate();

    function goBack() {
        navigate(-1)
    }

    return (
        <Column
            paddingLeft='30px'
            paddingRight='30px'
            paddingTop='20px'
        >

            <StyledRow >
                <ContenButtonBack
                    onClick={goBack}
                >
                    <StyledButton>
                        <Icon name='back' />
                    </StyledButton>
                    <Text>Voltar</Text>
                </ContenButtonBack>
            </StyledRow>

            <Text name='title'>Política de Privacidade</Text>

            <Text>Esta Política de Privacidade descreve como o Whizr coleta, usa e protege as informações pessoais dos usuários que acessam ou usam nosso serviço. Ao usar nosso serviço, você concorda com a coleta e uso de informações de acordo com esta política.</Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                <b>1.</b> Informações Coletadas

            </Text>
            <Text
                name='highlight'
                marginTop='20px'
            >
                <b>1.1.</b> Informações Fornecidas Pelo Usuário:


            </Text>
            <Text paddingBottom='20px'>Durante o uso do serviço, podemos coletar as seguintes informações pessoais fornecidas pelo usuário:</Text>
            <ul>
                <li>
                    <Text>Informações de Registro: Quando você se registra para uma conta, podemos coletar seu nome, endereço de e-mail e outras informações de contato.</Text>
                </li>
                <li>
                    <Text>Informações de Perfil: Podemos coletar informações adicionais fornecidas por você em seu perfil, como sua, idade, sexo, interesses e preferências.</Text>
                </li>
                <li>
                    <Text>Informações de Pagamento: Se você optar por fazer compras ou transações dentro do serviço, coletamos as informações de pagamento necessárias e as enviamos diretamente ao gateway de pagamento para validação. Não armazenamos os detalhes do cartão de crédito. O gateway de pagamento retorna a transação como autorizada ou não. Se a transação não for finalizada, você precisará inserir novamente as informações do cartão.</Text>
                </li>

                <li>
                    <Text>Comunicações: Podemos coletar informações fornecidas por você em comunicações conosco, como e-mails, mensagens ou feedbacks enviados para nossa equipe de suporte.</Text>
                </li>
            </ul>

            <Text
                name='highlight'
                marginTop='20px'
            >
                <b>1.2.</b> Informações Coletadas Automaticamente:
            </Text>
            <Text>Além das informações fornecidas pelo usuário, também podemos coletar automaticamente certas informações quando você usa nosso serviço, incluindo:</Text>
            
            <ul>
                <li>
                    <Text>Dados de Localização: Podemos coletar dados de localização precisos ou aproximados do seu dispositivo móvel, se você nos permitir acesso a essa informação.</Text>
                </li>
                <li>
                    <Text>Dados de Uso: Podemos coletar informações sobre como você interage com nosso serviço, incluindo páginas visitadas, links clicados e outras atividades realizadas enquanto usa o serviço.</Text>
                </li>
                <li>
                    <Text>Dados do Dispositivo: Podemos coletar informações sobre o dispositivo que você está usando para acessar o serviço, incluindo o tipo de dispositivo, identificadores exclusivos do dispositivo e informações sobre o sistema operacional.</Text>
                </li>

                <li>
                    <Text>Cookies e Tecnologias Semelhantes: Podemos usar cookies, web beacons e outras tecnologias de rastreamento para coletar informações adicionais sobre o uso do serviço e melhorar sua experiência de usuário.</Text>
                </li>

            </ul>
            
            <Text
                name='highlight'
                marginTop='20px'
            >
                <b>2.</b>Uso das Informações
            </Text>

            <Text>Usamos as informações coletadas para os seguintes propósitos:</Text>

            <ul>
                <li>
                    <Text>Fornecer e manter nosso serviço;</Text>
                    
                </li>
                <li>
                    <Text>Personalizar e melhorar a experiência do usuário;</Text>
                </li>
                <li>
                    <Text>Processar transações e fornecer suporte ao cliente;</Text>
                </li>

                <li>
                    <Text>Enviar comunicações de marketing e promoções, se autorizado pelo usuário;</Text>
                </li>

                <li>
                    <Text>Cumprir com obrigações legais e regulamentares.</Text>
                </li>

            </ul>


            <Text
                name='highlight'
                marginTop='20px'
            >
                <b>3.</b>Compartilhamento de Informações
            </Text>

            <Text>Podemos compartilhar informações pessoais com terceiros nas seguintes circunstâncias:</Text>

            <ul>
                <li>
                    <Text>Com prestadores de serviços terceirizados que nos auxiliam na prestação do serviço;</Text>
                    
                </li>
                <li>
                    <Text>Com parceiros de negócios, anunciantes e afiliados para fins de marketing e promoções, se autorizado pelo usuário;</Text>
                </li>
                <li>
                    <Text>Em resposta a solicitações legais ou para proteger nossos direitos legais.</Text>
                </li>
            </ul>

            <Text
                name='highlight'
                marginTop='20px'
            >
                4. Proteção de Informações
            </Text>

            <Text>Tomamos medidas razoáveis para proteger as informações pessoais dos usuários contra acesso não autorizado, uso, alteração ou destruição. No entanto, nenhum método de transmissão pela internet ou armazenamento eletrônico é 100% seguro e não podemos garantir sua segurança absoluta.</Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                5. Seus Direitos de Privacidade
            </Text>


            <Text>Você tem certos direitos sobre suas informações pessoais, incluindo o direito de acessar, corrigir, atualizar ou excluir suas informações.</Text>


            <Text
                name='highlight'
                marginTop='20px'
            >
                6. Conformidade com a LGPD
            </Text>

            <Text>O Whizr está em conformidade com a Lei Geral de Proteção de Dados (LGPD) e compromete-se a proteger os direitos de privacidade dos usuários de acordo com as disposições dessa legislação. Isso inclui garantir a transparência sobre o uso e tratamento de informações pessoais, obter o consentimento dos usuários quando necessário, e implementar medidas de segurança adequadas para proteger os dados pessoais dos usuários contra acesso não autorizado ou uso indevido.</Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                7. Alterações nesta Política de Privacidade
            </Text>


            <Text marginBottom={'40px'}>Reservamos o direito de atualizar esta Política de Privacidade periodicamente. Notificaremos os usuários sobre alterações significativas enviando um aviso para o endereço de e-mail associado à sua conta ou publicando um aviso em destaque em nosso site.</Text>

        </Column>
    )
}

const StyledRow = styled(Row)`
    width: 100%;
    justify-content: center;
`;

const ContenButtonBack = styled.div`
    display: flex;
    flex-direction: row;
    width: 80px;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in-out .25s;
    & > p {
        padding-bottom: 0px;
        font-size: 16px;
        margin-left: 5px;
        color: ${colors.primaryDashboard}
    }

    &:hover {
        transform: scale(1.02)
    }

`;

const StyledButton = styled(Row)`
    width: 26px;
    padding: 5px;
    height: 26px;
    border-radius: 50%;
`;