import React, { useEffect, useState } from 'react';
import BoxCollapse from 'components/_UI/BoxCollapse';
import InputWithAutocomplete from 'components/_UI/InputWithAutocomplete';
import eventsApi from 'api/requests/events'
import EventsNearToYou from '../nearToYou/EventsNearToYou';
import Selects from './Selects';
import breakpoints from 'theme/breakpoints';
import { useCollapse } from 'context/general/Collapse';
import EventDetail from '../detail/EventDetail';
import Button from 'components/_UI/Button';
import { useEvent } from 'context/events/Event';
import { useEventFilter } from 'context/events/EventFilter';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import sizes from 'theme/sizes';
import colors from 'theme/colors';
import Text from 'components/_UI/Text';
import { Row } from 'styled/alignment/Row';
import Icon from 'components/_UI/Icon';
import { FullyCentered } from 'styled/alignment/Center';

const INITIAL_HEIGHT = 'min';
const END_HEIGHT = 'max';

const ARROW_UP = 'up';
const ARROW_DOWN = 'down';

let randomNumber = false

export default function BoxFilters({
    isRouteParam,
    onClose,
    filterClick,
    onNearToYouClick = false
}) {
    const { eventFilter, setEventFilter } = useEventFilter();

    useEffect(() => {
        setCollapse({
            ...collapse,
            // arrowPosition: false,
            height: INITIAL_HEIGHT
        })

        if (isRouteParam) {
            upCollapse()
        }

        if (filterClick) {
            upCollapse()
        }
    }, [isRouteParam, filterClick])

    const { collapse, setCollapse } = useCollapse();
    const { event } = useEvent();

    const [searchValue, setSearchValue] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [syncPropertys, setSyncPropertys] = useState(true);
    const [searchClickable, setSearchClickable] = useState(null);
    const [searchTrigger, setSearchTrigger] = useState(0);
    const [toggleBackData, setToggleBackData] = useState(false)
    const [searchInputValue, setSearchInputValue] = useState('')
    const [refreshInput, setRefreshInput] = useState(false)

    function upCollapse() {
        setCollapse({
            ...collapse,
            height: END_HEIGHT,
            arrowPosition: 'both',
        })
        setSearchClickable(true)
    };

    function downCollapse() {
        setCollapse({
            ...collapse,
            height: INITIAL_HEIGHT,
            arrowPosition: true,
        })
    };

    function toggleCollapse() {
        if (toggleBackData) {
            setCollapse({
                ...collapse,
                height: INITIAL_HEIGHT,
                arrowPosition: true,
                withContent: false
            })
            return
        }

        collapse.arrowPosition === true ? upCollapse() : downCollapse();
    };

    function showEventSelected(event) {
        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => event && <EventDetail data={event} />
        })
    }

    function onBack(data) {
        setSearchInputValue('')
        setRefreshInput(Math.floor(Math.random() * 20000))
        setSearchClickable(true)
        setToggleBackData(true)
        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: 'both',
            componentToRender: () => data && boxCollapseContent(data)
        })
    }

    function onCloseCollapse() {
        setSearchTrigger(false)
        setToggleBackData(false)
        setSearchInputValue('')
        setRefreshInput(Math.floor(Math.random() * 20000))
        setEventFilter(false)
        randomNumber = false
        onClose()
    }

    function renderSelects(backData) {


        if (collapse.height === END_HEIGHT) {
            if (backData) {
                return <Selects
                    backData={backData}
                    searchTrigger={randomNumber}
                />
            }

            return <Selects
                searchTrigger={searchTrigger}
                isRouteParam={isRouteParam}
            />
        }

        return (
            <Column>
                <EventsNearToYou
                    searchEvents={() => upCollapse()}
                    wrap={collapse.height === END_HEIGHT}
                    onEventClick={(event) => {
                        showEventSelected(event)
                        onNearToYouClick(event)
                    }}
                />
            </Column>
        )
    }


    function boxCollapseContent(backData) {
        return (
            <CollapseContainer>
                <RowInputs>
                    <InputWithAutocomplete
                        placeholder='Pesquisar'
                        id='filter_search_input'
                        // data={searchValue}
                        layout='roundedDark'
                        inputValue={searchInputValue}
                        badgeIcon='search'
                        badgeIconColor={colors.neutral}
                        isBadgeClickable={true}
                        badgeIconBackground='#4F86F7'
                        trigger={refreshInput}
                        onBadgeClick={() => {
                            setRefreshInput(Math.floor(Math.random() * 20000))
                            setSearchInputValue('')
                            setSearchTrigger(searchTrigger + 1)

                            if (backData) {
                                randomNumber = Math.floor(Math.random() * 20000)
                            }
                        }}
                        onChange={(e) => {
                            setSearchInputValue(e)
                            setEventFilter({ ...eventFilter, term: e })
                            // setSearchValue(true)
                        }}
                        onFocus={() => !backData && upCollapse()}
                    // syncProp={syncPropertys}
                    // debounce
                    // onSelectItem={(e) => onSelectAutocompleteItem(e)}
                    />

                    <FilterButton onClick={() => upCollapse()}>
                        <Column width='18px' height='18px'>
                            <Icon name='filter' color={colors.night} />
                        </Column>
                        <Text>Mostrar filtros</Text>
                    </FilterButton>
                </RowInputs>

                {
                    collapse.height === END_HEIGHT &&
                    <IconWrapper>
                        <IconContentClickable onClick={() => onClose()} width='100px'>
                            <IconContainer>
                                <Icon name='close' color={colors.night} />
                            </IconContainer>
                            <Text color={colors.night} paddingBottom={0} type='bold'>Fechar filtros</Text>
                        </IconContentClickable>
                    </IconWrapper>
                }

                {renderSelects(backData)}
            </CollapseContainer>
        )
    }

    return (
        <BoxCollapse
            height={collapse.height}
            arrowPosition={collapse.arrowPosition}
            upCollapse={() => upCollapse()}
            downCollapse={() => downCollapse()}
            onToggle={() => toggleCollapse()}
            onClose={() => onCloseCollapse()}
            onBack={(e) => onBack(e)}
        >
            {!collapse.withContent ? boxCollapseContent() : collapse.componentToRender()}
        </BoxCollapse>
    )
};


const CollapseContainer = styled(Column)`
    padding: ${sizes.none} ${sizes.medium};
    overflow-x: unset;
    
    @media screen and (min-width: ${breakpoints.lg}){
        margin-top: 25px;
    }
`;

const IconContainer = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
`;

const IconWrapper = styled(FullyCentered)`
    display: none;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        display: flex;
    }
`

const IconContentClickable = styled(FullyCentered)`
    flex-direction: row;
    width: 200px;
    height: 100%;
    border-radius: 30px;
    border: 1px solid ${colors.night};
    padding: 8px;
    margin-top: 10px;
    transition: all ease-in-out .25s;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
    }
`;

const RowInputs = styled(Row)`
    display: none;
    /* margin-top: 14px; */
    & > div:first-child > div > input{
        background: ${colors.neutralLight};
        height: 38px;
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        display: flex;
    }
`;

const FilterButton = styled(FullyCentered)`
    background: ${colors.neutralLight};
    display: none;
    flex-direction: row;
    border-radius: 30px;
    margin-left: 10px;
    width: 290px;
    height: 38px;
    box-shadow: 0 2px 2px #ccc;
    border: 1px solid ${colors.neutralDark};
    cursor: pointer;
    transition: all ease-in-out .25s;

    &:hover {
        opacity: .8;
        transform: scale(1.05);
    }

    & > p {
        padding-bottom: 0;
        margin-left: 10px;
        font-weight: bold;
        color: ${colors.night};
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        display: flex;
    }
`;

const ContentNavbar = styled(Row)`
    margin-top: 50px;
    @media screen and (min-width: ${breakpoints.lg}) {
        display: none;
    }
`;