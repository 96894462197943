import React, { useEffect, useState } from 'react'
import Button from 'components/_UI/Button'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import paths from 'routes/paths';
import { useListProducts } from 'context/products/ListProducts';
import { PlusSquare } from 'react-feather';
import Text from 'components/_UI/Text';
import colors from 'theme/colors';


export default function RightSlotProducts({ setIsCreate }) {
    const { listProducts } = useListProducts()
    return (
        <RightSlotContainer>
            {
                listProducts && listProducts?.length > 0 &&
                <ButtonContainer>
                    <Button
                        marginBottom='0'
                        category='squiredBorded'
                        onClick={() => setIsCreate(true)}
                    >
                        <PlusSquare color={colors.neutral} size={18} />
                        <Text color={colors.neutral} marginLeft={'6px'} paddingBottom={0}>
                            Adicionar produto
                        </Text>
                    </Button>
                </ButtonContainer>
            }
        </RightSlotContainer>
    )
}

const RightSlotContainer = styled.div`
    width: 100%;
    height: 100%;

    & > button {
        margin-bottom: 0 !important;
    }

    @media screen and (min-width: ${breakpoints.md}){
        width: auto;
    }

`;

const ButtonContainer = styled.div`
    width: 100%;
    & > button {
            height: 35px;
            padding: 0;
    }
    @media screen and (min-width: ${breakpoints.md}){
        width: 165px;
        height: 100%;
        /* margin-right: 25px; */
        & > button {
            height: 35px;
            padding: 0;
        }
    }
    @media screen and (min-width: ${breakpoints.lg}){
        & > button {
            height: 35px;
        }
        /* margin-right: 11px; */
    }
    @media screen and (min-width: ${breakpoints.xl}){
        & > button {
            height: 35px;
        }
        margin-right: 0;
    }

    
`;