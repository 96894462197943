import collaboratorsApi from 'api/requests/collaborators'
import Button from 'components/_UI/Button'
import Fieldset from 'components/_UI/Fieldset'
import Loader from 'components/_UI/Loader'
import Modal from 'components/_UI/Modal'
import Switch from 'components/_UI/Switch'
import Text from 'components/_UI/Text'
import { useEvent } from 'context/events/Event'
import fieldValidations from 'helpers/fieldValidations'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { FullyCentered, HorizontalCenter } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import breakpoints from 'theme/breakpoints'
import colors from 'theme/colors'
import fonts from 'theme/fonts'


export default function PermissionsModal({ visible, edit, onClose, onSuccess }) {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const permissions = {
        ticket: 'ticket',
        product: 'product',
        both: 'both',
    }

    const { event } = useEvent();

    const [loading, setLoading] = useState(false);
    const [ticketSelected, setTicketSelected] = useState(false);
    const [productSelected, setProductSelected] = useState(false);
    const [permissionsSelected, setPermissionsSelected] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        if (ticketSelected && productSelected) {
            setPermissionsSelected(permissions.both);
            return
        }

        if (ticketSelected) {
            setPermissionsSelected(permissions.ticket);
            return
        }

        if (productSelected) {
            setPermissionsSelected(permissions.product);
            return
        }

        setPermissionsSelected('');
    }, [ticketSelected, productSelected]);

    useEffect(() => {
        if (edit) {
            setValue('email', edit?.email);
            setPermissionsSelected(edit?.permission_type)

            if (edit?.permission_type === permissions.ticket) {
                setTicketSelected(true)
                setProductSelected(false)
                return
            }

            if (edit?.permission_type === permissions.product) {
                setTicketSelected(false)
                setProductSelected(true)
                return
            }

            if (edit?.permission_type === permissions.both) {
                setTicketSelected(true)
                setProductSelected(true)
            }
        }
    }, [edit, setValue]);

    async function onSubmit({ email }) {
        const payload = {
            guest_email: email,
            permission_type: permissionsSelected,
            event_id: event?.id
        }

        setErrorMessage(false);
        setLoading(true);

        try {
            await collaboratorsApi.invite(payload);
            onSuccess();
        } catch ({ response }) {
            setErrorMessage(response?.data?.message)
        } finally {
            setLoading(false);
        }
    }

    async function editPermission({ email }) {
        const payload = {
            guest_email: email,
            permission_type: permissionsSelected,
            event_id: event?.id
        }

        setLoading(true);

        try {
            await collaboratorsApi.editPermission(edit?.id, payload);
            onSuccess();
        } catch (error) {
            console.log('error')
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal isOpen={visible} closeButton={true} onClose={onClose} size='medium'>
            <Container onSubmit={handleSubmit(!edit ? onSubmit : editPermission)}>
                <Text name='highlight'>
                    Permissões do colaborador
                </Text>

                <Column marginTop={'20px'}>
                    <Text color={colors.night} >
                        O usuário deve possuir uma conta Whizr registrada com o e-mail informado.
                    </Text>

                    <Fieldset
                        marginTop='10px'
                        label='E-mail do colaborador'
                        register={register}
                        name='email'
                        disabled={edit}
                        validations={fieldValidations.email()}
                    />

                    {
                        !errorMessage && errors.email && (
                            <HorizontalCenter>
                                <Text color={colors.danger}>
                                    {errors.email?.message}
                                </Text>
                            </HorizontalCenter>
                        )
                    }

                    {
                        errorMessage && !errors.email &&
                        (
                            <HorizontalCenter >
                                <Text color={colors.danger}>
                                    {errorMessage}
                                </Text>
                            </HorizontalCenter>
                        )
                    }
                </Column>

                <Column marginTop={'30px'}>
                    <Text color={colors.night} type='bold'>
                        Tipo de acesso
                    </Text>

                    <SwitchContainer>
                        <Switch
                            label='Validação de ingresso'
                            marginTop='12px'
                            checked={ticketSelected}
                            onChange={(e) => setTicketSelected(e)}
                        />
                        <Switch
                            label='Validação de produto'
                            marginTop='12px'
                            checked={productSelected}
                            onChange={(e) => setProductSelected(e)}
                        />
                    </SwitchContainer>
                </Column>

                {
                    loading ? (
                        <FullyCentered height='60px'>
                            <Loader />
                        </FullyCentered>
                    ) : (
                        <ButtonsContainer>
                            <Button
                                variant={'light'}
                                category='roundedBorded'
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>

                            <Button
                                variant={!permissionsSelected ? 'disabled' : 'lightBlue'}
                                category='roundedBorded'
                                type={'submit'}
                            >
                                {!edit ? 'Enviar convite' : 'Atualizar permissões'}
                            </Button>
                        </ButtonsContainer>
                    )
                }
            </Container>
        </Modal >
    )
}


const Container = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
`;

const ButtonsContainer = styled(Column)`
    margin-top: 20px;

    @media screen and (min-width: ${breakpoints.md}) {
        margin-top: 40px;
        flex-direction: row;
        
        button:first-child {
            margin-right: 16px;
        }
    }
`;

const SwitchContainer = styled(Column)`
    & > div > label {
        font-size: ${fonts.sizes.default} !important;
    }
`;