import Table from 'components/_UI/Table'
import Text from 'components/_UI/Text'
import React from 'react'
import { Column } from 'styled/alignment/Column'
import styled from 'styled-components'
import colors from 'theme/colors'

export default function DeclinedTransactions() {
    return (
        <Container>
            <Text name='highlight' >Transações recusadas</Text>

            <Table
                rows={[
                    {id: '#1d3ea1', event: 'Baile do gordão Patrick', date: '12/06/2024', hour: '15:30', value: 'R$ 134,45'},
                    {id: '#1d3ea2', event: 'Baile do gordão Patrick', date: '12/06/2024', hour: '15:30', value: 'R$ 134,45'},
                    {id: '#1d3ea3', event: 'Evento bonito', date: '12/06/2024', hour: '15:30', value: 'R$ 134,45'},
                ]}
                columns={['Código', 'Evento', 'Data', 'Hora', 'Valor']}
            />
        </Container>
    )
}

const Container = styled(Column)`
    background-color: ${colors.neutralLight};
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 2px 7px #ddd;
`;