import React, { useState, useEffect} from 'react';
import { Column } from 'styled/alignment/Column';
import { useForm } from 'react-hook-form';
import colors from 'theme/colors';
import fieldValidations from "helpers/fieldValidations";
import styled from 'styled-components';
import breakpoints from 'theme/breakpoints';
import Loader from 'components/_UI/Loader';
import Button from 'components/_UI/Button';
import Text from 'components/_UI/Text';
import Fieldset from 'components/_UI/Fieldset';
import masks from 'helpers/masks';
import unformat from 'helpers/unformat';
import { useCheckout } from 'context/checkout/Checkout';
import date from 'helpers/date';
import ReCAPTCHA from "react-google-recaptcha";
import { Row } from 'styled/alignment/Row';
import debounce from 'helpers/debounce';


export default function CreditcardForm({ onTrigger, errorRequest, setErrorRequest}) {
    const [loading, setLoading] = useState(false);
    const { checkout, setCheckout } = useCheckout();
    const [isRecaptcha, setIsRecaptcha] = useState(false)
    const [tokenRecaptcha, setTokenRecaptcha] = useState(null)


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if(errorRequest >= 3) {
          setIsRecaptcha(true)  
        }
      }, [errorRequest])

    function handlePayment(data) {
        window.trigger = Math.random(Infinity);

        const payload = {
            document: unformat.getNumbers(data?.document),
            creditcardNumber: unformat.getNumbers(data?.creditcardNumber),
            date: data?.date,
            holder: data?.holder,
            code: data?.code,
            grecaptcha_response: tokenRecaptcha
        }
        
        onTrigger(payload);
    }


    return (
        <Column>
            <RowInDesktop>
                <Column>
                    <Fieldset
                        marginTop='20px'
                        label='Nome escrito no cartão *'
                        // placeholder='Nome do titular *'
                        layout='squired'
                        name="holder"
                        register={register}
                        validations={fieldValidations.name('Nome escrito no cartão')}
                    />

                    {errors.holder?.message && (
                        <Column>
                            <Text color={colors.danger} name='small'> {errors.holder?.message} </Text>
                        </Column>
                    )}
                </Column>

                <Column>
                    <Fieldset
                        marginTop='6px'
                        label='CPF *'
                        layout='squired'
                        name="document"
                        register={register}
                        mask={masks.cpf}
                        validations={fieldValidations.cpf()}
                    />

                    {errors.document?.message && (
                        <Column>
                            <Text color={colors.danger} name='small'> {errors.document?.message} </Text>
                        </Column>
                    )}
                </Column>
            </RowInDesktop>

            <RowWithMultiples>
                <Column>
                    <Fieldset
                        marginTop='6px'
                        label='Número do cartão *'
                        layout='squired'
                        name="creditcardNumber"
                        register={register}
                        mask={masks.creditCard}
                        validations={fieldValidations.creditCard('Número do cartão')}
                    />

                    {errors.creditcardNumber?.message && (
                        <Column>
                            <Text color={colors.danger} name='small'> {errors.creditcardNumber?.message} </Text>
                        </Column>
                    )}
                </Column>

                <SpecialRowInDesktop between marginTop='6px'>
                    <Column width='64%'>
                        <Fieldset
                            marginTop='6px'
                            label='Data de expiração *'
                            name="date"
                            register={register}
                            validations={fieldValidations.shortDate('Data de expiração')}
                            layout='squired'
                            mask={masks.dateExpiredCardCredit}
                        />

                        {errors.date?.message && (
                            <Column>
                                <Text color={colors.danger} name='small'> {errors.date?.message} </Text>
                            </Column>
                        )}
                    </Column>

                    <Column width='34%'>
                        <Fieldset
                            marginTop='6px'
                            label='CVV *'
                            name="code"
                            register={register}
                            validations={fieldValidations.cvv()}
                            layout='squired'
                        />

                        {errors.code?.message && (
                            <Column>
                                <Text color={colors.danger} name='small'> {errors.code?.message} </ Text>
                            </Column>
                        )}
                    </Column>
                </SpecialRowInDesktop>

            </RowWithMultiples>

            {isRecaptcha && (
                <Row marginTop={'15px'} horizontalCenter marginBottom={'15px'}>
                    <ReCAPTCHA
                        sitekey={import.meta.env.VITE_APP_SITE_KEY}
                        onChange={(e) => setTokenRecaptcha(e)}
                    />
                </Row>
            )}

            {
                loading
                    ? <Loader />
                    : (
                        <Button
                            marginTop={'10px'}
                            marginBottom={'30px'}
                            variant={errorRequest >= 3 && !tokenRecaptcha ? 'disabled' : 'featured'}
                            onClick={handleSubmit(handlePayment)}
                        >
                            Efetuar pagamento
                        </Button>
                    )
            }
        </Column>
    );
}

const RowInDesktop = styled(Column)`
   @media screen and (min-width: ${breakpoints.md}) {
    flex-direction: row;

    div:nth-child(2) {
        margin-top: 14px;
        margin-left: 10px;

        & > div:nth-child(2) {
            margin-top: 2px;
        }
    }
    div:nth-child(1) > div:nth-child(2) {
        margin-top: 2px;
    }
    
   }
`;

const RowWithMultiples = styled(Column)`
   @media screen and (min-width: ${breakpoints.md}) {
    flex-direction: row;

    & div:nth-child(2) {
        margin-left: 10px;
        margin-top: 0;
    }
   }
`;

const SpecialRowInDesktop = styled(Column)`
    ${({between}) => between && `
        flex-direction: row;
        justify-content: space-between;
    `};

   @media screen and (min-width: ${breakpoints.md}) {
    flex-direction: row;
    
    & div:nth-child(2) {
        margin-top: 0;
        margin-left: 10px;
    }
   }
`;
