import api from "api";

const products = {
    get: (params) => {
        return api().get('/products-template', {
            params: {...params}
        });
    },
    create: (payload) => {
        return api().post('/products-template', payload)
    },
    delete: (id) => {
        return api().delete(`/products-template/${id}`)
    },
    update: (id, payload) => {
        return api().post(`/products-template/${id}`, payload)
    },
}

export default products;