import styled, { keyframes } from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import colors from 'theme/colors';

const spin = keyframes`
    from {
        transform: rotate(0);
    }
    to{
        transform: rotate(359deg);
    }
`;

const StyledLoaderBox = styled(FullyCentered)`
    width: 100%;
    height: auto;
    background-color: transparent;
    margin: ${({ margin }) => margin};
`;

StyledLoaderBox.defaultProps = {
    margin: '10px 0'
}

const StyledSpinner = styled.div`
    width: 26px;
    height: 26px;
    border: 3px solid ${colors.primary};
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: ${spin} .5s linear 0s infinite;

    ${({ color }) => color && `
        border: 3px solid ${color} !important;
        border-top: 3px solid transparent !important;
    `};

    ${({ size }) => size === 'small' && `
        width: 16px;
        height: 16px;
    `};
    
    ${({ size }) => size === 'mini' && `
        width: 10px !important;
        height: 10px !important;
    `};

    ${({ size }) => size === 'medium' && `
        width: 60px;
        height: 60px;
    `};
`

export {
    StyledLoaderBox,
    StyledSpinner,
}