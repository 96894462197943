import { useState, useEffect } from "react"
import styled from "styled-components"
import { Info, MapPin } from 'react-feather'
// import participantsApi from "api/requests/participants"
import collaboratorsApi from "api/requests/collaborators"
import Table from "components/_UI/Table"
import Text from "components/_UI/Text"
import colors from "theme/colors"
import { Column } from "styled/alignment/Column"
import { Row } from "styled/alignment/Row"
import Icon from "components/_UI/Icon"
import EventCard from "modules/dashboard/profile/items/card/EventCard"
import { FullyCentered } from "styled/alignment/Center"
import Loader from "components/_UI/Loader"
import InfoTooltip from "components/_UI/InfoTooltip"
import fonts from "theme/fonts"

export default function EventsList({ onEventClick = () => false, onBackClick = () => false }) {
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(false)
    const [lastPage, setLastPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [totalItems, setTotalItems] = useState(null);
    const [detail, setDetail] = useState()
    const [formatDateOrder, setFormatDateOrder] = useState('')

    const permissionTypeMap = {
        ticket: 'Validar ingressos',
        product: 'Validar produtos',
        both: 'Validar ingressos, Validar produtos'
    }

    useEffect(() => {
        getCollaborators()
    }, [])

    async function getCollaborators() {
        setLoading(true);

        try {
            const { data } = await collaboratorsApi.get()
            setEvents(data?.data)
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }


    return (
        loading ? (
            <FullyCentered height='200px' >
                <Loader />
            </FullyCentered>
        ) : (
            <>
                <Text type='bold' marginBottom={'20px'}>
                    Selecione o evento para ver a lista de participantes
                </Text>
                {
                    events.map(event => {
                        return (
                            <EventCard
                                key={event?.slug}
                                data={event}
                                onClick={() => onEventClick(event)}
                            />
                        )
                    })
                }
            </>
        )
    )
}


const CardContainer = styled(Column)`
    border-radius: 12px;
    box-shadow: 0px 1px 4px ${colors.night};
    cursor: pointer;
`;

const ContainerBody = styled(Column)`
    width: 100%;
    display: flex;
    padding: 10px 10px;
`;

const ContentBody = styled(Row)`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;

    & > p {
        padding-bottom: 0;
        font-size: 14px;
    }
`;

const StyledBackButton = styled(Column)`
    width: 27px;
    height: 27px;
    padding: 6px;
    cursor: pointer;

    ${({ desktop }) => desktop && `
        display: flex;
        cursor: pointer;
        `}

    ${({ mobile }) => mobile && `
        @media screen and (min-width: ${breakpoints.lg}) {
            display: none;
        }   
        `}
    `;

const StyledStatus = styled(Row)`
padding: 2px;
border-radius: 4px;
color: ${colors.neutralLight};
font-size: 12px;
justify-content: center;
width: auto;
padding: 3px 5px;
font-size: ${fonts.sizes.small};

${({ status }) => status == false && `
    background: ${colors.nightLight};
    border-radius: 6px 30px 30px 6px;
`}

${({ status }) => status == true && `
    background: ${colors.success};
    border-radius: 6px 30px 30px 6px;
`}

${({ status }) => status === 'ticket' && `
    background: orange;
`}

${({ status }) => status === 'product' && `
    background: ${colors.occasionalPurple};
`}
`;

const ContentAction = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all ease-in-out 0.25s;

    & > div > svg {
        &:hover {
            transform: scale(1.1);
       }
    }
`

const ContentNotResult = styled.div`
    & > div {
        flex-direction: column;
    }

    & > div > picture {
        width: 45%;
    }

    & > div > div {
        width: 100%;
    }
`;