import BackButtonCollapseDashProvider from 'context/dash/BackButtonCollapseDash'
import ShowAllEventsProvider from 'context/dash/ShowAllEvents'
import ListProductsProvider from 'context/products/ListProducts'
import ProductsProvider from 'context/products/Products'
// import UserProvider from 'context/user/User'
import DashContainer from 'modules/dashboard/_common/DashContainer'
import React from 'react'
import Financial from 'modules/dashboard/financial/Financial'
import RightSlotFinancial from 'modules/dashboard/financial/right-slot/RightSlotFinancial'
import WithdrawProvider from 'context/financial/Withdraw'
import DetailVisibleProvider from 'context/dash/DetailVisible'

export default function DashFinancial() {

  function rightSlotContent() {
    return (
      <RightSlotFinancial />
    )
  }

  return (
    // <UserProvider>
      <DetailVisibleProvider>
        <ListProductsProvider>
          <ProductsProvider>
            <ShowAllEventsProvider>
              <BackButtonCollapseDashProvider>
                <WithdrawProvider>

                  <DashContainer
                    title={'Financeiro'}
                    rightSlot={() => rightSlotContent()}
                  >
                    <Financial />
                  </DashContainer>

                </WithdrawProvider>
              </BackButtonCollapseDashProvider>
            </ShowAllEventsProvider>
          </ProductsProvider>
        </ListProductsProvider>
      </DetailVisibleProvider>
    // </UserProvider>
  )
}
