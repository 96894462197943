import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import colors from "theme/colors";
import { FullyCentered, HorizontalCenter } from "styled/alignment/Center";
import Button from "components/_UI/Button";
import Text from 'components/_UI/Text';
import sizes from 'theme/sizes';
import Fieldset from 'components/_UI/Fieldset';
import { Column } from 'styled/alignment/Column';
import { useForm } from 'react-hook-form';
import userApi from 'api/requests/user';
import fieldValidations from 'helpers/fieldValidations';
import Modal from 'components/_UI/Modal';
import Loader from 'components/_UI/Loader';
import { useOnSelectMenu } from 'context/general/OnSelectMenu';

export function UpdatePasswordDialog ({ open, setOpen, setModalSucessPassword }) {

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const { onSelectMenu, setOnSelectMenu } = useOnSelectMenu();


    function closeModalChangePassword() {
        setOpen(false)
        setOnSelectMenu(false)
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
      } = useForm()

    const changePassword = async({currentPassword, newPassword, confirmNewPassword}) => {
        
        if(newPassword !== confirmNewPassword) {
            setError('O campo nova senha e confirmação de senha devem ser iguais')
            return
        }
        setLoading(true)
        setError(false);


        try {
            await userApi.changePassword(currentPassword, newPassword)
           
            closeModalChangePassword()
            setModalSucessPassword(true)
               
            
        } catch ({response}) {
            
            if (response?.status == 400) {
                setError('Senha atual inválida');
                return;
            }

            setError('Ocorreu um erro, tente novamente')
            
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container onSubmit={handleSubmit(changePassword)}>
            <Modal isOpen={open} onClose={closeModalChangePassword}>
                <ModalWrapper>

                    <Text
                        name='title'
                        marginBottom={sizes.medium}
                    >
                        Para começar, informe sua nova senha.
                    </Text>

                    <Fieldset
                        marginBottom={sizes.medium}
                        placeholder='Senha atual'
                        name='currentPassword'
                        badgeIconPassword
                        badgeIconPositionPassword={'right'}
                        badgeIconColorPassword={colors.night}
                        inputType="password" 
                        register={register}
                        validations={fieldValidations.passwordWithoutPatterns('senha atual')}
                    />
                    {errors && (
                        <HorizontalCenter marginTop={'10px'}>
                            <Text color={colors.danger}>{errors.currentPassword?.message}</Text>
                        </HorizontalCenter>
                    )}

                    <Fieldset
                        marginBottom={sizes.medium}
                        placeholder='Nova senha'
                        name='newPassword'
                        inputType={'password'}
                        badgeIconPassword
                        badgeIconColorPassword={colors.night}
                        badgeIconPositionPassword={'right'}
                        register={register}
                        validations={fieldValidations.password('nova senha')}
                    />
                    {errors && (
                        <HorizontalCenter marginTop={'10px'}>
                            <Text color={colors.danger}>{errors.newPassword?.message}</Text>
                        </HorizontalCenter>
                    )}

                    <Fieldset
                        marginBottom={sizes.medium}
                        placeholder='Confirme sua nova senha'
                        name='confirmNewPassword'
                        register={register}
                        badgeIconPassword
                        badgeIconColorPassword={colors.night}
                        badgeIconPositionPassword={'right'}
                        inputType={'password'}
                        validations={fieldValidations.password('confirmação de senha')}
                    />
                     {errors && (
                        <HorizontalCenter marginTop={'10px'}>
                            <Text color={colors.danger}>{errors.confirmNewPassword?.message}</Text>
                        </HorizontalCenter>
                    )}
                    {error && (
                        <FullyCentered marginTop={'10px'}>
                            <Text color={colors.danger}>{error}</Text>
                        </FullyCentered>
                    )}

                    {
                        !loading ? (
                            <Column>
                                <Button
                                    type='submit'
                                >
                                    Confirmar
                                </Button>

                                <Button
                                    variant='secondary'
                                    onClick={closeModalChangePassword}
                                >
                                    Cancelar
                                </Button>
                            </Column>

                        ) : (
                            <Loader />
                        )
                    }
                </ModalWrapper>

              

            </Modal>
        </Container>
    )
}

const Container = styled.form`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${colors.neutralDark};
`;


const ModalWrapper = styled.div`
    padding: 10px
`;


