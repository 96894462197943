import React, { useEffect, useState } from 'react';
import Text from 'components/_UI/Text';
import styled, { css } from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import { FullyCentered } from 'styled/alignment/Center';
import Loader from 'components/_UI/Loader';
import Button from 'components/_UI/Button';
import { useCollapse } from 'context/general/Collapse';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';
import colors from 'theme/colors';
import CardResume from '../card/CardResume';
import { useEstablishmentsNearToYou } from 'context/establishments/EstablishmentsNearToYou';
import { useEstablishmentsNearListAll } from 'context/establishments/EstablishmentsNearListAll';
import establishmentsApi from 'api/requests/establishments';
import NearListAll from './NearListAll';
import { useUserLocale } from 'context/user/UserLocale';
import { useGlobalLoading } from 'context/general/GlobalLoading';
import EventCardSkeleton from 'components/commons/skeletons/EventCardSkeleton';

let total = null

export default function EstablishmentsNearToYou({
    wrap = false,
    onCardClick,
    searchEvents,
    onEventClick,
}) {
    const { establishmentsNearToYou, setEstablishmentsNearToYou } = useEstablishmentsNearToYou();
    const { establishmentsNearListAll, setEstablishmentsNearListAll } = useEstablishmentsNearListAll();
    const { collapse, setCollapse } = useCollapse();
    const { backButtonCollapse, setBackButtonCollapse } = useBackButtonCollapse();
    const { userLocale } = useUserLocale();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getEstablishmentsNearToYou()
    }, [userLocale])

    async function getEstablishmentsNearToYou() {
        if (!userLocale) {
            setEstablishmentsNearToYou([])
            // setLoading(false);
            return
        }

        let payload = {
            lat: Number(import.meta.env.VITE_BASE_LAT),
            long: Number(import.meta.env.VITE_BASE_LONG)
        }

        payload = {
            lat: Number(userLocale.lat),
            long: Number(userLocale.long)
        }

        if (establishmentsNearToYou?.length) {
            setLoading(false);
            return
        }

        try {
            const { data: res } = await establishmentsApi.near(payload)
            setEstablishmentsNearToYou(res?.data)
            total = res?.meta?.total
            setLoading(false);
        } catch (error) {
            // console.log(error)
        }
    }

    async function showAllNears() {
        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => (
                <FullyCentered height='100%'>
                    <Loader />
                </FullyCentered>
            )
        })
        const payload = {
            lat: Number(userLocale.lat),
            long: Number(userLocale.long)
        }

        if (establishmentsNearListAll) {
            renderEstablishmentsNearListAll(establishmentsNearListAll)
            return
        }

        try {
            const { data: res } = await establishmentsApi.allNears(payload)

            setEstablishmentsNearListAll(res?.data)
            renderEstablishmentsNearListAll(res?.data)
        } catch (error) {
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => (
                    <FullyCentered height='100%'>
                        <Text name='highlight'> Ocorreu um erro inesperado. </Text>
                    </FullyCentered>
                )
            })
        }
    }

    function renderEstablishmentsNearListAll(data) {
        setBackButtonCollapse({
            ...backButtonCollapse,
            data: data,
            totalEventsNears: total,
            from: 'near-list'
        })
        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => <NearListAll onEventClick={onEventClick} total={total} data={data} />
        })
    }

    return (
        <NearToYouContainer>
            {
                !establishmentsNearToYou || loading
                    ? (
                        <SkeletonContainer>
                            {
                                [1, 2, 3, 4, 5, 6].map((_, index) => (
                                    <EventCardSkeleton key={index} />
                                ))
                            }
                        </SkeletonContainer>
                    )
                    : (
                        establishmentsNearToYou?.length === 0
                            ? (
                                <ContainerNotestablishmentsNearToYou>
                                    <ContentNotestablishmentsNearToYou >
                                        <Column verticalCenter >
                                            <Text
                                                name='default'
                                                type='bold'
                                                text-align='center'
                                            >
                                                Não encontramos nenhum estabelecimento próximo a você
                                            </Text>
                                            <ContainerButtonSearch>
                                                <Button id={'not-found-events-button'} onClick={() => searchEvents()}> Procurar estabelecimentos</Button>
                                            </ContainerButtonSearch>
                                        </Column>
                                    </ContentNotestablishmentsNearToYou>
                                </ContainerNotestablishmentsNearToYou>
                            ) : (
                                <>
                                    <FullyCentered>
                                        <Text name='small' type='bold'>
                                            {
                                                establishmentsNearToYou.length === 1
                                                    ? '1 estabelecimento perto de você'
                                                    : `${total} estabelecimentos perto de você`
                                            }

                                        </Text>
                                    </FullyCentered>
                                    <CardsContainer wrapContent={wrap}>
                                        {
                                            establishmentsNearToYou.map((establishment) => {
                                                return (
                                                    <CardResume
                                                        whiteBackground={true}
                                                        key={establishment.slug}
                                                        data={establishment}
                                                        onCardClick={() => onCardClick(establishment)}
                                                    />
                                                )
                                            })
                                        }
                                        {total > 5 && (
                                            <ContainerAllNears onClick={() => showAllNears()}>
                                                <CardAllNears>
                                                    <Text type='bold' paddingBottom={0}>Ver todos</Text>
                                                </CardAllNears>
                                            </ContainerAllNears>
                                        )}
                                    </CardsContainer>
                                </>
                            )
                    )

            }
        </NearToYouContainer>
    )
};

const NearToYouContainer = styled(Column)`
    margin-top: 16px;
    @media screen and (max-width: ${breakpoints.xl}) {
        display: flex;
        justify-content: center;
    }
`;

const CardsContainer = styled(Row)`
    width: 100%;
    overflow-y: auto;
    overflow-x: unset;
    
    ${({ wrapContent }) => wrapContent && css`
        overflow-y: unset;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        height: auto;
        
    `}

    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: row;
        margin-top: 16px;
        overflow-y: hidden;
        padding: 0 4px;
        flex-wrap: wrap;
        & > div {
            width: 31%;
            margin-left: 1.5%;
        }
    }
`;

const ContainerNotestablishmentsNearToYou = styled(Column)`
    width: 100%;
    @media screen and (min-width: ${breakpoints.xm}) and (max-width: ${breakpoints.lg}) {
        display: flex;
        align-items: center;
        display: flex;
        height: 180px;
        justify-content: center;
    }
`;

const ContentNotestablishmentsNearToYou = styled(FullyCentered)`
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
`;
const ContainerButtonSearch = styled(FullyCentered)`
    width: 260px;
`;

const CardAllNears = styled(FullyCentered)`
    width: 155px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ContainerAllNears = styled.div`
   cursor: pointer;
   margin: 12px 2px 0 2px;
   width: 155px;
   height: 85px;
   display: flex;
   border-radius: 4px;
   justify-content: center;
   background: ${colors.neutralDark};
   align-items: center;
   &:hover {
        opacity: .8;
        transform: scale(1.01);
    }
       
    @media screen and (min-width: ${breakpoints.lg}) {
        background: ${colors.neutralLight};
        height: auto;
        box-shadow: 2px 2px 4px #ccc;  
        margin: 8px 0px;
        border-radius: 8px;
    }
`;

const SkeletonContainer = styled(Row)`
    margin-top: 50px;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        flex-wrap: wrap;
        margin-top: 30px;

        & > div {
            width: 31.5%;
            margin-left: 1.5%;
            margin-top: 20px;
            & > div {
                width: 100%;
            }
        }
    }
`;