import React, { createContext, useState, useContext } from 'react';

const BatchesContext = createContext();

export default function BatchesProvider({ children }) {
    const [batches, setBatches] = useState([]);

    return (
        <BatchesContext.Provider value={{ batches, setBatches }}>
            {children}
        </BatchesContext.Provider>
    );
}

export function useBatches() {
    const context = useContext(BatchesContext);

    if (!context) throw new Error('useBatches must be used within a BatchesProvider');

    const { batches, setBatches } = context;
    return { batches, setBatches };
}