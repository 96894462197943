import React, { createContext, useState, useContext } from 'react';

const ShowAllEventsContext = createContext();

export default function ShowAllEventsProvider({ children }) {
    const [showAllEvents, setShowAllEvents] = useState({
        visible: false,
        type: null,
        title: null,
        data: null
    });

    return (
        <ShowAllEventsContext.Provider value={{ showAllEvents, setShowAllEvents }}>
            {children}
        </ShowAllEventsContext.Provider>
    );
}

export function useShowAllEvents() {
    const context = useContext(ShowAllEventsContext);

    if (!context) throw new Error('useShowAllEvents must be used within a ShowAllEventsProvider');

    const { showAllEvents, setShowAllEvents} = context;
    return { showAllEvents, setShowAllEvents };
}