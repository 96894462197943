import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import currency from 'helpers/currency';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row'
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function VerticalProductCard({
    data,
    onDropdownAction,
    dropDownVisible,
    onMoreClick,
    onProductClick = () => false,
    visibleMoreAction = false,
    isVisiblePrice = false
}) {
    const [price, setPrice] = useState(null)
    const [toggleDropdown, setToggleDropdown] = useState(null);
    const [status, setStatus] = useState(false)

    function renderActions() {
        setToggleDropdown(!toggleDropdown)
        onMoreClick()
    }

    useEffect(() => {

        if (data) {
            data?.batches?.forEach((el) => {
                if (el?.quantity_available > 0 && el?.is_active == 1) {
                    setStatus('disponível');
                }
                if (el?.quantity_available == 0) {
                    setStatus('esgotado');
                }
            })
            setPrice(parseFloat(data?.price))
        }
    }, [data])

    return (
        <Card
            status={status}
            onClick={(e) => onProductClick(e)}>

            <Picture>
                {
                    data?.image ? (
                        <StyledImage src={data?.image} />
                    ) : (
                        <StyledImage src={'../../assets/images/placeholder-products.png'} />
                    )
                }
            </Picture>

            <ContentInfo>
                <TextStatus>{status}</TextStatus>
                <Text
                    ellipsisAt={'98%'}
                    // type='bold'
                    marginTop={'6px'}
                >
                    {data.name}
                </Text>

                {/* <TextPrice status={status}>{currency?.format(parseFloat(data?.price))}</TextPrice> */}
                <Row style={{alignItems: 'center'}} marginTop='8px'>
                    <Text paddingBottom={0} name='mini' type='bold'>R$</Text>
                    <Text paddingBottom={0} name='highlight' marginLeft={'2px'}>{parseFloat(data?.price)}</Text>
                </Row>


            </ContentInfo>

            {
                data.popular && (
                    <Popular>Mais vendido</Popular>
                )
            }
        </Card>
    )
}

const Card = styled(Column)`
   
   position: relative;
   box-shadow: 0 2px 7px #ddd;  
   /* height: 130px; */
   /* max-width: 100%; */
   cursor: pointer;
   transition: all ease-in-out .25s;
   background: ${colors.neutralLight};
   border-radius: 6px;

    &:hover {
        opacity: .85;
        transform: scale(1.025);
    }
    ${({ status }) => status == 'disponível' && `
        background: ${colors.neutralLight};
    `}
    ${({ status }) => status == 'esgotado' && `
        opacity: 0.6;
    `}
`;

const Picture = styled.picture`
    width: 100%;
    height: 100px;
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
`;

const ContentInfo = styled(Column)`
    width: 100%;
    padding: 10px;
`;


const Popular = styled.div`
    position: absolute;
    right: 0;
    top: -14px;
    padding: 6px 10px;
    background-color: ${colors.gold};
    color: ${colors.neutral};
    border-radius: 30px;
    font-size: 10px;
`;


// const TextPrice = styled.p`
//     padding-bottom: 0px;
//     margin-top: 5px;
//     font-weight: bold;
//     color: ${colors.primaryDashboard};
//     ${({ status }) => status === 'esgotado' && `
//         text-decoration: line-through;
//         color: ${colors.primaryDashboard};
//     `}
//     ${({ status }) => status === 'disponível' && `
//         text-decoration: normal;
//         ${colors.primaryDashboard};
//     `}
// `;

const TextStatus = styled.p`
    font-size: 10px;
    text-transform: capitalize;
    color: ${colors.primaryDashboard};
`;
