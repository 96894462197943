import ticketsApi from 'api/requests/tickets';
import Loader from 'components/_UI/Loader';
import Progress from 'components/_UI/Progress';
import Text from 'components/_UI/Text';
import currency from 'helpers/currency';
import ProductCard from 'modules/dashboard/products/list/ProductCard';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import fonts from 'theme/fonts';

export default function ProductDetail({ data }) {
    const [products, setProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [productTotals, setProductTotal] = useState({});
    const [productPercentages, setProductPercentages] = useState({});
    const [amountTotal, setAmountTotal] = useState(0);
    const [soldAmountProduct, setSoldAmountProduct] = useState(0);

    useEffect(() => {

        getProducts(data)
    }, [])

    async function getProducts(slug) {
        setIsLoading(true)
        try {
            const { data } = await ticketsApi.getTicketForEvent(slug);
            setSoldAmountProduct(data?.products_sold)
            setAmountTotal(data?.products_total)
            setProducts(data?.products)

            let totals = {};
            let percentages = {};

            data?.products?.forEach(product => {
                let totalCollected = 0;
                let totalEstimated = 0;
                let soldQuantity = 0;
                let totalQuantity = 0;

                product?.batches?.forEach(batch => {
                    soldQuantity += (batch.quantity - batch.quantity_available);
                    totalQuantity += batch.quantity;
                    totalCollected += (batch.quantity - batch.quantity_available) * batch.price;
                    totalEstimated += batch.quantity * batch.price;
                });

                totals[product.id] = {
                    collected: totalCollected,
                    estimated: totalEstimated
                };

                percentages[product.id] = (soldQuantity / totalQuantity) * 100;
            });

            setProductTotal(totals)
            setProductPercentages(percentages)

        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }
    return (
        <>
            {
                isLoading ? (
                    <ContentLoader>
                        <Loader />
                    </ContentLoader>
                ) : (
                    <Column>
                        <Text name='subtitle' color={colors.night}>Informações de Vendas</Text>
                        <Text color={colors.night}>Acompanhe suas vendas e veja quantos produtos já foram vendidos e quantos ainda estão disponíveis para alcançar sua meta.</Text>

                        <StyledData>
                            <ContentData>
                                <StyledTotals>
                                    <StyledProgressSale>
                                        <Text color={colors.secondaryPurple} name='hero' paddingBottom={'0'}>{soldAmountProduct}/</Text>
                                        <Text color={colors.secondaryPurple} name='title' paddingBottom={'0'}>{amountTotal}</Text>
                                    </StyledProgressSale>
                                    <Text type='bold' color={colors.night}>Produtos</Text>
                                    <Text type='bold' color={colors.secondaryPurple}>Vendidos</Text>
                                </StyledTotals>

                                <StyledTotals>
                                    <ContentTicketToSell>
                                        <Text color={colors.secondaryPurple} name='hero' paddingBottom={'0'}>{amountTotal - soldAmountProduct}/</Text>
                                        <Text color={colors.secondaryPurple} name='title' paddingBottom={'0'}>{amountTotal}</Text>
                                    </ContentTicketToSell>
                                    <Text type='bold' color={colors.night}>Produtos a</Text>
                                    <Text type='bold' color={colors.secondaryPurple}>Vender</Text>
                                </StyledTotals>
                            </ContentData>
                            <Text color={colors.night} marginTop={'20px'}><b>Estatísticas de vendas:</b> Confira a porcentagem de produtos vendidos para cada categoria e acompanhe seu desempenho.</Text>
                        </StyledData>

                        <ContainerProgressData>
                            {products?.map((el) => (
                                <ContentProgressData key={el.id}>
                                    <Text type='bold' color={colors.secondaryPurple}>{el?.name}</Text>
                                    <Row>
                                        <Progress value={productPercentages[el.id]} />
                                        <Text type='bold' color={colors.secondaryPurple} marginLeft={'10px'}>
                                            {Math.round(productPercentages[el.id])}%
                                        </Text>
                                    </Row>
                                </ContentProgressData>
                            ))}
                        </ContainerProgressData>
                        <ContainerProducts>


                            <Text name='subtitle' color={colors.night} marginTop={'40px'}>Produtos</Text>

                            <StyledTitles>
                                <ContentTitles products>
                                    <Text>Produtos</Text>
                                </ContentTitles>
                                <ContentTitles>
                                    <Text>Valor arrecadado</Text>
                                </ContentTitles>
                                <ContentTitles>
                                    <Text>Valor estimado</Text>
                                </ContentTitles>
                            </StyledTitles>

                            {
                                products?.map((el) => (
                                    <ProductsList key={el.id}>
                                        <Row>
                                            <ContentProducts>
                                                <ProductCard data={el} dropDownVisible={false} isVisiblePrice />
                                            </ContentProducts>
                                        </Row>

                                        <ContentValues>

                                            <ProductsValues>
                                                <StyledValues collected>
                                                    <TextTitlesValues>
                                                        <Text color={colors.night}>Valor arrecadado</Text>
                                                    </TextTitlesValues>
                                                    <Text color={colors.night}>{currency.format(productTotals[el.id]?.collected || 0)}</Text>
                                                </StyledValues>
                                            </ProductsValues>

                                            <ProductsValues>
                                                <StyledValues>
                                                    <TextTitlesValues>
                                                        <Text color={colors.night}>Valor estimado</Text>
                                                    </TextTitlesValues>
                                                    <Text color={colors.night}>{currency.format(productTotals[el.id]?.estimated || 0)}</Text>
                                                </StyledValues>
                                            </ProductsValues>
                                        </ContentValues>

                                    </ProductsList>
                                ))
                            }
                        </ContainerProducts>

                    </Column>
                )
            }
        </>
    )
}

const StyledData = styled(Column)`
    margin-top: 30px;
    width: 100%;

    & > div > div > div > h1 {
        display: flex;
        align-items: center;
    }
`;

const ContentData = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
`;


const StyledTotals = styled(Column)`
    width: 100%;

    @media screen and (min-width: ${breakpoints.sm}) {
        width: 50%;
    }
`

const StyledProgressSale = styled.div`
    display: flex;
    flex-direction: row;
`;

const ContentTicketToSell = styled.div`
    display: flex;
    flex-direction: row;
`;


const ContainerProgressData = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    @media screen and (min-width: ${breakpoints.sm}) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
`;

const ContainerProducts = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
`;


const ContentProgressData = styled.div`
    width: 100%;
    margin-top: 12px;

    &:first-child {
        margin-top: 0;
    }
    @media screen and (min-width: ${breakpoints.sm}) {
        width: 220px;
        margin-top: 0;
        margin-right: 20px;
    }
`;

const ProductsList = styled(Column)`
    border-bottom: 2px solid ${colors.nightLight};
    margin-top: 25px;
    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: row;
        border-bottom: unset;
    }
`;

const ContentProducts = styled(Row)`
    width: 100%;
    padding-right: 20px;

    & > div {
        @media screen and (min-width: ${breakpoints.xm}) and (max-width: 375px) {
            width: 100%;
        }

        @media screen and (min-width: ${breakpoints.sm}) {
            width: 60%;
            padding-right: 20px;
            
        }
        @media screen and (min-width: ${breakpoints.lg}) {
            width: 100%;
        }
        @media screen and (min-width: ${breakpoints.xl}) {
            padding-right: 20px;
        }
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        border-right: 1px solid ${colors.nightLight};
    }
    


`;


const ContentValues = styled(Row)`
    justify-content: center;
`;

const ProductsValues = styled(Row)`
    margin-top: 20px;
    justify-content: space-around;
    padding-bottom: 20px;
    @media screen and (min-width: ${breakpoints.lg}) {
        padding-bottom: unset;
        margin: unset;
    }
    width: 40%;
`;


const StyledValues = styled(Column)`
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    @media screen and (min-width: ${breakpoints.lg}) {
        align-items: flex-start;
        padding-left: 30px;
    }
    ${({ collected }) => collected && `
        border-right: 1px solid ${colors.nightLight};
        @media screen and (min-width: ${breakpoints.md}) {
            padding-left: 0px;
            // background: red;
            border-right: 1px solid ${colors.nightLight};
        }
    `}
    @media screen and (min-width: ${breakpoints.lg}) {
        margin: unset;
    }
`;

const TextTitlesValues = styled.div`
    display: flex;
    @media screen and (min-width: ${breakpoints.lg}) {
        display: none;
    }
`;

const StyledTitles = styled(Row)`
    display: none;
    width: 100%;
    @media screen and (min-width: ${breakpoints.lg}) {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
    }
`;


const ContentTitles = styled(Row)`
    justify-content: flex-start;
   
    & > p {
        font-size: ${fonts.sizes.highlight};
        color: ${colors.night}
    }

    @media screen and (min-width: ${breakpoints.md}) {
        width: 25%;
    }

    ${({ products }) => products && `
        justify-content: flex-start;
        margin-left: 10px;

        @media screen and (min-width: ${breakpoints.md}) {
            width: 50%;
            
        }
    `}

`;

const ContentLoader = styled(Column)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;