import Fieldset from 'components/_UI/Fieldset'
import InfoTooltip from 'components/_UI/InfoTooltip'
import Switch from 'components/_UI/Switch'
import Text from 'components/_UI/Text'
import currency from 'helpers/currency'
import fieldValidations from 'helpers/fieldValidations'
import React, { useState } from 'react'
import { Plus, Trash2 } from 'react-feather'
import styled from 'styled-components'
import { ContentSwitch } from 'styled/UI/StyledSwitch'
import CurrencyInputField from 'components/_UI/CurrencyInputField';
import { FullyCentered } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import breakpoints from 'theme/breakpoints'
import colors from 'theme/colors'
import { v4 as uuidv4 } from 'uuid';

export default function FormBatches({
    batches,
    setTicket,
    ticketIndex,
    setDeletedBatches = () => false
}) {
    const [priceError, setPriceError] = useState('')
    const [quantityError, setQuantityError] = useState('')

    function calculateFee(value) {
        return value > 25 ? value * 0.1 : 2.5
    }

    function calculateNetIncome(value) {
        const fee = calculateFee(value)
        return (value - fee) > 0
            ? currency.format(value - fee)
            : currency.format(0)
    }

    const addBatch = () => {
        setTicket(tickets => {
            const newTickets = JSON.parse(JSON.stringify(tickets))
            newTickets[ticketIndex].batches.push({
                internal_id: uuidv4(),
                price: '',
                floatPrice: 0,
                quantity: '',
                description: '',
                is_active: false
            })

            return newTickets
        })
    };

    const updateBatch = (key, value, index) => {
        const batch = {
            ...batches[index],
            [key]: value
        }
        setTicket(tickets => {
            const newTickets = JSON.parse(JSON.stringify(tickets))
            newTickets[ticketIndex].batches[index] = batch

            return newTickets
        })
    };

    const removeBatch = (index) => {
        setTicket(tickets => {
            const newTickets = JSON.parse(JSON.stringify(tickets))
            const removed = newTickets[ticketIndex].batches.splice(index, 1)

            if (removed[0]?.id) {
                setDeletedBatches(removedBatches => {
                    return [
                        ...removedBatches,
                        removed[0].id
                    ]
                })
            }

            return newTickets
        })
    };

    const handlePriceChange = (value, name, values, index) => {
        const batch = {
            ...batches[index],
            price: value,
            floatPrice: values.float
        }

        setTicket(tickets => {
            const newTickets = [...tickets]
            newTickets[ticketIndex].batches[index] = batch

            return newTickets
        })

        if (!value) {
            setPriceError('O preço do ingresso é obrigatório')

            return
        }

        if (values.float < 5) {
            setPriceError('O preço deve ser maior ou igual a 5')

            return
        }

        setPriceError('')
    };

    const handleQuantityChange = (value, index) => {
        const parsedValue = !value ? '' : value.replace(/\D/g, '');
        updateBatch('quantity', parsedValue, index);

        if (!parsedValue) {
            setQuantityError('A quantidade é obrigatória')

            return
        }

        if (Number(parsedValue) < 0) {
            setQuantityError('A quantidade deve ser acima de 0')

            return
        }

        setQuantityError('')
    };

    return (
        <Container>
            <Row>
                {batches?.map((batch, index) => (
                    <BatchItem marginTop={'10px'} key={batch.internal_id}>
                        <BatchHeader>
                            <Text type='bold' color={colors.secondaryPurple} paddingBottom={0}>
                                Lote {index + 2} {/* Alterado para começar do lote 2 */}
                            </Text>
                            <RemoveButton onClick={() => removeBatch(index)}>
                                <Trash2 size={20} color={colors.dangerDark} />
                            </RemoveButton>
                        </BatchHeader>

                        <Row>
                            <Column>
                                <CurrencyInputField
                                    placeholder='Valor'
                                    value={batch.price}
                                    syncProp={true}
                                    onChange={(value, name, values) => handlePriceChange(value, name, values, index)}
                                />

                                {priceError && (
                                    <Column>
                                        <Text color={colors.danger} name='small'> {priceError} </Text>
                                    </Column>
                                )}
                            </Column>

                            <Column marginLeft='10px'>
                                <Fieldset
                                    placeholder={'Quantidade'}
                                    value={batch.quantity}
                                    syncProp={true}
                                    onChangeValue={e => handleQuantityChange(e, index)} // Garante que apenas números inteiros sejam inseridos
                                />

                                {quantityError && (
                                    <Column>
                                        <Text color={colors.danger} name='small'> {quantityError} </Text>
                                    </Column>
                                )}
                            </Column>
                        </Row>

                        <Fieldset
                            placeholder={'Descrição'}
                            value={batch.description}
                            onChangeValue={e => updateBatch('description', e, index)}
                            validations={fieldValidations.description('Descrição')}
                        />

                        <Row marginTop='10px'>
                            <ContentSwitch>
                                <Switch
                                    checked={batch.is_active}
                                    onChange={e => updateBatch('is_active', e, index)}
                                />
                            </ContentSwitch>
                            <Row>
                                <Text marginLeft={'6px'}>
                                    Lote ativo
                                </Text>
                                <ContentToltip>
                                    <InfoTooltip
                                        bgColor={colors.occasionalPurple}
                                        text="Se ativo, os ingressos deste lote ficaram disponiveis para compra."
                                    />
                                </ContentToltip>
                            </Row>
                        </Row>

                        <Column marginTop={'10px'}>
                            <Row style={{ justifyContent: 'space-between' }}>
                                <Text name='small'>Preço do ingresso:</Text>
                                <Text type='bold' name='small' color={colors.success}>
                                    {currency.format(batch.floatPrice)}
                                </Text>
                            </Row>
                            <Row style={{ justifyContent: 'space-between' }}>
                                <Text name='small'>Taxa de serviço:</Text>
                                <Text type='bold' name='small' color={colors.success}>
                                    {currency.format(calculateFee(batch.floatPrice))}
                                </Text>
                            </Row>

                            <Row style={{ justifyContent: 'space-between' }}>
                                <Text name='small'>Você irá receber:</Text>
                                <Text type='bold' name='small' color={colors.success}>
                                    {calculateNetIncome(batch.floatPrice)}
                                </Text>
                            </Row>
                        </Column>

                    </BatchItem>
                ))}

                <AddButtonWrapper>
                    <span onClick={addBatch}>
                        <Plus size={25} color={colors.neutral} />
                    </span>
                    <Text name='small' marginTop={'8px'} type='bold' color={colors.occasionalPurple}>Novo lote</Text>
                </AddButtonWrapper>
            </Row>
        </Container>
    );
}

const Container = styled(Column)`
    width: 100%;

    @media screen and (min-width: ${breakpoints.md}) {
        width: 100%;
        & > div {
            overflow: auto;
        }
        
        & > button {
            width: 200px;
        }
    }
`;

const BatchItem = styled(Column)`
    border: 1px solid ${colors.night};
    width: 300px;
    padding: 10px;
    border-radius: 6px;
    margin-left: 20px;
    width: 280px !important;

    &:first-child {
        margin-left: 0;
    }
`;

const BatchHeader = styled(Row)`
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 10px;
`;

const AddButtonWrapper = styled(FullyCentered)`
    margin-left: 25px;
    width: auto;
    height: auto;
    flex-direction: column;
    
    & > span {
        display: flex;
        margin-top: 10px;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: ${colors.occasionalPurple};
        border-radius: 50%;
        cursor: pointer;
        transition: all ease-in-out .25s;
        
        &:hover {
            transform: scale(1.1);
        }
    }
`;

const RemoveButton = styled.span`
    cursor: pointer;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out .2s;

    &:hover {
        transform: scale(1.1);
    }
`;


const ContentToltip = styled.div`
    & > div {
        & > :nth-child(2) {
            width: 240px;
        }
    }
` 