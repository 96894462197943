import React, { createContext, useState, useContext } from 'react';
import tabs from 'theme/tabs';

const ShowLocationModalContext = createContext();

export default function ShowLocationModalProvider({ children }) {
    const [showLocationModal, setShowLocationModal] = useState(false);

    return (
        <ShowLocationModalContext.Provider value={{ showLocationModal, setShowLocationModal }}>
            {children}
        </ShowLocationModalContext.Provider>
    );
}

export function useShowLocationModal() {
    const context = useContext(ShowLocationModalContext);

    if (!context) throw new Error('useShowLocationModal must be used within a ShowLocationModalProvider');

    const { showLocationModal, setShowLocationModal } = context;
    return { showLocationModal, setShowLocationModal };
}