import CurrencyInputField from 'components/_UI/CurrencyInputField';
import Fieldset from 'components/_UI/Fieldset';
import React, { useEffect, useState } from 'react'
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import styled from 'styled-components';
import { useEvent } from 'context/events/Event';
import Text from 'components/_UI/Text';
import { ContentSwitch } from 'styled/UI/StyledSwitch';
import Switch from 'components/_UI/Switch';
import InfoTooltip from 'components/_UI/InfoTooltip';
import eventTypes from 'theme/eventTypes';
import { ChevronDown, ChevronUp, Trash2 } from 'react-feather';
import FormBatches from './FormBatches';
import currency from 'helpers/currency';

export default function DefaultForm({
    ticketIndex,
    selected,
    isUnique,
    onTicketClick = () => false,
    onDeleteTicket = () => false,
    ticket,
    setTicket,
    setDeletedBatches = () => false,
}) {
    const { event, setEvent } = useEvent();
    const [isFree, setIsFree] = useState(event?.classification.id === Number(eventTypes.free.id));
    const [serviceFee, setServiceFee] = useState('')
    const [netIncome, setNetIncome] = useState('')
    const [nameError, setNameError] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [priceError, setPriceError] = useState('')
    const [quantityError, setQuantityError] = useState('')

    function calculateFee(value) {
        return value > 25 ? value * 0.1 : 2.5
    }

    function changeActive(value) {
        setTicket(tickets => {
            const newTickets = [...tickets]
            newTickets[ticketIndex].is_active = value
            
            return newTickets
        })
    }

    function changeName(value) {
        setTicket(tickets => {
            const newTickets = [...tickets]
            newTickets[ticketIndex].name = value
            
            return newTickets
        })

        if (!value) {
            setNameError('O campo é obrigatório')

            return
        }

        if (value.length > 30) {
            setNameError('O nome deve ter menos de 30 caracteres')

            return
        }

        setNameError('')
    }

    function changeQuantity(value) {
        const parsedValue = !value ? '' : value.replace(/\D/g, '');

        setTicket(tickets => {
            const newTickets = [...tickets]
            newTickets[ticketIndex].quantity = parsedValue
            
            return newTickets
        })

        if (!parsedValue) {
            setQuantityError('A quantidade é obrigatória')

            return
        }

        if (Number(parsedValue) < 0) {
            setQuantityError('A quantidade deve ser acima de 0')

            return
        }

        setQuantityError('')
    }

    function changeDescription(value) {
        setTicket(tickets => {
            const newTickets = [...tickets]
            newTickets[ticketIndex].description = value
            
            return newTickets
        })

        if (value.length > 80) {
            setDescriptionError('A descrição deve ter menos de 80 caracteres')

            return
        }

        setDescriptionError('')
    }

    function changePrice(value, name, values) {
        const fee = calculateFee(values.float)
        setServiceFee(
            isFree
                ? currency.format(0)
                : currency.format(fee)
        )
        setNetIncome(
            isFree
                ? currency.format(0)
                : (values.float - fee) > 0
                    ? currency.format(values.float - fee)
                    : currency.format(0)
        )

        setTicket(tickets => {
            const newTickets = [...tickets]
            newTickets[ticketIndex].price = value
            newTickets[ticketIndex].floatPrice = values.float

            return newTickets
        })

        if (!value) {
            setPriceError('O preço do ingresso é obrigatório')

            return
        }

        if (!isFree) {
            if (Number(value) < 5) {
                setPriceError('O preço deve ser maior ou igual a 5')
    
                return
            }
        }

        setPriceError('')
    }

    function onHeaderClick() {
        if (isUnique) {
            return
        }

        onTicketClick()
    }

    useEffect(() => {
        const price = ticket.floatPrice
        const fee = calculateFee(price)

        setServiceFee(
            isFree
                ? currency.format(0)
                : currency.format(fee)
        )
        setNetIncome(
            isFree
                ? currency.format(0)
                : (price - fee) > 0
                    ? currency.format(price - fee)
                    : currency.format(0)
        )
    }, [])

    return (
        <Container active={selected}>
            <TicketHeader onClick={onHeaderClick}>
                {
                    !ticket.name ? (
                        <Text name='highlight' type='bold' paddingBottom={0} color={colors.occasionalPurple}>
                            Ingresso sem nome
                        </Text>

                    ) : (
                        <Text name='highlight' type='bold' paddingBottom={0} color={colors.occasionalPurple}>
                            {ticket.name}
                        </Text>
                    )
                }

                <HeaderActions>
                    {
                        !isUnique && (
                            <Trash2
                                style={{ marginRight: '12px', cursor: 'pointer' }}
                                color={colors.danger}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDeleteTicket();
                                }}
                            />
                        )
                    }

                    {
                        !!ticket?.name?.length && selected ? (
                            <ChevronUp color={colors.occasionalPurple} />
                        ) : <ChevronDown color={colors.occasionalPurple} />
                    }

                </HeaderActions>

            </TicketHeader>

            <Content active={selected}>
                <Row marginTop={'10px'} >
                    <Column>
                        <Fieldset
                            label='Nome do ingresso'
                            placeholder='Ingresso meia entrada, camarote, etc'
                            value={ticket.name}
                            onChangeValue={changeName}
                        />
                        {nameError && (
                            <Column>
                                <Text color={colors.danger} name='small'> {nameError} </Text>
                            </Column>
                        )}
                    </Column>

                    <Column marginLeft='20px'>
                        <Fieldset
                            label='Descrição (Opcional)'
                            placeholder='Ao comprar o ingresso ganhe um copo de brinde...'
                            value={ticket.description}
                            onChangeValue={changeDescription}
                        />
                        {descriptionError && (
                            <Column>
                                <Text color={colors.danger} name='small'> {descriptionError} </Text>
                            </Column>
                        )}
                    </Column>
                </Row>

                <Row marginTop='25px'>
                    <Column>
                        <Row>
                            <Column >
                                <CurrencyInputField
                                    placeholder='R$ 50,00'
                                    value={ticket.price}
                                    disabled={isFree}
                                    label='Valor (Lote 1)'
                                    onChange={changePrice}
                                />

                                {priceError && (
                                    <Column>
                                        <Text color={colors.danger} name='small'> {priceError} </Text>
                                    </Column>
                                )}
                            </Column>

                            <Column marginLeft='20px'>
                                <Fieldset
                                    label='Quantidade (Lote 1)'
                                    placeholder='15'
                                    value={ticket.quantity}
                                    syncProp={true}
                                    onChangeValue={changeQuantity}
                                />
                                {quantityError && (
                                    <Column>
                                        <Text color={colors.danger} name='small'> {quantityError} </Text>
                                    </Column>
                                )}
                            </Column>
                        </Row>


                        <Row marginTop='10px'>
                            <InfoBox>
                                <Row>
                                    <Text name='small' color={colors.night}>
                                        Preço do ingresso:
                                    </Text>
                                    <Text name='small' type='bold' marginLeft={'4px'} color={colors.success}>
                                        {
                                            isFree
                                                ? 'Gratuito'
                                                : currency.format(ticket.floatPrice)
                                        }
                                    </Text>
                                </Row>

                                <Row>
                                    <Text name='small' color={colors.night}>
                                        Taxa de serviço
                                    </Text>
                                    <Text name='small' type='bold' marginLeft={'4px'} color={colors.success}>
                                        { serviceFee }
                                    </Text>
                                </Row>

                                <Row>
                                    <Text name='small' color={colors.night}>
                                        Você irá receber:
                                    </Text>
                                    <Text name='small' type='bold' marginLeft={'4px'} color={colors.success}>
                                        { netIncome }
                                    </Text>
                                </Row>
                            </InfoBox>

                            <Row marginLeft='20px'>
                                <ContentSwitch>
                                    <Switch
                                        checked={ticket.is_active}
                                        onChange={changeActive}
                                    />
                                </ContentSwitch>
                                <Row>
                                    <Text marginLeft={'4px'}>
                                        Ingresso ativo
                                    </Text>
                                    <InfoTooltip
                                        bgColor={colors.occasionalPurple}
                                        text="Se ativo, o ingresso estará disponível para compra."
                                    />
                                </Row>
                            </Row>
                        </Row>
                    </Column>
                    <Column marginLeft='20px'></Column>
                </Row>
                {
                    !isFree && (
                        <Column marginTop='20px'>
                            <Row>
                                <Text type='bold' color={colors.nightDark} >
                                    Configuração de lotes
                                </Text>
                                <InfoTooltip
                                    text="Configurações de lotes permitem definir diferentes preços para diferentes quantidades de ingressos."
                                >
                                </InfoTooltip>
                            </Row>

                            <FormBatches
                                batches={ticket.batches}
                                setTicket={setTicket}
                                ticketIndex={ticketIndex}
                                setDeletedBatches={setDeletedBatches}
                            />
                        </Column>
                    )
                }
            </Content>
        </Container >
    )
}


const TicketHeader = styled(Row)`
    justify-content: space-between;
    border: 2px solid transparent;
`;

const Container = styled.form`
    padding-bottom: 20px;
    justify-content: space-between;
    border: 1px solid ${colors.night};
    border-radius: 6px;
    align-items: center;
    padding: 10px;
    margin-bottom: 15px;
    transition: all ease-in-out .25s;
`;

const Content = styled(Column)`
    height: 0;
    transition: all ease-in-out .25s;
    opacity: 0;
    visibility: hidden;
    
    ${({ active }) => active && `
        height: auto;
        opacity: 1;
        visibility: visible;
    `};
`;

const HeaderActions = styled(Row)`
    width: auto;    
`;

const InfoBox = styled(Column)`
    border-radius: 6px;
    margin-top: 2px;
    width: calc(90% + 30px);
    
    & > div {
        justify-content: space-between;
    }
`;

