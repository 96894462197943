import React, { createContext, useState, useContext } from 'react';

const EstablishmentsNearToYouContext = createContext();

export default function EstablishmentsNearToYouProvider({ children }) {
    const [establishmentsNearToYou, setEstablishmentsNearToYou] = useState(null);

    return (
        <EstablishmentsNearToYouContext.Provider value={{ establishmentsNearToYou, setEstablishmentsNearToYou }}>
            {children}
        </EstablishmentsNearToYouContext.Provider>
    );
}

export function useEstablishmentsNearToYou() {
    const context = useContext(EstablishmentsNearToYouContext);

    if (!context) throw new Error('useEstablishmentsNearToYou must be used within a EstablishmentsNearToYouProvider');

    const { establishmentsNearToYou, setEstablishmentsNearToYou } = context;
    return { establishmentsNearToYou, setEstablishmentsNearToYou };
}