import establishmentsApi from 'api/requests/establishments'
import Loader from 'components/_UI/Loader'
import Text from 'components/_UI/Text'
import React, { useState, useEffect } from 'react'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'
import { HorizontalCenter } from 'styled/alignment/Center'
import { useEstablishment } from 'context/establishments/Establishment'
import ButtonSelectDash from 'components/_UI/ButtonSelectDash'
import styled from 'styled-components'
import { Row } from 'styled/alignment/Row'
import breakpoints from 'theme/breakpoints'

export default function ServiceOptions({ data, isEdit, loading, setServiceOptions = () => false }) {
    const [selectedItems, setSelectedItems] = useState(isEdit?.map(item => item.id) || [])
    const { establishment, setEstablishment } = useEstablishment()

    useEffect(() => {
        if (isEdit) {
            const items = isEdit.map(item => item.id)
            setSelectedItems(items)
            // items.length && setEstablishment({ ...establishment, serviceOptions: items })
            setServiceOptions(items)
        }
    }, [isEdit, loading, data])


    const handleSelectItem = (id) => {
        setSelectedItems(prevSelectedItems => {
            let newSelectedItems

            if (prevSelectedItems.includes(id)) {
                newSelectedItems = prevSelectedItems.filter(item => item !== id)
            } else {
                newSelectedItems = [...prevSelectedItems, id]
            }

            // setEstablishment({
            //     ...establishment,
            //     serviceOptions: newSelectedItems
            // })
            setServiceOptions(newSelectedItems);

            return newSelectedItems
        })
    }

    return (
        <Column marginTop='40px' verticalCenter>
            <Text size='small' type='bold' color={colors.occasionalPurple}> Opções de serviço</Text>
            <Text size='small' color={colors.night} textAlign={'center'}>Selecione opções de serviço que seu estabelecimento oferece.</Text>

            {
                loading ? <Loader /> : (
                    <StyledList>
                        {
                            data?.map((el) => (
                                <SelectItem
                                    key={el.id}
                                    selected={selectedItems.includes(el.id)}
                                    onClick={() => handleSelectItem(el.id)}
                                >
                                    <Text
                                        name='mini'
                                        textAlign={'center'}
                                        paddingBottom={0}
                                        type='bold'
                                        ellipsisAt={'98%'}
                                        color={selectedItems.includes(el.id) ? colors.neutral : colors.night}
                                    >
                                        {el.name}
                                    </Text>
                                </SelectItem>

                            ))
                        }
                    </StyledList>
                )
            }

        </Column>
    )
}


const StyledList = styled(Row)`
    flex-wrap: wrap;
    justify-content: space-around;
`

const SelectItem = styled.div`
    width: 30%;
    margin-bottom: 3%;
    border: 1px solid ${colors.night};
    padding: 10px 5px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media screen and (min-width: ${breakpoints.lg}){
        width: 15%;
    }

    ${({ selected }) => selected && `
        background: #4617ad;
        border: 1px solid #4617ad;
    `};
`
