import CurrencyInputField from 'components/_UI/CurrencyInputField';
import Fieldset from 'components/_UI/Fieldset';
import Text from 'components/_UI/Text';
import React, { useState, useEffect } from 'react';
import { Check } from 'react-feather';
import styled, { keyframes, css } from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import indexPriority from 'theme/z-index';

export default function ProductSelect({ data, edit, selected, products, onSelect, onUpdate }) {
    const [price, setPrice] = useState(data?.price);
    const [description, setDescription] = useState(data?.description);
    const [quantity, setQuantity] = useState(data?.quantity);

    useEffect(() => {
        if (selected) {
            onUpdate(data.id, { price, description, quantity });

            const selectedItem = products.length && products?.find(el => el.name == selected.name);
            
            if (selectedItem) {
                setQuantity(selectedItem?.quantity);
                setPrice(selectedItem?.price);
                setDescription(selectedItem?.description);
            }

        }
    }, [selected]);

    const handleQuantityChange = (value) => {
        if (!value) {
            setQuantity('');
            return ''
        }

        const numericValue = value.replace(/[^0-9]/g, '');
        onUpdate(data.id, { price, description, quantity: numericValue });
        setQuantity(numericValue);
    };


    return (
        <CardContainer onClick={onSelect} selected={selected}>
            {selected ? (
                <SelectedMark>
                    <Check color={colors.neutral} />
                </SelectedMark>
            ) : (
                <SelectedMark empty={true} />
            )}

            <DefaultContentWrapper>
                <DefaultContent selected={selected}>
                    <ImageContainer>
                        {data?.image ? (
                            <Image src={data?.image} />
                        ) : (
                            <Image src={'../../assets/images/placeholder-products.png'} />
                        )}
                    </ImageContainer>

                    <FullyCentered marginTop='10px'>
                        <Text type='bold' color={colors.occasionalPurple} name='default'>
                            {data?.name}
                        </Text>
                    </FullyCentered>
                </DefaultContent>
            </DefaultContentWrapper>

            <SecondContent selected={selected}>
                <Text type='bold'>
                    {data?.name}
                </Text>
                <form onClick={(e) => e.stopPropagation()}>
                    <CurrencyInputField
                        placeholder='Preço'
                        value={price}
                        marginTop='5px'
                        badgeText='Preço'
                        badgePosition='left'
                        syncProp={true}
                        onChange={e => {
                            onUpdate(data.id, { price: parseFloat(e), description, quantity });
                            setPrice(e);
                        }}
                    />
                    <Fieldset
                        marginTop='5px'
                        placeholder='Quantidade'
                        badgeText='Quantidade'
                        badgePosition='left'
                        value={quantity}
                        syncProp={true}
                        paddingRight='60px'
                        onChangeValue={handleQuantityChange} 
                    />
                    <Fieldset
                        placeholder='Descrição (Opcional)'
                        marginTop='5px'
                        value={description}
                        syncProp={true}
                        onChangeValue={e => {
                            onUpdate(data.id, { price, description: e, quantity });
                            setDescription(e);
                        }
                        }
                    />
                </form>
            </SecondContent>

            <ActionsContent>
                <Column>
                    <Text name='mini' color={colors.occasionalPurple} paddingBottom={0} type='bold'>
                        Preço
                    </Text>
                    <Text name='normal' color={colors.night} paddingBottom={0} type='bold'>
                        R$ {price}
                    </Text>
                </Column>

                <Column>
                    <Text name='mini' color={colors.occasionalPurple} paddingBottom={0} type='bold'>
                        Quantidade
                    </Text>
                    <Text name='normal' color={colors.night} paddingBottom={0} type='bold'>
                        {quantity || '0'} 
                    </Text>
                </Column>
            </ActionsContent>
        </CardContainer>
    );
}

const CardContainer = styled(Column)`
    background: ${colors.neutralLight};
    box-shadow: 0px 1px 6px ${colors.nightLight};
    border: 2px solid ${({ selected }) => (selected ? colors.occasionalPurple : colors.neutralLight)};
    border-radius: 6px;
    padding: 4px;
    cursor: pointer;
    transition: all ease-in-out 0.25s;
    position: relative;
    overflow: hidden;
    height: 240px;
    margin-bottom: 20px;

    &:hover {
        box-shadow: 0px 1px 8px ${colors.night};
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        width: 31%;
    }
`;

const slideUp = keyframes`
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
`;

const slideDown = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const DefaultContentWrapper = styled.div`
    position: relative;
    height: 170px;
`;

const DefaultContent = styled(Column)`
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid ${colors.nightLight};
    animation: ${({ selected }) => selected ? css`${slideUp} 0.5s forwards` : 'none'};
    z-index: ${({ selected }) => selected ? 0 : 1};
`;

const SecondContent = styled(FullyCentered)`
    flex-direction: column;
    padding: 8px 4px;
    height: 170px;
    display: ${({ selected }) => (selected ? 'flex' : 'none')};
    animation: ${({ selected }) => selected ? css`${slideDown} 0.5s forwards` : 'none'};
    z-index: ${({ selected }) => selected ? 1 : 0};

    & > form {
        width: 85%;

        & input {
            text-align: right;
            &::placeholder {
                text-align: left;
            }
        }
    }
`;

const ImageContainer = styled.picture`
    width: 100%;
    height: 130px;
    padding: 2px;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
`;

const ActionsContent = styled(Row)`
    padding: 5px 10px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;

    & > div:nth-child(2) {
        justify-content: flex-end;
        text-align: right;
    }
`;

const SelectedMark = styled(FullyCentered)`
    position: absolute;
    right: 8px;
    top: 6px;
    background: ${colors.occasionalPurple};
    padding: 4px;
    width: 22px;
    height: 22px;
    border-radius: 60px;  

    ${({ empty }) => empty && `
        border: 2px solid ${colors.night};
        z-index: ${indexPriority.medium};
        background-color: ${colors.neutralLight};
    `}
`;
