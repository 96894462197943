import React from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import sizes from 'theme/sizes';
import { useCollapse } from 'context/general/Collapse';
import establishmentsApi from 'api/requests/establishments';
import { FullyCentered } from 'styled/alignment/Center';
import Text from 'components/_UI/Text';
import Loader from 'components/_UI/Loader';
import breakpoints from 'theme/breakpoints';
import { Row } from 'styled/alignment/Row';
import CardProfile from 'modules/dashboard/profile/parts/CardProfile';
import ResumeCard from '../card/CardResume';

export default function NearListAll({ data, total, onEventClick }) {
    const { collapse, setCollapse } = useCollapse();

    async function renderEstablishmentDetail(establishment) {
        setCollapse({   
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => (
                <FullyCentered height='100%'>
                    <Loader />
                </FullyCentered>
            )
        })
        try {
            const { data: res } = await establishmentsApi.getBySlug(establishment.slug);
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => establishment && <CardProfile onEventClick={onEventClick} fullWidth={true} data={res.data} />
            })
        } catch (error) {
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => (
                    <FullyCentered height='100%'>
                        <Text name='highlight'> Ocorreu um erro inesperado. </Text>
                    </FullyCentered>
                )
            })
        }

    }

    return (
        <Container >
            <FullyCentered>
                <Text name='default' type='bold'>
                    {total} estabelecimentos perto de você
                </Text>
            </FullyCentered>

            <RowItem>
                {data.map((establishment) => (
                    <ResumeCard
                        key={establishment.slug}
                        data={establishment}
                        onCardClick={() => renderEstablishmentDetail(establishment)}
                        fullWidth
                        smallOnDesktop={true}
                    />
                ))}
            </RowItem>
        </Container>
    )
}

const Container = styled(Column)`
    &:nth-child(1) {
        padding: ${sizes.medium} ${sizes.tiny} ${sizes.tiny} ${sizes.tiny};
    }
    width: 100%;
    height: auto;
    padding: ${sizes.medium};

    ${({ large }) => large && `
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    `}
    margin-top: 20px;
`;

const RowItem = styled(Column)`
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        padding: ${sizes.medium} 0;

        & > div {
            margin-left: 1%;
            margin-right: 1%;
            width: 31% !important;   
        }
    }
`;