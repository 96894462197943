import Divider from 'components/_UI/Divider'
import Text from 'components/_UI/Text';
import { useEventDetailTab } from 'context/dash/EventDetailTab';
import ProductCard from 'modules/dashboard/products/list/ProductCard';
import React from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function ProductsForSale({data, detailEvent}) {

    let setEventDetailTab;
    if (!detailEvent) {
        ({ setEventDetailTab } = useEventDetailTab());
    }

  return (
    <Column>
        <Divider marginTop={'40px'} color={colors.primaryDashboard} opacity={0.3} />

        <StyledContent>
            <StyledText>
                <Text name='subtitle' color={colors.night}>Produtos a venda</Text>
            </StyledText>

        </StyledContent>

        <MainContent>
            {
                data?.filter((el) => el.type === 'product').map((el) => (
                    <ContentProducts key={el.id} >
                        <ProductCard data={el} onProductClick={() => setEventDetailTab('products')}  isVisiblePrice/>
                    </ContentProducts>
                ))
            
            }
        </MainContent>
    </Column>
  )
}


const StyledContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    
    @media screen and (min-width: 690px) {
        display: flex;
        flex-direction: column;
    }
`;

const StyledText = styled(Row)`
    padding: 0;
    height: 20px;
    @media screen and (min-width: 690px){
        padding: 0 10px 0 10px;
    }
`;

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 690px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }

`;

const ContentProducts = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 20px;
    @media screen and (min-width: ${breakpoints.md}) {
        width: 48%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    & > div {
        width: 100%;
        height: 120px;
        @media screen and (min-width: 690px) {
            width: 95%;
        }
           
    }
`