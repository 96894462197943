const buttons = {
    variant: [
        'primary',
        'secondary',
        'disabled',
        'dark',
        'featured',
        'light',
        'promoted',
        'success',
        'successBorded',
        'lightBlue',
        'darkBlue',
        'yellow',
        'pink'
    ],

    category: [
        'rounded',
        'roundedBorded',
        'squired',
        'squiredBorded',
        'minimal',
        'minimalDark',
        'outline',
    ],

    size: [
        'small',
        'medium',
        'default',
        'large'
    ]
};

export default buttons;