import Icon from 'components/_UI/Icon'
import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import { Link, useNavigate } from 'react-router-dom';
import LinkPage from 'components/_UI/LinkPage'


export default function PrivacyTermUse() {
    const navigate = useNavigate();

    function goBack() {
        navigate(-1)
    }
    return (
        <Column
            paddingLeft='30px'
            paddingRight='30px'
            paddingTop='20px'
        >   

            <StyledRow >
                <ContenButtonBack
                    onClick={goBack}
                >
                    <StyledButton>
                        <Icon name='back' />
                    </StyledButton>
                    <Text>Voltar</Text>
                </ContenButtonBack>
            </StyledRow>
            <Text name='title'>Termos de uso</Text>

            <Text>O Whizr Plataforma de Conteúdo LTDA - sob CNPJ 54.729.749/0001-01, reserva o direito de fazer atualizações nos seus Termos de Uso e Privacidade a qualquer momento. A versão atualizada estará disponível para consulta com a data de alteração indicada no documento, sendo que, a partir do momento em as alterações entrarem em vigor, e ficarem disponíveis na plataforma, é de responsabilidade do usuário revisar tais alterações. Para os casos em que o usuário continue a utilizar os serviços propostos pelo Whizr sem contestação, será entendido que as alterações propostas foram aceitas.</Text>
            <Text>É de extrema importância que, para operar na plataforma, todas as pessoas e entidades usuárias aceitem os Termos de Uso e as Políticas de Privacidade.</Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                Introdução
            </Text>

            <Text>Olá, esse é o seu primeiro contato com o Whizr, seja muito bem-vindo!</Text>

            <Text>Os termos a seguir são constituídos a partir dos princípios que norteiam a plataforma, do relacionamento com as diferentes frentes de usuários e do bom relacionamento e respeito às normas previstas em lei. Portanto, para continuar com o seu cadastro, leia com atenção e, antes de realizar a aceitação dos termos propostos, esteja completamente ciente e de acordo com as regras que regem o documento.</Text>
            
            <Text>É importante ressaltarmos aqui que não é permitido acessar ou usar o Whizr para qualquer finalidade diferente daquela para a qual disponibilizamos a plataforma e os nossos serviços. </Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                Definições importantes
            </Text>


            <Text>Whizr: serviço disponível em plataforma web ou aplicativo, permitindo uso e cadastro de pessoas e negócios, de forma a intermediar informações, vendas ou compras entre os mesmos.</Text>

            <Text>Plataforma: refere-se ao acesso ao ambiente do Whizr em modo de navegação web.</Text>

            <Text>Aplicativo: refere-se ao acesso ao aplicativo Whizr para android.</Text>

            <Text>Serviços: refere-se aos serviços propostos pelo Whizr, sejam eles direcionados a empresas ou pessoas físicas.</Text>

            <Text>Contratante: refere-se a quem usa os serviços do Whizr para a realização de exposição de marca, criação de eventos, venda de ingressos e produtos, etc.</Text>

            <Text>Contratada: refere-se ao Whizr como plataforma intermediária entre negócios e clientes. </Text>

            <Text>Usuários: pessoas que usam os serviços disponíveis na plataforma do Whizr, sejam os mesmos usuários pessoa física ou pessoa jurídica.</Text>

            <Text>Taxa de operação: taxa que o Whizr cobra para operacionalizar os serviços.</Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                Tópicos abordados
            </Text>

            <ul>
                <Text>Nesse documento você irá encontrar informações acerca de:</Text>
                
                <Text> > Como fazer uso da plataforma do Whizr</Text>
                <Text> > Como a plataforma do Whizr funciona</Text>
                <Text> > Como é realizado o processo de cadastro na plataforma</Text>
                <Text> > Como é feita a aquisição, disponibilização e validação de ingressos e produtos</Text>
                <Text> > Como é realizado o pagamento de ingressos e/ou produtos</Text>
                <Text> > Como é realizado o processo de cancelamento e reembolso</Text>
                <Text> > Como funcionam as taxas cobradas pelo Whizr</Text>
                <Text> > Como funcionam os processos de contestação e estorno</Text>
                <Text> > Como o Whizr se posiciona em relação à segurança, propriedade intelectual e LGPD</Text>
            </ul>


            <Text>Além disso, as Políticas de Privacidade também poderão ser consultadas neste documento.</Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                Uso do Whizr
            </Text>

            <Text>A nossa plataforma foi criada com o intuito de tornar a experiência de encontrar eventos e atividades de lazer uma busca rápida e prática. Para isso, consolidamos todos os eventos no mapa e permitimos que sejam realizadas buscas personalizadas através dos nossos filtros. Também permitimos que os usuários favoritem seus eventos de interesse, assim como os compartilhem com outras pessoas através de inúmeros canais externos. Porém, para as atividades de interação com o evento, que excedem apenas a consulta das informações, é necessário que o usuário realize seu cadastro na plataforma, concordando com os Termos aqui dispostos. </Text>
            
            <Text>Além da possibilidade de interação com os eventos, o usuário cadastrado consegue realizar a aquisição de ingressos e produtos à venda adicionados pelos parceiros organizadores. Nesse sentido, quem disponibiliza e gerencia esses eventos, ingressos e produtos são os próprios parceiros organizadores, ficando o Whizr responsável apenas pela intermediação do processo de oferta, venda e disponibilização dos ingressos e/ou produtos adquiridos na ferramenta de “carteira” do usuário cadastrado.</Text>

            <Text>Os ingressos ou produtos poderão ser adquiridos diretamente no Whizr, que utiliza de gateway de pagamento integrado, garantindo múltiplas formas de pagamento e segurança para as transações. Os valores dos ingressos e produtos são atribuídos pelo parceiro organizador, seguindo regras de utilização da plataforma, portanto o Whizr não é o responsável pela precificação. </Text>

            <Text>Para a utilização do Whizr, os usuários se comprometem a fazer uso da plataforma de forma  ética e adequada aos propósitos estabelecidos nestes Termos, atendendo às leis brasileiras e comprometendo-se a se abster de qualquer atividade que constitua violação das referidas normas.</Text>

           
            <Text
                name='highlight'
                marginTop='20px'
            >
                Funcionamento do Whizr
            </Text>

            <Text>O nosso princípio de funcionamento se baseia no mapeamento constante de eventos de múltiplos espaços, populando a plataforma com informações de acesso público, porém, não é de nossa responsabilidade se um evento terceiro for cancelado, alterado, ou tiver qualquer outro problema posteriormente à adição do mesmo na plataforma. Para eventos cadastrados no Whizr por parceiros da plataforma, existem regras a serem respeitadas, tais como o preenchimento de campos obrigatórios e veracidade das informações dispostas. Dessa forma, o Whizr se dispõe a minimizar quaisquer erros de divulgação, embora não consiga validar informações alteradas ou desatualizadas por parte do parceiro, sendo de estrita responsabilidade do mesmo fazer as correções e avisos necessários sobre os seus eventos. Em casos de alterações mais complexas (como cancelamentos, por exemplo), as mesmas deverão ser justificadas e serão avaliadas pela plataforma.</Text>

            <Text>Para o caso de eventos mapeados que tenham sido criados fora do Whizr, nenhum valor será transacionado através da plataforma, assim como a aquisição de ingressos, portanto essas informações e necessidades relacionadas também estão diretamente ligadas aos organizadores externos, o Whizr apenas redireciona para o canal de compra quando existe um link público.</Text>

            <Text>O Whizr preza pela qualidade dos serviços oferecidos, porém a partir dos termos presentes, fica isento de culpa por alguma indisponibilidade do serviço necessária para manutenção ou motivos de causa maior. Em caso de indisponibilidade, o serviço será restabelecido com a maior agilidade possível, e, se programado, haverá comunicação sinalizando isso com antecedência.</Text>
            
            

            <Text
                name='highlight'
                marginTop='20px'
            >
                Cadastro no Whizr
            </Text>

            <Text>O cadastro no Whizr é feito de maneira gratuita, porém os serviços oferecidos pelos parceiros, como a venda de ingressos e produtos, serão cobrados de acordo com a precificação atribuída pelos mesmos, seguindo conformidade com as regras e taxas exercidas pela plataforma. Ao realizar uma compra, diferente da busca que não necessita de cadastro para ser acessada, é necessário que o usuário esteja cadastrado na plataforma para realizar o pagamento e receber os ingressos.</Text>

            <Text>Para fins legais, o usuário deve cadastrar informações verdadeiras, assumindo o compromisso de atualizar seus dados pessoais sempre que necessário ou quando requerido. No caso de dados falsos, podemos fazer a recusa, suspensão ou encerramento da conta. Para fins de confirmação, podemos solicitar documentos adicionais de confirmação de identidade.</Text>

            <Text>Para fornecer nossos serviços, podemos coletar e processar dados pessoais de acordo com nossa Política de Privacidade. Ao utilizar o serviço, você consente com a coleta, uso e divulgação de seus dados pessoais de acordo com nossa Política de Privacidade e com a LGPD. Dito isto, o Whizr não se responsabiliza pela veracidade dos dados cadastrados, porém a criação de perfis falsos poderá ser considerada crime de falsa identidade, falsidade ideológica ou estelionato.</Text>

            <Text>Em casos de qualquer atividade fraudulenta, suspeita de fraude sem confirmação de veracidade da conta, danos ou prejuízos causados à plataforma, parceiros ou outros usuários da nossa comunidade, assim como qualquer ponto que entre em desacordo com a lei e os Termos da plataforma, a conta poderá ser cancelada a qualquer momento pelo Whizr.</Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                Aquisição e validação de ingressos e produtos no Whizr
            </Text>

            <Text>No momento da aquisição de ingressos e/ou produtos, o usuário deve estar ciente de que as informações adicionadas ao formulário, assim como o método de pagamento utilizado, devem ser verdadeiros e concordantes com fins legais, sendo portanto responsabilidade do mesmo preencher todos os campos de forma correta. Após escolhido o método de pagamento, o usuário terá o tempo máximo para a finalização do pagamento de 15 minutos, podendo variar em períodos de alta ou baixa demanda. Ingressos adquiridos por outras fontes que não o Whizr não estarão sob a responsabilidade da plataforma.</Text>


            <Text
                name='highlight'
                marginTop='20px'
            >
                Tempo de disponibilização e validação
            </Text>

            <Text>Assim que o pagamento for identificado, o ingresso e/ou produto ficará disponível na plataforma, no item “carteira” do usuário. Na carteira também poderá ser visto o histórico de compras já validadas. Após o recebimento de códigos, é de responsabilidade do usuário mantê-lo em segurança, não divulgando o mesmo.</Text>

            <Text>Para a validação, é necessário apresentar ao organizador do evento os códigos respectivos às aquisições. Não serão aceitos códigos já validados.</Text>

            <Text>A validação para confirmar a realização do evento no Whizr é baseada em reclamações feitas pelos usuários até 5 dias corridos após a conclusão do evento, juntamente com a verificação de que no mínimo 50% dos ingressos vendidos no Whizr foram validados pelo parceiro via painel.</Text>

            <Text>Os fundos arrecadados serão disponibilizados 5 dias após o término do evento, desde que o evento tenha sido validado pela equipe do Whizr. A validação confirma se o evento realmente ocorreu. Transações feitas via Pix estarão disponíveis após a validação e os 5 dias. Transações realizadas por cartão de crédito à vista estarão disponíveis 30 dias após o término do evento, e o evento precisa estar validado.</Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                Pagamento
            </Text>

            <Text>
            O processo de pagamento começa a partir da escolha da modalidade de ingresso e/ou produto ofertado pelo organizador do evento. Feita a escolha, o usuário poderá finalizar a compra utilizando os métodos disponíveis. Essa transação passa por verificação de segurança. É importante salientarmos que o Whizr não emite notas fiscais para o usuário consumidor por prestar serviços de intermediação, ficando assim o organizador responsável pela nota fiscal para o usuário consumidor. A nota fiscal gerada pelo Whizr é uma NFSe, referente ao valor da prestação de serviço do Whizr para o parceiro. O valor do serviço na nota fiscal corresponde ao somatório total das taxas de cada transação do evento. 
            A NFSe será gerada um dia após o evento e ficará disponível para o parceiro baixar no painel.
            </Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                Taxas de serviço
            </Text>


            <Text>O Whizr cobra uma taxa de utilização para os organizadores de 10% para transações acima de 25,00, e para transações com valor menor ou igual a 25,00 a taxa de utilização tem um valor mínimo de R$2,50 por transação. Esse valor cobre os custos operacionais da plataforma e garante a qualidade do serviço. Os valores poderão vir visíveis para o usuário final ou já incluídos no valor da compra. </Text>


            <Text
                name='highlight'
                marginTop='20px'
            >
                Cancelamento e reembolso
            </Text>

            <Text>O usuário consumidor deve ficar atento às políticas de cancelamento do organizador do evento em questão e fazer o pedido diretamente para o mesmo, se atentando também aos direitos do consumidor. Feito e aprovado o pedido de cancelamento, o organizador deverá solicitar o reembolso do valor diminuído a taxa de operação (10% ou o valor mínimo de R$2,50), que acontecerá conforme o método de pagamento utilizado na compra. </Text>


            <Text
                name='highlight'
                marginTop='20px'
            >
                Contestações e estorno
            </Text>


            <Text>
                Uma contestação pode ser feita junto ao organizador, quando por exemplo, houve uma compra e você não a reconhece (lembrando que o player de pagamento pode estar com um nome diferente de Whizr ou do negócio específico, pois utilizamos um serviço intermediário para isso). Porém, caso aconteça cobrança indevida ou cobrança duplicada, por exemplo, deverá ser solicitado o estorno ao organizador do evento. Para que o estorno seja realizado, é possível que o organizador peça documentos comprobatórios do caso. No caso de o organizador não responder ou não aceitar a justificativa sem motivo plausível, é importante que o Whizr seja notificado através do contato de e-mail <b>contato@whizr.app</b>, para investigarmos o ocorrido e chegarmos a uma solução.
            </Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                Segurança no Whizr
            </Text>

            <Text>Um dos princípios do Whizr é que os nossos usuários estejam seguros no uso da plataforma, porém não somos responsáveis pela confidencialidade de credenciais de acesso dos usuários. Portanto, o Whizr não se responsabiliza por perdas em detrimento de compartilhamento de credenciais, de forma que os usuários se comprometem a não informar a terceiros tais dados, responsabilizando-se integralmente pelo uso que deles seja feito, se comprometendo a notificar o Whizr, no caso de qualquer uso não autorizado ou violações de segurança.</Text>

            <Text>Como informado no item de funcionamento, o Whizr não é o responsável pela criação dos eventos e informações divulgadas, funcionando como um intermediário facilitador entre quem divulga e vende ingressos e produtos e quem consome os mesmos. Nesse sentido, o Whizr não se responsabiliza por cobranças realizadas fora da plataforma. No caso de descumprimento das regras por parte de um parceiro, o usuário deve informar ao Whizr da situação e, se necessário, deve também apresentar o caso às autoridades judiciais mais apropriadas.</Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                Propriedade intelectual do Whizr
            </Text>

            <Text>O uso comercial da palavra “Whizr” como marca, nome empresarial ou nome de domínio, assim como os conteúdos das telas relativas aos serviços oferecidos, são de titularidade apenas do Whizr. O uso indevido de algum conteúdo é proibido, exceto que haja autorização prévia da marca.</Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                Proteção de registros no Whizr
            </Text>

            <Text>O Whizr será obrigado a disponibilizar registros de acesso ou informações pessoais armazenadas na conta caso haja uma ordem judicial ou requisição de autoridade policial ou administrativa competente.</Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
                Limitação de responsabilidade do Whizr
            </Text>

            <Text>Fica estabelecido que o Whizr não tem a responsabilidade pelas ações tomadas pelos usuários durante o uso do serviço e, portanto, não poderá ser penalizada por tais atos desses usuários. Isso se aplica inclusive em casos de atos ilícitos ou antiéticos, os quais devem responder à justiça em caráter pessoal.</Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
               Exclusão de Dados Pessoais
            </Text>

            <Text>
                Ao solicitar a exclusão da minha conta no aplicativo Whizr, concordo que todos os dados pessoais associados à minha conta serão permanentemente excluídos dos servidores da empresa. Isso inclui, mas não se limita a, informações de perfil, histórico de eventos participados, comentários e qualquer outro dado fornecido durante o uso do aplicativo, que não precise ser armazenado pelo Whizr em função de manter-se em acordo com a lei.
            </Text>

            <Text>
                É importante salientar também que para que uma solicitação de exclusão de conta seja efetivada, não poderão haver pendências da parte do usuário para com o Whizr e/ou parceiros da plataforma, sejam financeiras ou de uso indevido.
            </Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
               Conformidade com a LGPD e Políticas da Apple
            </Text>

            <Text>Reconheço que a exclusão de dados pessoais está em conformidade com as disposições da Lei Geral de Proteção de Dados (LGPD) e com as políticas de privacidade da Apple, caso eu tenha utilizado o aplicativo em dispositivos da Apple.</Text>


            <Text
                name='highlight'
                marginTop='20px'
            >
               Irreversibilidade da Exclusão
            </Text>
            
            <Text>
                Compreendo que a exclusão da minha conta é irreversível e que não poderei recuperar meus dados após a exclusão. Assumo total responsabilidade por esta decisão.
            </Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
               Responsabilidade do Titular dos Dados
            </Text>

            <Text>
                Declaro que sou o titular legítimo da conta e dos dados associados a ela. Isento a empresa Whizr Plataforma de conteúdo LTDA de qualquer responsabilidade futura relacionada aos dados excluídos.
            </Text>

            <Text
                name='highlight'
                marginTop='20px'
            >
               Registro da Solicitação
            </Text>

            <Text>Esta solicitação de exclusão será registrada para fins de conformidade com as leis de proteção de dados e poderá ser utilizada como comprovante da minha solicitação.</Text>

            <Text type='bold' marginBottom={'40px'}>Ficou com alguma dúvida? Entre em contato com a gente através do contato@whizr.app.</Text>

        </Column>
    )
}


const StyledRow = styled(Row)`
    width: 100%;
    justify-content: center;
`;

const ContenButtonBack = styled.div`
    display: flex;
    flex-direction: row;
    width: 80px;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in-out .25s;
    & > p {
        padding-bottom: 0px;
        font-size: 16px;
        margin-left: 5px;
        color: ${colors.primaryDashboard}
    }

    &:hover {
        transform: scale(1.02)
    }

`;

const StyledButton = styled(Row)`
    width: 26px;
    padding: 5px;
    height: 26px;
    border-radius: 50%;
`;
