const validateInputFile = (file, allowedFileSize = 5, additionalFileTypes) => {
	const allowedTypes = [
		'image/jpg',
		'image/jpeg',
		'image/png',
	];
	
	if (additionalFileTypes) {		
		allowedTypes.push(...additionalFileTypes);
		
	}
	
	const fileExtensions = allowedTypes?.map(type => type.split('/')[1].toUpperCase());

	
    const messages = {
        largeFile: `O arquivo é muito grande. O tamanho máximo permitido é ${allowedFileSize}MB.`,
        invalidFormat: `Tipo de arquivo inválido. Arquivos permitidos: ${fileExtensions}`
    };


	const currentFileSize = (file.size / (1024 * 1024)),
		allowedFileTypes = allowedTypes;

	
	if (currentFileSize > allowedFileSize) {
		return {
			isValid: false,
			message: messages.largeFile,
			errorType: 'size'
		};
	}

	if (!allowedFileTypes.includes(file.type)) {
		return {
			isValid: false,
			message: messages.invalidFormat,
			errorType: 'type'
		};
	}

	return {isValid: true};
};

export default validateInputFile;
