import React from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function CardSlot({ children, marginTop = 0}) {
  return (
    <Slot marginTop={marginTop}>
      {children}
    </Slot>
  )
}

const Slot = styled(Column)`
  background: ${colors.neutralLight};
  width: 100%;
  height: auto;
  border-radius: 4px;
  padding: 15px;
  margin-top: 20px;
  
  @media screen and (min-width: ${breakpoints.lg}) {
    margin-left: 1%;
    width: 49%;
  
  ${({marginTop}) => marginTop && `
    &:nth-child(1) {
      margin-top: 0;
    }
    
    &:nth-child(2) {
      margin-top: 0;
    }
      margin-top: 1%;
    `}
  }

`;


