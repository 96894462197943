import { useUser } from 'context/user/User'
import useAuth from 'hooks/useAuth'
import React, { useEffect, useState } from 'react'
import TermPartner from './TermPartner';
import userApi from 'api/requests/user';
import Loader from 'components/_UI/Loader';
import PrivacyTermUse from './partner/PrivacyTermUse';
import { Column } from 'styled/alignment/Column';
import styled from 'styled-components';
import routeParams from 'theme/routeParams'
import { useSearchParams } from "react-router-dom";



export default function ContentTermUse() {
    const [searchParams, setSearchParams] = useSearchParams();
    const showCreate = searchParams.get(routeParams.partner.create);

    
    const { isAuth } = useAuth();

    const [role, setRole] = useState(null)
    const [loading, setLoading] = useState(false)
    const [createPartner, setCreatePartner] = useState(false)

    useEffect(() => {

        if(showCreate == 1) {
            setCreatePartner(true)
        }

        if(isAuth) {
            getMe()
        }
    }, [role, createPartner])

    async function getMe(){
        setLoading(true)
        try {
            const {data} = await userApi.me()
            setRole(data?.role?.id)
        } catch (error) {
            
        } finally {
            setLoading(false)
        }
    }
    

  return (
    <>
    {
        loading ? (
            <ContainerLoading>
                <Loader />
            </ContainerLoading>
        ) : (
            (role != import.meta.env.VITE_ESTABLISHMENT_ROLE_ID && role != import.meta.env.VITE_PRODUCER_ROLE_ID) ? (
                createPartner ? (
                    <TermPartner />
                ) : (
                    <PrivacyTermUse />
                )
            ) : (
                <TermPartner />
            )
        )
    }
</>

  )
}


const ContainerLoading = styled(Column)`
    height: 100vh;
    justify-content: center;
`;