import Text from 'components/_UI/Text';
import styled, {css} from 'styled-components';
import { Row } from 'styled/alignment/Row';
import marginProps from 'styled/shared-props/margin';
import paddingProps from 'styled/shared-props/padding';
import colors from 'theme/colors';

export const StyledSwitch = styled(Row)`
  width: 100%;
  ${marginProps}
  ${paddingProps}
`;
export const ContentSwitch = styled.label`
  position: relative;
  display: inline-block;
  height: 100%;
`;
export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;
export const TextLabel = styled.label`
  color: ${ props => props.labelColor};
  font-size: ${ props => props.labelSize};
  margin-left: 5px;
`;

export const Slider = styled.span`
  position: relative;
  display: inline-block;
  width: ${ props => props.size};
  height: 16px;
  background-color: #ccc;
  border-radius: 16px;
  transition: background-color 0.3s;
  cursor: pointer;


  &:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
  }

  ${SwitchInput}:checked + & {
    background-color: ${colors.occasionalPurple};

    &:before {
      transform: translateX(14px);
    }
  }
`;