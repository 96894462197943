import Text from 'components/_UI/Text';
import React, {useRef, useState} from 'react'
import { useForm } from 'react-hook-form';
import styled from 'styled-components'
import sizes from 'theme/sizes';
import CategoriesInfo from './parts/CategoriesInfo';
import { usePreferences } from 'context/user/Preferences';
import Button from 'components/_UI/Button';
import WeekDaysPreferences from './parts/WeekDaysPreferences';
import TimePreferences from './parts/TimePreferences';
import RecurrencePreferences from './parts/RecurrencePreferences';
import EventTypes from './parts/EventTypes';
import buttonText from 'texts/buttons/buttonText';
import Loader from 'components/_UI/Loader';
import { FullyCentered } from 'styled/alignment/Center';
import colors from 'theme/colors';
import fieldMessages from 'texts/messages/field';
import { Column } from 'styled/alignment/Column';
import preferencesApi from 'api/requests/preferences';

export default function PreferencesForm({setModalSucess, closeForm}) {
  const {preferences} = usePreferences()
  const [daysWeekError, setDaysWeekError] = useState(false);

  const [tagsError, setTagsError] = useState(false);
  const [timesError, setTimesError] = useState(false);
  const [recurrenceWeekError, setRecurrenceWeekError] = useState(false);
  const [recurrenceMonthError, setRecurrenceMonthError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [eventsTypesError, setEventsTypesError] = useState(false);

  const timeRef = useRef(null);
  const daysWeekRef = useRef(null);
  const eventsTypesRef = useRef(null);
  const tagsRef = useRef(null);

    const {
      handleSubmit,
      formState: { errors }
    } = useForm({});

    function getSelectedDays(selectedIndexes) {
      let result = ''
      selectedIndexes.map((el, index) => {
        if (index === 0) {
          result = el.name
          return
        }
         result += `, ${el.name}`
      })
      return result
    }


    function createPayload() {
      return {
        day_pref: getSelectedDays(preferences?.weekDays),
        time_pref: getSelectedDays(preferences?.times),
        month_recurrence: preferences?.recurrenceMonth,
        week_recurrence: preferences?.recurrenceWeek,
        event_type: preferences?.eventTypes,
        tags: preferences?.tags.map(el => el.id),
      }
    }

    function validateSelect(value, setError, errorMessage, scrollToRef) {
      if (!value  || value.length === 0) {
        setError(errorMessage);
        if (scrollToRef) {
          scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        return false;
      } else {
        setError('');
      }
      return true;
    }
    
    function isValidTags() {
      if (preferences?.tags?.length > 3) {
        setTagsError('max');
        return false;
      } else if (!preferences?.tags) {
        setTagsError('min');
        return false;
      } else {
        setTagsError('');
      }
      return true;
    }
    
    function isValidDaysWeek() {
      return validateSelect(preferences?.weekDays, setDaysWeekError, 'min', daysWeekRef);
    }
    
    function isTimesValid() {
      return validateSelect(preferences?.times, setTimesError, 'min', timeRef);
    }
    
    function isValidRecurrenceWeek() {
      return validateSelect(preferences?.recurrenceWeek, setRecurrenceWeekError, 'min');
    }
    
    function isValidRecurrenceMonth() {
      return validateSelect(preferences?.recurrenceMonth, setRecurrenceMonthError, 'min');
    }
    
    function isValidEventsTypes() {
      return validateSelect(preferences?.eventTypes, setEventsTypesError, 'min', eventsTypesRef);
    }
    
    async function createPreferences() {
      if (!isTimesValid() || !isValidDaysWeek() || !isValidRecurrenceWeek() || !isValidRecurrenceMonth() || !isValidEventsTypes() || !isValidTags()) {
        return;
      }
      const payload = createPayload();
      setIsLoading(true)

      try {
        await preferencesApi.create(payload)
        closeForm()
        setModalSucess(true)
      } catch (error) {
        setErrorMessage(fieldMessages.generic);
      } finally {
        setIsLoading(false)
      }
    }
  
    
  return (

    <FormContainer onSubmit={handleSubmit(createPreferences)}>
      <Text marginBottom={sizes.medium} name='subtitle'>Defina suas preferências </Text>

      <TimePreferences 
        ref={timeRef} 
        error={timesError}
      />

      <WeekDaysPreferences 
        ref={daysWeekRef} 
        error={daysWeekError} 
      />

      <RecurrencePreferences 
        errorWeek={recurrenceWeekError} 
        errorMonth={recurrenceMonthError}
      />

      <EventTypes 
        ref={eventsTypesRef} 
        error={eventsTypesError}
      />

      <CategoriesInfo 
        ref={tagsRef}
        error={tagsError}
      />

      <FullyCentered marginTop={sizes.micro}>
        <Text
          color={colors.danger}
          type='bold'
        >
          {errorMessage}
        </Text>
      </FullyCentered>

      {
        !isLoading ? (
          <FullyCentered>
            <ContentButton>
              <Button
                marginTop={sizes.normal}
                type='submit'
                size='medium'
              >
                {buttonText.confirm}
              </Button>
            </ContentButton>
          </FullyCentered>
        ) : ( <Loader />)
      }
    </FormContainer>

  )
}

const FormContainer = styled.form `
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
`;

const ContentButton = styled(Column)`
  width: 300px;
`;