import React, { createContext, useState, useContext } from 'react';

const CheckoutContext = createContext();

export default function CheckoutProvider({ children }) {
    const [checkout, setCheckout] = useState('');

    return (
        <CheckoutContext.Provider value={{ checkout, setCheckout }}>
            {children}
        </CheckoutContext.Provider>
    );
}

export function useCheckout() {
    const context = useContext(CheckoutContext);

    if (!context) throw new Error('useCheckout must be used within a CheckoutProvider');

    const { checkout, setCheckout } = context;
    return { checkout, setCheckout };
}