import React, { createContext, useState, useContext } from 'react';

const DetailVisibleContext = createContext();

export default function DetailVisibleProvider({ children }) {
    const [detailVisible, setDetailVisible] = useState(null);

    return (
        <DetailVisibleContext.Provider value={{ detailVisible, setDetailVisible }}>
            {children}
        </DetailVisibleContext.Provider>
    );
}

export function useDetailVisible() {
    const context = useContext(DetailVisibleContext);

    if (!context) throw new Error('useDetailVisible must be used within a DetailVisibleProvider');

    const { detailVisible, setDetailVisible} = context;
    return { detailVisible, setDetailVisible };
}