import Button from 'components/_UI/Button'
import ImageUpload from 'components/_UI/ImageUpload'
import Text from 'components/_UI/Text'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Column } from 'styled/alignment/Column'
import buttonText from 'texts/buttons/buttonText'
import colors from 'theme/colors'

export default function ImageInfo({ onToggleImage, onDeleteFile, errorImage, allowedFiles, setErrorImage, setErrorTypeImage, errorTypeImage, isEdit }) {
    const [tryAgain, setTryAgain] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {

    }, [errorImage, errorTypeImage])

    function toggleFile(files) {
        onToggleImage(files[0]);
    }

    return (
        <Column marginTop='16px'>
            <Text size='small' type='bold' color={colors.nightDark}>
                Imagem do evento
            </Text>
            <Text size='small' color={colors.night}>
                Selecione uma imagem para ser a capa do seu evento.
            </Text>

            <ImageUpload
                onToggleFile={toggleFile}
                onDeleteFile={() => onDeleteFile()}
                tryAgain={tryAgain}
                isEdit={isEdit}
                product
                refreshTryAgain={() => {
                    setTryAgain(!tryAgain)
                    setErrorImage(false)
                }}
            />
            <Column verticalCenter>
                <Text name='small' marginTop={'10px'}>Arquivos permitidos: {allowedFiles}.</Text>
            </Column>

            {
                errorImage && (
                    <Column marginTop='10px'>
                        <Text color={colors.danger} name='small'>Ocorreu um erro ao cadastrar a imagem</Text>
                        <Button
                            category='outline'
                            onClick={() => {
                                setTryAgain(true)
                                setErrorImage(false)
                            }}
                        >
                            {buttonText.tryAgain}
                        </Button>
                    </Column>
                )

            }

        {errorTypeImage && (
            <Column marginTop='10px' verticalCenter>
                <Text textAlign={'center'} name='small' color={colors.danger}>{errorTypeImage}</Text>
                <Button 
                    category='outline'
                    onClick={() =>  {
                        setTryAgain(true)
                        setErrorImage(false)
                        setErrorTypeImage(false)
                    }}
                > 
                    {buttonText.tryAgain}
                </Button>
            </Column>
        )}


        </Column>
    )
}