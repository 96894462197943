import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import fonts from 'theme/fonts';
import tabs from 'theme/tabs';
import indexPriority from 'theme/z-index';

export default function TopActions({
    onChangeTab = () => false
}) {
    const location = useLocation();

    const isEventTab = location.pathname.includes('/eventos');
    const isEstablishment = location.pathname.includes('/estabelecimentos');
    return (
        <Container>
            <TabsContainer>
                <StyledTab
                    active={isEventTab}
                    to={'eventos'}
                    onClick={() => onChangeTab(tabs.events.slug)}
                >
                    Eventos
                </StyledTab>
                <StyledTab
                    to={'estabelecimentos'}
                    active={isEstablishment}
                    onClick={() => onChangeTab(tabs.establishment.slug)}
                >
                    Estabelecimentos
                </StyledTab>
            </TabsContainer>
        </Container>
    )
}


const Container = styled(Row)`
    z-index: ${indexPriority.high};
    width: 100%;
    margin-top: 20px;
    display: flex;
    position: absolute;
    /* top: 2; */
    justify-content: center;
  
    @media screen and (min-width: ${breakpoints.lg}){
        position: fixed;                                                
        right: 0;
        top: 97px;
        margin-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 40%;
        background-color: ${colors.neutralBackground};
        height: 86px;



        &:before {
            content: '';
            position: fixed;
            /* left: 0; */
            z-index: -1;
            height: 86px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            /* background-color: ${colors.neutralBackground}; */
            /* box-shadow: 1px 1px 4px #ddd; */
        }
    }
`;

const TabsContainer = styled(Row)`
    border-radius: 30px;
    background: ${colors.neutral};
    width: auto;
    padding: 4px;
    box-shadow: 0 0 3px ${colors.nightLight};;
`;

const StyledTab = styled(Link)`
    padding: 6px 12px;
    transition: all ease .25s;
    border-radius: 30px;
    background: transparent;
    color: ${colors.secondaryLight};
    font-size: ${fonts.sizes.small};
    
    ${({ active }) => active && `
        background: ${colors.secondaryLight};
        color: ${colors.neutral};
        `}

    @media screen and (min-width: ${breakpoints.lg}){
    font-size: ${fonts.sizes.default};
    
    }
`;