import React, { createContext, useState, useContext } from 'react';

const UserLocaleContext = createContext();

export default function UserLocaleProvider({ children }) {
    const [userLocale, setUserLocale] = useState(null);

    return (
        <UserLocaleContext.Provider value={{ userLocale, setUserLocale }}>
            {children}
        </UserLocaleContext.Provider>
    );
}

export function useUserLocale() {
    const context = useContext(UserLocaleContext);

    if (!context) throw new Error('useUserLocale must be used within a UserLocaleProvider');

    const { userLocale, setUserLocale } = context;
    return { userLocale, setUserLocale };
}