import api from "api";

const establishments = {
    get: () => {
        return api().get('/establishments');
    },

    getForMap: (city, params) => {
        return api().get('/establishments', {
            params: {
                city: city,
                grouped: true,
                city_lat_long: 1,
                ...params
            }
        })
    },

    filter: (payload) => {
        return api().get('/establishments', {
            params: { ...payload }
        })
    },

    create: (payload) => {
        return api().post('/establishments', payload);
    },

    update: (slug, payload) => {
        return api().put(`/establishments/${slug}`, payload);
    },

    getOptions: () => {
        return api().get('/establishments/options');
    },

    my: () => {
        return api().get(`/establishments/my-establishments `);
    },

    getTags: (payload) => {
        return api().get('/tags-establishments')
    },

    getFeatures: () => {
        return api().get('/features')
    },

    getServiceOtions: () => {
        return api().get('/service-options')
    },

    getBySlug: (payload) => {
        return api().get(`/establishments/detail/${payload}`)
    },

    near: ({ lat, long }) => {
        return api().get(`/establishments/near/${Number(lat)}/${Number(long)}/5/5`)
    },

    allNears: ({ lat, long }) => {
        return api().get(`/establishments/near/${Number(lat)}/${Number(long)}/999999/5`)
    },

    findMultiples: ({ lat, long }) => {
        return api().get(`/establishments`, {
            params: { lat, long }
        })
    },

    getClicksStatistics: (slug) => {
        return api().get(`/establishments/detail/${slug}/view`)
    },

};

export default establishments;

