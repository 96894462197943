import { useEffect, useRef, useState } from 'react'
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import sizes from 'theme/sizes'
import colors from 'theme/colors'
import { Row } from 'styled/alignment/Row'
import { Column } from 'styled/alignment/Column'
import ticketsApi from 'api/requests/tickets'
import styled, { keyframes } from 'styled-components';
import breakpoints from 'theme/breakpoints'
import { QrReader } from "react-qr-reader";
import Button from 'components/_UI/Button'
import Fieldset from 'components/_UI/Fieldset'
import Loader from 'components/_UI/Loader'
import debounce from 'helpers/debounce';
import fieldValidations from 'helpers/fieldValidations'
import { useForm } from 'react-hook-form'

const borderAnimation = keyframes`
  0% {
    border-color: ${colors.successLight};
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: ${colors.successLight};
  }
`;

export default function TicketValidationModal({
  isOpen,
  onClose,
  onSucess = () => false,
  isScanning,
  selected,
  setSelected,
  setIsScanning,
  visibleField,
  setVisibleField,
  errorValidation = () => false
}) {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false)
  const [isCode, setIsCode] = useState(null)


  useEffect(() => {
    handleSelected('scan');
    setSelected(true)
    setIsScanning(true)
    setLoading(false)
    setVisibleField(false)
  }, [])

  const handleScanFile = (result, error) => {
    if (result) {
      setLoading(true)
      setIsScanning(false)
      const code = result?.text;
      if (code) {
        validateTicket(code);
      }
    }
    if (error) {
      console.error('Scan error:', error);
    }
  };

  function handleSelected(type) {

    if (type === 'scan') {
      setVisibleField(false)
      setSelected(true)
      setIsScanning(true)
      setLoading(true)
    }
    if (type === 'write') {
      setIsScanning(false)
      setSelected(true)
      setVisibleField(true)
      setLoading(true)
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);

  }

  async function validateTicket(code) {
   
    try {
      const { data } = await ticketsApi.checkIn({ code });
      onSucess(data?.data);

    } catch (error) {
      // if (error) {
      //   onError()
      //   setLoading(false)
      // }
      
      errorValidation(error?.response?.data?.message)
      
    } finally {
      setLoading(false)
      onClose()
    }
  }


  function ValidationForm() {
    return (
      <>
        <StyledForm onSubmit={handleSubmit(validateTicket)}>
          <Text
            type='bold'
            paddingTop={'10px'}
          >
            Aponte a câmera para o Qr Code
          </Text>
          <ModalWrapper>

            <ScannerWrapper>

              {loading ? (
                <Loader />
              ) : (
                  <StyledQrCode>
                    <QrReader
                      scanDelay={800}
                      constraints={
                        {
                          facingMode: "environment"
                        }
                      }
                      onResult={handleScanFile}
                    />
                  </StyledQrCode>
              )}
              <Button category='squired' marginTop={'20px'} variant='featured' onClick={() => onClose()}>Fechar</Button>
            </ScannerWrapper>

          </ModalWrapper>

        </StyledForm>
      </>
    );
  }





  return (
    <Column>
      <Modal isOpen={isOpen} onClose={onClose}>
        {
          ValidationForm()
        }
      </Modal>
    </Column>
  )
}

const ModalWrapper = styled(Column)`
  padding: 10px;
  align-items: center;
  width: 100%;

  @media screen and (min-width: ${breakpoints.sm}) {
      width: 320px;
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScannerWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;

 
`;

const StyledQrCode = styled(Column)`
  position: relative;
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-radius: 8px;
  border: 4px solid ${colors.successLight};
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1); 
  animation: ${borderAnimation} 1.5s infinite;

  &::after {
    bottom: 0;
    right: 0;
    border-width: 0 0 20px 20px;
    border-color: transparent transparent ${colors.successLight} ${colors.successLight};
  }

  & > section > div {
    padding-top: 0px !important;
    height: 100%;
    position: unset !important;
  }
  & > section > div > video {
    height: unset !important;
  }

  @media screen and (min-width: 402px) {
    height: 177px;
  }
`;


const ContentButton = styled(Column)`
  width: 100%;
  justify-content: center;
  align-items: center;
  & > button {
    width: 200px;
  }
`;