import Button from 'components/_UI/Button'
import ImageUpload from 'components/_UI/ImageUpload'
import ImageUploadMenu from 'components/_UI/ImageUploadMenu'
import Text from 'components/_UI/Text'
import React, { useState } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import buttonText from 'texts/buttons/buttonText'
import colors from 'theme/colors'

export default function ImageMenu({ onToggleImage, onDeleteFile, allowedFilesMenu, errorImageMenu, setErrorImageMenu, setErrorTypeMenu, errorTypeMenu, isEdit }) {
    const [tryAgain, setTryAgain] = useState(false)

    useEffect(() => {
      
    }, [errorImageMenu])

    function toggleFile(files) {
        onToggleImage(files[0]);
    }

    return (
        <ContainerMenu  >
             <Text size='small' marginTop={'10px'} marginBottom={'10px'} color={colors.occasionalPurple}>
                Imagem ou PDF do Cardápio (opcional)
            </Text>

            <ImageUploadMenu
                onToggleFile={toggleFile}
                onDeleteFile={() => onDeleteFile()}
                tryAgain={tryAgain}
                isEdit={isEdit}
                refreshTryAgain={() => {
                    setTryAgain(!tryAgain)
                    setErrorImageMenu(false)
                }}
            />

           

            <Column verticalCenter>
                <Text name='small' marginTop={'10px'}>Arquivos permitidos: {allowedFilesMenu}.</Text>
            </Column>

            {
                errorImageMenu && (
                    <Column marginTop='20px' verticalCenter>
                        <Text color={colors.danger} name='small'>Ocorreu um erro ao cadastrar a imagem</Text>
                        <Button
                            category='outline'
                            onClick={() => {
                                setTryAgain(true)
                                setErrorImageMenu(false)
                            }}
                        >
                            {buttonText.tryAgain}
                        </Button>
                    </Column>
                )

            }

            {errorTypeMenu && (
                <Column marginTop='10px' verticalCenter>
                    <Text textAlign={'center'} name='small' color={colors.danger}>{errorTypeMenu}</Text>
                    <Button 
                        category='outline'
                        onClick={() =>  {
                            setTryAgain(true)
                            setErrorImageMenu(false)
                            setErrorTypeMenu(false)
                        }}
                    > 
                        {buttonText.tryAgain}
                    </Button>
                </Column>
            )}


        </ContainerMenu>
    )
}

const ContainerMenu = styled(Column)`
    margin-top: 40px;
    align-items: center;
    
    & > div > div {
        width: 300px;
        border-radius: 4px;
        justify-content: center;
        flex-direction: row;
    }

    & > div {
        justify-content: center;
    }

    & > span > div > div > p {
        display: none;
    }
`