import Chips from 'components/_UI/Chips'
import Paginate from 'components/_UI/Paginate'
import Table from 'components/_UI/Table'
import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'

export default function WithdrawList({ data, perPage, totalItems, onPaginate, lastPage, currentPage}) {

    const widthDrawData = data?.data?.data?.map(item => {
        const date = new Date(item.created_at);
        return {
            id: `${item.id}`,
            date: `${date.toLocaleDateString('pt-BR')} - ${date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}`,
            value: `R$ ${parseFloat(item.value).toFixed(2).replace('.', ',')}`,
            status: <Chips text={item?.status} />, // Usando o componente Chips aqui
        };
    });

    return (
        <Container>
            <Text name='highlight'>Histórico de saques</Text>

            <Table
                rows={widthDrawData}
                columns={['ID', 'Data', 'Valor', 'Status']}
            />
            
            {
                lastPage > 1 &&
                <Paginate perPage={perPage} onPaginate={onPaginate} totalItems={totalItems} lastPage={lastPage} firstPage={1} currentPage={currentPage} marginTop={'30px'} />
            }
        </Container>
    );
}

const Container = styled(Column)`
    background-color: ${colors.neutralLight};
    border-radius: 8px;
    padding: 20px;
    margin-top: 10px;
    box-shadow: 0 2px 7px #ddd;
`;
