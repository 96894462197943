const currency = {
	format: (number) => {
		const
			currencyConfig = new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL'
			}),

			formatedCurrency = currencyConfig.format(number);

		return formatedCurrency;
	}
};

export default currency;
