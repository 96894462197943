import Text from 'components/_UI/Text';
import React, { useEffect, useState } from 'react'
import BasicInfo from './BasicInfo';
import styled from 'styled-components';
import Button from 'components/_UI/Button';
import ImageProfile from './ImageProfile';
import ImageBackground from './ImageBackground';
import colors from 'theme/colors';
import breakpoints from 'theme/breakpoints';
import { useForm } from 'react-hook-form';
import { Row } from 'styled/alignment/Row';
import Icon from 'components/_UI/Icon';
import imageUploadApi from 'api/requests/imageUpload';
import userApi from 'api/requests/user';
import { useUser } from 'context/user/User';
import { FullyCentered } from 'styled/alignment/Center';
import sizes from 'theme/sizes';
import Loader from 'components/_UI/Loader';
import { useCollapse } from 'context/general/Collapse';
import Users from '../Users';
import validateInputFile from 'helpers/validate-input-file';
import allowedFilesTypes from 'texts/messages/allowedFilesTypes';

export default function EditProfile({data, mobile, close = () => false}) {
  const [imagePath, setImagePath] = useState(null)
  const [backgroundPath, setBackgroundPath] = useState(null)
  const [errorImage, setErrorImage] = useState(false);
  const [errorImageBackground, setErrorImageBackground] = useState(false);
  const {user, setUser} = useUser()
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const {collapse, setCollapse} = useCollapse()
  const [isUpdateImage, setUpdateImage] = useState(false)
  const [errorTypeImageProfile, setErrorTypeImageProfile] = useState(false)
  const [errorTypeImageBackground, setErrorTypeImageBackground] = useState(false)
  

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: !data ? {} : {
      firstName: data?.first_name,
      lastName: data?.last_name,
      description: data?.bio,
      username: data?.username
    }
  })

  useEffect(() => {
    if(data) {
      // console.log(data);
      
      reset({
        id: data?.id,
        username: data?.username,
        first_name: data?.firstName,
        last_name: data?.lastName,
        bio: data?.description,
      });
      setBackgroundPath(data?.background_photo_id)
      setImagePath(data?.avatar_id)
    }
  }, [])
  


  async function uploadImage(file) {
    setUpdateImage(true)
    
    const validationFile = validateInputFile(file);

    if (!validationFile.isValid) {
      setErrorTypeImageProfile(validationFile.message);
      setUpdateImage(false);
      return
    }
    
    const formData = new FormData()
    formData.append('image', file)
    formData.append('folder', 'users_photo')

    try {
      const { data } = await imageUploadApi.uploadWithFolder(formData)
      setImagePath(data.id)
      
    } catch (error) {
      setErrorImage(true)
    } finally {
      setUpdateImage(false)
    }
  }

  async function uploadImageBackground(file) {
    setUpdateImage(true)

    const validationFile = validateInputFile(file);

    if (!validationFile.isValid) {
      setErrorTypeImageBackground(validationFile.message);
      setUpdateImage(false);
      return
    }
    

    const formData = new FormData();

    formData.append('image', file)
    formData.append('folder', 'users_background')
      try {
        const { data } = await imageUploadApi.uploadWithFolder(formData)
        setBackgroundPath(data.id)
      } catch (error) {
        setErrorImageBackground(true)

      } finally {
        setUpdateImage(false)
      }
  }

  async function onToggleDelete() {
    
    setErrorTypeImageProfile(false)
    setErrorImage(false)
    
    const id = imagePath
    
    try {
      await imageUploadApi.delete(id)
      setImagePath(null)
     
    } catch (error) {
      console.log(error);
    }
  }
  async function onToggleDeleteBackground() {
    
    setErrorTypeImageBackground(false)
    setErrorImageBackground(false)
    
    const id = backgroundPath
    
    try {
      await imageUploadApi.delete(id)
      setBackgroundPath(null)
      
    } catch (error) {
      console.log(error);
    }
  }


  const editProfile = async(content) => {
    setIsLoading(true)
    const payload = {
      id: data?.id,
      username: content?.username,
      first_name: content?.firstName,
      last_name: content?.lastName,
      bio: content?.description,
      avatar_id: imagePath,
      background_photo_id: backgroundPath
    };
    

    try {
      const {data} = await userApi.update(payload.id, payload)
      setUser(data?.data)
      if(!mobile) {
          setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => <Users home />
        })
      }
      if(mobile) {
        close()
      }
  

    } catch (error) {
      if(error) {
        setErrorMessage('Ocorreu um erro inesperado')
        return
      }
    } finally {
      setIsLoading(false)
    }
  }


  return (
    <ContainerForm onSubmit={handleSubmit(editProfile)}>

        {
          mobile && 
            <StyledButton>
              <ContentCloseButtom onClick={() => close()}>
                <Icon name='close'  color={colors.night}/>
              </ContentCloseButtom>
            </StyledButton>
        }

        <Text name='title' color={colors.nightDark} marginTop={'20px'}>Editar Perfil</Text>
     
        <ImageBackground 
          isEdit={data}
          onToggleImage={(e) => uploadImageBackground(e)}
          onDeleteFile={() => onToggleDeleteBackground()}
          errorImageBackground={errorImageBackground}
          errorTypeImageBackground={errorTypeImageBackground}
          setErrorImageBackground={setErrorImageBackground}
          setErrorTypeImageBackground={setErrorTypeImageBackground}
          allowedFiles={allowedFilesTypes.images}

        />  

        <ImageProfile 
          isEdit={data}
          // product
          onToggleImage={(e) => uploadImage(e)}
          onDeleteFile={() => onToggleDelete()}
          setErrorImage={setErrorImage}
          errorTypeImageProfile={errorTypeImageProfile}
          setErrorTypeImageProfile={setErrorTypeImageProfile}
          allowedFiles={allowedFilesTypes.images}
        />

        <BasicInfo 
          register={register} 
          errors={errors}
        />

        <FullyCentered marginTop={sizes.micro}>
          <Text
              color={colors.danger}
              type='bold'
          >
              {errorMessage}
          </Text>
        </FullyCentered>

        {
          !isLoading ? (
            <ContentButton>
              <Button
                category={'squired'}
                variant={isUpdateImage ? 'disabeld' : 'primary'}
                type="submit"
              >
                Editar perfil
              </Button>
            </ContentButton>
          ) : (
            <Loader />
          )
        }
    </ContainerForm>
  )
}

const ContainerForm = styled.form`
  padding: 10px 20px 20px 20px;
  background: ${colors.neutralBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentButton = styled.div`
  width: 100%;

  @media screen and (min-width: ${breakpoints.sm}) {
    width: 500px;
    margin-top: 20px;
  }

`; 

const StyledButton = styled.div`  
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ContentCloseButtom = styled(Row)`
  width: 30px;
  height: 30px;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid ${colors.nightLight};
  background: ${colors.neutralLight};
  cursor: pointer;


`;