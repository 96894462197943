const modal = {
    size: [
        'small',
        'medium',
        'large'
    ],

    align: [
        'left', 
        'center', 
        'right'
    ]
};

export default modal;