import Fieldset from 'components/_UI/Fieldset';
import Text from 'components/_UI/Text';
import fieldValidations from 'helpers/fieldValidations';
import React from 'react'
import styled from 'styled-components'
import { HorizontalCenter } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function BasicInfo({register, errors}) {
  return (
    <ContainerBasic>
        <ContentFields>
            <Text size='small' color={colors.nightDark} type='bold' >Informações básicas</Text>

            <Fieldset
                name='username'
                register={register}
                marginTop='10px'
                placeholder='Nome de usuário'
                marginBottom='10px'
                validations={fieldValidations.name('nome de usuário')}
            />
            {errors && (
                <HorizontalCenter marginTop={'10px'}>
                    <Text color={colors.danger}>{errors.username?.message}</Text>
                </HorizontalCenter>
            )}

            <Fieldset
                name='firstName'
                register={register}
                marginTop='10px'
                placeholder='Primeiro nome'
                validations={fieldValidations.name('primeiro nome')}
                marginBottom='10px'
            />
             {errors && (
                <HorizontalCenter marginTop={'10px'}>
                    <Text color={colors.danger}>{errors.firstName?.message}</Text>
                </HorizontalCenter>
            )}
            <Fieldset
                name='lastName'
                register={register}
                placeholder='Último nome'
                marginTop='10px'
                marginBottom='10px'
                validations={fieldValidations.name('último nome')}

            />
             {errors && (
                <HorizontalCenter marginTop={'10px'}>
                    <Text color={colors.danger}>{errors.lastName?.message}</Text>
                </HorizontalCenter>
            )}
            <Fieldset
                name='description'
                register={register}
                placeholder='Descrição'
                marginTop='10px'
                marginBottom='10px'
                validations={fieldValidations.description('descrição', 120)}
            />
            {errors && (
                <HorizontalCenter marginTop={'10px'}>
                    <Text color={colors.danger}>{errors.description?.message}</Text>
                </HorizontalCenter>
            )}
        </ContentFields>
    </ContainerBasic>
  )
}

const ContainerBasic = styled(Column)`
    width: 100%;
    margin-top: 20px;
    align-items: center;
`;

const ContentFields = styled(Column)`
    width: 100%;
    align-items: center;
    @media screen and (min-width: ${breakpoints.sm}) {
        width: 500px
    }
`