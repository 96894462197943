import React, { useEffect, useState } from 'react'
import Button from 'components/_UI/Button'
import styled from 'styled-components'
import breakpoints from 'theme/breakpoints';
import { useMyEstablishment } from 'context/establishments/MyEstablishment';
import { Row } from 'styled/alignment/Row';
import { Column } from 'styled/alignment/Column';
import { Edit, MapPin } from 'react-feather';
import Text from 'components/_UI/Text';
import colors from 'theme/colors';

export default function RightSlotProfile({ onEditProfile = () => false }) {
    const { myEstablishment } = useMyEstablishment()
    return (
        <RightSlotContainer>
            <ButtonContainer>
                {myEstablishment && myEstablishment?.length > 0 &&
                    <Button
                        marginBottom='0'
                        category='squiredBorded'
                        path={`/estabelecimentos?estabelecimento=${myEstablishment[0].slug}`}
                    >
                        <MapPin color={colors.neutral} size={15} />
                        <Text color={colors.neutral} marginLeft={'4px'} paddingBottom={0}>
                            Visualizar no mapa
                        </Text>
                    </Button>
                }

                {myEstablishment && myEstablishment?.length > 0 &&
                    <Button
                        marginBottom='0'
                        category='squiredBorded'
                        onClick={() => onEditProfile()}
                    >
                        <Edit color={colors.neutral} size={15} />
                        <Text color={colors.neutral} marginLeft={'4px'} paddingBottom={0}>
                            Editar informações
                        </Text>
                    </Button>
                }
            </ButtonContainer>
        </RightSlotContainer>
    )
}

const RightSlotContainer = styled.div`
    width: 100%;
    height: 100%;

    & > button {
        margin-bottom: 0 !important;
    }

    @media screen and (min-width: ${breakpoints.md}){
        width: auto;
    }

`;

const ButtonContainer = styled(Column)`
    width: auto;

    button, a {
        height: 35px;
        padding: 0;
        margin-top: 15px;

        &:first-child{
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
    @media screen and (min-width: ${breakpoints.md}){
        width: 330px;
        height: 100%;
        flex-direction: row;
       
        button, a {
            margin-top: 0;
            margin-bottom: 10px;
            height: 35px;
            padding: 0;
        }
        &:first-child{
            margin-right: 0;
            margin-bottom: 0;
        }
    }
    @media screen and (min-width: ${breakpoints.lg}){
        button, a {
            height: 35px;
        }
        /* margin-right: 11px; */
    }
    @media screen and (min-width: ${breakpoints.xl}){
        button, a {
            height: 35px;
        }
        margin-right: 0;
    }

    
`;