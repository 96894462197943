import Fieldset from 'components/_UI/Fieldset'
import RadioSelectButton from 'components/_UI/RadioSelectButton'
import Text from 'components/_UI/Text'
import { useEventFilter } from 'context/events/EventFilter'
import date from 'helpers/date'
import masks from 'helpers/masks'
import regex from 'helpers/regex'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HorizontalCenter } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import dateFilters from 'theme/filters';

export default function DateSelects() {
    const { eventFilter, setEventFilter } = useEventFilter();

    const [customDate, setCustomDate] = useState({
        from: date.format(new Date()),
        to: date.format(getTomorrow())
    })

    const [customFieldError, setCustomFieldError] = useState(false)

    useEffect(() => {
        setEventFilter({
            ...eventFilter,
            customFields: customDate
        })
    }, [])

    function getTomorrow() {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(today.getDate() + 1)

        return tomorrow
    }

    function validateDate(from, to) {
        setCustomDate({ from, to })
        return regex.date.test(from) && regex.date.test(to);
    }

    function validateCustomField({ from, to }) {
        if (!validateDate(from, to)) {
            setCustomFieldError(true)
            setEventFilter({ ...eventFilter, customFields: false })
            return
        }

        setCustomFieldError(false)
        setEventFilter({
            ...eventFilter,
            customFields: { from, to }
        })
    }


    return (
        <Column marginTop='18px'>
            <Text size='small' type='bold' marginLeft='6px'> Data </Text>
            <Text size='small' color={colors.night}>Escolha o período que deseja buscar.</Text>
            <RadioSelectButton
                data={dateFilters}
                onChangeActive={(item) => setEventFilter({ ...eventFilter, dates: item.value })}
            />

            {eventFilter?.dates === 'custom' && (
                <Column marginTop='10px'>
                    <Text size='small' color={colors.night}>Escolha um periodo que se encaixe na sua busca.</Text>
                    <CustomDateContainer>
                        <Text
                            type='bold'
                            marginRight='14px'
                            color={colors.night}
                        >
                            De
                        </Text>

                        <Column width='100px'>
                            <Fieldset
                                placeholder='data'
                                mask={masks.date}
                                value={customDate.from}
                                onChangeValue={(from) => validateCustomField({ ...customDate, from })
                                }
                            />
                        </Column>

                        <Text
                            type='bold'
                            marginRight='14px'
                            marginLeft='14px'
                            color={colors.night}
                        >
                            Até
                        </Text>

                        <Column width='100px'>
                            <Fieldset
                                placeholder='data'
                                mask={masks.date}
                                value={customDate.to}
                                onChangeValue={(to) => validateCustomField({ ...customDate, to })
                                }
                            />
                        </Column>
                    </CustomDateContainer>
                    {customFieldError && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> Informe um período válido</Text>
                        </HorizontalCenter>
                    )}
                </Column>
            )}
        </Column>
    )
}

const CustomDateContainer = styled(Row)`
    width: 100%;
    margin-top: 10px;
    align-items: flex-end;
    justify-content: center;
`;