import React, { createContext, useState, useContext } from 'react';

const ListProductsContext = createContext();

export default function ListProductsProvider({ children }) {
    const [listProducts, setListProducts] = useState([]);

    return (
        <ListProductsContext.Provider value={{ listProducts, setListProducts }}>
            {children}
        </ListProductsContext.Provider>
    );
}

export function useListProducts() {
    const context = useContext(ListProductsContext);

    if (!context) throw new Error('useListProducts must be used within a ListProductsProvider');

    const { listProducts, setListProducts } = context;
    return { listProducts, setListProducts };
}