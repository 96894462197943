import eventsApi from 'api/requests/events';
import Fieldset from 'components/_UI/Fieldset';
import debounce from 'helpers/debounce';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import breakpoints from 'theme/breakpoints';
import SearchDropdown from './filters/SearchDropdown';
import { useContainerClick } from 'context/dash/ContainerClick';

export default function EventFilters({hasEvents}) {
    const [nextEvents, setNextEvents] = useState(false)
    const [expiredEvents, setExpiredEvents] = useState(false)
    const [nowEvents, setNowEvents] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const [toogleDropdown, setToogleDropdown] = useState(false)

    const { containerClick, setContainerClick } = useContainerClick()

    useEffect(() => {
        if (containerClick) {
            setToogleDropdown(false)
        }
    }, [containerClick])

    async function filter(searchTerm) {

        setToogleDropdown(true)
        setIsloading(true)

        if (!searchTerm) {
            setToogleDropdown(false)
        }

        try {
            const { data: res } = await eventsApi.searchEvents(searchTerm)
            setExpiredEvents(res?.data?.expired)
            setNextEvents(res?.data?.next)
            setNowEvents(res?.data?.now)
        } catch (error) {
            console.log('error');
        } finally {
            setIsloading(false)
        }
    }


    const handleInput = debounce((searchTerm) => {
        searchTerm ? filter(searchTerm) : setToogleDropdown(false)
    }, 500)

    return (
        <FiltersContainer>
            {hasEvents && (
                <FilterWrapper>
                    <Fieldset
                        layout={'roundedBorded'}
                        placeholder='Procure suas informações'
                        badgeIcon='searchDark'
                        badgePosition='left'
                        textIndent='8px'
                        onChangeValue={(e) => handleInput(e)}
                    />
                    {
                        toogleDropdown &&
                        <SearchDropdown
                            closeDropdown={() => setToogleDropdown(false)}
                            isLoading={isLoading}
                            nextEvents={nextEvents}
                            expiredEvents={expiredEvents}
                            nowEvents={nowEvents}
                        />
                    }
                </FilterWrapper>
            )}
        </FiltersContainer>
    )
}


const FiltersContainer = styled(Column)`
    position: relative;
    width: 100%;
    height: 35px;
    display: flex;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: ${breakpoints.md}) {
        margin-bottom: unset;
    }
    @media screen and (min-width: ${breakpoints.lg}) {
        margin-bottom: unset;
        width: 100%;
        flex-direction: row;
        height: 35px;
        align-items: center;
        justify-content: flex-end;
        display: flex;       
        margin-right: 20px; 
    }
    @media screen and (min-width: ${breakpoints.xl}){
        margin-right: 0; 
    }
`;

const FilterWrapper = styled.div`
    width: 100%;
    position: relative;    


    & > div {
        padding-bottom: 0;
        width: 100%;

    }
    & > div > input {
        height: 35px;
        padding: unset;
        border-radius: 6px;
    }
    &:nth-child(2) {
        margin-top: 10px;
    }

    
    & > div > div > svg {
        /* background: red; */
        z-index: 1;
        margin-top: 15px
    }
    

    @media screen and (min-width: ${breakpoints.md}) {
        &:nth-child(1) {
            width: 282px;
            & > div > input {
                height: 35px;
                padding-bottom: 0;
                border-radius: 6px;
                width: 100%;            }
            & > div {
                padding-bottom: 0;
            }
        }
    }

    @media screen and (min-width: ${breakpoints.lg}){
        width: 286px;
        height: 35px;
        display: flex;
        align-items: center;
    }
   @media screen and (min-width: ${breakpoints.xl}){
       margin-right: 20px;
    }
`;
