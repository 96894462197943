import api from "api";

const collaborators = {
    get: () => {
        return api().get('/invite-collaborator',)
    },
    
    getList: (slug) => {
        return api().get(`/invite-collaborator/${slug}`,)
    },

    invite: (payload) => {
        return api().post('/invite-collaborator', payload)
    },

    editPermission: (id, payload) => {
        return api().put(`/invite-collaborator/${id}`, payload)
    },
    
    deletePermission: (id) => {
        return api().delete(`/invite-collaborator/${id}`)
    },

}

export default collaborators;