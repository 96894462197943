import { Container, IlustrationContainer, ContentNotResult, ContentButton } from 'styled/UI/NotResultDashBoard';
import React from 'react';
import Button from 'components/_UI/Button';
import Ilustration from 'components/_UI/Ilustration';
import Text from 'components/_UI/Text';


export default function NotResultDashBoard({
  ilustration,
  reverse,
  text,
  title,
  textButton,
  path,
  minimal = false,
  slot = () => false,
  onClick,
  marginTop,
  marginBottom,
}) {
  return (
    <Container
      reverse={reverse}
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      {
        !minimal && (
          <IlustrationContainer>
            <Ilustration name={ilustration} />
          </IlustrationContainer>
        )
      }

      <ContentNotResult minimal={minimal}>

        {title && <Text name='highlight'>{title}</Text>}
        <Text name='default'>{text}</Text>

        {slot && slot()}

        {textButton &&
          <ContentButton>
            <Button category='squired' variant='featured' onClick={onClick} path={path} marginTop={'20px'}>{textButton}</Button>
          </ContentButton>
        }


      </ContentNotResult>
    </Container>
  )
}
