import { useState, useEffect, } from 'react';
import { useForm, Controller, } from 'react-hook-form'
import styled from 'styled-components';
import Select from 'react-select';
import masks from 'helpers/masks';
import fieldValidations from 'helpers/fieldValidations';
import regex from 'helpers/regex';
import colors from 'theme/colors';
import breakpoints from 'theme/breakpoints';
import Text from 'components/_UI/Text'
import Fieldset from 'components/_UI/Fieldset'
import Button from 'components/_UI/Button';
import Radio from 'components/_UI/Radio';
import Loader from 'components/_UI/Loader';
import { Row } from 'styled/alignment/Row';
import { Column } from 'styled/alignment/Column';
import { useUser } from 'context/user/User';
import financialApi from 'api/requests/financial';
import BankAddressInfo from './BankAddressInfo';
import { Edit, Plus, Trash } from 'react-feather';

export default function BankForm({ visible, toast, closeCollapse }) {
    const {
        register,
        unregister,
        setValue,
        getValues,
        setError,
        clearErrors,
        handleSubmit,
        control,
        watch,
        reset,
        formState,
        formState: { isDirty, isSubmitting, errors, },
    } = useForm({ mode: 'onChange', });

    const { user, setUser } = useUser();
    const [loading, setLoading] = useState(false);
    const [accountId, setAccountId] = useState(null);
    const [address, setAddress] = useState({});
    const [banks, setBanks] = useState([]);
    const [bank, setBank] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [isCpf, setIsCpf] = useState(true); // true = cpf, false = cnpj
    const [isCurrentAccount, setIsCurrentAccount] = useState(true); // true = conta corrente, false = conta poupança
    const [isEditing, setIsEditing] = useState(false)
    const [isValid, setIsValid] = useState(false)

    const watchCep = watch('cep')

    useEffect(() => {
        setIsValid(Object.keys(errors).length === 0)
    }, [formState])

    function changeRadio() {
        setValue('cpf', getValues('cpf'), { shouldDirty: true })
    }

    async function getBanks() {
        try {
            const { data } = await financialApi.getBanks();
            const banks = data.data.map((bank) => ({ label: bank.name, value: bank.id }));

            return banks;
        } catch (error) {
            // log error
        }
    }

    async function getAccount() {
        try {
            const response = await financialApi.getBankAccount();

            return response.data;
        } catch (error) {
            // log error
        }
    }
    async function deleteBankAccount() {
        try {
            setLoading(true);

            await financialApi.deleteBankAccount(accountId);

            resetForm();
            setAccountId(null);
            closeCollapse();

            toast.success('Conta deletada com sucesso');
        } catch (error) {
            // log error
        } finally {
            setLoading(false);
        }
    }

    async function editBankAccount(data) {
        const { holder, digit, account, agency, cpf, bank } = data;
        try {
            setLoading(true);
            setIsEditing(true);

            const payload = {
                holder: holder,
                agency: agency.replaceAll(regex.nonDigit, ''),
                account: `${account}${digit}`,
                account_type: isCurrentAccount ? 'corrente' : 'poupanca',
                cpf_cnpj: isCpf ? 'cpf' : 'cnpj',
                cpf: cpf.replaceAll(regex.nonDigit, ''),
                bank_id: bank.value,
                address: {
                    postalcode: data.cep,
                    number: String(data.addressNumber),
                }
            };

            if (!isCpf) {
                payload.cnpj = data.cnpj.replaceAll(regex.nonDigit, '');
            }

            await financialApi.editBankAccount(accountId, payload);

            closeCollapse();

            toast.success('Conta editada com sucesso');
        } catch (error) {
            // log error
        } finally {
            setLoading(false);
        }
    }

    async function createBankAccount(data) {
        const { holder, digit, account, agency, cpf, bank, cep, addressNumber } = data;
        try {
            setLoading(true);

            const payload = {
                holder: holder,
                agency: agency.replaceAll(regex.nonDigit, ''),
                account: `${account}${digit}`,
                account_type: isCurrentAccount ? 'corrente' : 'poupanca',
                cpf_cnpj: isCpf ? 'cpf' : 'cnpj',
                cpf: cpf.replaceAll(regex.nonDigit, ''),
                bank_id: bank.value,
                address: {
                    postalcode: cep,
                    number: String(addressNumber),
                }
            };
            if (!isCpf) {
                payload.cnpj = data.cnpj.replaceAll(regex.nonDigit, '');
            }

            const { data: accounting } = await financialApi.registerBankAccount(payload);

            setAccountId(accounting.id);
            refetchUser();

            closeCollapse();

            toast.success('Conta criada com sucesso');
        } catch (error) {
            // log error
        } finally {
            setLoading(false);
        }
    }

    function refetchUser() {
        setUser({
            ...user,
            has_bank_account: true
        });
    }

    function resetForm() {
        reset({
            holder: '',
            cpf: '',
            bank: null,
            agency: '',
            account: '',
            digit: '',
            cnpj: '',
            cep: '',
            addressNumber: '',
            addressDistrict: '',
            addressStreet: '',
        })
        setAccountId(null)
    }

    async function setInitialData() {
        try {
            setLoading(true);
            const banks = await getBanks();
            setBanks(banks);

            const { data: accounting } = await getAccount();

            if (!accounting || !accounting.id) {
                return;
            }

            setIsEditing(true);

            const account = accounting.account.slice(0, -1); // REMOVE O DIGITO DA CONTA 
            const digit = accounting.account.slice(-1); // PEGA SOMENTE O DíGITO DA CONTA E FÉ

            setAccountId(accounting.id);
            setAddress(accounting.address);
            setValue('holder', accounting.holder);
            setValue('account', account);
            setValue('digit', digit);
            setValue('agency', accounting.agency);
            setValue('cpf', accounting.cpf);

            if (accounting.cnpj) {
                setValue('cnpj', accounting.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5"));
            }

            setIsCpf(accounting.cpf_cnpj === 'cpf');
            setIsCurrentAccount(accounting.account_type === 'corrente');

            const userBank = banks.find(bank => bank.value === accounting.bank_id);
            setValue('bank', userBank);

            setValue('cep', accounting.address.street.postalcode);
            setValue('addressNumber', accounting.address.number);
            setValue('addressDistrict', accounting.address.street.district);
            setValue('addressStreet', accounting.address.street.name);
        } catch (error) {
            console.log(error)
            // log error
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (visible) {
            setInitialData();
        }
    }, [visible]);

    useEffect(() => {
        if (isCpf) {
            setCnpj(getValues('cnpj'))
            unregister('cnpj')
        } else {
            register('cnpj')

            if (!getValues('cnpj')) {
                setValue('cnpj', cnpj)
            }
        }
    }, [isCpf])

    return (
        <>
            <Text type='bold' name='title' color={colors.occasionalPurple} textAlign={'center'} marginBottom={'1rem'}>
                Conta bancária
            </Text>

            {loading ?
                <Loader /> :
                <>
                    <Text type='bold' textAlign={'center'} >
                        Informações do titular
                    </Text>
                    <CustomRow>
                        <Column>
                            <Fieldset
                                placeholder='Nome do titular'
                                label='Nome do titular'
                                layout='squired'
                                name='holder'
                                register={register}
                                validations={fieldValidations.generic('Titular')}
                            />
                            {errors?.holder?.message && (
                                <Text color={colors.danger} marginLeft='10px' name='small'> {errors.holder?.message} </Text>
                            )}
                        </Column>

                        <Column>
                            <Fieldset
                                placeholder='CPF'
                                label='CPF'
                                layout='squired'
                                name='cpf'
                                mask={masks.cpf}
                                register={register}
                                validations={fieldValidations.cpf('CPF')}
                            />
                            {errors?.cpf?.message && (
                                <Text color={colors.danger} marginLeft='10px' name='small'> {errors.cpf?.message} </Text>
                            )}
                        </Column>
                    </CustomRow>



                    <Text type='bold' textAlign={'center'} marginTop={'30px'}>
                        Informações da conta
                    </Text>

                    <CustomRow>
                        <Column>
                            <Text name='small' color={colors.nightDark} type='bold' marginTop={'2px'}>
                                Banco
                            </Text>
                            <ListContainer>
                                <Controller
                                    control={control}
                                    name='bank'
                                    rules={{ required: true }}
                                    render={renderProps => {
                                        return (
                                            <Select
                                                placeholder='Banco'
                                                label='Banco'
                                                options={banks}
                                                defaultValue={bank}
                                                {...register('bank')}
                                                {...renderProps.field}
                                                onChange={(e) => renderProps.field.onChange(e)}
                                            // styles={{
                                            //     control: (baseStyles) => ({
                                            //         ...baseStyles,
                                            //         border: '2px solid #ccc',
                                            //         borderRadius: '30px',
                                            //     }),
                                            // }}
                                            />
                                        );
                                    }}
                                >
                                </Controller>
                                {errors?.bank && (
                                    <Text color={colors.danger} marginLeft='10px' marginTop='8px' name='small'> Selecione um banco </Text>
                                )}
                            </ListContainer>
                        </Column>

                        <Column>
                            <Fieldset
                                // marginLeft={'8px'}
                                placeholder='Agência'
                                label='Agência'
                                layout='squired'
                                name='agency'
                                mask={masks.bankAgency}
                                maskPlaceholder=''
                                masklabel=''
                                register={register}
                                validations={fieldValidations.bankAgency()}
                            />

                            {errors?.agency?.message && (
                                <Text color={colors.danger} marginLeft='10px' name='small'> {errors.agency?.message} </Text>
                            )}
                        </Column>
                        <Column>
                            <Fieldset
                                // marginLeft={'8px'}
                                placeholder='Conta'
                                label='Conta'
                                layout='squired'
                                name='account'
                                // mask={masks.bankAccount}
                                maskPlaceholder=''
                                masklabel=''
                                register={register}
                                validations={fieldValidations.bankAccount()}
                            />
                            {errors?.account?.message && (
                                <Text color={colors.danger} marginLeft='10px' name='small'> {errors.account?.message} </Text>
                            )}
                        </Column>

                        <Column>
                            <Fieldset
                                // marginLeft={'8px'}
                                placeholder='Dígito'
                                label='Dígito'
                                layout='squired'
                                name='digit'
                                mask={masks.bankAccount}
                                maskPlaceholder=''
                                masklabel=''
                                register={register}
                                validations={fieldValidations.bankDigit()}
                            />
                            {errors?.account?.message && (
                                <Text color={colors.danger} marginLeft='10px' name='small'> {errors.account?.message} </Text>
                            )}
                        </Column>
                    </CustomRow>

                    <CustomRow>
                        <RadiosContainer >
                            <Text>Tipo de entidade</Text>
                            <Row gap={'1rem'} fullyCentralized>
                                <Radio
                                    label='Pessoa Física'
                                    selected={isCpf}
                                    onSelect={() => { setIsCpf(true); changeRadio() }}
                                    marginTop={'0'}
                                />
                                <Radio
                                    label='Pessoa Jurídica'
                                    selected={!isCpf}
                                    onSelect={() => { setIsCpf(false); changeRadio() }}
                                    marginTop={'0'}
                                />
                            </Row>
                        </RadiosContainer>

                        <RadiosContainer marginTop='10px'>
                            <Text>Tipo de conta</Text>
                            <Row gap={'1rem'} fullyCentralized>
                                <Radio
                                    label='Corrente'
                                    selected={isCurrentAccount}
                                    onSelect={() => { setIsCurrentAccount(true); changeRadio() }}
                                    marginTop={'0'}
                                />
                                <Radio
                                    label='Poupança'
                                    selected={!isCurrentAccount}
                                    onSelect={() => { setIsCurrentAccount(false); changeRadio() }}
                                    marginTop={'0'}
                                />
                            </Row>
                        </RadiosContainer>
                    </CustomRow>

                    {
                        !isCpf && (
                            <CNPJField>
                                <Fieldset
                                    marginTop={'8px'}
                                    width='50%'
                                    placeholder='CNPJ'
                                    label='CNPJ'
                                    layout='squired'
                                    name='cnpj'
                                    mask={masks.cnpj}
                                    register={register}
                                    validations={fieldValidations.cnpj('CNPJ', !isCpf)}
                                />
                                {errors?.cnpj?.message && (
                                    <Text color={colors.danger} marginLeft='10px' name='small'> {errors.cnpj?.message} </Text>
                                )}
                            </CNPJField>
                        )
                    }

                    <BankAddressInfo
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        setError={setError}
                        clearErrors={clearErrors}
                        errors={errors}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        watchCep={watchCep}
                    />

                    <ButtonsContainer>
                        <Button
                            variant={(accountId && !isSubmitting) ? 'danger' : 'disabled'}
                            onClick={deleteBankAccount}
                        >
                            <Trash
                                size={20}
                                color={(accountId && !isSubmitting) ? colors.danger : colors.night}
                            />
                            <Text
                                color={(accountId && !isSubmitting) ? colors.danger : colors.night}
                                paddingBottom={0}
                                marginLeft={'8px'}
                                type='bold'
                            >
                                Deletar conta
                            </Text>
                        </Button>
                        <Button
                            variant={((!isDirty || !isValid) || isSubmitting) ? 'disabled' : 'primary'}
                            onClick={accountId ? handleSubmit(editBankAccount) : handleSubmit(createBankAccount)}
                        >
                            {
                                accountId
                                    ? <Edit size={20} color={((!isDirty || !isValid) || isSubmitting) ? colors.night : colors.neutral} />
                                    : <Plus size={20} color={((!isDirty || !isValid) || isSubmitting) ? colors.night : colors.neutral} />}

                            <Text
                                color={((!isDirty || !isValid) || isSubmitting) ? colors.night : colors.neutral}
                                paddingBottom={0}
                                marginLeft={'8px'}
                                type='bold'
                            >
                                {accountId ? 'Atualizar' : 'Criar'} conta
                            </Text>
                        </Button>
                    </ButtonsContainer>
                </>
            }
        </>
    )
}

const ListContainer = styled.div`
    flex-shrink: 0;
    flex-basis: 100%;
    margin-bottom: .5rem;

    @media screen and (min-width: ${breakpoints.md}) {
        flex-basis: 33%;
        margin-bottom: 0;
    }
`

const CustomRow = styled(Row)`
    flex-wrap: wrap;

    @media screen and (min-width: ${breakpoints.md}) {
        flex-wrap: nowrap;

        & > div {
            margin-left: 10px;
        }
        
        & > div:first-child {
            margin-left: 0;
        }
    }
`

const RadiosContainer = styled(Column)`
    width: auto;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 50%;
        margin-left: 0;
    }
`;

const ButtonsContainer = styled(Column)`
    margin-top: 10px;
    width: 100%;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        & > button:first-child {
            margin-right: 10px;
        }
    }
`;

const CNPJField = styled(Column)`
    width: 100%;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 50%;
    }
`;