import Button from 'components/_UI/Button'
import ImageUpload from 'components/_UI/ImageUpload'
import Text from 'components/_UI/Text'
import React, { useState } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import buttonText from 'texts/buttons/buttonText'
import colors from 'theme/colors'

export default function ImageInfo({ onToggleImage, errorTypeImageProfile, allowedFiles, onDeleteFile, isEdit, setErrorImageInfo, errorImageInfo, setErrorTypeImageProfile }) {
    const [tryAgain, setTryAgain] = useState(false)

    useEffect(() => {
       
    }, [errorImageInfo])

    function toggleFile(files) {
        onToggleImage(files[0]);
    }

    return (
        <Container>

            <Text size='small' marginTop={'10px'} marginBottom={'10px'} color={colors.occasionalPurple}>
                Logo do estabelecimento (opcional)
            </Text>

            <ImageUpload
                onToggleFile={toggleFile}
                onDeleteFile={() => onDeleteFile()}
                tryAgain={tryAgain}
                isEdit={isEdit}

                refreshTryAgain={() => {
                    setTryAgain(!tryAgain)
                    setErrorImageInfo(false)
                }}
            />

            
            <Column verticalCenter>
                <Text name='small' marginTop={'10px'}>Arquivos permitidos: {allowedFiles}.</Text>
            </Column>


            {
                errorImageInfo && (
                    <Column marginTop='20px' verticalCenter>
                        <Text color={colors.danger} name='small'>Ocorreu um erro ao cadastrar a imagem</Text>
                        <Button
                            category='outline'
                            onClick={() => {
                                setTryAgain(true)
                                setErrorImageInfo(false)
                            }}
                        >
                            {buttonText.tryAgain}
                        </Button>
                    </Column>
                )

            }

            {errorTypeImageProfile && (
                <Column marginTop='10px' verticalCenter>
                    <Text textAlign={'center'} name='small' color={colors.danger}>{errorTypeImageProfile}</Text>
                    <Button 
                        category='outline'
                        onClick={() =>  {
                            setTryAgain(true)
                            setErrorImageInfo(false)
                            setErrorTypeImageProfile(false)
                        }}
                    > 
                        {buttonText.tryAgain}
                    </Button>
                </Column>
            )}


        </Container>
    )
}

const Container = styled(Column)`
    margin-top: 40px;
    align-items: center;
    /* & > div {
        flex-direction: row;
        justify-content: center;
    } */
    & > div > div {
        /* width: 120px; */
        border-radius: 50%;
    }

    & > span > div > div > p {
        display: none;
    }
`
