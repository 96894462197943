import Button from 'components/_UI/Button';
import Fieldset from 'components/_UI/Fieldset';
import Loader from 'components/_UI/Loader';
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text';
import regex from 'helpers/regex';
import React, { useState } from 'react'
import styled from 'styled-components';
import { FullyCentered, HorizontalCenter } from 'styled/alignment/Center';
import { Row } from 'styled/alignment/Row';
import buttonText from 'texts/buttons/buttonText';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import addressApi from 'api/requests/address';
import { Column } from 'styled/alignment/Column';
import masks from 'helpers/masks';

export default function UserLocationModal({ handleUserLocation, visible }) {

    const [cepFilled, setCepFilled] = useState(false)
    const [addressLoading, setAddressLoading] = useState(false);
    const [address, setAddress] = useState(null);
    const [errors, setErrors] = useState(false);
    const [loadingSubmitError, setLoadingSubmitError] = useState(false);
    const [withoutNumber, setWithoutNumber] = useState(false);

    async function submitAddress() {
        setLoadingSubmitError(true)
        const payload = {
            postalcode: address?.street?.postalcode,
            number: address?.number
        }

        try {
            const { data } = await addressApi.submitUserAddress(payload)
            setErrors({ ...errors, errorSubmit: false })
            handleUserLocation(data)
            localStorage.setItem('userLocation', JSON.stringify(data))
        } catch (error) {
            setErrors({ ...errors, errorSubmit: true })
            localStorage.removeItem('userLocation')
        } finally {
            setLoadingSubmitError(false)
        }
    }

    function onChangeNumber(e) {

        const parsedValue = Number(e);

        if (isNaN(parsedValue)) {
            setErrors({ ...errors, number: 'O campo número deve conter apenas caracteres numéricos.' });
            setAddress({ ...address, number: false });
            return;
        }

        setErrors({ ...errors, number: false });
    }

    function validateCEP(cep) {
        return regex.cep.test(cep);
    }
    function verifyCep(e) {
        if (!validateCEP(e)) {
            return
        }
        const postalCodeNumbers = e.replace(regex.onlyNumber, '')
        if (postalCodeNumbers.length === 8) {
            getAddress(postalCodeNumbers)
            setCepFilled(true)
        }
    }

    async function getAddress(payload) {
        if (errors) {
            setErrors('')
        }
        setAddressLoading(true)
        try {
            const { data } = await addressApi.getByPostalCode(payload)
            setAddress(data)
        } catch ({ response }) {
            if (response?.data?.message) {
                setErrors({ ...errors, cep: response?.data?.message })
            }
            setAddress(false)
        } finally {
            setTimeout(() => {
                setAddressLoading(false)
            }, 500);
        }
    }

    return (
        <Modal
            isOpen={visible}
            closeButton={false}
        >
            <ModalContainer>
                {
                    loadingSubmitError
                        ? <Loader />
                        : (
                            !errors.errorSubmit ? (

                                <>
                                    <Text name='subtitle'> Informe sua localização atual </Text>
                                    <Fieldset
                                        placeholder='CEP *'
                                        mask={masks.cep}
                                        name='cep'
                                        marginTop={sizes.tiny}
                                        onChangeValue={(e) => verifyCep(e)}
                                    />
                                    {errors && (
                                        <HorizontalCenter>
                                            <Text color={colors.danger}> {errors?.cep} </Text>
                                        </HorizontalCenter>
                                    )}
                                    {address && (
                                        addressLoading
                                            ?
                                            (<Loader />)
                                            :
                                            (
                                                <AddressFields visible={cepFilled} withoutNumber={withoutNumber}>
                                                    <Fieldset
                                                        placeholder='Bairro'
                                                        value={address?.street?.district}
                                                        badgeText='Bairro'
                                                        variant={'disabled'}
                                                        marginTop={sizes.tiny}
                                                    />
                                                    <Fieldset
                                                        placeholder='Rua'
                                                        value={address?.street?.name}
                                                        badgeText='Rua'
                                                        variant={'disabled'}
                                                        marginTop={sizes.tiny}
                                                    />
                                                    <Row
                                                        marginTop={sizes.tiny}
                                                    >
                                                        <Fieldset
                                                            placeholder='Número'
                                                            marginBottom={sizes.medium}
                                                            variant={withoutNumber && 'disabled'}
                                                            onChangeValue={(e) => onChangeNumber(e)}
                                                            refresh={withoutNumber}
                                                        />
                                                    </Row>
                                                </AddressFields>
                                            )
                                    )}
                                    <Button
                                        marginTop={sizes.medium}
                                        variant={address ? 'primary' : 'disabled'}
                                        onClick={() => submitAddress()}
                                    >
                                        {buttonText.confirm}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Text> Ops... Ocorreu um erro inesperado </Text>
                                    <FullyCentered>
                                        <ButtonContainer>
                                            <Button onClick={() => setErrors({ ...errors, errorSubmit: false })}> Tente novamente </Button>
                                        </ButtonContainer>
                                    </FullyCentered>
                                </>
                            )
                        )

                }
            </ModalContainer>
        </Modal>
    )
}

const AddressFields = styled.div`
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-in;

    ${({ visible }) => visible && `
        visibility: visible;
        opacity: 1;
    `};

    ${({ visible, withoutNumber }) => visible && !withoutNumber && `
        height: 120px;
    `};

    ${({ visible, withoutNumber }) => visible && withoutNumber && `
        height: 200px;
    `};

`;

const ModalContainer = styled(Column)`
    padding: 20px 0;
    width: 100%;
`;

const ButtonContainer = styled.div`
    margin-top: 10px;
    width: 230px;
`;