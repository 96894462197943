import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import date from 'helpers/date';
import types from 'helpers/system/types';
import React from 'react'
import { Calendar } from 'react-feather';
import styled, { css } from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import eventTypes from 'theme/eventTypes';
import fonts from 'theme/fonts';

export default function CardFilter({data}) {

    const formatDate = dateContent => date.format(dateContent)
  return (
    <ContainerCard>
        <ContentImage>
            {data?.images?.length ? (
                <CardImage src={data?.images[0]} />
            ) : (
                <CardTextWrapper category={data?.classification?.name}>
                    <Text
                        color={colors.neutralLight}
                        type='bold'
                        name='title'
                    >
                        {data?.name?.charAt(0).toUpperCase()}
                    </Text>
                </CardTextWrapper>
                )
            }
        </ContentImage>

        <ContentData>
            <StyledRow>
                <Text 
                    name='small'
                    type='bold'
                    paddingBottom={'0'} 
                    paddingTop={'5px'}
                    ellipsisAt={'95%'}
                    color={colors.secondaryPurple}
                >
                {data?.name}
                </Text>
            </StyledRow>
            <StyledRow categories>
                {
                    <RowDates>
                        <Calendar size={'20px'} color={colors.nightLight} />
                        <Text name='small' marginLeft={'4px'} color={colors.primaryDashboard} paddingBottom={'0'}>{formatDate(data?.dates[0].date)}</Text>
                    </RowDates> 
                }
                <ContentClassification>
                    <EventType type={data.classification.name}>
                        {types.getBySlug(data.classification.name).label}
                    </EventType>
                </ContentClassification>
            </StyledRow>
        </ContentData>
    </ContainerCard>
  )
}

const ContainerCard = styled(Row)`
    border: 1px solid ${colors.primaryDashboard};
    border-radius: 4px;
    margin-top: 5px;
    height: 65px;
    position: relative;
`
const ContentImage = styled(Column)`
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: center;
`;

const ContentData = styled(Column)`
    width: 70%;
    display: flex;
    justify-content: center;
    padding-left: 4px;
`;

const StyledRow = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 0 4px 5px;
    position: relative;

    ${({categories}) => categories && `
        width: 100%;
        display: flex;
        padding-bottom: 5px;
        justify-content: space-around;
        align-items: center;
    `}
`;


const EventType = styled.div`
    display: flex;
    justify-content: center;
    width: 35px;
    color: ${colors.neutralLight};
    border-radius: 30px;
    padding: 4px 12px;
    font-size: ${fonts.sizes.small};
    position: absolute;
    bottom: 3px;
    right: -10px;
    @media screen and (min-width: 600px) {
        bottom: 1px;
        right: -18px;
    }
    @media screen and (min-width: ${breakpoints.md}) {
        bottom: 3px;
        right: -10px;
    }

    ${({ type }) => type === eventTypes.free.slug && `
        background: ${eventTypes.free.color};
        font-size: 10px;
        width: 45px;
    `}

    ${({ type }) => type === eventTypes.paid.slug && `
        background: ${eventTypes.paid.color};
        font-size: 10px;

    `}
`;
const ContentClassification = styled(Column)`
    display: flex;
    align-items: center;
    width: 120px;
`;

const RowDates = styled(Row)`
    display: flex;
    align-items: center;
`;

const ContentIconContainer = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CardTextWrapper = styled(FullyCentered)`
    text-align: center;
    height: 100%;

    & > h1 {
        padding-bottom: 0;
    }

    ${({ category }) => category === 'free' && css`
        background: ${eventTypes.free.color};
    `};

    ${({ category }) => category === 'private' && css`
        background: ${eventTypes.paid.color};
    `};

`;

const CardImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;