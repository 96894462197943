import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import breakpoints from 'theme/breakpoints';
import FiltersResult from './FiltersResult';
import Loader from 'components/_UI/Loader';
import colors from 'theme/colors';

export default function SearchDropdown({ closeDropdown, expiredEvents, nextEvents, nowEvents, isLoading }) {
  return (
    <Container>
      <ContentDropdown>
        {
          isLoading ?
            <StyledLoading>
              <Loader />
            </StyledLoading>
            : <FiltersResult
              closeDropdown={closeDropdown}
              expiredEvents={expiredEvents}
              nextEvents={nextEvents}
              nowEvents={nowEvents} />
        }
      </ContentDropdown>
    </Container>
  )
}

const Container = styled(Column)`
  display: flex;
  width: 100%;
  position: absolute;
  top: 37px;
  left: 0px;
  @media screen and (min-width: ${breakpoints.md}) {
    top: 2px;
  }
`;

const ContentDropdown = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  height: 300px;
  border-radius: 8px;
  background: #FFFDFD;
  top: 37px;
  max-height: 200px;
  padding: 5px;
  border: 1px solid ${colors.primaryDashboard};
  z-index: 1;
  @media screen and (min-width: ${breakpoints.md}) {
    width: 282px;
    overflow: auto;
    height: 300px;
    position: absolute;
    border-radius: 8px;
    background: #FFFDFD;
    max-height: 200px;
    padding: 5px;
    border: 1px solid ${colors.primaryDashboard};
    z-index: 1;
  }

`;

const StyledLoading = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;