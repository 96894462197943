import api from "api";

const tags = {
    // parametro serve para retornar o número de eventos de cada tag,por exemplo: 
    // 0 => sem o número de eventos ativos por tag
    // 1 => com o número de eventos ativos por tag 
    get: (with_events = 0) => {
        return api().get('/tags', { params: { with_events } });
    },
};

export default tags;  