import currency from "./currency";

const masks = {
    date: '99/99/9999',
    time: '99:99',
    phone: '(99) 99999-9999',
    postalCode: '999999-99',
    cpf: '999.999.999-99',
    cep: '99999-999',
    creditCard: '9999-9999-9999-9999',
    // confirmCode: ' 
    cnpj: '99.999.999/9999-99',
    dateExpiredCardCredit: '99/9999',
    bankAgency: '9999',
    bankAccount: '99999999-*'
};

export default masks;