import styled, { css } from 'styled-components';
import color from 'theme/colors';
import sizes from 'theme/sizes';
import fonts from 'theme/fonts';
import marginProps from 'styled/shared-props/margin';
import paddingProps from 'styled/shared-props/padding';
import { BrowserRouter as Router, Link} from "react-router-dom";

const transitionTime = '.3s';
const transitionType = 'ease';
const defaultTransition = `all ${transitionType} ${transitionTime}`;
const defaultShadow = `0 2px 3px ${color.nightLight}`;
const defaultBorder = (color) => `1px solid ${color}`;

export const StyledButton = styled.button`
    cursor: pointer;
    transition: ${defaultTransition};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${marginProps};
    ${paddingProps};
    
    /*=========================*/
    /*        Variantes        */
    /*=========================*/
    ${({ variant }) => variant === 'primary' && css`
        background: ${color.primary};
        border:  ${defaultBorder(color.primary)};
        color: ${color.neutral};
        &:hover {
            background: ${color.primaryDark};
            border: ${defaultBorder(color.primaryDark)};
        }
    `}

    ${({ variant }) => variant === 'secondary' && css`
        background: ${color.neutralLight};
        border: ${defaultBorder(color.neutralLight)};
        color: ${color.primary};
        &:hover {
            background: ${color.neutral};
            border:  ${defaultBorder(color.neutral)};
        }
    `}
    
    ${({ variant }) => variant === 'danger' && css`
        background: ${color.neutralLight};
        border: ${defaultBorder(color.neutralLight)};
        color: ${color.danger};
        &:hover {
            background: ${color.neutral};
            border:  ${defaultBorder(color.neutral)};
        }
    `}

    ${({ variant }) => variant === 'disabled' && css`
        pointer-events: none;
        background: ${color.neutralLight};
        border: ${defaultBorder(color.nightLight)};
        color: ${color.nightLight};
    `}
    
    ${({ variant }) => variant === 'orange' && css`
        background: ${color.occasionalOrange};
        border: ${defaultBorder(color.occasionalOrange)};
        color: ${color.neutral};
        box-shadow: unset !important;
    `}

    ${({ variant }) => variant === 'dark' && css`
        background: ${color.secondaryLight};
        border: ${defaultBorder(color.secondaryLight)};
        color: ${color.neutralLight};
        &:hover {
            background: ${color.secondary};
            border: ${defaultBorder(color.secondary)};
        }
    `}

    ${({ variant }) => variant === 'featured' && css`
        background: ${color.secondaryPurple};
        border: ${defaultBorder(color.secondaryPurple)};
        color: ${color.neutralLight};
        &:hover {
            opacity: .9;
        }
    `}

    ${({ variant }) => variant === 'light' && css`
        background: ${color.neutralLight};
        border: ${defaultBorder(color.night)};
        color: ${color.night};
        &:hover {
            background: ${color.neutral};
            border: ${defaultBorder(color.nightDark)};
        }
    `}

    ${({ variant }) => variant === 'promoted' && css`
        background: ${color.neutralLight};
        border: ${defaultBorder(color.neutralLight)};
        color: ${color.success};
        &:hover {
            background: ${color.neutral};
            border: ${defaultBorder(color.neutral)};
        }
    `}

    ${({ variant }) => variant === 'success' && css`
        background: ${color.success};
        border: ${defaultBorder(color.success)};
        color: ${color.neutralLight};
        &:hover {
            background: ${color.successLight};
            border: ${defaultBorder(color.successLight)};
        }
    `}

    ${({ variant }) => variant === 'successBorded' && css`
        background: ${color.neutralLight};
        border: ${defaultBorder(color.success)};
        color: ${color.success};
        &:hover {
            color: ${color.successLight};
            border: ${defaultBorder(color.successLight)};
        }
    `}

    ${({ variant }) => variant === 'lightBlue' && css`
        background: ${color.primaryLight};
        border: ${defaultBorder(color.primaryLight)};
        color: ${color.neutralLight};
        &:hover {
            opacity: .9;
        }
    `}

    ${({ variant }) => variant === 'darkBlue' && css`
        background: ${color.occasionalBlue};
        border: ${defaultBorder(color.occasionalBlue)};
        color: ${color.neutralLight};
        &:hover {
            opacity: .9;
        }
    `}

    ${({ variant }) => variant === 'yellow' && css`
        background: ${color.goldLight};
        border: ${defaultBorder(color.goldLight)};
        color: ${color.neutralLight};
        &:hover {
            opacity: .9;
        }
    `}

    ${({ variant }) => variant === 'pink' && css`
        background: ${color.occasionalPink};
        border: ${defaultBorder(color.occasionalPink)};
        color: ${color.neutralLight};
        &:hover {
            opacity: .9;
        }
    `}
    
    ${({ variant }) => variant === 'yellow-borded' && css`
        background: transparent;
        border: ${defaultBorder(color.goldLight)};
        color: ${color.goldLight};
        &:hover {
            opacity: .9;
            background: ${color.goldLight};
            color: ${color.neutralLight};
        }
    `}

    ${({ variant }) => variant === 'pink-borded' && css`
        background: transparent;
        border: ${defaultBorder(color.occasionalPink)};
        color: ${color.occasionalPink};
        &:hover {
            opacity: .9;
            background: ${color.occasionalPink};
            color: ${color.neutralLight};
        }
    `}
    
    /*=========================*/
    /*        Categorias       */
    /*=========================*/
    ${({ category }) => category === 'rounded' && css`
        border-radius: 50px;
        box-shadow: ${defaultShadow};
        font-weight: ${fonts.weight.bold};
    `}

    ${({ category }) => category === 'roundedBorded' && css`
        border-radius: 50px;
        box-shadow: none;
        font-weight: ${fonts.weight.bold};
    `}

    ${({ category }) => category === 'squired' && css`
        border-radius: 6px;
        font-weight: ${fonts.weight.bold};
        box-shadow: ${defaultShadow};
    `}

    ${({ category }) => category === 'squiredBorded' && css`
        border-radius: 6px;
        font-weight: ${fonts.weight.bold};
        box-shadow: none;
    `}

    ${({ category }) => category === 'minimal' && css`
        box-shadow: none;
        font-weight: ${fonts.weight.bold};
        text-align: center;
        background: transparent !important;
        border: none !important;
        color: ${color.neutralDark};
        padding: ${sizes.quark};
        width: 100%;
        &:hover {
            border: 0 !important;
        }
    `}

    ${({ category }) => category === 'minimalDark' && css`
        box-shadow: none;
        font-weight: ${fonts.weight.bold};
        text-align: center;
        background: transparent !important;
        border: none !important;
        color: ${color.nightDark};
        padding: ${sizes.quark};
        width: 100%;
        &:hover {
            border: 0 !important;
        }
    `}
    
    ${({ category }) => category === 'outline' && css`
        box-shadow: none;
        font-weight: ${fonts.weight.regular};
        background: transparent !important;
        border: ${sizes.none};
        text-decoration: underline;
        color: ${color.primary};
        padding: ${sizes.quark} !important;
        width: 100%;
        &:hover {
            border: ${sizes.none} !important;
        }
    `}

    /*=========================*/
    /*         Tamanhos        */
    /*=========================*/
    ${({ size }) => size === 'small' && css`
        padding: ${sizes.quark} ${sizes.nano};
        font-size: ${fonts.sizes.small};
    `}

    ${({ size }) => size === 'medium' && css`
        padding: ${sizes.mini} ${sizes.medium};
        font-size: ${fonts.sizes.default};
    `}

    ${({ size }) => size === 'default' && css`
        padding: ${sizes.tiny} ${sizes.large};
        font-size: ${fonts.sizes.default};
    `}

    ${({ size }) => size === 'large' && css`
        padding: ${sizes.little} ${sizes.normal};
        font-size: ${fonts.sizes.highlight};
    `}
`;

export const StyledRouteLink = styled(Link)`
    width: 100%;
`

StyledButton.defaultProps = {
    marginBottom: sizes.little
}