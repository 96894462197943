import Text from 'components/_UI/Text';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import { useEventDetailTab } from 'context/dash/EventDetailTab';
import currency from 'helpers/currency';
import InfoTooltip from 'components/_UI/InfoTooltip';

export default function TicketCardMinimal({ data, onTicketClick = () => false }) {

    const [status, setStatus] = useState(false);

    useEffect(() => {
        let available = false;
        let soldOut = false;
        let unavailable = false;

        data?.batches?.forEach((el) => {
            if (el?.quantity_available > 0 && el?.is_active == 1) {
                available = true;
            }

            if (el?.quantity_available === 0 && el?.is_active == 1) {

                soldOut = true;
            }

            if (el?.is_active === 0 && el?.quantity_available > 0) {

                unavailable = true;
            }
        });

        if (available) {
            setStatus('Disponível');
            return;
        }

        if (soldOut && !available) {
            setStatus('Esgotado');
            return;
        }

        if (unavailable && !available && !soldOut) {
            setStatus('Inativo');
        }
    }, [data]);




    const renderLots = () => {
        return data?.batches?.slice(0, 5).map((el, index) => {

            let batchStatus = '';

            if (el.quantity_available > 0 && el.is_active === 1) {
                batchStatus = 'Disponível';
            }
            if (el.quantity_available === 0) {
                batchStatus = 'Esgotado';
            }
            if (el.is_active === 0) {
                batchStatus = 'Inativo';
            }

            return (
                <InfoTooltip
                    bgColor={colors.night}
                    text=<>Lote {index + 1}
                        <br></br>
                        Valor: {el.price != 0 ? currency.format(el.price) : 'Gratuito'}
                        <br></br>
                        Quantidade: {el.quantity_available.toLocaleString('pt-BR')}
                        <br></br>
                        Status: {batchStatus}
                    </>
                    type='none'
                >
                    <Lots
                        key={el.id}
                        zindex={index}
                        status={batchStatus}
                    >
                        <Text paddingBottom={'0'} name='small'>{index + 1}</Text>
                    </Lots>
                </InfoTooltip>

            );
        });
    };

    return (
        <Container status={status} onClick={(e) => onTicketClick(e)}>
            <StyledBackground src='/assets/images/ticket-background.png' />
            <StyledMain>
                {/* <ContentLeft> */}
                <TextStatus status={status}>{status}</TextStatus>
                {/* <ContentText> */}
                <Text
                    color={colors.coralGrey}
                    ellipsisAt={'95%'}
                    paddingBottom={0}
                    marginBottom={'10px'}
                    marginTop={'10px'}
                    type='bold'
                >
                    {data?.name}
                </Text>
                {/* </ContentText> */}
                {/* </ContentLeft> */}

                {/* <ContentRight> */}
                <Text color={colors.coralGrey} name='small'>Lotes</Text>

                <ContentLots>
                    {renderLots()}
                    {data?.batches?.length > 6 && (
                        <ContentTolTipMore>

                            <InfoTooltip
                                bgColor={colors.coralGrey}
                                text={`+${data?.batches?.length - 5} lotes`}
                                type='none'
                            >
                                <MoreLots>
                                    <Text paddingBottom={'0'} color={colors.coralGrey} name='small'>
                                        +{data?.batches?.length - 5}
                                    </Text>
                                </MoreLots>
                            </InfoTooltip>
                        </ContentTolTipMore>
                    )}
                </ContentLots>
                {/* </ContentRight> */}
            </StyledMain>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    /* height: 100px; */
    width: 100%;
    align-items: center;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    transition: all ease-in-out 0.25s;
    border: 1px solid  ${colors.nightLight};
    box-shadow: 0 2px 7px #ddd;
    border-radius: 6px;
    /* background-image: url(''); */
    /* background-color: ${colors.neutralLight}; */
    
    &:hover {
        opacity: .85;
        transform: scale(1.025);
    }

    /* @media screen and (min-width: ${breakpoints.lg}) {
    } */
`;

const StyledBackground = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 6px;
`;

const StyledMain = styled.div`
    width: 100%;
    /* height: 120px; */
    display: flex;
    flex-direction: column;
    padding: 10px 25px;
    /* justify-content: space-between; */
`;


const ContentLots = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &  > div {
        & > :nth-child(2) {
            bottom: -120% !important;
            transform: translateX(-20%);
            right: 0;
            left: 220%;
            &::after {
                transform: translateX(0%);
                left: -8%;
                top: 50%;
                transform: rotate(90deg);
            }
        }
    }
`;

const Lots = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    margin-top: -4px;
    justify-content: center;
    align-items: center;
    margin-left: -7px;
    z-index: ${({ zindex }) => zindex};
    transition: all ease-in-out .25s;

    &:hover {
        transform: scale(1.1);
    }

    ${({ status }) =>
        status === 'Disponível' &&
        `
        background: #7EFF7B;
        border: 1px solid ${colors.nightLight};

        & > p {
            color: ${colors.night} !important;
        }
    `}
    
    ${({ status }) =>
        status === 'Esgotado' &&
        `
        background: ${colors.danger};
        border: 1px solid ${colors.danger};
        & > p {
            color: ${colors.neutralDark} !important;
        }
    `}
    ${({ status }) =>
        status === 'Inativo' &&
        `
        background: #BCBCBC;
        border: 1px solid #00000033;
        // opacity: .7;
        & > p {
            color: #00000033;
        }
    `}
`;

const MoreLots = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    margin-top: -4px;
    justify-content: center;
    align-items: center;
    margin-left: -7px;
    z-index: 15;
    background: ${colors.primaryLight};
    border: 1px solid ${colors.night};
    transition: all ease-in-out .25s;
    &:hover {
        transform: scale(1.1);
    }
    & > p {
        color: ${colors.neutralLight};
    }
`;

const TextStatus = styled.p`
    font-size: 10px;
    text-transform: capitalize;
    font-weight: bold;
    color: ${colors.coralGrey};
`;

const TextPrice = styled.p`
    padding-bottom: 0px;
    margin-top: 5px;
    font-weight: bold;
    color: ${colors.neutral};
    ${({ status }) =>
        status === 'Esgotado' &&
        `
        text-decoration: line-through;
        color: ${colors.neutral};
    `}
    ${({ status }) =>
        status === 'Inativo' &&
        `
        text-decoration: line-through;
        color: ${colors.neutral};
    `}
    ${({ status }) =>
        status === 'Disponível' &&
        `
        text-decoration: normal;
        ${colors.neutral};
    `}
`;


const ContentTolTipMore = styled.div`
    margin-top: -1px;
    @media screen and (min-width: ${breakpoints.lg}) {
        margin-top: -3px;
    }
    &  > div {
        & > :nth-child(2) {
            bottom: -40% !important;
            transform: translateX(-20%);
            right: 0;
            left: 180%;
            &::after {
                transform: translateX(0%);
                left: -14%;
                top: 40%;
                transform: rotate(90deg);
            }
        }
    }
`;