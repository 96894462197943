import React from 'react'
import styled from 'styled-components'
import colors from 'theme/colors'
import fonts from 'theme/fonts'
import sizes from 'theme/sizes'

export default function TextArea({
    name,
    register,
    validations,
    squired = false,
    marginTop,
    ...props
}) {
    return (
        <StyledTextArea
            squired={squired}
            {...props}
            {...register(name, validations)}
            marginTop={marginTop}
        />
    )
}


const StyledTextArea = styled.textarea`
    width: 100%;
    resize: none;
    border: 2px solid ${colors.nightLight};
    border-radius: 60px;
    padding: ${sizes.normal} ${sizes.mini};
    font-weight: ${fonts.weight.bold};
    background: transparent;
    color: ${colors.night};
    outline: none;

    ${({marginTop}) => marginTop && `
        margin-top: ${marginTop};
    `};
    
    ${({squired}) => squired && `
        border: 1px solid ${colors.nightLight};
        border-radius: 4px !important;

        &:focus {
            border: ${sizes.preon} solid ${colors.night} !important; 
        }
    `};

    &:focus {
        border: ${sizes.quark} solid ${colors.night};
    }


    &::placeholder {
        color: ${colors.nightLight};
    }
`