import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import date from 'helpers/date'
import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'

export default function CronogramModal({data, visible, setVisible, }) {
  return (
    <Modal isOpen={visible} onClose={() => setVisible(false)}>
        <Container>
            {data.map((el, index) => (
                <Item key={el.date}>  
                    <Text name='subtitle'> Dia {index + 1} </Text>
                    <Text> <b>Data:</b> {date.getDay(el.date)} de {date.getMounthName(el.date)} </Text>
                    <Text> 
                        <b>Horário </b> {date.getTimeWithoutSeconds(el.start)} 
                        {el.end ? ` até ${date.getTimeWithoutSeconds(el.end)}` : ''} 
                    </Text>
                </Item>
            ))}
        </Container>
    </Modal>
  )
}

const Container = styled(Row)`
    flex-wrap: wrap;
    align-items: space-around;
    padding: 20px;
`;

const Item = styled(Column)`
    border: 2px solid ${colors.nightLight};
    padding: 10px 0;
    border-radius: 8px;
    width: 100%;
    margin-top : 10px;
`;