import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import colors from 'theme/colors';
import Text from './Text';
import { FullyCentered } from 'styled/alignment/Center';
import { Row } from 'styled/alignment/Row';
import Icon from './Icon';
import breakpoints from 'theme/breakpoints';

export default function ImageUploadBackground({ onToggleFile, onDeleteFile,  tryAgain, refreshTryAgain, isEdit, resetForm }) {
    const [dropzoneVisible, setDropzoneVisible] = useState(true);
    const [files, setFiles] = useState([]);
    const [isDelete, setIsDelete] = useState(false);
    const [editImage, setEditImage] = useState(null);
    const prevFilesRef = useRef([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            const updatedFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
            setFiles(updatedFiles);
            setDropzoneVisible(false);
            setEditImage(null);
            onToggleFile(updatedFiles);
        },
    });

    const deleteFile = () => {
        setDropzoneVisible(true);
        setFiles([]);
        setEditImage(null);
        onDeleteFile();
        setIsDelete(true);
    };

    useEffect(() => {
        return () => {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        };
    }, [files]);

    useEffect(() => {
        if (tryAgain) {
            setDropzoneVisible(true);
            setFiles([]);
            refreshTryAgain();
        }
    }, [tryAgain]);

    useEffect(() => {

        if(isEdit.backgroundImage) {
            setEditImage(isEdit?.backgroundImage.thumb);
            setDropzoneVisible(false);
        } else {
            setEditImage(null); 
            setFiles([]); 
            setDropzoneVisible(true); 
        }

        if(!isEdit.backgroundImage) {

            if (isEdit?.background_photo) {
                setEditImage(isEdit?.background_photo);
                setDropzoneVisible(false);
            } else {
                setEditImage(null); 
                setFiles([]); 
                setDropzoneVisible(true); 
            }
        }
    }, [isEdit]);

    useEffect(() => {
        if (resetForm) {
            setEditImage(null);
            setFiles([]);
            setDropzoneVisible(true);
        }
    }, [resetForm]);

    const renderThumbs = () => {
        if (editImage) {
            return (
                <Thumb key="edit-image">
                    <CloseThumb>
                        <CloseThumbIcon onClick={deleteFile}>
                            <Icon name="close" color={colors.night} />
                        </CloseThumbIcon>
                    </CloseThumb>
                    <ThumbInner>
                        <ThumbImage src={editImage} />
                    </ThumbInner>
                </Thumb>
            );
        } else if (files.length > 0) {
            return files.map((file) => (
                <Thumb key={file.name}>
                    <CloseThumb>
                        <CloseThumbIcon onClick={deleteFile}>
                            <Icon name="close" color={colors.night} />
                        </CloseThumbIcon>
                    </CloseThumb>
                    <ThumbInner>
                        <ThumbImage src={file.preview} />
                    </ThumbInner>
                </Thumb>
            ));
        } else {
            return (
                <Dropzone {...getRootProps()}>
                    <DropzoneContent>
                        <input {...getInputProps()} />
                        <IconContainer>
                            <Icon name="camera" color={colors.night} />
                        </IconContainer>
                    </DropzoneContent>
                </Dropzone>
            );
        }
    };

    return (
        <ThumbsContainer>{renderThumbs()}</ThumbsContainer>
    );
}

const Dropzone = styled(FullyCentered)`
    width: 100%;
    height: 160px;
    padding: 15px;
    border: dashed 2px ${colors.nightLight};
    border-radius: 4px;
    background: ${colors.neutralLight};
    cursor: pointer;
    transition: all ease-in-out 0.25s;

    @media screen and (min-width: ${breakpoints.sm}) {
        margin-left: 10px;
    }
    &:hover {
        border: dashed 2px ${colors.secondary};
    }
`;

const ThumbsContainer = styled(Row)`
    flex-wrap: wrap;
`;

const Thumb = styled.div`
    display: inline-flex;
    border-radius: 4px;
    margin-bottom: 8px;
    width: 100%;
    height: 160px;
    padding: 4px;
    position: relative;
`;

const IconContainer = styled.div`
    width: 46px;
    height: 46px;
`;

const ThumbInner = styled.div`
    display: flex;
    min-width: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const ThumbImage = styled.img`
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
`;

const CloseThumb = styled(FullyCentered)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${colors.nightDark};
    transition: all ease-in-out 0.25s;
    opacity: 0.8;
    border-radius: 4px;
`;

const CloseThumbIcon = styled.div`
    width: 30px;
    height: 30px;
    background: ${colors.neutralDark};
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
`;

const DropzoneContent = styled(FullyCentered)`
    flex-direction: column;
    padding: 30px;
`;
