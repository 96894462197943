import PropTypes from 'prop-types';
import { StyledSvg } from 'styled/UI/StyledSvg';
function Icon({
    // layout props
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    // action props
    name,
    color,
}) {
    return (
        <StyledSvg 
            fill={color}
            marginTop={marginTop}
            marginRight={marginRight}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
        >
            <use href={`../../../assets/svg/icons.svg#${name}`} />
        </StyledSvg>
    )
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
};

Icon.defaultProps = {
    color: '#ccc'
};

export default Icon