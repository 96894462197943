import Button from 'components/_UI/Button'
import ImageUploadBackground from 'components/_UI/ImageUploadBackground'
import Text from 'components/_UI/Text'
import React, { useState } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import buttonText from 'texts/buttons/buttonText'
import breakpoints from 'theme/breakpoints'
import colors from 'theme/colors'

export default function ImageBackground({ onToggleImage, onDeleteFile, setErrorTypeImageBackground, errorTypeImageBackground, allowedFiles, setErrorImageBackground, errorImageBackground, isEdit}) {
    const [tryAgain, setTryAgain] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
    }, [errorImageBackground, errorTypeImageBackground])

    function toggleFile(files) {
        onToggleImage(files[0]);
    }
    
    return (
        <ContainerCover>
            <Text size='small' marginTop={'10px'} color={colors.primaryDashboard} marginBottom='10px' type='bold'> Foto de capa </Text>
            
            <ImageUploadBackground 
                onToggleFile={toggleFile} 
                onDeleteFile={() => onDeleteFile()}
                tryAgain={tryAgain} 
                isEdit={isEdit}
                refreshTryAgain={() => {
                    setTryAgain(!tryAgain)
                    setErrorImageBackground(false)
                }} 
            />

            <Column verticalCenter>
                <Text name='small' marginTop={'10px'}>Arquivos permitidos: {allowedFiles}.</Text>
            </Column>
       
        {
            errorImageBackground && (
                <Column marginTop='20px' verticalCenter>
                    <Text color={colors.danger} name='small'>Ocorreu um erro ao cadastrar a imagem</Text>
                    <Button 
                        category='outline'
                        onClick={() =>  {
                            setTryAgain(true)
                            setErrorImageBackground(false)
                        }}
                    > 
                       {buttonText.tryAgain}
                    </Button>
                </Column>
            ) 
                
        }

        {errorTypeImageBackground && (
            <Column marginTop='10px' verticalCenter>
                <Text textAlign={'center'} name='small' color={colors.danger}>{errorTypeImageBackground}</Text>
                <Button 
                    category='outline'
                    onClick={() =>  {
                        setTryAgain(true)
                        setErrorImageBackground(false)
                        setErrorTypeImageBackground(false)
                    }}
                > 
                    {buttonText.tryAgain}
                </Button>
            </Column>
        )}

        
        </ContainerCover>
    )
}

const ContainerCover = styled(Column)`
    margin-top: 40px;
    align-items: center;

    & > div {
        justify-content: center;
    }

    & > div > div {
        width: 100%;
        border-radius: 8px;
        height: 160px;
        background: ${colors.neutralBackground};

        @media screen and (min-width: ${breakpoints.sm}) {
            width: 500px;
        }

    }

    & > span > div > div > p {
        display: none;
    }
`