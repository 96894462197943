import colors from "./colors";

const eventTypes = {
    free: {
        id: import.meta.env.VITE_EVENT_TYPE_FREE,
        label: 'Gratuito',
        color: colors.primaryLight,
        slug: 'free'
    },
    paid: {
        id: import.meta.env.VITE_EVENT_TYPE_PAID,
        label: 'Pago',
        color: colors.occasionalPurple,
        slug: 'private'
    },
};

const eventTypesWithBoth = {
    both: {
        id: 0,
        label: 'Todos os tipos',
    },
    ...eventTypes,
}

export const transformedEventTypes = Object.values(eventTypesWithBoth).map(el => {
    return {
        value: el.id,
        label: el.label
    }
})


export default eventTypes;