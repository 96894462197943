import React, { Children } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row'
import Text from './Text';
import PropTypes from 'prop-types';
import colors from 'theme/colors';
import { CheckCircle, Info, XCircle } from 'react-feather';
import RightSlotEvent from 'modules/dashboard/events/RightSlotEvent';
import breakpoints from 'theme/breakpoints';

export default function Banner({
    type,
    title,
    text,
    textColor = colors.nightDark,
    iconColor,
    withoutIcon,
    rightSlot = () => false

}) {
    return (
        <BannerContainer type={type}>
            <MainContent>
                {
                    !withoutIcon && (
                        <Column width='auto'>
                            {
                                type == 'warning' && (
                                    <Info color={iconColor} size={30} />
                                )
                            }

                            {
                                type == 'danger' && (
                                    <XCircle color={iconColor} size={30} />
                                )
                            }

                            {
                                type == 'success' && (
                                    <CheckCircle color={iconColor} size={30} />
                                )
                            }
                        </Column>

                    )
                }

                <Column marginLeft={!withoutIcon && '14px'}>
                    <Text
                        color={textColor}
                        type='bold'
                    >
                        {title}
                    </Text>

                    <Text
                        color={textColor}
                        paddingBottom={0}
                        name='small'
                    >
                        {text}
                    </Text>
                </Column>
            </MainContent>

            {
                rightSlot() && (
                    <RightSlot>
                        {rightSlot()}
                    </RightSlot>
                )
            }
        </BannerContainer>
    )
}

Banner.propTypes = {
    type: PropTypes.oneOf(['warning', 'danger', 'success']),
}

Banner.defaultProps = {
    type: 'warning',
    title: PropTypes.string,
    text: PropTypes.string,
}

const BannerContainer = styled(Column)`
    width: 100%;
    height: auto;
    padding: 14px 20px;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    ${({ type }) => type === 'warning' && `
        background: ${colors.warning};
    `};
    
    ${({ type }) => type === 'danger' && `
        background: ${colors.danger};
    `};

    ${({ type }) => type === 'success' && `
        background: ${colors.successLight};
    `};

    @media screen and (min-width: ${breakpoints.lg}){
        flex-direction: row;
        margin-bottom: 0;
    }
    `;

const MainContent = styled(Row)`
    width: 100%;  
    align-items: center;
    padding-bottom: 30px;
    & > div:nth-child(2) {
        margin-top: 20px;
    }
    @media screen and (min-width: ${breakpoints.lg}){
        padding-bottom: 0;
        & > div:nth-child(2) {
        margin-top: 0;
    }
    }
`;

const RightSlot = styled(Column)`
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    padding-left: 40px;
    
    @media screen and (min-width: ${breakpoints.lg}){
        width: auto;    
    }
`;



