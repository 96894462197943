import React from 'react'
import CardSlot from '../parts/CardSlot'
import Text from 'components/_UI/Text'
import colors from 'theme/colors'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import date from 'helpers/date'
import eventTypes from 'theme/eventTypes'
import paths from 'routes/paths'
import NotResultDashBoard from 'components/commons/NotResultDashBoard'
import breakpoints from 'theme/breakpoints'
import DynamicProfileBackground from 'components/commons/DynamicProfileBackground'

export default function ProfileEvents({data}) {


  
  const formatTime = (time) => time?.slice(0, 5); 

  let nextEvents = data?.slice(0, 5)


  return (
    <CardSlot marginTop={'0'}>
      <Text name='highlight' color={colors.occasionalPurple}>Próximos eventos</Text>

      <EventsList>
        {
          nextEvents?.length > 0 ? (
            nextEvents?.map((event, index) => (
              <ThinCard key={index}  onClick={() => {
                window.location.href = `${paths.dashboard.events}?detail=${event?.slug}`
              }}>
                
              <ContentImage>
                  <DynamicProfileBackground
                    img={event?.images[0]}
                    event
                    size={'80px'}
                  />
              </ContentImage>
                <TextContent>
                  <Text type='bold' color={colors.occasionalPurple}>{event?.name}</Text>
                  <Text name='small'>{`${date?.format(event?.dates[0]?.date)} - ${formatTime(event?.dates[0]?.start)} - ${formatTime(event?.dates[0]?.end)} `}</Text>
                </TextContent>
              </ThinCard>
            )
          )
            
          ) : (
            nextEvents == 0 &&
              <ContentNotResult>
                <NotResultDashBoard
                  ilustration={'events'}
                  textButton={'Criar evento'}
                  path={`${paths.dashboard.events}?create=1`}
                  text={'Notei que você ainda não possui ativos. Que tal criar seu evento e ter uma experiência incrível?'}
                />
              </ContentNotResult>
          )
        }
      </EventsList>
    </CardSlot>
  )
}

const EventsList = styled(Column)`
  margin-top: 10px;
`;

const ThinCard = styled(Row)`
  cursor: pointer;
  margin-bottom: 10px;
  transition: all ease-in-out .25s;

  & > div > div {
    height: 65px;
  }

  &:hover {
    transform: scale(1.01)
  }

`;

const ContentImage = styled.div`
  width: 150px;
  margin-bottom: 15px;
  & > div > img {
    border-radius: 4px;
  }
`;

const Picture = styled.picture`
  width: 110px;
  height: 65px;
  ${({ type }) => type === eventTypes.free.slug && ` 
    & > div {
      background: ${eventTypes.free.color};
      border-radius: 8px;
      height: 100%;
    }
        
  `}

  ${({ type }) => type === eventTypes.paid.slug && `
    & > div {
      background: ${eventTypes.paid.color};
      border-radius: 8px;
      height: 100%;
    }    
  `}
    
  
`;

const EventImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

const TextContent = styled(Column)`
  padding: 0 10px;
`;

const ContentNotResult = styled.div`
  @media screen and (min-width: ${breakpoints.lg}){

    & > div {
      display: flex;
      flex-direction: column;
    }
    & > div > div {
     width: 100%;
     padding: 0 5px 0 5px;
    }
  }
`;
