import api from "api";

const checkout = {
    getProducts: (payload) => {
        return api().get(`/events/${payload}/products`)
    },

    getTickets: (payload, config) => {
        return api().get(`/events/${payload}/tickets`, config)
    },

    getOrder: (payload) => {
        return api().get('/orders', {params: {event_id: payload}})
    },

    createOrder: (payload) => {
        return api().post('/orders/', payload)
    },

    checkoutOrder: (payload, id) => {
        return api().post(`/orders/${id}/checkout`, payload)
    },
    
    getCart: () => {
        return api().get('/users/tickets')
    },

};

export default checkout;  