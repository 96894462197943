import React, { useState } from 'react';
import Button from 'components/_UI/Button';
import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import currency from 'helpers/currency';
import { Eye, EyeOff } from 'react-feather';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function StatisticsCards({ data }) {
    const [isTotalVisible, setIsTotalVisible] = useState(false);
    const [isPendingVisible, setIsPendingVisible] = useState(false);
    const [isAvailableVisible, setIsAvailableVisible] = useState(false);

    const toggleTotalVisibility = () => setIsTotalVisible(!isTotalVisible);
    const togglePendingVisibility = () => setIsPendingVisible(!isPendingVisible);
    const toggleAvailableVisibility = () => setIsAvailableVisible(!isAvailableVisible);

    return (
        <Container>
            <Card bgColor={colors.primaryDark}>
                <Row>
                    <IconContainer>
                        <Icon name='contract' color={colors.primaryLight} />
                    </IconContainer>
                    <Column paddingLeft='15px'>
                        <Text color={colors.primaryLight}>Transações efetuadas</Text>
                        <Text color={colors.primaryLight} name='subtitle'>
                            {data?.count_total_transacted ? data?.count_total_transacted : 0}
                        </Text>
                    </Column>
                </Row>
            </Card>

            <Card bgColor={colors.goldLight}>
                <Row>
                    <IconContainer>
                        <Icon name='money' color={colors.gold} />
                    </IconContainer>
                    <Column paddingLeft='15px'>
                        <Text color={colors.gold}>Total transacionado</Text>
                        <Row>
                            <Text color={colors.gold} name='subtitle'>
                                {isTotalVisible ? currency.format(data?.total_transactions_carried_out) : 'R$ *****'}
                            </Text>
                            <Column
                                marginLeft='10px'
                                marginTop='2px'
                                style={{ cursor: 'pointer', width: 'auto' }}
                                onClick={toggleTotalVisibility}
                            >
                                {isTotalVisible ? <EyeOff size={20} color={colors.night} /> : <Eye size={20} color={colors.night} />}
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </Card>

            <Card bgColor={colors.success}>
                <Row>
                    <IconContainer>
                        <Icon name='wallet' color={colors.occasionalPurple} />
                    </IconContainer>
                    <Column paddingLeft='15px'>
                        <Text color={colors.occasionalPurple}>Total a receber</Text>
                        <Row>
                            <Text color={colors.occasionalPurple} name='subtitle' paddingBottom={0}>
                                {isPendingVisible
                                    ? currency.format(data?.balance_pending + data?.balance_available)
                                    : 'R$ *****'}
                            </Text>
                            <Column
                                marginLeft='10px'
                                marginTop='2px'
                                style={{ cursor: 'pointer', width: 'auto' }}
                                onClick={togglePendingVisibility}
                            >
                                {isPendingVisible ? <EyeOff size={20} color={colors.night} /> : <Eye size={20} color={colors.night} />}
                            </Column>
                        </Row>
                    </Column>
                    {/* <WithdrawButton>Sacar</WithdrawButton> */}
                </Row>
            </Card>

            <Card bgColor={colors.success}>
                <Row>
                    <IconContainer>
                        <Icon name='withdraw' color={colors.success} />
                    </IconContainer>
                    <Column paddingLeft='15px'>
                        <Text color={colors.success}>Disponível para saque</Text>
                        <Row>
                            <Text color={colors.success} name='subtitle'>
                                {isAvailableVisible ? currency.format(data?.balance_available) : 'R$ *****'}
                            </Text>
                            <Column
                                marginLeft='10px'
                                marginTop='2px'
                                style={{ cursor: 'pointer', width: 'auto' }}
                                onClick={toggleAvailableVisibility}
                            >
                                {isAvailableVisible ? <EyeOff size={20} color={colors.night} /> : <Eye size={20} color={colors.night} />}
                            </Column>
                        </Row>
                    </Column>
                    {/* <WithdrawButton>Sacar</WithdrawButton> */}
                </Row>
            </Card>
        </Container>
    );
}

const Container = styled(Column)`
    width: 100%;
    padding: 10px 0;

    @media screen and (min-width: ${breakpoints.md}) {
        flex-direction: row !important;
    }
`;

const Card = styled(Row)`
    padding: 20px;
    border-radius: 6px;
    background-color: ${colors.neutralLight};
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 7px #ddd;

    &:first-child {
        margin-top: 0;
        margin-left: 0;
    }

    @media screen and (min-width: ${breakpoints.md}) {
        margin-left: 20px;
        margin-top: 0;
        height: auto;
        align-items: flex-start;
        justify-content: flex-start;
    }
`;

const WithdrawButton = styled.button`
    width: 86px;
    color: ${colors.night};
    background-color: ${colors.neutral};
    border-radius: 6px;
    padding: 6px;
    margin-top: 6px;
`;

const IconContainer = styled.div`
    width: 58px;
    height: 58px;
`;
