const prefix = {
    user: '/u',
    dashboard: '/painel'
};

const paths = {
    // onboarding
    login: '/entrar',
    register: '/cadastrar',
    recoverAccount: '/recuperar-conta',  
    
    home: '/',
    
    events: {
        home: '/eventos',
        trendboard: '/eventos-em-alta',
    },

    partner: {
        createAccount: '/quero-ser-parceiro'
    },

    establishments: {
        home: '/estabelecimentos',
        trendboard: '/eventos-em-alta',
    },
    
    user: {
        profile: '/perfil',
        // following: `${prefix.user}/:user/seguindo`,
        // followers: `${prefix.user}/:user/seguidores`,
        wallet: `/carteira`,
    },

    dashboard: {
        home: `${prefix.dashboard}/perfil`,
        profile: `${prefix.dashboard}/perfil`,
        resume: `${prefix.dashboard}/resumo`,
        products: `${prefix.dashboard}/produtos`,
        events: `${prefix.dashboard}/eventos`,
        financial: `${prefix.dashboard}/financeiro`,
        support: `${prefix.dashboard}/suporte`,
        checkin: `${prefix.dashboard}/checkin`,
    },

    terms: {
        useTerms: '/termos-de-uso',
        policiePrivacy: '/politica-de-privacidade',
    }
};


export default paths;                   