import React, { createContext, useState, useContext } from 'react';

const EstablishmentListContext = createContext();

export default function EstablishmentsListProvider({ children }) {
    const [establishmentsList, setEstablishmentsList] = useState(null);

    return (
        <EstablishmentListContext.Provider value={{ establishmentsList, setEstablishmentsList }}>
            {children}
        </EstablishmentListContext.Provider>
    );
}

export function useEstablishmentsList() {
    const context = useContext(EstablishmentListContext);

    if (!context) throw new Error('useEstablishmentsList must be used within a EstablishmentsListProvider');

    const { establishmentsList, setEstablishmentsList } = context;
    return { establishmentsList, setEstablishmentsList };
}