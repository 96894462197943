import React, { createContext, useState, useContext } from 'react';

const PreferencesContext = createContext();

export default function PreferencesProvider({ children }) {
    const [preferences, setPreferences] = useState('');

    return (
        <PreferencesContext.Provider value={{ preferences, setPreferences }}>
            {children}
        </PreferencesContext.Provider>
    );
}

export function usePreferences() {
    const context = useContext(PreferencesContext);

    if (!context) throw new Error('usePreferences must be used within a PreferencesProvider');

    const { preferences, setPreferences } = context;
    return { preferences, setPreferences };
}