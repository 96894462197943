import Text from 'components/_UI/Text'
import { useShowAllEvents } from 'context/dash/ShowAllEvents'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import EventItem from './EventItem'
import Loader from 'components/_UI/Loader'
import eventsApi from 'api/requests/events'
import { FullyCentered } from 'styled/alignment/Center'
import Paginate from 'components/_UI/Paginate'
import { useBackButtonCollapseDash } from 'context/dash/BackButtonCollapseDash'

export default function ShowMoreList({
    withBackButton,
    title,
    type,
    onEventClick = () => false,
    onDropdownAction = () => false
}) {
    const {backButtonCollapseDash, setBackButtonCollapseDash} = useBackButtonCollapseDash()
    const { showAllEvents, setShowAllEvents } = useShowAllEvents();
    const [selected, setSelected] = useState(false)
    const [loading, setLoading] = useState(false);
    const [eventClicked, setEventClicked] = useState(0)

    useEffect(() => {

        // Trecho onde caso ja tenha acessado o mostrar mais de algum bloco e clicar na seta ele não faça request
        // Tem que ajustar, estava ficando salvo os mesmos dados após ja ter carregado alguma vez o mostrar mais 
        // if(backButtonCollapseDash?.item?.data?.length) {
        //     setShowAllEvents({ ...showAllEvents, data: backButtonCollapseDash?.item?.data, paginate: backButtonCollapseDash?.item?.meta})
        //     setBackButtonCollapseDash({...backButtonCollapseDash, visible: withBackButton})

        //     return
        // }

        getEvents()

    }, [])


    async function getEvents(number = 1) {
        setLoading(true)
        
        try {
            const { data } = await eventsApi.myEvents({ type: showAllEvents?.type, page: number })
            setShowAllEvents({ ...showAllEvents, data: data.data, paginate: data.meta})
            setBackButtonCollapseDash({...backButtonCollapseDash, item: data})
        } catch (error) {
            
        } finally {
            setLoading(false)
        }
    }
    

    return (
        <Column>
            <Text name='title' marginLeft={'15px'} marginTop={'15px'}> {showAllEvents?.title} </Text>

            {loading
                ? (
                    <FullyCentered width='100%' height='500px'>
                        <Loader />
                    </FullyCentered>
                )
                : (
                    <>
                        <EventsListContainer>
                            {
                                showAllEvents?.data?.map((el, index) => (
                                <span onClick={() => setEventClicked(eventClicked + 1)}>

                                    <EventItem
                                        expired={showAllEvents.type === 'expired'}
                                        data={el}
                                        withBackButton={true}
                                        dropDownVisible={index === selected}
                                        eventClick={eventClicked}
                                        onEventClick={() => {
                                            setShowAllEvents({ ...showAllEvents, visible: false})
                                            setSelected(index)
                                            onEventClick(el)
                                        }}
                                        onMoreClick={() => setSelected(index)}
                                        onDropdownAction={e => {
                                            onDropdownAction({ action: e, ...el })
                                            setShowAllEvents({ ...showAllEvents, visible: false})
                                        }}
                                    />
                                </span>
                                ))
                            }
                            {
                                showAllEvents?.paginate?.last_page > 1 &&
                                <Paginate 
                                    marginBottom={'20px'}
                                    currentPage={showAllEvents?.paginate?.current_page}
                                    totalItems={showAllEvents?.paginate?.total}
                                    lastPage={showAllEvents?.paginate?.last_page}
                                    firstPage={showAllEvents?.paginate?.first_page}
                                    perPage={showAllEvents?.paginate?.per_page}
                                    onPaginate={(number) => getEvents(number)}
                                />
                            }
                        </EventsListContainer>
                    </>
                )}

        </Column>
    )
}

const EventsListContainer = styled(Row)`
    flex-wrap: wrap;
    max-height: auto;
    justify-content: space-around;
    & > div {
        margin-top: 20px;
    }
    & > span {
        margin-bottom: 50px;
    }
`