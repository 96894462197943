import React, { createContext, useState, useContext } from 'react';
import tabs from 'theme/tabs';

const WithdrawContext = createContext();

export default function WithdrawProvider({ children }) {
    const [withdraw, setWithdraw] = useState({
        data: [],
        availableValue: 0,
        success: 0
    });

    return (
        <WithdrawContext.Provider value={{ withdraw, setWithdraw }}>
            {children}
        </WithdrawContext.Provider>
    );
}

export function useWithdraw() {
    const context = useContext(WithdrawContext);

    if (!context) throw new Error('useWithdraw must be used within a WithdrawProvider');

    const { withdraw, setWithdraw } = context;
    return { withdraw, setWithdraw };
}