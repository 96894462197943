import React from 'react'
import EstablishmentsListProvider from 'context/establishments/EstablishmentsList';
import OnSelectMenuProvider from 'context/general/OnSelectMenu';
import CheckoutProvider from 'context/checkout/Checkout';
import EstablishmentProvider from 'context/establishments/Establishment';
import EstablishmentFilterProvider from 'context/establishments/EstablishmentFilter';
import EstablishmentsNearListallProvider from 'context/establishments/EstablishmentsNearListAll';
import EstablishmentsNearToYouProvider from 'context/establishments/EstablishmentsNearToYou';
import CollapseProvider from 'context/general/Collapse';
import EventProvider from 'context/events/Event';
import EventsListProvider from 'context/events/EventsList';
import EventFilterProvider from 'context/events/EventFilter';
import TagsProvider from 'context/events/Tags';
import EventsNearToYouProvider from 'context/events/EventsNearToYou';
import BackButtonCollapseProvider from 'context/general/BackButtonCollapse';
import NearListAllProvider from 'context/events/NearListAll';
import EstablishmentTagsProvider from 'context/establishments/EstablishmentTags';
import UserLocaleProvider from 'context/user/UserLocale';
import UserLocationProvider from 'context/user/UserLocation';
import CurrentLocationProvider from 'context/user/CurrentLocation';
import ShowLocationModalProvider from 'context/general/ShowLocationModal';

export default function HomeWrapper({ children }) {
    return (
        <OnSelectMenuProvider>
            <CollapseProvider>
                <EventsListProvider>
                    <EventProvider>
                        <EventFilterProvider>
                            <TagsProvider>
                                <EventsNearToYouProvider>
                                    <NearListAllProvider>
                                        <BackButtonCollapseProvider>
                                            <EstablishmentsListProvider>
                                                <CheckoutProvider>
                                                    <EstablishmentProvider>
                                                        <EstablishmentFilterProvider>
                                                            <EstablishmentsNearListallProvider>
                                                                <EstablishmentsNearToYouProvider>
                                                                    <EstablishmentTagsProvider>
                                                                        <UserLocaleProvider>
                                                                            <UserLocationProvider>
                                                                                <CurrentLocationProvider>
                                                                                    <ShowLocationModalProvider>

                                                                                        {children}

                                                                                    </ShowLocationModalProvider>
                                                                                </CurrentLocationProvider>
                                                                            </UserLocationProvider>
                                                                        </UserLocaleProvider>
                                                                    </EstablishmentTagsProvider>
                                                                </EstablishmentsNearToYouProvider>
                                                            </EstablishmentsNearListallProvider>
                                                        </EstablishmentFilterProvider>
                                                    </EstablishmentProvider>
                                                </CheckoutProvider>
                                            </EstablishmentsListProvider>
                                        </BackButtonCollapseProvider>
                                    </NearListAllProvider>
                                </EventsNearToYouProvider>
                            </TagsProvider>
                        </EventFilterProvider>
                    </EventProvider>
                </EventsListProvider>
            </CollapseProvider>
        </OnSelectMenuProvider>
    )
}
