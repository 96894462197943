import EventProvider from 'context/events/Event'
import BackButtonCollapseProvider from 'context/general/BackButtonCollapse'
import CollapseProvider from 'context/general/Collapse'
import MainWallet from 'modules/users/wallet/MainWallet'
import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'

export default function Wallet() {
  return (
    <BackButtonCollapseProvider>
      <EventProvider>
        <CollapseProvider>
          <ContentWallet>
              <MainWallet  />
          </ContentWallet>
        </CollapseProvider>
      </EventProvider>
    </BackButtonCollapseProvider>
  )
}

const ContentWallet = styled(Column)`
    width: 100%;
    height: 100vh;
`;