import Fieldset from 'components/_UI/Fieldset'
import RadioSelectButton from 'components/_UI/RadioSelectButton'
import Text from 'components/_UI/Text'
import { useEventFilter } from 'context/events/EventFilter'
import date from 'helpers/date'
import masks from 'helpers/masks'
import regex from 'helpers/regex'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { FullyCentered, HorizontalCenter } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import breakpoints from 'theme/breakpoints'
import colors from 'theme/colors'
import dateFilters, { transformedDateFilters } from 'theme/filters';
import Select from 'react-select';
import Radio from 'components/_UI/Radio'

export default function DateSelects() {
    const { eventFilter, setEventFilter } = useEventFilter();
    const [radioNow, setRadioNow] = useState(0);
    const [radioCustom, setRadioCustom] = useState(0);
    const [customFieldError, setCustomFieldError] = useState(false)
    const [selected, setSelected] = useState(null);
    const [customDate, setCustomDate] = useState({
        from: date.format(new Date()),
        to: date.format(getTomorrow())
    })

    useEffect(() => {
        setEventFilter({
            ...eventFilter,
            customFields: customDate
        })

        if (selected !== null) {
            const selectedOption = transformedDateFilters.find(option => option.value == selected);
            if (selectedOption) {
                setRadioNow(0);
                setRadioCustom(0);

                setEventFilter({
                    ...eventFilter,
                    dates: selectedOption.value
                });
            }
        }
    }, [selected])



    const handleSelect = (item) => {
        setSelected(item.value);
    };


    function getTomorrow() {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(today.getDate() + 1)

        return tomorrow
    }

    function validateDate(from, to) {
        setCustomDate({ from, to })
        return regex.date.test(from) && regex.date.test(to);
    }

    function validateCustomField({ from, to }) {
        if (!validateDate(from, to)) {
            setCustomFieldError(true)
            setEventFilter({ ...eventFilter, customFields: false })
            return
        }

        setCustomFieldError(false)
        setEventFilter({
            ...eventFilter,
            customFields: { from, to }
        })
    }

    function handleRadioSelect(item) {
        let selectSelector = document.getElementById('select')
        selectSelector.children[2].children[0].children[0].textContent = 'Selecione uma opção';
        setSelected(null);


        if (eventFilter?.dates === item) {
            setRadioNow(0);
            setRadioCustom(0);
            setEventFilter({ ...eventFilter, dates: false });
            return
        }

        // if (item === 'now') {            
        //     setRadioCustom(0);
        //     setRadioNow(1);
        //     setEventFilter({ ...eventFilter, dates: 'now' });
        //     return
        // }

        if (item === 'custom') {
            setRadioNow(0);
            setRadioCustom(1);
            setEventFilter({ ...eventFilter, dates: 'custom' });
        }
    };


    return (
        <Container marginTop='18px'>
            <Text size='small' type='bold' marginLeft='6px'> Data </Text>
            <Text size='small' color={colors.night}>Escolha o período que deseja buscar.</Text>

            <Column>
                <Select
                    placeholder='Selecione uma opção'
                    options={transformedDateFilters}
                    selected={selected}
                    onChange={handleSelect}
                    id='select'
                />

                {/* <Radio
                    label='Eventos de hoje'
                    selected={radioNow === 1}
                    onSelect={() => handleRadioSelect('now')}
                /> */}
                <Radio
                    label='Escolher uma data personalizada'
                    selected={radioCustom === 1}
                    onSelect={() => handleRadioSelect('custom')}
                    marginTop={'10px'}
                />
            </Column>

            {eventFilter?.dates === 'custom' && (
                <Column marginTop='10px'>
                    <Text size='small' color={colors.night}>Escolha um periodo que se encaixe na sua busca.</Text>
                    <CustomDateContainer>
                        <Text
                            type='bold'
                            marginRight='14px'
                            color={colors.night}
                        >
                            De
                        </Text>

                        <Column width='100px'>
                            <Fieldset
                                placeholder='data'
                                mask={masks.date}
                                value={customDate.from}
                                onChangeValue={(from) => validateCustomField({ ...customDate, from })
                                }
                            />
                        </Column>

                        <Text
                            type='bold'
                            marginRight='14px'
                            marginLeft='14px'
                            color={colors.night}
                        >
                            Até
                        </Text>

                        <Column width='100px'>
                            <Fieldset
                                placeholder='data'
                                mask={masks.date}
                                value={customDate.to}
                                onChangeValue={(to) => validateCustomField({ ...customDate, to })
                                }
                            />
                        </Column>
                    </CustomDateContainer>
                    {customFieldError && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> Informe um período válido</Text>
                        </HorizontalCenter>
                    )}
                </Column>
            )}
        </Container>
    )
}

const CustomDateContainer = styled(Row)`
    width: 100%;
    margin-top: 10px;
    align-items: flex-end;
    justify-content: center;
`;

const Container = styled(FullyCentered)`
    flex-direction: column;
    margin-top: 25px;

    @media screen and (min-width: ${breakpoints.lg}) {
        width: 80%;
    }
`;