import styled from 'styled-components'
import { Row } from "styled/alignment/Row";

export const SelectContainer = styled(Row)`
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 5px;
`;

export const SelectWrapper = styled.div`
    display: flex;
    height: auto;
    flex-wrap: wrap;
    margin-right: 10px;
`;

export const ButtonSelectItem = styled.div`
    max-width: 100%;
    
    & > button {
        padding: 6px 10px;
    }
`;

export const ButtonSelectType = styled.div`
    width: 100px;
    
    & > button {
        padding: 6px 10px;
    }
`;