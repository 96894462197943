
import Text from 'components/_UI/Text'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import NotResultDashBoard from 'components/commons/NotResultDashBoard'
import { Column } from 'styled/alignment/Column'
import Fieldset from 'components/_UI/Fieldset'
import Button from 'components/_UI/Button'
import breakpoints from 'theme/breakpoints'
import useToast from 'hooks/useToast'
import ToastContainer from 'components/_UI/ToastsContainer';
import TicketValidationModal from './tickets/TicketValidationModal'
import fieldValidations from 'helpers/fieldValidations'
import { useForm } from 'react-hook-form'
import { FullyCentered } from 'styled/alignment/Center'
import Loader from 'components/_UI/Loader'
import ticketsApi from 'api/requests/tickets'
import Modal from 'components/_UI/Modal'
import { CheckCircle } from 'react-feather'
import currency from 'helpers/currency'
import buttonText from 'texts/buttons/buttonText'
import { useInvisibleButton } from 'context/general/InvisibleButton'
import EventsList from './events/EventsList'
import LinkPage from 'components/_UI/LinkPage'
import ParticipantsListModal from './events/ParticipantsListModal'

export default function Checkin({
    minimal = false,
    withParticipants = false,
}) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            code: ''
        }
    });

    const [isValidatingTicket, setIsValidatingTicket] = useState(false)
    const [isScanning, setIsScanning] = useState(false);
    const [selected, setSelected] = useState(false)
    const [visibleField, setVisibleField] = useState(false)
    const [loading, setLoading] = useState(false)
    const [validationError, setValidationError] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [isEventsVisible, setIsEventsVisible] = useState(false)
    const [errorValidation, setErrorValidation] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [showParticipantsVisible, setShowParticipantsVisible] = useState(false)

    const { invisibleButton, setInvisibleButton } = useInvisibleButton()

    const { toast, toasts } = useToast()



    function showQrScanner() {
        setInvisibleButton(true)
        setIsValidatingTicket(true)
    }

    function showEventsModal() {
        setInvisibleButton(true)
        setIsEventsVisible(true)
    }

    function onErrorValidation(textError) {
        toast.error(textError, {
            position: 'top-center'
        })
    }

    function renderForm() {
        return (
            <SlotContent onSubmit={handleSubmit(validateTicket)}>
                <Fieldset
                    label='Código de validação'
                    labelPosition
                    placeholder='000000'
                    layout='straight-center'
                    name='code'
                    register={register}
                    validations={fieldValidations.code('código')}
                />

                {errors.code && !validationError && (
                    <Column>
                        <Text color={colors.danger} name='small'> {errors.code?.message} </Text>
                    </Column>
                )}
                {!errors.code && validationError && (
                    <Column>
                        <Text color={colors.danger} name='small'> {validationError} </Text>
                    </Column>
                )}

                {
                    loading ? (
                        <FullyCentered>
                            <Loader />
                        </FullyCentered>
                    ) : (
                        <>
                            <Button
                                type={'submit'}
                                variant='featured'
                                marginTop={'10px'}>
                                Validar código
                            </Button>
                            <Text type='bold'>Ou</Text>
                            <Button
                                variant='featured'
                                onClick={showQrScanner}
                            >
                                Escanear QRCode
                            </Button>


                            {
                                withParticipants &&
                                <ParticipantsLinkContainer marginTop={'30px'} fullyCentralized onClick={showEventsModal}>
                                    <Text >Você pode acompanhar a lista de participantes </Text>&#160;
                                    <LinkPage size={'default'}>clicando aqui.</LinkPage>
                                </ParticipantsLinkContainer>

                            }
                        </>
                    )
                }
            </SlotContent>
        )
    }

    function closeModal() {
        setInvisibleButton(false)
        setIsScanning(false)
        setIsValidatingTicket(false)
        setSelected(false)
        setVisibleField(false)
    }

    async function validateTicket({ code }) {
        if (code.length !== 6) {
            return
        }

        setLoading(true)

        try {
            const { data } = await ticketsApi.checkIn({ code })
            setValidationError(false);
            setShowSuccessModal(data?.data);
            reset({ code: '' });

        } catch (error) {
            setValidationError(error?.response?.data?.message)
        } finally {
            setLoading(false)
            // onClose()
        }
    }

    function onEventClick(e) {
        setSelectedEvent(e);
        setIsEventsVisible(false);
        setShowParticipantsVisible(true);
    }

    return (
        <>
            <ToastContainer toasts={toasts} />
            <Container>
                <TicketValidationModal
                    isOpen={isValidatingTicket}
                    onClose={() => closeModal()}
                    isScanning={isScanning}
                    setIsScanning={setIsScanning}
                    visibleField={visibleField}
                    setVisibleField={setVisibleField}
                    selected={selected}
                    setSelected={setSelected}
                    onSucess={(e) => setShowSuccessModal(e)}
                    errorValidation={(text) => onErrorValidation(text)}
                />

                <NotResultDashBoard
                    minimal={minimal}
                    text={
                        <>
                            <Text name='subtitle'>Validação</Text>
                            <Text>Verifique se ingressos e/ou produtos são válidos de forma rápida e prática através do código de verificação.</Text>

                        </>
                    }
                    ilustration={'checkIn'}
                    reverse
                    slot={() => renderForm()}
                    marginTop={'15px'}
                />
            </Container>

            <Modal isOpen={showSuccessModal} closeButton={true} onClose={() => setShowSuccessModal(false)}>
                <FullyCentered>
                    <CheckCircle color={colors.success} />
                    <Text color={colors.success} name='subtitle' paddingBottom={0} marginLeft={'10px'}>
                        Ingresso validado
                    </Text>

                </FullyCentered>

                <Column>
                    {!!showSuccessModal?.event_name && (
                        <Text marginTop={'20px'}>
                            <b>Evento:</b> {showSuccessModal?.event_name}
                        </Text>

                    )}
                    <Text>
                        <b>Participante:</b> {showSuccessModal?.participant_name}
                    </Text>
                    <Text>
                        <b>{showSuccessModal?.type === 'ticket' ? 'Ingresso' : 'Produto'}</b> {showSuccessModal?.product_name}
                    </Text>
                    <Text>
                        <b>Preço:</b> {currency.format(Number(showSuccessModal?.price))}
                    </Text>
                </Column>

                <Button variant='lightBlue' marginTop={'20px'} onClick={() => setShowSuccessModal(false)} >
                    {buttonText.ok}
                </Button>
            </Modal>

            <Modal
                isOpen={isEventsVisible}
                closeButton={true}
                size={'small'}
                onClose={() => setIsEventsVisible(false)}
            >
                <Column paddingTop={'.5rem'} paddingBottom={'.5rem'} paddingLeft={'.5rem'} paddingRight={'.5rem'}>
                    <EventsList onEventClick={(e) => onEventClick(e)} />
                </Column>
            </Modal>
            {
                showParticipantsVisible && (
                    <ParticipantsListModal
                        event={selectedEvent}
                        visible={showParticipantsVisible}
                        onClose={() => setShowParticipantsVisible(false)}
                        onBack={() => {
                            setShowParticipantsVisible(false)
                            setIsEventsVisible(true)
                        }}
                    />
                )
            }
        </>
    )
}

const Container = styled(Row)`
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    border-radius: 4px;

    & > div > div {
        @media screen and (min-width: ${breakpoints.lg}){
            box-shadow: 1px 2px 10px #ccc;
            margin-right: 40px;
            margin-bottom: 40px;
            margin-top: 20px;
            
        }
    }
`;

const SlotContent = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    
    & > div  {
        width: 100%;
        text-align: center;
        & > input {
            margin-top: 10px;
            color: ${colors.night};

            &:focus {
                border-bottom: 2px solid ${colors.night};
            }
        }
    }
    
`;

const ParticipantsLinkContainer = styled(Row)`
    flex-wrap: wrap;

    & > a, p {
        width: auto;
    }
`;  

