import BackButtonCollapseDashProvider from 'context/dash/BackButtonCollapseDash'
import DetailVisibleProvider from 'context/dash/DetailVisible'
import ShowAllEventsProvider from 'context/dash/ShowAllEvents'
import ListProductsProvider from 'context/products/ListProducts'
import ProductsProvider from 'context/products/Products'
// import UserProvider from 'context/user/User'
import DashContainer from 'modules/dashboard/_common/DashContainer'
import Products from 'modules/dashboard/products/Products'
import RightSlotProducts from 'modules/dashboard/products/right-slot/RightSlotProducts'
import React, { useState } from 'react'

export default function DashProducts() {
  const [isCreate, setIsCreate] = useState(false);

  const handleCreateState = (value) => {
    setIsCreate(value);
  };
  function rightSlotContent() {
    return (
      <RightSlotProducts setIsCreate={handleCreateState}/>
    )
  }
  
  return (
    // <UserProvider>
    <DetailVisibleProvider>
      <ListProductsProvider>
          <ProductsProvider>
            <ShowAllEventsProvider>
              <BackButtonCollapseDashProvider>
                <DashContainer title={'Produtos'} rightSlot={() => rightSlotContent()}>
                  <Products isCreate={isCreate} setIsCreate={setIsCreate}/>
                </DashContainer>
              </BackButtonCollapseDashProvider>
            </ShowAllEventsProvider>
          </ProductsProvider>
      </ListProductsProvider>
    </DetailVisibleProvider>
    // </UserProvider>
  )
}
