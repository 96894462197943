const indexPriority = {
    negative: -5,
    none: 0,
    xSmall: 1,
    small: 3,
    normal: 4,
    medium: 10,
    high: 20,
    xHigh: 30,
    ultra: 50,
    enormous: 100,
    tremendous: 200,
    titanic: 500,
    max: 9999
}

export default indexPriority;
