const routeParams = {
    events: {
        city: 'city',
        event: 'evento',
        latitude: 'lat',
        longitude: 'long',
        term: 'term',
        date: 'filter_date',
        type: 'classification_id',
        tags: 'tags',
        range: 'distance_range',
        minDate: 'min_date',
        maxDate: 'max_date'
    },
    establishments: {
        city: 'city',
        establishment: 'estabelecimento',
        latitude: 'lat',
        longitude: 'long',
        term: 'term',
        date: 'filter_date',
        type: 'classification_id',
        tags: 'tags',
        feature: 'features',
        range: 'distance_range',
        minDate: 'min_date',
        maxDate: 'max_date',
        services: 'services'
    },

    permissions: {
        invitePermission: 'permission_invite',
        invitePermissionEvent: 'invite_permission_event'
    },

    resume: {
        create: 'create',
        detail: 'detail'
    },
    products: {
        create: 'create',
    },
    profile: {
        create: 'create'
    },
    partner: {
        create: 'createPartner'
    },
    financial: {
        bank: 'dados-bancarios'
    }
};

export default routeParams;