import React, { useEffect, useState } from "react";
import { GlobalStyle } from 'styled/global';
import Router from 'routes';
import EnviromentForm from "./EnviromentForm";

import UserProvider from "context/user/User";
import CookieBanner from "components/cookies/CookieBanner";
import ToastContainer from "components/_UI/ToastsContainer";
import useToast from "hooks/useToast";
import InvisibleButtonProvider from "context/general/InvisibleButton";
import MyEstablishmentProvider from "context/establishments/MyEstablishment";

const isDevelop = window.location.href.split('dev.whizr').length > 1

function App() {
  const { toasts } = useToast();

  const [init, setInit] = useState(false);

  useEffect(() => { }, [toasts])

  return (

    <UserProvider>
      <MyEstablishmentProvider>
        <InvisibleButtonProvider>

      
          <GlobalStyle />
          <CookieBanner />
          <ToastContainer toasts={toasts} />

          {
            isDevelop
              ? (!init ? <EnviromentForm onAuthorize={() => setInit(true)} /> : <Router />)
              : <Router features={window.featuresEnabled} />
          }
        </InvisibleButtonProvider>
      </MyEstablishmentProvider>
    </UserProvider>
  );
};

export default App;
