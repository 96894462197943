const filters = [
    // {name: 'Acontecendo agora', value: 'now'},
    {name: 'Hoje', value: 'daily'},
    {name: 'Amanhã', value: 'tomorrow'},
    {name: 'Esta semana', value: 'weekly'},
    {name: 'Este fim de semana', value: 'weekend'},
    {name: 'Próxima semana', value: 'next_weekly'},
    {name: 'Este mês', value: 'monthly'},
    {name: 'Este ano', value: 'yearly'},
    // {name: 'Escolher período', value: 'custom'},
];

export const transformedDateFilters = Object.values(filters).map(el => {
    return {
        value: el.value,
        label: el.name
    }
})

export default filters;