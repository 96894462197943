import Progress from 'components/_UI/Progress';
import Text from 'components/_UI/Text';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import CardTickets from '../card/CardTickets';
import Icon from 'components/_UI/Icon';
import fonts from 'theme/fonts';
import Switch from 'components/_UI/Switch';
import Button from 'components/_UI/Button';
import Loader from 'components/_UI/Loader';
import ticketsApi from 'api/requests/tickets';
import currency from 'helpers/currency';

export default function TicketDetail({ data }) {
    const [tickets, setTickets] = useState(null);
    const [amountTotal, setAmountTotal] = useState(0);
    const [soldAmountTicket, setSoldAmountTicket] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [ticketTotals, setTicketTotals] = useState({}); 
    const [ticketPercentages, setTicketPercentages] = useState({}); 


    useEffect(() => {
        getEvent(data);
    }, []);

    async function getEvent(slug) {
        try {
            const { data } = await ticketsApi.getTicketForEvent(slug);
            
            setSoldAmountTicket(data?.tickets_sold);
            setAmountTotal(data?.tickets_total);
            setTickets(data?.tickets);

            let totals = {};
            let percentages = {};

            data?.tickets.forEach(ticket => {
                let totalCollected = 0;
                let totalEstimated = 0;
                let soldQuantity = 0;
                let totalQuantity = 0;

                ticket?.batches?.forEach(batch => {
                    soldQuantity += (batch.quantity - batch.quantity_available);
                    totalQuantity += batch.quantity;
                    totalCollected += (batch.quantity - batch.quantity_available) * batch.price;
                    totalEstimated += batch.quantity * batch.price;
                });

                totals[ticket.id] = {
                    collected: totalCollected,
                    estimated: totalEstimated
                };
                
                percentages[ticket.id] = (soldQuantity / totalQuantity) * 100;
            });

            setTicketTotals(totals); 
            setTicketPercentages(percentages);
             
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <>
            {!isLoading ? (
                <ContainerDetail>
                    <Text name='subtitle' color={colors.night}>Informações de Vendas</Text>
                    <Text color={colors.night}>Acompanhe suas vendas e veja quantos ingressos já foram vendidos e quantos ainda estão disponíveis para alcançar sua meta.</Text>

                    <StyledData>
                        <ContentData>
                            <StyledTotals>
                                <StyledProgressSale>
                                    <Text color={colors.secondaryPurple} name='hero' paddingBottom={'0'}>{soldAmountTicket}/</Text>
                                    <Text color={colors.secondaryPurple} name='title' paddingBottom={'0'}>{amountTotal}</Text>
                                </StyledProgressSale>
                                <Text type='bold' color={colors.night}>Ingressos</Text>
                                <Text type='bold' color={colors.secondaryPurple}>Vendidos</Text>
                            </StyledTotals>

                            <StyledTotals>
                                <ContentTicketToSell>
                                    <Text color={colors.secondaryPurple} name='hero' paddingBottom={'0'}>{amountTotal - soldAmountTicket}/</Text>
                                    <Text color={colors.secondaryPurple} name='title' paddingBottom={'0'}>{amountTotal}</Text>
                                </ContentTicketToSell>
                                <Text type='bold' color={colors.night}>Ingressos a</Text>
                                <Text type='bold' color={colors.secondaryPurple}>Vender</Text>
                            </StyledTotals>
                        </ContentData>
                        <Text color={colors.night} marginTop={'20px'}><b>Estatísticas de vendas:</b> Confira a porcentagem de ingressos vendidos para cada categoria e acompanhe seu desempenho.</Text>
                    </StyledData>

                    <ContainerProgressData>
                        {tickets?.map((el) => (
                            <ContentProgressData key={el.id}>
                                <Text type='bold' color={colors.secondaryPurple}>{el?.name}</Text>
                                <Row>
                                    <Progress value={ticketPercentages[el.id]} />
                                    <Text type='bold' color={colors.secondaryPurple} marginLeft={'10px'}>
                                        {Math.round(ticketPercentages[el.id])}%
                                    </Text>
                                </Row>
                            </ContentProgressData>
                        ))}
                    </ContainerProgressData>

                    <ContentLots>
                        <Column marginTop='45px'>
                            <Text name='subtitle' color={colors.night}>Tipos de ingressos</Text>
                            <Text color={colors.night}>Acompanhe todos os seus ingressos e saiba o montante arrecadado e previsto por cada tipo de ingresso.</Text>
                        </Column>

                        <StyledTitles>
                            <ContentTitles tickets>
                                <Text>Tipos</Text>
                            </ContentTitles>
                                <ContentTitles>
                                    <Text>Valor arrecadado</Text>
                                </ContentTitles>
                            <ContentTitles>
                                <Text>Valor estimado</Text>
                            </ContentTitles>
                        </StyledTitles>

                        {tickets?.map((el) => (
                            <TicketsTypes key={el.id}>
                                <Row>
                                    <ContentTickets>
                                        <CardTickets data={el} />
                                    </ContentTickets>
                                   
                                </Row>

                                <ContentValues>
                                  
                                        <TicketsValues>
                                            <StyledValues collected>
                                                <TextTitlesValues>
                                                    <Text color={colors.night}>Valor arrecadado</Text>
                                                </TextTitlesValues>
                                                <Text color={colors.night}>{currency.format(ticketTotals[el.id]?.collected || 0)}</Text>
                                            </StyledValues>
                                        </TicketsValues>
                                    
                                    <TicketsValues>
                                        <StyledValues>
                                            <TextTitlesValues>
                                                <Text color={colors.night}>Valor estimado</Text>
                                            </TextTitlesValues>
                                            <Text color={colors.night}>{currency.format(ticketTotals[el.id]?.estimated || 0)}</Text>
                                        </StyledValues>
                                    </TicketsValues>
                                </ContentValues>
                            </TicketsTypes>
                        ))}
                    </ContentLots>
                </ContainerDetail>
            ) : (
                <ContentLoader>
                    <Loader />
                </ContentLoader>
            )}
        </>
    );
}



const ContainerDetail = styled(Column)`
    width: 100%;
`;

const StyledData = styled(Column)`
    margin-top: 30px;
    width: 100%;


    & > div > div > div > h1 {
        display: flex;
        align-items: center;
    }
`;

const ContentTickets = styled.div`
    width: 100%;
    padding-right: 20px;
    @media screen and (min-width: ${breakpoints.xm}) and (max-width: 375px) {
        width: 100%;
    }

    @media screen and (min-width: ${breakpoints.sm}) {
        width: 60%;
        padding-right: 20px;
    }
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 100%;
        border-right: 1px solid ${colors.nightLight};
        padding-right: 16px;
    }
    @media screen and (min-width: ${breakpoints.xl}) {
        padding-right: 20px;
        & > div {
            width: 100%;
        }
    }
`;

const ContentData = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap !important;
`;

const StyledProgressSale = styled.div`
    display: flex;
    flex-direction: row;
`;

const ContentTicketToSell = styled.div`
    display: flex;
    flex-direction: row;
`;

const ContainerProgressData = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    @media screen and (min-width: ${breakpoints.sm}) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
`;

const StyledTotals = styled(Column)`
    width: 100%;

    @media screen and (min-width: ${breakpoints.sm}) {
        width: 50%;
    }
`

const ContentProgressData = styled.div`
    width: 100%;
    margin-top: 12px;

    &:first-child {
        margin-top: 0;
    }
    @media screen and (min-width: ${breakpoints.sm}) {
        width: 220px;
        margin-top: 0;
        margin-right: 20px;

    }
`;

const ContentLots = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const TicketsTypes = styled(Column)`
    border-bottom: 2px solid ${colors.nightLight};
    margin-top: 25px;
    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: row;
        border-bottom: unset;
    }
`;

const TicketsValues = styled(Row)`
    margin-top: 20px;
    justify-content: space-around;
    padding-bottom: 20px;
    @media screen and (min-width: ${breakpoints.lg}) {
        padding-bottom: unset;
        margin: unset;
    }
    width: 40%;
`;

const StyledValues = styled(Column)`
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    @media screen and (min-width: ${breakpoints.lg}) {
        align-items: flex-start;
        padding-left: 30px;
    }
    ${({ collected }) => collected && `
        border-right: 1px solid ${colors.nightLight};
        @media screen and (min-width: ${breakpoints.md}) {
            padding-left: 0px;
            border-right: 1px solid ${colors.nightLight};
        }
    `}
    @media screen and (min-width: ${breakpoints.lg}) {
        margin: unset;
    }
`;

const TextTitlesValues = styled.div`
    display: flex;
    @media screen and (min-width: ${breakpoints.lg}) {
        display: none;
    }
`;

const StyledTitles = styled(Row)`
    display: none;
    width: 100%;
    @media screen and (min-width: ${breakpoints.lg}) {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
    }
`;

const ContentTitles = styled(Row)`
    justify-content: flex-start;
   
    & > p {
        font-size: ${fonts.sizes.highlight};
        color: ${colors.night}
    }

    @media screen and (min-width: ${breakpoints.md}) {
        width: 25%;
    }

    ${({ tickets }) => tickets && `
        justify-content: flex-start;
        margin-left: 10px;

        @media screen and (min-width: ${breakpoints.md}) {
            width: 50%;
            
        }
    `}

`;

const ContentValues = styled(Row)`
    justify-content: center;
`;

const ContentLoader = styled(Column)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;