import React from 'react';
import PresentationBackground from 'modules/onboarding/common/PresentationBackground';
import StepRegisterProvider from 'context/login/StepRegister';
import SignIn from 'modules/onboarding/sign-in/SignIn';

function Login() {

    return (
            <StepRegisterProvider>
                <PresentationBackground 
                    ComponentToRender={() => <SignIn />} 
                    is='signIn'
                />
            </StepRegisterProvider>
        
    )
};

export default Login;

