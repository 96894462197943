import React, { useState } from 'react'
import styled from 'styled-components';
import EventsResume from './EventsResume';
import { useEffect } from 'react';
import eventsApi from 'api/requests/events';
import Loader from 'components/_UI/Loader';
import { FullyCentered } from 'styled/alignment/Center';
import NotResultDashBoard from 'components/commons/NotResultDashBoard';
import { useListProducts } from 'context/products/ListProducts';
import paths from 'routes/paths';
import productsApi from 'api/requests/products';

export default function MainContent() {
    const [myEvents, setMyEvents] = useState(null)
    const [loading, setLoading] = useState(null)
    const {listProducts, setListProducts} = useListProducts()
    const [hasProducts, setHasProducts] = useState(false)


    useEffect(() => {
        getEvents()
        getProducts()
    }, [])

    async function getEvents() {
        setLoading(true)
        try {
            const { data } = await eventsApi.myEvents()
            setMyEvents(data.data)
        } catch (error) {
            console.log('error')
        } finally {
            setLoading(false)
        }
    }

    async function getProducts() {
        setLoading(true)

        try {
            const {data} = await productsApi.get()
            setListProducts(data?.data);
            if(data?.data.length === 0) {
                setHasProducts(true)
            }
        } catch (error) {
            console.log('error')
        } finally {
            setLoading(false)
        }
    }



    return (
        loading ? (
            <ViewContainer>
                <Loader />
            </ViewContainer>
        ) : (
            <>
                <StyledContainer>

                    <EventsResume data={myEvents} />
                  {
                    hasProducts &&
                        <NotResultDashBoard 
                            text={'Sem produtos cadastrados? Cadastre seu primeiro produto e maximize suas vendas durante a compra do ingresso.'}
                            textButton={'Adicionar produto'}
                            ilustration={'stock'}
                            reverse
                            marginTop={'15px'}
                            path={`${paths.dashboard.products}?create=1`}
                        />
                  }
                </StyledContainer>
            </>
        )
    )
}

const StyledContainer = styled.div`
    width: 100%;
    margin-top: 10px;
`;

const ViewContainer = styled(FullyCentered)`
    width: 100%;
    height: 500px;
`;