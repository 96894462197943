import Fieldset from 'components/_UI/Fieldset';
import Modal from 'components/_UI/Modal';
import Text from 'components/_UI/Text';
import React, { useEffect, useState } from 'react';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import Select from 'react-select';
import styled from 'styled-components';
import Button from 'components/_UI/Button';
import { useForm } from 'react-hook-form';
import fieldValidations from 'helpers/fieldValidations';
import colors from 'theme/colors';
import { FullyCentered } from 'styled/alignment/Center';
import Loader from 'components/_UI/Loader';
import buttonText from 'texts/buttons/buttonText';
import { PlusSquare, Trash2 } from 'react-feather';
import Switch from 'components/_UI/Switch';
import fonts from 'theme/fonts';
import { useEvent } from 'context/events/Event';
import customFieldsApi from 'api/requests/customFields';
import customFieldsTypes from 'theme/customFieldsTypes';

export default function CustomFieldsForm({ visible, isEdit, toast, onClose, onSuccess }) {
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([{ id: Date.now(), type: null, label: '', required: false, options: [] }]);

    const {event} = useEvent();
    
    const { 
        register, 
        handleSubmit, 
        watch, 
        reset, 
        formState: { errors } 
    } = useForm({defaultValues: {}});

    useEffect(() => {
        if (isEdit) {
            const updatedFields = isEdit.map(field => ({
                ...field,
                type: customFieldsTypes.find(type => type.value === field.type),
                options: field.options 
                    ? field.options.map(option => ({ id: Date.now() + Math.random(), label: option })) 
                    : []
            }));
            
            const defaultValues = updatedFields.reduce((acc, field) => {
                acc[`field_${field.id}_label`] = field.label; 
                field.options.forEach((option, index) => {
                    acc[`field_${field.id}_option_${index}_label`] = option.label; 
                });
                return acc;
            }, {});
    
            reset(defaultValues);  
            setFields(updatedFields);
        }
    }, [isEdit, reset]);

    
    const addField = () => {
        setFields([...fields, { id: Date.now(), type: null, label: '', required: false, options: [] }]);
    };

    const removeField = (id) => {
        if (fields.length > 1) {
            const updatedFields = fields.filter((field) => field.id !== id);
            setFields(updatedFields);
        }
    };

    const toggleRequired = (id) => {
        const updatedFields = fields.map((field) =>
            field.id === id ? { ...field, required: !field.required } : field
        );
        setFields(updatedFields);
    };

    const addOption = (fieldId) => {
        const updatedFields = fields.map((field) =>
            field.id === fieldId
                ? { ...field, options: [...field.options, { id: Date.now(), label: '' }] }
                : field
        );
        setFields(updatedFields);
    };

    const removeOption = (fieldId, optionId) => {
        const updatedFields = fields.map((field) =>
            field.id === fieldId
                ? { ...field, options: field.options.filter(option => option.id !== optionId) }
                : field
        );
        setFields(updatedFields);
    };

    const updateOptionLabel = (fieldId, optionId, label) => {
        const updatedFields = fields.map((field) =>
            field.id === fieldId
                ? {
                    ...field,
                    options: field.options.map(option =>
                        option.id === optionId ? { ...option, label } : option
                    )
                }
                : field
        );
        setFields(updatedFields);
    };

    async function onSubmitForm() {
        const content = fields.map(field => ({
            label: watch(`field_${field.id}_label`),  
            type: field.type?.value,
            required: field.required,
            options: field.options.map(option => option.label)
        }));

        const payload = {
            event_id: event?.id,
            custom_fields: content
        }
        
        setLoading(true);

        try {
            await customFieldsApi.create(payload)
            onSuccess();
            toast.success('Formulário criado com sucesso!', {
                position: 'top-center'
            })
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
    }

    async function onEditSubmit() {
        const content = fields.map(field => ({
            label: watch(`field_${field.id}_label`),  
            type: field.type?.value,
            required: field.required,
            options: field.options.map(option => option.label)
        }));

        const payload = {
            event_id: event?.id,
            custom_fields: content
        }
        
        setLoading(true);

        try {
            await customFieldsApi.update(payload)
            onSuccess();
            toast.success('Formulário atualizado com sucesso!', {
                position: 'top-center'
            })
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal isOpen={visible} onClose={onClose} size='medium'>
            <Text name={'highlight'} marginBottom={'40px'}>Campos adicionais</Text>

            <form style={{ width: 'auto' }} onSubmit={handleSubmit(!isEdit ? onSubmitForm : onEditSubmit)}>
                <Column width='auto'>
                    {fields.map((field, index) => (
                        <Column
                            key={field.id}
                            style={{
                                borderBottom:`1px solid #ddd`,
                                marginBottom: '15px' ,
                                paddingBottom: '15px'
                            }}>
                            <Row alignItems="center">
                                <Column style={{ textAlign: 'left', marginRight: '12px' }}>
                                    <Text name='small' color={colors.nightDark} type='bold'>Tipo de campo</Text>
                                    <CustomSelect>
                                        <Select
                                            placeholder='Tipo de campo'
                                            value={field.type}
                                            options={customFieldsTypes}
                                            onChange={(selectedType) => {
                                                const updatedFields = fields.map((f) => {
                                                    if (f.id === field.id) {
                                                        const isMultipleChoice = selectedType.value === 'radio' || selectedType.value === 'checkbox';
                                                        return {
                                                            ...f,
                                                            type: selectedType,
                                                            options: isMultipleChoice
                                                                ? [{ id: Date.now(), label: '' }, { id: Date.now() + 1, label: '' }]
                                                                : []
                                                        };
                                                    }
                                                    return f;
                                                });
                                                setFields(updatedFields);
                                            }}
                                        />
                                    </CustomSelect>
                                </Column>
                            
                                <Column style={{marginTop: '-2px'}}>
                                    <Fieldset
                                            label='Nome do campo'
                                            layout='squired'
                                            placeholder='Nome do campo'
                                            register={register}
                                            name={`field_${field.id}_label`}
                                            validations={fieldValidations.name('Nome do campo')}
                                        />
                                        {errors[`field_${field.id}_label`]?.message && (
                                            <Column>
                                                <Text color={colors.danger} name='small'>
                                                    {errors[`field_${field.id}_label`]?.message}
                                                </Text>
                                            </Column>
                                        )}
                                    </Column>

                                <TrashIcon
                                    onClick={() => removeField(field.id)}
                                    disabled={fields.length === 1}
                                >
                                    <Trash2 size='20px' color={fields.length === 1 ? colors.nightLight : colors.danger} />
                                </TrashIcon>
                            </Row>

                            {(field.type?.value === 'radio' || field.type?.value === 'checkbox') && (
                                <Column style={{ marginTop: '10px' }}>
                                    <Text name='small' color={colors.nightDark} textAlign={'left'}>
                                        Opções
                                    </Text>
                                    {field.options.map((option, index) => (
                                        <Row key={option.id} alignItems="center" style={{ marginBottom: '8px' }}>
                                            <Text paddingBottom={0}>{index + 1}. </Text>
                                            <Fieldset
                                                placeholder={`Opção ${index + 1}`}
                                                value={option.label}
                                                onChangeValue={(e) => updateOptionLabel(field.id, option.id, e)}
                                            />
                                            <TrashIcon onClick={() => removeOption(field.id, option.id)}>
                                                <Trash2 size='18px' color={colors.danger} />
                                            </TrashIcon>
                                        </Row>
                                    ))}
                                    <NewOption onClick={(e) => {
                                        e.preventDefault();
                                        addOption(field.id);
                                    }}>
                                        Adicionar nova opção
                                    </NewOption>
                                </Column>
                            )}

                            <Switch
                                marginTop='4px'
                                label='Campo obrigatório'
                                labelSize='small'
                                checked={field.required}
                                onChange={() => toggleRequired(field.id)}
                            />
                        </Column>
                    ))}
                </Column>

                <CustomButtons>
                    <Button
                        category={'squiredBorded'}
                        onClick={addField}
                        disabled={!fields.length || !(fields[fields.length - 1].type && fields[fields.length - 1].label)}
                    >
                        <PlusSquare size='20px' />
                        <Text color={colors.occasionalPurple} paddingBottom='0' marginLeft={'6px'}>
                            Adicionar novo campo
                        </Text>
                    </Button>
                </CustomButtons>

                {loading ? (
                    <FullyCentered height='40px'>
                        <Loader />
                    </FullyCentered>
                ) : (
                    <ButtonsContainer>
                        <Button variant={'light'} category={'roundedBorded'} onClick={onClose}>
                            {buttonText.cancel}
                        </Button>
                        <Button category={'roundedBorded'} type={'submit'}>
                            {!isEdit ? buttonText.create : buttonText.update} formulário
                        </Button>
                    </ButtonsContainer>
                )}
            </form>
        </Modal>
    );
};



const TrashIcon = styled.div`
    cursor: pointer;
    margin-left: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;
`;

const CustomSelect = styled.div`
    width: 100%;
    font-size: ${fonts.sizes.default};
    & > div > div {
        text-align: left;
    }

    & > div:nth-child(1) {
        .css-1nmdiq5-menu {
            overflow: auto !important;
            height: 200px;
        }
     }
   
`;

const CustomButtons = styled(FullyCentered)`
    flex-direction: row;
    width: 100%;
    margin-top: 14px;
    & > button {
        border: 1px solid ${colors.occasionalPurple};
        background: transparent;
        color: ${colors.occasionalPurple} !important;
        transition: all ease-in-out .25s;
        &:hover {
            border: 1px solid ${colors.occasionalPurple};
            background: transparent;
            color: ${colors.occasionalPurple} !important;
            opacity: .8;
        }
    }
`;

const ButtonsContainer = styled(Column)`
    margin-top: 20px;
    flex-direction: row;
    button:nth-child(2) {
        margin-left: 10px;
    }
`;

const NewOption = styled.button`
    color: ${colors.primaryLight};
    border-bottom: 1px solid ${colors.primaryLight};
    width: 130px;
    text-align: left;
    background: transparent;
    margin-bottom: 15px;
`;

// const OptionsContainer = styled(Row)`
//     flex-wrap: wrap;

//     & > div {
//         width: 48%;
//     }
    
//     & > div:first-child {
//         margin-right: 2%;
//     }
// `;