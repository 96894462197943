import React, { useEffect, useState } from 'react';
import Button from 'components/_UI/Button';
import Fieldset from 'components/_UI/Fieldset';
import Text from 'components/_UI/Text';
import { useStepRegister } from 'context/login/StepRegister';
import { useUser } from 'context/user/User';
import masks from 'helpers/masks';
import { Column } from 'styled/alignment/Column';
import { StyledForm } from 'styled/UI/StyledForm';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import { useForm } from 'react-hook-form';
import { FullyCentered, HorizontalCenter } from 'styled/alignment/Center';
import fieldValidations from 'helpers/fieldValidations';
import buttonText from 'texts/buttons/buttonText';
import step from 'helpers/system/step';
import paths from 'routes/paths';
import userApi from 'api/requests/user';
import Loader from 'components/_UI/Loader';
import fieldMessages from 'texts/messages/field';
import { useNavigate } from 'react-router-dom';

const errorMessages = {
    unique: 'E-mail já cadastrado',
    fail: 'Ocorreu um erro, tente novamente.'
}

export default function FastOnboarding() {
    const navegate = useNavigate()
    const { stepRegister, setStepRegister } = useStepRegister();
    const { user, setUser } = useUser();

    const [validateEmailError, setValidateEmailError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false)
    const [equalPasswordError, setEqualPasswordError] = useState(false)
    const [back,setBack] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            firstName: user?.first_name || '',
            lastName: user?.last_name || '',
            email: user?.email || '',
        }
    });

    useEffect(() => {
     if(!back) {
        reset({
            firstName: '',
            lastName: '',
            email: ''
        })
     }
    }, [back])
    

    async function validateData({ firstName, lastName, password, confirmPassword, email }) {
        let hasError = false

        setLoading(true)

        try {
            const { data } = await userApi.dataEnable(
                {
                    email,
                });
            hasError = !data.email
        } catch (error) {
            hasError = 'fail'
        } finally {
            setLoading(false)
        }

        if (hasError === 'fail') {
            setValidateEmailError(true)
            return;
        }

        if (hasError) {
            setEmailError(true)
            return
        }

        nextStep(firstName, lastName, password, confirmPassword, email)
    }

    function onBack() {
        reset({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: ''
        })
        navegate(paths.login)
    }

    const nextStep = (firstName, lastName, password, confirmPassword, email) => {
        setValidateEmailError(false)
        setEmailError(false)
        setEqualPasswordError(false)
        
        if (password !== confirmPassword) {
            setEqualPasswordError(true)   
            return
        }
        
        setUser({
            ...user,
            first_name: firstName,
            last_name: lastName,
            password,
            email
        });
        setBack(true)
        setStepRegister(step.next(stepRegister))
    };

    return (
        <>
            <Column verticalCenter>
                <Text color={colors.nightDark} type='bold' name='highlight'>Para começarmos,</Text>
                <Text color={colors.nightDark} type='bold' name='highlight'>preencha suas informações básicas.</Text>
            </Column>

            <StyledForm
                marginTop={sizes.normal}
                paddingRight='13%'
                paddingLeft='13%'
                onSubmit={handleSubmit(validateData)}
            >
                <Fieldset
                    placeholder='Nome *'
                    layout='straight-center-dark'
                    marginTop={sizes.normal}
                    name="firstName"
                    register={register}
                    validations={fieldValidations.name()}
                />
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors.firstName?.message} </Text>
                </HorizontalCenter>

                <Fieldset
                    placeholder='Sobrenome *'
                    layout='straight-center-dark'
                    marginTop={sizes.normal}
                    name="lastName"
                    register={register}
                    validations={fieldValidations.name('sobrenome')}
                />

                <HorizontalCenter>
                    <Text color={colors.danger}> {errors.lastName?.message} </Text>
                </HorizontalCenter>

                <Fieldset
                    placeholder='E-mail *'
                    layout='straight-center-dark'
                    marginTop={sizes.normal}
                    name='email'
                    register={register}
                    validations={fieldValidations.email()}
                />

                {
                    errors.email?.message && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.email?.message} </Text>
                        </HorizontalCenter>
                    )
                }

                {
                    emailError && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errorMessages.unique} </Text>
                        </HorizontalCenter>
                    )
                }

                <Fieldset
                    placeholder='Senha *'
                    layout='straight-center-dark'
                    marginTop={sizes.normal}
                    inputType='password'
                    badgeIconPassword
                    badgeIconColorPassword={colors.night}
                    badgeIconPositionPassword={'right'}
                    name='password'
                    register={register}
                    validations={fieldValidations.password('senha')}
                />
                {
                    errors.password?.message && (
                        <FullyCentered textAlign='center'>
                            <Text color={colors.danger}> {errors.password?.message} </Text>
                        </FullyCentered>
                    )
                }

                <Fieldset
                    placeholder='Confirme sua senha *'
                    layout='straight-center-dark'
                    marginTop={sizes.normal}
                    inputType='password'
                    name='confirmPassword'
                    badgeIconPassword
                    badgeIconColorPassword={colors.night}
                    badgeIconPositionPassword={'right'}
                    register={register}
                    validations={fieldValidations.password('confirmação de senha')}
                />
                {
                    errors.confirmPassword?.message && (
                        <FullyCentered textAlign='center'>
                            <Text color={colors.danger}> {errors.confirmPassword?.message} </Text>
                        </FullyCentered>
                    )
                }
                
                {
                    equalPasswordError && (
                        <FullyCentered textAlign='center'>
                            <Text color={colors.danger}> {fieldMessages.passwordMustBeEqual} </Text>
                        </FullyCentered>
                    )
                }

                {/* <FullyCentered marginTop={sizes.micro}>
                    <Text
                        color={colors.danger}
                        type='bold'
                    >
                        {errorMessage}
                    </Text>
                </FullyCentered> */}
                {
                    loading
                        ? <Loader />
                        : (
                            <>
                                <Button
                                    category='roundedBorded'
                                    variant='primary'
                                    size='large'
                                    marginTop={sizes.big}
                                    type='submit'
                                >
                                    {buttonText.confirm}
                                </Button>

                                <Button
                                    onClick={() => onBack()}
                                    category='minimalDark'
                                    size='large'
                                    marginTop={sizes.nano}
                                >
                                    {buttonText.back}
                                </Button>

                            </>
                        )
                }

                {validateEmailError &&
                    <FullyCentered>
                        <Text color={colors.danger}>{errorMessages.fail}</Text>
                    </FullyCentered>
                }
            </StyledForm>
        </>
    )
};