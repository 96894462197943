import React, { useEffect, useState } from 'react'
import paths from 'routes/paths';
import Button from 'components/_UI/Button'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import Modal from 'components/_UI/Modal';
import Text from 'components/_UI/Text';
import Fieldset from 'components/_UI/Fieldset';
import colors from 'theme/colors';
import { Row } from 'styled/alignment/Row';
import currency from 'helpers/currency';
import { useWithdraw } from 'context/financial/Withdraw';
import CurrencyInputField from 'components/_UI/CurrencyInputField';
import WithdrawModal from '../withdraw/WithdrawModal';
import { useUser } from 'context/user/User';
import { useMyEstablishment } from 'context/establishments/MyEstablishment';
import ToastContainer from 'components/_UI/ToastsContainer';
import useToast from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { DollarSign } from 'react-feather';
import { FullyCentered } from 'styled/alignment/Center';
import Icon from 'components/_UI/Icon';


export default function RightSlotFinancial() {
    const navigate = useNavigate()
    const { toast, toasts } = useToast()
    const [withdrawModal, setWithdrawModal] = useState(false);
    const { user } = useUser()
    // const { myEstablishment } = useMyEstablishment()

    function handleBankClick() {
        // if (myEstablishment.length > 0 ) {
        navigate(`${paths.dashboard.financial}?dados-bancarios=1`)
        // }
    }

    return (
        <RightSlotContainer>
            <ToastContainer toasts={toasts} />
            <ButtonContainer bank>
                <Button
                    marginBottom='0'
                    category='squiredBorded'
                    // variant={myEstablishment?.length > 0  ? 'primary' : 'disabled'}
                    onClick={handleBankClick}
                >

                    <Row fullyCentralized height='18px'>
                        <ContentIconBank>
                            <Icon name='bank' color={colors.neutralLight}/>
                        </ContentIconBank>
                        <Text color={colors.neutral} marginLeft={'6px'} paddingBottom={0}>
                            Dados bancários
                        </Text>
                    </Row>

                </Button>
            </ButtonContainer>
            <ButtonContainer>
                <Button
                    marginBottom='0'
                    category='squiredBorded'
                    onClick={() => setWithdrawModal(true)}
                    variant={user?.has_bank_account ? 'primary' : 'disabled'}
                >
                    <DollarSign
                        size={18}
                        color={user?.has_bank_account ? colors.neutral : colors.night}
                    />
                    <Text
                        color={user?.has_bank_account ? colors.neutral : colors.night}
                        marginLeft={'6px'}
                        paddingBottom={0}
                    >
                        Efetuar saque
                    </Text>
                </Button>
            </ButtonContainer>

            <WithdrawModal visible={withdrawModal} onClose={() => setWithdrawModal(false)} onSucess={() => toast.success('Saque efetuado com sucesso.', { position: 'top-center' })} />

        </RightSlotContainer >
    )
}

const RightSlotContainer = styled(Column)`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1rem;

    & > button {
        margin-bottom: 0 !important;
    }

    @media screen and (min-width: ${breakpoints.md}){
        width: auto;
        flex-direction: row;
    }

`;

const ButtonContainer = styled.div`
    width: 100%;
    white-space: nowrap;
    & > button {
            height: 35px;
            padding: 0;
    }
    @media screen and (min-width: ${breakpoints.md}){
        width: 165px;
        height: 100%;
        & > button {
            height: 35px;
            padding: 0;
        }
    }
    ${({ bank }) => bank && `
        width: 100%;
        & > a >  button {
            height: 35px;
            padding: 0;
        }
        @media screen and (min-width: ${breakpoints.md}){
            width: 165px;
            height: 100%;
            & > a > button {
                height: 35px;
                padding: 0;
            }
        }
        @media screen and (min-width: ${breakpoints.lg}){
            & > a > button {
                height: 35px;
            }
    
        }
        @media screen and (min-width: ${breakpoints.xl}){
            & > a > button {
                height: 35px;
            }
            margin-right: 0;
        }  
            
    `}
    @media screen and (min-width: ${breakpoints.lg}){
        & > a > button {
            height: 35px;
        }
        /* margin-right: 11px; */
    }
    @media screen and (min-width: ${breakpoints.xl}){
        & > a >  button {
            height: 35px;
        }
        margin-right: 0;
    }  
`;

const ContentIconBank = styled.div`
    width: 18px;
    height: 18px;
`
