import financialApi from 'api/requests/financial';
import Button from 'components/_UI/Button';
import CurrencyInputField from 'components/_UI/CurrencyInputField';
import Loader from 'components/_UI/Loader';
import Modal from 'components/_UI/Modal';
import Text from 'components/_UI/Text';
import { useWithdraw } from 'context/financial/Withdraw';
import currency from 'helpers/currency';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

let convertWithValue = null

export default function WithdrawModal({ visible, onClose, onSucess }) {
    const { withdraw, setWithdraw } = useWithdraw();

    const [withdrawValue, setWithdrawValue] = useState();
    const [invalidField, setInvalidField] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => { }, [withdraw?.availableValue])


    function isValidValue() {
        
        if(!withdrawValue) {
            setInvalidField('Preencha o campo para efetuar o saque!')
            return;
        }

        convertWithValue = Number(withdrawValue.replace(',', '.'))
        if (convertWithValue < 0 || isNaN(convertWithValue)) {
            setInvalidField('Insira um valor valido')
            return false;
        }

        if (convertWithValue > withdraw.availableValue) {
            setInvalidField(`O valor disponivel para saque é ${currency.format(withdraw.availableValue)}`)
            return false;
        }

        if(convertWithValue < 5) {
            setInvalidField('O valor mínimo de saque é R$ 5,00')
            return false
        }

        return true;
    }

    async function onWithdraw() {
        
        if (!isValidValue()) {
            return
        }
        
        setLoading(true);

        try {
            
            await financialApi.withdraw({ value: convertWithValue });
            setWithdraw({ ...withdraw, success: Math.random(Infinity) });
            onSucess()
            onClose();

        } catch (error) {
            setInvalidField('Ocorreu um erro ao fazer o saque, tente novamente.')
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal isOpen={visible} onClose={() => onClose(false)}>
            <Text name='highlight' marginBottom={'10px'}> Efetue seu saque </Text>

            <CurrencyInputField
                label='Valor do saque'
                placeholder={currency.format(withdraw?.availableValue)}
                onChange={e => setWithdrawValue(e)}
            />
            {
                invalidField && !loading && (
                    <Text color={colors.danger}> {invalidField} </Text>
                )
            }

            <Text
                color={colors.success}
                marginBottom={'10px'}
                marginTop={'10px'}
            >
                Seu valor atual disponivel para saque é: {currency.format(withdraw?.availableValue)}
            </Text>

            {
                loading ? (
                    <FullyCentered marginTop='20px'>
                        <Loader />
                    </FullyCentered>
                ) : (
                    <RowButtons>
                        <Button
                            variant='light'
                            marginRight={'10px'}
                            onClick={() => onClose(false)}
                        >
                            Cancelar
                        </Button>

                        <Button
                            variant={withdraw?.availableValue > 0 && withdrawValue ? 'lightBlue' : 'disabled' }
                            onClick={onWithdraw}
                        >
                            Efetuar saque
                        </Button>
                    </RowButtons>
                )
            }

        </Modal >
    )
}

const RowButtons = styled(Column)`
    & > button {
        box-shadow: unset;
    }
    
    @media screen and (min-width: ${breakpoints.lg}){
        flex-direction: row;    
    }
    
    & > button:first-child{
        display: none;
        @media screen and (min-width: ${breakpoints.lg}){
            display: flex;
         }
    }
`;