import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components'
import { Row } from 'styled/alignment/Row'
import FilterGroupList from './FilterGroupList'
import { FullyCentered } from 'styled/alignment/Center'
import colors from 'theme/colors'

export default function FiltersResult({ closeDropdown, expiredEvents, nextEvents, nowEvents }) {

    return (
        <StyledResult>
            {
                !expiredEvents?.total && !nextEvents?.total && !nowEvents?.total
                    ?
                    <FullyCentered>
                        <Text marginTop={'20px'} color={colors.primaryDashboard}>Nenhum resultado encontrado</Text>
                    </FullyCentered>
                    :
                    <FilterGroupList
                        closeDropdown={closeDropdown}
                        expiredEvents={expiredEvents}
                        nextEvents={nextEvents}
                        nowEvents={nowEvents}
                    />
            }
        </StyledResult>
    )
}

const StyledResult = styled(Row)`
    display: flex;
    background: transparent;
    padding: 10px;
    border-radius: 4px;
    &:first-child {
        padding-top: 0;
    }
`;




