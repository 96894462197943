import React, { createContext, useState, useContext } from 'react';
import tabs from 'theme/tabs';

const GlobalLoadingContext = createContext();

export default function GlobalLoadingProvider({ children }) {
    const [globalLoading, setGlobalLoading] = useState(false);

    return (
        <GlobalLoadingContext.Provider value={{ globalLoading, setGlobalLoading }}>
            {children}
        </GlobalLoadingContext.Provider>
    );
}

export function useGlobalLoading() {
    const context = useContext(GlobalLoadingContext);

    if (!context) throw new Error('useGlobalLoading must be used within a GlobalLoadingProvider');

    const { globalLoading, setGlobalLoading } = context;
    return { globalLoading, setGlobalLoading };
}