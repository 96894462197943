import styled from 'styled-components';
import { Row } from 'styled/alignment/Row';
import marginProps from 'styled/shared-props/margin';
import colors from 'theme/colors';
import fonts from 'theme/fonts';
import sizes from 'theme/sizes';

export const StyledPaginate = styled(Row)`
   ${marginProps}
    height: ${sizes.immense};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const StyledContentPaginate = styled(Row)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledContentButton = styled(Row)`
    width: ${sizes.immense};
    height: ${sizes.immense};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledButtonAction = styled.button`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid ${colors.primaryDashboard};
    background-color: white; 
    color: ${colors.primaryDashboard}; 
    position: absolute;
   
    & > button {
        height: ${sizes.gigantic};
        width: ${sizes.gigantic};
    }

    ${({previous}) => previous && `
        margin-right: ${sizes.medium};
        padding-left: 15px; 
        &:hover {
            background: ${colors.primaryLight};
            color: ${colors.neutralLight};
            padding-left: 15px;
            & > div  {
                border-right: 2px solid ${colors.neutralLight};
                border-bottom: 2px solid ${colors.neutralLight};
            }
        }
    `}

    ${({next}) => next && `
        margin-left: ${sizes.medium};
        padding-left: 12px;    
        &:hover {
            background: ${colors.primaryLight};
            color: ${colors.neutralLight};
            padding-left: 12px;
            & > div  {
                border-right: 2px solid ${colors.neutralLight};
                border-bottom: 2px solid ${colors.neutralLight};
            }
        }
    `}

    
`;

export const StyledArrow = styled.div`
    width: 10px;
    height: 10px;
    background: ${colors.white};
    border-right: 2px solid ${colors.primaryLight};
    border-bottom: 2px solid ${colors.primaryLight};
    transform: rotate(130deg);
    right: 0 !important;
    ${({next}) => next && `
        transform: rotate(-45deg);
        border-right: 2px solid ${colors.primaryLight};
        border-bottom: 2px solid ${colors.primaryLight};
        top: 50%;
        left: 0; 
    `}
  

`;

export const StyledButtonPage = styled.button`
    height: ${sizes.gigantic};
    width: ${sizes.gigantic};
    position: absolute;
    background: ${colors.neutralLight};
    border: 1px solid ${colors.primaryDashboard};
    color: ${colors.primary};
    border-radius: 50%;
    font-size: ${fonts.sizes.default};
    font-weight: ${fonts.weight.bold};
    &:hover {
        background: ${colors.primaryLight};
        border: ${colors.neutralLight};
        color: ${colors.neutralLight};
    }
    ${({active}) => active && `
        pointer-events: none;
        background: ${colors.primaryLight};
        border: ${colors.neutralLight};
        color: ${colors.neutralLight};
    `}

`;

