import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import React, { useEffect, useState } from 'react'
import colors from 'theme/colors'
import routeParams from 'theme/routeParams';

export default function InvitePermissionAlert({ visible, data, onClose }) {
    
    return (
        <Modal isOpen={!!visible} closeButton={true} onClose={onClose}>
            {
                data?.type == 'accept' && (
                    <>
                        <Text name='normal' type='bold' color={colors.successLight}>
                            Convite aceito
                        </Text>

                        <Text color={colors.night}>
                            Parabéns! Agora você tem permissão para validação no evento <b>"{data?.event}"</b>.
                        </Text>
                    </>
                )
            }

            {
                data?.type == 'reject' && (
                    <>
                        <Text name='normal' type='bold' color={colors.danger}>
                            Convite recusado
                        </Text>

                        <Text color={colors.night}>
                            Você recusou o convite para ter permissão de validação no evento <b>"{data?.event}"</b>.
                        </Text>
                    </>
                )
            }

            {
                data?.type == 'error' && (
                    <>
                        <Text name='normal' type='bold' color={colors.danger}>
                            Ocorreu um erro
                        </Text>

                        <Text color={colors.night}>
                            Ocorreu um erro inesperado durante o seu convite, entre em contato com o responsavel do evento.
                        </Text>
                    </>
                )
            }
        </Modal>
    )
}
