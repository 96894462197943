import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function Header({title}) {
  return (
    <StyledHeader>
            
        <ContentHeader>
            <Text name='subtitle'>{title}</Text>
        </ContentHeader>
    </StyledHeader>
  )
}

const StyledHeader = styled(Column)`
    width: 100%;
    justify-content: center;
    background: ${colors.neutralLight};
    position: relative;
`;

const ContentHeader = styled(Row)`
    width: 100%;
    justify-content: center; 
    padding: 15px 0px 15px 0;
    & > h3 {
        padding-bottom: 0px;
        font-weight: normal;
    }
`;
