const fieldMessages = {
    required: (value) => `O campo ${value} é obrigatório`,
    minValue: (value) => `No mínimo ${value} caracteres`,
    maxValue: (value) => `No máximo ${value} caracteres`,
    fillCorrectly: (value) => `Preencha o campo ${value} corretamente`,
    invalidFemaleField: (value) => `${value} inválida`,
    invalidMaleField: (value) => `${value} inválido`,
    invalidTime: (value) => `O campo ${value} deve ter o formato HH:MM.`,
    invalidUrl: 'Informe uma URL válida.',
    onlyAlphabetic: 'São válidos apenas caracteres alfabéticos',
    onlyNumber: 'São válidos apenas caracteres numéricos',
    passwordMustBeEqual: 'As senhas devem ser iguais',
    password: (value) => (
        <>
            <b>A {value} deve atender aos seguintes critérios:</b>
            <p>- Pelo menos 8 caracteres</p>
            <p>- Pelo menos uma letra maiúscula</p>
            <p>- Pelo menos um número</p>
            <p>- Pelo menos um caractere especial</p>
        </>
    ),
    generic: 'Ocorreu um erro. Tente novamente'
};

export default fieldMessages;