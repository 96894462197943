import React, { createContext, useState, useContext } from 'react';

const EventsNearToYouContext = createContext();

export default function EventsNearToYouProvider({ children }) {
    const [eventsNearToYou, setEventsNearToYou] = useState(null);

    return (
        <EventsNearToYouContext.Provider value={{ eventsNearToYou, setEventsNearToYou }}>
            {children}
        </EventsNearToYouContext.Provider>
    );
}

export function useEventsNearToYou() {
    const context = useContext(EventsNearToYouContext);

    if (!context) throw new Error('useEventsNearToYou must be used within a EventsNearToYouProvider');

    const { eventsNearToYou, setEventsNearToYou } = context;
    return { eventsNearToYou, setEventsNearToYou };
}