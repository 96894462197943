import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors';
import Text from './Text';
import Icon from './Icon';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import { useCollapse } from 'context/general/Collapse';
import { useNavigate } from 'react-router-dom';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';


export default function UtilityHeader({title, onBack = () => false}) {
    const navigate = useNavigate();

    const {collapse, setCollapse} = useCollapse();
    const {backButtonCollapse, setBackButtonCollapse} = useBackButtonCollapse()

    function onClose() {
        setCollapse({
            ...collapse,
            withContent: false,
            height: 'min',
            componentToRender: false,
            withHideCity: false
        });
        setBackButtonCollapse({ ...backButtonCollapse, visible: false })
        // add resete de url
    }

  return (
    <Container>
        <StyledBackButton mobile onClick={() => onBack()}>
            <Icon name='back' color={colors.night} />
        </StyledBackButton>

        <Text name='highlight' paddingBottom={'0px'}>{title}</Text>

        <StyledDesktop>
            <StyledBackButton desktop onClick={() => onBack()}>
                <Icon name='back' color={colors.night} />
            </StyledBackButton>

            <StyledButtonClose desktop onClick={() => onClose()}>
                <Icon name='close' color={colors.night}/>
            </StyledButtonClose>
        </StyledDesktop>

        <StyledButtonClose mobile onClick={() => onClose()}>
            <Icon name='close' color={colors.night}/>
        </StyledButtonClose>
    </Container>
  )
}

const Container = styled(Row)`
    width: 100%;
    background: ${colors.neutralLight};
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px 10px 10px;
    border-bottom: 1px solid ${colors.nightLight};

    @media screen and (min-width: ${breakpoints.lg}) {
        justify-content: center;
    }

`;

const StyledBackButton = styled(Column)`
   width: 27px;
   height: 27px;
   padding: 6px;

    ${({desktop}) => desktop && `
        display: flex;
        cursor: pointer;
    `}

    ${({mobile}) => mobile && `
        @media screen and (min-width: ${breakpoints.lg}) {
            display: none;
        }   
    `}
`;

const StyledButtonClose = styled(Column)`
    width: 30px;
    height: 30px;
    padding: 6px;

    ${({mobile}) => mobile && `
         @media screen and (min-width: ${breakpoints.lg}) {
            display: none;
        }   
    `}
    ${({desktop}) => desktop && `
        display: flex;
        cursor: pointer;
    `}
`;

const StyledDesktop = styled(Row)`
    display: none;
    @media screen and (min-width: ${breakpoints.lg}) {
        display: flex;
        position: absolute;
        align-items: center;
        width: 80px;
        right: 0;
        justify-content: space-around;
    }
`;
