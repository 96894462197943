import Loader from 'components/_UI/Loader';
import Modal from 'components/_UI/Modal';
import Text from 'components/_UI/Text';
import { useEventsList } from 'context/events/EventsList';
import { useCollapse } from 'context/general/Collapse';
import { useOnSelectMenu } from 'context/general/OnSelectMenu';
import Establishments from 'modules/establishment/Establishments';
import Events from 'modules/events/Events';
import Map from 'modules/events/map/Map';
import Users from 'modules/users/Users';
import EditProfile from 'modules/users/form/EditProfile';
import { DeleteAccountDialog } from 'modules/users/profile/DeleteAccountDialog';
import { UpdatePasswordDialog } from 'modules/users/profile/UpdatePasswordDialog';
import MainWallet from 'modules/users/wallet/MainWallet';
import React, { useState, useEffect } from 'react'
import { CheckCircle } from 'react-feather';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import paths from 'routes/paths';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import tabs from 'theme/tabs';
import indexPriority from 'theme/z-index';
import { Routes, Route, Outlet, Link } from 'react-router-dom';
import TopActions from './TopActions';
import Icon from 'components/_UI/Icon';
import SelectLocation from 'components/_UI/SelectLocation';
import locationApi from 'api/requests/location';
import { useCurrentLocation } from 'context/user/CurrentLocation';
import ModalLocation from './ModalLocation';
import { useShowLocationModal } from 'context/general/ShowLocationModal';
import { useUserLocation } from 'context/user/UserLocation';
import { useUserLocale } from 'context/user/UserLocale';
import Navbar from 'components/commons/Navbar';
import collaboratorsApi from 'api/requests/collaborators';
import useAuth from 'hooks/useAuth';
import routeParams from 'theme/routeParams';
import InvitePermissionAlert from './InvitePermissionAlert';
import Checkin from 'modules/dashboard/checkin/Checkin';
import { useInvisibleButton } from 'context/general/InvisibleButton';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';

export default function Main({ selectValidation, setSelectValidation }) {
    const [latitude, setLatitude] = useState(false)
    const [longitude, setLongitude] = useState(false);
    const [renderMap, setRenderMap] = useState(false);
    const [selectedTab, setSelectedTab] = useState(tabs.events.slug);
    const [dynamicData, setDynamicData] = useState(false);
    const { collapse, setCollapse } = useCollapse()
    const [renderTabCount, setRenderTabCount] = useState(0);
    const [filterClick, setFilterClick] = useState(false);
    const [eventClick, setEventClick] = useState(false);
    // const [touristicPlaceClick, setTouristicPlaceClick] = useState(false);
    const [establishmentClick, setEstablishmentClick] = useState(false);
    const [locationLoading, setLocationLoading] = useState(true);
    const [tabChange, setTabChange] = useState(false);

    const { eventsList, setEventsList } = useEventsList();
    const { onSelectMenu, setOnSelectMenu } = useOnSelectMenu();

    const [open, setOpen] = useState(false)
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [modalSucessPassword, setModalSucessPassword] = useState(false);
    const [invitePermissionModal, setInvitePermissionModal] = useState(false);

    const { showLocationModal, setShowLocationModal } = useShowLocationModal();
    const { currentLocation, setCurrentLocation } = useCurrentLocation();
    const { userLocation, setUserLocation } = useUserLocation();
    const { userLocale, setUserLocale } = useUserLocale();
    const [searchParams, setSearchParams] = useSearchParams();
    const {backButtonCollapse, setBackButtonCollapse} = useBackButtonCollapse()
    const { isAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    let cityAthorized = import.meta.env.VITE_PRODUCER_AUTHORIZED_CITIES.split(',');

    const isEventTab = location.pathname.includes(paths.events.home);
    const isEstablishment = location.pathname.includes(paths.establishments.home);

    useEffect(() => {
        let permissionObservable;

        navigator.permissions.query({ name: "geolocation" })
            .then((result) => {
                permissionObservable = result
                onPermissionChange(result)

                result.addEventListener('change', () => onPermissionChange(result));
            })
            .catch((err) => console.error('Permission query failed:', err));
        
        return () => {
            if (permissionObservable) {
                permissionObservable.removeEventListener('change', onPermissionChange)
            }
        }
    }, [])

    useEffect(() => {
        const permissionInvite = searchParams.get(routeParams.permissions.invitePermission);
        const permissionInviteEvent = searchParams.get(routeParams.permissions.invitePermissionEvent);
        
        if (permissionInvite) {
            setInvitePermissionModal({ type: permissionInvite, event: permissionInviteEvent });
        }

        if (onSelectMenu) {  
                      
            setSelectValidation(0)
            setCollapse({
                ...collapse,
                arrowPosition: true,
                withContent: false,
                height: 'min'
            });
    
            getDropdown(onSelectMenu);
            return
        }

        if (selectValidation > 0) {  
            
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => (
                    <ContentCheckin>
                        <Checkin minimal withParticipants />
                    </ContentCheckin>
                )
            });
            return
        }


        
    }, [onSelectMenu, selectedTab, showLocationModal, selectValidation]);


    function onClosePermissionModal() {
        setInvitePermissionModal(false);

        setSearchParams((prevSearchParams) => {
            const newSearchParams = new URLSearchParams(prevSearchParams);

            newSearchParams.delete(routeParams?.permissions?.invitePermission);
            newSearchParams.delete(routeParams?.permissions?.invitePermissionEvent);

            return newSearchParams;
        });
    }

    function getDropdown() {
       
        if (onSelectMenu?.tab === 'logout') {
            window.location.reload()
            localStorage.removeItem("token");

        }

        // if (onSelectMenu?.tab === 'delete-account') {
        //     setOpen(true)
        // }
        if (onSelectMenu?.tab === 'change-password') {
            setOpenChangePassword(true)
        }

        if (onSelectMenu?.tab === 'use-terms') {
            window.location.href = paths.terms.useTerms
        }

        if (onSelectMenu?.tab === 'privacy-policies') {
            window.location.href = paths.terms.policiePrivacy
        }

        if (onSelectMenu?.tab === 'wallet') {
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => <MainWallet home />
            })
        }

        if (onSelectMenu?.tab === 'profile') {
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => <Users home handleEditUser={editUser} />
            })
        }
    }

    async function getLocationModal(location) {
        if (currentLocation?.city) {
            return
        }
        try {
            const { data } = await locationApi.getLocation(location.lat, location.long)
            setCurrentLocation(data)
        } catch (error) {
            console.log('error')
        }
    }

    function upCollapse() {
        setCollapse({
            ...collapse,
            height: 'max',
            arrowPosition: 'both',
        })
    }

    function editUser(user) {
        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => <EditProfile data={user} />
        })
    }

    function getGeolocationFromUser() {
        setLocationLoading(true);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error)
        }
    };

    function showLocation() {
        setShowLocationModal(true)
    }

    function success({ coords }) {
        
        if (coords) {
            const { latitude, longitude } = coords;

            setLatitude(latitude);
            setLongitude(longitude);
            setRenderMap(true);

            const data = { lat: latitude, long: longitude }
            setUserLocation(data);
            getLocationModal(data);
            setLocationLoading(false);

            if (!userLocale) {
                setUserLocale(data);
            }
        }

    };

    function error(error) {
        if (error) {
            setLatitude(false);
            setLongitude(false);
            setRenderMap(false);
            localStorage.removeItem('userLocation')
            const formatedObject = {
                lat: import.meta.env.VITE_BASE_LAT,
                long: import.meta.env.VITE_BASE_LONG
            }
            setUserLocation(formatedObject);
            getLocationModal(formatedObject);
            setLocationLoading(false);

            if (!userLocale) {
                setUserLocale(formatedObject);
            }
        }
    };

    function createDynamicData(e) {
        if (!e) {
            return
        }
        setOnSelectMenu(false);
        setCollapse({
            ...collapse,
            withContent: false,
            height: 'min'
        })

        if (isEventTab) {
            setDynamicData(e);
        }

        if (isEstablishment) {
            setDynamicData(e);
        }
    }

    function onSelectedActionClick(e) {
        setOnSelectMenu(false);
        setCollapse({
            ...collapse,
            withContent: false,
            arrowPosition: true,
        })

        if (isEventTab) {
            setEventClick(e)

        }

        if (isEstablishment) {
            setEstablishmentClick(e)
        }

    }

    function onChangeTab(selected) {
        setOnSelectMenu(false);
        setBackButtonCollapse({
            ...backButtonCollapse,
            visible: false
        })
        setCollapse({
            ...collapse,
            withContent: false,
            arrowPosition: true,
        })

        if (selected === tabs.establishment.slug) {
            setSelectedTab(tabs.establishment.slug);
        }
        if (selected === tabs.events.slug) {
            setSelectedTab(tabs.events.slug);
        }
    }

    function onChangeModalLocation() {
        setEventClick(false);
        setEstablishmentClick(false);
    }

    function onLoadingChildrens(data) {
        createDynamicData(data)
    }

    function onRenderTabCount(count) {
        setRenderTabCount(count)
        // onTabChange(count)
        setTabChange(count)
    }

    function onPermissionChange (result) {
        if (result.state === 'granted') {
            getGeolocationFromUser()
        }

        if (!result.state || result.state === 'prompt' || result.state === 'denied') {
            const latitude = import.meta.env.VITE_BASE_LAT
            const longitude = import.meta.env.VITE_BASE_LONG
            const city = import.meta.env.VITE_BASE_CITY
            const data = {
                lat: latitude,
                long: longitude,
            }
            
            setLocationLoading(false);
            setLatitude(latitude);
            setLongitude(longitude);
            setUserLocale(data)
            setUserLocation(data)
            setCurrentLocation({
                city
            })
            setRenderMap(true)
        }
    }

    return (
        <Container>
            {
                !!currentLocation?.city &&
                <ContentSelectLocation>
                    <SelectLocation onClick={showLocation} textButton={currentLocation?.city} />
                </ContentSelectLocation>
            }

            <TopActions onChangeTab={(tab) => onChangeTab(tab)} />

            {/* <Routes>
                <Route
                    path={paths.events.home}
                    element={
                        <Events
                            filterClick={filterClick}
                            onLoadData={(e) => onLoadingChildrens(e)}
                            onLoading={(e) => onRenderTabCount(e)}
                            eventClick={eventClick}
                        />
                    }>
                </Route>
            </Routes> */}

            {

                <Routes>
                    <Route
                        path={paths.events.home}
                        element={
                            <Events
                                filterClick={filterClick}
                                onLoadData={(e) => onLoadingChildrens(e)}
                                onLoading={(e) => onRenderTabCount(e)}
                                eventClick={eventClick}
                                setEventClick={setEventClick}
                            />
                        }>
                    </Route>

                    <Route
                        path={paths.establishments.home}
                        element={
                            <Establishments
                                filterClick={filterClick}
                                onLoadData={(e) => onLoadingChildrens(e)}
                                onLoading={(e) => onRenderTabCount(e)}
                                establishmentClick={establishmentClick}
                                setEstablishmentClick={setEstablishmentClick}
                            />
                        }>
                    </Route>
                </Routes>
            }

            <Column width='0' height='0'>
                <Outlet />
            </Column>

            <FloatCenteredItem>
                <FilterButton
                    size='small'
                    variant='secondary'
                    onClick={() => upCollapse()}
                >
                    <Column width='14px' height={'14px'}>
                        <Icon
                            name='filter'
                            color={colors.night}
                        />
                    </Column>
                    <Text
                        name='small'
                        color={colors.night}
                        paddingLeft={'8px'}
                        paddingBottom={0}
                    >
                        Filtrar ou Pesquisar
                    </Text>
                </FilterButton>
            </FloatCenteredItem >
            
            {
                locationLoading || !dynamicData ? (
                    <StyledPlaceholder src='/assets/images/map-placeholder.png' />
                ) : (
                    <Map
                        withUserLocation
                        latitude={latitude}
                        longitude={longitude}
                        data={dynamicData?.data}
                        multiples={dynamicData?.withMultiples}
                        onMarkerClick={(item) => onSelectedActionClick(item)}
                    />
                )
            }

            <Navbar />


            {!!open &&
                <DeleteAccountDialog home open={open} setOpen={setOpen} />
            }

            {
                !!openChangePassword &&
                <UpdatePasswordDialog
                    setModalSucessPassword={setModalSucessPassword}
                    open={openChangePassword}
                    setOpen={setOpenChangePassword}
                ></UpdatePasswordDialog>
            }

            <Modal isOpen={modalSucessPassword} onClose={() => setModalSucessPassword(false)}>
                <ContentModalSucessPassword>
                    <CheckCircle color={colors.successLight} size={'40px'} />
                    <Text color={colors.primaryDashboard} marginTop={'20px'}>
                        Sua senha foi alterada com sucesso!
                    </Text>
                </ContentModalSucessPassword>
            </Modal>

            {
                !!currentLocation &&
                <ModalLocation
                    data={cityAthorized}
                    onChangeModalLocation={onChangeModalLocation}
                />
            }

            {
                !!invitePermissionModal && (
                    <InvitePermissionAlert
                        visible={!!invitePermissionModal}
                        data={invitePermissionModal}
                        onClose={() => onClosePermissionModal()}
                    />
                )
            }
        </Container>
    )
}


const Container = styled(Column)`
    width: 100%;
    height: 100%;
    /* height: 100vh; */
    /* height: 100dvh; */
    /* overflow: hidden; */
    
    @media screen and (min-width: ${breakpoints.lg}) {
        display:flex;
        flex-direction: row;
    }
`;


const FullScreenLoading = styled(FullyCentered)`
    z-index: ${indexPriority.enormous};
    position: fixed;
    left: ${sizes.none};
    background: rgba(26, 26, 26, .9);
    width: 100%;
    height: 100vh;
    top: ${sizes.none};        
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: ${indexPriority.tremendous};
        background: rgba(0, 0, 0, .65);
    }
`;


const ContentModalSucessPassword = styled(Column)`
  align-items: center;
`;

const ContentFloating = styled.div`
    @media screen and (min-width: ${breakpoints.lg}) {
        display: none;
    }
`;


const FloatCenteredItem = styled(FullyCentered)`
    width: 100vw;
    height: 30px;
    position: fixed;
    right: 0;
    bottom: 352px;
    /* bottom: 312px; */
    z-index: ${indexPriority.small};
`;

const FilterButton = styled.div`
    background: ${colors.neutralLight};
    border: 1px solid ${colors.neutralLight};
    color: ${colors.primary};
    box-shadow: -2px 3px 5px ${colors.nightLight};
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 8px 12px;
    border: 1px solid ${colors.neutralDark};
    z-index: ${indexPriority.normal};
`;

const ContentSelectLocation = styled.div`
    display: none;
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 40%;
        position: fixed;
        display: flex;
        flex-direction: row;
        top: 195px;
        right: 0;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

`;


const StyledPlaceholder = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
`;

const ContentCheckin = styled(Column)`
    height: 100%; 
    & > div:nth-child(2) {
        height: 100%;
        background: ${colors.neutralBackground};
        align-items: flex-start;
        & > div:nth-child(2) {
            background: unset;
            & > div {
                background: ${colors.neutralLight}
            }
        }
    }
   
`;
