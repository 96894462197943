import RadioSelectButton from 'components/_UI/RadioSelectButton'
import Text from 'components/_UI/Text'
import { usePreferences } from 'context/user/Preferences'
import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import { HorizontalCenter } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'
import recurrencesTypes from 'theme/recurrencesTypes'
import sizes from 'theme/sizes'

 const RecurrencePreferences = React.forwardRef(({errorWeek, errorMonth}) => {
    const {preferences, setPreferences} = usePreferences()
    const recurrenceWeekRef = useRef(null);
    const recurrenceMonthRef= useRef(null);

    useEffect(() => {
        if(errorWeek) {
            recurrenceWeekRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if(errorMonth) {
            recurrenceMonthRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [errorWeek, errorMonth])
    
  return (
    <Container>
        <ContainerSelect ref={recurrenceWeekRef}>
            <Text 
                type='bold'
                marginBottom={sizes.normal}
            >
                Recorrência que você sai na semana: 
            </Text>

            <ContentSelect>
                <RadioSelectButton 
                    data={recurrencesTypes.week} 
                    onChangeActive={(item) => setPreferences({ ...preferences, recurrenceWeek: item.value })}
                />
            </ContentSelect>
            {errorWeek && (
                <HorizontalCenter>
                    {errorWeek === 'min' && (
                        <Text color={colors.danger}> Você deve selecionar a recorrência que sai na semana</Text>
                    )}
                </HorizontalCenter>
            )}
        </ContainerSelect>
        <ContainerSelect ref={recurrenceMonthRef}>
            <Text 
                type='bold'
                marginBottom={sizes.normal}
            >
                Recorrência que você sai no mês: 
            </Text>

            <ContentSelect>
                <RadioSelectButton 
                    data={recurrencesTypes.month} 
                    onChangeActive={(item) => setPreferences({ ...preferences, recurrenceMonth: item.value })}
                />
            </ContentSelect>
        </ContainerSelect>
        {errorMonth && (
            <HorizontalCenter>
                {errorMonth === 'min' && (
                    <Text color={colors.danger}> Você deve selecionar a recorrência que sai no mês</Text>
                )}
            </HorizontalCenter>
        )}

    </Container>
  )
})

export default RecurrencePreferences;

const Container = styled(Column)`
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


const ContentSelect = styled.div`
    width: 100%;
    display: flex;
    height: auto;
    flex-wrap: wrap;
    margin-left: ${sizes.micro};
    & > div > div > div > button {
        width: 185px;
        margin-right: 0px
    }
`;

const ContainerSelect = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;   
    margin-top: ${sizes.normal};
`;