import eventsApi from 'api/requests/events';
import Button from 'components/_UI/Button';
import Loader from 'components/_UI/Loader';
import Modal from 'components/_UI/Modal';
import Text from 'components/_UI/Text';
import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import buttonText from 'texts/buttons/buttonText';
import colors from 'theme/colors';
import sizes from 'theme/sizes';

export default function DeleteEvent({
    isDelete,
    onSuccess = () => false,
    onClose = () => false
}) {
    const [isLoading, setIsloading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)

    useEffect(() => {
    }, [isDelete])

    async function deleteItem() {
        setIsloading(true)
        const slug = isDelete?.slug

        try {
            await eventsApi.deleteMyEvents(slug)
            onSuccess(true)

        } catch (error) {
            setErrorMessage(true)
        } finally {
            setIsloading(false)
            onClose()
        }
    }
    return (
        <Modal isOpen={isDelete} onClose={() => onClose(false)}>
            <ModalWrapper>
                {
                    isLoading ? (
                        <Loader />
                    ) :
                        errorMessage ? (
                            <Column fullyCentralized>
                                <Text name='subtitle'> Ocorreu um erro inesperado </Text>
                                <Column width='200px' marginTop='10px'>
                                    <Button onClick={() => deleteItem()}>
                                        {buttonText.tryAgain}
                                    </Button>
                                </Column>
                            </Column>
                        ) : (
                            <>
                                <Text color={colors.night}>Tem certeza que deseja <b>excluir</b> o evento <b>{isDelete?.name}</b>? </Text>
                                <ContentButton>
                                    <Button
                                        marginRight='15px'
                                        variant='secondary'
                                        onClick={() => onClose(false)}
                                    >
                                        {buttonText.cancel}
                                    </Button>
                                    <Button
                                        variant='primary'
                                        onClick={() => deleteItem()}
                                    >
                                        {buttonText.confirm}
                                    </Button>
                                </ContentButton>
                            </>
                        )
                }

            </ModalWrapper>
        </Modal>
    )
}

const ContentButton = styled(Row)`
    width: 300px;
    display: flex;
    align-items : center;
    justify-content: center;
    margin-top: 10px;
`;

const ModalWrapper = styled.div`
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    padding: ${sizes.tiny} ${sizes.none} ${sizes.tiny} ${sizes.none};
`;