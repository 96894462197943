const customFieldsTypes = [
    { label: 'Campo de texto curto', value: 'text' },
    { label: 'Campo de texto longo', value: 'textarea' },
    { label: 'Campo númerico', value: 'numeric' },
    { label: 'Seleção única', value: 'radio' },
    { label: 'Seleção múltipla', value: 'checkbox' },
    { label: 'Data', value: 'date' },
    { label: 'Hora', value: 'time' },
    { label: 'Arquivo', value: 'upload' }
];


export default customFieldsTypes