import React, { createContext, useState, useContext } from 'react';

const EstablishmentFilterContext = createContext();

export default function EstablishmentFilterProvider({ children }) {
    const [establishmentFilter, setEstablishmentFilter] = useState(null);

    return (
        <EstablishmentFilterContext.Provider value={{ establishmentFilter, setEstablishmentFilter }}>
            {children}
        </EstablishmentFilterContext.Provider>
    );
}

export function useEstablishmentFilter() {
    const context = useContext(EstablishmentFilterContext);

    if (!context) throw new Error('EstablishmentFilter must be used within a EstablishmentFilterProvider');

    const { establishmentFilter, setEstablishmentFilter } = context;
    return { establishmentFilter, setEstablishmentFilter };
}