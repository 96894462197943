import React, { useEffect } from 'react'
import publicRoutes from './public'
import dashRoutes from './dash'
import hybridRoutes from './hybrid';
import protectedRoutes from './protected';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import useAuth from "hooks/useAuth";
import paths from "routes/paths";
import Home from 'screens/home/Home';

export default function () {

    const { isAuth } = useAuth();

    useEffect(() => {

    }, [isAuth])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />}>
                    <Route index element={<Navigate to={paths.events.home} replace />} />
                    <Route path={paths?.events?.home} />
                    <Route path={paths?.establishments?.home} />
                </Route>

                {/* Rotas públicas */}
                {!isAuth ?
                    publicRoutes.map((route, i) => (
                        <Route
                            key={i}
                            exact={route.exact}
                            path={route.path}
                            element={route.element}
                        />
                    ))
                    : <Route path="*" element={<Navigate to="/" replace />} />
                }

                {/* Rotas do hibridas */}
                {
                    hybridRoutes.map((route, i) => (
                        <Route
                            key={i}
                            exact={route.exact}
                            path={route.path}
                            element={route.element}
                        />
                    ))
                }

                {/* Rotas protegidas */}
                {
                    isAuth ?
                        protectedRoutes.map((route, i) => (
                            <Route
                                key={i}
                                exact={route.exact}
                                path={route.path}
                                element={route.element}
                            />
                        ))
                        : <Route path="*" element={<Navigate to="/" replace />} />
                }

                {/* Rotas publicas */}
                {
                    !isAuth ? (
                        publicRoutes.map((route, i) => (
                            <Route key={i} {...route} element={<Route element={route.element} />} />
                        ))
                    ) : <Route path="*" element={<Navigate to="/" replace />} />
                }

                {/* Rotas do dashboard */}
                {isAuth
                    ? dashRoutes.map((route, i) => (
                        <Route key={i} {...route} element={<ProtectedRoute element={route.element} />} />
                    ))
                    : <Route path="*" element={<Navigate to="/" replace />} />
                }
            </Routes>

        </BrowserRouter>
    )
}

function ProtectedRoute({ element }) {
    const { isAuth } = useAuth();
    return isAuth ? element : <Navigate to="/" replace />;
}
