import React, { createContext, useState, useContext } from 'react';

const EventFilterContext = createContext();

export default function EventFilterProvider({ children }) {
    const [eventFilter, setEventFilter] = useState(null);

    return (
        <EventFilterContext.Provider value={{ eventFilter, setEventFilter }}>
            {children}
        </EventFilterContext.Provider>
    );
}

export function useEventFilter() {
    const context = useContext(EventFilterContext);

    if (!context) throw new Error('EventFilter must be used within a EventFilterProvider');

    const { eventFilter, setEventFilter } = context;
    return { eventFilter, setEventFilter };
}