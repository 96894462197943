import Button from 'components/_UI/Button';
import Ilustration from 'components/_UI/Ilustration';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import ToastContainer from 'components/_UI/ToastsContainer';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react'
import { Edit, PlusSquare, Trash2 } from 'react-feather';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import { Row } from 'styled/alignment/Row';
import { useEvent } from 'context/events/Event';
import CustomFieldsForm from './CustomFieldsForm';
import customFieldsApi from 'api/requests/customFields';
import CustomFieldCard from './CustomFieldCard';
import CustomFieldsDeleteModal from './CustomFieldsDeleteModal';

export default function Additional() {
    const { toast, toasts } = useToast();
    const { event } = useEvent();

    const [loading, setLaoding] = useState(false);
    const [content, setContent] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        getCustomFields();
    }, [])

    function refreshData() {
        setModalVisible(false);
        setDeleteModal(false);
        setEdit(false);

        getCustomFields();
    }

    async function getCustomFields() {
        setLaoding(true);

        try {
            const { data } = await customFieldsApi.get(event?.slug);
            setContent(data?.data);
        } catch (error) {

        } finally {
            setLaoding(false);
        }
    }

    return (
        <Container>
            <ToastContainer toasts={toasts} />

            <HeaderContent>
                <Text name='subtitle' paddingBottom={0}>
                    Configurações adicionais
                </Text>

                {
                    !content?.length && (
                        <Button
                            variant='featured'
                            category='squired'
                            onClick={() => setModalVisible(true)}
                        >
                            <PlusSquare color={colors.neutralLight} size={16} />
                            <Text
                                color={colors.neutralLight}
                                paddingBottom={0}
                                marginLeft={'6px'}
                            >
                                Criar campos personalizados
                            </Text>
                        </Button>
                    )
                }
            </HeaderContent>

            {modalVisible &&
                <CustomFieldsForm
                    visible={modalVisible}
                    isEdit={edit}
                    toast={toast}
                    onClose={() => {
                        setEdit(false);
                        setModalVisible(false);
                    }}
                    onSuccess={refreshData}
                />
            }

            {deleteModal &&
                <CustomFieldsDeleteModal
                    visible={deleteModal}
                    toast={toast}
                    onClose={() => setDeleteModal(false)}
                    onSuccess={refreshData}
                />
            }

            {
                loading ? (
                    <ContentLoader>
                        <Loader />
                    </ContentLoader>
                ) : (
                    content?.length
                        ? (
                            <>
                                <CustomTitle>
                                    <Text type='bold' marginTop={'50px'} marginBottom={'10px'} paddingBottom={0}>
                                        Campos adicionadis criados
                                    </Text>

                                    <span>
                                        <Edit
                                            size={14}
                                            color={colors.primaryLight}
                                            style={{ marginRight: '6px' }}
                                            onClick={() => {
                                                setEdit(content)
                                                setModalVisible(true);
                                            }}
                                        />

                                        <Trash2
                                            size={14}
                                            color={colors.danger}
                                            onClick={() => setDeleteModal(true)}
                                        />
                                    </span>
                                </CustomTitle>

                                <CustomFieldsContainer>
                                    {content?.map(el => (
                                        <CustomFieldCard data={el} key={el?.id} />
                                    ))}
                                </CustomFieldsContainer>
                            </>
                        ) : (
                            <>
                                <Picture>
                                    <Ilustration name='additionalForms' />
                                </Picture>

                                <Text type='bold' color={colors.night} textAlign={'center'} name='normal'>
                                    Você pode coletar dados adicionais durante a venda dos ingressos e/ou produtos.
                                </Text>

                                <Text type='bold' color={colors.night} textAlign={'center'} name='normal'>
                                    Quel tal criar um formulário personalizado?
                                </Text>

                                <FullyCentered marginTop='10px'>
                                    <Column width='280px'>
                                        <Button
                                            variant='featured'
                                            category='squired'
                                            onClick={() => {
                                                setEdit(false);
                                                setModalVisible(true);
                                            }}
                                        >
                                            <PlusSquare color={colors.neutralLight} size={16} />
                                            <Text
                                                color={colors.neutralLight}
                                                paddingBottom={0}
                                                marginLeft={'6px'}
                                            >
                                                Criar campos personalizados
                                            </Text>
                                        </Button>
                                    </Column>
                                </FullyCentered>
                            </>
                        )
                )
            }

        </Container>
    )
}


const Container = styled(Column)`
    width: 100%;
`;

const HeaderContent = styled(Column)`
    width: 100%;
    
    & > button {
        margin-top: 20px;
    }
    
    @media screen and (min-width: ${breakpoints.md}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        & > h2,
        & > button {
            width: auto;
            margin-top: 0;
        }
        
        
    }
`;

const CustomFieldsContainer = styled(Row)`
    width: 100%;
    flex-wrap: wrap;
    /* border: 1px solid ${colors.nightLight}; */
    /* box-shadow: 0 2px 5px ${colors.nightLight}; */
    /* padding: 10px; */
    /* border-radius: 8px; */

        @media screen and (min-width: ${breakpoints.lg}) {
        div:nth-of-type(n+5) {
            margin-top: 1.2%; 
        }
    }
`;

const Picture = styled.picture`
    width: auto;
    height: 300px;
`;

const ActionButton = styled.div`
    width: auto;
    height: auto;
    transition: all ease-in-out .25s;
    cursor: pointer;

    ${({ disabled }) => disabled && `
        opacity: .4;
        pointer-events: none;
    `};
`;

const DiscountCodesList = styled(Column)`
    width: 100%;

    div {
        margin-top: 2%;
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;

        div {
            margin-top: 0;
        }
        
        div:nth-of-type(4n + 1) {
            margin-left: 0;
        }
    
        div:nth-of-type(n+5) {
            margin-top: 1.2%; 
        }
    }
`;

const CustomTitle = styled(Row)`
    align-items: flex-end;

    & > span {
        margin-left: 10px;
        margin-bottom: 8px;

        & > svg {
            cursor: pointer;
        }
    }
`;

const ContentLoader = styled(Column)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;