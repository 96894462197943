import React from 'react'
import CardSlot from '../parts/CardSlot'
import Text from 'components/_UI/Text';
import styled from 'styled-components';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import { formatPostalcodel } from 'helpers/postalcode';

export default function ProfileAddress({data}) {

  return (
    <CardSlot marginTop={'10px'}>
      <Text name='highlight' color={colors.occasionalPurple}>Endereço</Text>

      <AddressRow>
        <Text type='bold'>Bairro:</Text>
        <Text>{data?.district}</Text>
      </AddressRow>
      
      <AddressRow>
        <Text type='bold'>Rua:</Text>
        <Text ellipsisAt={'90%'}>{data?.name}</Text>
      </AddressRow>

      <AddressRow>
        <Text type='bold'>CEP:</Text>
        <Text>{formatPostalcodel(data?.postalcode)}</Text>
      </AddressRow>

      <AddressRow>
        <Text type='bold'>Número:</Text>
        <Text> {data?.number} </Text>
      </AddressRow>

    { 
      data?.complement &&
        <AddressRow>
          <Text type='bold'>Complemento:</Text>
          <Text>{data?.complement}</Text>
        </AddressRow>
    }

    </CardSlot>
  )
}

const AddressRow = styled(Row)`
  justify-content: space-between;
  margin-top: 20px;
`;