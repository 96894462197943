import React from 'react';
import PropTypes from 'prop-types';
import { StyledLoaderBox, StyledSpinner } from 'styled/UI/Loader';

export default function Loader({size, color, containerMargin}) {
  return (
    <StyledLoaderBox margin={containerMargin}>
        <StyledSpinner size={size} color={color} />
    </StyledLoaderBox>
  )
};

Loader.defaultProps = {
    size: 'default'
};

Loader.propTypes = {
    size: PropTypes.oneOf(['mini', 'small', 'default', 'medium', 'large'])
};