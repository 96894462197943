import React from 'react'
import PropTypes from 'prop-types';
import modal from 'theme/modal';
import { StyledCloseModal, StyledModal, StyledModalContent, StyledModalOverlay } from 'styled/UI/StyledModal';
import Text from './Text';
import Icon from './Icon';

function Modal({
    children,
    isOpen,
    closeButton,
    isCircularClose,
    title,
    size,
    align,
    onClose
}) {

    const onCloseClick = (e) => onClose(e || false);

    return (
        isOpen && (
            <StyledModalOverlay
                size={size}
                onClick={(e) => onCloseClick(e)}
            >
                <StyledModal
                    size={size}
                    onClick={(e) => e.stopPropagation()}
                >
                    {closeButton && (
                        <StyledCloseModal circular={isCircularClose} onClick={(e) => onCloseClick(e)}>
                            <Icon name='close' />
                        </StyledCloseModal>
                    )}

                    {title && (
                        <Text name='highlight' marginBottom='12px'>
                            {title}
                        </Text>
                    )}
                    <StyledModalContent textAlign={align}>
                        {children}
                    </StyledModalContent>
                </StyledModal>
            </StyledModalOverlay>
        )
    )
};

Modal.propTypes = {
    isOpen: PropTypes.bool,
    closeButton: PropTypes.bool,
    title: PropTypes.string,
    align: PropTypes.oneOf([...modal.align]),
    size: PropTypes.oneOf(['default', 'medium', 'large', 'xLarge']),
    onClose: PropTypes.func
};

Modal.defaultProps = {
    isOpen: false,
    closeButton: true,
    title: '',
    align: 'center',
    size: 'default',
    onClose: () => ''
};


export default Modal;