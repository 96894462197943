import Text from 'components/_UI/Text'
import React, { useState, useEffect} from 'react'
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import interests from 'theme/interests'
import interestsApi from 'api/requests/interests'
import ButtonSelect from 'components/_UI/ButtonSelect'
import Loader from 'components/_UI/Loader'

export default function Interest({ slug, presence, userPresenceStatus }) {
    const [interest, setInterest] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [confirmedUser, setConfirmedUser] = useState(false)
    const [interestedUser, setInterestedUser] = useState(false)
    const [userAccepted, setUserAccepted] = useState(false)
    const [userInterested, setUserInterested] = useState(false)


    useEffect(() => {
        
        if(userPresenceStatus === 1) {
            setConfirm(true)
            setUserAccepted(true)
            setInterest(false)
        }
        if(userPresenceStatus === 2) {
            setInterest(true)
            setUserInterested(true)
            setConfirm(false)
        }
    }, [])
    async function onConfirm(active) {
        try {
            setLoading(true)
            await interestsApi.toggle(slug, { status: interests.accept.id })
            setConfirm(true)
            setInterest(false)
            setConfirmedUser(true)
            setInterestedUser(false)
        } catch (error) {
            // console.log('error', error)
            setConfirm(false)
        } finally {
            setLoading(false)
        }
    }

    async function onInterest(active) {
        setLoading(true)
        try {
            await interestsApi.toggle(slug, { status: interests.interest.id })
            setInterest(true)
            setConfirm(false)
            setInterestedUser(true)
            setConfirmedUser(false)
        } catch (error) {
            setInterest(false)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Column>
            <ContentContainer>
                {
                    loading
                        ? <Loader />
                        : (
                            <>
                                <StyledButtonContainer>
                                    <ButtonSelect
                                        isVariant={!confirm ? 'pink-borded' : 'pink'}
                                        onToggle={(state) => onConfirm(state)}
                                        isActive={confirm}
                                    >
                                        {interests.accept.label}
                                    </ButtonSelect>
                                </StyledButtonContainer>

                                <StyledButtonContainer>
                                    <ButtonSelect
                                        isVariant={!interest ? 'yellow-borded' : 'yellow'}
                                        isActive={interest}
                                        onToggle={(state) => onInterest(state)}
                                    >
                                        {interests.interest.label}
                                    </ButtonSelect>
                                </StyledButtonContainer>
                            </>
                        )
                }
            </ContentContainer>

            <FullyCentered>
                
                
                <Column verticalCenter>
                    <ContentInterest>
                       {
                            presence.accept === 1 ? (
                                userAccepted ? (
                                    !interestedUser && 
                                    <Text name='small'>Você confirmou presença</Text>
                                ) : confirmedUser ? (
                                    <Text name='small'>Você e mais {presence.accept === 1 ? `${presence.accept} pessoa ` : `${presence.accept} pessoas`} confirmaram presença</Text>
                                ) : (
                                    <Text name='small'>{presence.accept} confirmou presença</Text>
                                )
                            ) : presence.accept > 1 &&  (
                                userAccepted ? (
                                    !interestedUser ? (
                                        <Text name='small'>Você e mais {`${presence.accept -1 === 1 ? `${presence.accept -1} pessoa` : `${presence.accept -1} pessoas`} `} confirmaram presença</Text>
                                    ) : (
                                        <Text name='small'>{`${presence.accept -1 === 1 ? `${presence.accept -1} pessoa confirmou` : `${presence.accept -1} pessoas confirmaram`} `} presença</Text>
                                    )
                                ) : confirmedUser ? (
                                    <Text name='small'>Você e mais {presence.accept === 1 ? `${presence.accept} pessoa ` : `${presence.accept} pessoas`} confirmaram presença</Text>
                                ) : (
                                    <Text name='small'>{presence.accept} pessoas confirmaram presença</Text>
                                )
                            ) 
                        }
                        {
                            presence.accept === 0 && (
                                confirmedUser && (
                                    <Text name='small'>Você confirmou presença</Text>
                                ) 
                                
                            )    
                        }

                        {
                            presence.interest === 1 ? (
                                userInterested ? (
                                    !confirmedUser && 
                                    <Text name='small'>Você tem interesse</Text>
                                ) : interestedUser ? (
                                    <Text name='small'>Você e mais {presence.interest === 1 ? `${presence.interest} pessoa ` : `${presence.interest} pessoas`} tem interesse</Text>
                                ) : (
                                    <Text name='small'>{presence.interest === 1 ? `${presence.interest} pessoa ` : `${presence.interest} pessoas`} tem interesse</Text>
                                )
                            ) : presence.interest > 1 &&  (
                                userInterested ? (
                                    !confirmedUser ? (
                                        <Text name='small'>Você e mais {`${presence.interest -1 === 1 ? `${presence.interest -1} pessoa` : `${presence.interest -1} pessoas`} `} tem interesse</Text>
                                    ) : (
                                        <Text name='small'>{`${presence.interest -1 === 1 ? `${presence.interest -1} pessoa` : `${presence.interest -1} pessoas`} `} tem interesse</Text>
                                    )
                                ) : interestedUser ? (
                                    <Text name='small'>Você e mais {presence.interest === 1 ? `${presence.interest} pessoa ` : `${presence.interest} pessoas`} tem interesse</Text>
                                ) : (
                                    <Text name='small'>{presence.interest} pessoas tem interesse</Text>
                                )
                            ) 
                        }

                        {
                            presence.interest === 0 && (
                                interestedUser ? (
                                    <Text name='small'>Você tem interesse</Text>
                                ) : userInterested && (
                                    !confirmedUser && 
                                        <Text name='small'>Você tem interesse</Text>
                                )
                            ) 
                        }

                    </ContentInterest>
                </Column>
            </FullyCentered>
        </Column>
    )
}


const StyledButtonContainer = styled.div`
    width: 125px;
    margin-left: 8px;

    & > button {
        padding: 6px;
    }
`;

const ContentContainer = styled(Row)`
    width: 100%;
    align-items: center;
    justify-content: center;
`
const ContentInterest = styled.div`
    flex-direction: column;
    display: flex;
`