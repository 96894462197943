import Text from 'components/_UI/Text';
import React from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import EventCard from './card/EventCard';

export default function EventsGroupItem({data, title, onEventClick}) {
  
  return (
    <Container>
        <StyledRow>
          <StyledText>
              <Text name='mini'>{title}</Text>
          </StyledText>
        </StyledRow>
        <Column verticalCenter>
        {
          data?.items?.data ? (
            data?.items?.data?.map((el) => (
              <Item key={el?.id} onClick={() => onEventClick(el.slug)}>
                  <EventCard data={el} />
              </Item>
            ))

          ) : (
            data && data?.map((el) => (
              <Item key={el?.id} onClick={() => onEventClick(el?.slug)}>
                <EventCard data={el} />
              </Item>              
            ))
          )
        }
          
        </Column>
    </Container>
  )
}

const Container = styled(Column)`
  justify-content: center;
  align-items: center;
`;

const StyledRow = styled(Row)`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 95%;
  align-items: center;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    background: ${colors.nightLight};
    height: 1px;
    position: absolute;
    top: 6px;
    right: 2px;
  }
`;

const StyledText = styled.div`
  background: ${colors.neutralLight};
  position: sticky;
  padding: 0 6px 0 6px;
  & > p {
    color: ${colors.primaryDashboard};
  }
`;

const Item = styled(Column)`
  width: 95%;
  display: flex;
  cursor: pointer;
  &:last-child {
    padding-bottom: 10px;
  }
  &:hover {
    transform: scale(1.01);
  }
`;