import Text from 'components/_UI/Text';
import { useEvent } from 'context/events/Event';
import date from 'helpers/date';
import React, { useEffect } from 'react';
import { Calendar, Feather, MapPin } from 'react-feather';
import styled, { css } from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import eventTypes from 'theme/eventTypes';

export default function EventCard({
    data,
    fullWidth,
    whiteBackground = false,
    smallOnDesktop = false,
    withoutTags = false,
    onEventClick,
    expired = false,
}) {
    const { setEvent } = useEvent()
    useEffect(() => {
        setEvent(data)
    }, [])

    return (
        <CardContainer
            id='event_card'
            onClick={() => onEventClick(data)}
            fullWidth={fullWidth}
            whiteBackground={whiteBackground}
            smallOnDesktop={smallOnDesktop}
            expired={expired}

        >
            <ImageContainer fullWidth={fullWidth}>
                {data?.images?.length && data?.images[0] || data.path ? (
                    <CardImage src={data.path ? data?.path : data.images[0]} />
                    ) : (
                    <CardImage src={'/assets/images/placeholder-events.png'} />
                )}

            </ImageContainer>

            <Text
                name='small'
                type='bold'
                marginTop={fullWidth ? '10px' : '6px'}
                ellipsisAt='95%'
                paddingBottom={fullWidth ? '8px' : '2px'}
            >
                {data.name}
            </Text>
            
            <Row 
                marginBottom={fullWidth ? '6px' : 0}
                paddingRight='18px'
            >
                 {
                    fullWidth && (
                        <MapPin size={15} color={colors.nightLight}/>
                    )
                }
                <Text
                    name='small'
                    color={colors.nightLight}
                    ellipsisAt='95%'
                    marginLeft={fullWidth ? '4px' : 0}
                >
                    {data?.address && data?.address?.street.name}, Bairro {data?.address?.street.district}
                    {data?.address?.number && `, nº ${data?.address?.number}`}
                    {data?.address?.complement && ` - ${data?.address?.complement}`}
                </Text>
            </Row>

            {
                data?.dates && fullWidth && (
                    <Row  marginBottom={fullWidth ? '6px' : 0}>
                        {
                            fullWidth && (
                                <Calendar size={15} color={colors.nightLight}/>
                            )
                        }
                        <Text
                            name='small'
                            color={colors.nightLight}
                            ellipsisAt='95%'
                            paddingBottom={'4px'}
                            marginLeft={fullWidth ? '4px' : 0}
                        >
                            {date?.format(data?.dates[0]?.date)} - {data?.dates[0]?.start.split(':')[0]}:{data?.dates[0].start.split(':')[1]}
                        </Text>
                    </Row>
                )
            }

                {
                    !withoutTags && (
                        <TagsContainer>
                            <CategoryCircle
                                fullWidth={fullWidth}
                                category={data?.classification?.name}
                            />

                            <Text name='mini'
                                color={colors.nightLight}
                                ellipsisAt='95%'
                                paddingBottom={'0px'}
                                marginLeft={'3px'}
                            >
                                {data?.tags?.map(item => `#${item.name} `)}
                            </Text>

                            {/* {
                                data.tags.map(item => (
                                    <Tag>
                                        <WUIText name='mini'
                                            color={colors.neutralLight}
                                            ellipsisAt='95%'
                                        >
                                            {item.name}
                                        </WUIText>
                                    </Tag>
                                ))
                            } */}
                        </TagsContainer>

                    )
                }
        </CardContainer>
    )
};

const CardContainer = styled(Column)`
    width: 155px;
    margin: 12px 2px 0 2px;
    cursor: pointer;
    transition: all ease-in-out .25s;
    /* background-color: ${colors.neutralLight}; */
    
    ${({ fullWidth }) => fullWidth && `
        width: 100% !important;
        margin: 8px 0;
        border-radius: 10px;
        box-shadow: 2px 8px 10px #ccc;        
        padding: 6px;
    `}    
    
    @media screen and (min-width: ${breakpoints.lg}) {
        ${({ smallOnDesktop }) => smallOnDesktop && `
            width: 30% !important; 
        `}    
    }

    ${({ whiteBackground }) => whiteBackground && `
        background: ${colors.neutralLight}; 
    `}   
    
    ${({ expired }) => expired && `
        opacity: .6;
    `}
    
    
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 99%;
        margin: 8px 0;
        border-radius: 10px;
        box-shadow: 2px 2px 4px #ccc;        
        padding: 6px;
    }

    &:hover {
        transform: scale(1.01);
    }
`;

const CardTextWrapper = styled(FullyCentered)`
    width: 100%;
    text-align: center;
    padding: 20px;
    border-radius: 4px;

    ${({ category }) => category === 'free' && css`
        background: ${eventTypes.free.color};
    `};

    ${({ category }) => category === 'private' && css`
        background: ${eventTypes.paid.color};
    `};

`;

const ImageContainer = styled.div`
    display: flex;
    width: 100%;
    height: 85px;

    @media screen and (min-width: ${breakpoints.lg}) {
        height: 140px;
    }

    ${({ fullWidth }) => fullWidth && `
        height: 140px;
    `}
    
`;

const CardImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
`;

const CategoryCircle = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 30px;
    ${({ category }) => category === 'free' && css`
        background: ${eventTypes.free.color};
    `};
    
    ${({ category }) => category === 'private' && css`
        background: ${eventTypes.paid.color};
    `};

    ${({ fullWidth }) => fullWidth && css`
        margin-left: 3px;
        margin-right: 5px;
    `};
`;


const TagsContainer = styled(Row)`
    align-items: center;
`;