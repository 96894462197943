import Button from 'components/_UI/Button';
import Text from 'components/_UI/Text';
import React, {useEffect, useState} from 'react'
import styled, { css } from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import date from 'helpers/date';
import sizes from 'theme/sizes';
import eventTypes from 'theme/eventTypes';
import fonts from 'theme/fonts';
import types from 'helpers/system/types';
import paths from 'routes/paths';
import breakpoints from 'theme/breakpoints';
import NotResultDashBoard from 'components/commons/NotResultDashBoard';
import { FullyCentered } from 'styled/alignment/Center';

export default function EventsResume({ data }) {
    const formatDate = dateContent => date.format(dateContent)
    const nextEventsCount = () =>  data?.next?.total
    const nowEventsCount = () => data?.now?.total
    const expiredEventsCount = () => data?.expired?.total
    const totalEventsCount = () => nowEventsCount() + nextEventsCount() + expiredEventsCount()
    const totalEventsActive = () => nowEventsCount() + nextEventsCount() 
    const newArray = data?.next?.items?.data?.sort((a, b) => new Date(a.dates[0].date) - new Date(b.dates[0].date));
    const nextEvent = data?.next?.items?.data && newArray[0];    

    return (
        <ContainerResume>
            <ContentResume>
                {totalEventsCount() == 0 ? (
                    <NotResultDashBoard 
                        ilustration={'up-graphic'}
                        text={'Notei que você ainda não possui eventos. Que tal criar seu primeiro evento e ter uma experiência incrível?'}
                        textButton={'Criar meu primeiro evento!'}
                        path={`${paths.dashboard.events}?create=1`}
                    />
                ) : (
                    totalEventsCount() > 0 &&
                    <CardWrapper >
                        <Text name='highlight' type='bold' color={colors.primaryDashboard}>{totalEventsActive()} Eventos ativos</Text>

                        <Column fullyCentralized marginTop='15px'>
                            {
                                !!nowEventsCount() && (
                                    <Text type='bold' color={colors.primaryDashboard}>
                                        Acontecendo hoje: <b> {nowEventsCount()} </b>
                                    </Text>

                                )
                            }
                            {
                                !!nextEventsCount() && (
                                    <Text color={colors.primaryDashboard}>
                                        Próximos eventos: <b>{nextEventsCount()}</b>
                                    </Text>

                                )
                            }
                            {
                                !!expiredEventsCount() && (
                                    <Text  color={colors.primaryDashboard}>
                                        Finalizados: <b>{expiredEventsCount()}</b>
                                    </Text>

                                )
                            }
                        </Column>
                        <Button category='squired' variant='featured' marginTop={'15px'} path={paths.dashboard.events}>Ver eventos</Button>
                    </CardWrapper >
                )
                }


                {
                    nextEventsCount() && totalEventsCount() > 0 ? (
                        <CardWrapper nextEvent>
                            <Text name='highlight' type='bold' color={colors.primaryDashboard}>Próximo evento</Text>

                            <Column fullyCentralized>
                                
                                <Row horizontalCenter>
                                    <Text color={colors.primaryDashboard} ellipsisAt={'95%'}>
                                        {nextEvent?.name}
                                    </Text>
                                </Row>
                                <Row horizontalCenter>
                                    <Text color={colors.primaryDashboard}>
                                        {formatDate(nextEvent?.dates[0].date)}
                                    </Text>
                                    <Text color={colors.primaryDashboard} marginLeft={sizes.mini}>
                                        {date?.getTimeWithoutSeconds(nextEvent?.dates[0]?.start)}
                                    </Text>
                                </Row>
                                <ContentTags>
                                    <CategoryCircle category={nextEvent?.classification?.name} />
                                    <EventType type={nextEvent?.classification?.name}>
                                        {types.getBySlug(nextEvent?.classification?.name).label}
                                    </EventType>
                                
                                    {
                                        nextEvent?.tags?.map((el) => (
                                            <Text color={colors.primaryDashboard} paddingBottom={'0'} marginLeft={sizes.micro}>-  #{el?.name}</Text>
                                        ))
                                    }
                                </ContentTags>
                                
                            </Column>
                            <Button category='squired' variant='featured' marginTop={'15px'} onClick={() => {
                                window.location.href = `${paths.dashboard.events}?detail=${nextEvent.slug}`
                            }} >Ver evento</Button>

                        </CardWrapper>
                    ) : (
                        !nextEventsCount() && !!totalEventsCount() &&
                        <CardWrapper nextEvent>
                            <Text name='highlight' type='bold' color={colors.primaryDashboard}>Próximo evento</Text>
                            <Column fullyCentralized>
                                <Row horizontalCenter>
                                    <Text color={colors.primaryDashboard} >Você não tem nenhum próximo evento</Text>
                                </Row>
                                <Row horizontalCenter>
                                    <Text color={colors.primaryDashboard} type='bold'>Vamos criar um?</Text>
                                </Row>
                            </Column>
                            <Button category='squired' variant='featured' marginTop={'15px'} paddingBottom={'0'} path={`${paths.dashboard.events}?create=1`}>Criar evento</Button>
                        </CardWrapper>
                    )
                }
                
                {
                    totalEventsCount() > 0 && (
                        <CardWrapper totalEvents>
                        <Text name='hero' type='bold' color={colors.primaryDashboard} paddingBottom={'0'}>{totalEventsCount()}</Text>
                        <Text name='subtitle' type='bold' color={colors.primaryDashboard} >Eventos Realizados</Text>

                        {/* <Row fullyCentralized marginTop='20px'>
                            
                            <CategoryCircle paid />
                            <Text paddingBottom={'0'} marginLeft={'4px'} marginRight={'10px'} color={colors.primaryDashboard} >

                                Pagos {0}
                            </Text>
                            
                            
                            <CategoryCircle free />
                            <Text paddingBottom={'0'} marginLeft={'4px'} color={colors.primaryDashboard} >
                                Gratuitos {0}
                            </Text>
                        </Row> */}

                    </CardWrapper>
                    )
                }
            </ContentResume>
        </ContainerResume>
    )

}

const ContainerResume = styled(Column)`
    width: 100%;
    border-radius: 4px;
    justify-content: flex-start;
    background: ${colors.neutralLight};
    padding: 30px;
    
`;

const ContentResume = styled(Column)`
    justify-content: space-around;
    align-items: center;
    @media screen and (min-width: ${breakpoints.md}) {
        flex-direction: row;
    }
`;

const CardWrapper = styled(Column)`
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    width: 100%;
    border-radius: 8px;
    height: 250px;
    box-shadow: 0 3px 5px ${colors.nightLight};
    @media screen and (min-width: ${breakpoints.md}) {
        min-width: 30%;
        max-width: 30%;
        height: 250px;
    }
    ${({nextEvent}) => nextEvent && `
        align-items: center;
        padding: 30px;
        width: 100%;
        height: 250px;
        justify-content: space-between;
        margin-top: 30px;
        @media screen and (min-width: ${breakpoints.md}) {
            margin-top: 0;
            min-width: 30%;
            max-width: 30%;
            height: 250px;
        }

    `}
    ${({totalEvents}) => totalEvents && `
        align-items: center;
        padding: 30px;
        width: 100%;
        justify-content: center;
        margin-top: 30px;
        height: 250px;
        @media screen and (min-width: ${breakpoints.md}) {
            margin-top: 0;
            min-width: 30%;
            max-width: 30%;
            height: 250px;
        }

    `}

`;


const ResumeEventCard = styled(Column)`
    width: 100%;
    height: 200px;
    padding: 30px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    @media screen and (min-width: ${breakpoints.sm}) {
        width: 35%;
    }
`;



const ButtonContainer = styled.div`
    width: 250px;
    cursor: pointer;
    & > button {
        margin-bottom: 0;
    }
`;

const ContentTags = styled(FullyCentered)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const WithoutEvent = styled(Row)`
    align-items: center;
    justify-content: center;
    border-radius: 8px;
`;

const CategoryCircle = styled.div`
    min-width: 10px;
    height: 10px;
    border-radius: 30px;
    background: ${({ category, free, paid }) => {
        if (category === 'free' || free) {
            return eventTypes.free.color;
        } else if (category === 'private' || paid) {
            return eventTypes.paid.color;
        } 
    }};
`;

const EventType = styled.span`
    height: 25px;
    color: ${colors.primaryDashboard};
    border-radius: 4px;
    padding-left: 4px;
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: ${fonts.sizes.default};
`;

const IlustrationContainer = styled.picture`
    display: none;
    width: auto;
    height: 300px;
    margin-right: 40px;
    @media screen and (min-width: ${breakpoints.sm}) {
        display: flex;
    }
`;
