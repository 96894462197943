import Button from 'components/_UI/Button';
import Text from 'components/_UI/Text';
import React, { useEffect, useState } from 'react'
import paths from 'routes/paths';
import gtag from 'scripts/gtag';
import gtm from 'scripts/gtm';
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import fonts from 'theme/fonts';

const STORAGE_COOKIES_KEY = 'authorize_cookies';

export default function CookieBanner() {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const isAuthorized = Number(localStorage.getItem(STORAGE_COOKIES_KEY));

        if (!isAuthorized) {
            setVisible(true);
            return
        }

        appendScripts();
    }, [])

    function rejectCookies() {
        localStorage.setItem(STORAGE_COOKIES_KEY, 0);
        setVisible(false);
    }

    function authorizedCookies() {
        localStorage.setItem(STORAGE_COOKIES_KEY, 1);
        setVisible(false);
        appendScripts();
    }

    function appendScripts() {
        const head = document.head;
        
        head.appendChild(gtag());
        head.appendChild(gtm());
    }


    return (
        <Banner visible={visible}>
            <Content>
                <CookieImage src='/assets/images/cookie.png' />
                <Text>
                    Utilizamos cookies para melhorar a sua experiência dentro da plataforma. Para conhecer mais sobre cookies no Whizr, acesse os nossos
                    <a href={paths.terms.useTerms} target='_blank'> Termos de uso </a>
                    e
                    <a href={paths.terms.policiePrivacy} target='_blank'> Política de privacidade</a>.
                </Text>
            </Content>

            <ButtonsContainer>
                <Column width='104px' marginRight='10px'>
                    <Button
                        variant='light'
                        onClick={rejectCookies}
                    >
                        Rejeitar
                    </Button>
                </Column>
                <Column width='104px'>
                    <Button
                        variant='lightBlue'
                        onClick={authorizedCookies}
                    >
                        Aceitar
                    </Button>
                </Column>
            </ButtonsContainer>
        </Banner>
    )
}


const Banner = styled(Column)`
    background: ${colors.neutralLight};
    border-radius: 8px;
    position: fixed;
    bottom: 12px;
    z-index: 99;
    width: 94%;
    right: 3%;
    padding: 14px 10px;
    box-shadow: 0 2px 6px ${colors.nightLight};
    border: 1px solid ${colors.nightLight};
    display: none;

    ${({ visible }) => visible && `
        display: flex;
    `};
    
    @media screen and (min-width: ${breakpoints.lg}){
        width: 28%;
        right: 10px;
    }
`;

const Content = styled(Row)`
    & > p > a  {
        color: ${colors.primaryLight};
        cursor: pointer;
    }
`;

const CookieImage = styled.img`
    width: 48px;
    height: 48px;
    margin-right: 15px;
`;

const ButtonsContainer = styled(Row)`
    width: 100%;
    justify-content: flex-end;
    margin-top: 8px;

    & > div > button {
        padding: 6px 2px;
        font-weight: ${fonts.weight.regular};
        box-shadow: unset;
        margin-bottom: 0;
        
    }
`;

