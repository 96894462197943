import DashContainer from 'modules/dashboard/_common/DashContainer'
import Checkin from 'modules/dashboard/checkin/Checkin'
import React from 'react'

export default function DashCheckin() {
  return (
    <DashContainer title={'Validação'}>
        <Checkin />
    </DashContainer>
  )
}
