import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import breakpoints from 'theme/breakpoints'
import ProductCard from './ProductCard'
import Paginate from 'components/_UI/Paginate'
import { Row } from 'styled/alignment/Row'
import Modal from 'components/_UI/Modal'
import ProductDetail from '../detail/ProductDetail'
import { useContainerClick } from 'context/dash/ContainerClick'

export default function ProductsList({ data, perPage, totalItems, setVisibleDetail, visibleDetail, onPaginate, lastPage, currentPage, onDropdownAction, onProductClick }) {
    const { containerClick } = useContainerClick()
    const [selected, setSelected] = useState(false)

    useEffect(() => {
        if (containerClick) {
            setSelected(false)
        }
    }, [containerClick])

    return (
        <Container>
            {
                data?.map((el, index) => (
                    <ContentProductCard key={index} onClick={(e) => e.stopPropagation(e)}>
                        <ProductCard
                            data={el}
                            onMoreClick={() => setSelected(index)}
                            dropDownVisible={index === selected}
                            visibleMoreAction
                            onProductClick={() => {
                                onProductClick(el)
                                setSelected(index)
                            }}
                            onDropdownAction={e => { onDropdownAction({ action: e, ...el }) }}
                        />
                    </ContentProductCard>
                ))
            }
            {
                lastPage > 1 &&
                <Paginate perPage={perPage} totalItems={totalItems} lastPage={lastPage} firstPage={1} onPaginate={onPaginate} currentPage={currentPage} marginTop={'30px'} />
            }

            <Modal isOpen={visibleDetail} onClose={() => setVisibleDetail(false)}>
                <ProductDetail data={visibleDetail} />
            </Modal>


        </Container>
    )
}

const Container = styled(Column)`
    width: 100%;
    margin-top: 30px;
    @media screen and (min-width: ${breakpoints.sm}){
        flex-direction: row;
        flex-wrap: wrap;
    }

`;

const ContentProductCard = styled(Column)`
    width: 100%;
    cursor: pointer;
    margin-right: 1%;
    margin-bottom: 2%;
    
    @media screen and (min-width: ${breakpoints.sm}){
        width: 40%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: 1%;
        margin-bottom: 1%;
    }
    @media screen and (min-width: ${breakpoints.lg}){
        width: 31.3%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: 1%;
        margin-left: 1%;
        margin-bottom: 1%;
    }

    transition: all ease-in-out .25s;


    &:hover {
        transform: scale(1.01)
    }
   
`;