const regex = {
    onlyAlphabetic: /[a-zA-Z\u00C0-\u00FF ]+/i,
    onlyNumber: /^\d+$/,
    nonDigit: /\D/g,
    time: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
    email: /\S+@\S+\.\S+/,
    password: /^(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9\s])[A-Za-z\d\W_]{8,}$/,
    date: /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/,
    shortDate: /^(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
    cpf: /^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/,
    cnpj: /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/,
    phone: /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/,
    link: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
    cep: /^\d{5}-?\d{3}$/,
    creditCard: /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9]{2})[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
    
};

export default regex;