import { createGlobalStyle } from 'styled-components';
import breakpoints from 'theme/breakpoints';

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        @media (max-width: 1080px) {
            font-size: 93.75%
        }
        @media (max-width: 720px) {
            font-size: 87.5%
        }
    }

    a {
        text-decoration: none;
    }

    body {
        --webkit-font-smoothing: antialiased;
        
        a[href^="http://maps.google.com/maps"]{
            display:none !important
        }
       
        a[href^="https://maps.google.com/maps"]{
            display:none !important
        }

        .gmnoprint a, .gmnoprint span, .gm-style-cc {
            display: none;
        }

        .gmnoprint div {
            background: none !important;
        }

        .gm-fullscreen-control {
            display: none;
        }

        .gm-zoom-control {
            display: none !important;
        }

        .gmnoprint {
            display: none;
        }

        /* .zsiq_theme1 .zsiq_floatmain:before {
                font-size: 16px !important;
    
                @media screen and (min-width: ${breakpoints.lg}) {
                       font-size: 25px;
                }
        }

        .siq_bR {
            bottom: 70%;
        } */
    }

    body, input, textarea, button {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        --webkit-font-smoothing: antialiased;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 600;
    }

    button {
        cursor: pointer;
        border: 0;
    }

    [disabled] {
        opacity: .6;
        cursor: not-allowed;
    }
`;