import Text from 'components/_UI/Text';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicInfo from './parts/BasicInfo';
import { useForm } from 'react-hook-form';
import SocialMedia from './parts/SocialMedia.js'
import ImageInfo from './parts/ImageInfo';
import ImageCover from './parts/ImageCover';
import CategoriesInfo from './parts/CategoriesInfo';
import FeaturesSelects from './parts/FeaturesSelects';
import AddressInfo from './parts/AddressInfo';
import { useEstablishment } from 'context/establishments/Establishment';
import Button from 'components/_UI/Button';
import ImageMenu from './parts/ImageMenu';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import ServiceOptions from './parts/ServiceOptions';
import imageUploadApi from 'api/requests/imageUpload';
import establishmentsApi from 'api/requests/establishments';
import userApi from 'api/requests/user';
import Loader from 'components/_UI/Loader';
import unformat from 'helpers/unformat';
import Hours from './parts/Hours';
import { useMyEstablishment } from 'context/establishments/MyEstablishment';
import colors from 'theme/colors';
import { useUser } from 'context/user/User';
import { FullyCentered } from 'styled/alignment/Center';
import ToastContainer from 'components/_UI/ToastsContainer';
import useToast from 'hooks/useToast';
import validateInputFile from 'helpers/validate-input-file';
import allowedFilesTypes from 'texts/messages/allowedFilesTypes';

export default function ProfileForm({ onSuccess, isEdit, onCloseCollapse = () => false }) {
  const [trigger, setTrigger] = useState(false);
  const [tagsError, setTagsError] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false)
  const [errorImageInfo, setErrorImageInfo] = useState(false);
  const [errorImageCover, setErrorImageCover] = useState(false);
  const [errorImageMenu, setErrorImageMenu] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [imgBackground, setImgBackground] = useState(false);
  const [imgProfile, setImgProfile] = useState(false);
  const [menuFile, setMenuFile] = useState(false);
  const { toast, toasts } = useToast()

  const { establishment, setEstablishment } = useEstablishment()
  const { myEstablishment, setMyEstablishment } = useMyEstablishment()
  const { user, setUser } = useUser();

  const [serviceOptions, setServiceOptions] = useState([])
  const [categories, setCategories] = useState([])
  const [features, setFeatures] = useState([])
  const [socialMedia, setSocialMedia] = useState([])

  const [serviceOptionsSelected, setServiceOptionsSelected] = useState([])
  const [categoriesSelected, setCategoriesSelected] = useState([])
  const [featuresSelected, setFeaturesSelected] = useState([])
  const [basicInfo, setBasicInfo] = useState([])
  const [socialMediaContent, setSocialMediaContent] = useState([])
  const [addressContent, setAddressContent] = useState(null)
  const [errorTypeImageProfile, setErrorTypeImageProfile] = useState('')
  const [errorTypeImageBackground, setErrorTypeImageBackground] = useState('')
  const [errorTypeMenu, setErrorTypeMenu] = useState('')

  const [loaddingOptions, setLoadingOptions] = useState(false)
  const [isUpdateImage, setIsUpdateImage] = useState(false)


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      description: ''
    }
  })

  


  useEffect(() => {

    if (isEdit) {
      reset({
        description: isEdit && isEdit?.description,
        nameEstablishment: isEdit && isEdit?.name,
        phone: isEdit?.phone && isEdit?.phone,
        delivery: isEdit?.delivery_url,

      })
      setImgBackground(isEdit?.backgroundImage?.id)
      setImgProfile(isEdit?.profileImage?.id)
      setMenuFile(isEdit?.menuImage?.id)
    }
  }, [isEdit])

  useEffect(() => {
    getOptions();
  }, [isEdit]);

  async function getOptions() {
    setLoadingOptions(true);

    try {
      const { data } = await establishmentsApi.getOptions();
      setCategories(data?.tags);
      setServiceOptions(data?.serviceOptions);
      setFeatures(data?.features);
      const formatedSocialMediaData = data?.socialMedia?.map(el => {
        return {
          label: el.name,
          social_media_id: el.id,
          url: ''
        }
      })
      setSocialMedia(formatedSocialMediaData);
    } catch (error) {
      // console.log(error)
    } finally {
      setLoadingOptions(false);
    }
  }

  
  async function onToggleDeleteImgMenu() {
    setErrorImageMenu(false)
    setErrorTypeMenu(false)
    try {
      await imageUploadApi.delete(menuFile)
      setEstablishment({ ...establishment, menu_image_id: null });
      setMenuFile(null)
    } catch (error) {
      // console.log(error);
    }
  }
  async function onToggleDeleteImgBackground() {
    setErrorImageCover(false)
    setErrorTypeImageBackground(false)
    try {
      await imageUploadApi.delete(imgBackground)
      setImgBackground(null)
      setEstablishment({ ...establishment, background_image_id: null });
    } catch (error) {
      // console.log(error);
    }
  }

  async function onToggleDeleteImgProfile() {
    setErrorImageInfo(false)
    setErrorTypeImageProfile(false)
    try {
      await imageUploadApi.delete(imgProfile)
      setImgProfile(null)
      setEstablishment({ ...establishment, profile_image_id: null });
    } catch (error) {
      // console.log(error);
    }
  }

  async function uploadImageBackground(file, folder) {
    setIsUpdateImage(true)


    const validationFile = validateInputFile(file)

    if (!validationFile.isValid) {
      setErrorTypeImageBackground(validationFile.message);
      setIsUpdateImage(false);
      return;
    }

    const formData = new FormData()
    formData.append('image', file)
    formData.append('folder', folder)

    

    try {
      const { data } = await imageUploadApi.uploadWithFolder(formData)
      setEstablishment({ ...establishment, background_image_id: data.id });
      setImgBackground(data?.id)
    } catch (error) {
      setErrorImageCover(true)
    } finally {
      setIsUpdateImage(false)
    }
  }

  async function uploadImageProfile(file, folder) {
    
    setIsUpdateImage(true)
    const validationFile = validateInputFile(file)

    if (!validationFile.isValid) {
      setErrorTypeImageProfile(validationFile.message);
      setIsUpdateImage(false);
      return;
    }
  
    const formData = new FormData()
    formData.append('image', file)
    formData.append('folder', folder)

    try {
      const { data } = await imageUploadApi.uploadWithFolder(formData)
      // setEstablishment({ ...establishment, profile_image_id: data.id });
      setImgProfile(data?.id)

    } catch (error) {
      setErrorImageInfo(true)
    } finally {
      setIsUpdateImage(false)
    }
  }

  async function uploadImageMenu(file, folder) {
    setIsUpdateImage(true)

    const additionalFileTypes = ['application/pdf']
    const validationFile = validateInputFile(file, 5, additionalFileTypes)

    if (!validationFile.isValid) {
      setErrorTypeMenu(validationFile.message);
      setIsUpdateImage(false);
      return;
    }
  
    const formData = new FormData()
    formData.append('image', file)
    formData.append('folder', folder)

    try {
      const { data } = await imageUploadApi.uploadWithFolder(formData)
      // setEstablishment({ ...establishment, menu_image_id: data.id });

      setMenuFile(data?.id)
    } catch (error) {
      setErrorImageMenu(true)
    } finally {
      setIsUpdateImage(false)
    }
  }



  function createAddressPayload(address) {
    let payload = '';

    if (address?.number) {
      payload = {
        postalcode: unformat.getNumbers(address?.street?.postalcode) || unformat.getNumbers(address?.postalcode),
        number: address?.number,
        complement: address?.complement
      }

      if (!payload?.complement) {
        delete payload.complement
      }

      return payload
    }

    if (address?.name) {
      payload = {
        postalcode: unformat.getNumbers(address?.street?.postalcode) || unformat.getNumbers(address?.postalcode),
        name: address?.name
      }

      if (!payload?.complement) {
        delete payload.complement
      }

      return payload
    }
  }

  function isValidTags() {
    if (tagsError) {
      setTagsError('');
    }

    if (categoriesSelected?.length > 5) {
      setTagsError('max');
      return false;
    }

    if (!categoriesSelected || categoriesSelected?.length === 0) {
      setTagsError('min');
      return false;
    }

    return true;
  }

  function isValidAddress() {
    if (!addressContent?.address) {
      setErrorAddress('Preencha os campos de endereço');
      return false;
    }


    if (!addressContent?.address?.number && !nameValue) {
      setErrorAddress('Preencha o número do endereço');
      return false;
    }

    return true;
  }

  const createPayload = (data) => {
    const payload = {
      establishment: {
        // ...establishment,
        adulthood: basicInfo?.adulthood,
        name: data?.nameEstablishment,
        description: data?.description,
        cnpj: unformat.getNumbers(user?.document),
        phone: unformat.getNumbers(data?.phone),
        social_reason: user?.first_name,
        classification_id: basicInfo?.classification_id || import.meta.env.VITE_ESTABLISHMENT_TYPE_FREE,
        menu_image_id: menuFile,
        background_image_id: imgBackground,
        profile_image_id: imgProfile,
        delivery_url: genericUrlPayload(data?.delivery),
      },
      address: createAddressPayload(addressContent.address),
      tags_establishments: categoriesSelected?.map(el => ({ tag_establishment_id: el })),
      features: featuresSelected ? featuresSelected.map(el => ({ feature_id: el })) : [],
      service_options: serviceOptionsSelected ? serviceOptionsSelected.map(el => ({ service_option_id: el })) : [],
      social_media: createSocialMediaPayload(socialMediaContent),
      days: removeEmptyTimeSlots(establishment?.days),
    }

    if (!payload?.establishment?.delivery_url) {
      delete payload?.establishment?.delivery_url
    }

    if (!payload?.establishment?.phone) {
      delete payload?.establishment?.phone
    }

    if (!isEdit) {
      if (!payload?.establishment?.menu_image_id) {
        delete payload?.establishment?.menu_image_id
      }
      if (!payload?.establishment?.profile_image_id) {
        delete payload?.establishment?.profile_image_id
      }
      if (!payload?.establishment?.background_image_id) {
        delete payload?.establishment?.background_image_id
      }
    }


    return payload
  }

  function removeEmptyTimeSlots(days) {
    for (const day in days) {
      if (days.hasOwnProperty(day)) {
        days[day] = days[day].filter(slot => !(slot.start === '' && slot.end === '' && slot.allDay === false));
      }
    }
    return days;
  }

  function genericUrlPayload(item) {
    let link = item;

    if (!link) {
      return false
    }

    if (!link.startsWith('http') && !link.startsWith('www.')) {
      link = `http://${link}`;
    }

    if (!link.startsWith('http') && link.startsWith('www.')) {
      const sanitizedUrl = link.split('www.')[1];
      link = `http://${sanitizedUrl}`;
    }

    return link;
  }

  function createSocialMediaPayload(data) {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }

    const adjustedArray = data.map(item => {
      if (item.label == 'WhatsApp' && !item.url.startsWith('http') && !item.url.startsWith('www.')) {
        const onlyNumbers = item.url.replace(/\D/g, '')
        item.url = `http://wa.me/55${onlyNumbers}`
      }

      if (!item.url.startsWith('http') && !item.url.startsWith('www.')) {
        item.url = `http://${item.url}`;
      }

      if (!item.url.startsWith('http') && item.url.startsWith('www.')) {
        const sanitizedUrl = item.url.split('www.')[1];
        item.url = `http://${sanitizedUrl}`;
      }


      if (item.label) {
        delete item.label
      }

      return item;
    });

    return adjustedArray;
  }

  const createProfile = async (content) => {
    const tagsValid = isValidTags();
    const addressValid = isValidAddress();

    if (!tagsValid || !addressValid) {
      return;
    }

    setIsLoading(true)
    const payload = createPayload(content);
    // console.log('payload', payload);
    // return

    try {
      const { data } = await establishmentsApi.create(payload)
      const { data: updatedUser } = await userApi.me();
      setMyEstablishment(data?.data)
      setUser(updatedUser)
      onSuccess(data, 'criado');
      onCloseCollapse();
    } catch (error) {
      toast.error('Ocorreu um erro inesperado', {
        position: 'top-center'
      });
    } finally {
      setIsLoading(false)
    }
  }

  async function updateProfile(content) {
    const tagsValid = isValidTags();
    const addressValid = isValidAddress();

    if (!tagsValid || !addressValid) {
      return;
    }
    setIsLoading(true)
    const payload = createPayload(content);

    try {
      const { data } = await establishmentsApi.update(isEdit?.slug, payload)
      const { data: updatedUser } = await userApi.me();
      setMyEstablishment(data?.data)
      setUser(updatedUser)
      onSuccess(data, 'atualizado');
    } catch (error) {
      toast.error('Ocorreu um erro inesperado', {
        position: 'top-center'
      });
    } finally {
      setIsLoading(false)
    }

  }

  return (
    <FormContainer onSubmit={isEdit ? handleSubmit(updateProfile) : handleSubmit(createProfile)}>
      <ToastContainer toasts={toasts} />
      <Text name='title' color={colors.occasionalPurple}>
        {`${isEdit ? 'Editar' : 'Criar'}`} estabelecimento
      </Text>

      <BasicInfo
        register={register}
        errors={errors}
        isEdit={isEdit}
        basicInfo={basicInfo}
        setBasicInfo={setBasicInfo}
      />

      <ContainerImages>
        <ImageInfo
          isEdit={isEdit}
          onToggleImage={(e) => uploadImageProfile(e, 'image_profile')}
          onDeleteFile={() => onToggleDeleteImgProfile()}
          errorImageInfo={errorImageInfo}
          errorTypeImageProfile={errorTypeImageProfile}
          setErrorImageInfo={setErrorImageInfo}
          setErrorTypeImageProfile={setErrorTypeImageProfile}
          allowedFiles={allowedFilesTypes.images}
        />
        <ImageCover
          isEdit={isEdit}
          onToggleImage={(e) => uploadImageBackground(e, 'image_background')}
          onDeleteFile={() => onToggleDeleteImgBackground()}
          errorTypeImageBackground={errorTypeImageBackground}
          errorImageCover={errorImageCover}
          setErrorImageCover={setErrorImageCover}
          setErrorTypeImageBackground={setErrorTypeImageBackground}
          allowedFiles={allowedFilesTypes.images}
        />
      </ContainerImages>

      <ImageMenu
        onToggleImage={(e) => uploadImageMenu(e, 'menu')}
        onDeleteFile={() => onToggleDeleteImgMenu()}
        errorImageMenu={errorImageMenu}
        errorTypeMenu={errorTypeMenu}
        setErrorImageMenu={setErrorImageMenu}
        setErrorTypeMenu={setErrorTypeMenu}
        isEdit={isEdit}
        allowedFilesMenu={allowedFilesTypes.imagesAndPdf}
      />

      <CategoriesInfo
        error={tagsError}
        data={categories}
        loading={loaddingOptions}
        setCategories={setCategoriesSelected}
        isEdit={isEdit?.tagsEstablishments}
      />

      <Hours
        isEdit={isEdit?.dates}
      />

      <ServiceOptions
        data={serviceOptions}
        loading={loaddingOptions}
        setServiceOptions={setServiceOptionsSelected}
        isEdit={isEdit?.serviceOptions}
      />

      <FeaturesSelects
        isEdit={isEdit?.features}
        data={features}
        setFeatures={setFeaturesSelected}
        loading={loaddingOptions}
      />

      <SocialMedia
        isEdit={isEdit?.socialMedia}
        data={socialMedia}
        loading={loaddingOptions}
        setSocialMediaContent={setSocialMediaContent}
      />

      <AddressInfo
        trigger={trigger}
        isEdit={isEdit?.address}
        errorAddress={errorAddress}
        setErrorAddress={setErrorAddress}
        nameValue={nameValue}
        setNameValue={setNameValue}
        addressContent={addressContent}
        setAddressContent={setAddressContent}
      />

      {!isLoading ? (
        <ContentButtonSubmit onClick={() => setTrigger(true)}>
          <Button
            marginTop={'18px'}
            paddingBottom={'30px'}
            type="submit"
            variant={isUpdateImage ? 'disabled' : 'featured'}
          >
            {isEdit ? 'Editar' : 'Criar'} estabelecimento
          </Button>
        </ContentButtonSubmit>
      ) : (
        <FullyCentered marginTop='20px'>
          <Loader />
        </FullyCentered>
      )}

    </FormContainer>
  )
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContainerImages = styled(Column)`
  @media screen and (min-width: ${breakpoints.lg}){
    display: flex;
    align-items: center;
    flex-direction: row;
  }
`
const ContentButtonSubmit = styled.span`
    width: 100%;
    
`;