import ButtonSelect from 'components/_UI/ButtonSelect'
import Loader from 'components/_UI/Loader'
import { ButtonSelectItem, SelectContainer, SelectWrapper } from 'components/_UI/SelectsContent'
import Text from 'components/_UI/Text'
import { useEventFilter } from 'context/events/EventFilter'
import { useTags } from 'context/events/Tags'
import React, { useState, useEffect } from 'react'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'
import tagsApi from 'api/requests/tags'
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center'
import breakpoints from 'theme/breakpoints'
import { Row } from 'styled/alignment/Row'

let categories = [];

export default function CategoriesSelects({refresh}) {
    const { tags, setTags } = useTags();
    const { eventFilter, setEventFilter } = useEventFilter();

    const [selectedItems, setSelectedItems] = useState([]);
    const [loadCategories, setLoadCategories] = useState(false);

    useEffect(() => {
        getCategories()

        if (refresh) {
            categories = []
        }

    }, [refresh])


    async function getCategories() {
        if (tags) {
            return
        }

        setLoadCategories(true)

        try {
            const { data } = await tagsApi.get(1)
            setTags(data)
        } catch (error) {
            console.log('error')
        } finally {
            setLoadCategories(false)
        }
    }

    const handleSelectItem = (name) => {
        setSelectedItems(prevSelectedItems => {
            let newSelectedItems;

            if (prevSelectedItems.includes(name)) {
                newSelectedItems = prevSelectedItems.filter(item => item !== name);
            } else {
                newSelectedItems = [...prevSelectedItems, name];
            }   

            setEventFilter({
                ...eventFilter,
                categories: newSelectedItems
            });

            return newSelectedItems;
        });
    };


    return (
        <Column marginTop='18px'>
            <Text size='small' type='bold'> Categorias</Text>
            <Text size='small' color={colors.night}>Escolha as categorias que deseja buscar.</Text>
            <StyledList>
             
                {
                    loadCategories ? (
                        <FullyCentered>
                            <Loader />
                        </FullyCentered>
                    ) : (
                        tags?.map(el => (
                            <SelectItem
                                key={el.id}
                                selected={selectedItems.includes(el.name)}
                                onClick={() => handleSelectItem(el.name)}
                            >
                                <Text
                                    name='mini'
                                    textAlign={'center'}
                                    paddingBottom={0}
                                    type='bold'
                                    ellipsisAt={'98%'}
                                    color={selectedItems.includes(el.name) ? colors.neutral : colors.night}
                                >
                                    {el.name}
                                </Text>
                            </SelectItem>
                        ))
                    )
                }
            </StyledList>
        </Column>
    );
}

const StyledList = styled(Row)`
    flex-wrap: wrap;
    justify-content: space-around;
`;

const SelectItem = styled.div`
    width: 30%;
    margin-bottom: 3%;
    border: 1px solid ${colors.night};
    padding: 10px 5px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;


    @media screen and (min-width: ${breakpoints.lg}){
        width: 15%;
    }

    ${({ selected }) => selected && `
        background: #4617ad;
        border: 1px solid #4617ad;
    `};
`;
