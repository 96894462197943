import React, { useState } from 'react';
import Button from 'components/_UI/Button';
import Fieldset from 'components/_UI/Fieldset';
import Text from 'components/_UI/Text';
import masks from 'helpers/masks';
import { Column } from 'styled/alignment/Column';
import { StyledForm } from 'styled/UI/StyledForm';
import colors from 'theme/colors';
import { cpf as cpfValidator, cnpj as cnpjValidator, cnpj } from 'cpf-cnpj-validator';
import sizes from 'theme/sizes';
import { useForm } from 'react-hook-form';
import { FullyCentered, HorizontalCenter } from 'styled/alignment/Center';
import fieldValidations from 'helpers/fieldValidations';
import buttonText from 'texts/buttons/buttonText';
import paths from 'routes/paths';
import userApi from 'api/requests/user';
import Loader from 'components/_UI/Loader';
import fieldMessages from 'texts/messages/field';
import styled from 'styled-components';
import unformat from 'helpers/unformat';
import { Row } from 'styled/alignment/Row';
import { Link } from 'react-router-dom';

import ConfirmEmailModal from 'modules/onboarding/sign-in/ConfirmEmailModal';
import { useUser } from 'context/user/User';
import Icon from 'components/_UI/Icon';
import { Check, CheckCircle, User } from 'react-feather';
import breakpoints from 'theme/breakpoints';
import debounce from 'helpers/debounce';

const errorMessages = {
    unique: 'E-mail já cadastrado',
    fail: 'Ocorreu um erro, tente novamente.'
}



export default function PartnerForm() {

    const [selectedRole, setSelectedRole] = useState(null);

    const [validateEmailError, setValidateEmailError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false)
    const [equalPasswordError, setEqualPasswordError] = useState(false)
    const [selectedTerms, setSelectedTerms] = useState(false)
    const [showConfirmEmail, setShowConfirmEmail] = useState(false)
    const [error, setError] = useState(false)
    const [errorSelectedRole, setErrorSelectedRole] = useState(null)
    const [visibleDocument, setVisibleDocument] = useState(false)
    const [visibleCnpj, setVisibleCnpj] = useState(false)
    const [isCnpj, setIsCnpj] = useState(false)
    const [isDocument, setIsDocument] = useState(false)
    const [errorCnpj, setErrorCnpj] = useState(false)
    const [errorDocument, setErrorDocument] = useState(false)

    const { user, setUser } = useUser();

    const typesRoles = [
        {
            role_id: import.meta.env.VITE_PRODUCER_ROLE_ID,
            label: 'Organizador',
            text: 'Organiza e produz eventos, mas não possui estabelecimento físico.',
            icon: 'user'
        },
        {
            role_id: import.meta.env.VITE_ESTABLISHMENT_ROLE_ID,
            label: 'Estabelecimento',
            text: 'Possui um espaço físico, e pode organizar eventos e vender produtos.',
            icon: 'store'
        },

    ]

    const { register, handleSubmit, formState: { errors }, setValue, watch, resetField } = useForm();



    async function validateData({ corporateReason, confirmPassword, password, email, content, document, cnpj }) {
        setLoading(true);
        let hasError = false;

        if (password !== confirmPassword) {
            setEqualPasswordError(true);
            setLoading(false);
            return;
        }

        if (!selectedRole) {
            setErrorSelectedRole('Você precisa selecionar uma categoria.')
            setLoading(false)
            return
        }


        if (selectedRole) {
            if (!cnpj) {
                if (!document) {
                    setErrorDocument('Preencha o CNPJ ou CPF!')
                    setLoading(false)
                    return
                }
            }
        }

        setLoading(true);

        try {
            const { data } = await userApi.dataEnable({ email });

            hasError = !data.email;

            if (!data.email) {
                setLoading(false);
            }


            if (data?.email) {
                if (!cnpj) {

                    const formattedCnpj = unformat.getNumbers(document);


                    if (isCnpj) {
                        if (!cnpjValidator.isValid(formattedCnpj)) {
                            setErrorCnpj('CNPJ inváldo');
                            setErrorDocument(false)
                            setLoading(false);
                            return;
                        }
                    }
                    if (isDocument) {
                        if (!cpfValidator.isValid(formattedCnpj)) {
                            setErrorDocument('CPF inváldo');
                            setErrorCnpj(false)
                            setLoading(false);
                            return;
                        }
                    }
                }


                createPartner({ corporateReason, confirmPassword, password, email, cnpj, document });
            }

        } catch (error) {
            hasError = 'fail';
            setLoading(false);
        }



        if (hasError === 'fail') {
            setValidateEmailError(true);
            return;
        }

        if (hasError) {
            setEmailError(true);
            return;
        }
    }

    async function createPartner({ corporateReason, email, password, cnpj, document }) {

        const payload = createPayload({ corporateReason, password, email, cnpj, document })

        try {
            await userApi.create(payload);
            setShowConfirmEmail(true);
            setUser(payload);

        } catch ({ response }) {
            if (response.status == 422) {
                if (response?.data?.errors?.document == 'O document já foi utilizado.') {
                    setError('O CNPJ ou CPF já foi utilizado')
                    return
                }
            }
        } finally {
            setLoading(false)
        }
    }

    const createPayload = ({ corporateReason, email, password, cnpj, document }) => {
        const payload = {
            first_name: corporateReason,
            document: cnpj ? unformat.getNumbers(cnpj) : unformat.getNumbers(document),
            email: email,
            password: password,
            role_id: selectedRole
        };


        return payload;
    };

    function handleDocumentChange(e) {
        let value = e.target.value;
        value = value.replace(/\D/g, '');


        if (value.length > 14) {
            value = value.slice(0, 14);
        }

        let formattedValue = '';


        if (value.length <= 14) {
            setIsCnpj(true)
            setIsDocument(false)
            formattedValue = value
                .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
                .slice(0, 18);

        }
        if (value.length <= 11) {

            setIsDocument(true)
            setIsCnpj(false)
            formattedValue = value
                .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
                .slice(0, 14);
        }

        setValue('document', formattedValue, { shouldValidate: true });
    }

    function handleKeyPress(e) {
        if (!/[\d]/.test(e.key)) {
            e.preventDefault();
        }
    }

    function handleKeyUp(e) {

        debounce((e) => {
            e && handleDocumentChange(e);
        }, 500)
    }

    function changeRole(role) {
        setSelectedRole(role)

        if (role == import.meta.env.VITE_PRODUCER_ROLE_ID) {
            setVisibleDocument(true)
            setVisibleCnpj(false)
            resetField('cnpj')
        }

        if (role == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID) {
            setVisibleCnpj(true)
            setVisibleDocument(false)
            resetField('document')
        }
    }


    return (
        <ContainerForm>
            <ContentTiles>
                <Text color={colors.nightDark} marginTop={'25px'} type='bold' name='highlight'>Para começarmos,</Text>
                <Text color={colors.nightDark} type='bold' name='highlight'>preencha suas informações básicas.</Text>
            </ContentTiles>

            <StyledForm
                marginTop={sizes.normal}
                paddingRight='13%'
                paddingLeft='13%'
                onSubmit={handleSubmit(validateData)}
            >
                <Fieldset
                    placeholder='Razão social *'
                    layout='straight-center-dark'
                    marginTop={sizes.normal}
                    name="corporateReason"
                    register={register}
                    validations={fieldValidations.name('razão social')}
                />
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors.corporateReason?.message} </Text>
                </HorizontalCenter>



                {error && (
                    <HorizontalCenter marginTop={'10px'}>
                        <Text color={colors.danger}>{error}</Text>
                    </HorizontalCenter>
                )}

                <Fieldset
                    placeholder='E-mail *'
                    layout='straight-center-dark'
                    marginTop={sizes.normal}
                    name='email'
                    register={register}
                    validations={fieldValidations.email()}
                />


                {
                    errors.email?.message && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.email?.message} </Text>
                        </HorizontalCenter>
                    )
                }

                {
                    emailError && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errorMessages.unique} </Text>
                        </HorizontalCenter>
                    )
                }

                <Fieldset
                    placeholder='Senha *'
                    layout='straight-center-dark'
                    marginTop={sizes.normal}
                    inputType='password'
                    badgeIconPassword
                    badgeIconColorPassword={colors.night}
                    badgeIconPositionPassword={'right'}
                    name='password'
                    register={register}
                    validations={fieldValidations.password('senha')}
                />
                {
                    errors.password?.message && (
                        <FullyCentered textAlign='center'>
                            <Text color={colors.danger}> {errors.password?.message} </Text>
                        </FullyCentered>
                    )
                }

                <Fieldset
                    placeholder='Confirme sua senha *'
                    layout='straight-center-dark'
                    badgeIconPassword
                    badgeIconColorPassword={colors.night}
                    badgeIconPositionPassword={'right'}
                    marginTop={sizes.normal}
                    inputType='password'
                    name='confirmPassword'
                    register={register}
                    validations={fieldValidations.password('confirmação de senha')}
                />
                {
                    errors.confirmPassword?.message && (
                        <FullyCentered textAlign='center'>
                            <Text color={colors.danger}> {errors.confirmPassword?.message} </Text>
                        </FullyCentered>
                    )
                }

                {
                    equalPasswordError && (
                        <FullyCentered textAlign='center'>
                            <Text color={colors.danger}> {fieldMessages.passwordMustBeEqual} </Text>
                        </FullyCentered>
                    )
                }

                <Column verticalCenter marginBottom='20px' marginTop='10px'>
                    <Text marginBottom={'15px'} marginTop={'10px'} type='bold'>Em qual categoria você se encaixa?</Text>
                    <ContentTypeRole>
                        {typesRoles.map((el) => (
                            <StyledType key={el.role_id} onClick={() => changeRole(el.role_id)} selected={selectedRole === el.role_id}>


                                <RowBoxSelected selected={selectedRole === el.role_id} />
                                {el.icon == 'user' ? (
                                    <User size={'40px'} color={selectedRole === el.role_id ? colors.neutral : colors.night} />
                                ) : (
                                    <ContentStyledIcon>
                                        <Icon name={el.icon} color={selectedRole === el.role_id ? colors.neutral : colors.night} />
                                    </ContentStyledIcon>
                                )}
                                <Text type='bold' paddingBottom={0} marginTop={'5px'}>{el.label}</Text>
                                <Text name='small' textAlign={'center'} >
                                    {el.text}
                                </Text>

                            </StyledType>
                        ))}

                    </ContentTypeRole>

                    {
                        errorSelectedRole && (
                            <HorizontalCenter marginTop={'10px'}>
                                <Text color={colors.danger}> {errorSelectedRole} </Text>
                            </HorizontalCenter>
                        )
                    }
                </Column>

                {
                    visibleCnpj && (
                        <>
                            <Fieldset
                                placeholder='CNPJ *'
                                layout='straight-center-dark'
                                marginTop={sizes.normal}
                                name="cnpj"
                                register={register}
                                mask={masks.cnpj}
                                validations={{
                                    required: 'CNPJ é obrigatório',
                                    validate: value => cnpjValidator.isValid(value) || 'CNPJ inválido'
                                }}
                            />
                            {errors.cnpj && (
                                <HorizontalCenter marginTop={'10px'}>
                                    <Text color={colors.danger}>{errors.cnpj.message}</Text>
                                </HorizontalCenter>
                            )}
                        </>
                    )
                }

                {visibleDocument &&
                    <>
                        <InputDocuments
                            placeholder='CNPJ ou CPF *'
                            onKeyPress={handleKeyPress}
                            onChange={(e) => handleDocumentChange(e)}
                            onKeyUp={handleKeyUp}
                            name='document'
                            value={watch('document') || ''}
                        />

                        {errorCnpj && (
                            <HorizontalCenter marginTop={'10px'} marginBottom='20px'>
                                <Text color={colors.danger}>{errorCnpj}</Text>
                            </HorizontalCenter>
                        )}
                        {errorDocument && (
                            <HorizontalCenter marginTop={'10px'} marginBottom='20px'>
                                <Text color={colors.danger}>{errorDocument}</Text>
                            </HorizontalCenter>
                        )}
                    </>
                }

                {/* <FullyCentered marginTop={sizes.micro}>
                    <Text
                        color={colors.danger}
                        type='bold'
                    >
                        {errorMessage}
                    </Text>
                </FullyCentered> */}



                <Row verticalCenter>
                    <input
                        type='checkbox'
                        checked={selectedTerms}
                        id='terms'
                        onChange={() => setSelectedTerms(!selectedTerms)}
                    />
                    <TextLabel for='terms'>Li e concordo com os <LinkTerms to={`${paths.terms.useTerms}?createPartner=1`}>Termos de Uso</LinkTerms> e a <LinkTerms to={`${paths.terms.policiePrivacy}`}>Política de Privacidade</LinkTerms>.</TextLabel>
                </Row>

                {
                    loading
                        ? <Loader />
                        : (
                            <>
                                <Button
                                    category='roundedBorded'
                                    variant={selectedTerms ? 'primary' : 'disabled'}
                                    size='large'
                                    marginTop={sizes.big}
                                    type='submit'
                                >
                                    {buttonText.confirm}
                                </Button>

                                <Button
                                    path={paths.login}
                                    category='minimalDark'
                                    size='large'
                                    marginTop={sizes.nano}
                                >
                                    {buttonText.back}
                                </Button>

                            </>
                        )
                }



                {validateEmailError &&
                    <FullyCentered>
                        <Text color={colors.danger}>{errorMessages.fail}</Text>
                    </FullyCentered>
                }
            </StyledForm>

            {showConfirmEmail && (
                <ConfirmEmailModal role={selectedRole} />
            )}
        </ContainerForm>
    )
};


const ContainerForm = styled(Column)`
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    @media screen and (max-width: ${breakpoints.md}) {
        padding-top: 100px;
        justify-content: center;
        align-items: center;
    }
`;

const LinkTerms = styled(Link)`
    font-size: 12px;
`;

const ContentTiles = styled(Column)`
    align-items: center;
    margin-top: 100px;

    @media screen and (min-width: ${breakpoints.lg}) {
        margin-top: 0;
    }
 
`;

const TextLabel = styled.label`
    margin-left: 10px;
    font-size: 12px;
    color: ${colors.nightDark};
`;

const ContentTypeRole = styled(Column)`
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (min-width: ${breakpoints.md}) {
        flex-direction: row;
    }
    /* @media screen and (min-width: ${breakpoints.lg}) { */
        /* flex-direction: row;
        justify-content: space-between; */
    /* } */
`;

const StyledType = styled(Column)`
    padding: 8px 15px;
    background: ${colors.neutral};
    border: 1px solid ${colors.night};
    border-radius: 10px;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    height: 170px;
    cursor: pointer;
    transition: all ease-in-out 0.25s;

    /* &:hover {
        transform: scale(1.01)
    } */
    & > p {
        color: ${colors.primaryDashboard};
    }
    ${({ selected }) => selected && `
        background: ${colors.secondaryPurple};
        & > p {
            color: ${colors.neutral} !important;
        }
    `}

    /* @media screen and (min-width: 545px) {
        width: 42%;
    } */
    @media screen and (min-width: ${breakpoints.md}) {    
        &:first-child {
            margin-right: 10px;
        }
    }
    
`;

const ContentStyledIcon = styled.div`
    width: 40px;
    height: 40px;
`;

const RowBoxSelected = styled(Row)`
    width: 100%;
    justify-content: flex-end;
    position: relative; 

    &::before {
        content: '';
        width: 15px;
        height: 15px;
        border: 1px solid ${colors.night};
        border-radius: 50%;
        position: absolute; 
        top: 50%; 
        right: 0; 
        transform: translate(50%, -50%); 
        display: flex; 
        align-items: center; 
        justify-content: center; 
        
        ${({ selected }) => selected && `
            content: '';
            background-color: ${colors.neutral};
            color: ${colors.night}; 
        `}
    }
    
    &::after {
        content: '';
        width: 15px;
        height: 15px;
        border: 1px solid ${colors.night};
        border-radius: 50%;
        position: absolute; 
        top: 50%; 
        right: 0; 
        transform: translate(50%, -50%); 
        display: flex; 
        align-items: center; 
        justify-content: center; 
        
        ${({ selected }) => selected && `
            content: '';
            background-color: ${colors.primaryLight};
            position: absolute;
            right: 0;
            top: 0;
            height: 6px;
            width: 6px;
        `}
    }
`;

const InputDocuments = styled.input`
    width: 100%;
    outline-color: transparent;
    transition: all ease .25s;
    background: transparent;
    outline: transparent;
    color: #707070;
    border: none;
    border-bottom: 2px solid #ACACAC;
    padding: 2px;
    font-weight: 700;
    text-align: center;
    color: #707070;
    margin-bottom: 20px;
`;


// const TextBody = styled.p`
//     font-weight: bold;
//     text-align: center !important;
//     color: ${colors.night} !important;
//     font-size: 12px;
//     max-height: 59px; 
//     font-family: 'Roboto', sans-serif;
// `