import { useState } from 'react';
import styled from 'styled-components';
import indexPriority from 'theme/z-index';
import Icon from 'components/_UI/Icon';
import sizes from 'theme/sizes';
import colors from 'theme/colors';
import { UserCheck, Trash2, Shield, LogOut, Lock } from 'react-feather'
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';

export function Menu (props) {

    const navigate = useNavigate()

    const {
        isMenuOpen,
        setIsMenuOpen,
        setOpenDeleteAccount,
        setOpenChangePassword,
        setOpenPersonalInfo,
    } = props;
    const [ activeMenu, setActiveMenu ] = useState('')
    const logout = () => {
        localStorage.removeItem("token");
        navigate(paths.home)
    }
    return (
        <>
            <CollapseContainer
                onClick={() => {
                    setIsMenuOpen(false)
                    setActiveMenu('')
                }}
                open={isMenuOpen}
            >
                <Collapse
                    open={isMenuOpen}
                    onClick={(e) => e.stopPropagation()}
                >
                    <List>
                
                        {/* <ListItem
                            active={activeMenu === 'configurations'}
                            onClick={() => {
                                setActiveMenu('configurations')
                                setOpenPersonalInfo(true)
                            }}
                        >
                            <IconContainer>
                                <Icon
                                    name='user'
                                    color={ activeMenu === 'configurations' ? colors.primary : colors.primaryDashboard }
                                />
                            </IconContainer>
                            <ItemTitle
                                active={activeMenu === 'configurations'}
                            >
                                Configurações
                            </ItemTitle>
                        </ListItem> */}

                        <ListItem
                            active={activeMenu === 'change_password'}
                            onClick={() => {
                                setActiveMenu('change_password')
                                setOpenChangePassword(true)
                            }}
                        >
                            <IconContainer>
                                <Lock
                                    color={ activeMenu === 'change_password' ? colors.primary : colors.primaryDashboard }
                                />
                            </IconContainer>
                            <ItemTitle
                                active={activeMenu === 'change_password'}
                            >
                                Alterar senha
                            </ItemTitle>
                        </ListItem>

                        {/* <ListItem
                            active={activeMenu === 'delete_account'}
                            onClick={() => {
                                setActiveMenu('delete_account')
                                setOpenDeleteAccount(true)
                            }}
                        >
                            <IconContainer>
                                <Trash2
                                    color={ activeMenu === 'delete_account' ? colors.primary : colors.primaryDashboard }
                                />
                            </IconContainer>
                            <ItemTitle
                                active={activeMenu === 'delete_account'}
                            >
                                Excluir conta
                            </ItemTitle>
                        </ListItem> */}

                        <ListItem
                            active={activeMenu === 'terms'}
                            onClick={() => window.location.href = paths.terms.useTerms}
                        >
                            <IconContainer>
                                <UserCheck 
                                        color={ activeMenu === 'terms' ? colors.primary : colors.primaryDashboard }
                                    />
                                </IconContainer>
                            <ItemTitle
                                active={activeMenu === 'terms'}
                            >
                                Termos de uso
                            </ItemTitle>
                        </ListItem>

                        <ListItem
                            active={activeMenu === 'privacy'}
                            onClick={() => window.location.href = paths.terms.policiePrivacy}
                        >
                            <IconContainer>
                                <Shield
                                    color={ activeMenu === 'privacy' ? colors.primary : colors.primaryDashboard }
                                />
                            </IconContainer>
                            <ItemTitle
                                active={activeMenu === 'privacy'}
                            >
                                Política de privacidade
                            </ItemTitle>
                        </ListItem>

                        <ListItem
                            active={activeMenu === 'exit'}
                            onClick={() => logout()}
                        >
                            <IconContainer>
                                <LogOut
                                    color={ activeMenu === 'exit' ? colors.primary : colors.primaryDashboard }
                                />
                            </IconContainer>
                            <ItemTitle
                                active={activeMenu === 'exit'}
                            >
                                Sair
                            </ItemTitle>
                        </ListItem>
                    </List>
                </Collapse>
            </CollapseContainer>
        </>
    )
}

const CollapseContainer = styled.div`
    position: fixed;
    top: ${sizes.none};
    left: ${sizes.none};
    right: ${sizes.none};
    bottom: ${sizes.none};
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: ${ props => props.open ? '1' : '0' };
    z-index: ${ props => props.open ? indexPriority.normal : indexPriority.negative };
    visibility: ${ props => props.open ? 'visible' : 'hidden' };
`;

const Collapse = styled.div`
    position: fixed;
    top: ${sizes.none};
    right: ${sizes.none};
    height: 100vh;
    background-color: ${colors.neutralLight};
    z-index: ${indexPriority.xSmall};
    overflow-y: auto;
    transform: ${ props => props.open ? 'none' : 'translateX(100%)' };
    transition: 325ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const IconContainer = styled.div`
    width: ${sizes.large};
    height: ${sizes.large};
`

const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${sizes.little};
    overflow-y: scroll;
    padding: ${sizes.normal};
`;

const ListItem = styled.li`
    display: flex;
    align-items: center;
    gap: ${sizes.big};
    padding: ${sizes.mini} ${sizes.little};
    list-style: none;
    background: ${ props => props.active ? colors.neutralBackground : '' };
    border-radius: ${sizes.micro};
`

const ItemTitle = styled.p`
    color: ${ props => props.active ? colors.primary : colors.primaryDashboard }
`;