const colors = {
    primary: '#3F6BC6',
    primaryLight: '#4F86F7',
    primaryDark: '#375EAD',

    secondary: '#151136',
    secondaryLight: '#1A1543',
    secondaryDark: '#120F2F',
    
    deepRose: '#993C58',
    deepRoseLight: '#BF4B6E',
    deepRoseDark: '#86354D',

    gold: '#B28946',
    goldLight: '#DEAB57',
    goldDark: '#9C783D',

    neutralBackground: '#F2F7FF',

    success: '#2BC866',
    successLight: '#41D679',
    successDark: '#27B75D',

    danger: '#E7505A',
    dangerLight: '#EB6F78',
    dangerDark: '#E53E49',
    
    neutral: '#F7F7F7',
    neutralLight: '#FFFFFF',
    neutralDark: '#F2F2F2',
    
    night: '#707070',
    nightLight: '#ACACAC',
    nightDark: '#1A1A1A',

    primaryDashboard: '#A2A0A0',
    secondaryPurple: '#8025e8',
    specialPurple: '#C29CFF',

    warning: '#ffe6b1',

    occasionalBlue: '#003393',
    occasionalPurple: '#9F16DB',
    occasionalPink: '#FF427A',
    occasionalGreen: '#05CC52',
    occasionalGrey: '#EFEFEF',
    occasionalOrange: '#ff9123',

    coralGrey: '#555',

    blueVerticalGradient: 'linear-gradient(to bottom, #1041af, #1041af, #10399f, #10328f, #10328f, #0e2a7f, #0b2370);'
};

export default colors;