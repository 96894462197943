import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import { useEventDetailTab } from 'context/dash/EventDetailTab'

export default function Participants({data}) {
    const {setEventDetailTab} = useEventDetailTab()
    const visibleParticipants = data.slice(0, 9)
  return (
    <Container>
         <Text  marginTop={'25px'}>Participantes</Text>

        <StyledParticipants onClick={() => setEventDetailTab('participant')}>
            {
                visibleParticipants.map((el, index) => (
                    el.images ? (
                        <ContentParticipants key={el.id} zindex={100 - index}>
                            <CardImage  src={el?.image}/>
                        </ContentParticipants>
                    ) : (
                        <ImageParticipants>
                            {el.name?.substring(0, 1)}
                        </ImageParticipants>
                    )
                ))
            }
            {data?.length > 9 && <MoreParticipants><Text paddingBottom={'0'} color={colors.primaryLight}>+{data?.length - 9}</Text></MoreParticipants>}
        </StyledParticipants>                        
    </Container>
  )
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    & > p {
        font-weight: bold;
        color: ${colors.night};
    }

    @media screen and (min-width: 690px) {
        width: 100%;
        padding-left: 15px;
    }
`;

const ContentParticipants = styled.div`
      width: 100%;
      flex-direction: row;

`;


const CardImage = styled.img`
    border: 1px solid ${colors.primaryDashboard};
    object-fit: cover;
    width: 48px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -10px;
    z-index: ${({zindex}) => zindex};
    background: #D9D9D9;
    box-shadow: 1px 4x 4px ${colors.night};

    &:nth-child(1) {
        margin-left: 0;
    }
    
`;

const StyledParticipants = styled(Row)`
    width: 100%;
    cursor: pointer;
`;


const ImageParticipants = styled(FullyCentered)`
    border: 1px solid ${colors.primaryDark};
    width: 48px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    margin-left: -16px;
    justify-content: center;
    align-items: center;
    z-index: ${({zindex}) => zindex};
    background: ${colors.primaryLight};
    box-shadow: 1px 4x 4px ${colors.night};
    color: ${colors.neutral};
    font-weight: bold;

    &:hover {
        transition: all .3s ease-in-out;
        background: ${colors.primary};
    }

    &:nth-child(1) {
        margin-left: 0;
    }

`;

const MoreParticipants = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    margin-top: -4px;
    justify-content: center;
    align-items: center;
    margin-left: -10px;
    z-index: 15;
    background: ${colors.neutral};
    border: 1px solid ${colors.night};

    &:hover {
        & > p {
            color: ${colors.neutral};
        }
        transition: all .3s ease-in-out;
        background: ${colors.primaryLight};
    }

    & > p {
        color: ${colors.night};
    }
`;