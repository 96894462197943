import Skeleton from 'components/_UI/Skeleton'
import React from 'react'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'

export default function EventCardSkeleton() {
    return (
        <Column marginLeft='10px'>
            <Skeleton
                width="180px"
                height="90px"
            />
            <Skeleton
                width="180px"
                height="14px"
                style={{ marginTop: '6px' }}
            />

            <Skeleton
                width="180px"
                height="14px"
                style={{ marginTop: '6px' }}
            />

            <Row>
                <Skeleton
                    width="60px"
                    height="14px"
                    style={{ 
                        marginTop: '6px',
                        marginRight: '10px'
                }}
                />

                <Skeleton
                    width="60px"
                    height="14px"
                    style={{ marginTop: '6px' }}
                />
            </Row>
        </Column>
    )
}
