import React from 'react'
import styled from 'styled-components'
import colors from 'theme/colors';

export default function CardOrganizers({data}) {
  return (
    <StyledImage src={data} />
  )
}

const StyledImage = styled.img`
    border: 1px solid ${colors.primaryDashboard};
    object-fit: cover;
    width: 48px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -7px;
    z-index: ${({zindex}) => zindex};
    background: #D9D9D9;
    box-shadow: 1px 4x 4px ${colors.night};
    & > p {
        color: #354D71;
        font-weight: bold;
    }
    
`;
