import React from 'react'
import { ChevronDown,  Navigation } from 'react-feather'
import styled from 'styled-components'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import Text from './Text'
import breakpoints from 'theme/breakpoints'

export default function SelectLocation({ onClick, textButton = false }) {
  return (
    <ContainerSelect onClick={onClick}>
      <StyledSelectNavigation>
        <Navigation size={'18px'} color={colors.primaryLight} />
        <Text
          type='bold'
          color={colors.primaryLight}
          marginLeft='8px'
          marginRight='4px'
          paddingBottom={'0px'}
        >
          {textButton}
        </Text>
        <ChevronDown size={20} color={colors.primaryLight} />
      </StyledSelectNavigation>
    </ContainerSelect>
  )
}

const ContainerSelect = styled.div` 
  cursor: pointer;
`;

const StyledSelectNavigation = styled(Row)`
  width: auto;
  padding: 5px;
  top: 185px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.primaryLight};
  background: ${colors.neutral};
  border-radius: 30px;
  transition: all ease .25s;

  &:hover {
    transform: scale(1.03);
  }
`;