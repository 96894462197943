import Button from 'components/_UI/Button';
import Fieldset from 'components/_UI/Fieldset'
import Switch from 'components/_UI/Switch';
import Text from 'components/_UI/Text'
import TextArea from 'components/_UI/TextArea';
import RichTextEditor from 'components/commons/RichText';
import { useEvent } from 'context/events/Event';
import fieldValidations from 'helpers/fieldValidations';
import masks from 'helpers/masks';
import validateInputFile from 'helpers/validate-input-file';
import React, { useEffect, useRef, useState } from 'react'
import { Plus, Trash } from 'react-feather';
import styled from 'styled-components';
import { HorizontalCenter } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function BasicInfos({
    register,
    unregister,
    errors,
    reset,
    setValue,
    edit,
    descriptionError,
    onChangeDescriptionWithHtml,
    onChangeDescription, 
    uploadImageDescription,
    pathImageDescription,
    errorTypeImageDescription,
    setErrorTypeImageDescription
}) {
    const { event, setEvent } = useEvent()

    const [multipleDays, setMultipleDays] = useState(false)

    const inputsRef = useRef({})

    const [field, setField] = useState({
        date: '',
        start: '',
        end: ''
    })
    const [fields, setFields] = useState([field])

    useEffect(() => {
        if (edit) {
            setFields(edit?.dates)
            setMultipleDays((edit?.dates.length > 1))
            reset({ ...edit, ...edit?.dates })

            // edit?.dates?.forEach((date, index) => {
            //     setValue(`date${index + 1}`, date.date);
            //     setValue(`start${index + 1}`, date.start);
            //     setValue(`end${index + 1}`, date.end);
            // });
            return
        }

        setMultipleDays(false)
        setFields([field])
    }, [edit, descriptionError, pathImageDescription])

    

    function renderNewEventDate() {
        return fields.map((el, index) => {
            if (index !== 0) {
                return (
                    <React.Fragment key={index}>
                        <DaysCountLabel>
                            <Text color={colors.night} name='small' type='bold'>Dia {index + 1}: </Text>
                        </DaysCountLabel>

                        <ResponsiveRow>
                            <Column>
                                <Fieldset
                                    layout='rounded'
                                    placeholder='Data'
                                    mask={masks.date}
                                    name={`date${index + 1}`}
                                    register={register}
                                    validations={fieldValidations.date()}
                                />

                                {
                                    errors && Object.entries(errors).map(el => {
                                        if (el[0] === `date${index + 1}`) {
                                            return (
                                                <HorizontalCenter key={index}>
                                                    <Text color={colors.danger}> {el[1].message} </Text>
                                                </HorizontalCenter>
                                            )
                                        }
                                    })
                                }
                            </Column>

                            <Column>
                                <Fieldset
                                    layout='rounded'
                                    placeholder='Inicio'
                                    badgeText='Horas'
                                    mask={masks.time}
                                    name={`start${index + 1}`}
                                    register={register}
                                    validations={fieldValidations.time('inicio')}
                                />

                                {
                                    errors && Object.entries(errors).map(el => {
                                        if (el[0] === `start${index + 1}`) {
                                            return (
                                                <HorizontalCenter key={index}>
                                                    <Text color={colors.danger}> {el[1].message} </Text>
                                                </HorizontalCenter>
                                            )
                                        }
                                    })
                                }
                            </Column>

                            <Column>
                                <Fieldset
                                    layout='rounded'
                                    placeholder='Fim'
                                    badgeText='Horas'
                                    mask={masks.time}
                                    name={`end${index + 1}`}
                                    register={register}
                                    validations={fieldValidations.time('Fim')}
                                />

                                {
                                    errors && Object.entries(errors).map(el => {
                                        if (el[0] === `end${index + 1}`) {
                                            return (
                                                <HorizontalCenter key={index}>
                                                    <Text color={colors.danger}> {el[1].message} </Text>
                                                </HorizontalCenter>
                                            )
                                        }
                                    })

                                }
                            </Column>
                        </ResponsiveRow>
                    </React.Fragment>
                )
            }

        })
    }

    function addNewField() {
        const newField = {
            date: '',
            start: '',
            end: ''
        };

        // Atualiza os campos sem sobrescrever os existentes
        const updatedFields = [...fields, newField];
        setFields(updatedFields);

        // Só define o valor do novo campo, sem afetar os outros
        const newIndex = updatedFields.length;
        setValue(`date${newIndex}`, '');
        setValue(`start${newIndex}`, '');
        setValue(`end${newIndex}`, '');
    }

    function removeField() {
        if (fields.length > 1) {
            const newData = fields.slice(0, -1);
            setFields(newData);

            // Remover o último campo do formulário manualmente
            const lastIndex = fields.length;
            const keysToRemove = [`date${lastIndex}`, `start${lastIndex}`, `end${lastIndex}`];

            keysToRemove.forEach(key => {
                setValue(key, undefined);  // Remove o valor do campo específico
                unregister(key);  // Desregistra o campo do formulário
            });

            if (newData.length === 1) {
                setMultipleDays(false);
            }
        }
    }

    function onChangeDaysLenght() {
        if (!multipleDays) {
            setMultipleDays(!multipleDays)
            addNewField()
            return
        }

        setMultipleDays(!multipleDays)
        setFields([field])
        reset({ ...event, dates: [field] })

    }

    return (
        <Column marginTop='12px'>
            <Text size='small' type='bold' color={colors.nightDark}
            >
                Informações básicas
            </Text>

            <Fieldset
                layout='rounded'
                placeholder='Nome do evento'
                name="name"
                register={register}
                validations={fieldValidations.generic('name', 2, 100)}
            />

            {errors && (
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors.name?.message} </Text>
                </HorizontalCenter>
            )}

            {/* <TextArea
                // squired={true}
                placeholder='Descrição'
                name='description'
                validations={fieldValidations.generic('descrição', 2, 5000)}
                register={register}
            /> */}
            {/* {errors && (
                <HorizontalCenter marginTop={'10px'}>
                    <Text color={colors.danger}> {errors.description?.message} </Text>
                </HorizontalCenter>
            )} */}


            {
                multipleDays && (
                    <DaysCountLabel>
                        <Text color={colors.night} name='small' type='bold'> Dia 1: </Text>
                    </DaysCountLabel>
                )
            }
            <ResponsiveRow>
                <Column>
                    <Fieldset
                        layout='rounded'
                        placeholder='Data'
                        mask={masks.date}
                        name="date1"
                        register={register}
                        validations={fieldValidations.date()}
                    />

                    {errors && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.date1?.message} </Text>
                        </HorizontalCenter>
                    )}

                </Column>

                <Column>
                    <Fieldset
                        layout='rounded'
                        placeholder='Inicio'
                        badgeText='Horas'
                        mask={masks.time}
                        name="start1"
                        register={register}
                        validations={fieldValidations.time()}
                    />
                    {errors && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.start1?.message} </Text>
                        </HorizontalCenter>
                    )}
                </Column>

                <Column>
                    <Fieldset
                        layout='rounded'
                        placeholder='Fim'
                        badgeText='Horas'
                        mask={masks.time}
                        name={'end1'}
                        register={register}
                        validations={fieldValidations.time('fim')}
                    />
                    {errors && (
                        <HorizontalCenter>
                            <Text color={colors.danger}> {errors.end1?.message} </Text>
                        </HorizontalCenter>
                    )}
                </Column>
            </ResponsiveRow>

            <Row marginLeft='4px' marginTop='6px'>
                <Column width='auto'>
                    <Switch
                        checked={multipleDays}
                        onChange={onChangeDaysLenght}
                    />
                </Column>
                <Text
                    size='small'
                    color={colors.night}
                    paddingLeft={'8px'}
                >
                    O evento tem mais de um dia de duração
                </Text>
            </Row>

            {
                multipleDays && (
                    <AddFieldContainer>
                        {renderNewEventDate()}
                        <ButtonsRow>
                            <Button
                                variant='secondary'
                                onClick={() => addNewField()}
                            >
                                <Row fullyCentralized>
                                    <Plus size={20} />
                                    <Text
                                        paddingBottom={0}
                                        color={colors.primary}
                                        type='bold'
                                        marginLeft={'6px'}
                                    >
                                        Adicionar campo
                                    </Text>
                                </Row>
                            </Button>

                            <Button
                                variant='danger'
                                onClick={() => removeField()}
                            >
                                <Row fullyCentralized>
                                    <Trash size={20} />
                                    <Text
                                        paddingBottom={0}
                                        color={colors.danger}
                                        type='bold'
                                        marginLeft={'6px'}
                                    >
                                        Remover campo
                                    </Text>
                                </Row>
                            </Button>
                        </ButtonsRow>
                    </AddFieldContainer>
                )
            }

            <Text type='bold' color={colors.nightDark} marginTop={'30px'} marginBottom={'10px'}>
                Descrição do evento
            </Text>
            <RichTextEditor
                edit={edit}
                onChangePureText={e => onChangeDescription(e)}
                onChange={e => onChangeDescriptionWithHtml(e)}
                onToggleImage={e => uploadImageDescription(e)}
                pathImageDescription={pathImageDescription}
                errorTypeImageDescription={errorTypeImageDescription}
                setErrorTypeImageDescription={setErrorTypeImageDescription}
            />
            {errorTypeImageDescription && 
                <HorizontalCenter marginBottom='6px'>
                    <Text color={colors.danger}>{errorTypeImageDescription}</Text>
                </HorizontalCenter>
            }
            {descriptionError && (
                <HorizontalCenter marginBottom='6px'>
                    <Text color={colors.danger}> O campo descrição é obrigatório </Text>
                </HorizontalCenter>
            )}
        </Column>
    )
}


const AddFieldContainer = styled(Column)`
    padding-top: 10px;
`;

const ButtonsRow = styled(Column)`

    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: row;
        margin-top: 15px;
        & > button:first-child {
            margin-right: 6px;

        }
    }
`;

const DaysCountLabel = styled.div`
    text-align: left;
`

const ResponsiveRow = styled(Column)`
    @media screen and (min-width: ${breakpoints.lg}){
        flex-direction: row;
        & > div:nth-child(2) {
            margin: 0 10px;
        }
    }
`;