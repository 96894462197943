import React, { useState } from 'react'
import { useEffect } from 'react';
import styled from 'styled-components';
import Button from './Button';
import Text from './Text';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import { Row } from 'styled/alignment/Row';

export default function ButtonSelectDash({
    children,
    isActive = false,
    isVariant,
    onToggle,
    smallSpacement,
    equalWidth,
    equalWidthStyle = 'rounded'
}) {
    const states = {
        active: 'ACTIVE',
        inative: 'INATIVE',
    }

    const [currentState, setCurrentState] = useState(isActive);
    const [variant, setVariant] = useState(null);
    const [unselectedVariant, setUnselectedVariant] = useState(null);

    useEffect(() => {
        if (isVariant) {
            setVariant(isVariant)
            if (isVariant === 'yellow-borded') {
                setVariant('yellow')
                setUnselectedVariant(isVariant)
            }

            if (isVariant === 'pink-borded') {
                setVariant('pink')
                setUnselectedVariant(isVariant)
            }
            return;
        }

        setVariant('dark')
        setUnselectedVariant('light')
    }, [isActive])

    const toggleState = () => {
        if (currentState === states.active) {
            setCurrentState(states.inative);
            onToggle(states.inative)

            return;
        }

        setCurrentState(states.active);
        onToggle(states.active)
    };

    return (

        equalWidth ? (
            <SquiredItem
                selected={currentState === states.active}
                equalWidthStyle={equalWidthStyle}
                onClick={(e) => toggleState(e)}
            >
                <Text
                    name={children.length > 12 ? 'mini' : 'small'}
                    color={currentState === states.active ? colors.neutral : colors.night}
                    textAlign={'center'}
                    ellipsisAt={'96%'}
                    textTransform='capitalize'
                    type='bold'
                    equalWidthStyle={equalWidthStyle}
                >
                    {children}
                </Text>
            </SquiredItem>
        ) : (
            <Button
                variant={
                    currentState === states.active ? variant : unselectedVariant
                }
                size='small'
                onClick={(e) => toggleState(e)}
                marginRight={smallSpacement ? 0 : sizes.medium}
                marginBottom={smallSpacement ? 14 : sizes.medium}
                textTransform='capitalize'
            >
                {children}
            </Button>
        )


    )
};


const SquiredItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 31%;
    margin-bottom: 3%;
    border: 1px solid ${colors.night};
    padding: 12px 4px;
    border-radius: 30px;
    cursor: pointer;

    ${({equalWidthStyle}) => equalWidthStyle && `
        border-radius: ${equalWidthStyle === 'rounded' ? 30 : 6}px
    `};

    ${({ selected }) => selected && `
        background: #4617ad;
        border: 1px solid #4617ad;
    `};
`;