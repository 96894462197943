import Button from 'components/_UI/Button'
import ImageUpload from 'components/_UI/ImageUpload'
import Text from 'components/_UI/Text'
import React, { useState } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import buttonText from 'texts/buttons/buttonText'
import breakpoints from 'theme/breakpoints'
import colors from 'theme/colors'

export default function ImageProduct({ onToggleImage, onDeleteFile, errorImage, setErrorImage, setErrorTypeImage, allowedFiles, errorTypeImage, resetState , isEdit, product
}) {
    const [tryAgain, setTryAgain] = useState(false)

    useEffect(() => {
       
          
    }, [resetState, errorImage])

    function toggleFile(files) {
        onToggleImage(files[0]);
    }
    
    return (
        <Container>
            <Text size='small' color={colors.occasionalPurple}> Imagem do produto </Text>

            <ImageUpload 
                onToggleFile={toggleFile} 
                onDeleteFile={() => onDeleteFile()}
                tryAgain={tryAgain} 
                isEdit={isEdit} 
                product={product}
                resetState={resetState}
                refreshTryAgain={() => {
                    setTryAgain(!tryAgain)
                    setErrorImage(false)
                }} 
            />

            <ContainerInfo>
                <Text name='small' marginTop={'2px'}>Arquivos permitidos: {allowedFiles}.</Text>
            </ContainerInfo>
       
        {
            errorImage && (
                <Column marginTop='10px' verticalCenter>
                    <Text color={colors.danger} name='small'>Ocorreu um erro ao cadastrar a imagem</Text>
                    
                    <Button 
                        category='outline'
                        onClick={() =>  {
                            setTryAgain(true)
                            setErrorImage(false)
                        }}
                    > 
                        {buttonText.tryAgain}
                    </Button>
                </Column>
            ) 
                
        }
        {errorTypeImage && (
            <Column marginTop='10px' verticalCenter>
                <Text textAlign={'center'} name='small' color={colors.danger}>{errorTypeImage}</Text>
                <Button 
                    category='outline'
                    onClick={() =>  {
                        setTryAgain(true)
                        setErrorImage(false)
                        setErrorTypeImage(false)
                    }}
                > 
                    {buttonText.tryAgain}
                </Button>
            </Column>
        )}

        
        </Container>
    )
}

const Container = styled(Column)`
    align-items: center;

    & > span {
        width: 100%;
        display: flex;
        justify-content: center;
        @media screen and (min-width: ${breakpoints.sm}){
            width: 90%
        }
    }
    & > span > div {
        width: 100%;
        border-radius: 8px;
        height: 100%;
        margin-top: 0px;
    }

    & > span > div > div > p {
        display: none;
    }
`

const ContainerInfo = styled(Column)`
    padding: 10px;
    align-items: center;
`