import Loader from 'components/_UI/Loader'
import Text from 'components/_UI/Text';
import { useCollapse } from 'context/general/Collapse';
import React, { useEffect } from 'react'
import { FullyCentered } from 'styled/alignment/Center'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import establishmentsApi from 'api/requests/establishments';
import sizes from 'theme/sizes';
import { useSearchParams } from 'react-router-dom';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';
import ResumeCard from 'modules/establishment/card/CardResume';
import CardProfile from 'modules/dashboard/profile/parts/CardProfile';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import eventsApi from 'api/requests/events';
import EventDetail from '../detail/EventDetail';
import { useInvisibleButton } from 'context/general/InvisibleButton';

export default function MultipleEstablishmentsList({ data }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { collapse, setCollapse } = useCollapse();
    const { backButtonCollapse, setBackButtonCollapse } = useBackButtonCollapse();
    const {invisibleButton, setInvisibleButton} = useInvisibleButton()

    useEffect(() => {
        
        setBackButtonCollapse({
            ...backButtonCollapse,
            data: data,
            from: 'multiple-list'
        })
    }, [])

    async function renderEstablishmentDetail(establishment) {
        setSearchParams({ estabelecimento: establishment.slug })
        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => (
                <FullyCentered height='100%'>
                    <Loader />
                </FullyCentered>
            )
        })

        try {
            const { data: res } = await establishmentsApi.getBySlug(establishment.slug);
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => establishment && <CardProfile onEventClick={onEventClick} fullWidth withBackButton data={res?.data} />
            })
        } catch (error) {
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => (
                    <FullyCentered height='100%'>
                        <Text name='highlight'> Ocorreu um erro inesperado. </Text>
                    </FullyCentered>
                )
            })
        }
    }

    const onEventClick = async(slug) => {
        setInvisibleButton(false)
        setCollapse({
          ...collapse,
          withContent: true,
          height: 'max',
          arrowPosition: false,
          componentToRender: () => (
              <FullyCentered height='100%'>
                  <Loader />
              </FullyCentered>
          )
        })
        try {
          const {data} = await eventsApi.getBySlug(slug)
          setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => data && <EventDetail data={data.data} />
           })
        } catch (error) {
          setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => (
              <FullyCentered height='100%'>
                <Text>Ocorreu um erro, tente novamente mais tarde.</Text>
              </FullyCentered>
            )
        })
        }
      }
    return (
        <CardsContainer wrap={data.length > 4}>
            <Column
                fullyCentralized
                marginTop='20px'
                marginBottom='20px'
            >
                <Text type='bold'>
                    {data[0].address.name 
                    ? `${data[0].address.name}, ` 
                    : data[0].address.street 
                    ? `${data[0].address.street}, ` 
                    : ''}
                    {data[0].address?.district && `Bairro ${data[0].address.district}`}
                    {data[0].address.number && `, nº ${data[0].address?.number}`}
                    {data[0].address?.complement && ` - ${data[0].address?.complement}`}
                </Text>
            </Column>

            <Content>
                {
                    data.map(el => (
                        <ResumeCard
                            fullWidth={data.length <= 4}
                            key={el.id}
                            smallOnDesktop={true}
                            data={el}
                            onCardClick={(e) => renderEstablishmentDetail(e)}
                        />
                    ))
                }
            </Content>
        </CardsContainer>
    )
}


const CardsContainer = styled(Column)`
    width: 100%;
    height: auto;
    padding: ${sizes.medium};
    ${({ wrap }) => wrap && `
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    `}
`;

const Content = styled(Column)`
    @media screen and (min-width: ${breakpoints.lg}){
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        padding: ${sizes.medium} 0;

        & > div {
            margin-left: 1%;
            margin-right: 1%;
            width: 31%;
            
        }
    }
`;
