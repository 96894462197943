import React, { createContext, useState, useContext } from 'react';

const UserLocationContext = createContext();

export default function UserLocationProvider({ children }) {
    const [userLocation, setUserLocation] = useState(null);

    return (
        <UserLocationContext.Provider value={{ userLocation, setUserLocation }}>
            {children}
        </UserLocationContext.Provider>
    );
}

export function useUserLocation() {
    const context = useContext(UserLocationContext);

    if (!context) throw new Error('useUserLocation must be used within a UserLocationProvider');

    const { userLocation, setUserLocation } = context;
    return { userLocation, setUserLocation };
}