import Button from 'components/_UI/Button';
import Ilustration from 'components/_UI/Ilustration';
import Text from 'components/_UI/Text';
import React, { useEffect, useState } from 'react'
import { Edit, Edit2, PlusSquare, Trash2 } from 'react-feather';
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import PermissionsModal from './PermissionsModal';
import collaboratorsApi from 'api/requests/collaborators';
import { useEvent } from 'context/events/Event';
import { FullyCentered } from 'styled/alignment/Center';
import Loader from 'components/_UI/Loader';
import useToast from 'hooks/useToast';
import ToastContainer from 'components/_UI/ToastsContainer';
import Table from 'components/_UI/Table';
import Chips from 'components/_UI/Chips';
import DeletePermissionModal from './DeletePermissionModal';
import InfoTooltip from 'components/_UI/InfoTooltip';

export default function CollaboratorsPermissions() {
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [content, setContent] = useState(false);
    const [selected, setSelected] = useState(false);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const { event } = useEvent();
    const { toast, toasts } = useToast();

    useEffect(() => {
        getCollaboratorsPermissions();
    }, []);

    async function getCollaboratorsPermissions() {
        setLoading(true);

        try {
            const { data } = await collaboratorsApi.getList(event?.slug);
            setContent(data?.data);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    function onSuccess() {
        setModalVisible(false);
        setSelected(false);
        getCollaboratorsPermissions();
        toast.success('Convite enviado com sucesso!', { position: 'top-center' })
    }

    function renderPermissionList(data) {
        if (data === 'ticket') {
            return 'Validar ingressos'
        }

        if (data === 'product') {
            return 'Validar produtos'
        }

        if (data === 'both') {
            return 'Validar ingressos, Validar produtos'
        }
    }

    function onEditClick(item) {
        setEdit(item);
        setSelected(item);
        setModalVisible(true);
    }

    function onDeleteClick(item) {
        setSelected(item);
        setDeleteModalVisible(true);
    }

    const renderCollaborators = content.length && content?.map(item => {
        return {
            email: <Text name='small' paddingBottom={0} ellipsisAt={'98%'}>{item?.email}</Text>,
            name: <Text name='small' paddingBottom={0}>{renderPermissionList(item?.permission_type)}</Text>,
            // type: <StyledStatus status={item?.status}> {item.type === 'ticket' ? 'Ingresso' : 'Produto'} </StyledStatus>,
            product: <Chips text={item?.status} size={'small'} />,
            actions: <>
                <InfoTooltip
                    text='Editar'
                    type='none'
                >
                    <ActionButton onClick={() => onEditClick(item)} disabled={item?.status === 'rejected'}>
                        <Edit
                            color={item?.status !== 'rejected' ? colors.primary : colors.nightLight}
                            size={18}
                            style={{ marginRight: 10 }}
                        />
                    </ActionButton>
                </InfoTooltip>
                <InfoTooltip
                    text='Excluir'
                    type='none'
                >
                    <ActionButton onClick={() => onDeleteClick(item)}>
                        <Trash2
                            color={colors.danger}
                            size={18}
                        />
                    </ActionButton>
                </InfoTooltip>
            </>,
            // status: <StyledStatus status={item.used}>{item.used ? 'Utilizado' : 'Não Utilizado'}</StyledStatus>

        };
    })

    function onClose() {
        setModalVisible(false);
        setEdit(false);
    }

    function onDeleteModal() {
        setSelected(false);
        setDeleteModalVisible(false);
        getCollaboratorsPermissions();
        toast.success('Permissão excluida com sucesso!', { position: 'top-center' })
    }


    return (
        <Container>
            <ToastContainer toasts={toasts} />

            <HeaderContent>
                <Text name='subtitle' paddingBottom={0}>
                    Permissões de colaboradores
                </Text>

                <Button
                    variant='featured'
                    category='squired'
                    onClick={() => setModalVisible(true)}
                >
                    <PlusSquare color={colors.neutralLight} size={16} />
                    <Text
                        color={colors.neutralLight}
                        paddingBottom={0}
                        marginLeft={'6px'}
                    >
                        Convidar colaborador
                    </Text>
                </Button>
            </HeaderContent>

            {
                modalVisible && (
                    <PermissionsModal
                        visible={modalVisible}
                        edit={edit}
                        onClose={onClose}
                        onSuccess={onSuccess}
                    />
                )
            }

            {
                deleteModalVisible && (
                    <DeletePermissionModal
                        visible={deleteModalVisible}
                        data={selected}
                        onClose={() => setDeleteModalVisible(false)}
                        onSuccess={onDeleteModal}
                    />
                )
            }


            {
                loading ? (
                    <ContentLoader>
                        <Loader />
                    </ContentLoader>
                ) : (
                    content?.length
                        ? (
                            <>
                                <Text type='bold' marginTop={'50px'} marginBottom={'10px'}>
                                    Colaboradores convidados
                                </Text>
                                <Table
                                    rows={renderCollaborators}
                                    columns={['Colaborador', 'Permissão', 'Status do convite', 'Ações',]}
                                />
                            </>
                        ) : (
                            <>
                                <Picture>
                                    <Ilustration name='collaboration' />
                                </Picture>

                                <Text type='bold' color={colors.night} textAlign={'center'} name='normal'>
                                    Você ainda não convidou nenhum colaborador para este evento.
                                </Text>

                                <Text type='bold' color={colors.night} textAlign={'center'} name='normal'>
                                    Quel tal convidar?
                                </Text>

                                <FullyCentered marginTop='10px'>
                                    <Column width='250px'>
                                        <Button
                                            variant='featured'
                                            category='squired'
                                            onClick={() => setModalVisible(true)}
                                        >
                                            <PlusSquare color={colors.neutralLight} size={16} />
                                            <Text
                                                color={colors.neutralLight}
                                                paddingBottom={0}
                                                marginLeft={'6px'}
                                            >
                                                Convidar colaborador
                                            </Text>
                                        </Button>
                                    </Column>
                                </FullyCentered>
                            </>
                        )
                )
            }

        </Container>
    )
}


const Container = styled(Column)`
    width: 100%;
`;

const HeaderContent = styled(Column)`
    width: 100%;

    & > button {
        margin-top: 20px;
    }

    @media screen and (min-width: ${breakpoints.md}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & > h2,
        & > button {
            width: auto;
            margin-top: 0;
        }

        
    }
`;

const Picture = styled.picture`
    width: auto;
    height: 300px;
`;

const ActionButton = styled.div`
    width: auto;
    height: auto;
    transition: all ease-in-out .25s;
    cursor: pointer;

    ${({ disabled }) => disabled && `
        opacity: .4;
        pointer-events: none;
    `};
`;

const ContentLoader = styled(Column)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;