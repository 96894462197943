const establishmentServicesOptions = [
    { value: 'Retirada no local', key: '1' },
    { value: 'Consumo no local', key: '3' },
    { value: 'Entrega', key: '2' },
    { value: 'Para viagem', key: '4' },
    { value: 'Rodizio', key: '6' },
    { "value": "Drive-Thru", "key": "7" },
    { "value": "Buffet livre", "key": "8" },
]

export const transformedServicesOptions = (data) => data.map(option => ({
    label: option.name,
    value: option.id
}));

export default establishmentServicesOptions;

