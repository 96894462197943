const buttonText = {
    enter: 'Entrar',
    next: 'Avançar',
    back: 'Voltar',
    ok: 'Ok',
    understood: 'Entendi',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    delete: 'Excluir',
    create: 'Criar',
    register: 'Cadastrar',
    newAccount: 'Criar nova conta',
    recoverAccount: 'Recuperar conta',
    tryAgain: 'Tentar novamente',
    close: 'fechar',
    seeWallet: 'Ver carteira',
    resend: 'Reenviar',
    addCoupon: 'Adicionar código promocional',
    apply: 'Aplicar',
    update: 'Atualizar',
};

export default buttonText;