import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import Button from 'components/_UI/Button';
import Fieldset from 'components/_UI/Fieldset';
import Switch from 'components/_UI/Switch';
import Text from 'components/_UI/Text';
import breakpoints from 'theme/breakpoints';
import { useEstablishment } from 'context/establishments/Establishment';
import masks from 'helpers/masks';

export default function Hours({ isEdit }) {
    const { establishment, setEstablishment } = useEstablishment();

    const initialState = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
    };

    const [days, setDays] = useState(initialState);
    const [allDayFlags, setAllDayFlags] = useState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
    });

    useEffect(() => {
        if (isEdit) {
            const editedDays = {};
            const editedFlags = {};
            for (const day in isEdit) {
                if (isEdit[day] === null) {
                    editedDays[day] = [];
                    editedFlags[day] = false;
                } else if (isEdit[day].some(item => item["24h"])) {
                    editedDays[day] = [{ start: '00:00', end: '00:00', allDay: true }];
                    editedFlags[day] = true;
                } else {
                    editedDays[day] = isEdit[day].map(item => ({
                        start: item.start || '',
                        end: item.end || '',
                        allDay: item.allDay || false,
                    }));
                    editedFlags[day] = false;
                }
            }
            setDays(editedDays);
            setAllDayFlags(editedFlags);
        }
    }, [isEdit]);

    useEffect(() => {
        setEstablishment({
            ...establishment,
            days
        });
    }, [days]);

    const handleSwitchChange = (day) => {
        setDays((prevDays) => ({
            ...prevDays,
            [day]: prevDays[day].length > 0 ? [] : [{ start: '', end: '', allDay: false }]
        }));
    };

    const handleAddField = (day) => {
        setDays((prevDays) => ({
            ...prevDays,
            [day]: [...prevDays[day], { start: '', end: '', allDay: false }]
        }));
    };

    const handleRemoveField = (day) => {
        setDays((prevDays) => ({
            ...prevDays,
            [day]: prevDays[day].slice(0, -1) // Remove the last item
        }));
    };

    const handleAllDayCheckboxChange = (day) => {
        const newValue = !allDayFlags[day];
        setAllDayFlags((prevFlags) => ({
            ...prevFlags,
            [day]: newValue
        }));

        setDays((prevDays) => ({
            ...prevDays,
            [day]: newValue
                ? [{ start: '00:00', end: '00:00', allDay: true }]
                : prevDays[day].map(item => ({ ...item, allDay: false, start: '', end: '' }))
        }));
    };

    const handleInputChange = (day, index, field, value) => {
        setDays((prevDays) => ({
            ...prevDays,
            [day]: prevDays[day].map((item, i) =>
                i === index ? { ...item, [field]: value } : item
            )
        }));
    };

    const renderTimeFields = (day) => (
        <>
            {days[day].length > 0 && (
                <CheckboxContainer>
                    <CheckboxInput
                        type="checkbox"
                        checked={allDayFlags[day]}
                        onChange={() => handleAllDayCheckboxChange(day)}
                    />
                    <CheckboxLabel>24 horas</CheckboxLabel>
                </CheckboxContainer>
            )}
            <ContentFieldsHours>
                {days[day].map((item, index) => (
                    <TimeFieldsContainer key={`${day}-${index}`}>
                        <StyledInput>
                            <Fieldset
                                placeholder='Abertura'
                                marginRight={'10px'}
                                disabled={item.allDay}
                                layout='rounded'
                                mask={masks.time}
                                value={item.start}
                                onChangeValue={(e) => handleInputChange(day, index, 'start', e)}
                            />
                        </StyledInput>
                        <StyledInput>
                            <Fieldset
                                placeholder='Fechamento'
                                disabled={item.allDay}
                                layout='rounded'
                                mask={masks.time}
                                value={item.end}
                                onChangeValue={(e) => handleInputChange(day, index, 'end', e)}
                            />
                        </StyledInput>
                    </TimeFieldsContainer>
                ))}
            </ContentFieldsHours>
            <StyledRow>
                <StyledButtonAdd>
                    <Button
                        onClick={() => handleAddField(day)}
                        style={{ marginTop: '10px' }}
                        variant={allDayFlags[day] ? 'disabled' : 'primary'}
                    >
                        Adicionar campo
                    </Button>
                </StyledButtonAdd>
                {days[day].length > 1 && (
                    <Button
                        onClick={() => handleRemoveField(day)}
                        style={{ marginTop: '10px' }}
                        variant={allDayFlags[day] ? 'disabled' : 'danger'}
                    >
                        Remover último campo
                    </Button>
                )}
            </StyledRow>
        </>
    );

    return (
        <Column marginTop='40px' verticalCenter>
            <Text size='small' type='bold' color={colors.occasionalPurple}>Horários de Funcionamento</Text>
            <Text size='small' color={colors.night} textAlign={'center'}>
                Selecione os dias de funcionamento do seu estabelecimento e preencha os horários.
            </Text>

            <SwitchsContainer>
                <Column marginTop={'20px'}>
                    <DaySwitch
                        label='Domingo'
                        checked={days.sunday.length > 0}
                        onChange={() => handleSwitchChange('sunday')}
                    />
                    {days.sunday.length > 0 && renderTimeFields('sunday')}
                </Column>

                <Column marginTop={'20px'}>
                    <DaySwitch
                        label='Segunda-feira'
                        checked={days.monday.length > 0}
                        onChange={() => handleSwitchChange('monday')}
                    />
                    {days.monday.length > 0 && renderTimeFields('monday')}
                </Column>

                <Column marginTop={'20px'}>
                    <DaySwitch
                        label='Terça-feira'
                        checked={days.tuesday.length > 0}
                        onChange={() => handleSwitchChange('tuesday')}
                    />
                    {days.tuesday.length > 0 && renderTimeFields('tuesday')}
                </Column>

                <Column marginTop={'20px'}>
                    <DaySwitch
                        label='Quarta-feira'
                        checked={days.wednesday.length > 0}
                        onChange={() => handleSwitchChange('wednesday')}
                    />
                    {days.wednesday.length > 0 && renderTimeFields('wednesday')}
                </Column>

                <Column marginTop={'20px'}>
                    <DaySwitch
                        label='Quinta-feira'
                        checked={days.thursday.length > 0}
                        onChange={() => handleSwitchChange('thursday')}
                    />
                    {days.thursday.length > 0 && renderTimeFields('thursday')}
                </Column>

                <Column marginTop={'20px'}>
                    <DaySwitch
                        label='Sexta-feira'
                        checked={days.friday.length > 0}
                        onChange={() => handleSwitchChange('friday')}
                    />
                    {days.friday.length > 0 && renderTimeFields('friday')}
                </Column>

                <Column marginTop={'20px'}>
                    <DaySwitch
                        label='Sábado'
                        checked={days.saturday.length > 0}
                        onChange={() => handleSwitchChange('saturday')}
                    />
                    {days.saturday.length > 0 && renderTimeFields('saturday')}
                </Column>
            </SwitchsContainer>
        </Column>
    );
}

// Componente para o switch de cada dia
function DaySwitch({ label, checked, onChange }) {
    return (
        <>
            <Switch label={label} checked={checked} onChange={onChange} />
        </>
    );
}

// Styles for the switches container
const SwitchsContainer = styled(Row)`
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
`;

const StyledRow = styled(Column)`
    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: column;
        align-items: center;
        button:nth-child(2) {
            width: 65%;
        }
    }
`;


// Styles for the time fields container
const TimeFieldsContainer = styled(Row)`
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: ${breakpoints.sm}) {
        width: 65%;
    }
`;

// Styles for the checkbox container
const CheckboxContainer = styled.div`
    margin: 10px 0 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const CheckboxInput = styled.input`
    margin-right: 8px;
`;

const CheckboxLabel = styled.label`
    cursor: pointer;
    color: ${colors.primaryDashboard};
    font-size: 14px;
`;

const StyledInput = styled.div`
    width: 49%;
`;

const StyledButtonAdd = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    & > button {
        width: 100%;
    }
    @media screen and (min-width: ${breakpoints.sm}) {
        & > button {
            width: 65%;
        }
    }
`

const ContentFieldsHours = styled(Column)`
    justify-content: center;
    width: 100%;
    align-items: center;
`

