import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import React from 'react'
import { AlignLeft, Calendar, CheckSquare, Clock, Hash, Upload } from 'react-feather';
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import customFieldsTypes from 'theme/customFieldsTypes';
import fonts from 'theme/fonts';

export default function CustomFieldCard({ data }) {
    function renderType() {
        const selectedType = customFieldsTypes.find(el => data?.type === el?.value);

        return (
            <Row width='auto' marginLeft='4px'>
                {renderTypeIcon(selectedType.value)}
                <Text paddingBottom={0} name='small' marginLeft='4px'>
                    {selectedType.label}
                </Text>
            </Row>
        )
    }

    function renderTypeIcon(icon) {

        if (icon === 'text') {
            return (
                <Column width='16px' height='16px'>
                    <Icon name='short-text' color={colors.night} />
                </Column>
            )
        }

        if (icon === 'textarea') {
            return <AlignLeft color={colors.night} size={14} />
        }

        if (icon === 'numeric') {
            return <Hash color={colors.night} size={14} />
        }

        if (icon === 'date') {
            return <Calendar color={colors.night} size={14} />
        }

        if (icon === 'time') {
            return <Clock color={colors.night} size={14} />
        }
        
        if (icon === 'checkbox') {
            return <CheckSquare color={colors.night} size={14} />
            // <Column width='14px' height='14px'>
            //     <Icon name='checkbox' color={colors.night} />
            // </Column>
        }

        if (icon === 'upload') {
            return <Upload color={colors.night} size={14} />
        }

        if (icon === 'radio') {
            return (
                <Column width='14px' height='14px'>
                    <Icon name='radio-checked' color={colors.night} />
                </Column>
            )
        }

        return null
    }

    return (
        <CardContainer>
            <Text type='bold' color={colors.occasionalPurple}>{data?.label}</Text>

            <Text name='small'>
                <b>Campo obrigatorio:</b> {data?.required ? 'Sim' : 'Não'}
            </Text>

            <Row marginTop='8x'>
                <Text name='small' type='bold'>Tipo de campo:</Text>
                {renderType()}
            </Row>


            {
                !!data?.options?.length && (
                    <Column marginTop='15x'>
                        <Text type='bold' name='small'>Opções de escolha:</Text>
                        <OptionsContainer>
                            {data?.options?.map(el => (
                                <Option>{el}</Option>
                            ))}
                        </OptionsContainer>
                    </Column>

                )
            }

        </CardContainer>
    )
}

const CardContainer = styled(Column)`
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 7px #ddd;
    border: 1px solid #ddd;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 24%;
        margin-right: 1%;
    }
`;

const Option = styled.p`
    padding: 4px 6px;
    background: ${colors.night};
    color: ${colors.neutral};
    border-radius: 4px;
    width: auto;
    font-size: ${fonts.sizes.mini};
    margin-left: 4px;
    margin-top: 6px;
`;

const OptionsContainer = styled(Row)`
    flex-wrap: wrap;
    margin-top: -6px;
`;
