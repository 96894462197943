import React, { useEffect, useState } from 'react'
import Modal from "components/_UI/Modal";
import styled from "styled-components";
import colors from "theme/colors";
import { FullyCentered } from "styled/alignment/Center";
import Button from "components/_UI/Button";
import Text from 'components/_UI/Text';
import sizes from 'theme/sizes';
import { Column } from 'styled/alignment/Column';
import Loader from 'components/_UI/Loader';
import userApi from 'api/requests/user';
import { useUser } from 'context/user/User';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';

export function DeleteAccountDialog ({ open, setOpen, home }) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const {user} = useUser()

    const navigate = useNavigate();
    
    const accountDelete = async() => {
        setLoading(true)
        const id = user?.id
        try {
            await userApi.delete(id)
            localStorage.removeItem("token");
            navigate(paths.home)
        } catch (error) {
            setError('Ocorreu um erro inesperado')
        } finally {
            setOpen(false)
            setLoading(false)
        }
    }

    return (
        <Container home={home}>
                
            <Modal isOpen={open} onClose={() => setOpen(false)}>
                <ModalWrapper>
                    <Text name='title'>Tem certeza que deseja excluir a conta?</Text>
                    <Text marginBottom={sizes.small}>Essa ação é irreversível e é importante ressaltar que, ao prosseguir, você irá:</Text>
                    <Text>Perder acesso ao seu histórico de conta;</Text>
                    <Text>Perder acesso às suas credenciais de login;</Text>
                    <Text marginBottom={sizes.normal}>Ficar sem acesso à qualquer recurso de interação dentro da plataforma;</Text>

                    {error && (
                        <FullyCentered marginTop={'10px'}>
                            <Text color={colors.danger}>{error}</Text>
                        </FullyCentered>
                    )}

                    {
                        !loading ? (
                            <Column>
                                <Button
                                    onClick={() => accountDelete()}
                                > 
                                    Excluir conta
                                </Button>
                                <Button
                                    variant='secondary'
                                    onClick={() => setOpen(false)}
                                >
                                    Cancelar
                                </Button>
                            </Column>
                        ) : (
                            <Loader />
                        )
                    }
                </ModalWrapper>
            </Modal>
        </Container>
    )
}

const Container = styled(FullyCentered)`
  width: 100%;
  height: 100vh;
  background: ${colors.neutralDark};
  ${({home}) => home && `
     background: ${colors.neutralBackground}
  `}
`;

const ModalWrapper = styled.div`
    padding: 10px;
`;
