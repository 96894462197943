import React, { createContext, useState, useContext } from 'react';

const EstablishmentsNearListAllContext = createContext();

export default function EstablishmentsNearListallProvider({ children }) {
    const [establishmentsNearListAll, setEstablishmentsNearListAll] = useState(null);

    return (
        <EstablishmentsNearListAllContext.Provider value={{ establishmentsNearListAll, setEstablishmentsNearListAll }}>
            {children}
        </EstablishmentsNearListAllContext.Provider>
    );
}

export function useEstablishmentsNearListAll() {
    const context = useContext(EstablishmentsNearListAllContext);

    if (!context) throw new Error('useEstablishmentsNearListAll must be used within a EstablishmentsNearListallProvider');

    const { establishmentsNearListAll, setEstablishmentsNearListAll } = context;
    return { establishmentsNearListAll, setEstablishmentsNearListAll };
}