import Button from 'components/_UI/Button';
import Text from 'components/_UI/Text';
import { useUser } from 'context/user/User';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import userApi from 'api/requests/user';
import Loader from 'components/_UI/Loader';
import DynamicProfileBackground from 'components/commons/DynamicProfileBackground';
import DynamicProfileImage from 'components/commons/DynamicProfileImage';

export default function Profile({handleEditUser = () => false}) {
    const { user, setUser } = useUser()
    const { isAuth } = useAuth()


    const [loadUser, setLoadUser] = useState(false)

    useEffect(() => {
        if (!user && !user?.visitant && isAuth) {
            getMeData()
        }
    }, [])

    async function getMeData() {
        setLoadUser(true)
        try {
            const { data } = await userApi.me()
            setUser(data)
        } catch (error) {
            setUser(false)
        } finally {
            setLoadUser(false)
        }
    };
    return (
        <ProfileContainer>
            <UserInfoContainer>
                {
                    !user
                        ? <Loader />
                        : (
                            <ContainerInfos>
                                
                                <ContentThumb>

                                    <DynamicProfileBackground
                                        img={user?.background_photo}
                                        size={'160px'}
                                    />
                                    <UserImageContainer> 
                                        <DynamicProfileImage
                                            img={user?.avatar}
                                            word={user?.first_name}
                                            size={'95px'}
                                        />
                                    </UserImageContainer> 
                                </ContentThumb>

                                <UserInfo>
                                    <Text name='title' color={colors.nightDark}>{`${user?.first_name} ${user?.last_name}`}</Text>
                                    <Text
                                        name='default'
                                        color={colors.nightLight}
                                        marginTop={'-10px'}>
                                        {`@${user?.username}`}
                                    </Text>
                                    <Text
                                        color={colors.primaryDashboard}
                                        marginTop={'5px'}
                                    >
                                       {user?.bio}
                                    </Text>
                                </UserInfo>

                                <ButtonContainer>
                                    <Button
                                        category='squiredBorded'
                                        variant='light'
                                        size='small'
                                        onClick={() => handleEditUser(user)}
                                    >
                                        Editar perfil
                                    </Button>
                                </ButtonContainer>
                            </ContainerInfos>
                        )
                }
            </UserInfoContainer>
        </ProfileContainer>
    )
}

const ProfileContainer = styled(Column)`
    background: ${colors.neutralDark};
    align-items: flex-start;
`;

const UserInfoContainer = styled(Column)`    
    background: ${colors.neutralLight};
    justify-content: flex-start;
    height: 420px;
    border-radius: ${sizes.none} ${sizes.none} ${sizes.small} ${sizes.small} ;
`;

const UserImageContainer = styled.div`
    width: 90px;
    height: 90px;
    position: absolute;
    bottom: -35px;
    padding-left: 26px;
`;

const ContainerInfos = styled(Column)`
    position: relative;
    align-items: flex-start;
`

const UserImage = styled.img`
    width: 95px;
    height: 95px;
    object-fit: cover;
    border-radius: 50%;
    position: absolute;

`;

const UserInfo = styled(Column)`
    margin-top: 50px;
    padding: 0 15px 0 30px;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
`;

const ButtonContainer = styled.div`
    padding: 0 20px 20px 30px;

    width: 140px;
    margin-top: 10px;
    height: 14px;
    
    & > button {
        text-transform: capitalize;
        padding: 8px 2px !important;
    }
`;

const ContentThumb = styled.div`  
  display:flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;