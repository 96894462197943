import Text from 'components/_UI/Text';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import { useEventDetailTab } from 'context/dash/EventDetailTab';
import currency from 'helpers/currency';
import InfoTooltip from 'components/_UI/InfoTooltip';

export default function CardTickets({ data, onTicketClick = () => false }) {
    const [status, setStatus] = useState(false);
    
    useEffect(() => {
        let disponivel = false;
        let esgotado = false;
        let desativado = false;
    
        data?.batches?.forEach((el) => {
            if (el?.quantity_available > 0 && el?.is_active == 1) {
                disponivel = true;
            }
            
            if (el?.quantity_available === 0 && el?.is_active == 1) {

                esgotado = true;
            }
            
            if (el?.is_active === 0 && el?.quantity_available > 0) {
                
                desativado = true;
            }
        });
    
        if (disponivel) {
            setStatus('disponível');
            return; 
        }
    
        if (esgotado && !disponivel) {
            setStatus('esgotado');
            return;
        }
    
        if (desativado && !disponivel && !esgotado) {            
            setStatus('desativado');
        }
    }, [data]);
    



    const renderLots = () => {
        return data?.batches?.slice(0, 5).map((el, index) => {
            
            let loteStatus = '';

            if (el.quantity_available > 0 && el.is_active === 1) {
                loteStatus = 'disponível';
            } 
            if (el.quantity_available === 0) {
                loteStatus = 'esgotado';
            } 
            if (el.is_active === 0) {
                loteStatus = 'desativado';
            }

            return (
                <InfoTooltip
                    bgColor={colors.night}
                    text=<>Lote {index + 1}
                    <br></br>
                    Valor: {currency.format(el.price)}
                    <br></br>
                    Quantidade: {el.quantity_available.toLocaleString('pt-BR')}
                    <br></br>
                    Status: {loteStatus}
                    </>
                    type='none'
                >
                    <Lots
                        key={el.id}
                        zindex={index}
                        status={loteStatus}
                    >

                        <Text paddingBottom={'0'}>{index + 1}</Text>
                    </Lots>
                </InfoTooltip>
                    
            );
        });
    };

    return (
        <Container status={status} onClick={(e) => onTicketClick(e)}>
            <StyledMain>
                <ContentLeft>
                    <TextStatus>{status}</TextStatus>
                    <ContentText>
                        <Text color={colors.neutral}  ellipsisAt={'95'} paddingBottom={'2px'} name="highlight">
                            {data?.name}
                        </Text>
                    </ContentText>
                </ContentLeft>
                <ContentRight>
                    <Text color={colors.neutral}>Lotes</Text>
                    <ContentLots>
                        {renderLots()}
                        {data?.batches?.length > 6 && (
                            <InfoTooltip 
                                bgColor={colors.night}
                                text={`+${data?.batches?.length - 5} lotes`}
                                type='none'
                            >
                                <MoreLots>
                                    <Text paddingBottom={'0'} color={colors.neutralLight}>
                                        +{data?.batches?.length - 5}
                                    </Text>
                                </MoreLots>
                            </InfoTooltip>
                        )}
                    </ContentLots>
                </ContentRight>
            </StyledMain>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    height: 100px;
    width: 100%;
    align-items: center;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    transition: all ease-in-out 0.25s;
    &:hover {
        transform: scale(1.03);
    }

    @media screen and (min-width: 450px) {
        width: 320px;
    }

    @media screen and (min-width: 690px) {
        width: 100%;
    }
    @media screen and (min-width: ${breakpoints.xl}) {
        width: 320px;
    }

    &:after {
        content: '';
        background: white;
        position: absolute;
        left: -16px;
        top: 38px;
        width: 28px;
        height: 28px;
        border-radius: 50px;
    }

    &:before {
        content: '';
        background: white;
        position: absolute;
        right: -16px;
        top: 38px;
        width: 28px;
        height: 28px;
        border-radius: 50px;
    }

    ${({ status }) =>
        status === 'disponível' &&
        `
        background: ${colors.secondaryPurple};
        box-shadow: 1px 2px 5px ${colors.secondaryPurple};
    `}
    ${({ status }) =>
        status === 'esgotado' &&
        `
        background: ${colors.secondaryPurple};
        box-shadow: 1px 2px 5px ${colors.secondaryPurple};
        opacity: 0.4;
    `}
    ${({ status }) =>
        status === 'desativado' &&
        `
        background: ${colors.nightLight};
        box-shadow: 1px 2px 5px ${colors.nightLight};
    `}
`;

const StyledMain = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ContentLeft = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px 0 0 30px;
    width: 65%;
    justify-content: space-around;
    border-radius: 4px;
    & > h3 {
        text-transform: capitalize;
        padding-bottom: 0;
    }
`;

const ContentText = styled.div`
    height: 45px;
`

const ContentRight = styled.div`
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 100px;
    padding: 5px 15px 0 20px;
    justify-content: center;
    border-left: 2px dashed ${colors.neutral};
`;

const ContentLots = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const Lots = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    margin-top: -4px;
    justify-content: center;
    align-items: center;
    margin-left: -7px;
    z-index: ${({ zindex }) => zindex};
    transition: all ease-in-out .25s;

    &:hover {
        transform: scale(1.1);
    }

    ${({ status }) =>
        status === 'disponível' &&
        `
        background: #7EFF7B;
        border: 1px solid #038D00;
        & > p {
            color: #038D00 !important;
        }
    `}
    
    ${({ status }) =>
        status === 'esgotado' &&
        `
         background: #BCBCBC;
        border: 1px solid #00000033;
        & > p {
            color: #00000033;
        }
    `}
    ${({ status }) =>
        status === 'desativado' &&
        `
        background: #BCBCBC;
        border: 1px solid #00000033;
        & > p {
            color: #00000033;
        }
    `}
`;

const MoreLots = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    margin-top: -4px;
    justify-content: center;
    align-items: center;
    margin-left: -7px;
    z-index: 15;
    background: ${colors.primaryLight};
    border: 1px solid ${colors.night};
    transition: all ease-in-out .25s;

    &:hover {
        transform: scale(1.1);
    }
    & > p {
        color: ${colors.neutralLight};
    }
`;

const TextStatus = styled.p`
    font-size: 10px;
    text-transform: capitalize;
    color: ${colors.neutral};
`;

const TextPrice = styled.p`
    padding-bottom: 0px;
    margin-top: 5px;
    font-weight: bold;
    color: ${colors.neutral};
    ${({ status }) =>
        status === 'esgotado' &&
        `
        text-decoration: line-through;
        color: ${colors.neutral};
    `}
    ${({ status }) =>
        status === 'desativado' &&
        `
        text-decoration: line-through;
        color: ${colors.neutral};
    `}
    ${({ status }) =>
        status === 'disponível' &&
        `
        text-decoration: normal;
        ${colors.neutral};
    `}
`;
