import Divider from 'components/_UI/Divider';
import Text from 'components/_UI/Text';
import React ,{useEffect} from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors';
import CardTickets from './card/CardTickets';
import breakpoints from 'theme/breakpoints';
import { Row } from 'styled/alignment/Row';
import Organizers from '../organizers/Organizers';
import { StyledDivider } from 'styled/UI/StyledDivider';
import Participants from '../participants/Participants';
import { useEventDetailTab } from 'context/dash/EventDetailTab';





export default function TicketsForSale({data, detailEvent}) {
    let setEventDetailTab;
    if (!detailEvent) {
        ({ setEventDetailTab } = useEventDetailTab());
    }
    
  return (
    <ContainerTickets>
        <Divider marginTop={'40px'} color={colors.primaryDashboard} opacity={0.3} />
        
        <StyledContent>
            <StyledText>
                <Text name='subtitle' color={colors.night}>Ingressos a venda</Text>
            </StyledText>

            <MainContent>
                <ContentTickets>
                    {
                        data?.filter((el) => el.type === 'ticket').map((el) => (
                            <Column marginTop='15px' key={el.id}>
                                <CardTickets data={el} onTicketClick={() => setEventDetailTab('tickets')} />
                            </Column>
                        ))
                    
                    }
                </ContentTickets>
                
                
                {!detailEvent &&
                    <>
                        <DividerStyled marginBottom={'20px'} color={colors.primaryDashboard} opacity={0.3}/>
                        <StyledPeoples>
                            <Organizers />
                        </StyledPeoples>
                    </>
                }

                    {/* <Participants data={participants} /> */}

            </MainContent>
        </StyledContent>

    </ContainerTickets>
  )
}

const ContainerTickets = styled(Column)`
    width: 100%;
`;

const StyledContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    
    @media screen and (min-width: 690px) {
        display: flex;
        flex-direction: column;
    }
`;

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 690px) {
        flex-direction: row;
    }

`;

const ContentTickets = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
    @media screen and (min-width: 690px) {
        width: 50%;
        padding: 10px 20px 0px 10px;
        border-right: 1px solid #cccc;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
`;


const StyledText = styled(Row)`
    padding: 0;
    height: 20px;
    @media screen and (min-width: 690px){
        padding: 0 10px 0 10px;
    }
`;

const StyledPeoples = styled.div`
    width: 50%;

    @media screen and (min-width: 690px) {
        border-left: 1px solid #cccc;
    }
`

const DividerStyled = styled(StyledDivider)`
    display: flex;
    @media screen and (min-width: 690px) {
        display: none;
    }
`;
