import React from 'react'
import { StyledDivider } from 'styled/UI/StyledDivider';
import colors from 'theme/colors';
import sizes from 'theme/sizes';

function Divider({
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    color,
    opacity,
    style
}) {
  return (
    <StyledDivider  
        marginTop={marginTop}
        marginRight={marginRight}
        marginBottom={marginBottom}
        marginLeft={marginLeft}   
        color={color}
        opacity={opacity}
        style={style}
    />
  )  
};

Divider.defaultProps = {
  marginTop: sizes.normal,
  marginBottom: sizes.normal,
  marginLeft: sizes.none,
  marginRight: sizes.none,
  color: colors.nightLight,
  opacity: undefined
};

export default Divider;