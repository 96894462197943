import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const Skeleton = ({ width, height, rounded, style }) => (
    <SkeletonContainer
        width={width}
        height={height}
        rounded={rounded}
        style={style}
    />
);

const waveAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;


const SkeletonContainer = styled.div`
    display: inline-block;
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '1em'};
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
    border-radius: 4px;
    animation: ${waveAnimation} 2.5s infinite linear;

  ${({ rounded }) => rounded && `
    border-radius: 50%;
  `};
`;


export default Skeleton;
