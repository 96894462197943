import Text from 'components/_UI/Text';
import React, { useEffect, useState } from 'react';
import { Column } from 'styled/alignment/Column';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import axios from 'axios';
import { useCheckout } from 'context/checkout/Checkout';
import styled from 'styled-components';
import colors from 'theme/colors';

export default function PaymentAddress({ }) {
    const apiKey = import.meta.env.VITE_GMAPS_KEY;

    const { checkout, setCheckout } = useCheckout();

    const [value, setValue] = useState(null);
    const [hasFilled, setHasFilled] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (window.trigger && !hasFilled) {
            setShowError('O campo endereço é obrigatorio');
        } else {
            setShowError(false);
        }
    }, [window.trigger]);

    async function getPlaceId(data) {
        const placeId = data?.value?.place_id;
        const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${apiKey}`;

        if (placeId) {
            try {
                const { data } = await axios.get(endpoint);
                if (data.status === 'OK') {
                    const addressComponents = data.results[0]?.address_components;
                    // const location = data.results[0]?.geometry.location;

                    const postalcode = addressComponents.find(component => component.types.includes('postal_code'))?.long_name || '';
                    const streetName = addressComponents.find(component => component.types.includes('route'))?.long_name || '';
                    const district = addressComponents.find(component => component.types.includes('sublocality') || component.types.includes('locality'))?.long_name || '';
                    const city = addressComponents.find(component => component.types.includes('administrative_area_level_2'))?.long_name || '';
                    const state = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.short_name || '';
                    const streetNumber = addressComponents.find(component => component.types.includes('street_number'))?.long_name || '';

                    if (!streetNumber) {
                        setShowError('Informe um endereço com número');
                        return
                    }

                    if (!postalcode || !streetName || !district || !city || !state) {
                        setShowError('Endereço invalido');
                        return
                    }

                    const address = {
                        street: {
                            postalcode,
                            name: streetName,
                            district,
                            city: {
                                name: city,
                                state: {
                                    uf: state
                                }
                            }
                        },
                        number: streetNumber,
                        country: 'Brasil'
                    }

                    setCheckout({ ...checkout, address });
                    setHasFilled(true);
                    setShowError(false);
                    return;
                }
                setHasFilled(false);
            } catch (err) {
                setHasFilled(false);
            }
        }
    }

    return (
        <Container>
            <Text name='small' paddingBottom={'6px'} type='bold' color={colors.nightDark}>
                Endereço *
            </Text>
            
            <GooglePlacesAutocomplete
                apiKey={apiKey}
                apiOptions={{ language: 'pt', region: 'br' }}
                debounce={300}
                selectProps={{
                    value,
                    onChange: (newValue) => {
                        setValue(newValue);
                        getPlaceId(newValue);
                    },
                    placeholder: ' ',
                    noOptionsMessage: () => 'Nenhum endereço encontrado',
                    loadingMessage: () => 'Carregando...'
                }}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: 'br'
                    }
                }}
            />

            {!!showError && (
                <Column marginTop='5px'>
                    <Text color={colors.danger} name='small' paddingLeft={'6px'}> {showError} </Text>
                </Column>
            )}
        </Container>
    );
}

const Container = styled(Column)`
    margin-top: 8px;

    & > div > div {
        border: 1px solid ${colors.nightLight};
    }

    /* & > div > div {
        border-radius: 30px;
        background-color: ${colors.neutralDark};
        border: 1px solid ${colors.neutralDark};
        font-weight: bold;
        color: ${colors.nightLight};
        
        & > div {
            color: ${colors.night};
        }
    } */
`;
