import Pusher from 'pusher-js';

class PusherService {
    constructor() {
        this.pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
            cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        });
        this.channels = {};
    }

    subscribe(channelName, eventName, callback) {
        if (typeof channelName !== 'string' || typeof eventName !== 'string') {
            // console.error('channelName and eventName must be strings');
            return;
        }

        if (!this.channels[channelName]) {
            this.channels[channelName] = this.pusher.subscribe(channelName);
        }

        this.channels[channelName].bind(eventName, callback);
    }

    unsubscribe(channelName, eventName, callback) {
        if (typeof channelName !== 'string' || typeof eventName !== 'string') {
            // console.error('channelName and eventName must be strings');
            return;
        }

        if (this.channels[channelName]) {
            this.channels[channelName].unbind(eventName, callback);
            if (Object.keys(this.channels[channelName].bindings).length === 0) {
                this.pusher.unsubscribe(channelName);
                delete this.channels[channelName];
            }
        }
    }
}

const pusherService = new PusherService();
export default pusherService;
