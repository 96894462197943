import Button from 'components/_UI/Button';
import Ilustration from 'components/_UI/Ilustration';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import ToastContainer from 'components/_UI/ToastsContainer';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react'
import { PlusSquare } from 'react-feather';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import CreatePromotionalCode from './CreatePromotionalCode';
import { Row } from 'styled/alignment/Row';
import DiscountCodeCard from './DiscountCodeCard';
import promotionalCodesApi from 'api/requests/promotionalCodes';
import { useEvent } from 'context/events/Event';
import DeleteDiscountCodeModal from './DeleteDiscountCodeModal';

export default function PromotionalCodes() {
    const { toast, toasts } = useToast();
    const { event } = useEvent();

    const [loading, setLaoding] = useState(false);
    const [content, setContent] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        getDiscountCodes();
    }, [])


    async function getDiscountCodes() {
        setLaoding(true);

        try {
            const { data } = await promotionalCodesApi.getByEvent(event?.slug);
            setContent(data);
        } catch (error) {
        } finally {
            setLaoding(false);
        }
    }

    function onEditClick(item) {
        setEdit(item);
        setModalVisible(true);
    }

    function refreshTickets() {
        setModalVisible(false);
        setDeleteModal(false);
        setEdit(false);

        getDiscountCodes();
    }

    async function onCopyClick(code) {
        if (navigator?.clipboard?.writeText) {
            try {
                await navigator.clipboard.writeText(code)
                toast.success('Link copiado com sucesso!', { position: 'top-center' })
            } catch (error) {
                copyToClipboardFallback(code)
            }
        }
    }

    function copyToClipboardFallback(value) {
        const input = document.createElement('input');
        input.value = value;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        toast.success('Link copiado com sucesso!', { position: 'top-center' })
    }

    return (
        <Container>
            <ToastContainer toasts={toasts} />

            <HeaderContent>
                <Text name='subtitle' paddingBottom={0}>
                    Códigos promocionais
                </Text>

                <Button
                    variant='featured'
                    category='squired'
                    onClick={() => setModalVisible(true)}
                >
                    <PlusSquare color={colors.neutralLight} size={16} />
                    <Text
                        color={colors.neutralLight}
                        paddingBottom={0}
                        marginLeft={'6px'}
                    >
                        Criar código promocional
                    </Text>
                </Button>
            </HeaderContent>

            {modalVisible &&
                <CreatePromotionalCode
                    visible={modalVisible}
                    isEdit={edit}
                    toast={toast}
                    onClose={() => {
                        setEdit(false);
                        setModalVisible(false);
                    }}
                    onSuccess={refreshTickets}
                />
            }

            {
                deleteModal &&
                <DeleteDiscountCodeModal
                    visible={!!deleteModal}
                    data={deleteModal}
                    toast={toast}
                    onClose={() => setDeleteModal(false)}
                    onSuccess={refreshTickets}
                />
            }


            {
                loading ? (
                    <ContentLoader>
                        <Loader />
                    </ContentLoader>
                ) : (
                    content?.length
                        ? (
                            <>
                                <Text type='bold' marginTop={'50px'} marginBottom={'10px'}>
                                    Códigos promocionais criados
                                </Text>

                                <DiscountCodesList>
                                    {
                                        content.map(el => (
                                            <DiscountCodeCard
                                                key={el?.id}
                                                data={el}
                                                onDelete={() => setDeleteModal(el)}
                                                onEdit={() => onEditClick(el)}
                                                onCopy={() => onCopyClick(el?.code)}
                                            />
                                        ))
                                    }
                                </DiscountCodesList>
                            </>
                        ) : (
                            <>
                                <Picture>
                                    <Ilustration name='promotionalCode' />
                                </Picture>

                                <Text type='bold' color={colors.night} textAlign={'center'} name='normal'>
                                    Você ainda não possui códigos promocionais
                                </Text>

                                <Text type='bold' color={colors.night} textAlign={'center'} name='normal'>
                                    Quel tal criar um?
                                </Text>

                                <FullyCentered marginTop='10px'>
                                    <Column width='250px'>
                                        <Button
                                            variant='featured'
                                            category='squired'
                                            onClick={() => {
                                                setEdit(false);
                                                setModalVisible(true);
                                            }}
                                        >
                                            <PlusSquare color={colors.neutralLight} size={16} />
                                            <Text
                                                color={colors.neutralLight}
                                                paddingBottom={0}
                                                marginLeft={'6px'}
                                            >
                                                Criar código promocional
                                            </Text>
                                        </Button>
                                    </Column>
                                </FullyCentered>
                            </>
                        )
                )
            }

        </Container>
    )
}


const Container = styled(Column)`
    width: 100%;
`;

const HeaderContent = styled(Column)`
    width: 100%;

    & > button {
        margin-top: 20px;
    }

    @media screen and (min-width: ${breakpoints.md}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & > h2,
        & > button {
            width: auto;
            margin-top: 0;
        }

        
    }
`;

const Picture = styled.picture`
    width: auto;
    height: 300px;
`;

const ActionButton = styled.div`
    width: auto;
    height: auto;
    transition: all ease-in-out .25s;
    cursor: pointer;

    ${({ disabled }) => disabled && `
        opacity: .4;
        pointer-events: none;
    `};
`;

const DiscountCodesList = styled(Column)`
    width: 100%;

    div {
        margin-top: 2%;
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;

        div {
            margin-top: 0;
        }
        
        div:nth-of-type(4n + 1) {
            margin-left: 0;
        }
    
        div:nth-of-type(n+5) {
            margin-top: 1.2%; 
        }
    }

`;

const ContentLoader = styled(Column)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;