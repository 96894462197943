import React, { createContext, useState, useContext } from 'react';
import tabs from 'theme/tabs';

const OnSelectMenuContext = createContext();

export default function OnSelectMenuProvider({ children }) {
    const [onSelectMenu, setOnSelectMenu] = useState(null);

    return (
        <OnSelectMenuContext.Provider value={{ onSelectMenu, setOnSelectMenu }}>
            {children}
        </OnSelectMenuContext.Provider>
    );
}

export function useOnSelectMenu() {
    const context = useContext(OnSelectMenuContext);

    if (!context) throw new Error('useOnSelectMenu must be used within a OnSelectMenuProvider');

    const { onSelectMenu, setOnSelectMenu } = context;
    return { onSelectMenu, setOnSelectMenu };
}