import Text from 'components/_UI/Text'
import React from 'react'
import styled from 'styled-components'
import { Row } from 'styled/alignment/Row'
import colors from 'theme/colors'
import marginProps from 'styled/shared-props/margin';
import paddingProps from 'styled/shared-props/padding'


export default function TabWallet({
    toogleTab, 
    selectedTab,
    marginTop, 
    marginBottom, 
    marginLeft, 
    marginRight, 
    paddingTop, 
    paddingBottom, 
    paddingLeft, 
    paddingRight
}) {
    const items = {
        ordersActive: {name: 'active', label: 'Ativos'},
        ordersFinish: {name: 'finish', label: 'Encerrados'}
    }
  return (
    <StyledTab
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        paddingTop={paddingTop}
        paddingRight={paddingRight}
        paddingBottom={paddingBottom}
        paddingLeft={paddingLeft} 
    >
        {
            Object.values(items).map((item) => (

                <TabItem
                    onClick={() => toogleTab(item.name)}
                    active={selectedTab === item.name}
                >
                    <Text
                        name='highlight'
                        paddingBottom={'0px'}
                    >
                        {item.label}
                    </Text>
                </TabItem>
             ))
            
        }
    </StyledTab>
  )
}

const StyledTab = styled(Row)`
    width: 100%;
    height: 40px;
    justify-content: space-around;
    background: ${colors.neutralLight};
    ${marginProps};
    ${paddingProps};
`;

const TabItem = styled(Row)`
    align-items: center;
    width: 50%;
    justify-content: center;
    cursor: pointer;
    
    ${({ active }) => active && `
       border-bottom: 3px solid ${colors.primary};
    `};
   
`;
