import Divider from 'components/_UI/Divider'
import Text from 'components/_UI/Text'
import TicketCardMinimal from 'components/commons/tickets/TicketCardMinimal'
import CardTickets from 'modules/dashboard/events/detail/tickets/card/CardTickets'
import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import breakpoints from 'theme/breakpoints'
import colors from 'theme/colors'

export default function TicketsForSale({ data, onTicketClick = () => false }) {
    return (
        <Column>

            <Divider marginTop={'15px'} color={colors.primaryDashboard} opacity={0.3} />

            <Text
                name='normal'
                color={colors.night}
                type='bold'
            >
                Ingressos à venda
            </Text>

            <ContentTickets>

                {
                    data?.filter((el) => el.type === 'ticket').map((el) => (
                        <CustomContainerTicket marginTop='15px' key={el.id}>
                            <TicketCardMinimal data={el} onTicketClick={() => onTicketClick()} />
                        </CustomContainerTicket>
                    ))

                }
            </ContentTickets>

        </Column>
    )
}


const ContentTickets = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
    overflow-y: auto;
    flex-direction: row;

    & > div:first-child{
        margin-left: 0;
    }

    @media screen and (min-width: ${breakpoints.md}) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        & > div:first-child{
            margin-left: 0;
        }

        & > div:nth-child(3n + 1) {
            margin-left: 0; 
        }
    }
`;

const CustomContainerTicket = styled(Column)`
    margin-left: 10px;

    & > div {
        width: 220px !important;
    }

    @media screen and (min-width: ${breakpoints.md}) {
        flex-direction: row;
        width: 31.5%;
        margin-left: 2%;

        & > div {
            width: 100% !important;
        }
    }

`;
