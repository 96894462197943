import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import React from 'react';
import styled, { css } from 'styled-components';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import Background from './Background';
import PropTypes from 'prop-types';
import Button from 'components/_UI/Button';

function PresentationBackground({ ComponentToRender, is }) {
    return (
        <Container>
            <Background />

            <LogoContainer>
                <Icon name='whizr-inline-logo' color='white' />
            </LogoContainer>

            <TextContainer>
                <Text name='hero' color={colors.neutralLight}>
                    Encontre eventos e coisas novas,
                </Text>
                <Text name='hero' color={colors.neutralLight} marginTop='-15px'>
                    em qualquer lugar da sua região.
                </Text>
                <Text name='subtitle' color={colors.neutralLight} marginTop='5px'>
                    Crie agora sua conta e descubra tudo novo com Whizr.
                </Text>
            </TextContainer>

            <SignContainer
                signIn={is === 'signIn' && colors.neutralDark}
                signUp={is === 'signUp' && colors.neutralDark}
                createPartner={is === 'createPartner' && colors.neutralDark}
                recoverAccount={is === 'recoverAccount' && colors.blueVerticalGradient}
            >
                <ComponentToRender />
            </SignContainer>
        </Container>

    )
};

PresentationBackground.propTypes = {
    is: PropTypes.oneOf(['signIn', 'signUp', 'recoverAccount', 'createPartner'])
};

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
`;

const TextContainer = styled.div`
    display: none;

    @media screen and (min-width: ${breakpoints.lg}) {
        display: block;
        padding-top: 20%;
        padding-left: 4%;
        width: 50%;
    };

    @media screen and (min-width: ${breakpoints.xl}) {
        display: block;
        height: 100vh;
        padding: 17% 4%;
    };
`;

const LogoContainer = styled.div`
    display: none;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        display: block;
        position: absolute;
        top: 0;
        left: 4%;
        width: 200px;
    };
`;

const SignContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    
    ${({ signIn }) => signIn && css`
        background: ${signIn};
    `};
    
    ${({ signUp }) => signUp && css`
        background: ${signUp};
    `};
    ${({ createPartner }) => createPartner && css`
        background: ${createPartner};
    `};

    ${({ recoverAccount }) => recoverAccount && css`
        background-image: ${recoverAccount};
    `};
    
    @media screen and (min-width: ${breakpoints.lg}) { 
        position: absolute;
        right: 0;
        top: 0;
        width: 33.5%;
        border-radius: ${sizes.grand} ${sizes.none} ${sizes.none} ${sizes.grand};
    }
`;

export default PresentationBackground;
