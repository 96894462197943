import BoxFilters from './filters/BoxFilters';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { useSearchParams } from 'react-router-dom';
import breakpoints from 'theme/breakpoints';
import { useCollapse } from 'context/general/Collapse';
import eventsApi from 'api/requests/events';
import EventDetail from './detail/EventDetail';
import indexPriority from 'theme/z-index';
import { FullyCentered } from 'styled/alignment/Center';
import sizes from 'theme/sizes';
import Loader from 'components/_UI/Loader';
import colors from 'theme/colors';
import { useEventsList } from 'context/events/EventsList';
import EventForm from './form/EventForm';
import { useEvent } from 'context/events/Event';
import DeleteEvent from './delete/DeleteEvent';
import UserLocationModal from './map/UserLocationModal';
import Text from 'components/_UI/Text';
import EventCard from './card/EventCard';
import MultipleEventsList from './map/MultipleEventsList';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';
import routeParams from 'theme/routeParams';
import { useCurrentLocation } from 'context/user/CurrentLocation';

export default function Events({
    filterClick,
    eventClick,
    onLoadData,
    onLoading,
    setEventClick
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { backButtonCollapse, setBackButtonCollapse } = useBackButtonCollapse();
    const { collapse, setCollapse } = useCollapse();
    const { eventsList, setEventsList } = useEventsList();
    const { event } = useEvent();

    const [eventData, setEventData] = useState();
    const [filterData, setFilterData] = useState(null)
    const [slug, setSlug] = useState(null);
    const [modalDelete, setModalDelete] = useState(false);
    const { currentLocation } = useCurrentLocation();

    useEffect(async () => {
        await getEvents();
        await getEventByRouteParam();
    }, [currentLocation?.city])

    useEffect(async () => {
        if (eventClick) {
            onEventClick(eventClick)
            return
        }
    }, [eventClick]);

    async function renderMultipleEvents(lat, long) {
        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => (
                <FullyCentered height='100%'>
                    <Loader />
                </FullyCentered>
            )
        })

        try {
            const { data: res } = await eventsApi.findMultiples({ lat, long })
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => res?.data && <MultipleEventsList data={res.data} />
            })
        } catch (error) {
            <FullyCentered height='100%'>
                <Text name='highlight'> Ocorreu um erro inesperado. </Text>
            </FullyCentered>
        }
    }


    async function getEventByRouteParam() {
        const eventSlug = searchParams.get('evento');

        const latParam = searchParams.get('lat')
        const longParam = searchParams.get('long')

        const term = searchParams.get('term')
        const filter_date = searchParams.get('filter_date')
        const classification_id = searchParams.get('classification_id')
        const tags = searchParams.get('tags')
        const distance_range = searchParams.get('distance_range')
        const min_date = searchParams.get('min_date')
        const max_date = searchParams.get('max_date')
        const city = searchParams.get('city')

        if (term || filter_date || classification_id || tags || distance_range || (min_date && max_date) || city) {
            setFilterData({ term, filter_date, classification_id, tags, distance_range, min_date, max_date, city })
            return
        }

        if (latParam && longParam) {
            renderMultipleEvents(latParam, longParam)
            return
        }

        if (!eventSlug) {
            return
        }

        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => (
                <FullyCentered height='100%'>
                    <Loader />
                </FullyCentered>
            )
        })

        try {
            const { data } = await eventsApi.getBySlug(eventSlug);
            setSlug(eventSlug);
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => data && <EventDetail data={data.data} />
            })
        } catch (error) {
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => (
                    <FullyCentered height='100%'>
                        <Text>Ocorreu um erro, tente novamente mais tarde.</Text>
                    </FullyCentered>
                )
            })
        }
    }


    async function getEvents() {
        onLoading(true)
        try {
            const { data } = await eventsApi.get(true, currentLocation?.city);
            const arraysWithMultiple = data?.data?.filter(obj => obj.hasOwnProperty('multiples'));
            const arraysWithoutMultiple = data?.data?.filter(obj => !obj.hasOwnProperty('multiples'));

            const formatedData = {
                data: arraysWithoutMultiple,
                withMultiples: arraysWithMultiple
            }
            
            onLoadData(formatedData);
            setEventData(formatedData);
            setEventsList(formatedData);

            onLoading(false)
        } catch (error) {
            console.log('error G-E')
            onLoading(false)
        }
    }


    async function onEventClick(event) {
        let response = false
        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => (
                <FullyCentered height='100%'>
                    <Loader />
                </FullyCentered>
            )
        })

        try {
            const { data } = await eventsApi.getBySlug(event.slug)
            response = data
        } catch (error) {
            response = 'error'
        } finally {
            setSearchParams({ evento: event.slug })
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => event && eventDetailToRender(response)
            })
        }
    };

    function eventDetailToRender(response) {
        if (!response) {
            return
        }

        if (response === 'error') {
            return (
                <FullyCentered height='100%'>
                    <Text name='highlight'>Ocorreu um erro inesperado.</Text>
                </FullyCentered>
            )
        }

        return <EventDetail data={response.data} />
    }

    function onCloseCollapse() {
        setEventClick(null)
        setSearchParams((prevSearchParams) => {
            const newSearchParams = new URLSearchParams(prevSearchParams);

            Object.values(routeParams.events).map(el => {
                newSearchParams.delete(el)
            })

            return newSearchParams;
        });

        setCollapse({
            ...collapse,
            withContent: false,
            height: 'min',
            arrowPosition: true,
            componentToRender: false
        });

        setBackButtonCollapse({ ...backButtonCollapse, visible: false });
    };

    return (
        <BoxFilters
            onClose={() => onCloseCollapse()}
            onNearToYouClick={(event) => onEventClick(event)}
            isRouteParam={filterData}
            filterClick={filterClick}
        />
    );
}

const EventsContainer = styled(Column)`
    width: 100%;
    height: 100vh;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        display:flex;
        flex-direction: row;
    }
`;
