import Button from 'components/_UI/Button';
import Modal from 'components/_UI/Modal';
import Text from 'components/_UI/Text';
import React, { useState, useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import fonts from 'theme/fonts';
import QuillResizeImage from 'quill-resize-image';


const Size = Quill.import('attributors/style/size');
Size.whitelist = ['18px', '16px', '12px', '10px'];
Quill.register(Size, true);
Quill.register("modules/resize", QuillResizeImage);


function RichTextEditor({ edit, onChangePureText, onChange, onToggleImage, pathImageDescription, setErrorTypeImageDescription, errorTypeImageDescription }) {
  const [content, setContent] = useState('');
  const [selectedSize, setSelectedSize] = useState('Normal');
  const [showPreview, setShowPreview] = useState(false);
  const [valueImage, setValueImage] = useState(null)

  const quillRef = useRef(null);



  const modules = {
    toolbar: [
      [{ size: ['18px', '16px', '12px', '10px'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
      [{ color: Object.values(colors) }, { background: Object.values(colors) }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['image'],
      ['link']
    ],
    resize: { 
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    }
  };

  const formats = [
    'size',
    'bold', 'italic', 'underline', 'strike',
    'align',
    'color', 'background',
    'list', 'bullet',
    'image',
    'link',
  ]

  useEffect(() => {
    
    if (pathImageDescription) {
      
      const newPath = pathImageDescription;
      
      // Cria um elemento temporário e insere o HTML existente
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = valueImage;
    
      // Seleciona a imagem dentro do <p> que você quer atualizar
      const imgElement = tempDiv.querySelector('p img');
      
      if (imgElement) {
        // Atualiza somente o atributo src da imagem
        imgElement.setAttribute('src', newPath);
        
        // Atribui o novo src diretamente ao valor da imagem no conteúdo existente
        const updatedSrc = imgElement.getAttribute('src');
        
        document.querySelector('p img').setAttribute('src', newPath);
      }

      return 
    }

    

    const quillEditor = document.querySelector('.ql-toolbar .ql-size');
    if (quillEditor) {
      quillEditor.addEventListener('click', () => {
        const selectedItem = document.querySelector('.ql-size .ql-selected');
        if (selectedItem) {
          const size = selectedItem.getAttribute('data-value');
          switch (size) {
            case '18px':
              setSelectedSize('Título');
              break;
            case '16px':
              setSelectedSize('Subtítulo');
              break;
            case '12px':
              setSelectedSize('Normal');
              break;
            case '10px':
              setSelectedSize('Pequeno');
              break;
            default:
              setSelectedSize('Normal');
          }
        }
      });
    }

    if (edit) {
      handleChange(edit?.description_with_html);
    }
  }, [pathImageDescription]);

  function base64ToFile(base64, fileName) {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1]; 
    const b64Data = arr[1]; 
    const byteCharacters = atob(b64Data); 
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mime });

    const file = new File([blob], fileName, { type: mime });

    return file;
  }



  const handleChange = (value) => {
    
    if(value) {
      const hasBase64Image = value?.includes('data:image');

      if(!hasBase64Image && errorTypeImageDescription) {
        setErrorTypeImageDescription('')
      }

      if(hasBase64Image) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = value;
        
        const imgElement = tempDiv.querySelector('p img');
    
        let imgSrc = imgElement.getAttribute('src');
        setValueImage(value)
        const file = base64ToFile(imgSrc, 'image_description');
        onToggleImage(file)
        
      }
      
    }

    setContent(value); 
    
    onChange(value); 

    if (quillRef.current) {
      const quillEditor = quillRef.current.getEditor();
      const pureText = quillEditor.getText();
      onChangePureText(pureText);
      return
    }

    onChangePureText('');
  };



  return (
    <Container selectedSize={selectedSize}>
      <ReactQuill
        ref={quillRef}
        value={content}
        onChange={handleChange}
        theme="snow"
        modules={modules}
        formats={formats}
      />

      {
        !!content?.length && (
          <Button
            marginTop={'18px'}
            category='outline'
            onClick={() => setShowPreview(true)}
          >
            Pré visualizar conteudo
          </Button>

        )
      }

      {
        showPreview && (
          <Modal
            size='large'
            isOpen={showPreview}
            closeButton={true}
            onClose={() => setShowPreview(false)}
          >
            <Column>
              <Text name='subtitle'>Pré visualização</Text>
              <div dangerouslySetInnerHTML={{ __html: content }}  />
            </Column>
          </Modal>
        )

      }
    </Container>
  );
};

export default RichTextEditor;

const Container = styled.div`
  margin-bottom: 18px;

  .ql-editor {
    max-height: 400px;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before {
    content: '${props => props.selectedSize}'; 
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: 'Título';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: 'Subtítulo';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
    content: 'Normal';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
    content: 'Pequeno';
  }

  .ql-snow.ql-toolbar button {
    width: auto;
  }

  .ql-size-large {
    font-size: ${fonts.sizes.highlight};
  }

  .ql-size-subtitle {
    font-size: ${fonts.sizes.subtitle};
  }

  .ql-size-normal {
    font-size: ${fonts.sizes.default};
  }

  .ql-size-small {
    font-size: ${fonts.sizes.small};
  }

  .ql-toolbar.ql-snow {
    padding: 0 8px;
    text-align: left;
  }

  .ql-toolbar.ql-snow .ql-formats {
    border-right: 1px solid ${colors.nightLight};
    padding: 8px 10px;
    margin-right: 0;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label {
    width: auto;
    padding-right: 25px;
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: 'Salvar';
  }

  .ql-snow .ql-tooltip a.ql-action::after {
    content: 'Editar';
  }

  .ql-snow .ql-tooltip::before {
    content: 'link:';
  }

  .ql-snow .ql-tooltip a.ql-remove::before {
    content: 'Remover';
  }

  #editor-resizer .toolbar .group:first-child {
    .btn:nth-child(4) {
      color: transparent; 
      position: relative;
    }
    .btn:nth-child(4)::before {
      content: "resetar"; 
      color: black; 
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.night}
    }
  }

    #editor-resizer .toolbar .group:not(:first-child) {
    .btn:nth-child(1) {
      color: transparent; 
      position: relative;
    }
    .btn:nth-child(1)::before {
      content: "esquerda"; 
      color: black;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.night}
    }

    .btn:nth-child(2) {
      color: transparent;
      position: relative;
    }
    .btn:nth-child(2)::before {
      content: "centro"; 
      color: black; 
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.night}
    }

    .btn:nth-child(3) {
      color: transparent; 
      position: relative;
    }
    .btn:nth-child(3)::before {
      content: "direita"; 
      color: black; 
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.night}
    }

    .btn:nth-child(4) {
      color: transparent; 
      position: relative;
    }
    .btn:nth-child(4)::before {
      content: "resetar"; 
      color: black; 
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.night}
    }
  }
`;

