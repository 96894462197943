import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import EventsGroupItem from './EventsGroupItem';
import Loader from 'components/_UI/Loader';

export default function EventsGroupList({nextEvents, nowEvents, onEventClick}) {
  const hasNextEvents = nextEvents?.items?.data?.length > 0 || nextEvents?.length > 0;
  const hasNowEvents = nowEvents?.items?.data?.length > 0 || nowEvents?.length > 0;
  
  return (
    <Container>
      <>
        {
          (hasNextEvents) ? (
            <EventsGroupItem
              data={nextEvents}
              title={'Próximos Eventos'}
              onEventClick={onEventClick}
            />
          ) : null
        }
        {
          (hasNowEvents) ? (
            <EventsGroupItem  data={nowEvents} title={'Em andamento'}  onEventClick={onEventClick}/>
          ) : null
        }
      </>
    </Container>
  )
}

const Container = styled(Column)`
  width: 100%;
  display: flex;
  &:nth-child(1) {
      margin-top: none;
  }
`;