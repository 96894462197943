import React, { useEffect, useState } from 'react'
import { FullyCentered } from 'styled/alignment/Center';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import Table from 'components/_UI/Table';
import colors from 'theme/colors';
import Icon from 'components/_UI/Icon';
import participantsApi from "api/requests/participants"
import InfoTooltip from 'components/_UI/InfoTooltip';
import { Download, DownloadCloud, Info, Mail } from 'react-feather';
import Modal from 'components/_UI/Modal';
import { Row } from 'styled/alignment/Row';
import fonts from 'theme/fonts';
import { Link } from 'react-router-dom';
import DynamicProfileImage from 'components/commons/DynamicProfileImage';
import Paginate from 'components/_UI/Paginate';
import downloadBlob from 'helpers/downloadBlob';

export default function ParticipantsListModal({ event, visible, onBack, onClose }) {
    const [loading, setLoading] = useState(false)
    const [participants, setParticipants] = useState([])
    const [perPage, setPerpage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [totalItems, setTotalItems] = useState(null);;
    const [detail, setDetail] = useState()
    const [formatDateOrder, setFormatDateOrder] = useState('')
    const [downloadLoader, setDownloadLoader] = useState('')
    // const [selectedSlug, setSelectedSlug] = useState('')

    const permissionTypeMap = {
        ticket: 'Validar ingressos',
        product: 'Validar produtos',
        both: 'Validar ingressos, Validar produtos'
    }

    useEffect(() => {
        getParticipants()
    }, [])

    const getDetail = (item) => {
        setDetail(item)
        let formatDate = new Date(item?.created_at)
        setFormatDateOrder(formatDate);
    }

    async function getParticipants(page = 1) {
        setLoading(true);

        try {
            const { data } = await participantsApi.list(event?.slug, page)

            setParticipants(data?.data);
            setPerpage(data?.meta?.per_page)
            setLastPage(data.meta?.last_page)
            setCurrentPage(data?.meta?.current_page)
            setTotalItems(data?.meta?.total)
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const listParticipants = participants?.map(item => {
        const date = new Date(item?.created_at);

        return {
            code: <Text name='small'>{item?.code}</Text>,
            name: <Text name='small'>{item?.name}</Text>,
            type: <StyledStatus status={item?.type}> {item.type === 'ticket' ? 'Ingresso' : 'Produto'} </StyledStatus>,
            product: <Text name='small'>{item?.product}</Text>,
            date: <Text name='small'>
                {date.toLocaleDateString('pt-BR')} - {date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
            </Text>,
            status: <StyledStatus status={item.used}>{item.used ? 'Utilizado' : 'Não Utilizado'}</StyledStatus>,
            actions:
                <ContentAction
                    onClick={() => getDetail(item)}
                >
                    <InfoTooltip
                        bgColor={colors.night}
                        text={'Visualizar Detalhes'}
                        type='none'
                    >
                        <Info size={20} color={colors.primaryLight} />
                    </InfoTooltip>
                </ContentAction>
        };
    })


    async function onDownload() {
        setDownloadLoader(true);
        try {
            const { data } = await participantsApi.download(event?.id);
            const fileName = `Participantes_${event?.name}.xls`;

            downloadBlob(data, fileName);

            // toast.success('Arquivo baixado com sucesso!', { position: 'top-center' })
        } catch (error) {
            // toast.error('Ocorreu um erro ao baixar o arquivo.', { position: 'top-center' })
        } finally {
            setDownloadLoader(false);
        }
    }


    return (
        <Modal isOpen={visible} onClose={onClose} size='xLarge'>
            {

                loading ? (
                    <FullyCentered height='200px' >
                        <Loader />
                    </FullyCentered>
                ) : (
                    <>
                        <StyledBackButton onClick={onBack} >
                            <Icon name='back' color={colors.nightLight} />
                        </StyledBackButton>

                        {
                            participants?.length ?
                                (
                                    <Column >
                                        <Row fullyCentralized marginBottom={'30px'}>
                                            <Text type='bold' name='highlight' width='auto' paddingBottom={0}>
                                                {event?.name} - Participantes
                                            </Text>

                                            <StyledDownloadButton onClick={onDownload}>
                                                <InfoTooltip
                                                    bgColor={colors.night}
                                                    text={'Baixar lista de participantes'}
                                                    type='none'
                                                >
                                                    <DownloadCloud size={20} color={colors.secondaryPurple} />
                                                </InfoTooltip>
                                            </StyledDownloadButton>
                                        </Row>

                                        <Table
                                            rows={listParticipants}
                                            columns={['Código', 'Participante', 'Tipo', 'Item', 'Data de compra', 'Status', 'Detalhes']}
                                        />

                                        {
                                            lastPage > 1 &&
                                            <Paginate
                                                perPage={perPage}
                                                totalItems={totalItems}
                                                lastPage={lastPage}
                                                firstPage={1}
                                                currentPage={currentPage}
                                                marginTop={'20px'}
                                                onPaginate={(number) => getParticipants(number)}
                                            />
                                        }
                                    </Column>
                                ) : (
                                    <Column height='200px'>
                                        <Text name="highlight">
                                            Não foram encontrados participantes neste evento até o momento.
                                        </Text>
                                    </Column>
                                )
                        }
                    </>
                )
            }

            <Modal isOpen={detail} onClose={() => setDetail(false)}>
                <ModalWrapper>
                    <Row verticalCenter paddingBottom='25px'>
                        <Column width='100px'>
                            <DynamicProfileImage
                                img={detail?.avatar}
                                word={detail?.name}
                                size={'85px'}
                            />
                        </Column>
                        <Column>
                            <Row marginLeft={'10px'} >
                                <Text name='subtitle'>
                                    {detail?.name}
                                </Text>
                            </Row>
                            <Row verticalCenter marginLeft={'10px'} >
                                <Mail
                                    size={20}
                                    color={colors.night}
                                />
                                <Text
                                    marginLeft={'5px'}
                                    paddingBottom={0}
                                >

                                    {detail?.email}
                                </Text>
                            </Row>
                        </Column>
                    </Row>

                    <Row paddingBottom='0'>
                        <Text><b>Código:</b> {detail?.code} </Text>
                    </Row>

                    <Row marginTop='5px' paddingBottom='0'>
                        <Text><b>Tipo:</b> {detail?.type === 'ticket' ? 'Ingresso' : 'Produto'} </Text>
                    </Row>

                    <Row marginTop='5px' paddingBottom='0'>
                        <Text><b>Item:</b> {detail?.product} </Text>
                    </Row>

                    <Row marginTop='5px' paddingBottom='0'>
                        <Text><b>Data de compra:</b>
                            {formatDateOrder &&
                                ` ${formatDateOrder.toLocaleDateString('pt-BR')} - ${formatDateOrder.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}`
                            }
                        </Text>
                    </Row>

                    <Row marginTop='5px' paddingBottom='0'>
                        <Text><b>Status:</b> {detail?.used ? 'Utilizado' : 'Não utilizado'} </Text>
                    </Row>

                    <Row marginTop='5px' paddingBottom='0'>
                        <Text><b>Código promocional:</b> {detail?.coupon ? detail?.coupon : 'Não'}</Text>
                    </Row>

                    {
                        detail?.custom_fields && Object?.keys(detail?.custom_fields).length > 0 && (
                            <ContentAditionalFields>
                                <Text name='highlight' marginTop={'5px'}>Campos adicionais</Text>

                                {detail?.custom_fields && Object.entries(detail?.custom_fields).map(([key, value]) => (
                                    <Row marginTop="5px" key={key} verticalCenter>
                                        <Text paddingBottom='0'>
                                            <b>{key}:</b>
                                        </Text>
                                        {typeof value === 'string' && (value.startsWith('https://') || value.startsWith('www')) ? (
                                            <AditionalLinkField to={value.startsWith('www') ? `https://${value}` : value} target="_blank" rel="noopener noreferrer">
                                                abrir link
                                            </AditionalLinkField>
                                        ) : (
                                            <Text marginLeft={'5px'} paddingBottom='0' >
                                                {Array.isArray(value) ? value.join(", ") : value}
                                            </Text>
                                        )}
                                    </Row>
                                ))}

                            </ContentAditionalFields>
                        )
                    }
                </ModalWrapper>
            </Modal>
        </Modal>
    )
}

const StyledBackButton = styled(Column)`
    width: 27px;
    height: 27px;
    padding: 6px;
    cursor: pointer;
    position: absolute;
    left: 10px;
    top: 8px;

    ${({ desktop }) => desktop && `
        display: flex;
        cursor: pointer;
        `}

    ${({ mobile }) => mobile && `
        @media screen and (min-width: ${breakpoints.lg}) {
            display: none;
        }   
        `}
    `;

const StyledDownloadButton = styled(Column)`
    width: 27px;
    height: 27px;
    padding: 6px;
    margin-left: 4px;
    cursor: pointer;
    transition: all ease-in-out .25s;

    &  > div {
        & > :nth-child(2) {
            bottom: -120% !important;
            transform: translateX(-20%);
            right: 0;
            left: 220%;
            &::after {
                transform: translateX(0%);
                left: -8%;
                top: 50%;
                transform: rotate(90deg);
            }
        }
    }


    ${({ desktop }) => desktop && `
        display: flex;
        cursor: pointer;
        `}

    /* ${({ mobile }) => mobile && `
        @media screen and (min-width: ${breakpoints.lg}) {
            display: none;
        }   
        `} */
    `;


const CardContainer = styled(Column)`
border-radius: 12px;
box-shadow: 0px 1px 4px ${colors.night};
cursor: pointer;
`;

const ContainerBody = styled(Column)`
width: 100%;
display: flex;
padding: 10px 10px;
`;

const ContentBody = styled(Row)`
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
padding: 5px 0;

& > p {
    padding-bottom: 0;
    font-size: 14px;
}
`;

const StyledStatus = styled(Row)`
padding: 2px;
border-radius: 4px;
color: ${colors.neutralLight};
font-size: 12px;
justify-content: center;
width: auto;
padding: 3px 5px;
font-size: ${fonts.sizes.small};

${({ status }) => status == false && `
background: ${colors.nightLight};
border-radius: 6px 30px 30px 6px;
`}

${({ status }) => status == true && `
background: ${colors.success};
border-radius: 6px 30px 30px 6px;
`}

${({ status }) => status === 'ticket' && `
background: orange;
`}

${({ status }) => status === 'product' && `
background: ${colors.occasionalPurple};
`}
`;

const ContentAction = styled.div`
cursor: pointer;
display: flex;
align-items: center;
transition: all ease-in-out 0.25s;

& > div > svg {
    &:hover {
        transform: scale(1.1);
   }
}
`

const ContentNotResult = styled.div`
& > div {
    flex-direction: column;
}

& > div > picture {
    width: 45%;
}

& > div > div {
    width: 100%;
}
`;


const ModalWrapper = styled(Column)`
    padding: 10px;

    & > p {
        text-transform: capitalize;
    }
`

const ContentAditionalFields = styled(Column)`
    margin-top: 10px;
    align-items: flex-start;

    & * p {
        text-transform: capitalize;
    }
`

const AditionalLinkField = styled(Link)`
    padding-bottom: 0 !important;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 5px;
`
