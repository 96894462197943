// Toast.js
import React from 'react';
import { CheckCircle, Info, XCircle } from 'react-feather';
import styled, { css, keyframes } from 'styled-components';
import colors from 'theme/colors';
import Text from './Text';
import { Row } from 'styled/alignment/Row';
import { FullyCentered } from 'styled/alignment/Center';
import indexPriority from 'theme/z-index';


export default function Toast({ message, type }) {
    return (
        <ToastWrapper
            type={type}>
            {
                type === 'warning' && (
                    <Info color={colors.neutral} size={20} />
                )
            }

            {
                type === 'success' && (
                    <CheckCircle color={colors.neutral} size={20} />
                )
            }

            {
                type === 'error' && (
                    <XCircle color={colors.neutral} size={20} />
                )
            }

            <Text marginLeft='10px' color={colors.neutral} paddingBottom={0}>
                {message}
            </Text>
        </ToastWrapper>
    );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const ToastWrapper = styled(FullyCentered)`
  flex-direction: row;
  background-color: #333;
  color: ${colors.neutral};
  padding: 16px;
  margin: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s, ${fadeOut} 0.5s 2.5s;
  z-index: ${indexPriority.max};

  ${(props) =>
        props.type === 'success' &&
        css`
      background-color: ${colors.success};
    `}

  ${(props) =>
        props.type === 'error' &&
        css`
      background-color: ${colors.danger};
    `}

  ${(props) =>
        props.type === 'warning' &&
        css`
      background-color: ${colors.occasionalOrange};
    `}
`;