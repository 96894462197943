import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import sizes from 'theme/sizes';
import indexPriority from 'theme/z-index';
import Icon from './Icon';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';
import MultipleEventsList from 'modules/events/map/MultipleEventsList';
import { useCollapse } from 'context/general/Collapse';
import { useSearchParams } from 'react-router-dom';
import NearListAll from 'modules/events/nearToYou/NearListAll';
import Navbar from 'components/commons/Navbar';
import { Row } from 'styled/alignment/Row';
import Wallet from 'screens/user/Wallet';
import SelectLocation from './SelectLocation';
import { useCurrentLocation } from 'context/user/CurrentLocation';
import { useShowLocationModal } from 'context/general/ShowLocationModal';
import { useInvisibleButton } from 'context/general/InvisibleButton';
import MainWallet from 'modules/users/wallet/MainWallet';


const ARROW_UP = 'up';
const ARROW_DOWN = 'down';

export default function BoxCollapse({
    children,
    height = 'min',
    upCollapse,
    downCollapse,
    arrowPosition = false,
    onClose,
    onToggle,
    onBack,
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const {invisibleButton} = useInvisibleButton()

    const { currentLocation } = useCurrentLocation()
    const { showLocationModal, setShowLocationModal } = useShowLocationModal()

    const [customHeight, setCustomHeight] = useState('200px');
    const { backButtonCollapse, setBackButtonCollapse } = useBackButtonCollapse();
    const { collapse, setCollapse } = useCollapse();

    const [touchStartY, setTouchStartY] = useState(0);
    const [touchDifference, setTouchDifference] = useState(0);

    useEffect(() => {
              
        if (height === 'min') {
            setCustomHeight('285px');
            // setCustomHeight('235px');
        }

        if (height === 'max') {
            setCustomHeight('88dvh');
        }
    }, [height, currentLocation?.city, invisibleButton])

    function onBackButton() {
        setBackButtonCollapse({ ...backButtonCollapse, visible: false })
        if (backButtonCollapse.from === 'multiple-list') {
            renderMultipleEvents(backButtonCollapse.data)
            return
        }

        if (backButtonCollapse.from === 'filter-result') {
            onBack(backButtonCollapse.data)
        }
        if (backButtonCollapse.from === 'near-list') {
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => backButtonCollapse.data && <NearListAll total={backButtonCollapse.totalEventsNears} data={backButtonCollapse.data} />
            })
        }

        if (backButtonCollapse.from === 'wallet-detail') {
            
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => <MainWallet data={backButtonCollapse?.data} />
            })
        }
    }

    async function renderMultipleEvents(data) {
        setCollapse({
            ...collapse,
            withContent: true,
            height: 'max',
            arrowPosition: false,
            componentToRender: () => data && <MultipleEventsList data={data} />
        })
    }

    function handleTouchStart(event) {
        setTouchStartY(event.touches[0].clientY);
        setTouchDifference(0);
    }

    function handleTouchMove(event) {
        const currentY = event.touches[0].clientY;
        const diff = touchStartY - currentY;
        setTouchDifference(diff);
    }

    function handleTouchEnd() {
        // Define um valor de limite para determinar quando ativar upCollapse ou downCollapse
        const threshold = 50;

        if (touchDifference > threshold) {
            upCollapse();
            setTouchStartY(0);
            setTouchDifference(0);
            return
        }

        if (touchDifference < -threshold) {
            downCollapse();
            setTouchStartY(0);
            setTouchDifference(0);
        }
    }

    return (
        <Container>
            {
                (!arrowPosition || arrowPosition === 'both' && !collapse?.withHideCity) && (
                    <CloseButton
                        id='collapse_close'
                        position={arrowPosition}
                        collapseHeight={customHeight} 
                        onClick={() => onClose()}
                        invisible={invisibleButton}
                    >
                        <Icon name='close' color={colors.night} />
                    </CloseButton>
                )
            }

            {
                (backButtonCollapse.visible && !collapse?.withHideCity) && (
                    <BackButton
                        id='collapse_back'
                        collapseHeight={customHeight} onClick={() => onBackButton()}
                        invisible={invisibleButton}
                    >
                        <Icon name='back' color={colors.night} />
                    </BackButton>
                )
            }

            <CollapseContainer height={customHeight} invisible={invisibleButton}>
                {(arrowPosition === true || arrowPosition === 'both' ) && !collapse?.withHideCity && (
                    <ArrowContainer
                        id='collapse_toggler'
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        <ToggleBar onClick={() => onToggle()} />
                        {/* <Arrow position={arrowPosition} onClick={() => onToggle()} /> */}
                    </ArrowContainer>
                )
                }

                {
                    !!currentLocation  && arrowPosition == true && !collapse?.withHideCity  &&
                    <ContentSelectLocation>
                        <SelectLocation onClick={() => setShowLocationModal(true)} textButton={currentLocation?.city} />
                    </ContentSelectLocation>
                }

                {children}


                {/* <ContentNavbar>
                    <Navbar />
                </ContentNavbar> */}
            </CollapseContainer>
        </Container>
    )
};


const Container = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: 40px;

    @media screen and (min-width: ${breakpoints.lg}) {
        max-width: 60%;
        position: relative;
        padding-top: 95px;
        /* bottom: 10px; */
        padding-bottom:0px;
        width: 100%;
        /* margin-top: 13px; */
    }
`;

const CollapseContainer = styled.div`
    position: relative;
    background: ${colors.neutralLight};
    width: 100%;
    height: ${props => props.height};
    border-radius: ${sizes.small} ${sizes.small} ${sizes.none} ${sizes.none};
    box-shadow: ${sizes.none} ${sizes.none} ${sizes.tiny} ${colors.nightLight};
    transition: all .3s ease-in-out;
    z-index: ${indexPriority.high};
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 20px;

    @media screen and (min-width: ${breakpoints.lg}) {
        height: 100%;
        border-radius: ${sizes.none} ${sizes.none} ${sizes.none} ${sizes.small};
        box-shadow: none;
        background: ${colors.neutralBackground};
        margin-bottom: 0px;


    }

    ${({invisible}) => invisible && `
        overflow: unset !important;
    `}
`

const CloseButton = styled.div`
    display: flex;
    position: absolute;
    right: 20px;
    width: 30px;
    height: 30px;
    background: ${colors.neutralLight};
    padding: 6px;
    border-radius: 30px;
    border: 1px solid ${colors.nightLight};
    box-shadow: 1px 4x 4px ${colors.night};
    transition: all ease .3s;
    z-index: ${indexPriority.xHigh};

    ${({invisible}) => invisible && `
        z-index: 1;
    `}

    cursor: pointer;
    
    ${({ collapseHeight }) => collapseHeight && `
        bottom: calc(${collapseHeight} + 42px);
        right: 20px;
    `};

    @media screen and (min-width: ${breakpoints.lg}) {
        top: 5px;
        position: absolute;
        top: 104px !important;
        right: 16px !important;
        width: 32px;
        height: 32px;

        ${({ position }) => position === 'both' && `
            display: none;
        `}

    }
`;

const ArrowContainer = styled(FullyCentered)`
    width: 100%;
    height: auto;
`;

const ToggleBar = styled.div`
    width: 26px;
    height: 4px;
    background: ${colors.nightLight};
    border-radius: 30px;
    margin: 10px;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        display: none;
    }
`;

const BackButton = styled.div`
    position: absolute;
    right: 60px;
    width: 30px;
    height: 30px;
    padding: 6px;
    border-radius: 30px;
    background: ${colors.neutralLight};
    border: 1px solid ${colors.nightLight};
    box-shadow: 1px 4x 4px ${colors.night};
    transition: all ease .3s;
    z-index: ${indexPriority.xHigh};
    cursor: pointer;


    ${({ collapseHeight }) => collapseHeight && `
        bottom: calc(${collapseHeight} + 42px);
        right: 60px;
    `};

    @media screen and (min-width: ${breakpoints.lg}) {
        position: absolute;
        top: 104px !important;
        right: 56px !important;
        width: 32px;
        height: 32px;


        ${({ position }) => position === 'both' && `
            display: none;
        `}

    }

    ${({invisible}) => invisible && `
        z-index: 1;
    `}

`;

const ContentNavbar = styled(Row)`
    margin-top: 60px;

    @media screen and (min-width: ${breakpoints.lg}) {
        display: none;
    }
`;

const ContentSelectLocation = styled.div`   
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 12px;

    @media screen and (min-width: ${breakpoints.lg}) {
        display: none;
    }
`;

