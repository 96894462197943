import colors from "./colors";

const establishmentTypes = {
    free: {
        id: import.meta.env.VITE_ESTABLISHMENT_TYPE_FREE,
        key: import.meta.env.VITE_ESTABLISHMENT_TYPE_FREE,
        label: 'Gratuito',
        color: colors.primaryLight,
        slug: 'free',
        description: ''
    },
    paid: {
        id: import.meta.env.VITE_ESTABLISHMENT_TYPE_PAID,
        key: import.meta.env.VITE_ESTABLISHMENT_TYPE_PAID,
        label: 'Pago',
        color: colors.occasionalPurple,
        slug: 'paid',
        description: ''
    },

    all: {
        id: 9999,
        key: 9999,
        label: 'Todos os tipos',
        value: 'Todos os tipos'
    },
};


export default establishmentTypes;