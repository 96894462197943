

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import sizes from 'theme/sizes';
import { FullyCentered } from 'styled/alignment/Center';
import colors from 'theme/colors';

const Pin = styled(FullyCentered)`
  position: absolute;
  background: ${colors.neutralLight};
  box-shadow: ${sizes.preon} ${sizes.preon} ${sizes.quark} ${colors.nightLight};
  width: ${sizes.small};
  height: ${sizes.small};
  border-radius: ${sizes.grand};

  &:before {
    content: '';
    position: absolute;
    width: ${sizes.tiny};
    height: ${sizes.tiny};
    background: ${colors.occasionalPurple};
    border-radius: ${sizes.grand};
  }
`;

function UserLocationMark({ onClick }) {
  return (
    <Pin onClick={onClick} />   
  );
}


UserLocationMark.defaultProps = {
  onClick: null,
};

UserLocationMark.propTypes = {
  onClick: PropTypes.func,
};

export default UserLocationMark;