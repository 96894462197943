import PropTypes from 'prop-types';
import { StyledIlustration } from 'styled/UI/StyledIlustration';
function Ilustration({
    // layout props
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    // action props
    name,
}) {
    return (
        <StyledIlustration 
            marginTop={marginTop}
            marginRight={marginRight}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
        >
            <use href={`../../../assets/svg/ilustrations.svg#${name}`} />
        </StyledIlustration>
    )
}

Ilustration.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
};

Ilustration.defaultProps = {
    color: '#ccc'
};

export default Ilustration