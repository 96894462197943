import Text from 'components/_UI/Text';
import React, {useEffect}from 'react'
import styled from 'styled-components';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import CardOrganizers from './card/CardOrganizers';
import { useUser } from 'context/user/User';
import userApi from 'api/requests/user';
import { FullyCentered } from 'styled/alignment/Center';
export default function Organizers({data}) {
  const { user, setUser } = useUser();

  useEffect(async() => {    
    if(!user) {
        try {
            const {data} = await userApi.me()
            setUser(data)
        } catch (error) {
            console.log(error)
        }
    }
  }, [])
  


  return (
    <ContainerOrganizers>
        <Text>Organizadores</Text>
        <Row>
            {
                data?.avatar ? (
                    <ContentOrganizers>
                        <CardOrganizers data={user?.avatar} />
                    </ContentOrganizers>
                ) : (
                    <UserSettings>
                        {user?.first_name?.substring(0, 1)}
                    </UserSettings>
                )
             }
         </Row>

    </ContainerOrganizers>
  )
}


const ContainerOrganizers = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    & > p {
        font-weight: bold;
        color: ${colors.night};
    }

    @media screen and (min-width: 690px) {
        width: 100%;
        padding-left: 15px;
    }

`;

const ContentOrganizers = styled.div`
    margin-left: -15px;
    padding-left: 7px;

    &:nth-child(1) {
        margin-left: 0;
    }
`;


const UserSettings = styled(FullyCentered)`
    border: 1px solid ${colors.primaryDark};
    object-fit: cover;
    width: 48px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${({zindex}) => zindex};
    background: ${colors.primaryLight};
    box-shadow: 1px 4x 4px ${colors.night};
    color: ${colors.neutral};
    font-weight: bold;

`;
