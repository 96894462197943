import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import FilterGroupItem from './FilterGroupItem';

export default function FilterGroupList({ closeDropdown, expiredEvents, nextEvents, nowEvents }) {
    return (
        <ContainerGroupItem>
            {
                nextEvents?.items?.data?.length ? (
                    <FilterGroupItem
                        closeDropdown={closeDropdown}
                        data={nextEvents}
                        title={'Próximos Eventos'}
                    />
                ) : null
            }

            {
                expiredEvents?.items?.data?.length  ? (
                    <FilterGroupItem
                        closeDropdown={closeDropdown}
                        data={expiredEvents}
                        title={'Finalizados'}
                    />
                ) : null
            }

            {
                nowEvents?.total ? (
                    <FilterGroupItem closeDropdown={closeDropdown} data={nowEvents} title={'Em andamento'} />
                ) : null
            }

        </ContainerGroupItem>
    )
}

const ContainerGroupItem = styled(Column)`
    width: 100%;
    display: flex;
    &:nth-child(1) {
        margin-top: none;
    }
`;

