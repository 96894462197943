import InfoTooltip from 'components/_UI/InfoTooltip';
import Text from 'components/_UI/Text';
import { Copy, Delete, Edit, Trash2 } from 'react-feather';
import styled from 'styled-components';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import fonts from 'theme/fonts';

export default function DiscountCodeCard({ data, onDelete, onEdit, onCopy }) {
  function renderStatus() {
    if (data?.max_uses - data?.uses < 1) {
      return <Status status='soldout'>Esgotado</Status>
    }

    if (!!data?.is_active) {
      return <Status status='available'>Disponivel</Status>
    }

    if (!data?.is_active) {
      return <Status status='unavailable'>Desativado</Status>
    }
  }

  function renderType() {
    const { discount_type } = data;

    if (discount_type === 'ticket') {
      return 'Ingressos'
    }

    if (discount_type === 'product') {
      return 'Produtos'
    }

    if (discount_type === 'both') {
      return 'Ingressos e produtos'
    }
  }

  return (
    <TagContainer>
      <TagDetails>
        <InfoTooltip
          bgColor={colors.night}
          text={data?.code}
          type='none'
        >
          <Text color={colors.secondaryPurple} type={'bold'} ellipsisAt={'92%'}>
            {data?.code}
          </Text>
        </InfoTooltip>
        {/* <Text name={'small'} color={colors.night}>Expiração: 10/10/2025</Text> */}
        <Text name={'small'} color={colors.night}>
          <b>Desconto:</b> {data?.total_discount}
        </Text>
        <Text name={'small'} color={colors.night}>
          <b>Qnt. Utilizada:</b> {data?.uses}
        </Text>
        <Text name={'small'} color={colors.night}>
          <b>Qnt. Disponivel:</b> {(data?.max_uses - data?.uses)}
        </Text>
        <Text name={'small'} color={colors.night}>
          <b>Qnt. Total:</b> {data?.max_uses}
        </Text>
        <Text name={'small'} color={colors.night}>
          <Row>
            <b>Status:</b> {renderStatus()}
          </Row>
        </Text>
        <Text name={'small'} color={colors.night}><b>Aplicável em:</b> {renderType()} </Text>
      </TagDetails>

      <IconsContainer>
        <InfoTooltip
          bgColor={colors.night}
          text='Copiar codigo promocional'
          type='none'
        >
          <Copy size={14} color={colors.primaryLight} onClick={onCopy} />
        </InfoTooltip>

        <InfoTooltip
          bgColor={colors.night}
          text='Editar codigo promocional'
          type='none'
        >
          <Edit size={14} color={colors.primaryLight} onClick={onEdit} />
        </InfoTooltip>

        <InfoTooltip
          bgColor={colors.night}
          text='Excluir codigo promocional'
          type='none'
        >
          <Trash2 size={14} color={colors.danger} onClick={onDelete} />
        </InfoTooltip>
      </IconsContainer>
    </TagContainer>
  );
};

const TagContainer = styled.div`
  background-color: ${colors.neutralLight};  
  box-shadow: 0 2px 7px #ddd;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${breakpoints.lg}) {
    margin-left: 1.2%;
    width: 24%;
  }
`;

const TagDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.div`
  padding: 2px 4px;
  border-radius: 4px;
  color: ${colors.neutral};
  font-size: ${fonts.sizes.mini};
  font-weight: bold;
  text-align: center;
  margin-left: 2px !important;
  width: auto;

  ${({ status }) => status === 'available' && `
    background: ${colors.success};
  `};
  
  ${({ status }) => status === 'unavailable' && `
    background: ${colors.nightLight};
  `};
  
  ${({ status }) => status === 'soldout' && `
    background: ${colors.danger};
  `};
`;

const IconsContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;

  svg {
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.05);
    }
  }
`;
